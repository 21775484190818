import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { PreferencesType } from "services/Utils/DossierUtils";
import DossierFormula from "./DossierFormula";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>;
  preferences: PreferencesType;
};

function DossierFormulas(params: Props): JSX.Element {
  const { formMethods, formulaFormMethods, preferences } = params;

  const { getValues } = formulaFormMethods;
  const { formulas } = getValues();

  return (
    <MDBox>
      {formulas?.map((formula: APIt.CodigoFormula, index) => (
        <DossierFormula
          formMethods={formMethods}
          formulaFormMethods={formulaFormMethods}
          preferences={preferences}
          formula={formula}
          index={index}
          key={formula.id}
        />
      ))}
    </MDBox>
  );
}

export default DossierFormulas;
