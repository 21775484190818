import { Grid } from "@mui/material";
import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useTramitesEstatusData } from "features/Dashboard/useDashboardData";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { useEffect, useState } from "react";
import SearchBar from "../SearchBar";
import Total from "./Total";

function Estatus(): JSX.Element {
  const [dbData, loading] = useTramitesEstatusData();
  const { dashboardFilterState } = useDashboardFilters();
  const { paises, businessUnits } = dashboardFilterState;

  // IN MEMORY FILTER
  const [initialData, setInitialData] = useState([] as DashboardTramiteDaily[]);

  useEffect(() => {
    const filterPais = (tramite: DashboardTramiteVO) =>
      !paises || paises.length < 1 || paises.includes(tramite.pais?.codigo);
    const filterBU = (tramite: DashboardTramiteVO) =>
      !businessUnits ||
      businessUnits.length < 1 ||
      businessUnits.some((bu) => tramite.businessUnit?.codigo?.includes(bu));

    const iData = dbData.map((dashboardEntry: DashboardTramiteDaily) => {
      const estatusArmado = dashboardEntry.estatusArmado?.filter(filterPais)?.filter(filterBU);
      const estatusEnDesarrollo = dashboardEntry.estatusEnDesarrollo
        ?.filter(filterPais)
        ?.filter(filterBU);
      const estatusSinDossier = dashboardEntry.estatusSinDossier
        ?.filter(filterPais)
        ?.filter(filterBU);
      const estatusEnProceso = dashboardEntry.estatusEnProceso
        ?.filter(filterPais)
        ?.filter(filterBU);
      const estatusAprobado = dashboardEntry.estatusAprobado?.filter(filterPais)?.filter(filterBU);
      const estatusRechazado = dashboardEntry.estatusRechazado
        ?.filter(filterPais)
        ?.filter(filterBU);
      const estatusDesistido = dashboardEntry.estatusDesistido
        ?.filter(filterPais)
        ?.filter(filterBU);

      return {
        ...dashboardEntry,
        estatusArmado,
        estatusEnDesarrollo,
        estatusSinDossier,
        estatusEnProceso,
        estatusAprobado,
        estatusRechazado,
        estatusDesistido,
      };
    });

    setInitialData(iData);
  }, [paises, businessUnits, dbData]);

  return (
    <Layout>
      <Navbar />
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Estatus de Innovaciones
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox maxWidth={900}>
                <Total initialData={initialData} loading={loading} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default Estatus;
