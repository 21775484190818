export const listCategorias = /* GraphQL */ `
  query ListCategorias($filter: ModelCategoriaFilterInput, $limit: Int, $nextToken: String) {
    listCategorias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        codigo
        nombre
      }
      nextToken
    }
  }
`;

export const tmp = "";
