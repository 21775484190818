import {
  Registro,
  RegistroAcondicionadorPrimarioVO,
  RegistroFabricanteVO,
  RegistroFormulaVO,
  RegistroPresentacionesVO,
  RegistroPrincipioActivoVO,
} from "API";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useState, useEffect } from "react";
import { downloadExcel, downloadExcelEasy } from "services/Export/ExcelExporter";
import registrosConfig from "services/Export/Registros/RegistrosExportConfig";

import { fetchAllRegistrosList } from "services/Registro/RegistroList";

/* eslint-disable */
type RefreshCallback = (overrideFull?: string) => void;
/* eslint-enable */

const useRegistroList = (): [Registro[], RefreshCallback, () => void, boolean, boolean, any] => {
  const [isLoading, setIsLoading] = useState(false);
  const [registros, setRegistros] = useState([]);

  const {
    state: { registroSearchState },
    dispatch,
  } = useTramitesAppStateContext();

  // get first page
  useEffect(() => {
    if (registroSearchState?.results?.length < 1) {
      setIsLoading(true);
      fetchAllRegistrosList(null, null).then(
        (result) => {
          const { page } = result;
          /* dispatch({
            type: "update-registro-list",
            payload: { page, nextToken },
          }); */

          setRegistros(page);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  }, []);

  const fetchNextPage = (overrideFull?: string) => {
    setIsLoading(true);
    const searchState = overrideFull
      ? { ...registroSearchState, full: overrideFull }
      : registroSearchState;
    fetchAllRegistrosList(null, searchState).then(
      (result) => {
        const { page } = result;
        /* dispatch({
          type: "update-registro-list",
          payload: { page: [...registroSearchState.results, ...page], nextToken },
        }); */
        setRegistros(page);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const refresh = (overrideFull: string) => {
    setIsLoading(true);
    const searchState =
      overrideFull !== null ? { ...registroSearchState, full: overrideFull } : registroSearchState;
    fetchAllRegistrosList(null, searchState).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-registro-list",
          payload: { page, nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const fetchAll = async (searchState: any) => {
    const allItems: any[] = [];
    let currentToken: any = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      const { page, nextToken } = await fetchAllRegistrosList(currentToken, searchState, true);

      allItems.push(...page);
      currentToken = nextToken;
    } while (currentToken != null);
    return allItems;
  };

  const exportRegistros = (columns: any) => {
    setIsLoading(true);
    fetchAll(registroSearchState).then((results) => {
      setIsLoading(false);
      downloadExcel(results, registrosConfig, columns);
    });
  };

  return [registros, refresh, fetchNextPage, isLoading, false, exportRegistros];
};

export type RegistroListRow = {
  id: string;
  categoria: string;
  codigo: string;
  pais: string;
  marca: string;
  registro: string;
  vencimiento: string;
  inicioRenovacion: string;
  formaCosmeticaFarmaceutica: string;
  categoriaTerapeutica: string;
  ingrediente: string;
  concentracion: string;
  ingredienteFabricante: string;
  ingredienteDireccion: string;
  producto: string;
  presentacion: string;
  fabricante: string;
  fabricanteDireccion: string;
  sourcing: string;
  acondicionadorId: string;
  acondicionador: string;
  acondicionadorDireccion: string;
  condicionVenta: string;
  estatusVenta: string;
  compliance: string;
  estabilidad: string;
  vidaUtil: string;
};

const formatRegistros = (registros: Registro[], includePrincipios?: boolean): RegistroListRow[] => {
  const result: RegistroListRow[] = [];

  const addByRegistro = (
    registro: Registro,
    formula: RegistroFormulaVO,
    principio?: RegistroPrincipioActivoVO
  ) => {
    registro.presentaciones?.forEach((presentacion: RegistroPresentacionesVO) => {
      presentacion.fabricantes?.forEach((fabricante: RegistroFabricanteVO) => {
        fabricante.acondicionadoresPrimarios?.forEach(
          (acondicionador: RegistroAcondicionadorPrimarioVO) => {
            result.push({
              id: registro.id,
              categoria: formula.categoria?.codigo,
              codigo: formula.codigo,
              pais: registro.pais?.codigo,
              marca: formula.marca,
              registro: registro.numeroRegistro,
              vencimiento: registro.vencimientoSanitario,
              inicioRenovacion: registro.inicioRenovacion,
              formaCosmeticaFarmaceutica: formula.formaCosmeticaFarmaceutica?.nombre,
              categoriaTerapeutica: formula.categoriaTerapeutica?.nombre,
              ingrediente: principio?.principio.nombre,
              concentracion: principio?.concentracion,
              ingredienteFabricante:
                principio?.fabricantes?.length > 0 ? principio.fabricantes[0].nombre || "" : "",
              ingredienteDireccion:
                principio?.fabricantes?.length > 0 ? principio.fabricantes[0].direccion || "" : "",
              producto: presentacion.producto,
              presentacion: presentacion.presentaciones,
              fabricante: fabricante.fabricante.nombre,
              fabricanteDireccion: fabricante.fabricante.direccion,
              sourcing: fabricante.fabricante.pais.codigo,
              acondicionadorId: acondicionador.acondicionadorPrimario.id,
              acondicionador: acondicionador.acondicionadorPrimario.nombre,
              acondicionadorDireccion: acondicionador.acondicionadorPrimario.direccion,
              condicionVenta: formula.condicionVenta?.nombre,
              estatusVenta: acondicionador.estatusVenta,
              compliance: acondicionador.compliance ? "Si" : "No",
              estabilidad: acondicionador.estabilidad ? "Si" : "No",
              vidaUtil: acondicionador.vidaUtil,
            });
          }
        );
      });
    });
  };

  registros.forEach((registro: Registro) => {
    registro.formulas?.forEach((formula: RegistroFormulaVO) => {
      if (includePrincipios && formula.principiosActivos?.length > 0) {
        formula.principiosActivos?.forEach((principio: RegistroPrincipioActivoVO) => {
          addByRegistro(registro, formula, principio);
        });
      } else {
        addByRegistro(registro, formula);
      }
    });
  });

  return result;
};

const useRegistroListPlano = (): [Registro[], boolean, any] => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { registroSearchState },
    dispatch,
  } = useTramitesAppStateContext();

  const exportRegistros = (data: string[][], header: string[]) => {
    downloadExcelEasy(data, header);
  };

  // get first page
  useEffect(() => {
    if (registroSearchState?.results?.length < 1) {
      setIsLoading(true);
      fetchAllRegistrosList(null, null).then(
        (result) => {
          const { page } = result;
          dispatch({
            type: "update-registro-list",
            payload: { page },
          });
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  }, []);

  return [registroSearchState?.results || [], isLoading, exportRegistros];
};

export { useRegistroList, useRegistroListPlano, formatRegistros };
