import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (initialData: DashboardTramiteDaily[]) => {
  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["Aprobados", "Rechazados", "Desistidos"];

  let header = "Por Iniciativa - Sin datos";

  if (initialData?.length > 0) {
    // ADD INICIATIVA LABELS
    const unsortedLabels: string[] = [];
    initialData.forEach((entry: DashboardTramiteDaily) => {
      const addIniciativaLabel = (tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (unsortedLabels.includes(tramite.iniciativa?.nombre)) return;

        unsortedLabels.push(tramite.iniciativa?.nombre);
      };

      entry.efectividadAprobados?.forEach(addIniciativaLabel);
      entry.efectividadRechazados?.forEach(addIniciativaLabel);
      entry.efectividadDesistidos?.forEach(addIniciativaLabel);
    });
    const labels = unsortedLabels.sort();

    // ADD DATASETS
    // -- Aprobados
    const tmpAprobadosData: any = {};
    const tmpRechazadosData: any = {};
    const tmpDesistidosData: any = {};
    initialData.forEach((entry: DashboardTramiteDaily) => {
      entry.efectividadAprobados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpAprobadosData[tramite.iniciativa?.nombre])
          tmpAprobadosData[tramite.iniciativa?.nombre] = 0;
        tmpAprobadosData[tramite.iniciativa?.nombre] += 1;
      });
      entry.efectividadRechazados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpRechazadosData[tramite.iniciativa?.nombre])
          tmpRechazadosData[tramite.iniciativa?.nombre] = 0;
        tmpRechazadosData[tramite.iniciativa?.nombre] += 1;
      });
      entry.efectividadDesistidos?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpDesistidosData[tramite.iniciativa?.nombre])
          tmpDesistidosData[tramite.iniciativa?.nombre] = 0;
        tmpDesistidosData[tramite.iniciativa?.nombre] += 1;
      });
    });

    const datasetAprobados = {
      label: "Aprobados",
      data: labels.map((iniciativa: string) => tmpAprobadosData[iniciativa] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetAprobados);

    const datasetRechazados = {
      label: "Rechazados",
      data: labels.map((iniciativa: string) => tmpRechazadosData[iniciativa] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetRechazados);

    const datasetDesistidos = {
      label: "Desistidos",
      data: labels.map((iniciativa: string) => tmpDesistidosData[iniciativa] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetDesistidos);

    data.labels = labels.map((iniciativa: string) => {
      const aprobados = tmpAprobadosData[iniciativa] || 0;
      const rechazados = tmpRechazadosData[iniciativa] || 0;
      const desistidos = tmpDesistidosData[iniciativa] || 0;

      const total = aprobados + rechazados + desistidos;
      const percentage = (aprobados / total) * 100;

      return `${iniciativa} (${percentage.toFixed(2)}%)`;
    });

    header = "Por Iniciativa";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
