export const searchRegistros = /* GraphQL */ `
  query ListRegistros(
    $filter: SearchableRegistroSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableRegistroSearchSortInput]
  ) {
    searchRegistroSearches(sort: $sort, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        registro {
          id
          formulas {
            bu {
              codigo
              id
              nombre
            }
            categoria {
              codigo
              id
              nombre
            }
            categoriaTerapeutica {
              id
              nombre
            }
            codigo
            condicionVenta {
              id
              nombre
            }
            formaCosmeticaFarmaceutica {
              id
              nombre
            }
            id
            marca
            principiosActivos {
              concentracion
              fabricantes {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              principio {
                id
                nombre
              }
            }
          }
          inicioRenovacion
          numeroRegistro
          pais {
            nombre
            id
            codigo
          }
          autoridad {
            id
            nombre
            pais {
              id
              codigo
              nombre
            }
          }
          presentaciones {
            formulas
            presentaciones
            producto
            fabricantes {
              acondicionadoresPrimarios {
                acondicionadorPrimario {
                  direccion
                  id
                  nombre
                  pais {
                    codigo
                    nombre
                    id
                  }
                  vencimientoBPM
                }
                compliance
                estabilidad
                estatusVenta
                vidaUtil
              }
              fabricante {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              fabricantesAPI {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              maquiladores {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
            }
          }
          vencimientoSanitario
        }
      }
      nextToken
    }
  }
`;

export const listRegistros = /* GraphQL */ `
  query ListRegistros($filter: ModelRegistroSearchFilterInput, $limit: Int, $nextToken: String) {
    listRegistroSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        registro {
          id
          autoridad {
            id
            nombre
            pais {
              id
              codigo
              nombre
            }
          }
          formulas {
            bu {
              codigo
              id
              nombre
            }
            categoria {
              codigo
              id
              nombre
            }
            categoriaTerapeutica {
              id
              nombre
            }
            codigo
            condicionVenta {
              id
              nombre
            }
            formaCosmeticaFarmaceutica {
              id
              nombre
            }
            id
            marca
            principiosActivos {
              concentracion
              fabricantes {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              principio {
                id
                nombre
              }
            }
          }
          inicioRenovacion
          numeroRegistro
          pais {
            nombre
            id
            codigo
          }
          autoridad {
            id
            nombre
            pais {
              id
              codigo
              nombre
            }
          }
          presentaciones {
            formulas
            presentaciones
            producto
            fabricantes {
              acondicionadoresPrimarios {
                acondicionadorPrimario {
                  direccion
                  id
                  nombre
                  pais {
                    codigo
                    nombre
                    id
                  }
                  vencimientoBPM
                }
                compliance
                estabilidad
                estatusVenta
                vidaUtil
              }
              fabricante {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              fabricantesAPI {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              maquiladores {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
            }
          }
          vencimientoSanitario
        }
      }
      nextToken
    }
  }
`;

export const getRegistro = /* GraphQL */ `
  query GetRegistros($id: ID!) {
    getRegistro(id: $id) {
      id
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
        }
      }
      formulas {
        bu {
          codigo
          id
          nombre
        }
        categoria {
          codigo
          id
          nombre
        }
        categoriaTerapeutica {
          id
          nombre
        }
        codigo
        condicionVenta {
          id
          nombre
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
        }
        id
        marca
        principiosActivos {
          concentracion
          fabricantes {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
          principio {
            id
            nombre
          }
        }
      }
      inicioRenovacion
      numeroRegistro
      pais {
        nombre
        id
        codigo
      }
      presentaciones {
        formulas
        presentaciones
        producto
        fabricantes {
          acondicionadoresPrimarios {
            acondicionadorPrimario {
              direccion
              id
              nombre
              pais {
                codigo
                nombre
                id
              }
              vencimientoBPM
            }
            compliance
            estabilidad
            estatusVenta
            vidaUtil
          }
          fabricante {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
          fabricantesAPI {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
          maquiladores {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
        }
      }
      vencimientoSanitario
    }
  }
`;
