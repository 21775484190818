import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import * as DossierUtils from "services/Utils/DossierUtils";

/**
 *
 * HANDLE DOSSIER PREFERENCES
 *
 */
const useDossierPreferencesHook = (
  formMethods: UseFormReturn<APIt.Tramite, any>,
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>
) => {
  const { getValues, setValue } = formMethods;
  const {
    tipo,
    pais: { codigo: codigoPais },
    dossierPreferences,
    dossierData,
    formulas: registroFormulas,
  } = getValues();

  const { getValues: getFormulaValues, setValue: setFormulasValue } = formulaFormMethods;
  const { formulas } = getFormulaValues();

  useEffect(() => {
    const { tramiteDossierPreferencesObj, formulaDossierDataArray, newDossierData } =
      DossierUtils.prepareDossierPreferences(
        registroFormulas,
        dossierPreferences,
        codigoPais,
        tipo,
        dossierData,
        formulas
      );

    formulaDossierDataArray.forEach((x, i) => setFormulasValue(`formulas.${i}.dossierData`, x));
    setValue("dossierData", newDossierData);
    setValue("dossierPreferences", JSON.stringify(tramiteDossierPreferencesObj));
  }, [registroFormulas, formulas]);
};

export default useDossierPreferencesHook;
