import * as APIt from "API";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { queryEestatus, queryEfectividad, queryObjeciones, querySometimientos } from "./queries";

const getEfectividadEntries = async (fechaInicio: string, fechaFin: string): Promise<any[]> => {
  const conditions: APIt.DashboardDailyByTypeAndDateQueryVariables = {
    type: APIt.TipoDashboardTramiteDaily.efectividad,
    date: {
      between: [fechaInicio, fechaFin],
    },
  };

  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardDailyByTypeAndDateQuery>>(
      graphqlOperation(queryEfectividad, { ...conditions, nextToken })
    );
    if (result?.data?.dashboardDailyByTypeAndDate?.items?.length > 0) {
      const newData = result?.data?.dashboardDailyByTypeAndDate?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardDailyByTypeAndDate?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

const getSometimientosEntries = async (fechaInicio: string, fechaFin: string): Promise<any[]> => {
  const conditions: APIt.DashboardDailyByTypeAndDateQueryVariables = {
    type: APIt.TipoDashboardTramiteDaily.sometimientos,
    date: {
      between: [fechaInicio, fechaFin],
    },
  };

  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardDailyByTypeAndDateQuery>>(
      graphqlOperation(querySometimientos, { ...conditions, nextToken })
    );
    if (result?.data?.dashboardDailyByTypeAndDate?.items?.length > 0) {
      const newData = result?.data?.dashboardDailyByTypeAndDate?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardDailyByTypeAndDate?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

const getObjecionessEntries = async (fechaInicio: string, fechaFin: string): Promise<any[]> => {
  const conditions: APIt.DashboardDailyByTypeAndDateQueryVariables = {
    type: APIt.TipoDashboardTramiteDaily.objeciones,
    date: {
      between: [fechaInicio, fechaFin],
    },
  };

  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardDailyByTypeAndDateQuery>>(
      graphqlOperation(queryObjeciones, { ...conditions, nextToken })
    );
    if (result?.data?.dashboardDailyByTypeAndDate?.items?.length > 0) {
      const newData = result?.data?.dashboardDailyByTypeAndDate?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardDailyByTypeAndDate?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

const getEstatusEntries = async (fechaInicio: string, fechaFin: string): Promise<any[]> => {
  const conditions: APIt.DashboardDailyByTypeAndDateQueryVariables = {
    type: APIt.TipoDashboardTramiteDaily.estatus,
    date: {
      between: [fechaInicio, fechaFin],
    },
  };

  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardDailyByTypeAndDateQuery>>(
      graphqlOperation(queryEestatus, { ...conditions, nextToken })
    );
    if (result?.data?.dashboardDailyByTypeAndDate?.items?.length > 0) {
      const newData = result?.data?.dashboardDailyByTypeAndDate?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardDailyByTypeAndDate?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

export { getEfectividadEntries, getSometimientosEntries, getObjecionessEntries, getEstatusEntries };
