import { Grid } from "@mui/material";
import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useTramitesSometimientosData } from "features/Dashboard/useDashboardData";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { useEffect, useState } from "react";
import { isAfter, parse } from "date-fns";
import SearchBar from "../SearchBar";
import Total from "./Total";
import PorPais from "./PorPais";
import PorIniciativa from "./PorIniciativa";
import PorTipo from "./PorTipo";

function Sometimientos(): JSX.Element {
  const [dbData, loading] = useTramitesSometimientosData();
  const { dashboardFilterState } = useDashboardFilters();
  const { paises, businessUnits } = dashboardFilterState;

  // IN MEMORY FILTER
  const [initialData, setInitialData] = useState([] as DashboardTramiteDaily[]);

  useEffect(() => {
    const filterPais = (tramite: DashboardTramiteVO) =>
      !paises || paises.length < 1 || paises.includes(tramite.pais?.codigo);
    const filterBU = (tramite: DashboardTramiteVO) =>
      !businessUnits ||
      businessUnits.length < 1 ||
      businessUnits.some((bu) => tramite.businessUnit?.codigo?.includes(bu));

    const iData = dbData.map((dashboardEntry: DashboardTramiteDaily) => {
      const sometimientosOnTime = dashboardEntry.sometimientosOnTime
        ?.filter(filterPais)
        ?.filter(filterBU);
      const sometimientosVencidos = dashboardEntry.sometimientosVencidos
        ?.filter(filterPais)
        ?.filter(filterBU);
      const casosEnCurso = dashboardEntry.sometimientosEnCurso
        ?.filter(filterPais)
        ?.filter(filterBU);

      let sometimientosEnCurso: any[] = [];
      let sometimientosRetrasados: any[] = [];
      if (isAfter(new Date(), parse(dashboardEntry.date, "yyyy-MM-dd", new Date()))) {
        sometimientosRetrasados = casosEnCurso;
      } else {
        sometimientosEnCurso = casosEnCurso;
      }

      return {
        ...dashboardEntry,
        sometimientosOnTime,
        sometimientosVencidos,
        sometimientosEnCurso,
        sometimientosRetrasados,
      };
    });

    setInitialData(iData);
  }, [paises, businessUnits, dbData]);

  return (
    <Layout>
      <Navbar />
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Sometimientos On Time
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Total initialData={initialData} loading={loading} />
            </Grid>
            <Grid item xs={12} md={8}>
              <PorPais initialData={initialData} loading={loading} />
            </Grid>
            <Grid item xs={12} md={6}>
              <PorIniciativa initialData={initialData} loading={loading} />
            </Grid>
            <Grid item xs={12} md={6}>
              <PorTipo initialData={initialData} loading={loading} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default Sometimientos;
