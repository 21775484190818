import CodigoFormula from "features/CodigoFormula/CodigoFormulaList";
import CodigoFormulaEdit from "features/CodigoFormula/CodigoFormulaEdit";

// @mui icons
import Icon from "@mui/material/Icon";
import TramiteList from "features/Tramite/TramiteList";
import TramiteEdit from "features/Tramite/TramiteEdit";
import { TipoTramite } from "API";
import Import from "features/Importacion/Import";
import RegistroList from "features/Registro/RegistroList";
import ImportFormulas from "features/Importacion/ImportFormulas";
import ImportRegistros from "features/Importacion/ImportRegistros";
import RegistroEdit from "features/Registro/RegistroEdit";
import Efectividad from "features/Dashboard/Tramites/Efectividad";
import Sometimientos from "features/Dashboard/Tramites/Sometimientos";
import Objeciones from "features/Dashboard/Tramites/Objeciones";
import Estatus from "features/Dashboard/Tramites/EstatusInnovaciones";
import Portafolio from "features/Dashboard/Registros/Portafolio";
import Notificaciones from "features/Notificaciones";
// import Creg from "features/Tramite/TramiteList/Creg";

const routes = [
  {
    type: "static",
    key: "import",
    route: "/import",
    component: <Import />,
  },
  {
    type: "static",
    key: "importFormulas",
    route: "/import_formulas",
    component: <ImportFormulas />,
  },
  {
    type: "static",
    key: "importRegistros",
    route: "/import_registros",
    component: <ImportRegistros />,
  },
  {
    type: "collapse",
    name: "Códigos de fórmula",
    key: "codigo-formula",
    route: "/codigo-formula",
    component: <CodigoFormula />,
    noCollapse: true,
    icon: <Icon fontSize="medium">science</Icon>,
  },
  {
    type: "static",
    key: "codigo-formula",
    route: "/codigo-formula/:id",
    component: <CodigoFormulaEdit />,
  },
  {
    type: "static",
    key: "codigo-formula",
    route: "/codigo-formula/nueva",
    component: <CodigoFormulaEdit />,
  },
  {
    type: "collapse",
    name: "Trámites",
    key: "tramites",
    icon: <Icon fontSize="medium">feed</Icon>,
    collapse: [
      {
        name: "Nuevos Registros",
        key: TipoTramite.nuevo,
        route: `/${TipoTramite.nuevo}`,
        component: <TramiteList tipo={TipoTramite.nuevo} />,
      },
      /* {
        name: "Mod. técnicas",
        key: TipoTramite.modificacionTecnica,
        route: `/${TipoTramite.modificacionTecnica}`,
        component: <TramiteList tipo={TipoTramite.modificacionTecnica} />,
      },
      {
        name: "Mod. administrativas",
        key: TipoTramite.modificacionAdministrativa,
        route: `/${TipoTramite.modificacionAdministrativa}`,
        component: <TramiteList tipo={TipoTramite.modificacionAdministrativa} />,
      }, */
      {
        name: "Modificaciones",
        key: TipoTramite.modificacion,
        route: `/${TipoTramite.modificacion}`,
        component: <TramiteList tipo={TipoTramite.modificacion} />,
      },
      {
        name: "Renovaciones",
        key: TipoTramite.renovacion,
        route: `/${TipoTramite.renovacion}`,
        component: <TramiteList tipo={TipoTramite.renovacion} />,
      },
      {
        name: "Publicidad",
        key: TipoTramite.publicidad,
        route: `/${TipoTramite.publicidad}`,
        component: <TramiteList tipo={TipoTramite.publicidad} />,
      },
      {
        name: "Certificados",
        key: TipoTramite.certificados,
        route: `/${TipoTramite.certificados}`,
        component: <TramiteList tipo={TipoTramite.certificados} />,
      },
      {
        name: "Vigilancia",
        key: TipoTramite.vigilanciaSanitaria,
        route: `/${TipoTramite.vigilanciaSanitaria}`,
        component: <TramiteList tipo={TipoTramite.vigilanciaSanitaria} />,
      },
      {
        name: "Otros",
        key: TipoTramite.otros,
        route: `/${TipoTramite.otros}`,
        component: <TramiteList tipo={TipoTramite.otros} />,
      },
      /* {
        name: "Reconocimientos",
        key: TipoTramite.reconocimiento,
        route: `/${TipoTramite.reconocimiento}`,
        component: <TramiteList tipo={TipoTramite.reconocimiento} />,
      }, */
      {
        name: "Todos",
        key: "todos",
        route: "/todos",
        component: <TramiteList tipo={null} />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Registros",
    key: "registros-sanitarios",
    route: "/registros-sanitarios",
    component: <RegistroList />,
    noCollapse: true,
    icon: <Icon fontSize="medium">gavel</Icon>,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">leaderboard</Icon>,
    collapse: [
      {
        name: "Efectividad de trámites",
        key: "efectividad",
        route: `/efectividad`,
        component: <Efectividad />,
      },
      {
        name: "Sometimientos On Time",
        key: "sometimientos",
        route: `/sometimientos`,
        component: <Sometimientos />,
      },
      {
        name: "Total de Objeciones",
        key: "objeciones",
        route: `/objeciones`,
        component: <Objeciones />,
      },
      {
        name: "Estatus de Innovaciones",
        key: "estatus",
        route: `/estatus`,
        component: <Estatus />,
      },
      {
        name: "Comparativa de portafolio",
        key: "portafolio",
        route: `/portafolio`,
        component: <Portafolio />,
      },
    ],
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.nuevo}/:id`,
    component: <TramiteEdit tipo={TipoTramite.nuevo} />,
  },
  /* {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.modificacionTecnica}/:id`,
    component: <TramiteEdit tipo={TipoTramite.modificacionTecnica} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.modificacionAdministrativa}/:id`,
    component: <TramiteEdit tipo={TipoTramite.modificacionAdministrativa} />,
  }, */
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.modificacion}/:id`,
    component: <TramiteEdit tipo={TipoTramite.modificacion} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.renovacion}/:id`,
    component: <TramiteEdit tipo={TipoTramite.renovacion} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.publicidad}/:id`,
    component: <TramiteEdit tipo={TipoTramite.publicidad} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.certificados}/:id`,
    component: <TramiteEdit tipo={TipoTramite.certificados} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.vigilanciaSanitaria}/:id`,
    component: <TramiteEdit tipo={TipoTramite.vigilanciaSanitaria} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.otros}/:id`,
    component: <TramiteEdit tipo={TipoTramite.otros} />,
  },
  {
    type: "static",
    key: "tramite",
    route: `/${TipoTramite.reconocimiento}/:id`,
    component: <TramiteEdit tipo={TipoTramite.reconocimiento} />,
  },
  {
    type: "static",
    key: "tramite",
    route: "/todos/:id",
    component: <TramiteEdit tipo={null} />,
  },
  {
    type: "static",
    key: "registro",
    route: "/registro-sanitario/:id",
    component: <RegistroEdit />,
  },
  {
    type: "static",
    key: "registro",
    route: "/registro-sanitario",
    component: <RegistroEdit />,
  },
  {
    type: "static",
    key: "notificaciones",
    route: "/notificaciones",
    component: <Notificaciones />,
  },
];

export default routes;
