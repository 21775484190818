import { Iniciativa } from "API";
import { useState, useEffect } from "react";

import fetchIniciativaesList from "services/Iniciativa/IniciativaList";

const useIniciativaOptions = (): [Iniciativa[], () => void] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchIniciativaesList().then((result) => {
        const { iniciativas } = result;
        setItems(iniciativas);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useIniciativaOptions;
