import useRegistroCRUD from "features/CodigoFormula/ServiceHooks/useRegistroCRUD";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import * as APIt from "API";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Navbar from "components/tramites/Navbar";
import { Backdrop, Card, CircularProgress, Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import MDButton from "components/md/MDButton";
import DatosGenerales from "features/Registro/RegistroEdit/DatosGenerales";
import DetalleRegistro from "features/Tramite/TramiteEdit/DatosTramite/DetalleTramite/DetalleRegistro";
import { useNotificacionUpdater } from "features/Notificaciones/ServiceHooks/useNotificaciones";
// import { useGroups } from "features/ServiceHooks/useAuthorization";

const firstValidations = {
  numeroRegistro: Yup.string().required("Número de registro requerido").nullable(true),
  vencimientoSanitario: Yup.string().required("Vencimiento sanitario requerido").nullable(true),
  inicioRenovacion: Yup.string().required("Inicio de renovación requerida").nullable(true),
  pais: Yup.object().required("País requerido").nullable(true),
  autoridad: Yup.object().required("Autoridad requerida").nullable(true),
  formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
};
const validations = {
  presentaciones: Yup.array().of(
    Yup.object().shape({
      formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
      producto: Yup.string().required("Producto requerido").nullable(true),
      presentaciones: Yup.string().required("Indique presntaciones").nullable(true),
      fabricantes: Yup.array()
        .of(
          Yup.object().shape({
            fabricante: Yup.object().required("Fabricante requerido").nullable(true),
            acondicionadoresPrimarios: Yup.array()
              .of(
                Yup.object().shape({
                  acondicionadorPrimario: Yup.object()
                    .required("Acondicionador primario requerido")
                    .nullable(true),
                })
              )
              .min(1, "Seleccione al menos un acondicionador"),
          })
        )
        .min(1, "Seleccione al menos un fabricante"),
    })
  ),
  // .min(1, "Indique al menos una presentación"),
  formulas: Yup.array()
    .of(
      Yup.object().shape({
        categoria: Yup.object().required("Categoría requerida").nullable(true),
        bu: Yup.object().required("B.U. requerido").nullable(true),
        formaCosmeticaFarmaceutica: Yup.object()
          .required("Forma cosmética/farmacéutica requerida")
          .nullable(true),
        marca: Yup.string().required("Marca requerida").nullable(true),
        condicionVenta: Yup.object()
          .when("categoria", {
            is: (categoria: APIt.CategoriaVO) => categoria?.codigo === "OTC",
            then: Yup.object().required("Condicion de venta requerida").nullable(true),
          })
          .nullable(true),
        categoriaTerapeutica: Yup.object()
          .when("categoria", {
            is: (categoria: APIt.CategoriaVO) => categoria?.codigo === "OTC",
            then: Yup.object().required("Categoría terapéutica requerida").nullable(true),
          })
          .nullable(true),
        /* principiosActivos: Yup.array().when("categoria", {
            is: (categoria: APIt.CategoriaVO) => categoria?.codigo === "OTC",
            then: Yup.array().of(
              Yup.object().shape({
                fabricantes: Yup.array().min(1, "Seleccione al menos un fabricante"),
              })
            ),
          }), */
      })
    )
    .min(1, "Indique al menos una fórmula"),
};

function RegistroEdit(): JSX.Element {
  // NAVIGATION
  const navigate = useNavigate();
  const back = "/registros-sanitarios";

  const { id: idParam } = useParams();
  const { registro, commitChanges, fetchFormula } = useRegistroCRUD(idParam);

  const [loading, setLoading] = useState(true);
  const [formulas, setFormulas] = useState([] as APIt.CodigoFormula[]);

  // CONTEXT (UPDATE LIST ON SAVE)
  const { dispatch } = useTramitesAppStateContext();
  const [markReaded] = useNotificacionUpdater();

  // MARK NOTIFICATIONS AS READED
  useEffect(() => {
    if (registro?.id) {
      markReaded(registro.id);
    }
  }, [registro?.id]);

  const shape =
    idParam || registro?.id ? { ...firstValidations, ...validations } : firstValidations;
  const formMethods: UseFormReturn<APIt.Registro, any> = useForm({
    resolver: yupResolver(Yup.object().shape(shape)),
    defaultValues: registro,
  });

  const { reset, trigger, getValues } = formMethods;
  const { formulas: watchFormulas } = getValues();

  useEffect(() => {
    setFormulas([]);
    watchFormulas?.forEach((formula: APIt.RegistroFormulaVO) => {
      fetchFormula(formula.id, (result: any) => {
        setFormulas([...formulas, result]);
      });
    });
  }, [watchFormulas]);

  // LOAD Registro FROM URL
  useEffect(() => {
    if (registro?.id) {
      reset(registro);
      setLoading(false);
    }
  }, [registro]);

  // STOP SPINNER IF NO ID
  useEffect(() => {
    if (!idParam) setLoading(false);
  }, []);

  const doCommitChanges = async () => {
    commitChanges(getValues(), (result) => {
      setLoading(false);
      dispatch({
        type: "update-registro-list-entry",
        payload: result,
      });
    });
  };

  const save = () => {
    trigger().then((valid) => {
      if (valid) {
        setLoading(true);
        doCommitChanges();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const saveAndClose = () => {
    trigger().then((valid) => {
      if (valid) {
        doCommitChanges();
        navigate(back);
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const formulaPicked = !!registro?.id;

  return (
    <PageLayout>
      <Navbar isMini isFull />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDBox ml={1}>
              <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
                Edición manual de registro sanitario (Atención! Solo para casos creados en CPT)
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>

        <MDBox mb={3}>
          <Card sx={{ overflow: "visible", paddingRight: 10 }}>
            <MDBox pb={3} px={3} pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DatosGenerales formMethods={formMethods} />
                </Grid>
                {formulaPicked && (
                  <Grid item xs={12}>
                    <DetalleRegistro formMethods={formMethods as any} formulas={formulas} />
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox mb={3}>
          <Card id="basic-info" sx={{ overflow: "visible", paddingRight: 10 }}>
            <Grid container direction="row-reverse" my={2} mr={2} spacing={1}>
              <Grid item>
                <MDButton variant="gradient" color="info" onClick={saveAndClose}>
                  Guardar y salir
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton variant="gradient" color="info" type="submit">
                  Guardar
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton variant="gradient" color="light" onClick={() => navigate(back)}>
                  Salir
                </MDButton>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
      </form>
    </PageLayout>
  );
}

export default RegistroEdit;
