import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listBusinessUnits } from "services/BusinessUnit/queries";

const fetchBusinessUnitsList = async (): Promise<{ businessUnits: APIt.BusinessUnit[] }> => {
  let businessUnits: APIt.BusinessUnit[] = [];

  const conditions: APIt.ListBusinessUnitsQueryVariables = { limit: 20000 };

  const result = (await API.graphql(
    graphqlOperation(listBusinessUnits, conditions)
  )) as GraphQLResult<APIt.ListBusinessUnitsQuery>;

  if (result.data) {
    const resultData: APIt.ListBusinessUnitsQuery = result.data;
    businessUnits = resultData.listBusinessUnits.items;
  }

  return { businessUnits };
};

export default fetchBusinessUnitsList;
