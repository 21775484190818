import { EstadoTramite, TipoTramite } from "API";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useState, useEffect } from "react";
import { downloadExcel } from "services/Export/ExcelExporter";
import tramitesConfig from "services/Export/Tramites/TramitesExportConfig";

import fetchTramitesList from "services/Tramite/TramiteList";

/* eslint-disable */
type RefreshCallback = (overrideFull?: string) => void;
/* eslint-enable */

const useTramiteList = (
  tipo?: TipoTramite,
  estado?: EstadoTramite
): [any[], RefreshCallback, () => void, boolean, boolean, any, any] => {
  const [isLoading, setIsLoading] = useState(false);
  if (4 < 3) console.log(estado);

  const {
    state: { tramiteSearchState },
    dispatch,
  } = useTramitesAppStateContext();

  // get first page
  useEffect(() => {
    dispatch({
      type: "update-tramite-tipo",
      payload: tipo,
    });

    if (tramiteSearchState?.results?.length < 1 || tipo !== tramiteSearchState.tipo) {
      setIsLoading(true);
      fetchTramitesList(null, null, tipo).then(
        (result) => {
          const { page, nextToken } = result;
          dispatch({
            type: "update-tramite-list",
            payload: { page, nextToken },
          });
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  }, [tipo]);

  const fetchNextPage = (overrideFull?: string) => {
    setIsLoading(true);
    const searchState = overrideFull
      ? { ...tramiteSearchState, full: overrideFull }
      : tramiteSearchState;
    fetchTramitesList(tramiteSearchState.nextToken, searchState, tipo).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-tramite-list",
          payload: { page: [...tramiteSearchState.results, ...page], nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const refresh = (overrideFull: string) => {
    setIsLoading(true);
    const searchState =
      overrideFull !== null ? { ...tramiteSearchState, full: overrideFull } : tramiteSearchState;
    fetchTramitesList(null, searchState, tipo).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-tramite-list",
          payload: { page, nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const fetchAll = async (searchState: any) => {
    const allItems: any[] = [];
    let currentToken: any = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      const { page, nextToken } = await fetchTramitesList(currentToken, searchState, tipo, true);

      allItems.push(...page);
      currentToken = nextToken;
    } while (currentToken != null);
    return allItems;
  };

  const exportTramites = (columns: any) => {
    setIsLoading(true);
    fetchAll(tramiteSearchState).then((results) => {
      setIsLoading(false);
      downloadExcel(results, tramitesConfig, columns);
    });
  };

  return [
    tramiteSearchState.results,
    refresh,
    fetchNextPage,
    isLoading,
    !!tramiteSearchState.nextToken,
    setIsLoading,
    exportTramites,
  ];
};

export default useTramiteList;
