import API, { graphqlOperation } from "@aws-amplify/api";
import { detailedDiff } from "deep-object-diff";
import { Auth } from "aws-amplify";

const createRegistroCambioMutation = `
  mutation CreateRegistroCambio($input: CreateRegistroCambioInput!, $condition: ModelRegistroCambioConditionInput) {
    createRegistroCambio(input: $input, condition: $condition) {
      id
    }
  }
`;

const createRegistroCambio = async (original: any, modified: any, tipo: string) => {
  const diff = detailedDiff(original, modified);

  const userData = await Auth.currentAuthenticatedUser();

  const input = {
    tipo,
    objectId: modified.id,
    usuario: userData.attributes.email,
    cambios: JSON.stringify(diff),
  };

  await API.graphql(graphqlOperation(createRegistroCambioMutation, { input }));
};

export { createRegistroCambio, createRegistroCambioMutation };
