import { DashboardObjecionVO, DashboardTramiteDaily } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { dashboardColors } from "globalvars";

const useData = (initialData: any[]) => {
  const { dashboardFilterState } = useDashboardFilters();
  const { paises } = dashboardFilterState;

  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["A Favor", "Rechazadas", "En Curso", "Desistidas"];

  let header = "Por País - Sin datos";

  if (initialData?.length > 0) {
    // ADD PAIS LABELS
    const labels: string[] = [];
    initialData.forEach((entry: DashboardTramiteDaily) => {
      const addPaisLabel = (objecion: DashboardObjecionVO) => {
        if (labels.includes(objecion.tramite.pais.nombre)) return;

        if (paises && paises.length > 0 && !paises.includes(objecion.tramite.pais.codigo)) return;

        labels.push(objecion.tramite.pais.nombre);
      };

      entry.objecionesAFavor?.forEach(addPaisLabel);
      entry.objecionesEnContra?.forEach(addPaisLabel);
      entry.objecionesEnCurso?.forEach(addPaisLabel);
      entry.objecionesDesistidas?.forEach(addPaisLabel);
    });

    // ADD DATASETS
    // -- Aprobados
    const tmpAFavorData: any = {};
    const tmpEnContraData: any = {};
    const tmpEnCursoData: any = {};
    const tmpDesisitdasData: any = {};
    initialData.forEach((entry: any) => {
      entry.objecionesAFavor?.forEach((objecion: DashboardObjecionVO) => {
        if (!tmpAFavorData[objecion.tramite.pais.nombre])
          tmpAFavorData[objecion.tramite.pais.nombre] = 0;
        tmpAFavorData[objecion.tramite.pais.nombre] += 1;
      });
      entry.objecionesEnContra?.forEach((objecion: DashboardObjecionVO) => {
        if (!tmpEnContraData[objecion.tramite.pais.nombre])
          tmpEnContraData[objecion.tramite.pais.nombre] = 0;
        tmpEnContraData[objecion.tramite.pais.nombre] += 1;
      });
      entry.objecionesEnCurso?.forEach((objecion: DashboardObjecionVO) => {
        if (!tmpEnCursoData[objecion.tramite.pais.nombre])
          tmpEnCursoData[objecion.tramite.pais.nombre] = 0;
        tmpEnCursoData[objecion.tramite.pais.nombre] += 1;
      });
      entry.objecionesDesistidas?.forEach((objecion: DashboardObjecionVO) => {
        if (!tmpDesisitdasData[objecion.tramite.pais.nombre])
          tmpDesisitdasData[objecion.tramite.pais.nombre] = 0;
        tmpDesisitdasData[objecion.tramite.pais.nombre] += 1;
      });
    });

    const datasetAFavor = {
      label: "A Favor",
      data: labels.map((pais: string) => tmpAFavorData[pais] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetAFavor);

    const datasetEnContra = {
      label: "Rechazadas",
      data: labels.map((pais: string) => tmpEnContraData[pais] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetEnContra);

    const datasetEnCurso = {
      label: "En Curso",
      data: labels.map((pais: string) => tmpEnCursoData[pais] || 0),
      backgroundColor: dashboardColors.yellow.background,
    };
    data.datasets.push(datasetEnCurso);

    const datasetDesistidas = {
      label: "Desistidas",
      data: labels.map((pais: string) => tmpDesisitdasData[pais] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetDesistidas);

    data.labels = labels.map((pais: string) => {
      const aFavor = tmpAFavorData[pais] || 0;
      const rechazadas = tmpEnContraData[pais] || 0;

      const total = aFavor + rechazadas;
      let percentage = (aFavor / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${pais} (${percentage.toFixed(2)}%)`;
    });

    header = "Por País";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
