import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listCategorias } from "services/Categoria/queries";

const fetchCategoriasList = async (): Promise<{ categorias: APIt.Categoria[] }> => {
  let categorias: APIt.Categoria[] = [];

  const conditions: APIt.ListCategoriasQueryVariables = { limit: 20000 };

  const result = (await API.graphql(
    graphqlOperation(listCategorias, conditions)
  )) as GraphQLResult<APIt.ListCategoriasQuery>;

  if (result.data) {
    const resultData: APIt.ListCategoriasQuery = result.data;
    categorias = resultData.listCategorias.items;
  }

  return { categorias };
};

export default fetchCategoriasList;
