import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { nanoid } from "nanoid";

import * as APIt from "API";
import { removeKey } from "globalvars";
import { getTramite } from "services/Tramite/queries";
import * as DossierUtils from "services/Utils/DossierUtils";
import { saveTramite } from "graphql/mutations";

export const listTramites = /* GraphQL */ `
  query ListTramites($filter: ModelTramiteSearchFilterInput, $limit: Int, $nextToken: String) {
    listTramiteSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const searchTramites = /* GraphQL */ `
  query ListTramites(
    $filter: SearchableTramiteSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableTramiteSearchSortInput]
  ) {
    searchTramiteSearches(sort: $sort, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

const fetchTramite = async (id: string): Promise<APIt.Tramite> => {
  let tramite: APIt.Tramite = null;
  const params: APIt.GetTramiteQueryVariables = { id };

  const result = (await API.graphql({
    query: getTramite,
    variables: params,
  })) as GraphQLResult<APIt.GetTramiteQuery>;
  if (result.data) {
    const query: APIt.GetTramiteQuery = result.data;
    if (query.getTramite) {
      tramite = query.getTramite as APIt.Tramite;
    }
  }
  return tramite;
};

/* async function findTramiteSearch(id: string) {
  const query = amplifyMock ? listTramites : searchTramites;
  const conditions: APIt.ListTramiteSearchesQueryVariables = {
    filter: { tramiteId: { eq: id } },
  };

  const result = (await API.graphql(graphqlOperation(query, conditions))) as any;

  const { data } = result;
  const entries: APIt.TramiteSearch[] = amplifyMock
    ? data.listTramiteSearches.items
    : data.searchTramiteSearches.items;

  return entries?.at(0);
}

const deleteTramite = async (tramiteId: string) => {
  const updateRequest = (await API.graphql(
    graphqlOperation(
      `
    mutation UpdateTramite($input: UpdateTramiteInput!, $condition: ModelTramiteConditionInput) {
      updateTramite(input: $input, condition: $condition) {
        id
      }
    }
  `,
      { input: { id: tramiteId, deleted: true } }
    )
  )) as GraphQLResult<APIt.UpdateTramiteMutation>;
  if (!updateRequest?.data?.updateTramite?.id) {
    console.log(updateRequest?.errors);
    throw new Error("No se pudo borrar el trámite");
  }

  const tramiteSearch = await findTramiteSearch(tramiteId);
  console.log(tramiteSearch);

  const updateSearchRequest = (await API.graphql(
    graphqlOperation(
      `
    mutation UpdateTramiteSearch($input: UpdateTramiteSearchInput!, $condition: ModelTramiteSearchConditionInput) {
      updateTramiteSearch(input: $input, condition: $condition) {
        id
      }
    }
  `,
      { input: { id: tramiteSearch.id, deleted: true } }
    )
  )) as GraphQLResult<APIt.UpdateTramiteSearchMutation>;
  if (!updateSearchRequest?.data?.updateTramiteSearch?.id) {
    console.log(updateRequest?.errors);
    throw new Error("No se pudo borrar el trámite");
  }
}; */
const deleteTramite = async (tramiteId: string) => {
  const tramite = await fetchTramite(tramiteId);

  const body = (await API.graphql(
    graphqlOperation(saveTramite, {
      input: { tramite: JSON.stringify({ ...tramite, deleted: true }) },
    })
  )) as GraphQLResult<APIt.SaveTramiteMutation>;
  const retId = body.data.saveTramite?.objectId;
  if (!retId) {
    console.log(body?.errors);
    throw new Error("No se pudo borrar el trámite");
  }
};

const getObjecionEstado = (tramite: APIt.Tramite) => {
  let estadoUpdate: APIt.EstadoTramite = tramite.estado;
  if ([APIt.EstadoTramite.objetado, APIt.EstadoTramite.presentado].includes(tramite.estado)) {
    const wasObjetado = tramite.estado === APIt.EstadoTramite.objetado;
    estadoUpdate = null;
    tramite.objeciones?.forEach((objecion: any) => {
      if (
        objecion.estado === APIt.EstadoObjecion.pendiente ||
        objecion.estado === APIt.EstadoObjecion.respondida
      ) {
        estadoUpdate = APIt.EstadoTramite.objetado;
      }
    });
    if (estadoUpdate === null && wasObjetado) estadoUpdate = APIt.EstadoTramite.presentado;
    else if (estadoUpdate === null) estadoUpdate = tramite.estado;
  }

  return estadoUpdate;
};

const setDefaultDossierPreferences = (tramite: APIt.Tramite) => {
  const codigoFormulas = tramite.formulas?.map((x) => ({ id: x.id, dossierData: [] })) || [];

  const { tramiteDossierPreferencesObj, formulaDossierDataArray, newDossierData } =
    DossierUtils.prepareDossierPreferences(
      tramite.formulas,
      null,
      tramite.pais.codigo,
      tramite.tipo,
      [],
      codigoFormulas as APIt.CodigoFormula[]
    );

  const newFormulas = [...codigoFormulas];
  formulaDossierDataArray.forEach((x, i) => {
    newFormulas[i].dossierData = x;
  });
  const newTramite = {
    ...tramite,
    dossierData: newDossierData,
    dossierPreferences: JSON.stringify(tramiteDossierPreferencesObj),
  };

  return { newTramite, newFormulas };
};

const updateTramite = async (
  tramite: APIt.Tramite,
  formulasDossier: APIt.CodigoFormula[]
): Promise<string> => {
  const tramiteSinKeys = removeKey(tramite, "arrayKey");
  let { id: retId } = tramiteSinKeys;

  // GET STATUS FROM OBJECIONES
  const estadoUpdate = getObjecionEstado(tramite);

  // SET USER
  const userData = await Auth.currentAuthenticatedUser();
  if (!tramiteSinKeys.createdBy) {
    tramiteSinKeys.createdBy = userData.attributes.email;
  }
  tramiteSinKeys.modifiedBy = userData.attributes.email;

  // SET DOSSIER DATA FOR FORMULA UPDATE
  tramiteSinKeys.formulaDossierData =
    formulasDossier?.map((formula) => ({
      id: formula.id,
      dossierData: formula.dossierData || [],
    })) || [];

  // SET DEFAULT DOSSIER PREFERENCES
  if (!tramiteSinKeys.dossierPreferences) {
    const { newTramite, newFormulas } = setDefaultDossierPreferences(tramiteSinKeys);

    const { dossierData, dossierPreferences } = newTramite;
    tramiteSinKeys.dossierData = dossierData;
    tramiteSinKeys.dossierPreferences = dossierPreferences;

    if (!formulasDossier || formulasDossier.length < 1) {
      tramiteSinKeys.formulaDossierData =
        newFormulas?.map((formula) => ({
          id: formula.id,
          dossierData: formula.dossierData || [],
        })) || [];
    }
  }

  // SET DEFAULT CODIGO CPT
  let newCodigoCPT = tramite.codigoCPT;
  if (!newCodigoCPT) {
    newCodigoCPT = `${tramite.tipo.substring(3, 0)}-${nanoid(10)}`;
  }

  // SAVE TRAMITE
  const input = {
    tramite: JSON.stringify({
      ...tramiteSinKeys,
      estado: estadoUpdate,
      codigoCPT: newCodigoCPT,
    }),
  };
  const body = (await API.graphql(
    graphqlOperation(saveTramite, {
      input,
    })
  )) as GraphQLResult<APIt.SaveTramiteMutation>;
  retId = body.data.saveTramite?.objectId;

  console.log(body);
  return retId;
};

export { fetchTramite, updateTramite, deleteTramite };
