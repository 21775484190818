/* tslint:disable */
/* eslint-disable */

import { Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDInput from "components/md/MDInput";
import MDProgress from "components/md/MDProgress";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useState } from "react";
import * as Service from "services/Importacion/ImportacionCRUD";

function ImportRegistros(): JSX.Element {
  const [Progress, setProgress] = useState({ done: 0, total: 0 });

  const [registros, setregistros] = useState("");
  const [registrosFormulas, setregistrosFormulas] = useState("");
  const [registrosFormulasPrincipios, setregistrosFormulasPrincipios] = useState("");
  const [registrosPresentaciones, setregistrosPresentaciones] = useState("");
  const [registrosPresentacionesAcondicionadores, setregistrosPresentacionesAcondicionadores] =
    useState("");
  const [
    registrosPresentacionesAcondicionadoresData,
    setregistrosPresentacionesAcondicionadoresData,
  ] = useState("");

  const tuto = {
    registros,
    registrosFormulas,
    registrosFormulasPrincipios,
    registrosPresentaciones,
    registrosPresentacionesAcondicionadores,
    registrosPresentacionesAcondicionadoresData,
  };

  const ImportRegistros = () => {
    Service.importRegistros(tuto, setProgress);
  };

  return (
    <Layout>
      <Navbar />
      <MDBox>
        <Grid container spacing={4}>
          <Grid item sm={2}>
            Registros:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setregistros(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            Registros / Formulas:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setregistrosFormulas(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            Registros / Formulas / Principios:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setregistrosFormulasPrincipios(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            Registros / Presentaciones:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setregistrosPresentaciones(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            Registros / Presentaciones / Acondicionadores:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setregistrosPresentacionesAcondicionadores(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            Registros / Presentaciones / Acondicionadores / Data:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setregistrosPresentacionesAcondicionadoresData(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            <MDButton onClick={ImportRegistros}>Importar</MDButton>
          </Grid>
          <Grid item sm={10}>
            <MDProgress
              variant="gradient"
              value={parseFloat(((Progress.done / Progress.total) * 100).toFixed(2)) || 0}
              label
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={12}> </MDBox>
    </Layout>
  );
}
export default ImportRegistros;
