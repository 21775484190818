/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCodigoFormulaSearch = /* GraphQL */ `
  mutation CreateCodigoFormulaSearch(
    $input: CreateCodigoFormulaSearchInput!
    $condition: ModelCodigoFormulaSearchConditionInput
  ) {
    createCodigoFormulaSearch(input: $input, condition: $condition) {
      id
      codigoFormula {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        producto
        presentaciones
        categoria {
          id
          codigo
          nombre
          __typename
        }
        fabricantes {
          id
          nombre
          direccion
          vencimientoBPM
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        tramites {
          id
          tipo
          estado
          dossierCompleteness
          __typename
        }
        registros {
          id
          vencimientoSanitario
          inicioRenovacion
          numeroRegistro
          __typename
        }
        dossierData {
          codigo
          done
          __typename
        }
        dossierCompleteness
        historico
        deleted
        createdBy
        modifiedBy
        createdAt
        updatedAt
        __typename
      }
      codigoFormulaId
      historico
      deleted
      searchCodigo
      searchMarca
      searchProducto
      searchBu
      searchCategoriaTerapeutica
      searchFormaCosmeticaFarmaceutica
      searchCategoria
      searchPrincipiosActivos
      searchSourcingUnits
      searchFabricantes
      searchPaisesDestino
      searchPresentaciones
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCodigoFormulaSearch = /* GraphQL */ `
  mutation UpdateCodigoFormulaSearch(
    $input: UpdateCodigoFormulaSearchInput!
    $condition: ModelCodigoFormulaSearchConditionInput
  ) {
    updateCodigoFormulaSearch(input: $input, condition: $condition) {
      id
      codigoFormula {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        producto
        presentaciones
        categoria {
          id
          codigo
          nombre
          __typename
        }
        fabricantes {
          id
          nombre
          direccion
          vencimientoBPM
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        tramites {
          id
          tipo
          estado
          dossierCompleteness
          __typename
        }
        registros {
          id
          vencimientoSanitario
          inicioRenovacion
          numeroRegistro
          __typename
        }
        dossierData {
          codigo
          done
          __typename
        }
        dossierCompleteness
        historico
        deleted
        createdBy
        modifiedBy
        createdAt
        updatedAt
        __typename
      }
      codigoFormulaId
      historico
      deleted
      searchCodigo
      searchMarca
      searchProducto
      searchBu
      searchCategoriaTerapeutica
      searchFormaCosmeticaFarmaceutica
      searchCategoria
      searchPrincipiosActivos
      searchSourcingUnits
      searchFabricantes
      searchPaisesDestino
      searchPresentaciones
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCodigoFormulaSearch = /* GraphQL */ `
  mutation DeleteCodigoFormulaSearch(
    $input: DeleteCodigoFormulaSearchInput!
    $condition: ModelCodigoFormulaSearchConditionInput
  ) {
    deleteCodigoFormulaSearch(input: $input, condition: $condition) {
      id
      codigoFormula {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        producto
        presentaciones
        categoria {
          id
          codigo
          nombre
          __typename
        }
        fabricantes {
          id
          nombre
          direccion
          vencimientoBPM
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        tramites {
          id
          tipo
          estado
          dossierCompleteness
          __typename
        }
        registros {
          id
          vencimientoSanitario
          inicioRenovacion
          numeroRegistro
          __typename
        }
        dossierData {
          codigo
          done
          __typename
        }
        dossierCompleteness
        historico
        deleted
        createdBy
        modifiedBy
        createdAt
        updatedAt
        __typename
      }
      codigoFormulaId
      historico
      deleted
      searchCodigo
      searchMarca
      searchProducto
      searchBu
      searchCategoriaTerapeutica
      searchFormaCosmeticaFarmaceutica
      searchCategoria
      searchPrincipiosActivos
      searchSourcingUnits
      searchFabricantes
      searchPaisesDestino
      searchPresentaciones
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCodigoFormula = /* GraphQL */ `
  mutation CreateCodigoFormula(
    $input: CreateCodigoFormulaInput!
    $condition: ModelCodigoFormulaConditionInput
  ) {
    createCodigoFormula(input: $input, condition: $condition) {
      id
      codigo
      marca
      bu {
        id
        codigo
        nombre
        __typename
      }
      categoriaTerapeutica {
        id
        nombre
        __typename
      }
      formaCosmeticaFarmaceutica {
        id
        nombre
        __typename
      }
      producto
      presentaciones
      categoria {
        id
        codigo
        nombre
        __typename
      }
      fabricantes {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        nombre
        direccion
        vencimientoBPM
        __typename
      }
      principiosActivos {
        principio {
          id
          nombre
          __typename
        }
        concentracion
        fabricantes {
          id
          nombre
          direccion
          vencimientoBPM
          __typename
        }
        __typename
      }
      tramites {
        id
        tipo
        estado
        pais {
          id
          codigo
          nombre
          __typename
        }
        dossierCompleteness
        __typename
      }
      registros {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
        __typename
      }
      dossierData {
        codigo
        done
        __typename
      }
      dossierCompleteness
      historico
      deleted
      createdBy
      modifiedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCodigoFormula = /* GraphQL */ `
  mutation UpdateCodigoFormula(
    $input: UpdateCodigoFormulaInput!
    $condition: ModelCodigoFormulaConditionInput
  ) {
    updateCodigoFormula(input: $input, condition: $condition) {
      id
      codigo
      marca
      bu {
        id
        codigo
        nombre
        __typename
      }
      categoriaTerapeutica {
        id
        nombre
        __typename
      }
      formaCosmeticaFarmaceutica {
        id
        nombre
        __typename
      }
      producto
      presentaciones
      categoria {
        id
        codigo
        nombre
        __typename
      }
      fabricantes {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        nombre
        direccion
        vencimientoBPM
        __typename
      }
      principiosActivos {
        principio {
          id
          nombre
          __typename
        }
        concentracion
        fabricantes {
          id
          nombre
          direccion
          vencimientoBPM
          __typename
        }
        __typename
      }
      tramites {
        id
        tipo
        estado
        pais {
          id
          codigo
          nombre
          __typename
        }
        dossierCompleteness
        __typename
      }
      registros {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
        __typename
      }
      dossierData {
        codigo
        done
        __typename
      }
      dossierCompleteness
      historico
      deleted
      createdBy
      modifiedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCodigoFormula = /* GraphQL */ `
  mutation DeleteCodigoFormula(
    $input: DeleteCodigoFormulaInput!
    $condition: ModelCodigoFormulaConditionInput
  ) {
    deleteCodigoFormula(input: $input, condition: $condition) {
      id
      codigo
      marca
      bu {
        id
        codigo
        nombre
        __typename
      }
      categoriaTerapeutica {
        id
        nombre
        __typename
      }
      formaCosmeticaFarmaceutica {
        id
        nombre
        __typename
      }
      producto
      presentaciones
      categoria {
        id
        codigo
        nombre
        __typename
      }
      fabricantes {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        nombre
        direccion
        vencimientoBPM
        __typename
      }
      principiosActivos {
        principio {
          id
          nombre
          __typename
        }
        concentracion
        fabricantes {
          id
          nombre
          direccion
          vencimientoBPM
          __typename
        }
        __typename
      }
      tramites {
        id
        tipo
        estado
        pais {
          id
          codigo
          nombre
          __typename
        }
        dossierCompleteness
        __typename
      }
      registros {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
        __typename
      }
      dossierData {
        codigo
        done
        __typename
      }
      dossierCompleteness
      historico
      deleted
      createdBy
      modifiedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTramiteSearch = /* GraphQL */ `
  mutation CreateTramiteSearch(
    $input: CreateTramiteSearchInput!
    $condition: ModelTramiteSearchConditionInput
  ) {
    createTramiteSearch(input: $input, condition: $condition) {
      id
      tipo
      estado
      tramite {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        paisReceptor {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        descripcion
        tipo
        innovacion
        estado
        fechaPresentacion
        fechaPresentacionObjetivo
        fechaObjetivo
        fechaObtencion
        fechaLanzamiento
        fechaLanzamientoObjetivo
        fechaLiberacion
        fechaLiberacionObjetivo
        iniciativa {
          id
          nombre
          __typename
        }
        prioridad {
          id
          nombre
          __typename
        }
        costosDirectos
        costosIndirectos
        comentarios
        rechazoMotivo
        rechazoRA
        numeroRegistro
        vencimientoSanitario
        medioDifusion {
          id
          nombre
          __typename
        }
        tipoCertificado {
          id
          nombre
          __typename
        }
        objeciones {
          id
          estado
          objecion
          asignableRA
          fechaIngreso
          fechaObjecion
          limiteEspera
          descripcion
          fechaRespuesta
          exitoso
          fechaCierre
          __typename
        }
        formulas {
          id
          codigo
          marca
          __typename
        }
        presentaciones {
          presentaciones
          producto
          formulas
          __typename
        }
        registroModificado {
          id
          vencimientoSanitario
          inicioRenovacion
          numeroRegistro
          __typename
        }
        dossierPreferences
        dossierCompleteness
        dossierData {
          codigo
          done
          __typename
        }
        historico
        deleted
        codigoCPT
        etiqueta
        createdBy
        modifiedBy
        formulaDossierData {
          id
          __typename
        }
        updatedAt
        notificacionesWatchList
        createdAt
        __typename
      }
      tramiteId
      historico
      deleted
      updatedAt
      searchIniciativa
      searchPaisDestino
      searchCodigos
      searchMarcas
      searchBus
      searchCategoriasTerapeuticas
      searchFormasCosmeticasFarmaceuticas
      searchCategorias
      searchPrincipiosActivos
      searchSourcingUnits
      searchProductos
      searchPresentaciones
      searchFabricantes
      searchEtiqueta
      searchCodigoCPT
      createdAt
      __typename
    }
  }
`;
export const updateTramiteSearch = /* GraphQL */ `
  mutation UpdateTramiteSearch(
    $input: UpdateTramiteSearchInput!
    $condition: ModelTramiteSearchConditionInput
  ) {
    updateTramiteSearch(input: $input, condition: $condition) {
      id
      tipo
      estado
      tramite {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        paisReceptor {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        descripcion
        tipo
        innovacion
        estado
        fechaPresentacion
        fechaPresentacionObjetivo
        fechaObjetivo
        fechaObtencion
        fechaLanzamiento
        fechaLanzamientoObjetivo
        fechaLiberacion
        fechaLiberacionObjetivo
        iniciativa {
          id
          nombre
          __typename
        }
        prioridad {
          id
          nombre
          __typename
        }
        costosDirectos
        costosIndirectos
        comentarios
        rechazoMotivo
        rechazoRA
        numeroRegistro
        vencimientoSanitario
        medioDifusion {
          id
          nombre
          __typename
        }
        tipoCertificado {
          id
          nombre
          __typename
        }
        objeciones {
          id
          estado
          objecion
          asignableRA
          fechaIngreso
          fechaObjecion
          limiteEspera
          descripcion
          fechaRespuesta
          exitoso
          fechaCierre
          __typename
        }
        formulas {
          id
          codigo
          marca
          __typename
        }
        presentaciones {
          presentaciones
          producto
          formulas
          __typename
        }
        registroModificado {
          id
          vencimientoSanitario
          inicioRenovacion
          numeroRegistro
          __typename
        }
        dossierPreferences
        dossierCompleteness
        dossierData {
          codigo
          done
          __typename
        }
        historico
        deleted
        codigoCPT
        etiqueta
        createdBy
        modifiedBy
        formulaDossierData {
          id
          __typename
        }
        updatedAt
        notificacionesWatchList
        createdAt
        __typename
      }
      tramiteId
      historico
      deleted
      updatedAt
      searchIniciativa
      searchPaisDestino
      searchCodigos
      searchMarcas
      searchBus
      searchCategoriasTerapeuticas
      searchFormasCosmeticasFarmaceuticas
      searchCategorias
      searchPrincipiosActivos
      searchSourcingUnits
      searchProductos
      searchPresentaciones
      searchFabricantes
      searchEtiqueta
      searchCodigoCPT
      createdAt
      __typename
    }
  }
`;
export const deleteTramiteSearch = /* GraphQL */ `
  mutation DeleteTramiteSearch(
    $input: DeleteTramiteSearchInput!
    $condition: ModelTramiteSearchConditionInput
  ) {
    deleteTramiteSearch(input: $input, condition: $condition) {
      id
      tipo
      estado
      tramite {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        paisReceptor {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        descripcion
        tipo
        innovacion
        estado
        fechaPresentacion
        fechaPresentacionObjetivo
        fechaObjetivo
        fechaObtencion
        fechaLanzamiento
        fechaLanzamientoObjetivo
        fechaLiberacion
        fechaLiberacionObjetivo
        iniciativa {
          id
          nombre
          __typename
        }
        prioridad {
          id
          nombre
          __typename
        }
        costosDirectos
        costosIndirectos
        comentarios
        rechazoMotivo
        rechazoRA
        numeroRegistro
        vencimientoSanitario
        medioDifusion {
          id
          nombre
          __typename
        }
        tipoCertificado {
          id
          nombre
          __typename
        }
        objeciones {
          id
          estado
          objecion
          asignableRA
          fechaIngreso
          fechaObjecion
          limiteEspera
          descripcion
          fechaRespuesta
          exitoso
          fechaCierre
          __typename
        }
        formulas {
          id
          codigo
          marca
          __typename
        }
        presentaciones {
          presentaciones
          producto
          formulas
          __typename
        }
        registroModificado {
          id
          vencimientoSanitario
          inicioRenovacion
          numeroRegistro
          __typename
        }
        dossierPreferences
        dossierCompleteness
        dossierData {
          codigo
          done
          __typename
        }
        historico
        deleted
        codigoCPT
        etiqueta
        createdBy
        modifiedBy
        formulaDossierData {
          id
          __typename
        }
        updatedAt
        notificacionesWatchList
        createdAt
        __typename
      }
      tramiteId
      historico
      deleted
      updatedAt
      searchIniciativa
      searchPaisDestino
      searchCodigos
      searchMarcas
      searchBus
      searchCategoriasTerapeuticas
      searchFormasCosmeticasFarmaceuticas
      searchCategorias
      searchPrincipiosActivos
      searchSourcingUnits
      searchProductos
      searchPresentaciones
      searchFabricantes
      searchEtiqueta
      searchCodigoCPT
      createdAt
      __typename
    }
  }
`;
export const createTramite = /* GraphQL */ `
  mutation CreateTramite(
    $input: CreateTramiteInput!
    $condition: ModelTramiteConditionInput
  ) {
    createTramite(input: $input, condition: $condition) {
      id
      pais {
        id
        codigo
        nombre
        __typename
      }
      paisReceptor {
        id
        codigo
        nombre
        __typename
      }
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
          __typename
        }
        __typename
      }
      descripcion
      tipo
      innovacion
      estado
      fechaPresentacion
      fechaPresentacionObjetivo
      fechaObjetivo
      fechaObtencion
      fechaLanzamiento
      fechaLanzamientoObjetivo
      fechaLiberacion
      fechaLiberacionObjetivo
      iniciativa {
        id
        nombre
        __typename
      }
      prioridad {
        id
        nombre
        __typename
      }
      costosDirectos
      costosIndirectos
      comentarios
      rechazoMotivo
      rechazoRA
      numeroRegistro
      vencimientoSanitario
      medioDifusion {
        id
        nombre
        __typename
      }
      tipoCertificado {
        id
        nombre
        __typename
      }
      objeciones {
        id
        estado
        objecion
        autoridad {
          id
          nombre
          __typename
        }
        asignableRA
        fechaIngreso
        fechaObjecion
        limiteEspera
        descripcion
        fechaRespuesta
        exitoso
        fechaCierre
        __typename
      }
      formulas {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoria {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        condicionVenta {
          id
          nombre
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        __typename
      }
      presentaciones {
        presentaciones
        producto
        fabricantes {
          __typename
        }
        formulas
        __typename
      }
      registroModificado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
        __typename
      }
      dossierPreferences
      dossierCompleteness
      dossierData {
        codigo
        done
        __typename
      }
      historico
      deleted
      codigoCPT
      etiqueta
      createdBy
      modifiedBy
      formulaDossierData {
        id
        dossierData {
          codigo
          done
          __typename
        }
        __typename
      }
      updatedAt
      notificacionesWatchList
      createdAt
      __typename
    }
  }
`;
export const updateTramite = /* GraphQL */ `
  mutation UpdateTramite(
    $input: UpdateTramiteInput!
    $condition: ModelTramiteConditionInput
  ) {
    updateTramite(input: $input, condition: $condition) {
      id
      pais {
        id
        codigo
        nombre
        __typename
      }
      paisReceptor {
        id
        codigo
        nombre
        __typename
      }
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
          __typename
        }
        __typename
      }
      descripcion
      tipo
      innovacion
      estado
      fechaPresentacion
      fechaPresentacionObjetivo
      fechaObjetivo
      fechaObtencion
      fechaLanzamiento
      fechaLanzamientoObjetivo
      fechaLiberacion
      fechaLiberacionObjetivo
      iniciativa {
        id
        nombre
        __typename
      }
      prioridad {
        id
        nombre
        __typename
      }
      costosDirectos
      costosIndirectos
      comentarios
      rechazoMotivo
      rechazoRA
      numeroRegistro
      vencimientoSanitario
      medioDifusion {
        id
        nombre
        __typename
      }
      tipoCertificado {
        id
        nombre
        __typename
      }
      objeciones {
        id
        estado
        objecion
        autoridad {
          id
          nombre
          __typename
        }
        asignableRA
        fechaIngreso
        fechaObjecion
        limiteEspera
        descripcion
        fechaRespuesta
        exitoso
        fechaCierre
        __typename
      }
      formulas {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoria {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        condicionVenta {
          id
          nombre
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        __typename
      }
      presentaciones {
        presentaciones
        producto
        fabricantes {
          __typename
        }
        formulas
        __typename
      }
      registroModificado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
        __typename
      }
      dossierPreferences
      dossierCompleteness
      dossierData {
        codigo
        done
        __typename
      }
      historico
      deleted
      codigoCPT
      etiqueta
      createdBy
      modifiedBy
      formulaDossierData {
        id
        dossierData {
          codigo
          done
          __typename
        }
        __typename
      }
      updatedAt
      notificacionesWatchList
      createdAt
      __typename
    }
  }
`;
export const deleteTramite = /* GraphQL */ `
  mutation DeleteTramite(
    $input: DeleteTramiteInput!
    $condition: ModelTramiteConditionInput
  ) {
    deleteTramite(input: $input, condition: $condition) {
      id
      pais {
        id
        codigo
        nombre
        __typename
      }
      paisReceptor {
        id
        codigo
        nombre
        __typename
      }
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
          __typename
        }
        __typename
      }
      descripcion
      tipo
      innovacion
      estado
      fechaPresentacion
      fechaPresentacionObjetivo
      fechaObjetivo
      fechaObtencion
      fechaLanzamiento
      fechaLanzamientoObjetivo
      fechaLiberacion
      fechaLiberacionObjetivo
      iniciativa {
        id
        nombre
        __typename
      }
      prioridad {
        id
        nombre
        __typename
      }
      costosDirectos
      costosIndirectos
      comentarios
      rechazoMotivo
      rechazoRA
      numeroRegistro
      vencimientoSanitario
      medioDifusion {
        id
        nombre
        __typename
      }
      tipoCertificado {
        id
        nombre
        __typename
      }
      objeciones {
        id
        estado
        objecion
        autoridad {
          id
          nombre
          __typename
        }
        asignableRA
        fechaIngreso
        fechaObjecion
        limiteEspera
        descripcion
        fechaRespuesta
        exitoso
        fechaCierre
        __typename
      }
      formulas {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoria {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        condicionVenta {
          id
          nombre
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        __typename
      }
      presentaciones {
        presentaciones
        producto
        fabricantes {
          __typename
        }
        formulas
        __typename
      }
      registroModificado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
        __typename
      }
      dossierPreferences
      dossierCompleteness
      dossierData {
        codigo
        done
        __typename
      }
      historico
      deleted
      codigoCPT
      etiqueta
      createdBy
      modifiedBy
      formulaDossierData {
        id
        dossierData {
          codigo
          done
          __typename
        }
        __typename
      }
      updatedAt
      notificacionesWatchList
      createdAt
      __typename
    }
  }
`;
export const createRegistroSearch = /* GraphQL */ `
  mutation CreateRegistroSearch(
    $input: CreateRegistroSearchInput!
    $condition: ModelRegistroSearchConditionInput
  ) {
    createRegistroSearch(input: $input, condition: $condition) {
      id
      registro {
        id
        numeroRegistro
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        formulas {
          id
          codigo
          marca
          __typename
        }
        presentaciones {
          presentaciones
          producto
          formulas
          __typename
        }
        historico
        deleted
        createdBy
        modifiedBy
        updatedAt
        notificacionesWatchList
        createdAt
        __typename
      }
      registroId
      historico
      deleted
      searchIniciativa
      searchPaisDestino
      searchCodigos
      searchMarcas
      searchBus
      searchCategoriasTerapeuticas
      searchFormasCosmeticasFarmaceuticas
      searchCategorias
      searchPrincipiosActivos
      searchSourcingUnits
      searchProductos
      searchPresentaciones
      searchFabricantes
      searchRegistro
      searchEstatusVenta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegistroSearch = /* GraphQL */ `
  mutation UpdateRegistroSearch(
    $input: UpdateRegistroSearchInput!
    $condition: ModelRegistroSearchConditionInput
  ) {
    updateRegistroSearch(input: $input, condition: $condition) {
      id
      registro {
        id
        numeroRegistro
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        formulas {
          id
          codigo
          marca
          __typename
        }
        presentaciones {
          presentaciones
          producto
          formulas
          __typename
        }
        historico
        deleted
        createdBy
        modifiedBy
        updatedAt
        notificacionesWatchList
        createdAt
        __typename
      }
      registroId
      historico
      deleted
      searchIniciativa
      searchPaisDestino
      searchCodigos
      searchMarcas
      searchBus
      searchCategoriasTerapeuticas
      searchFormasCosmeticasFarmaceuticas
      searchCategorias
      searchPrincipiosActivos
      searchSourcingUnits
      searchProductos
      searchPresentaciones
      searchFabricantes
      searchRegistro
      searchEstatusVenta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegistroSearch = /* GraphQL */ `
  mutation DeleteRegistroSearch(
    $input: DeleteRegistroSearchInput!
    $condition: ModelRegistroSearchConditionInput
  ) {
    deleteRegistroSearch(input: $input, condition: $condition) {
      id
      registro {
        id
        numeroRegistro
        pais {
          id
          codigo
          nombre
          __typename
        }
        autoridad {
          id
          nombre
          __typename
        }
        vencimientoSanitario
        inicioRenovacion
        formulas {
          id
          codigo
          marca
          __typename
        }
        presentaciones {
          presentaciones
          producto
          formulas
          __typename
        }
        historico
        deleted
        createdBy
        modifiedBy
        updatedAt
        notificacionesWatchList
        createdAt
        __typename
      }
      registroId
      historico
      deleted
      searchIniciativa
      searchPaisDestino
      searchCodigos
      searchMarcas
      searchBus
      searchCategoriasTerapeuticas
      searchFormasCosmeticasFarmaceuticas
      searchCategorias
      searchPrincipiosActivos
      searchSourcingUnits
      searchProductos
      searchPresentaciones
      searchFabricantes
      searchRegistro
      searchEstatusVenta
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistro = /* GraphQL */ `
  mutation CreateRegistro(
    $input: CreateRegistroInput!
    $condition: ModelRegistroConditionInput
  ) {
    createRegistro(input: $input, condition: $condition) {
      id
      numeroRegistro
      pais {
        id
        codigo
        nombre
        __typename
      }
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
          __typename
        }
        __typename
      }
      vencimientoSanitario
      inicioRenovacion
      formulas {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoria {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        condicionVenta {
          id
          nombre
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        __typename
      }
      presentaciones {
        presentaciones
        producto
        fabricantes {
          __typename
        }
        formulas
        __typename
      }
      historico
      deleted
      createdBy
      modifiedBy
      updatedAt
      notificacionesWatchList
      createdAt
      __typename
    }
  }
`;
export const updateRegistro = /* GraphQL */ `
  mutation UpdateRegistro(
    $input: UpdateRegistroInput!
    $condition: ModelRegistroConditionInput
  ) {
    updateRegistro(input: $input, condition: $condition) {
      id
      numeroRegistro
      pais {
        id
        codigo
        nombre
        __typename
      }
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
          __typename
        }
        __typename
      }
      vencimientoSanitario
      inicioRenovacion
      formulas {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoria {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        condicionVenta {
          id
          nombre
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        __typename
      }
      presentaciones {
        presentaciones
        producto
        fabricantes {
          __typename
        }
        formulas
        __typename
      }
      historico
      deleted
      createdBy
      modifiedBy
      updatedAt
      notificacionesWatchList
      createdAt
      __typename
    }
  }
`;
export const deleteRegistro = /* GraphQL */ `
  mutation DeleteRegistro(
    $input: DeleteRegistroInput!
    $condition: ModelRegistroConditionInput
  ) {
    deleteRegistro(input: $input, condition: $condition) {
      id
      numeroRegistro
      pais {
        id
        codigo
        nombre
        __typename
      }
      autoridad {
        id
        nombre
        pais {
          id
          codigo
          nombre
          __typename
        }
        __typename
      }
      vencimientoSanitario
      inicioRenovacion
      formulas {
        id
        codigo
        marca
        bu {
          id
          codigo
          nombre
          __typename
        }
        categoria {
          id
          codigo
          nombre
          __typename
        }
        categoriaTerapeutica {
          id
          nombre
          __typename
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
          __typename
        }
        condicionVenta {
          id
          nombre
          __typename
        }
        principiosActivos {
          concentracion
          __typename
        }
        __typename
      }
      presentaciones {
        presentaciones
        producto
        fabricantes {
          __typename
        }
        formulas
        __typename
      }
      historico
      deleted
      createdBy
      modifiedBy
      updatedAt
      notificacionesWatchList
      createdAt
      __typename
    }
  }
`;
export const createRegistroCambio = /* GraphQL */ `
  mutation CreateRegistroCambio(
    $input: CreateRegistroCambioInput!
    $condition: ModelRegistroCambioConditionInput
  ) {
    createRegistroCambio(input: $input, condition: $condition) {
      id
      tipo
      objectId
      usuario
      cambios
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateRegistroCambio = /* GraphQL */ `
  mutation UpdateRegistroCambio(
    $input: UpdateRegistroCambioInput!
    $condition: ModelRegistroCambioConditionInput
  ) {
    updateRegistroCambio(input: $input, condition: $condition) {
      id
      tipo
      objectId
      usuario
      cambios
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteRegistroCambio = /* GraphQL */ `
  mutation DeleteRegistroCambio(
    $input: DeleteRegistroCambioInput!
    $condition: ModelRegistroCambioConditionInput
  ) {
    deleteRegistroCambio(input: $input, condition: $condition) {
      id
      tipo
      objectId
      usuario
      cambios
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createCategoriaTerapeutica = /* GraphQL */ `
  mutation CreateCategoriaTerapeutica(
    $input: CreateCategoriaTerapeuticaInput!
    $condition: ModelCategoriaTerapeuticaConditionInput
  ) {
    createCategoriaTerapeutica(input: $input, condition: $condition) {
      id
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategoriaTerapeutica = /* GraphQL */ `
  mutation UpdateCategoriaTerapeutica(
    $input: UpdateCategoriaTerapeuticaInput!
    $condition: ModelCategoriaTerapeuticaConditionInput
  ) {
    updateCategoriaTerapeutica(input: $input, condition: $condition) {
      id
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategoriaTerapeutica = /* GraphQL */ `
  mutation DeleteCategoriaTerapeutica(
    $input: DeleteCategoriaTerapeuticaInput!
    $condition: ModelCategoriaTerapeuticaConditionInput
  ) {
    deleteCategoriaTerapeutica(input: $input, condition: $condition) {
      id
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIniciativa = /* GraphQL */ `
  mutation CreateIniciativa(
    $input: CreateIniciativaInput!
    $condition: ModelIniciativaConditionInput
  ) {
    createIniciativa(input: $input, condition: $condition) {
      id
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIniciativa = /* GraphQL */ `
  mutation UpdateIniciativa(
    $input: UpdateIniciativaInput!
    $condition: ModelIniciativaConditionInput
  ) {
    updateIniciativa(input: $input, condition: $condition) {
      id
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIniciativa = /* GraphQL */ `
  mutation DeleteIniciativa(
    $input: DeleteIniciativaInput!
    $condition: ModelIniciativaConditionInput
  ) {
    deleteIniciativa(input: $input, condition: $condition) {
      id
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormaCosmeticaFarmaceutica = /* GraphQL */ `
  mutation CreateFormaCosmeticaFarmaceutica(
    $input: CreateFormaCosmeticaFarmaceuticaInput!
    $condition: ModelFormaCosmeticaFarmaceuticaConditionInput
  ) {
    createFormaCosmeticaFarmaceutica(input: $input, condition: $condition) {
      id
      nombre
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormaCosmeticaFarmaceutica = /* GraphQL */ `
  mutation UpdateFormaCosmeticaFarmaceutica(
    $input: UpdateFormaCosmeticaFarmaceuticaInput!
    $condition: ModelFormaCosmeticaFarmaceuticaConditionInput
  ) {
    updateFormaCosmeticaFarmaceutica(input: $input, condition: $condition) {
      id
      nombre
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormaCosmeticaFarmaceutica = /* GraphQL */ `
  mutation DeleteFormaCosmeticaFarmaceutica(
    $input: DeleteFormaCosmeticaFarmaceuticaInput!
    $condition: ModelFormaCosmeticaFarmaceuticaConditionInput
  ) {
    deleteFormaCosmeticaFarmaceutica(input: $input, condition: $condition) {
      id
      nombre
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBusinessUnit = /* GraphQL */ `
  mutation CreateBusinessUnit(
    $input: CreateBusinessUnitInput!
    $condition: ModelBusinessUnitConditionInput
  ) {
    createBusinessUnit(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBusinessUnit = /* GraphQL */ `
  mutation UpdateBusinessUnit(
    $input: UpdateBusinessUnitInput!
    $condition: ModelBusinessUnitConditionInput
  ) {
    updateBusinessUnit(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBusinessUnit = /* GraphQL */ `
  mutation DeleteBusinessUnit(
    $input: DeleteBusinessUnitInput!
    $condition: ModelBusinessUnitConditionInput
  ) {
    deleteBusinessUnit(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategoria = /* GraphQL */ `
  mutation CreateCategoria(
    $input: CreateCategoriaInput!
    $condition: ModelCategoriaConditionInput
  ) {
    createCategoria(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategoria = /* GraphQL */ `
  mutation UpdateCategoria(
    $input: UpdateCategoriaInput!
    $condition: ModelCategoriaConditionInput
  ) {
    updateCategoria(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategoria = /* GraphQL */ `
  mutation DeleteCategoria(
    $input: DeleteCategoriaInput!
    $condition: ModelCategoriaConditionInput
  ) {
    deleteCategoria(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFabricante = /* GraphQL */ `
  mutation CreateFabricante(
    $input: CreateFabricanteInput!
    $condition: ModelFabricanteConditionInput
  ) {
    createFabricante(input: $input, condition: $condition) {
      id
      pais {
        id
        codigo
        nombre
        createdAt
        updatedAt
        __typename
      }
      paisId
      nombre
      direccion
      vencimientoBPM
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFabricante = /* GraphQL */ `
  mutation UpdateFabricante(
    $input: UpdateFabricanteInput!
    $condition: ModelFabricanteConditionInput
  ) {
    updateFabricante(input: $input, condition: $condition) {
      id
      pais {
        id
        codigo
        nombre
        createdAt
        updatedAt
        __typename
      }
      paisId
      nombre
      direccion
      vencimientoBPM
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFabricante = /* GraphQL */ `
  mutation DeleteFabricante(
    $input: DeleteFabricanteInput!
    $condition: ModelFabricanteConditionInput
  ) {
    deleteFabricante(input: $input, condition: $condition) {
      id
      pais {
        id
        codigo
        nombre
        createdAt
        updatedAt
        __typename
      }
      paisId
      nombre
      direccion
      vencimientoBPM
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPrincipioActivo = /* GraphQL */ `
  mutation CreatePrincipioActivo(
    $input: CreatePrincipioActivoInput!
    $condition: ModelPrincipioActivoConditionInput
  ) {
    createPrincipioActivo(input: $input, condition: $condition) {
      id
      nombre
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePrincipioActivo = /* GraphQL */ `
  mutation UpdatePrincipioActivo(
    $input: UpdatePrincipioActivoInput!
    $condition: ModelPrincipioActivoConditionInput
  ) {
    updatePrincipioActivo(input: $input, condition: $condition) {
      id
      nombre
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePrincipioActivo = /* GraphQL */ `
  mutation DeletePrincipioActivo(
    $input: DeletePrincipioActivoInput!
    $condition: ModelPrincipioActivoConditionInput
  ) {
    deletePrincipioActivo(input: $input, condition: $condition) {
      id
      nombre
      historico
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPais = /* GraphQL */ `
  mutation CreatePais(
    $input: CreatePaisInput!
    $condition: ModelPaisConditionInput
  ) {
    createPais(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePais = /* GraphQL */ `
  mutation UpdatePais(
    $input: UpdatePaisInput!
    $condition: ModelPaisConditionInput
  ) {
    updatePais(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePais = /* GraphQL */ `
  mutation DeletePais(
    $input: DeletePaisInput!
    $condition: ModelPaisConditionInput
  ) {
    deletePais(input: $input, condition: $condition) {
      id
      codigo
      nombre
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAutoridad = /* GraphQL */ `
  mutation CreateAutoridad(
    $input: CreateAutoridadInput!
    $condition: ModelAutoridadConditionInput
  ) {
    createAutoridad(input: $input, condition: $condition) {
      id
      nombre
      pais {
        id
        codigo
        nombre
        createdAt
        updatedAt
        __typename
      }
      paisId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAutoridad = /* GraphQL */ `
  mutation UpdateAutoridad(
    $input: UpdateAutoridadInput!
    $condition: ModelAutoridadConditionInput
  ) {
    updateAutoridad(input: $input, condition: $condition) {
      id
      nombre
      pais {
        id
        codigo
        nombre
        createdAt
        updatedAt
        __typename
      }
      paisId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAutoridad = /* GraphQL */ `
  mutation DeleteAutoridad(
    $input: DeleteAutoridadInput!
    $condition: ModelAutoridadConditionInput
  ) {
    deleteAutoridad(input: $input, condition: $condition) {
      id
      nombre
      pais {
        id
        codigo
        nombre
        createdAt
        updatedAt
        __typename
      }
      paisId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDashboardTramiteDaily = /* GraphQL */ `
  mutation CreateDashboardTramiteDaily(
    $input: CreateDashboardTramiteDailyInput!
    $condition: ModelDashboardTramiteDailyConditionInput
  ) {
    createDashboardTramiteDaily(input: $input, condition: $condition) {
      id
      date
      type
      efectividadAprobados {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadRechazados {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadDesistidos {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadEnCurso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosOnTime {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosVencidos {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosEnCurso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      objecionesEnCurso {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesDesistidas {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesAFavor {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesEnContra {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      estatusArmado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusEnDesarrollo {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusSinDossier {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusEnProceso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusAprobado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusRechazado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusDesistido {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDashboardTramiteDaily = /* GraphQL */ `
  mutation UpdateDashboardTramiteDaily(
    $input: UpdateDashboardTramiteDailyInput!
    $condition: ModelDashboardTramiteDailyConditionInput
  ) {
    updateDashboardTramiteDaily(input: $input, condition: $condition) {
      id
      date
      type
      efectividadAprobados {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadRechazados {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadDesistidos {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadEnCurso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosOnTime {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosVencidos {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosEnCurso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      objecionesEnCurso {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesDesistidas {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesAFavor {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesEnContra {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      estatusArmado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusEnDesarrollo {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusSinDossier {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusEnProceso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusAprobado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusRechazado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusDesistido {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDashboardTramiteDaily = /* GraphQL */ `
  mutation DeleteDashboardTramiteDaily(
    $input: DeleteDashboardTramiteDailyInput!
    $condition: ModelDashboardTramiteDailyConditionInput
  ) {
    deleteDashboardTramiteDaily(input: $input, condition: $condition) {
      id
      date
      type
      efectividadAprobados {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadRechazados {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadDesistidos {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      efectividadEnCurso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosOnTime {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosVencidos {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      sometimientosEnCurso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      objecionesEnCurso {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesDesistidas {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesAFavor {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      objecionesEnContra {
        id
        tramite {
          id
          estado
          tipo
          innovacion
          formula
          __typename
        }
        __typename
      }
      estatusArmado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusEnDesarrollo {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusSinDossier {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusEnProceso {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusAprobado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusRechazado {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      estatusDesistido {
        id
        pais {
          id
          codigo
          nombre
          __typename
        }
        businessUnit {
          id
          codigo
          nombre
          __typename
        }
        estado
        tipo
        innovacion
        iniciativa {
          id
          nombre
          __typename
        }
        formula
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDashboardTramiteYearly = /* GraphQL */ `
  mutation CreateDashboardTramiteYearly(
    $input: CreateDashboardTramiteYearlyInput!
    $condition: ModelDashboardTramiteYearlyConditionInput
  ) {
    createDashboardTramiteYearly(input: $input, condition: $condition) {
      id
      year
      type
      registros
      tramitesAprobados
      tramitesRechazados
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDashboardTramiteYearly = /* GraphQL */ `
  mutation UpdateDashboardTramiteYearly(
    $input: UpdateDashboardTramiteYearlyInput!
    $condition: ModelDashboardTramiteYearlyConditionInput
  ) {
    updateDashboardTramiteYearly(input: $input, condition: $condition) {
      id
      year
      type
      registros
      tramitesAprobados
      tramitesRechazados
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDashboardTramiteYearly = /* GraphQL */ `
  mutation DeleteDashboardTramiteYearly(
    $input: DeleteDashboardTramiteYearlyInput!
    $condition: ModelDashboardTramiteYearlyConditionInput
  ) {
    deleteDashboardTramiteYearly(input: $input, condition: $condition) {
      id
      year
      type
      registros
      tramitesAprobados
      tramitesRechazados
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotificacion = /* GraphQL */ `
  mutation CreateNotificacion(
    $input: CreateNotificacionInput!
    $condition: ModelNotificacionConditionInput
  ) {
    createNotificacion(input: $input, condition: $condition) {
      id
      tipo
      destinatario
      objectId
      objectData {
        id
        codigoTramite
        codigoFormula
        numeroRegistro
        tipoTramite
        fechaLiberacionObjetivo
        fechaPresentacionObjetivo
        fechaObjetivo
        tipoCertificado
        pais
        autoridad
        vencimientoSanitario
        inicioRenovacion
        vencimientoCertificado
        __typename
      }
      enviado
      leido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotificacion = /* GraphQL */ `
  mutation UpdateNotificacion(
    $input: UpdateNotificacionInput!
    $condition: ModelNotificacionConditionInput
  ) {
    updateNotificacion(input: $input, condition: $condition) {
      id
      tipo
      destinatario
      objectId
      objectData {
        id
        codigoTramite
        codigoFormula
        numeroRegistro
        tipoTramite
        fechaLiberacionObjetivo
        fechaPresentacionObjetivo
        fechaObjetivo
        tipoCertificado
        pais
        autoridad
        vencimientoSanitario
        inicioRenovacion
        vencimientoCertificado
        __typename
      }
      enviado
      leido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotificacion = /* GraphQL */ `
  mutation DeleteNotificacion(
    $input: DeleteNotificacionInput!
    $condition: ModelNotificacionConditionInput
  ) {
    deleteNotificacion(input: $input, condition: $condition) {
      id
      tipo
      destinatario
      objectId
      objectData {
        id
        codigoTramite
        codigoFormula
        numeroRegistro
        tipoTramite
        fechaLiberacionObjetivo
        fechaPresentacionObjetivo
        fechaObjetivo
        tipoCertificado
        pais
        autoridad
        vencimientoSanitario
        inicioRenovacion
        vencimientoCertificado
        __typename
      }
      enviado
      leido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserConfig = /* GraphQL */ `
  mutation CreateUserConfig(
    $input: CreateUserConfigInput!
    $condition: ModelUserConfigConditionInput
  ) {
    createUserConfig(input: $input, condition: $condition) {
      id
      email
      rol
      paises
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserConfig = /* GraphQL */ `
  mutation UpdateUserConfig(
    $input: UpdateUserConfigInput!
    $condition: ModelUserConfigConditionInput
  ) {
    updateUserConfig(input: $input, condition: $condition) {
      id
      email
      rol
      paises
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserConfig = /* GraphQL */ `
  mutation DeleteUserConfig(
    $input: DeleteUserConfigInput!
    $condition: ModelUserConfigConditionInput
  ) {
    deleteUserConfig(input: $input, condition: $condition) {
      id
      email
      rol
      paises
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const saveFormula = /* GraphQL */ `
  mutation SaveFormula($input: SaveFormulaInput) {
    saveFormula(input: $input) {
      statusCode
      objectId
      errors
      __typename
    }
  }
`;
export const saveTramite = /* GraphQL */ `
  mutation SaveTramite($input: SaveTramiteInput) {
    saveTramite(input: $input) {
      statusCode
      objectId
      errors
      __typename
    }
  }
`;
export const saveRegistro = /* GraphQL */ `
  mutation SaveRegistro($input: SaveRegistroInput) {
    saveRegistro(input: $input) {
      statusCode
      objectId
      errors
      __typename
    }
  }
`;
