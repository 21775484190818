import { Controller, useFieldArray, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { Autocomplete, Divider, Grid, Icon, TextField } from "@mui/material";
import MDTypography from "components/md/MDTypography";
import MDButton from "components/md/MDButton";
import FormField from "components/tramites/FormField";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import DetallePresentacionFabricante from "./DetallePresentacionFabricante";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
  registroPresentacion: APIt.RegistroPresentacionesVO;
  index: number;
  remove: UseFieldArrayRemove;
};

function DetallePresentacion(params: Props): JSX.Element {
  const { formMethods, registroPresentacion, index, remove: removePresentacion, formulas } = params;
  const {
    control,
    formState: { errors },
    register,
    getValues,
  } = formMethods;

  const { formulas: watchFormulas, estado, pais } = getValues();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `presentaciones.${index}.fabricantes`,
    keyName: "arrayKey",
  });

  const defaultFabricante = {
    acondicionadoresPrimarios: [],
    fabricante: null,
  } as APIt.RegistroFabricanteVO;

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;
  const canEditRegistro = !estado && isUserGlobal;

  return (
    <MDBox px={3} mt={2}>
      <MDBox display="flex" sx={{ mt: 0.7 }}>
        {canEdit && (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            iconOnly
            circular
            sx={{ mt: 4.5, mr: 1 }}
            onClick={() => {
              removePresentacion(index);
            }}
          >
            <Icon sx={{ cursor: "pointer" }}>remove</Icon>
          </MDButton>
        )}
        <Grid container direction="row" spacing={1} pl={2}>
          <Grid container spacing={3} pt={4}>
            <Grid item xs={6}>
              <Controller
                render={(props) => {
                  const renderAutocomplete = () => (
                    <Autocomplete
                      readOnly={!canEdit}
                      multiple
                      options={watchFormulas.map(
                        (codigoFormula: APIt.RegistroFormulaVO) => codigoFormula.codigo
                      )}
                      renderInput={(params) => {
                        const { inputProps } = params;
                        return (
                          <MDBox mb={1.5}>
                            <TextField
                              {...params}
                              name={`presentaciones.${index}.formulas`}
                              label="Códigos de fórmula *"
                              error={
                                !!(
                                  errors.presentaciones &&
                                  errors.presentaciones[index] &&
                                  errors.presentaciones[index].formulas?.message
                                )
                              }
                              inputProps={{
                                ...inputProps,
                                autoComplete: "off",
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {errors.presentaciones &&
                                  errors.presentaciones[index] &&
                                  errors.presentaciones[index].formulas?.message}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        );
                      }}
                      onChange={(_, data) => props.field.onChange(data)}
                      value={props.field.value || []}
                    />
                  );
                  return renderAutocomplete();
                }}
                name={`presentaciones.${index}.formulas`}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Producto *"
                type="text"
                name={`presentaciones.${index}.producto`}
                errors={
                  errors.presentaciones &&
                  errors.presentaciones[index] &&
                  errors.presentaciones[index].producto?.message
                }
                register={register as any}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Presentaciones *"
                type="text"
                name={`presentaciones.${index}.presentaciones`}
                errors={
                  errors.presentaciones &&
                  errors.presentaciones[index] &&
                  errors.presentaciones[index].presentaciones?.message
                }
                register={register as any}
                disabled={!canEdit && !canEditRegistro}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pl={4}>
        <Divider />
        <MDBox display="flex">
          <MDTypography variant="label" fontWeight="medium" sx={{ mt: -0.5 }}>
            Fabricantes
          </MDTypography>
          {canEdit && (
            <MDButton
              variant="outlined"
              color="secondary"
              size="small"
              iconOnly
              circular
              sx={{ mt: -0.4, ml: 1 }}
              onClick={() => {
                append(defaultFabricante);
              }}
            >
              <Icon sx={{ cursor: "pointer" }}>add</Icon>
            </MDButton>
          )}
        </MDBox>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {errors &&
            errors.presentaciones &&
            errors.presentaciones[index] &&
            errors.presentaciones[index].fabricantes?.message}
        </MDTypography>
        {fields.map((registroFabricante: APIt.RegistroFabricanteVO, fabricanteIndex: number) => (
          <DetallePresentacionFabricante
            formMethods={formMethods}
            formulas={formulas}
            remove={remove}
            registroPresentacion={registroPresentacion}
            presentacionIndex={index}
            registroFabricante={registroFabricante}
            fabricanteIndex={fabricanteIndex}
            key={`${registroFabricante.fabricante?.id}.${fabricanteIndex + 1}`}
          />
        ))}
      </MDBox>
    </MDBox>
  );
}

export default DetallePresentacion;
