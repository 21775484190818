import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/md/MDButton";
import { DialogContentText, Divider, Grid, Icon } from "@mui/material";
import NotificationItem from "components/tramites/NotificationItem";
import {
  ItemRenderProps,
  SortableItem,
  SortableItemProps,
  SortableList,
} from "@thaddeusjiang/react-sortable-list";
import MDBox from "components/md/MDBox";
import { IconDragHandle } from "@aws-amplify/ui-react";
import "@thaddeusjiang/react-sortable-list/dist/index.css";

type Props = {
  allColumnDef: any;
  columnOrder: any;
  setColumnOrder: any;
};

function DragHandler(props: any) {
  return (
    <div {...props}>
      <div className="" title="drag handler" style={{ marginTop: 3 }}>
        <IconDragHandle />
      </div>
    </div>
  );
}

function SortColumns(params: Props): JSX.Element {
  const { allColumnDef, columnOrder, setColumnOrder } = params;
  const [open, setOpen] = React.useState(false);

  const [pendingItems, setPendingItems] = React.useState(
    allColumnDef
      .filter((e: any) => !columnOrder.some((c: string) => c === e.accessor))
      .map((e: any) => ({ id: e.accessor, name: e.Header }))
  );
  const [selectedItems, setSelectedItems] = React.useState(
    allColumnDef
      .filter((e: any) => columnOrder.some((c: string) => c === e.accessor))
      .map((e: any) => ({ id: e.accessor, name: e.Header }))
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setColumnOrder(selectedItems.map((e: any) => e.id));
    setOpen(false);
  };

  const addColumn = (item: any) => {
    setSelectedItems([...selectedItems, item]);
    setPendingItems(pendingItems.filter((e: any) => e.id !== item.id));
  };

  const removeColumn = (item: any) => {
    setPendingItems([...pendingItems, item]);
    setSelectedItems(selectedItems.filter((e: any) => e.id !== item.id));
  };

  return (
    <div>
      <MDButton variant="gradient" color="info" onClick={handleClickOpen} title="Ajustar columnas">
        <Icon>tune</Icon>&nbsp; Ajustar columnas
      </MDButton>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle sx={{ width: 900 }}>Seleccione las columnas a visualizar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione los campos que desea incluir en el archivo descargado.
          </DialogContentText>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              Columnas disponibles
              <SortableList
                disabled
                items={pendingItems}
                setItems={setPendingItems}
                // eslint-disable-next-line react/no-unstable-nested-components
                itemRender={({ item }: ItemRenderProps) => (
                  <MDBox display="flex" flexDirection="row" key={item.id}>
                    <NotificationItem icon={null} title={item.name} />
                    <Button onClick={() => addColumn(item)}>{">>"}</Button>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              Columnas seleccionadas
              <SortableList items={selectedItems} setItems={setSelectedItems}>
                {({ items }: any) => (
                  <div className="space-y-4">
                    {items.map((item: SortableItemProps) => (
                      <SortableItem
                        key={item.id}
                        id={item.id}
                        DragHandler={DragHandler}
                        className="flex"
                      >
                        <Grid container>
                          <Grid item xs={11}>
                            <NotificationItem icon={null} title={item.name} />{" "}
                          </Grid>
                          <Grid item xs={1}>
                            <Button onClick={() => removeColumn(item)}>{"<<"}</Button>{" "}
                          </Grid>
                        </Grid>
                      </SortableItem>
                    ))}
                  </div>
                )}
              </SortableList>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SortColumns;
