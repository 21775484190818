import React from "react";
import { Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

type Props = {
  gridXS: number;
  gridSM: number;
  gridLGTop: number;
  gridXLTop: number;
  title: string;
  children?: React.ReactNode;
};

function ReadOnlyField(input: Props): JSX.Element {
  const { gridXS, gridSM, gridLGTop, gridXLTop, title, children } = input;

  return (
    <Grid item xs={gridXS} sm={gridSM} lg={gridLGTop} xl={gridXLTop}>
      <MDBox mb={1.5}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {title}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="regular">
          {children}
        </MDTypography>
      </MDBox>
    </Grid>
  );
}
export default ReadOnlyField;
