/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Autocomplete, DialogContentText, Divider, Grid, Switch } from "@mui/material";
import MDBox from "components/md/MDBox";
import { EstatusVenta } from "API";
import MDInput from "components/md/MDInput";
import { estatusVentaLabel } from "globalvars";

type Props = {
  columnName: any;
  callback: any;
  type: "compliance" | "estabilidad" | "vidaUtil" | "estatusVenta";
  initialValue: any;
};

function EditDialog(params: Props): JSX.Element {
  const { columnName, callback, type, initialValue } = params;
  const [open, setOpen] = React.useState(false);

  const initialValueFormatted = ["compliance", "estabilidad"].includes(type)
    ? initialValue === "Si"
    : initialValue;

  const [value, setValue] = React.useState(initialValueFormatted);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    callback(value);
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleClickOpen} style={{ marginLeft: 4, cursor: "pointer" }}>
        🖊
      </span>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle sx={{ width: 900 }}>Editar {columnName}</DialogTitle>
        <DialogContent>
          <DialogContentText>Indique el nuevo valor</DialogContentText>
          <Divider />
          {["compliance", "estabilidad"].includes(type) && (
            <MDBox mr={1}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>No</Grid>
                <Grid item>
                  <Switch onChange={(e) => setValue(e.target.checked)} checked={value} />
                </Grid>
                <Grid item>Si</Grid>
              </Grid>
            </MDBox>
          )}
          {type === "estatusVenta" && (
            <Autocomplete
              options={[
                EstatusVenta.comercializado,
                EstatusVenta.nocomercializado,
                EstatusVenta.discontinuado,
              ]}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" placeholder="Seleccione..." />
              )}
              value={value}
              onChange={(e: any, newVal: any) => {
                setValue(newVal);
              }}
              getOptionLabel={(option: any) =>
                option ? estatusVentaLabel[option as keyof typeof estatusVentaLabel] : ""
              }
            />
          )}
          {type === "vidaUtil" && (
            <MDInput
              variant="standard"
              type="number"
              placeholder="Seleccione..."
              value={value}
              onChange={(e: any) => {
                setValue(e.target.value);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditDialog;
