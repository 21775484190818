import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { TramiteSearchState } from "features/TramitesAppContext";
import { paisByCluster, prepareForSearch } from "globalvars";

import {
  listTramites,
  listExportTramites,
  listExportTramitesByTipo,
  listTramitesByTipo,
} from "services/Tramite/queries";

const fetchTramitesList = async (
  _nextToken: string,
  filter?: TramiteSearchState,
  tipo?: APIt.TipoTramite,
  forExport?: boolean
): Promise<{ page: any[]; nextToken: string }> => {
  let nextToken = _nextToken;
  let page: APIt.Tramite[] = [];

  const conditions: any = {};
  conditions.filter = { and: [{ deleted: { eq: false } }] };

  const { full, codigo, advanced, advancedData } = filter || {};

  if (tipo) {
    conditions.tipo = tipo;
    conditions.sortDirection = "DESC";
  }

  if (full?.trim().length > 0) {
    const fullUpper = prepareForSearch(full);

    conditions.filter.and.push({
      or: [
        { searchCodigos: { contains: fullUpper } },
        { searchMarcas: { contains: fullUpper } },
        { searchProductos: { contains: fullUpper } },
        { searchBus: { contains: fullUpper } },
        { searchCategorias: { contains: fullUpper } },
        { searchCategoriasTerapeuticas: { contains: fullUpper } },
        { searchIniciativa: { contains: fullUpper } },
        { searchFormasCosmeticasFarmaceuticas: { contains: fullUpper } },
        { searchPrincipiosActivos: { contains: fullUpper } },
        { searchSourcingUnits: { contains: fullUpper } },
        { searchPaisDestino: { contains: fullUpper } },
        { searchPresentaciones: { contains: fullUpper } },
        { searchEtiqueta: { contains: fullUpper } },
        { searchCodigoCPT: { contains: fullUpper } },
      ],
    });
  }

  if (codigo?.trim().length > 0) {
    conditions.filter.and.push({ searchCodigos: { contains: prepareForSearch(codigo) } });
  }

  if (advanced) {
    const {
      searchSourcingUnit,
      searchProducto,
      searchPrincipiosActivos,
      searchPresentaciones,
      searchPaisDestino,
      searchMarca,
      searchIniciativa,
      searchFormaCosmeticaFarmaceutica,
      searchCodigo,
      searchCategoriaTerapeutica,
      searchCategoria,
      searchBu,
      searchTipo,
      searchEtiqueta,
      searchCodigoCPT,
      searchCluster,
    } = advancedData;

    if (searchSourcingUnit)
      conditions.filter.and.push({
        searchSourcingUnits: { contains: prepareForSearch(searchSourcingUnit) },
      });
    if (searchProducto)
      conditions.filter.and.push({
        or: [{ searchProductos: { contains: prepareForSearch(searchProducto) } }],
      });
    if (searchPrincipiosActivos)
      conditions.filter.and.push({
        searchPrincipiosActivos: { contains: prepareForSearch(searchPrincipiosActivos) },
      });
    if (searchPresentaciones)
      conditions.filter.and.push({
        searchPresentaciones: { contains: prepareForSearch(searchPresentaciones) },
      });

    if (searchPaisDestino && searchPaisDestino.length > 0) {
      const or: any[] = [];

      searchPaisDestino.forEach((pais: APIt.Pais) => {
        or.push({ searchPaisDestino: { contains: prepareForSearch(pais.nombre) } });
      });

      conditions.filter.and.push({
        or,
      });
    }

    if (searchCluster) {
      const paises = paisByCluster[searchCluster as keyof typeof paisByCluster];
      const or = paises.map((pais: string) => ({
        searchPaisDestino: { contains: prepareForSearch(pais) },
      }));
      conditions.filter.and.push({ or });
    }

    if (searchMarca)
      conditions.filter.and.push({ searchMarcas: { contains: prepareForSearch(searchMarca) } });
    if (searchIniciativa)
      conditions.filter.and.push({
        searchIniciativa: { contains: prepareForSearch(searchIniciativa) },
      });
    if (searchFormaCosmeticaFarmaceutica)
      conditions.filter.and.push({
        searchFormasCosmeticasFarmaceuticas: {
          contains: prepareForSearch(searchFormaCosmeticaFarmaceutica),
        },
      });
    if (searchCodigo)
      conditions.filter.and.push({
        searchCodigos: { contains: prepareForSearch(searchCodigo) },
      });
    if (searchCategoriaTerapeutica)
      conditions.filter.and.push({
        searchCategoriasTerapeuticas: { contains: prepareForSearch(searchCategoriaTerapeutica) },
      });
    if (searchCategoria)
      conditions.filter.and.push({
        searchCategorias: { contains: prepareForSearch(searchCategoria) },
      });
    if (searchBu)
      conditions.filter.and.push({ searchBus: { contains: prepareForSearch(searchBu) } });
    if (searchTipo) conditions.filter.and.push({ tipo: { eq: searchTipo } });

    if (searchEtiqueta)
      conditions.filter.and.push({
        searchEtiqueta: { contains: prepareForSearch(searchEtiqueta) },
      });

    if (searchCodigoCPT)
      conditions.filter.and.push({
        searchCodigoCPT: { contains: prepareForSearch(searchCodigoCPT) },
      });
  }
  if (advancedData?.searchEstado)
    conditions.filter.and.push({ estado: { eq: advancedData.searchEstado } });

  let query = "";
  if (forExport) {
    query = tipo ? listExportTramitesByTipo : listExportTramites;
  } else {
    query = tipo ? listTramitesByTipo : listTramites;
  }

  if (!forExport) {
    conditions.limit = 20;
  }

  do {
    conditions.nextToken = nextToken;
    // eslint-disable-next-line no-await-in-loop
    const result = (await API.graphql(
      graphqlOperation(query, conditions)
    )) as GraphQLResult<APIt.ListTramiteSearchesQuery>;

    if (result.data) {
      const resultData: any = result.data;
      const entries = tipo
        ? resultData.tramiteSearchByTipo.items
        : resultData.listTramiteSearches.items;

      const thisPage = entries.map((entry: any) => entry.tramite);
      page = [...page, ...thisPage];

      nextToken = tipo
        ? resultData.tramiteSearchByTipo.nextToken
        : resultData.listTramiteSearches.nextToken;
    }
  } while (nextToken && (page.length < 20 || forExport));

  return { page, nextToken };
};

export default fetchTramitesList;
