import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/md/MDButton";
import FormField from "components/tramites/FormField";
import React from "react";
import * as Yup from "yup";
import * as APIt from "API";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* eslint-disable */
type ConfirmCallback = (e: any) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  onConfirm: ConfirmCallback;
};

export default function PresentarDlg(params: Props) {
  const { formMethods, onConfirm } = params;
  const { trigger } = formMethods;
  const [open, setOpen] = React.useState(false);

  const validations = Yup.object().shape({
    fechaPresentacion: Yup.string().required("Fecha de presentación requerida").nullable(),
  });
  const {
    register,
    formState: { errors },
    control,
    trigger: triggerLocal,
    getValues: getLocalValues,
    reset,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const handleClickOpen = () => {
    trigger().then((valid: boolean) => {
      if (valid) setOpen(true);
      reset({
        fechaPresentacion: null,
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const e = getLocalValues();
    triggerLocal().then((valid: any) => {
      if (!valid) return;

      onConfirm(e);
    });
  };

  return (
    <div>
      <MDButton variant="gradient" color="success" onClick={handleClickOpen}>
        Presentar
      </MDButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Indicar como presentado</DialogTitle>
        <DialogContent>
          <DialogContentText>Por favor indique la fecha de presentación.</DialogContentText>
          <FormField
            label="Fecha de presentación"
            type="date"
            name="fechaPresentacion"
            errors={errors.fechaPresentacion?.message}
            register={register}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
