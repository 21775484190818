import { getBasePieOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (initialData: any[]) => {
  const data: any = {
    labels: ["On Time", "Vencidos", "En Curso", "Retrasados"],
    datasets: [],
  };

  let header = "Total - Sin datos";

  if (initialData?.length > 0) {
    const onTime = initialData?.reduce((acc, curr) => {
      if (curr.sometimientosOnTime?.length) return acc + curr.sometimientosOnTime.length;
      return acc;
    }, 0);
    const vencidos = initialData?.reduce((acc, curr) => {
      if (curr.sometimientosVencidos?.length) return acc + curr.sometimientosVencidos.length;
      return acc;
    }, 0);
    const enCurso = initialData?.reduce((acc, curr) => {
      if (curr.sometimientosEnCurso?.length) return acc + curr.sometimientosEnCurso.length;
      return acc;
    }, 0);
    const retrasados = initialData?.reduce((acc, curr) => {
      if (curr.sometimientosRetrasados?.length) return acc + curr.sometimientosRetrasados.length;
      return acc;
    }, 0);

    const dataset = {
      data: [onTime, vencidos, enCurso, retrasados],
      backgroundColor: [
        dashboardColors.green.background,
        dashboardColors.red.background,
        dashboardColors.yellow.background,
        dashboardColors.orange.background,
      ],
      borderColor: [
        dashboardColors.green.border,
        dashboardColors.red.border,
        dashboardColors.yellow.border,
        dashboardColors.orange.border,
      ],
      borderWidth: 1,
    };
    data.datasets = [dataset];

    const total = onTime + vencidos + retrasados;
    const efectividad = (onTime / total) * 100;
    header = `Total - Efectividad ${efectividad.toFixed(2)}%`;
  }

  return { header, data, options: getBasePieOptions() };
};

export default useData;
