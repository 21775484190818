import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import {
  createPrincipioActivoMutation,
  getPrincipioActivo,
} from "services/PrincipioActivo/queries";

const createPrincipioActivo = async (nombre: string): Promise<string> => {
  let id: string = "";

  const PrincipioActivoInput: APIt.CreatePrincipioActivoInput = {
    nombre,
  };

  const inputVariables: APIt.CreatePrincipioActivoMutationVariables = {
    input: PrincipioActivoInput,
  };

  const createRequest = (await API.graphql(
    graphqlOperation(createPrincipioActivoMutation, inputVariables)
  )) as GraphQLResult<APIt.CreatePrincipioActivoMutation>;

  if (createRequest?.data?.createPrincipioActivo) {
    id = createRequest.data.createPrincipioActivo.id;
  }

  return id;
};

const fetchPrincipioActivo = async (id: string): Promise<APIt.PrincipioActivo> => {
  let principioActivo: APIt.PrincipioActivo = null;
  const params: APIt.GetPrincipioActivoQueryVariables = { id };

  const result = (await API.graphql({
    query: getPrincipioActivo,
    variables: params,
  })) as GraphQLResult<APIt.GetPrincipioActivoQuery>;

  if (result.data) {
    const query: APIt.GetPrincipioActivoQuery = result.data;
    if (query.getPrincipioActivo) {
      principioActivo = query.getPrincipioActivo;
    }
  }

  return principioActivo;
};

export { createPrincipioActivo, fetchPrincipioActivo };
