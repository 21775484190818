import { Card, CircularProgress } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import React from "react";
import { Line } from "react-chartjs-2";

interface Props {
  header: string;
  data: any;
  options: any;
  loading: boolean;
}

function PieChart(props: Props): JSX.Element {
  const { header, data, options, loading } = props;

  return (
    <Card>
      <MDBox ml={3}>
        <MDTypography variant="h6">{header}</MDTypography>
      </MDBox>
      {!loading && (
        <MDBox margin={2}>
          <Line data={data} options={options} />
        </MDBox>
      )}
      {loading && (
        <MDBox margin={2}>
          <CircularProgress />
        </MDBox>
      )}
    </Card>
  );
}

export default React.memo(PieChart);
