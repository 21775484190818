import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/md/MDButton";
import { Autocomplete, Box, Chip, Grid, Icon, TextField } from "@mui/material";
import * as Yup from "yup";
import FormField from "components/tramites/FormField";
import MDBox from "components/md/MDBox";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import NewFabricanteDialog from "features/CodigoFormula/NewFabricanteDialog";
import NewPrincipioActivoDialog from "features/CodigoFormula/NewPrincipioActivoDialog";
import Avatar from "components/tramites/Avatar";
import FlagBuilder from "components/tramites/FlagBuilder";
import MDTypography from "components/md/MDTypography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validations = Yup.object().shape({
  principio: Yup.object().required("Principio requerido").nullable(true),
  concentracion: Yup.string().required("Concentracion requerida"),
  fabricantes: Yup.array().required("Fabricantes requeridos"),
});

/* eslint-disable */
type SetValueCallback = (values: any) => void;
/* eslint-enable */

type Props = {
  valueSetter: SetValueCallback;
  edit: boolean;
  editValues: any;
  inUse: String[];
};

function NewFormulaPrincipio(params: Props): JSX.Element {
  const { valueSetter, edit, editValues, inUse } = params;
  const [open, setOpen] = React.useState(false);

  const {
    state: { principioList, fabricanteList },
  } = useTramitesAppStateContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const { append: appendFabricante, remove: removeFabricante } = useFieldArray({
    control,
    name: "fabricantes",
    keyName: "arrayKey",
  });

  const handleClickOpen = () => {
    if (editValues?.principio?.id) reset(editValues);
    else reset({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const values = getValues();
    valueSetter(values);
    setOpen(false);
    reset({});
  };

  return (
    <div>
      {!edit && (
        <MDButton variant="gradient" color="info" onClick={handleClickOpen}>
          Agregar
        </MDButton>
      )}
      {edit && (
        <MDButton variant="text" color="dark" onClick={handleClickOpen}>
          <Icon>edit</Icon>&nbsp;Editar
        </MDButton>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <form onSubmit={handleSave}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {edit ? "Edición de " : "Nuevo "} Principio
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <MDBox>
              <MDBox>
                <ControlledAutocomplete
                  label="Principio activo"
                  name="principio"
                  options={principioList.filter(
                    (principioEntry: any) => !inUse.includes(principioEntry.nombre)
                  )}
                  control={control}
                  errors={errors.principio?.message}
                >
                  <NewPrincipioActivoDialog
                    valueSetter={(newVal: any) => setValue("principio", newVal)}
                  />
                </ControlledAutocomplete>
              </MDBox>
              <MDBox>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={10}>
                    <Autocomplete
                      id="fabricantes"
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.nombre
                      }
                      filterOptions={(options, state) =>
                        options.filter((option) =>
                          option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                        )
                      }
                      options={fabricanteList}
                      autoComplete
                      multiple
                      includeInputInList
                      filterSelectedOptions
                      value={getValues().fabricantes || []}
                      onChange={(event: any, newValue: any, selectOption: any, option: any) => {
                        if (option?.option?.id) appendFabricante(option.option);
                        else removeFabricante();
                      }}
                      isOptionEqualToValue={(option, value: any) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Fabricantes"
                          fullWidth
                          onKeyDown={(event: any) => {
                            if (event.key === "Backspace") {
                              event.stopPropagation();
                            }
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 2 }}>
                            {" "}
                            <FlagBuilder country={option.pais.codigo} />
                          </Avatar>
                          {option.nombre}
                        </Box>
                      )}
                      renderTags={(value: any, getTagProps) =>
                        value.map((option: any, index: number) => (
                          <Chip
                            avatar={
                              <Avatar title={option.pais.nombre} size="xxs">
                                {" "}
                                <FlagBuilder country={option.pais.codigo} />
                              </Avatar>
                            }
                            variant="outlined"
                            label={
                              <MDTypography sx={{ ml: 2 }} variant="label" color="white">
                                {option.nombre}
                              </MDTypography>
                            }
                            {...getTagProps({ index })}
                            onDelete={() => {
                              removeFabricante(index);
                            }}
                          />
                        ))
                      }
                    />
                  </Grid>
                  <Grid item md={2} pt={1} mt={1}>
                    <NewFabricanteDialog valueSetter={(newVal: any) => appendFabricante(newVal)} />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox>
                <FormField
                  type="text"
                  label="Concentracion"
                  name="concentracion"
                  errors={errors.concentracion?.message}
                  register={register}
                />
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button autoFocus onClick={handleSubmit(handleSave)}>
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default NewFormulaPrincipio;
