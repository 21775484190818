import { Grid } from "@mui/material";
import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useTramitesEfectividadData } from "features/Dashboard/useDashboardData";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { useEffect, useState } from "react";
import { tipoNames } from "globalvars";
import SearchBar from "../SearchBar";
import Total from "./Total";
import PorPais from "./PorPais";
import PorIniciativa from "./PorIniciativa";
import PorTipo from "./PorTipo";
import PorInnovacion from "./PorInnovacion";
import Pipeline from "./Pipeline";

function Efectividad(): JSX.Element {
  const [dbData, loading] = useTramitesEfectividadData();
  const { dashboardFilterState } = useDashboardFilters();
  const { paises, businessUnits } = dashboardFilterState;

  // IN MEMORY FILTER
  const [initialData, setInitialData] = useState([] as DashboardTramiteDaily[]);
  const [pipelineIniciativas, setPipelineIniciativas] = useState([] as string[]);
  const [pipelineTipos, setPipelineTipos] = useState([] as string[]);

  useEffect(() => {
    const filterPais = (tramite: DashboardTramiteVO) =>
      !paises || paises.length < 1 || paises.includes(tramite.pais?.codigo);
    const filterBU = (tramite: DashboardTramiteVO) =>
      !businessUnits ||
      businessUnits.length < 1 ||
      businessUnits.some((bu) => tramite.businessUnit?.codigo?.includes(bu));

    const iData = dbData.map((dashboardEntry: DashboardTramiteDaily) => {
      const efectividadAprobados = dashboardEntry.efectividadAprobados
        ?.filter(filterPais)
        ?.filter(filterBU);
      const efectividadRechazados = dashboardEntry.efectividadRechazados
        ?.filter(filterPais)
        ?.filter(filterBU);
      const efectividadDesistidos = dashboardEntry.efectividadDesistidos
        ?.filter(filterPais)
        ?.filter(filterBU);
      const efectividadEnCurso = dashboardEntry.efectividadEnCurso
        ?.filter(filterPais)
        ?.filter(filterBU);
      return {
        ...dashboardEntry,
        efectividadAprobados,
        efectividadRechazados,
        efectividadDesistidos,
        efectividadEnCurso,
      };
    });

    setInitialData(iData);

    const newPipelineIniciativas: string[] = [];
    const newPipelineTipos: string[] = [];
    const addPipelines = (tramite: DashboardTramiteVO) => {
      if (tramite.iniciativa?.nombre) {
        if (!newPipelineIniciativas.includes(tramite.iniciativa.nombre)) {
          newPipelineIniciativas.push(tramite.iniciativa.nombre);
        }
      }
      if (!newPipelineTipos.includes(tramite.tipo)) {
        newPipelineTipos.push(tramite.tipo);
      }
    };
    iData.forEach((dashboardEntry: DashboardTramiteDaily) => {
      dashboardEntry.efectividadAprobados?.forEach(addPipelines);
      dashboardEntry.efectividadRechazados?.forEach(addPipelines);
      dashboardEntry.efectividadDesistidos?.forEach(addPipelines);
      dashboardEntry.efectividadEnCurso?.forEach(addPipelines);
    });

    newPipelineIniciativas.sort();
    newPipelineTipos.sort();

    setPipelineIniciativas(newPipelineIniciativas);
    setPipelineTipos(newPipelineTipos);
  }, [paises, businessUnits, dbData]);

  return (
    <Layout>
      <Navbar />
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Efectividad de trámites
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Total initialData={initialData} loading={loading} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <PorPais initialData={initialData} loading={loading} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PorIniciativa initialData={initialData} loading={loading} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PorTipo initialData={initialData} loading={loading} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PorInnovacion initialData={initialData} loading={loading} />
                </Grid>
              </Grid>
            </Grid>
            {/* PIPELINE */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  Pipeline
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Pipeline
                    initialData={initialData}
                    loading={loading}
                    label="Total"
                    filterType=""
                    filterValue=""
                  />
                </Grid>
                {/* Iniciativas */}
                {pipelineIniciativas.map((iniciativa: string) => (
                  <Grid item xs={6} sm={3}>
                    <Pipeline
                      initialData={initialData}
                      loading={loading}
                      label={`Iniciativa ${iniciativa}`}
                      filterType="iniciativa"
                      filterValue={iniciativa}
                      key={iniciativa}
                    />
                  </Grid>
                ))}
                {/* Tipos */}
                {pipelineTipos.map((tipo: string) => (
                  <Grid item xs={6} sm={3}>
                    <Pipeline
                      initialData={initialData}
                      loading={loading}
                      label={tipoNames[tipo as keyof typeof tipoNames]}
                      filterType="tipo"
                      filterValue={tipo}
                      key={tipo}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default Efectividad;
