import React from "react";
import { Link } from "react-router-dom";
import useRegistroCRUD from "features/CodigoFormula/ServiceHooks/useRegistroCRUD";
import MDBox from "components/md/MDBox";
import { matchSorter } from "match-sorter";
import { estatusVentaLabel } from "globalvars";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import MultiSelectFilter from "./MultiSelectFilter";
import EditDialog from "./EditDialog";

const useTableConfig = () => {
  const { findRegistro, saveRegistro } = useRegistroCRUD(null);

  const {
    state: { userRol },
  } = useTramitesAppStateContext();

  function useInstance(instance: any) {
    const { allColumns } = instance;

    let rowSpanHeaders: any[] = [];

    allColumns.forEach((column: any) => {
      const { id, enableRowSpan, parentIds } = column;

      if (enableRowSpan) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, parentIds, topCellValue: null, topCellIndex: 0 },
        ];
      }
    });

    Object.assign(instance, { rowSpanHeaders });
  }

  // eslint-disable-next-line no-unused-vars
  const formatEditable = (props: any) => {
    const {
      value,
      column: { Header, id: accessor },
      row: {
        index,
        original: { id, acondicionadorId },
      },
      updateMyData,
    } = props;

    let showValue = value;
    if (accessor === "estatusVenta") {
      showValue = estatusVentaLabel[value as keyof typeof estatusVentaLabel];
    }

    if (userRol !== "Global") {
      return showValue;
    }

    const handleSaveRegistro = (value: any) => {
      findRegistro(id).then((registro) => {
        const newPresentaciones = registro.presentaciones?.map((p) => {
          const newFabricantes = p.fabricantes?.map((f) => {
            const newAcondicionadores = f.acondicionadoresPrimarios?.map((a) => {
              if (a.acondicionadorPrimario.id === acondicionadorId) {
                const newAcondicionador = { ...a };
                // "compliance" | "estabilidad" | "vidaUtil" | "estatusVenta"
                if (accessor === "compliance") newAcondicionador.compliance = value;
                if (accessor === "estabilidad") newAcondicionador.estabilidad = value;
                if (accessor === "vidaUtil") newAcondicionador.vidaUtil = value;
                if (accessor === "estatusVenta") newAcondicionador.estatusVenta = value;
                return newAcondicionador;
              }
              return a;
            });
            return { ...f, acondicionadoresPrimarios: newAcondicionadores };
          });
          return { ...p, fabricantes: newFabricantes };
        });

        let formatedValue = value;
        if (["compliance", "estabilidad"].includes(accessor)) {
          formatedValue = value ? "Si" : "No";
        }
        updateMyData(index, accessor, formatedValue);
        saveRegistro({ ...registro, presentaciones: newPresentaciones });
      });
    };

    return (
      <MDBox display="flex" flexDirection="row">
        {showValue}
        <EditDialog
          columnName={Header}
          callback={handleSaveRegistro}
          type={accessor}
          initialValue={value}
        />
      </MDBox>
    );
  };

  const formatShort = (props: any) => {
    const { value } = props;
    const shortValue = (value || "").substring(0, 10);
    return (
      <span title={value || ""}>
        {shortValue}
        {shortValue && "..."}
      </span>
    );
  };

  const formatRegistro = (props: any) => {
    const {
      value,
      row: {
        original: { id },
      },
    } = props;

    if (userRol !== "Global") {
      return value;
    }
    return <Link to={`/registro-sanitario/${id}`}>{value}</Link>;
  };

  // eslint-disable-next-line no-unused-vars
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: any) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  function SelectColumnFilter(props: any) {
    const {
      column: { filterValue, setFilter, preFilteredRows, id },
    } = props;
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i as number} value={option as string}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // eslint-disable-next-line no-unused-vars
  function PopupFilter({ column: { setFilter, preFilteredRows, id, Header } }: any) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options =
      React.useMemo(() => {
        const inOptions = new Set();
        preFilteredRows.forEach((row: any) => {
          inOptions.add(row.values[id] || "");
        });
        return [...inOptions.values()];
      }, [id, preFilteredRows]) || [];

    // Render a multi-select box
    return (
      <MultiSelectFilter options={options} columnName={Header} columnId={id} callback={setFilter} />
    );
  }

  function multiSelectFilter(rows: any, columnIds: any, filterValue: any) {
    // Filters only if filters are selected
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) => filterValue.includes(String(row.original[columnIds[0]])));
  }

  const allColumnDef = [
    {
      Header: "Categoría",
      accessor: "categoria",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Código de formula",
      accessor: "codigo",
      enableRowSpan: true,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "País",
      accessor: "pais",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Marca",
      accessor: "marca",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Registro",
      accessor: "registro",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Cell: formatRegistro,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Vencimiento sanitario",
      accessor: "vencimiento",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Inicio renovación",
      accessor: "inicioRenovacion",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "FF/FC",
      accessor: "formaCosmeticaFarmaceutica",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Categoría terapéutica",
      accessor: "categoriaTerapeutica",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Ingrediente/Principio",
      accessor: "ingrediente",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Cell: formatShort,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Concentración",
      accessor: "concentracion",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Cell: formatShort,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Fabricante API",
      accessor: "ingredienteFabricante",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Dirección F. API",
      accessor: "ingredienteDireccion",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Producto",
      accessor: "producto",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Presentación",
      accessor: "presentacion",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Fabricante PT",
      accessor: "fabricante",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Dirección F. PT",
      accessor: "fabricanteDireccion",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Sourcing",
      accessor: "sourcing",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Acondicionador",
      accessor: "acondicionador",
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Dirección Ac.",
      accessor: "acondicionadorDireccion",
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Condición Venta",
      accessor: "condicionVenta",
      enableRowSpan: true,
      parentIds: ["codigo"],
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Estatus Venta",
      accessor: "estatusVenta",
      Cell: formatEditable,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Estabilidad",
      accessor: "estabilidad",
      Cell: formatEditable,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Compliance",
      accessor: "compliance",
      Cell: formatEditable,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
    {
      Header: "Vida Útil",
      accessor: "vidaUtil",
      Cell: formatEditable,
      Filter: PopupFilter,
      filter: multiSelectFilter,
    },
  ];

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
    return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] });
  }

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) =>
        rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  return { useInstance, allColumnDef, defaultColumn, filterTypes };
};

export default useTableConfig;
