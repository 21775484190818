import { Fabricante } from "API";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

import { createFabricante, fetchFabricante } from "services/Fabricante/FabricanteCRUD";

/* eslint-disable */
type CreateCallback = (newEntry: Fabricante) => void;
/* eslint-enable */

const useFabricanteCRUD = () => {
  const {
    state: { fabricanteList },
    dispatch,
  } = useTramitesAppStateContext();

  const doCreateFabricante = (newEntry: Fabricante, callback: CreateCallback) => {
    createFabricante(newEntry).then((id) => {
      fetchFabricante(id).then((fabricante) => {
        dispatch({ type: "update-fabricantes", payload: [...fabricanteList, fabricante] });
        if (callback) callback(fabricante);
      });
    });
  };

  return [doCreateFabricante];
};

export default useFabricanteCRUD;
