import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { FormulaSearchState } from "features/TramitesAppContext";
import { prepareForSearch } from "globalvars";
// import { listCodigoFormulas } from "graphql/queries";
import { listCodigoFormulas, listCodigoFormulasCombo } from "services/CodigoFormula/queries";

const fetchCodigosFormulaList = async (
  _nextToken: string,
  filter?: FormulaSearchState,
  combo?: boolean,
  forExport?: boolean
): Promise<{ page: any[]; nextToken: string }> => {
  let nextToken = _nextToken;
  let page: APIt.CodigoFormula[] = [];

  const conditions: APIt.ListCodigoFormulaSearchesQueryVariables = {};
  conditions.limit = forExport ? 1000 : 200;
  conditions.filter = { and: [{ deleted: { ne: true } }] };

  const { full, codigo, advanced, advancedData } = filter || {};

  if (full?.trim().length > 0) {
    const fullUpper = prepareForSearch(full);

    conditions.filter.and.push({
      or: [
        { searchCodigo: { contains: fullUpper } },
        { searchMarca: { contains: fullUpper } },
        { searchProducto: { contains: fullUpper } },
        { searchBu: { contains: fullUpper } },
        { searchCategoria: { contains: fullUpper } },
        { searchCategoriaTerapeutica: { contains: fullUpper } },
        { searchFormaCosmeticaFarmaceutica: { contains: fullUpper } },
        { searchPrincipiosActivos: { contains: fullUpper } },
        { searchSourcingUnits: { contains: fullUpper } },
        { searchPaisesDestino: { contains: fullUpper } },
        { searchPresentaciones: { contains: fullUpper } },
      ],
    });
  }

  if (codigo?.trim().length > 0) {
    conditions.filter.and.push({
      or: [
        { searchCodigo: { contains: prepareForSearch(codigo) } },
        { searchProducto: { contains: prepareForSearch(codigo) } },
      ],
    });
  }
  if (codigo?.trim().length > 0) {
    conditions.filter.and.push({
      or: [
        { searchCodigo: { contains: prepareForSearch(codigo) } },
        { searchProducto: { contains: prepareForSearch(codigo) } },
      ],
    });
  }

  if (advanced) {
    const {
      searchSourcingUnits,
      searchProducto,
      searchPrincipiosActivos,
      searchPresentaciones,
      searchPaisesDestino,
      searchMarca,
      searchFormaCosmeticaFarmaceutica,
      searchCodigo,
      searchCategoriaTerapeutica,
      searchCategoria,
      searchBu,
    } = advancedData;

    if (searchSourcingUnits)
      conditions.filter.and.push({
        searchSourcingUnits: { contains: prepareForSearch(searchSourcingUnits) },
      });
    if (searchProducto)
      conditions.filter.and.push({
        or: [{ searchProducto: { contains: prepareForSearch(searchProducto) } }],
      });
    if (searchPrincipiosActivos)
      conditions.filter.and.push({
        searchPrincipiosActivos: {
          contains: prepareForSearch(searchPrincipiosActivos),
        },
      });
    if (searchPresentaciones)
      conditions.filter.and.push({
        searchPresentaciones: { contains: prepareForSearch(searchPresentaciones) },
      });
    if (searchPaisesDestino)
      conditions.filter.and.push({
        searchPaisesDestino: { contains: prepareForSearch(searchPaisesDestino) },
      });
    if (searchMarca)
      conditions.filter.and.push({
        searchMarca: { contains: prepareForSearch(searchMarca) },
      });
    if (searchFormaCosmeticaFarmaceutica)
      conditions.filter.and.push({
        searchFormaCosmeticaFarmaceutica: {
          contains: prepareForSearch(searchFormaCosmeticaFarmaceutica),
        },
      });
    if (searchCodigo)
      conditions.filter.and.push({
        searchCodigo: { contains: prepareForSearch(searchCodigo) },
      });
    if (searchCategoriaTerapeutica)
      conditions.filter.and.push({
        searchCategoriaTerapeutica: {
          contains: prepareForSearch(searchCategoriaTerapeutica),
        },
      });
    if (searchCategoria)
      conditions.filter.and.push({
        searchCategoria: { contains: prepareForSearch(searchCategoria) },
      });
    if (searchBu)
      conditions.filter.and.push({
        searchBu: { contains: prepareForSearch(searchBu) },
      });
  }

  const query = combo ? listCodigoFormulasCombo : listCodigoFormulas;
  do {
    conditions.nextToken = nextToken;
    // eslint-disable-next-line no-await-in-loop
    const result = (await API.graphql(
      graphqlOperation(query, conditions)
    )) as GraphQLResult<APIt.ListCodigoFormulaSearchesQuery>;

    if (result.data?.listCodigoFormulaSearches) {
      const resultData: APIt.ListCodigoFormulaSearchesQuery = result.data;
      const entries = resultData.listCodigoFormulaSearches.items;

      const thisPage = entries.map((entry: APIt.CodigoFormulaSearch) => entry.codigoFormula);
      page = [...page, ...thisPage];

      nextToken = resultData.listCodigoFormulaSearches.nextToken;
    }
  } while (nextToken && page.length < 20);

  return { page, nextToken };
};

export default fetchCodigosFormulaList;
