export const searchTramites = /* GraphQL */ `
  query ListTramites(
    $filter: SearchableTramiteSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableTramiteSearchSortInput]
  ) {
    searchTramiteSearches(sort: $sort, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tramite {
          id
          tipo
          estado
          dossierCompleteness
          fechaLanzamiento
          fechaLanzamientoObjetivo
          fechaLiberacion
          fechaLiberacionObjetivo
          fechaObjetivo
          fechaObtencion
          fechaPresentacion
          fechaPresentacionObjetivo
          etiqueta
          numeroRegistro
          formulas {
            codigo
            marca
            categoria {
              codigo
              nombre
            }
            bu {
              nombre
            }
          }
          codigoCPT
          innovacion
          pais {
            codigo
            nombre
          }
          paisReceptor {
            codigo
            id
            nombre
          }
          presentaciones {
            producto
            fabricantes {
              fabricante {
                pais {
                  codigo
                  nombre
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listTramitesByTipo = /* GraphQL */ `
  query ListTramites(
    $filter: ModelTramiteSearchFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tipo: TipoTramite!
    $limit: Int
  ) {
    tramiteSearchByTipo(
      filter: $filter
      nextToken: $nextToken
      sortDirection: $sortDirection
      tipo: $tipo
      limit: $limit
    ) {
      items {
        tramite {
          id
          tipo
          estado
          dossierCompleteness
          fechaLanzamiento
          fechaLanzamientoObjetivo
          fechaLiberacion
          fechaLiberacionObjetivo
          fechaObjetivo
          fechaObtencion
          fechaPresentacion
          fechaPresentacionObjetivo
          etiqueta
          numeroRegistro
          formulas {
            codigo
            marca
            categoria {
              codigo
              nombre
            }
            bu {
              nombre
            }
          }
          codigoCPT
          innovacion
          pais {
            codigo
            nombre
          }
          paisReceptor {
            codigo
            id
            nombre
          }
          presentaciones {
            producto
            fabricantes {
              fabricante {
                pais {
                  codigo
                  nombre
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listTramites = /* GraphQL */ `
  query ListTramites($filter: ModelTramiteSearchFilterInput, $limit: Int, $nextToken: String) {
    listTramiteSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tramite {
          id
          tipo
          estado
          dossierCompleteness
          fechaLanzamiento
          fechaLanzamientoObjetivo
          fechaLiberacion
          fechaLiberacionObjetivo
          fechaObjetivo
          fechaObtencion
          fechaPresentacion
          fechaPresentacionObjetivo
          etiqueta
          numeroRegistro
          formulas {
            codigo
            marca
            categoria {
              codigo
              nombre
            }
            bu {
              nombre
            }
          }
          codigoCPT
          innovacion
          pais {
            codigo
            nombre
          }
          paisReceptor {
            codigo
            id
            nombre
          }
          presentaciones {
            producto
            fabricantes {
              fabricante {
                pais {
                  codigo
                  nombre
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getTramite = /* GraphQL */ `
  query GetTramite($id: ID!) {
    getTramite(id: $id) {
      id
      comentarios
      codigoCPT
      costosDirectos
      costosIndirectos
      descripcion
      dossierCompleteness
      dossierData {
        codigo
        done
      }
      prioridad {
        id
        nombre
      }
      dossierPreferences
      estado
      fechaLanzamiento
      fechaLanzamientoObjetivo
      fechaLiberacion
      fechaLiberacionObjetivo
      fechaObjetivo
      fechaObtencion
      fechaPresentacion
      fechaPresentacionObjetivo
      historico
      codigoCPT
      innovacion
      numeroRegistro
      etiqueta
      registroModificado {
        autoridad {
          id
          nombre
          pais {
            codigo
            id
            nombre
          }
        }
        id
        inicioRenovacion
        numeroRegistro
        pais {
          codigo
          id
          nombre
        }
        vencimientoSanitario
      }
      tipo
      vencimientoSanitario
      formulas {
        bu {
          codigo
          id
          nombre
        }
        categoria {
          codigo
          id
          nombre
        }
        categoriaTerapeutica {
          id
          nombre
        }
        codigo
        condicionVenta {
          id
          nombre
        }
        formaCosmeticaFarmaceutica {
          id
          nombre
        }
        id
        marca
        principiosActivos {
          concentracion
          fabricantes {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
          principio {
            id
            nombre
          }
        }
      }
      iniciativa {
        id
        nombre
      }
      objeciones {
        id
        asignableRA
        autoridad {
          id
          nombre
          pais {
            codigo
            id
            nombre
          }
        }
        descripcion
        estado
        exitoso
        fechaCierre
        fechaObjecion
        fechaRespuesta
        limiteEspera
        objecion
      }
      tipoCertificado {
        id
        nombre
      }
      medioDifusion {
        id
        nombre
      }
      pais {
        codigo
        id
        nombre
      }
      paisReceptor {
        codigo
        id
        nombre
      }
      autoridad {
        id
        nombre
        pais {
          codigo
          id
          nombre
        }
      }
      presentaciones {
        fabricantes {
          acondicionadoresPrimarios {
            acondicionadorPrimario {
              direccion
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
              vencimientoBPM
            }
            compliance
            estabilidad
            estatusVenta
            vidaUtil
          }
          fabricante {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
          fabricantesAPI {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
          maquiladores {
            direccion
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
            vencimientoBPM
          }
        }
        formulas
        presentaciones
        producto
      }
    }
  }
`;

export const createTramiteMutation = /* GraphQL */ `
  mutation CreateTramite($input: CreateTramiteInput!, $condition: ModelTramiteConditionInput) {
    createTramite(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateTramiteMutation = /* GraphQL */ `
  mutation UpdateTramite($input: UpdateTramiteInput!, $condition: ModelTramiteConditionInput) {
    updateTramite(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createTramiteMutationBkp = /* GraphQL */ `
  mutation SaveTramite($data: FNS_TramiteInput!) {
    saveTramite(data: $data)
  }
`;

export const updateTramiteMutationBkp = /* GraphQL */ `
  mutation SaveTramite($data: FNS_TramiteInput!) {
    saveTramite(data: $data)
  }
`;

/* ****************************************************************************
 * EXPORT QUERIES *************************************************************
 ****************************************************************************** */
export const searchExportTramites = /* GraphQL */ `
  query ListTramites(
    $filter: SearchableTramiteSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableTramiteSearchSortInput]
  ) {
    searchTramiteSearches(sort: $sort, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tramite {
          id
          createdAt
          comentarios
          codigoCPT
          costosDirectos
          costosIndirectos
          descripcion
          dossierCompleteness
          dossierData {
            codigo
            done
          }
          prioridad {
            id
            nombre
          }
          dossierPreferences
          estado
          fechaLanzamiento
          fechaLanzamientoObjetivo
          fechaLiberacion
          fechaLiberacionObjetivo
          fechaObjetivo
          fechaObtencion
          fechaPresentacion
          fechaPresentacionObjetivo
          historico
          codigoCPT
          innovacion
          numeroRegistro
          etiqueta
          registroModificado {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
          tipo
          vencimientoSanitario
          formulas {
            bu {
              codigo
              id
              nombre
            }
            categoria {
              codigo
              id
              nombre
            }
            categoriaTerapeutica {
              id
              nombre
            }
            codigo
            condicionVenta {
              id
              nombre
            }
            formaCosmeticaFarmaceutica {
              id
              nombre
            }
            id
            marca
            principiosActivos {
              concentracion
              fabricantes {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              principio {
                id
                nombre
              }
            }
          }
          iniciativa {
            id
            nombre
          }
          objeciones {
            asignableRA
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            descripcion
            estado
            exitoso
            fechaCierre
            fechaObjecion
            fechaRespuesta
            limiteEspera
            objecion
          }
          tipoCertificado {
            id
            nombre
          }
          medioDifusion {
            id
            nombre
          }
          pais {
            codigo
            id
            nombre
          }
          paisReceptor {
            codigo
            id
            nombre
          }
          autoridad {
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
          }
          presentaciones {
            fabricantes {
              acondicionadoresPrimarios {
                acondicionadorPrimario {
                  direccion
                  id
                  nombre
                  pais {
                    codigo
                    id
                    nombre
                  }
                  vencimientoBPM
                }
                compliance
                estabilidad
                estatusVenta
                vidaUtil
              }
              fabricante {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              fabricantesAPI {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              maquiladores {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
            }
            formulas
            presentaciones
            producto
          }
        }
      }
      nextToken
    }
  }
`;

export const listExportTramitesByTipo = /* GraphQL */ `
  query ListTramites(
    $filter: ModelTramiteSearchFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tipo: TipoTramite!
    $limit: Int
  ) {
    tramiteSearchByTipo(
      filter: $filter
      nextToken: $nextToken
      sortDirection: $sortDirection
      tipo: $tipo
      limit: $limit
    ) {
      items {
        tramite {
          id
          createdAt
          comentarios
          codigoCPT
          costosDirectos
          costosIndirectos
          descripcion
          dossierCompleteness
          dossierData {
            codigo
            done
          }
          prioridad {
            id
            nombre
          }
          dossierPreferences
          estado
          fechaLanzamiento
          fechaLanzamientoObjetivo
          fechaLiberacion
          fechaLiberacionObjetivo
          fechaObjetivo
          fechaObtencion
          fechaPresentacion
          fechaPresentacionObjetivo
          historico
          codigoCPT
          innovacion
          numeroRegistro
          etiqueta
          registroModificado {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
          tipo
          vencimientoSanitario
          formulas {
            bu {
              codigo
              id
              nombre
            }
            categoria {
              codigo
              id
              nombre
            }
            categoriaTerapeutica {
              id
              nombre
            }
            codigo
            condicionVenta {
              id
              nombre
            }
            formaCosmeticaFarmaceutica {
              id
              nombre
            }
            id
            marca
            principiosActivos {
              concentracion
              fabricantes {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              principio {
                id
                nombre
              }
            }
          }
          iniciativa {
            id
            nombre
          }
          objeciones {
            asignableRA
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            descripcion
            estado
            exitoso
            fechaCierre
            fechaObjecion
            fechaRespuesta
            limiteEspera
            objecion
          }
          tipoCertificado {
            id
            nombre
          }
          medioDifusion {
            id
            nombre
          }
          pais {
            codigo
            id
            nombre
          }
          paisReceptor {
            codigo
            id
            nombre
          }
          autoridad {
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
          }
          presentaciones {
            fabricantes {
              acondicionadoresPrimarios {
                acondicionadorPrimario {
                  direccion
                  id
                  nombre
                  pais {
                    codigo
                    id
                    nombre
                  }
                  vencimientoBPM
                }
                compliance
                estabilidad
                estatusVenta
                vidaUtil
              }
              fabricante {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              fabricantesAPI {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              maquiladores {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
            }
            formulas
            presentaciones
            producto
          }
        }
      }
      nextToken
    }
  }
`;

export const listExportTramites = /* GraphQL */ `
  query ListTramites($filter: ModelTramiteSearchFilterInput, $limit: Int, $nextToken: String) {
    listTramiteSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tramite {
          id
          createdAt
          comentarios
          codigoCPT
          costosDirectos
          costosIndirectos
          descripcion
          dossierCompleteness
          dossierData {
            codigo
            done
          }
          prioridad {
            id
            nombre
          }
          dossierPreferences
          estado
          fechaLanzamiento
          fechaLanzamientoObjetivo
          fechaLiberacion
          fechaLiberacionObjetivo
          fechaObjetivo
          fechaObtencion
          fechaPresentacion
          fechaPresentacionObjetivo
          historico
          codigoCPT
          innovacion
          numeroRegistro
          etiqueta
          registroModificado {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
          tipo
          vencimientoSanitario
          formulas {
            bu {
              codigo
              id
              nombre
            }
            categoria {
              codigo
              id
              nombre
            }
            categoriaTerapeutica {
              id
              nombre
            }
            codigo
            condicionVenta {
              id
              nombre
            }
            formaCosmeticaFarmaceutica {
              id
              nombre
            }
            id
            marca
            principiosActivos {
              concentracion
              fabricantes {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              principio {
                id
                nombre
              }
            }
          }
          iniciativa {
            id
            nombre
          }
          objeciones {
            asignableRA
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            descripcion
            estado
            exitoso
            fechaCierre
            fechaObjecion
            fechaRespuesta
            limiteEspera
            objecion
          }
          tipoCertificado {
            id
            nombre
          }
          medioDifusion {
            id
            nombre
          }
          pais {
            codigo
            id
            nombre
          }
          paisReceptor {
            codigo
            id
            nombre
          }
          autoridad {
            id
            nombre
            pais {
              codigo
              id
              nombre
            }
          }
          presentaciones {
            fabricantes {
              acondicionadoresPrimarios {
                acondicionadorPrimario {
                  direccion
                  id
                  nombre
                  pais {
                    codigo
                    id
                    nombre
                  }
                  vencimientoBPM
                }
                compliance
                estabilidad
                estatusVenta
                vidaUtil
              }
              fabricante {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              fabricantesAPI {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
              maquiladores {
                direccion
                id
                nombre
                pais {
                  codigo
                  id
                  nombre
                }
                vencimientoBPM
              }
            }
            formulas
            presentaciones
            producto
          }
        }
      }
      nextToken
    }
  }
`;
