import { AppBar, Card, Grid, Tab, Tabs } from "@mui/material";
import { TipoTramite, Tramite } from "API";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDTypography from "components/md/MDTypography";
import { UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import NuevoPicker from "features/Tramite/TramiteEdit/FormulaPicker/NuevoPicker";
import ModificacionPicker from "./ModificacionPicker";
import PublicidadPicker from "./PublicidadPicker";
import CertificadoPicker from "./CertificadoPicker";
import VigilanciaPicker from "./VigilanciaPicker";
import OtrosPicker from "./OtrosPicker";
import MultiplePicker from "./MultiplePicker";

type Props = {
  formMethods: UseFormReturn<Tramite, any>;
  tipo: TipoTramite;
};

function FormulaPicker(data: Props): JSX.Element {
  const { formMethods, tipo } = data;
  const { setValue } = formMethods;

  const path = useLocation().pathname;
  const back = path.substring(0, path.lastIndexOf("/"));

  const navigate = useNavigate();

  const tiposModificacion = [
    TipoTramite.modificacion,
    TipoTramite.modificacionAdministrativa,
    TipoTramite.modificacionTecnica,
    TipoTramite.renovacion,
  ];

  const canMultiple = [
    TipoTramite.modificacion,
    TipoTramite.modificacionAdministrativa,
    TipoTramite.modificacionTecnica,
    TipoTramite.publicidad,
    TipoTramite.nuevo,
  ].includes(tipo);

  setValue("tipo", tipo);

  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [tabValue, setTabValue] = useState("simple");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
  });

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      {canMultiple && (
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={(event: any, newValue: any) => setTabValue(newValue)}
            variant="fullWidth"
          >
            <Tab label={<MDTypography variant="h5">Nuevo trámite</MDTypography>} value="simple" />
            <Tab
              label={<MDTypography variant="h5">Activación múltiple</MDTypography>}
              value="multiple"
            />
          </Tabs>
        </AppBar>
      )}

      {tabValue === "multiple" && <MultiplePicker tipo={tipo} />}

      {tabValue === "simple" && (
        <>
          <MDBox p={3}>
            <MDTypography variant="h6">Nuevo trámite</MDTypography>
          </MDBox>
          <MDBox pb={3} px={3}>
            {tipo === TipoTramite.nuevo && <NuevoPicker formMethods={formMethods} />}
            {tiposModificacion.includes(tipo) && <ModificacionPicker formMethods={formMethods} />}
            {tipo === TipoTramite.publicidad && <PublicidadPicker formMethods={formMethods} />}
            {tipo === TipoTramite.certificados && <CertificadoPicker formMethods={formMethods} />}
            {tipo === TipoTramite.vigilanciaSanitaria && (
              <VigilanciaPicker formMethods={formMethods} />
            )}
            {tipo === TipoTramite.otros && <OtrosPicker formMethods={formMethods} />}
            <Grid container spacing={3} direction="row-reverse" my={2}>
              <Grid item>
                <MDBox
                  width="100%"
                  height={{ xs: "auto", md: "100%" }}
                  display="flex"
                  justifyContent={{ xs: "flex-start", md: "flex-end" }}
                  alignItems="flex-end"
                  mt={{ xs: 2, md: 0 }}
                >
                  <MDButton variant="gradient" color="info" type="submit">
                    Activar
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item>
                <MDBox
                  width="100%"
                  height={{ xs: "auto", md: "100%" }}
                  display="flex"
                  justifyContent={{ xs: "flex-start", md: "flex-end" }}
                  alignItems="flex-end"
                  mt={{ xs: 2, md: 0 }}
                >
                  <MDButton variant="gradient" color="light" onClick={() => navigate(back)}>
                    Salir
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </Card>
  );
}

export default FormulaPicker;
