import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { dashboardColors } from "globalvars";

const useData = (initialData: DashboardTramiteDaily[]) => {
  const { dashboardFilterState } = useDashboardFilters();
  const { paises } = dashboardFilterState;

  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["Aprobados", "Rechazados", "Desistidos"];

  let header = "Por País - Sin datos";

  if (initialData?.length > 0) {
    // ADD PAIS LABELS
    const labels: string[] = [];
    initialData.forEach((entry: DashboardTramiteDaily) => {
      const addPaisLabel = (tramite: DashboardTramiteVO) => {
        if (labels.includes(tramite.pais.nombre)) return;

        if (paises && paises.length > 0 && !paises.includes(tramite.pais.codigo)) return;

        labels.push(tramite.pais.nombre);
      };

      entry.efectividadAprobados?.forEach(addPaisLabel);
      entry.efectividadRechazados?.forEach(addPaisLabel);
      entry.efectividadDesistidos?.forEach(addPaisLabel);
    });

    // ADD DATASETS
    // -- Aprobados
    const tmpAprobadosData: any = {};
    const tmpRechazadosData: any = {};
    const tmpDesistidosData: any = {};
    initialData.forEach((entry: DashboardTramiteDaily) => {
      entry.efectividadAprobados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpAprobadosData[tramite.pais.nombre]) tmpAprobadosData[tramite.pais.nombre] = 0;
        tmpAprobadosData[tramite.pais.nombre] += 1;
      });
      entry.efectividadRechazados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpRechazadosData[tramite.pais.nombre]) tmpRechazadosData[tramite.pais.nombre] = 0;
        tmpRechazadosData[tramite.pais.nombre] += 1;
      });
      entry.efectividadDesistidos?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpDesistidosData[tramite.pais.nombre]) tmpDesistidosData[tramite.pais.nombre] = 0;
        tmpDesistidosData[tramite.pais.nombre] += 1;
      });
    });

    const datasetAprobados = {
      label: "Aprobados",
      data: labels.map((pais: string) => tmpAprobadosData[pais] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetAprobados);

    const datasetRechazados = {
      label: "Rechazados",
      data: labels.map((pais: string) => tmpRechazadosData[pais] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetRechazados);

    const datasetDesistidos = {
      label: "Desistidos",
      data: labels.map((pais: string) => tmpDesistidosData[pais] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetDesistidos);

    data.labels = labels.map((pais: string) => {
      const aprobados = tmpAprobadosData[pais] || 0;
      const rechazados = tmpRechazadosData[pais] || 0;
      const desistidos = tmpDesistidosData[pais] || 0;

      const total = aprobados + rechazados + desistidos;
      let percentage = (aprobados / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${pais} (${percentage.toFixed(2)}%)`;
    });

    header = "Por País";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
