import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import useRegistroCRUD from "features/CodigoFormula/ServiceHooks/useRegistroCRUD";
import { Divider, Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { estatusVentaLabel, gridSizes } from "globalvars";
import ReadOnlyField from "features/Tramite/TramiteEdit/DatosTramite/DetalleTramite/ReadOnlyField";
import MDTypography from "components/md/MDTypography";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import VerPrincipiosTable from "./VerPrincipiosTable";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  registroId: string;
};

export default function VerRegistro(params: Props): JSX.Element {
  const { registroId } = params;
  if (!registroId) return null;

  const { registro } = useRegistroCRUD(registroId);

  return (
    <MDBox>
      <MDBox p={2} pl={4}>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3} pt={4}>
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="Número de registro"
            >
              {registro.numeroRegistro}
            </ReadOnlyField>
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="País"
            >
              {registro.pais?.nombre}
            </ReadOnlyField>
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="Autoridad"
            >
              {registro.autoridad?.nombre}
            </ReadOnlyField>
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="Inicio Renovación"
            >
              {registro.inicioRenovacion}
            </ReadOnlyField>
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="Vencimiento Sanitario"
            >
              {registro.vencimientoSanitario}
            </ReadOnlyField>
          </Grid>
        </MDBox>

        <MDBox pb={3} px={3}>
          <MDTypography variant="h4">Fórmulas</MDTypography>
          {registro.formulas?.map((formula: APIt.RegistroFormulaVO) => (
            <MDBox pb={3} px={3} key={`${formula.id}`}>
              <Grid container spacing={3} pt={4}>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="Fórmula"
                >
                  {formula.codigo}
                </ReadOnlyField>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="B.U."
                >
                  {formula.bu?.nombre}
                </ReadOnlyField>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="Cóndicion Venta"
                >
                  {formula.condicionVenta?.nombre}
                </ReadOnlyField>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="Categoría Terapéutica"
                >
                  {formula.categoriaTerapeutica?.nombre}
                </ReadOnlyField>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="Categoría"
                >
                  {formula.categoria?.nombre}
                </ReadOnlyField>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="Marca"
                >
                  {formula.marca}
                </ReadOnlyField>
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="FF/FC"
                >
                  {formula.formaCosmeticaFarmaceutica?.nombre}
                </ReadOnlyField>
              </Grid>

              <Grid container spacing={3} pt={4}>
                {formula.categoria?.codigo === "OTC" && (
                  <ReadOnlyField
                    gridXS={12}
                    gridSM={12}
                    gridLGTop={12}
                    gridXLTop={12}
                    title="Fabricantes"
                  >
                    <VerPrincipiosTable formula={formula} />
                  </ReadOnlyField>
                )}
                {formula.categoria?.codigo !== "OTC" && (
                  <ReadOnlyField
                    gridXS={12}
                    gridSM={12}
                    gridLGTop={12}
                    gridXLTop={12}
                    title="Ingredientes"
                  >
                    {formula.principiosActivos?.map(
                      (principio: APIt.RegistroPrincipioActivoVO, pi: number) =>
                        `${principio.principio?.nombre}${pi > 0 ? ", " : ""}`
                    )}
                  </ReadOnlyField>
                )}
              </Grid>
              <Divider />
            </MDBox>
          ))}
        </MDBox>

        <MDBox pb={3} px={3}>
          <MDTypography variant="h4">Presentaciones</MDTypography>
          {registro.presentaciones?.map(
            (presentacion: APIt.RegistroPresentacionesVO, index: number) => (
              <MDBox key={`${presentacion.producto}.${index + 1}`}>
                <MDTypography variant="label" sx={{ mt: 2, ml: 2, mb: 2 }}>
                  {presentacion.formulas?.map(
                    (formula: string, i: number) => `${formula}${i > 0 ? ", " : ""}`
                  )}{" "}
                  - {presentacion.producto} - {presentacion.presentaciones}
                </MDTypography>
                <MDBox pb={3} px={3} mt={2}>
                  <MDTypography variant="h5">Fabricantes</MDTypography>
                  {presentacion.fabricantes?.map(
                    (fabricante: APIt.RegistroFabricanteVO, x: number) => (
                      <MDBox pb={3} px={3} key={`${fabricante.fabricante.id}.${x + 1}`}>
                        <Grid container spacing={3} pt={4}>
                          <ReadOnlyField
                            gridXS={gridSizes.xs}
                            gridSM={gridSizes.sm}
                            gridLGTop={4}
                            gridXLTop={4}
                            title="Fabricante PT"
                          >
                            {fabricante.fabricante.nombre}
                          </ReadOnlyField>
                          <ReadOnlyField
                            gridXS={gridSizes.xs}
                            gridSM={gridSizes.sm}
                            gridLGTop={4}
                            gridXLTop={4}
                            title="Maquiladores"
                          >
                            <MDBox>
                              {fabricante.maquiladores?.map((maquilador: APIt.FabricanteVO) => (
                                <MDBox key={maquilador.id}>{maquilador.nombre}</MDBox>
                              ))}
                            </MDBox>
                          </ReadOnlyField>
                          {!!fabricante.fabricantesAPI?.length && (
                            <ReadOnlyField
                              gridXS={gridSizes.xs}
                              gridSM={gridSizes.sm}
                              gridLGTop={4}
                              gridXLTop={4}
                              title="Fabricantes API"
                            >
                              <MDBox>
                                {fabricante.fabricantesAPI?.map(
                                  (fabricanteAPI: APIt.FabricanteVO) => (
                                    <MDBox key={fabricanteAPI.id}>{fabricanteAPI.nombre}</MDBox>
                                  )
                                )}
                              </MDBox>
                            </ReadOnlyField>
                          )}
                        </Grid>
                        <MDBox pb={3} px={3}>
                          <MDTypography variant="h5">Acondicionadores primarios</MDTypography>
                          <MDBox p={2}>
                            <TableContainer>
                              <Table style={{ width: "100%" }}>
                                <MDBox component="thead">
                                  <DataTableHeadCell width="40%" align="left" sorted={false}>
                                    Acondicionador
                                  </DataTableHeadCell>
                                  <DataTableHeadCell width="20%" align="left" sorted={false}>
                                    Compliance
                                  </DataTableHeadCell>
                                  <DataTableHeadCell width="40%" align="left" sorted={false}>
                                    Estabilidad
                                  </DataTableHeadCell>
                                  <DataTableHeadCell width="40%" align="left" sorted={false}>
                                    Vida Útil
                                  </DataTableHeadCell>
                                  <DataTableHeadCell width="40%" align="left" sorted={false}>
                                    Estatus Venta
                                  </DataTableHeadCell>
                                </MDBox>
                                <TableBody>
                                  {fabricante.acondicionadoresPrimarios.map(
                                    (acondicionador: APIt.RegistroAcondicionadorPrimarioVO) => (
                                      <TableRow key={acondicionador.acondicionadorPrimario.id}>
                                        <DataTableBodyCell align="left">
                                          {acondicionador.acondicionadorPrimario?.nombre}
                                        </DataTableBodyCell>
                                        <DataTableBodyCell align="left">
                                          {acondicionador.compliance ? "Si" : "No"}
                                        </DataTableBodyCell>
                                        <DataTableBodyCell align="left">
                                          {acondicionador.estabilidad ? "Presente" : "Ausente"}
                                        </DataTableBodyCell>
                                        <DataTableBodyCell align="left">
                                          {acondicionador.vidaUtil} (Meses)
                                        </DataTableBodyCell>
                                        <DataTableBodyCell align="left">
                                          {estatusVentaLabel[acondicionador.estatusVenta]}
                                        </DataTableBodyCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    )
                  )}
                </MDBox>
              </MDBox>
            )
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
