import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listFabricantes } from "services/Fabricante/queries";

const fetchFabricantesList = async (): Promise<{ fabricantes: APIt.Fabricante[] }> => {
  let fabricantes: APIt.Fabricante[] = [];
  let nextToken = null;

  do {
    const conditions: APIt.ListFabricantesQueryVariables = {
      filter: { historico: { eq: false } },
      limit: 20000,
      nextToken,
    };

    /* eslint-disable */
    const result = (await API.graphql(
      graphqlOperation(listFabricantes, conditions)
    )) as GraphQLResult<APIt.ListFabricantesQuery>;
    /* eslint-enable */

    if (result.data) {
      const resultData: APIt.ListFabricantesQuery = result.data;
      nextToken = result.data.listFabricantes?.nextToken;
      fabricantes = [...fabricantes, ...resultData.listFabricantes.items];
    } else break;
  } while (nextToken != null);

  return { fabricantes };
};

export default fetchFabricantesList;
