import { Autocomplete, Box, Card, Grid, Icon } from "@mui/material";
import MDButton from "components/md/MDButton";
import MDBox from "components/md/MDBox";
import MDInput from "components/md/MDInput";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid, format, parseISO } from "date-fns";
import useDashboardFilters from "../useDashboardFilter";

function SearchBar(): JSX.Element {
  const {
    state: { buList, suList },
  } = useTramitesAppStateContext();
  const {
    dashboardFilterState,
    setPaises,
    setBusinessUnits,
    setRange,
    setFechaInicio,
    setFechaFin,
    setNextRange,
    setPrevRange,
  } = useDashboardFilters();

  const { paises, businessUnits, range, fechaInicio, fechaFin } = dashboardFilterState;

  const paisOptions = suList.filter((su) => !paises?.includes(su.codigo)).map((su) => su.codigo);
  const buOptions = buList.filter((su) => !paises?.includes(su.codigo)).map((su) => su.codigo);

  const getPaisNombre = (codigo: string) => suList.find((su) => su.codigo === codigo).nombre;
  const getBUNombre = (codigo: string) => buList.find((su) => su.codigo === codigo).nombre;
  const rangos = { Q: "Trimestral", Y: "Anual", M: "Mensual", C: "Custom" };
  const getRangoNombre = (codigo: string) => rangos[codigo as keyof typeof rangos];
  return (
    <Card>
      <MDBox margin={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <MDBox>
              <Autocomplete
                multiple
                filterSelectedOptions
                options={paisOptions}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" placeholder="Paises" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {getPaisNombre(option)}
                  </li>
                )}
                getOptionLabel={(option: any) => getPaisNombre(option)}
                onChange={(e: any, newVal: any) => {
                  setPaises(newVal);
                }}
                value={paises || []}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <MDBox>
              <Autocomplete
                multiple
                filterSelectedOptions
                options={buOptions}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" placeholder="B.U.s" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {getBUNombre(option)}
                  </li>
                )}
                getOptionLabel={(option: any) => getBUNombre(option)}
                onChange={(e: any, newVal: any) => {
                  setBusinessUnits(newVal);
                }}
                value={businessUnits || []}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <MDBox>
              <Autocomplete
                options={Object.keys(rangos)}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" placeholder="Rango" />
                )}
                onChange={(e: any, newVal: any) => {
                  setRange(newVal);
                }}
                getOptionLabel={(option: any) => (option?.[0] ? getRangoNombre(option) : "")}
                value={[range] || null}
              />
            </MDBox>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={2}>
            <MDBox>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fecha Inicio"
                  onChange={(date) =>
                    isValid(date) ? setFechaInicio(format(date, "yyyy-MM-dd")) : null
                  }
                  value={fechaInicio && parseISO(fechaInicio)}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <MDInput ref={inputRef} {...inputProps} fullWidth />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </MDBox>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={2}>
            <MDBox>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fecha Fin"
                  onChange={(date) =>
                    isValid(date) ? setFechaFin(format(date, "yyyy-MM-dd")) : null
                  }
                  value={fechaFin && parseISO(fechaFin)}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <MDInput ref={inputRef} {...inputProps} fullWidth />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </MDBox>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <MDBox display="flex" alignItems="right">
              <MDButton
                variant="outlined"
                color="info"
                iconOnly
                circular
                onClick={setPrevRange}
                title="Rango anteior"
              >
                <Icon sx={{ fontWeight: "bold" }}>undo</Icon>
              </MDButton>
              <MDButton
                variant="outlined"
                color="info"
                iconOnly
                circular
                onClick={setNextRange}
                title="Rango siguiente"
              >
                <Icon sx={{ fontWeight: "bold" }}>redo</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SearchBar;
