import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import MDButton from "components/md/MDButton";
import FormField from "components/tramites/FormField";
import React from "react";
import * as Yup from "yup";
import * as APIt from "API";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import AprobarDlgRegistro from "./AprobarDlgRegistro";

/* eslint-disable */
type ConfirmCallback = (values: any) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  onConfirm: ConfirmCallback;
};

export default function AprobarDlg(params: Props) {
  const { formMethods: tramiteMethods, onConfirm } = params;
  const {
    trigger: tramiteTrigger,
    getValues: getTramiteValues,
    control: tramiteControl,
  } = tramiteMethods;
  const { presentaciones, registroModificado, tipo, formulas } = getTramiteValues();

  const watchObjeciones = useWatch({ control: tramiteControl, name: "objeciones" });

  const canApprove =
    !watchObjeciones ||
    watchObjeciones.length === 0 ||
    watchObjeciones
      ?.sort((a: APIt.ObjecionVO, b: APIt.ObjecionVO) => (a.fechaCierre > b.fechaCierre ? 1 : -1))
      ?.at(watchObjeciones.length - 1)?.estado === APIt.EstadoObjecion.aceptada;

  const registroLabel = [
    APIt.TipoTramite.vigilanciaSanitaria,
    APIt.TipoTramite.certificados,
    APIt.TipoTramite.publicidad,
    APIt.TipoTramite.otros,
  ].includes(tipo)
    ? "Número de trámite"
    : "Número de registro";

  const datosRegistro = [
    APIt.TipoTramite.nuevo,
    APIt.TipoTramite.modificacion,
    APIt.TipoTramite.modificacionAdministrativa,
    APIt.TipoTramite.modificacionTecnica,
  ].includes(tipo);

  const showVencimiento = ![
    APIt.TipoTramite.vigilanciaSanitaria,
    APIt.TipoTramite.publicidad,
    APIt.TipoTramite.otros,
  ].includes(tipo);

  const registroOTramite = [
    APIt.TipoTramite.nuevo,
    APIt.TipoTramite.modificacion,
    APIt.TipoTramite.modificacionAdministrativa,
    APIt.TipoTramite.modificacionTecnica,
    APIt.TipoTramite.renovacion,
  ].includes(tipo)
    ? "registro"
    : "tramite";

  const numeroRegistro = registroModificado?.numeroRegistro || "";
  const vencimientoSanitario = registroModificado?.vencimientoSanitario || null;

  const [open, setOpen] = React.useState(false);

  const shape: any = {
    fechaObtencion: Yup.string().required("Fecha de obtención requerida").nullable(),
    numeroRegistro: Yup.string().required(`Número de ${registroOTramite} requerido`),
  };
  if (showVencimiento) {
    shape.vencimientoSanitario = Yup.string()
      .required(`Fecha de vencimiento de ${registroOTramite} requerida`)
      .nullable();
  }

  const validations = Yup.object().shape(shape);
  const formMethods = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      presentaciones,
      formulas,
      numeroRegistro,
      vencimientoSanitario,
      fechaObtencion: null,
    } as APIt.Tramite,
  });
  const {
    reset,
    formState: { errors },
    getValues,
    trigger,
    register,
    control,
  } = formMethods;

  const handleClickOpen = () => {
    tramiteTrigger().then((valid: boolean) => {
      if (valid) setOpen(true);
      reset({
        presentaciones,
        formulas,
        fechaObtencion: null,
        numeroRegistro,
        vencimientoSanitario,
      } as APIt.Tramite);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const e = getValues();
    trigger().then((valid: any) => {
      if (!valid) {
        toast.error("Verificar campos obligatorios");
        return;
      }

      setOpen(false);
      onConfirm(e);
    });
  };

  return (
    <div>
      {canApprove && (
        <MDButton variant="gradient" color="success" onClick={handleClickOpen}>
          Aprobar
        </MDButton>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle>Indicar como aprobado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Por favor indique la información del ${registroOTramite}.`}
          </DialogContentText>
          <Divider />
          <FormField
            label="Fecha de obtención"
            type="date"
            name="fechaObtencion"
            errors={errors.fechaObtencion?.message}
            register={register as any}
            control={control as any}
            PopperProps={{
              placement: "auto",
            }}
          />
          <FormField
            label={registroLabel}
            type="text"
            name="numeroRegistro"
            errors={errors.numeroRegistro?.message}
            register={register as any}
            control={control as any}
          />

          {showVencimiento && (
            <FormField
              label={`Vencimiento de ${registroOTramite}`}
              type="date"
              name="vencimientoSanitario"
              errors={errors.vencimientoSanitario?.message}
              register={register as any}
              control={control as any}
              PopperProps={{
                placement: "auto",
              }}
            />
          )}

          {datosRegistro && <AprobarDlgRegistro formMethods={formMethods} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
