import { Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import {
  useRegistrosRegistrosData,
  useRegistrosTramitesAprobadosData,
  useRegistrosTramitesRechazadosData,
} from "features/Dashboard/useDashboardData";
import { useEffect, useState } from "react";
import Registros from "./Registros";
import Tramites from "./Tramites";

function Efectividad(): JSX.Element {
  const [registrosDBData, registrosDBloading] = useRegistrosRegistrosData();
  const [aprobadosDBData, aprobadosDBloading] = useRegistrosTramitesAprobadosData();
  const [rechazadosDBData, rechazadosDBloading] = useRegistrosTramitesRechazadosData();

  // IN MEMORY FILTER
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(registrosDBloading || aprobadosDBloading || rechazadosDBloading);
  }, [registrosDBloading, aprobadosDBloading, rechazadosDBloading]);

  return (
    <Layout>
      <Navbar />
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Comparativa de Portafolio
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Registros initialData={registrosDBData} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Tramites
                    initialAprobadosData={aprobadosDBData}
                    initialRechazadosData={rechazadosDBData}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default Efectividad;
