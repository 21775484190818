import { useTramitesAppStateContext } from "features/TramitesAppContext";

import {
  createPrincipioActivo,
  fetchPrincipioActivo,
} from "services/PrincipioActivo/PrincipioActivoCRUD";

const usePrincipioActivoCRUD = () => {
  const {
    state: { principioList },
    dispatch,
  } = useTramitesAppStateContext();

  const doCreatePrincipioActivo = (nombre: string, callback: any) => {
    createPrincipioActivo(nombre).then((id) => {
      fetchPrincipioActivo(id).then((principioActivo) => {
        dispatch({ type: "update-principios", payload: [...principioList, principioActivo] });
        const { id, nombre } = principioActivo;
        if (callback) callback({ id, nombre });
      });
    });
  };

  return [doCreatePrincipioActivo];
};

export default usePrincipioActivoCRUD;
