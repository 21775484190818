import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useState, useEffect } from "react";
import { markNotificationsReaded } from "services/Notificaciones/NotificacionesCrud";

import { findNotificaciones } from "services/Notificaciones/NotificacionesList";

/* eslint-disable */
type RefreshCallback = (overrideFull?: string) => void;
type MarkReaded = (id: string) => void;
/* eslint-enable */

const useNotificacionListLeidas = (): [any[], RefreshCallback, () => void, boolean, boolean] => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { notificacionesLeidasSearchState, user },
    dispatch,
  } = useTramitesAppStateContext();

  const findFirst = (user: string) => {
    findNotificaciones(user, "Y", 20, null).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-notificaciones-leidas-search-state",
          payload: { page, nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  // get first page
  useEffect(() => {
    if (user && notificacionesLeidasSearchState?.results?.length < 1) {
      setIsLoading(true);
      findFirst(user);
    }
  }, [user]);

  const fetchNextPage = () => {
    setIsLoading(true);
    findNotificaciones(user, "Y", 20, notificacionesLeidasSearchState.nextToken).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-notificaciones-leidas-search-state",
          payload: { page: [...notificacionesLeidasSearchState.results, ...page], nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const refresh = () => {
    if (user) {
      setIsLoading(true);
      findFirst(user);
    }
  };

  return [
    notificacionesLeidasSearchState.results,
    refresh,
    fetchNextPage,
    isLoading,
    !!notificacionesLeidasSearchState.nextToken,
  ];
};

const useNotificacionListPendientes = (): [
  any[],
  RefreshCallback,
  () => void,
  boolean,
  boolean
] => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { notificacionesPendientesSearchState, user },
    dispatch,
  } = useTramitesAppStateContext();

  const findFirst = (user: string) => {
    findNotificaciones(user, "N", 20, null).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-notificaciones-pendientes-search-state",
          payload: { page, nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  // get first page
  useEffect(() => {
    if (user && notificacionesPendientesSearchState?.results?.length < 1) {
      setIsLoading(true);
      findFirst(user);
    }
  }, [user]);

  const fetchNextPage = () => {
    setIsLoading(true);
    findNotificaciones(user, "N", 20, notificacionesPendientesSearchState.nextToken).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-notificaciones-pendientes-search-state",
          payload: { page: [...notificacionesPendientesSearchState.results, ...page], nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const refresh = () => {
    if (user) {
      setIsLoading(true);
      findFirst(user);
    }
  };

  return [
    notificacionesPendientesSearchState.results,
    refresh,
    fetchNextPage,
    isLoading,
    !!notificacionesPendientesSearchState.nextToken,
  ];
};

const useNotificacionUpdater = (): [MarkReaded] => {
  const {
    state: { user },
    dispatch,
  } = useTramitesAppStateContext();

  const markReaded = (id: string) => {
    markNotificationsReaded(user, id).then((someUpdated) => {
      if (someUpdated) {
        // RESETEAR PAGINA DE NO LEIDAS
        findNotificaciones(user, "N", 20, null).then(
          (result) => {
            const { page, nextToken } = result;
            dispatch({
              type: "update-notificaciones-pendientes-search-state",
              payload: { page, nextToken },
            });
          },
          (error) => {
            console.log(error);
          }
        );
        // RESETEAR PAGINA DE LEIDAS
        findNotificaciones(user, "Y", 20, null).then(
          (result) => {
            const { page, nextToken } = result;
            dispatch({
              type: "update-notificaciones-leidas-search-state",
              payload: { page, nextToken },
            });
          },
          (error) => {
            console.log(error);
          }
        );
        // RESETEAR BADGE
        findNotificaciones(user, "N", 10, null).then(
          (result) => {
            const { page } = result;
            dispatch({
              type: "update-top-notifications",
              payload: page,
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  };
  return [markReaded];
};

export { useNotificacionListLeidas, useNotificacionListPendientes, useNotificacionUpdater };
