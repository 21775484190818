import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listFormaCosmeticaFarmaceuticas } from "services/FormaCosmeticaFarmaceutica/queries";

const fetchFormaCosmeticaFarmaceuticasList = async (): Promise<{
  formaCosmeticaFarmaceuticas: APIt.FormaCosmeticaFarmaceutica[];
}> => {
  let formaCosmeticaFarmaceuticas: APIt.FormaCosmeticaFarmaceutica[] = [];

  const conditions: APIt.ListFormaCosmeticaFarmaceuticasQueryVariables = {
    filter: { historico: { eq: false } },
    limit: 20000,
  };

  const result = (await API.graphql(
    graphqlOperation(listFormaCosmeticaFarmaceuticas, conditions)
  )) as GraphQLResult<APIt.ListFormaCosmeticaFarmaceuticasQuery>;

  if (result.data) {
    const resultData: APIt.ListFormaCosmeticaFarmaceuticasQuery = result.data;
    formaCosmeticaFarmaceuticas = resultData.listFormaCosmeticaFarmaceuticas.items;
  }

  return { formaCosmeticaFarmaceuticas };
};

export default fetchFormaCosmeticaFarmaceuticasList;
