import {
  Controller,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  Grid,
  Icon,
  Switch,
  Table,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { estatusVentaLabel, gridSizes } from "globalvars";
import MDButton from "components/md/MDButton";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import CropTooltip from "components/tramites/CropTooltip/CropTooltip";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import FormField from "components/tramites/FormField";
import { useEffect, useState } from "react";
import MDTypography from "components/md/MDTypography";
import FabricanteAutocomplete from "components/tramites/ControlledAutocomplete/FabricanteAutocomplete";
import Avatar from "components/tramites/Avatar";
import FlagBuilder from "components/tramites/FlagBuilder";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
  registroPresentacion: APIt.RegistroPresentacionesVO;
  presentacionIndex: number;
  registroFabricante: APIt.RegistroFabricanteVO;
  fabricanteIndex: number;
  remove: UseFieldArrayRemove;
};

function DetallePresentacionFabricante(params: Props): JSX.Element {
  const {
    formMethods,
    fabricanteIndex,
    remove,
    formulas,
    presentacionIndex,
    registroPresentacion,
  } = params;
  const {
    control,
    formState: { errors },
    getValues,
    register,
  } = formMethods;
  const { tipo, estado, pais } = getValues();

  const [fabricantesAPIOptions, setFabricantesAPIOptions] = useState([] as APIt.FabricanteVO[]);

  const modificacionTipos = [
    APIt.TipoTramite.modificacion,
    APIt.TipoTramite.modificacionAdministrativa,
    APIt.TipoTramite.modificacionTecnica,
  ];
  const modificacion = modificacionTipos.includes(tipo);
  const isRegistro = !tipo;
  let isOTC = false;

  const watchFormulas = useWatch({ control, name: "formulas" });
  watchFormulas?.forEach((formula: APIt.RegistroFormulaVO) => {
    if (formula.categoria?.codigo === "OTC") {
      isOTC = registroPresentacion?.formulas.includes(formula.codigo);
    }
  });

  const watchFabricante = useWatch({
    control,
    name: `presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.fabricante`,
  });

  const fabricantesPT: APIt.FabricanteVO[] = [];
  const checkPT: string[] = [];

  formulas?.forEach((codigoFormula: APIt.CodigoFormula) => {
    codigoFormula.fabricantes?.forEach((fabricante: APIt.FabricanteVO) => {
      if (!checkPT.includes(fabricante.nombre)) {
        checkPT.push(fabricante.nombre);
        fabricantesPT.push(fabricante);
      }
    });
  });

  const paisFabAdded: string[] = [];

  fabricantesPT?.forEach((fabricante: APIt.FabricanteVO) => {
    if (!paisFabAdded.includes(fabricante.pais.codigo)) {
      paisFabAdded.push(fabricante.pais.codigo);
    }
  });

  const {
    state: { fabricanteList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const {
    fields: maquiladorFields,
    append: appendMaquilador,
    remove: removeMaquilador,
  } = useFieldArray({
    control,
    name: `presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.maquiladores`,
    keyName: "arrayKey",
  });

  const {
    fields: fabricantesAPIFields,
    append: appendFabricantesAPI,
    remove: removeFabricantesAPI,
  } = useFieldArray({
    control,
    name: `presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.fabricantesAPI`,
    keyName: "arrayKey",
  });

  const {
    fields: acondicionadorPrimarioFields,
    append: appendAcondicionadorPrimario,
    remove: removeAcondicionadorPrimario,
  } = useFieldArray({
    control,
    name: `presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios`,
    keyName: "arrayKey",
  });

  const defaultAcondicionador = {
    compliance: false,
    estabilidad: false,
    estatusVenta: APIt.EstatusVenta.nocomercializado,
  } as APIt.RegistroAcondicionadorPrimarioVO;

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;
  const canEditRegistro = !estado && isUserGlobal;

  useEffect(() => {
    const fabricantesAPI: APIt.FabricanteVO[] = [];
    const checkAPI: string[] = [];
    // LIST AVAILABLE FABRICANTES API
    watchFormulas?.forEach((formula: APIt.RegistroFormulaVO) => {
      formula.principiosActivos?.forEach((principioActivo: APIt.RegistroPrincipioActivoVO) => {
        principioActivo.fabricantes?.forEach((fabricante: APIt.FabricanteVO) => {
          if (!checkAPI.includes(fabricante.nombre)) {
            checkAPI.push(fabricante.nombre);
            fabricantesAPI.push(fabricante);
          }
        });
      });
    });
    // REMOVE FABRICANTES EXCLUDED
    const indexesToRemove: number[] = [];
    fabricantesAPIFields.forEach(
      (fabricante: APIt.FabricanteVO, index: number) => {
        const fabricanteFound = fabricantesAPI.find(
          (fabricanteAPI: APIt.FabricanteVO) => fabricanteAPI.id === fabricante.id
        );
        if (!fabricanteFound) {
          indexesToRemove.push(index);
        }
      },
      [fabricantesAPI]
    );
    indexesToRemove.reverse().forEach((index: number) => {
      removeFabricantesAPI(index);
    });
    setFabricantesAPIOptions(fabricantesAPI);
  }, [watchFormulas]);

  useEffect(() => {
    // ADD DEFAULT ACONDICIONADOR
    if (acondicionadorPrimarioFields?.length < 1 && watchFabricante?.nombre) {
      appendAcondicionadorPrimario({
        ...defaultAcondicionador,
        acondicionadorPrimario: watchFabricante,
      });
    }
  }, [watchFabricante]);

  return (
    <MDBox px={3} mt={2}>
      <MDBox display="flex" sx={{ mt: 0.7 }}>
        {canEdit && (
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            iconOnly
            circular
            sx={{ mt: 4.5, mr: 1 }}
            onClick={() => {
              remove(fabricanteIndex);
            }}
          >
            <Icon sx={{ cursor: "pointer" }}>remove</Icon>
          </MDButton>
        )}
        <Grid container direction="row" spacing={1} pl={2}>
          <Grid container spacing={3} pt={4}>
            <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
              <FabricanteAutocomplete
                label="Fabricante"
                name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.fabricante`}
                options={fabricantesPT}
                control={control as any}
                errors={
                  errors.presentaciones &&
                  errors.presentaciones[presentacionIndex] &&
                  errors.presentaciones[presentacionIndex].fabricantes &&
                  errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex] &&
                  errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex].fabricante
                    ?.message
                }
                readOnly={!canEdit}
                renderOption={(props: any, option: any) => (
                  <Box component="li" {...props} key={option.id}>
                    <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 2 }}>
                      {" "}
                      <FlagBuilder country={option.pais.codigo} />
                    </Avatar>
                    {option.nombre}
                  </Box>
                )}
              />
            </Grid>
            {pais.codigo === "MX" && (
              <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
                <Autocomplete
                  id="maquiladores"
                  getOptionLabel={(option) => (typeof option === "string" ? option : option.nombre)}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                    )
                  }
                  options={
                    fabricanteList.map((fabricante: APIt.Fabricante) => {
                      const { id, nombre, direccion, pais, vencimientoBPM } = fabricante;
                      return {
                        id,
                        nombre,
                        direccion,
                        pais: { id: pais.id, codigo: pais.codigo, nombre: pais.nombre },
                        vencimientoBPM,
                      } as APIt.FabricanteVO;
                    }) || []
                  }
                  autoComplete
                  multiple
                  includeInputInList
                  filterSelectedOptions
                  value={maquiladorFields || []}
                  onChange={(event: any, newValue: any, selectOption: any, option: any) => {
                    if (option?.option?.id) appendMaquilador(option.option);
                    else removeMaquilador();
                  }}
                  isOptionEqualToValue={(option, value: any) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Maquiladores"
                      fullWidth
                      onKeyDown={(event: any) => {
                        if (event.key === "Backspace") {
                          event.stopPropagation();
                        }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.nombre}
                    </Box>
                  )}
                  renderTags={(value: any, getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="outlined"
                        label={
                          <MDBox>
                            <CropTooltip
                              truncate={25}
                              text={option.nombre || ""}
                              variant="label"
                              color="white"
                            />
                          </MDBox>
                        }
                        {...getTagProps({ index })}
                        onDelete={() => {
                          removeMaquilador(index);
                        }}
                        disabled={!canEdit}
                      />
                    ))
                  }
                  readOnly={!canEdit}
                />
              </Grid>
            )}
            {isOTC && ["MX", "CL"].includes(pais.codigo) && (
              <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
                <Autocomplete
                  id="fabricantesAPI"
                  readOnly={!canEdit}
                  getOptionLabel={(option) => (typeof option === "string" ? option : option.nombre)}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                    )
                  }
                  options={fabricantesAPIOptions}
                  autoComplete
                  multiple
                  includeInputInList
                  filterSelectedOptions
                  value={fabricantesAPIFields || []}
                  onChange={(event: any, newValue: any, selectOption: any, option: any) => {
                    if (option?.option?.id) appendFabricantesAPI(option.option);
                    else removeFabricantesAPI();
                  }}
                  isOptionEqualToValue={(option, value: any) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fabricantes API"
                      fullWidth
                      onKeyDown={(event: any) => {
                        if (event.key === "Backspace") {
                          event.stopPropagation();
                        }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.nombre}
                    </Box>
                  )}
                  renderTags={(value: any, getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="outlined"
                        label={
                          <MDBox>
                            <CropTooltip
                              truncate={20}
                              text={option.nombre || ""}
                              variant="label"
                              color="white"
                            />
                          </MDBox>
                        }
                        {...getTagProps({ index })}
                        onDelete={() => {
                          removeFabricantesAPI(index);
                        }}
                        disabled={!canEdit}
                      />
                    ))
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </MDBox>

      <MDBox display="flex" sx={{ mt: 0.7 }}>
        <TableContainer>
          <Table style={{ width: "100%" }}>
            <MDBox component="thead">
              <TableRow>
                <DataTableHeadCell width="1%" align="left" sorted={false}>
                  {canEdit && (
                    <MDButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      iconOnly
                      circular
                      sx={{ mt: 0, mr: 1 }}
                      onClick={() => {
                        appendAcondicionadorPrimario(defaultAcondicionador);
                      }}
                    >
                      <Icon sx={{ cursor: "pointer" }}>add</Icon>
                    </MDButton>
                  )}
                </DataTableHeadCell>
                <DataTableHeadCell width="40%" align="left" sorted={false}>
                  Acondicionador primario
                </DataTableHeadCell>
                <DataTableHeadCell width="20%" align="left" sorted={false}>
                  Compliance
                </DataTableHeadCell>
                <DataTableHeadCell width="14%" align="left" sorted={false}>
                  Estabilidad
                </DataTableHeadCell>
                <DataTableHeadCell width="15%" align="left" sorted={false}>
                  Vida útil (Meses)
                </DataTableHeadCell>
                <DataTableHeadCell width="15%" align="left" sorted={false}>
                  Estatus Venta
                </DataTableHeadCell>
              </TableRow>
              {acondicionadorPrimarioFields?.map(
                (
                  registroAcondicionadorPrimario: APIt.RegistroAcondicionadorPrimarioVO,
                  acondicionadorPrimarioIndex: number
                ) => (
                  <TableRow
                    key={`${registroAcondicionadorPrimario.acondicionadorPrimario?.id}${
                      acondicionadorPrimarioIndex + 1
                    }`}
                  >
                    <MDBox component="td" px={3} display="flex">
                      {canEdit && (
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          iconOnly
                          circular
                          sx={{ mt: 1.5, mr: 1 }}
                          onClick={() => {
                            removeAcondicionadorPrimario(acondicionadorPrimarioIndex);
                          }}
                        >
                          <Icon sx={{ cursor: "pointer" }}>remove</Icon>
                        </MDButton>
                      )}
                    </MDBox>
                    <MDBox component="td">
                      <Grid container direction="row" spacing={1}>
                        <Grid item md={12}>
                          <FabricanteAutocomplete
                            label=""
                            name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.acondicionadorPrimario`}
                            options={fabricanteList}
                            control={control as any}
                            errors={
                              errors.presentaciones &&
                              errors.presentaciones[presentacionIndex] &&
                              errors.presentaciones[presentacionIndex].fabricantes &&
                              errors.presentaciones[presentacionIndex].fabricantes[
                                fabricanteIndex
                              ] &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios[acondicionadorPrimarioIndex] &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios[acondicionadorPrimarioIndex]
                                .acondicionadorPrimario?.message
                            }
                            disabled={!canEdit}
                            renderOption={(props: any, option: any) => (
                              <Box component="li" {...props} key={option.id}>
                                <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 2 }}>
                                  {" "}
                                  <FlagBuilder country={option.pais.codigo} />
                                </Avatar>
                                {option.nombre}
                              </Box>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    {!modificacion && !isRegistro && (
                      <DataTableBodyCell align="left">Pendiente</DataTableBodyCell>
                    )}
                    {!modificacion && !isRegistro && (
                      <DataTableBodyCell align="left">Pendiente</DataTableBodyCell>
                    )}
                    {!modificacion && !isRegistro && (
                      <DataTableBodyCell align="left">Pendiente</DataTableBodyCell>
                    )}
                    {!modificacion && !isRegistro && (
                      <DataTableBodyCell align="left">Pendiente</DataTableBodyCell>
                    )}
                    {(modificacion || isRegistro) && (
                      <DataTableBodyCell align="left">
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          width={{ xs: "100%", sm: "auto" }}
                          mt={{ xs: 2, sm: 1 }}
                        >
                          <MDBox mr={1}>
                            <Grid component="label" container alignItems="center" spacing={1}>
                              <Grid item>No</Grid>
                              <Grid item>
                                <Controller
                                  name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.compliance`}
                                  control={control}
                                  render={(props: any) => {
                                    const { field } = props;
                                    return (
                                      <Switch
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value}
                                        disabled={!canEdit && !canEditRegistro}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item>Si</Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </DataTableBodyCell>
                    )}
                    {(modificacion || isRegistro) && (
                      <DataTableBodyCell align="left">
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          width={{ xs: "100%", sm: "auto" }}
                          mt={{ xs: 2, sm: 1 }}
                        >
                          <MDBox mr={1}>
                            <Grid component="label" container alignItems="center" spacing={1}>
                              <Grid item>No</Grid>
                              <Grid item>
                                <Controller
                                  name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.estabilidad`}
                                  control={control}
                                  render={(props: any) => {
                                    const { field } = props;
                                    return (
                                      <Switch
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value}
                                        disabled={!canEdit && !canEditRegistro}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid item>Si</Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </DataTableBodyCell>
                    )}
                    {(modificacion || isRegistro) && (
                      <DataTableBodyCell align="left">
                        <MDBox width={50}>
                          <FormField
                            label=""
                            type="number"
                            name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.vidaUtil`}
                            errors={
                              errors.presentaciones &&
                              errors.presentaciones[presentacionIndex].fabricantes &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios[acondicionadorPrimarioIndex] &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios[acondicionadorPrimarioIndex].vidaUtil
                                ?.message
                            }
                            register={register as any}
                            disabled={!canEdit}
                          />
                        </MDBox>
                      </DataTableBodyCell>
                    )}
                    {(modificacion || isRegistro) && (
                      <MDBox component="td" px={3}>
                        <MDBox sx={{ verticalAlign: "middle", mt: 1, mb: 1 }}>
                          <Grid container direction="row" spacing={1}>
                            <Grid item md={12}>
                              <ControlledAutocomplete
                                label=""
                                name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.estatusVenta`}
                                options={[
                                  APIt.EstatusVenta.comercializado,
                                  APIt.EstatusVenta.nocomercializado,
                                  APIt.EstatusVenta.discontinuado,
                                ]}
                                getOptionLabel={(option: any) =>
                                  option
                                    ? estatusVentaLabel[option as keyof typeof estatusVentaLabel]
                                    : ""
                                }
                                isOptionEqualToValue={(option: any, value: any) => {
                                  if (!value) return false;
                                  return option.id === value.id;
                                }}
                                renderOption={(props: any, option: any) => (
                                  <Box component="li" {...props} key={option}>
                                    {estatusVentaLabel[option as keyof typeof estatusVentaLabel]}
                                  </Box>
                                )}
                                control={control as any}
                                errors={
                                  errors.presentaciones &&
                                  errors.presentaciones[presentacionIndex].fabricantes &&
                                  errors.presentaciones[presentacionIndex].fabricantes[
                                    fabricanteIndex
                                  ].acondicionadoresPrimarios &&
                                  errors.presentaciones[presentacionIndex].fabricantes[
                                    fabricanteIndex
                                  ].acondicionadoresPrimarios[acondicionadorPrimarioIndex] &&
                                  errors.presentaciones[presentacionIndex].fabricantes[
                                    fabricanteIndex
                                  ].acondicionadoresPrimarios[acondicionadorPrimarioIndex]
                                    .estatusVenta?.message
                                }
                                onChange={(data: any, field: any) => {
                                  if (data) field.onChange(data);
                                }}
                                disabled={!canEdit && !canEditRegistro}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    )}
                  </TableRow>
                )
              )}
            </MDBox>
          </Table>
        </TableContainer>
      </MDBox>
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        {errors &&
          errors.presentaciones &&
          errors.presentaciones[presentacionIndex] &&
          errors.presentaciones[presentacionIndex].fabricantes &&
          errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex] &&
          errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
            .acondicionadoresPrimarios?.message}
      </MDTypography>
      <Divider />
    </MDBox>
  );
}

export default DetallePresentacionFabricante;
