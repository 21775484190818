import { Autocomplete, Box, TextField } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Avatar from "components/tramites/Avatar";
import FlagBuilder from "components/tramites/FlagBuilder";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { prepareForSearch } from "globalvars";
import { debounce } from "lodash";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function RegistroCombo(data: Props): JSX.Element {
  const { formMethods } = data;
  const [options, setOptions] = useState([]);
  const [registro, setRegistro] = useState(null as APIt.Registro);

  const {
    state: {
      registroSearchState: { results },
      userRol,
      userPaises,
    },
  } = useTramitesAppStateContext();

  const {
    formState: { errors },
    setValue,
  } = formMethods;

  // OBTENCIÓN DE OPCIONES
  const debouncedSearch = React.useRef(
    debounce(async (codigo) => {
      const newOptions = results?.filter(
        (r) =>
          (userRol === "Global" || userPaises.includes(r.pais?.codigo)) &&
          (prepareForSearch(r.numeroRegistro).includes(prepareForSearch(codigo)) ||
            r.formulas?.some((f) => prepareForSearch(f.codigo).includes(prepareForSearch(codigo))))
      );
      setOptions(newOptions);
    }, 500)
  ).current;

  async function handleRegistroSearch(numeroRegistro: string) {
    debouncedSearch(numeroRegistro);
  }

  const pickRegistro = (data: APIt.Registro) => {
    const {
      pais,
      autoridad,
      vencimientoSanitario,
      formulas,
      presentaciones,
      numeroRegistro,
      inicioRenovacion,
      id,
    } = data;

    const formulaRegistro = {
      id,
      pais,
      autoridad,
      vencimientoSanitario,
      inicioRenovacion,
      numeroRegistro,
    } as APIt.FormulaRegistroVO;

    setRegistro(data);
    setValue("pais", pais);
    setValue("autoridad", autoridad);
    setValue("vencimientoSanitario", vencimientoSanitario);
    setValue("formulas", formulas);
    setValue("presentaciones", presentaciones);
    setValue("numeroRegistro", numeroRegistro);
    setValue("registroModificado", formulaRegistro);
  };

  const unpickRegistro = () => {
    setRegistro(null);
    setValue("pais", null);
    setValue("autoridad", null);
    setValue("vencimientoSanitario", null);
    setValue("formulas", null);
    setValue("presentaciones", null);
    setValue("numeroRegistro", null);
    setValue("registroModificado", null);
  };

  React.useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  return (
    <Autocomplete
      id="registro-combo"
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : `${option.pais.codigo} - ${option.numeroRegistro} (${option.formulas?.map(
              (formula: APIt.RegistroFormulaVO, index: number) =>
                `${index === 0 ? "" : ", "}${formula.codigo}`
            )})`
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={registro as any}
      onChange={(event: any, newValue: any, selectOption: any, option: any) => {
        if (option?.option?.id) pickRegistro(option.option);
        else unpickRegistro();
      }}
      onInputChange={(event, newInputValue) => {
        handleRegistroSearch(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => {
        if (!value.id) return false;
        return option.id === value.id;
      }}
      renderInput={(params) => (
        <MDBox mb={1.5}>
          <TextField
            {...params}
            label="Registro"
            fullWidth
            onKeyDown={(event: any) => {
              if (event.key === "Backspace") {
                event.stopPropagation();
              }
            }}
            error={!!errors.numeroRegistro?.message}
          />
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errors.numeroRegistro?.message}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id} display="flex">
          <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 1 }}>
            <FlagBuilder country={option.pais.codigo} />
          </Avatar>
          <MDTypography variant="caption">{option.numeroRegistro}</MDTypography>
          <MDTypography variant="caption" sx={{ ml: 1 }}>
            {" ("}
            {option.formulas?.map(
              (formula: APIt.RegistroFormulaVO, index: number) =>
                `${index === 0 ? "" : ", "}${formula.codigo}`
            )}
            )
          </MDTypography>
        </Box>
      )}
    />
  );
}

export default RegistroCombo;
