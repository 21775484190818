import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Switch,
} from "@mui/material";
import MDButton from "components/md/MDButton";
import FormField from "components/tramites/FormField";
import React from "react";
import * as Yup from "yup";
import * as APIt from "API";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

/* eslint-disable */
type ConfirmCallback = (e: any) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  onConfirm: ConfirmCallback;
};

export default function RechazarDlg(params: Props) {
  const { formMethods, onConfirm } = params;
  const { trigger } = formMethods;
  const [open, setOpen] = React.useState(false);

  const validations = Yup.object().shape({
    fechaObtencion: Yup.string().required("Fecha de obtención requerida").nullable(),
    rechazoMotivo: Yup.string().required("Indicar motivo de rechazo").nullable(),
  });
  const {
    register,
    formState: { errors },
    control,
    trigger: triggerLocal,
    getValues: getLocalValues,
    reset,
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      fechaObtencion: null,
      rechazoMotivo: "",
      rechazoRA: true,
    },
  });

  const handleClickOpen = () => {
    trigger().then((valid: boolean) => {
      if (valid) setOpen(true);
      reset({
        fechaObtencion: null,
        rechazoMotivo: "",
        rechazoRA: true,
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const e = getLocalValues();
    triggerLocal().then((valid: any) => {
      if (!valid) return;

      onConfirm(e);
    });
  };

  return (
    <div>
      <MDButton variant="gradient" color="error" onClick={handleClickOpen}>
        Rechazar
      </MDButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Indicar como rechazado</DialogTitle>
        <DialogContent>
          <DialogContentText>Por favor indique la fecha de obtención.</DialogContentText>
          <FormField
            label="Fecha de obtención"
            type="date"
            name="fechaObtencion"
            errors={errors.fechaObtencion?.message}
            register={register as any}
            control={control as any}
          />
          <Divider />
          <FormField
            label="Motivo de rechazo"
            type="text"
            name="rechazoMotivo"
            multiline
            rows={4}
            errors={errors.rechazoMotivo?.message}
            register={register as any}
            control={control as any}
          />
        </DialogContent>
        <DialogContent dividers>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox mr={1}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>No</Grid>
                <Grid item>
                  <Controller
                    name="rechazoRA"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item>Si</Grid>
              </Grid>
            </MDBox>
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                Asignable RA
              </MDTypography>
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
