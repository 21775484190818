import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Avatar from "components/tramites/Avatar";
import FlagBuilder from "components/tramites/FlagBuilder";
import { UseFormReturn } from "react-hook-form";
import ArrowForward from "@mui/icons-material/ArrowForward";
import * as APIt from "API";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};

function TramiteHeader(params: Props): JSX.Element {
  const {
    formMethods: { getValues },
  } = params;
  const { tipo } = getValues();

  return (
    <MDBox display="flex">
      <Avatar
        size="md"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",

          "&:not(:first-of-type)": {
            ml: 0,
          },

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
        title={getValues().pais.nombre}
      >
        <FlagBuilder country={getValues().pais.codigo} />
      </Avatar>
      {tipo === APIt.TipoTramite.certificados && (
        <>
          <ArrowForward sx={{ mt: 2, ml: -0.4, mr: -0.4 }} />
          <Avatar
            size="md"
            sx={({ borders: { borderWidth }, palette: { white } }) => ({
              border: `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: 0,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            })}
            title={getValues().paisReceptor?.nombre}
          >
            <FlagBuilder country={getValues().paisReceptor?.codigo} />
          </Avatar>
        </>
      )}

      <MDTypography
        variant="h4"
        fontWeight="medium"
        textTransform="capitalize"
        sx={{ mt: 1, mr: 4 }}
      >
        {getValues().autoridad?.nombre}
      </MDTypography>

      {getValues().formulas?.map((formula: APIt.RegistroFormulaVO) => (
        <MDBox key={formula.codigo} display="flex" sx={{ ml: 1, mt: 0.7 }}>
          <Avatar title={formula.categoria?.nombre} size="sm" sx={{ mr: 1 }} bgColor="info">
            {formula.categoria?.codigo}
          </Avatar>
          <MDTypography
            variant="h4"
            fontWeight="medium"
            textTransform="capitalize"
            sx={{ mt: 0.3 }}
          >
            {formula.codigo}
          </MDTypography>
        </MDBox>
      ))}
    </MDBox>
  );
}

export default TramiteHeader;
