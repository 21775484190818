/* tslint:disable */
/* eslint-disable */
import * as APIt from "API";

export const linkBase = "https://gira.genommalab.com/";

export const formatExportDate = (inputDate: string) => {
  return inputDate?.substring(0, 10);
};

export const gridSizes = {
  xs: 12,
  sm: 6,
  lg: 3,
  xl: 3,
  lgtop: 3,
  xltop: 2,
};

export const tipoTitles = {
  nuevo: "Nuevo registro",
  renovacion: "Renovaciones",
  modificacionTecnica: "Modificaciones técnicas",
  modificacionAdministrativa: "Modificaciones administrativas",
  docSoporteLegal: "Documentación de soporte legal",
  vigilanciaSanitaria: "Vigilancia sanitaria",
  publicidad: "Publicidades",
  otros: "Otros trámites",
  certificados: "Certificados",
  reconocimiento: "Reconocimientos",
  modificacion: "Modificaciones",
  creg: "Importaciones CREG",
};

export const tipoNames = {
  nuevo: "Nuevo registro",
  renovacion: "Renovación",
  modificacionTecnica: "M. técnica",
  modificacionAdministrativa: "M. administrativa",
  docSoporteLegal: "Doc. S. Legal",
  vigilanciaSanitaria: "Vigilancia sanitaria",
  publicidad: "Publicidad",
  otros: "Otros trámites",
  certificados: "Certificado",
  reconocimiento: "Reconocimiento",
  modificacion: "Modificación",
  creg: "Importación CREG",
};

export const estadoTramiteColors = {
  pendiente: "light",
  liberado: "secondary",
  presentado: "info",
  objetado: "primary",
  aprobado: "success",
  rechazado: "error",
  desistido: "warning",
};

export const estadoTramiteNames = {
  pendiente: "Pendiente",
  liberado: "Liberado",
  presentado: "Presentado",
  objetado: "Objetado",
  aprobado: "Aprobado",
  rechazado: "Rechazado",
  desistido: "Desistido",
};

export const condicionVentaLabel = {
  VentaLibre: "Venta Libre",
  RX: "RX",
  OTC: "OTC",
};

export const estatusVentaLabel = {
  comercializado: "Comercializado",
  nocomercializado: "No comercializado",
  discontinuado: "Discontinuado",
};

export const amplifyMock = false;

export const removeKey: any = (obj: any, key: any) =>
  obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.map((item) => removeKey(item, key))
    : Object.keys(obj)
        .filter((k) => k !== key)
        .reduce((acc, x) => Object.assign(acc, { [x]: removeKey(obj[x], key) }), {});

export const prepareForSearch = (value: string): string => {
  if (!value) return value;

  return value.toUpperCase().replaceAll(" ", "_").replace(/-/g, "_");
};

export const faseNames = {
  sinDossier: "Sin dossier",
  desarrolloDossier: "Desarrollo de dossier",
  // armadoDossier: "Armado de dossier",
  liberado: "Armado de dossier",
  presentado: "Registro en proceso",
  rechazado: "Rechazado",
  objetado: "Objetado",
  aprobado: "Aprobado",
  desistido: "Desistido",
};

const getDossierStatus = (
  progress: number
): "sinDossier" | "desarrolloDossier" /* | "armadoDossier" */ => {
  let status: "sinDossier" | "desarrolloDossier" /* | "armadoDossier" */ = "sinDossier";
  if (progress > 0 /* && progress < 100 */) {
    status = "desarrolloDossier";
  } /* else if (progress === 100) {
    status = "armadoDossier";
  } */
  return status;
};

export const getFase = (tramite: APIt.Tramite) => {
  if (tramite.estado === APIt.EstadoTramite.pendiente) {
    const dossierStatus = getDossierStatus(tramite.dossierCompleteness);
    return faseNames[dossierStatus];
  }
  return faseNames[tramite.estado];
};

export const clusterByPais = {
  MX: "México",
  US: "USA",
  BR: "Brasil",
  EC: "Andino",
  PE: "Andino",
  CO: "Andino",
  BO: "Andino",
  SV: "CariCam",
  GT: "CariCam",
  HN: "CariCam",
  DO: "CariCam",
  CR: "CariCam",
  PA: "CariCam",
  NI: "CariCam",
  CL: "Sur",
  AR: "Sur",
  UY: "Sur",
  PY: "Sur",
};

export const paisByCluster = {
  México: ["México"],
  USA: ["USA"],
  Brasil: ["BRASIL"],
  Andino: ["ECUADOR", "PERÚ", "COLOMBIA", "BOLIVIA"],
  CariCam: [
    "EL_SALVADOR",
    "GUATEMALA",
    "HONDURAS",
    "REPÚBLICA_DOMINICANA",
    "COSTA_RICA",
    "PANAMÁ",
    "NICARAGUA",
  ],
  Sur: ["CHILE", "ARGENTINA", "URUGUAY", "PARAGUAY"],
};

export const dashboardColors = {
  green: {
    background: "rgba(75, 192, 192, 0.2)",
    border: "rgba(75, 192, 192, 1)",
  },
  yellow: {
    background: "rgba(255, 206, 86, 0.2)",
    border: "rgba(255, 206, 86, 1)",
  },
  orange: {
    background: "rgba(255, 159, 64, 0.2)",
    border: "rgba(255, 159, 64, 1)",
  },
  red: {
    background: "rgba(255, 99, 132, 0.2)",
    border: "rgba(255, 99, 132, 1)",
  },
};

export const getNotificacionText = (notificacion: any) => {
  const {
    tipo,
    objectData: { codigoFormula, codigoTramite, tipoTramite, numeroRegistro },
  } = notificacion;
  switch (tipo) {
    case "tramiteActivado":
      return `Se ha activado el trámite ${codigoTramite} correspondiente a ${
        tipoNames[tipoTramite as keyof typeof tipoNames]
      }`;
    case "tramiteModificacionAprobada":
      return `Hay una modificación para la fórmula ${codigoFormula} disponible`;
    case "tramiteLiberado":
      return `Dossier del trámite ${codigoTramite} ha sido liberado`;
    case "tramiteAprobado":
      return `El trámite ${codigoTramite} ha sido aprobado`;
    case "tramiteRechazado":
      return `El trámite ${codigoTramite} ha sido rechazado`;
    case "tramiteDesistido":
      return `El trámite ${codigoTramite} ha sido desistido`;
    case "tramitePresentado":
      return `El trámite ${codigoTramite} ha ingresado a la autoridad`;
    case "objecionNueva":
      return `Se ha generado una objeción al trámite ${codigoTramite}`;
    case "objecionCerrada":
      return `Se ha cerrado una objeción del trámite ${codigoTramite}`;
    case "registroPorVencer":
      return `El registro ${numeroRegistro} está proximo a vencer`;
    case "registroVencido":
      return `El registro ${numeroRegistro} está vencido`;
    case "sometimientoPorVencer":
      return `La fecha objetivo de sometimiento del trámite ${codigoTramite} está proxima a vencer`;
    case "sometimientoVencido":
      return `La fecha objetivo de sometimiento del trámite ${codigoTramite} no fue cumplida`;
    case "tramitePorVencer":
      return `La fecha objetivo de obtención del trámite ${codigoTramite} está proxima a vencer`;
    case "tramiteVencido":
      return `La fecha objetivo de obtención del trámite ${codigoTramite} no fue cumplida`;
    case "certificadoPorVencer":
      return `El certificado de GMP del fabricante está proximo a vencer, ver trámite ${codigoTramite}`;
    case "certificadoVencido":
      return `El certificado de GMP del fabricante está vencido, ver trámite ${codigoTramite}`;
    default:
      return "Notificación indefinida";
  }
};

export const getNotificacionLink = (notificacion: any) => {
  const {
    tipo,
    objectData: { id },
  } = notificacion;
  switch (tipo) {
    case "tramiteActivado":
    case "tramiteModificacionAprobada":
    case "tramiteLiberado":
    case "tramiteAprobado":
    case "tramiteRechazado":
    case "tramiteDesistido":
    case "tramitePresentado":
    case "objecionNueva":
    case "objecionCerrada":
    case "sometimientoPorVencer":
    case "sometimientoVencido":
    case "tramitePorVencer":
    case "tramiteVencido":
    case "certificadoPorVencer":
    case "certificadoVencido":
      return `todos/${id}`;
    case "registroPorVencer":
    case "registroVencido":
      return `registro-sanitario/${id}`;
    default:
      return "";
  }
};
