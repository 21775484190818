import { TipoTramite } from "API";
// MASTER DEFINITION FOR EVERY DOSSIER FIELD AVAILABLETipoTramite.certificados
export type DossierDefinitionEntry = {
  path: string;
  name: string;
  code: string;
  scope?: "Formula" | "Tramite";
  isField?: boolean;
  preLoaded?: boolean;
  details?: boolean;
  countries?: string[];
  categories?: string[];
  tiposTramite?: TipoTramite[];
};
// CONFIGURATION
export const masterDossierDefinition: DossierDefinitionEntry[] = [
  {
    path: "/root",
    name: "Dossier",
    code: "root",
    preLoaded: true,
  },
  {
    path: "/root/OTC.0",
    name: "Información Legal-Administrativa",
    code: "OTC.0",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.1",
    name: "Información Técnica",
    code: "OTC.1",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.0/OTC.0.0",
    name: "1.1 Información legal",
    code: "OTC.0.0",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.0/OTC.0.2",
    name: "1.3 Información del titular del registro",
    code: "OTC.0.2",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.0/OTC.0.1",
    name: "1.2 Información del fabricante del medicamento",
    code: "OTC.0.1",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.0/OTC.0.3",
    name: "1.4 Evidencia de Buenas Prácticas",
    code: "OTC.0.3",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.0/OTC.0.4",
    name: "1.5 Etiquetado",
    code: "OTC.0.4",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.0/OTC.0.5",
    name: "1.6 Información farmacológica y de seguridad",
    code: "OTC.0.5",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.1/OTC.1.6",
    name: "3.2.S Sustancia farmacológica",
    code: "OTC.1.6",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.1/OTC.1.7",
    name: "3.2.P Medicamento",
    code: "OTC.1.7",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.1/OTC.1.8",
    name: "3.2.A Apéndices",
    code: "OTC.1.8",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.1/OTC.1.9",
    name: "3.2.R Información regional",
    code: "OTC.1.9",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.1/OTC.1.10",
    name: "3.3. Referencias de literatura",
    code: "OTC.1.10",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/OTC.9",
    name: "Tecnicos",
    code: "OTC.9",
    preLoaded: true,
    categories: ["OTC", "OTRA"],
  },
  {
    path: "/root/NT.0",
    name: "Legales",
    code: "NT.0",
    preLoaded: true,
    categories: ["NT", "OTRA"],
  },
  {
    path: "/root/NT.1",
    name: "Tecnicos",
    code: "NT.1",
    preLoaded: true,
    categories: ["NT", "OTRA"],
  },
  {
    path: "/root/PC.0",
    name: "Legales",
    code: "PC.0",
    preLoaded: true,
    categories: ["PC", "OTRA"],
  },
  {
    path: "/root/PC.1",
    name: "Técnicos",
    code: "PC.1",
    preLoaded: true,
    categories: ["PC", "OTRA"],
  },
  {
    path: "/root/PC.0/PC.0.0",
    name: "Certificado de Exportación (CE)",
    code: "PC.0.0",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["DO", "PA", "CL", "UY", "PY"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.0/PC.0.1",
    name: "Declaración Jurada",
    code: "PC.0.1",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["GT", "HN", "SV", "NI", "CR", "PA"],
    tiposTramite: [TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.0/PC.0.2",
    name: "Certificado BPM",
    code: "PC.0.2",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["DO", "GT", "HN", "SV", "NI", "CR", "PA", "CL", "UY", "PY", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.0/PC.0.3",
    name: "Artes",
    code: "PC.0.3",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.0/PC.0.4",
    name: "Renders/Fotos del producto",
    code: "PC.0.4",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["DO", "PA", "PY"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.0/PC.0.5",
    name: "Autorización Titular",
    code: "PC.0.5",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["DO", "GT", "HN", "SV", "NI", "CR", "PA", "CO", "EC", "PE", "PY"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.0/PC.0.6",
    name: "Declaración Fabricante",
    code: "PC.0.6",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: [
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.0/PC.0.7",
    name: "Cert. De Registro",
    code: "PC.0.7",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["UY", "PY"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.0/PC.0.8",
    name: "Registro de marca",
    code: "PC.0.8",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["DO"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.9",
    name: "Fórmula",
    code: "PC.1.9",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.10",
    name: "Fórmula desglosada",
    code: "PC.1.10",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["CO", "EC", "PE", "BO"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.11",
    name: "Especificaciones",
    code: "PC.1.11",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.12",
    name: "Sistema de loteo",
    code: "PC.1.12",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["PA", "CO", "EC", "PE", "BO", "CL"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.13",
    name: "Proceso de manufactura",
    code: "PC.1.13",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["PA"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.14",
    name: "Soporte de claims (CSD)",
    code: "PC.1.14",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["MX", "PA", "CO", "EC", "PE", "BO", "CL", "AR", "UY", "PY", "US", "BR"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.15",
    name: "Estabilidad",
    code: "PC.1.15",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["MX", "CO", "EC", "PE", "BO", "CL", "AR", "UY", "PY", "US", "BR"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.16",
    name: "Certificado de Calidad",
    code: "PC.1.16",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["PY"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.17",
    name: "Estudios de seguridad",
    code: "PC.1.17",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["MX", "PY", "BR"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.18",
    name: "Cert. Alérgenos",
    code: "PC.1.18",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["AR", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.19",
    name: "Challenge Test",
    code: "PC.1.19",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/PC.1/PC.1.20",
    name: "Certificado Lote Colorantes",
    code: "PC.1.20",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["PC", "OTRA"],
    countries: ["US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.0/NT.0.0",
    name: "Certificado de exportación (CE)",
    code: "NT.0.0",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "DO",
      "GU",
      "HN",
      "ES",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "PY",
      "AR",
      "CL",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.1",
    name: "Certificado BPM o CE BPS",
    code: "NT.0.1",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["MX", "DO", "GU", "HN", "ES", "NI", "CR", "PA", "BO", "UY", "AR", "CL", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.3",
    name: "Muestras",
    code: "NT.0.3",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["DO", "GU", "ES", "PA", "AR", "CL"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.2",
    name: "Artes",
    code: "NT.0.2",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GU",
      "HN",
      "ES",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "PY",
      "AR",
      "CL",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.4",
    name: "Autorización de comercialización",
    code: "NT.0.4",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["MX", "DO", "GU", "CR", "PA", "CO", "EC", "PE", "BO", "UY", "PY", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.5",
    name: "Declaración de Fabricante",
    code: "NT.0.5",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["MX", "DO", "GU", "HN", "ES", "NI", "CR", "PA", "CO", "EC", "BO", "PY", "AR", "CL"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.0/NT.0.6",
    name: "Declaración Jurada",
    code: "NT.0.6",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["PA"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.7",
    name: "Licencia de distribuidor local",
    code: "NT.0.7",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["MX", "GU", "UY", "PY", "AR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.8",
    name: "Carta aclaratoria etiquetado exclusivo.",
    code: "NT.0.8",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/NT.0/NT.0.9",
    name: "Carta aclaratoria CE denominación Denominación Distintiva.",
    code: "NT.0.9",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/NT.1/NT.1.8",
    name: "Fórmula",
    code: "NT.1.8",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "HN",
      "ES",
      "NI",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "PY",
      "AR",
      "CL",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.9",
    name: "Información Nutricional",
    code: "NT.1.9",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GU",
      "HN",
      "ES",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "PY",
      "AR",
      "CL",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.10",
    name: "Metodología analítica para contenido nutrimental",
    code: "NT.1.10",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["GU", "CR", "BO", "UY"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.11",
    name: "Especificaciones",
    code: "NT.1.11",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GU",
      "HN",
      "ES",
      "NI",
      "CR",
      "PA",
      "CO",
      "PE",
      "BO",
      "UY",
      "AR",
      "CL",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.12",
    name: "CoA PT",
    code: "NT.1.12",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["DO", "ES", "PA", "PE", "AR", "CL"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.14",
    name: "Estabilidades",
    code: "NT.1.14",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["MX", "EC", "PE", "BO", "UY", "PY", "AR", "CL", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.1/NT.1.15",
    name: "Proceso de fabricación",
    code: "NT.1.15",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["DO", "CR", "EC", "UY", "PY", "AR"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.1/NT.1.16",
    name: "Sistema de lotificado",
    code: "NT.1.16",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["MX", "EC", "PE", "UY", "PY", "CL"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/NT.1/NT.1.13",
    name: "CoA Bromatológico",
    code: "NT.1.13",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["CO", "EC", "PE"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.17",
    name: "Soporte de Claims",
    code: "NT.1.17",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GU",
      "HN",
      "ES",
      "NI",
      "CR",
      "PA",
      "EC",
      "BO",
      "UY",
      "PY",
      "AR",
      "CL",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.18",
    name: "Fichas técnicas de MP",
    code: "NT.1.18",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["DO", "GU", "HN", "ES", "NI", "PA", "BO", "UY", "AR", "CL"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.19",
    name: "Fichas técnicas de Envase",
    code: "NT.1.19",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["DO", "GU", "HN", "ES", "NI", "PA", "CO", "EC", "BO", "UY", "PY", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/NT.1/NT.1.20",
    name: "Condiciones de almacenamiento",
    code: "NT.1.20",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["NT", "OTRA"],
    countries: ["HN", "ES", "NI", "BO", "UY", "PY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Formula",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.0",
    name: "1.1.1 Certificado de marca",
    code: "OTC.0.0.0",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["DO", "CO", "PY"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.2",
    name: "1.1.3 Resolución del Ministerio de Salud Publica",
    code: "OTC.0.0.2",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["UY"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.1",
    name: "1.1.2 Declaración jurada",
    code: "OTC.0.0.1",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["PA"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.3",
    name: "1.1.4 Convenio/acuerdo de maquila/contrato de fabricación ",
    code: "OTC.0.0.3",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "GT", "HN", "NI", "SV", "CR", "PA", "DO", "CO", "BO", "PY", "AR", "CL"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.4",
    name: "1.1.5 Acta constitutiva de la empresa registrante",
    code: "OTC.0.0.4",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.5",
    name: "1.1.6 Poder de representación legal",
    code: "OTC.0.0.5",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "GT", "HN", "NI", "SV", "CR", "PA", "DO", "EC", "BO", "PY"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.6",
    name: "1.1.7 Propuesta de denominación distintiva",
    code: "OTC.0.0.6",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.7",
    name: "1.1.8 Información de la(s) patente(s) del(os) API(s)",
    code: "OTC.0.0.7",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.0/OTC.0.0.8",
    name: "1.1.9 Evidencia de comercialización equivalente de país origen y/o farmacéutico comercializado (foto de producto lotificado y con fecha de caducidad o CLV)",
    code: "OTC.0.0.8",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["AR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.0/OTC.0.1/OTC.0.1.9",
    name: "1.2.1 Aviso de Responsable Sanitario y Carta de director técnico (del fabricante del medicamento)",
    code: "OTC.0.1.9",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "BO", "PY", "AR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.1/OTC.0.1.10",
    name: "1.2.2 Licencia Sanitaria o habilitación funcional del fabricante del medicamento (Número FEI FDA)",
    code: "OTC.0.1.10",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "EC", "AR", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.2/OTC.0.2.11",
    name: "1.3.1 Aviso de Responsable Sanitario o farmacéutico responsable  (del titular del registro del medicamento)",
    code: "OTC.0.2.11",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "GT", "HN", "NI", "SV", "CR", "PA", "DO", "BO", "PY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.2/OTC.0.2.12",
    name: "1.3.2 Licencia Sanitaria o Habilitación funcional de la empresa registrante",
    code: "OTC.0.2.12",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "EC", "PY", "UY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.3/OTC.0.3.13",
    name: "1.4.1 Registro del medicamento en otros países  (como OTC)",
    code: "OTC.0.3.13",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BO", "AR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.0/OTC.0.3/OTC.0.3.14",
    name: "1.4.2 Certificado de libre venta (CLV) ó CPP tipo OMS (Productos importados)",
    code: "OTC.0.3.14",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.3/OTC.0.3.15",
    name: "1.4.3 GMP fabricante del medicamento",
    code: "OTC.0.3.15",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.3/OTC.0.3.16",
    name: "1.4.4 BPL del laboratorio que realiza los análisis fisicoquímicos y microbiológicos",
    code: "OTC.0.3.16",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.3/OTC.0.3.17",
    name: "1.4.5 GMP fabricante del API(s)",
    code: "OTC.0.3.17",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.4/OTC.0.4.18",
    name: "1.5.1 Proyectos de marbete para autorizar (Rotulo)   (Cajilla; etiquetas; inserto; blíster; tubo; etc.)",
    code: "OTC.0.4.18",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "EC", "PE", "AR", "CL", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.4/OTC.0.4.19",
    name: "1.5.2 Artes de empaque primario para registro",
    code: "OTC.0.4.19",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["GT", "HN", "NI", "SV", "CR", "PA", "DO", "CO", "BO", "PY", "UY", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.4/OTC.0.4.20",
    name: "1.5.3 Artes de empaque secundario para registro",
    code: "OTC.0.4.20",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["GT", "HN", "NI", "SV", "CR", "PA", "DO", "CO", "BO", "PY", "UY", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo, TipoTramite.renovacion],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.4/OTC.0.4.21",
    name: "1.5.4 Artes de inserto o prospecto (paciente) para registro",
    code: "OTC.0.4.21",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["GT", "HN", "NI", "SV", "CR", "PA", "DO", "CO", "BO", "PY", "UY", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.4/OTC.0.4.22",
    name: "1.5.5Etiquetas originales del producto del país del cual se importa",
    code: "OTC.0.4.22",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["EC"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.5/OTC.0.5.23",
    name: "1.6.1 Informe de la prueba de intercambiabilidad aplicable con dictamen   (Equivalencia terapéutica)",
    code: "OTC.0.5.23",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.5/OTC.0.5.24",
    name: "1.6.2 Reporte del estudio de intercambiabilidad (si aplica)",
    code: "OTC.0.5.24",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.5/OTC.0.5.25",
    name: "1.6.3 Justificación de la omisión de prueba de intercambiabilidad  (Aplica solo para el tipo de prueba A)",
    code: "OTC.0.5.25",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.5/OTC.0.5.26",
    name: "1.6.4 Plan de Manejo de Riesgos",
    code: "OTC.0.5.26",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.5/OTC.0.5.27",
    name: "1.6.5 Reportes Periódicos de Seguridad",
    code: "OTC.0.5.27",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.0/OTC.0.5/OTC.0.5.28",
    name: "1.6.6 Monografía farmacológica / Información para prescribir / Inserto. (CCDS/RSI)",
    code: "OTC.0.5.28",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.29",
    name: "3.2.S.1.1 Nomenclatura",
    code: "OTC.1.6.29",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.30",
    name: "3.2.S.1.2 Estructura",
    code: "OTC.1.6.30",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.31",
    name: "3.2.S.1.3 Propiedades generales",
    code: "OTC.1.6.31",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.32",
    name: "3.2.S.2.1 Fabricante (s)",
    code: "OTC.1.6.32",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.33",
    name: "3.2.S.2.2 Descripción del proceso de fabricación y controles de proceso",
    code: "OTC.1.6.33",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.34",
    name: "3.2.S.2.3 Control de materiales",
    code: "OTC.1.6.34",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.35",
    name: "3.2.S.2.4 Controles de pasos críticos e intermedios",
    code: "OTC.1.6.35",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.36",
    name: "3.2.S.2.5 Validación y / o evaluación del proceso",
    code: "OTC.1.6.36",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.37",
    name: "3.2.S.2.6 Desarrollo del proceso de fabricación",
    code: "OTC.1.6.37",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.38",
    name: "3.2.S.3.1 Aclaración de la estructura y otras características",
    code: "OTC.1.6.38",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.39",
    name: "3.2.S.3.2 Impurezas",
    code: "OTC.1.6.39",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.40",
    name: "3.2.S.4.1 Especificación",
    code: "OTC.1.6.40",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "GT", "NI", "CO", "EC", "PE", "BO", "UY", "AR", "CL", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.41",
    name: "3.2.S.4.2 Procedimientos analíticos",
    code: "OTC.1.6.41",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "GT", "NI", "PE", "BO", "UY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.42",
    name: "3.2.S.4.3 Validación de procedimientos analíticos",
    code: "OTC.1.6.42",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "NI", "BO", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Formula",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.43",
    name: "3.2.S.4.4 Análisis de lotes   Certificado de análisis del API (emitido por el fabricante del fármaco(s) correspondiente al (los) lotes empleado para la fabricación de los lotes ingresados a estudio de estabilidad)",
    code: "OTC.1.6.43",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "NI", "CO", "BO", "UY", "CL", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.44",
    name: "3.2.S.4.4 Análisis de lotes  Certificado de análisis del API   (emitido por el fabricante del medicamento); correspondiente al (los) lotes empleado para la fabricación de los lotes ingresados a estudio de estabilidad.",
    code: "OTC.1.6.44",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "EC", "BO", "UY", "AR", "CL", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.45",
    name: "3.2.S.4.4 Análisis de lotes   Evidencia de análisis.",
    code: "OTC.1.6.45",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.46",
    name: "3.2.S.4.5 Justificación de la especificación",
    code: "OTC.1.6.46",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.47",
    name: "Muestra de API estandarizada de cada uno de los fármacos; con evidencia analítica y trazabilidad a estándar primario.",
    code: "OTC.1.6.47",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["PA", "BO", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.48",
    name: "Certificado del estándar de referencia",
    code: "OTC.1.6.48",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.49",
    name: "Descripción del material de empaque empleado",
    code: "OTC.1.6.49",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.50",
    name: "3.2.S.7.1 Resumen de estabilidad y conclusiones",
    code: "OTC.1.6.50",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.52",
    name: "3.2.S.7.3 Datos de estabilidad",
    code: "OTC.1.6.52",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.6/OTC.1.6.51",
    name: "3.2.S.7.2 Protocolo de estabilidad posterior a la aprobación y compromiso de estabilidad",
    code: "OTC.1.6.51",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.54",
    name: "3.2.P.2.1 Componentes del medicamento: Desarrollo Farmacéutico",
    code: "OTC.1.7.54",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "BR", "US"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.53",
    name: "3.2.P.1 Descripción y composición del medicamento",
    code: "OTC.1.7.53",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.55",
    name: "3.2.P.2.1 Componentes del medicamento: Desarrollo Farmacéutico",
    code: "OTC.1.7.55",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.56",
    name: "3.2.P.2.1 Componentes del medicamento: Desarrollo Farmacéutico",
    code: "OTC.1.7.56",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.57",
    name: "3.2.P.2.2 Medicamento",
    code: "OTC.1.7.57",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.58",
    name: "3.2.P.2.2 Medicamento",
    code: "OTC.1.7.58",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.59",
    name: "3.2.P.2.2 Medicamento",
    code: "OTC.1.7.59",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.60",
    name: "3.2.P.2.3 Desarrollo del proceso de fabricación",
    code: "OTC.1.7.60",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["DO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.63",
    name: "3.2.P.2.6 Compatibilidad",
    code: "OTC.1.7.63",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.61",
    name: "3.2.P.2.4 Sistema de cierre de contenedores",
    code: "OTC.1.7.61",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.62",
    name: "3.2.P.2.5 Atributos microbiológicos",
    code: "OTC.1.7.62",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.64",
    name: "3.2.P.3.1 Fabricante (s)",
    code: "OTC.1.7.64",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.65",
    name: "3.2.P.3.2 Fórmula de lote",
    code: "OTC.1.7.65",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.66",
    name: "3.2.P.3.3 Descripción del proceso de fabricación y controles de proceso",
    code: "OTC.1.7.66",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "DO", "CO", "EC", "PE", "BO", "UY", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.67",
    name: "3.2.P.3.4 Controles de pasos críticos e intermedios",
    code: "OTC.1.7.67",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["DO", "CO", "EC", "PE", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.68",
    name: "3.2.P.3.5 Validación y / o evaluación del proceso  Protocolo para la validación del proceso de fabricación y acondicionamiento.",
    code: "OTC.1.7.68",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "PE", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.69",
    name: "3.2.P.3.5 Validación y / o evaluación del proceso  Informe de validación del proceso de fabricación y acondicionamiento",
    code: "OTC.1.7.69",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "PE", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.70",
    name: "3.2.P.4.1 Especificaciones",
    code: "OTC.1.7.70",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.71",
    name: "3.2.P.4.2 Procedimientos analíticos",
    code: "OTC.1.7.71",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.72",
    name: "3.2.P.4.3 Validación de procedimientos analíticos",
    code: "OTC.1.7.72",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.74",
    name: "3.2.P.4.5 Excipientes de origen humano o animal",
    code: "OTC.1.7.74",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.73",
    name: "3.2.P.4.4 Justificación de las especificaciones",
    code: "OTC.1.7.73",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.75",
    name: "3.2.P.4.6 Excipientes nuevos",
    code: "OTC.1.7.75",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.76",
    name: "3.2.P.5.1 Especificaciones",
    code: "OTC.1.7.76",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.77",
    name: "3.2.P.5.2 Procedimientos analíticos",
    code: "OTC.1.7.77",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.78",
    name: "3.2.P.5.3 Validación de procedimientos analíticos.  Protocolo de validación.",
    code: "OTC.1.7.78",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.79",
    name: "3.2.P.5.3 Validación de procedimientos analíticos.  Reporte de validación.",
    code: "OTC.1.7.79",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.80",
    name: "3.2.P.5.3 Validación de procedimientos analíticos.  Evidencia analítica",
    code: "OTC.1.7.80",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "DO", "CO", "PE", "BO", "UY", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.81",
    name: "3.2.P.5.4 Análisis por lotes.  Certificado de análisis del medicamento (correspondientes a los lotes ingresados a estabilidad)",
    code: "OTC.1.7.81",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.82",
    name: "Evidencia analítica del análisis del medicamento (emitido por el fabricante del medicamento):",
    code: "OTC.1.7.82",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "BO", "UY", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.83",
    name: "3.2.P.5.5 Caracterización de impurezas",
    code: "OTC.1.7.83",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "PE", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.84",
    name: "3.2.P.5.6 Justificación de la (s) especificación (es)",
    code: "OTC.1.7.84",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["GT", "HN", "NI", "SV", "CR", "PA", "DO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.85",
    name: "3.2.P.6 Estándares o materiales de referencia",
    code: "OTC.1.7.85",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.86",
    name: "Información de desarrollo de idoneidad del sistema contenedor cierre",
    code: "OTC.1.7.86",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "SV", "PE", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.88",
    name: "Método de análisis de empaque primario",
    code: "OTC.1.7.88",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "PE", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.89",
    name: "Certificados de análisis de empaque primario (fabricante del medicamento)",
    code: "OTC.1.7.89",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.90",
    name: "Certificados de análisis de empaque primario (proveedor o fabricante del empaque)",
    code: "OTC.1.7.90",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.91",
    name: "Especificaciones de empaque secundario",
    code: "OTC.1.7.91",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "SV", "DO", "CO", "EC", "PE", "BO", "PY", "UY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.92",
    name: "Métodos de análisis de empaque secundario",
    code: "OTC.1.7.92",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "PE", "AR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.87",
    name: "Especificaciones de empaque primario",
    code: "OTC.1.7.87",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "SV", "DO", "CO", "EC", "PE", "BO", "PY", "UY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.93",
    name: "Certificados de análisis de empaque secundario (emitidos por el fabricante del medicamento)",
    code: "OTC.1.7.93",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.94",
    name: "Certificados de análisis de empaque secundario (emitidos por el proveedor o fabricante del empaque)",
    code: "OTC.1.7.94",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.95",
    name: "3.2.P.8.1 Resumen de estabilidad y conclusión",
    code: "OTC.1.7.95",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "PE", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.96",
    name: "Protocolo de estabilidad acelerada",
    code: "OTC.1.7.96",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.97",
    name: "Protocolo de estabilidad a largo plazo (natural) y Protocolo estabilidad de seguimiento",
    code: "OTC.1.7.97",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.98",
    name: "Protocolo de estabilidad en uso",
    code: "OTC.1.7.98",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.99",
    name: "Informe de estabilidad acelerada",
    code: "OTC.1.7.99",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.100",
    name: "Evidencia analítica de estabilidad acelerada (correspondiente a cada periodo)",
    code: "OTC.1.7.100",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "PE", "BO", "UY", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.101",
    name: "Informe del estudio de estabilidad a largo plazo (natural)",
    code: "OTC.1.7.101",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "GT",
      "HN",
      "NI",
      "SV",
      "CR",
      "PA",
      "DO",
      "CO",
      "EC",
      "PE",
      "BO",
      "PY",
      "UY",
      "AR",
      "CL",
      "BR",
      "US",
    ],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.102",
    name: "Evidencia analítica de estabilidad a largo plazo (correspondiente a cada periodo)",
    code: "OTC.1.7.102",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "BO", "UY", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.103",
    name: "Informe de estabilidad en uso (si aplica)",
    code: "OTC.1.7.103",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.7/OTC.1.7.104",
    name: "Evidencia analítica de estabilidad en uso",
    code: "OTC.1.7.104",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "AR", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.8/OTC.1.8.105",
    name: "3.2.A.1 Instalaciones y equipos",
    code: "OTC.1.8.105",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.8/OTC.1.8.106",
    name: "3.2.A.2 Evaluación de seguridad de agentes adventicios",
    code: "OTC.1.8.106",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CL"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.8/OTC.1.8.107",
    name: "3.2.A.3 Excipientes",
    code: "OTC.1.8.107",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.109",
    name: "Máster de fabricación.",
    code: "OTC.1.9.109",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "UY", "AR", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.110",
    name: "Orden de acondicionamiento.",
    code: "OTC.1.9.110",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.111",
    name: "Máster de acondicionamiento",
    code: "OTC.1.9.111",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["CO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.112",
    name: "Muestras de producto terminado.",
    code: "OTC.1.9.112",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["GT", "NI", "CR", "PA", "BO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.113",
    name: "Método de destrucción o manejo de desechos y confinamiento de residuos.",
    code: "OTC.1.9.113",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["PA", "DO", "CO", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.114",
    name: "Interpretación de código de lote.",
    code: "OTC.1.9.114",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["PA", "CO", "EC", "BO", "CL", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.9/OTC.1.9.108",
    name: "Orden de fabricación.",
    code: "OTC.1.9.108",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["MX", "CO", "UY", "BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.1/OTC.1.10/OTC.1.10.115",
    name: "3.3.1  Referencias de literatura",
    code: "OTC.1.10.115",
    isField: true,
    preLoaded: true,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: ["BR"],
    tiposTramite: [TipoTramite.nuevo],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.9/OTC.9.999",
    name: "Pieza publicitaria",
    code: "OTC.9.999",
    isField: true,
    preLoaded: false,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.publicidad],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.999",
    name: "Pieza publicitaria",
    code: "PC.1.999",
    isField: true,
    preLoaded: false,
    details: true,
    categories: ["PC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.publicidad],
    scope: "Tramite",
  },
  {
    path: "/root/NT.1/NT.1.999",
    name: "Pieza publicitaria",
    code: "NT.1.999",
    isField: true,
    preLoaded: false,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.publicidad],
    scope: "Tramite",
  },
  {
    path: "/root/OTC.9/OTC.9.998",
    name: "Soporte técnico",
    code: "OTC.9.998",
    isField: true,
    preLoaded: false,
    details: true,
    categories: ["OTC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.publicidad],
    scope: "Tramite",
  },
  {
    path: "/root/PC.1/PC.1.998",
    name: "Soporte técnico",
    code: "PC.1.998",
    isField: true,
    preLoaded: false,
    details: true,
    categories: ["PC", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.publicidad],
    scope: "Tramite",
  },
  {
    path: "/root/NT.1/NT.1.998",
    name: "Soporte técnico",
    code: "NT.1.998",
    isField: true,
    preLoaded: false,
    details: true,
    categories: ["NT", "OTRA"],
    countries: [
      "MX",
      "DO",
      "GT",
      "HN",
      "SV",
      "NI",
      "CR",
      "PA",
      "CO",
      "EC",
      "PE",
      "BO",
      "CL",
      "AR",
      "UY",
      "PY",
      "US",
      "BR",
    ],
    tiposTramite: [TipoTramite.publicidad],
    scope: "Tramite",
  },
];
