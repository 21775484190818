import { useState } from "react";

import fetchCodigosFormulaList from "services/CodigoFormula/CodigoFormulaList";

/* eslint-disable */
type fetchCodigosFn = (filter: any) => void;
/* eslint-enable */
const useCodigoFormulaCombo = (): [any[], fetchCodigosFn, boolean] => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCodigos = (filter: any) => {
    setIsLoading(true);
    fetchCodigosFormulaList(null, filter, true).then(
      (result) => {
        const { page } = result;
        setItems(page);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return [items, fetchCodigos, isLoading];
};

export default useCodigoFormulaCombo;
