/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// formik components
// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import MDInput from "components/md/MDInput";
import { Control, Controller, FieldValues, UseFormRegister } from "react-hook-form";
import { parseISO, format, isValid } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box } from "@mui/material";

// Declaring props types for FormField
interface Props {
  label: string;
  name: string;
  errors: any;
  control?: Control;
  register: UseFormRegister<FieldValues>;
  [key: string]: any;
}

function FormField({ label, name, errors, control, register, ...rest }: Props): JSX.Element {
  if (rest.type === "date") {
    return (
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={label}
              onChange={(date) =>
                isValid(date) ? field.onChange(format(date, "yyyy-MM-dd")) : field.onChange(null)
              }
              value={field.value && parseISO(field.value)}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MDInput
                    ref={inputRef}
                    {...inputProps}
                    error={!!errors}
                    fullWidth
                    label={label}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
              inputFormat="dd/MM/yyyy"
              {...rest}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                {errors}
              </MDTypography>
            </MDBox>
          </LocalizationProvider>
        )}
      />
    );
  }
  return (
    <MDBox mb={1.5}>
      <MDInput
        {...register(name)}
        {...rest}
        variant="standard"
        label={label}
        fullWidth
        error={!!errors}
        inputProps={{
          ...rest.inputProps,
          autoComplete: "off",
        }}
        InputLabelProps={{ shrink: true }}
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {errors}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormField.defaultProps = {
  control: null,
};

export default FormField;
