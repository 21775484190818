import {
  Backdrop,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Navbar from "components/tramites/Navbar";
import { getNotificacionLink, getNotificacionText } from "globalvars";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useCallback, useRef } from "react";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/md/MDButton";

import {
  useNotificacionListLeidas,
  useNotificacionListPendientes,
} from "./ServiceHooks/useNotificaciones";

function Notificaciones(): JSX.Element {
  const navigate = useNavigate();
  const [leidas, refreshLeidas, fetchNextPageLeidas, isLoadingLeidas, hasMoreLeidas] =
    useNotificacionListLeidas();
  const [
    pendientes,
    refreshPendientes,
    fetchNextPagePendientes,
    isLoadingPendientes,
    hasMorePendientes,
  ] = useNotificacionListPendientes();

  if (1 > 2) console.log(refreshLeidas, refreshPendientes);

  const observerLeidas: any = useRef();
  const observerPendientes: any = useRef();

  const lastElementRefLeidas = useCallback(
    (node) => {
      if (isLoadingLeidas) return;
      if (observerLeidas.current) observerLeidas.current.disconnect();
      observerLeidas.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreLeidas) {
          fetchNextPageLeidas();
        }
      });
      if (node) observerLeidas.current.observe(node);
    },
    [isLoadingLeidas, hasMoreLeidas]
  );

  const lastElementRefPendientes = useCallback(
    (node) => {
      if (isLoadingPendientes) return;
      if (observerPendientes.current) observerPendientes.current.disconnect();
      observerPendientes.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePendientes) {
          fetchNextPagePendientes();
        }
      });
      if (node) observerPendientes.current.observe(node);
    },
    [isLoadingPendientes, hasMorePendientes]
  );

  const dataTableDataLeidas = {
    columns: [{ Header: "notificaciones leídas", accessor: "noti", width: "100%" }],

    rows:
      leidas?.map((noti, index) => {
        const isLastElement = index === leidas.length + 1;
        return {
          noti: (
            <MDBox ml={4} lineHeight={0} mt={-1.5} style={{ marginLeft: "-9px" }}>
              {isLastElement && <div ref={lastElementRefLeidas} />}
              <Link to={`/${getNotificacionLink(noti)}`} key={noti.id}>
                {noti.createdAt?.substring(0, 10)} - {getNotificacionText(noti)}
              </Link>
            </MDBox>
          ),
        };
      }) || [],
  };

  const dataTableDataPendientes = {
    columns: [{ Header: "notificaciones pendientes", accessor: "noti", width: "100%" }],

    rows:
      pendientes?.map((noti, index) => {
        const isLastElement = index === pendientes.length + 1;
        return {
          noti: (
            <MDBox ml={4} lineHeight={0} mt={-1.5} style={{ marginLeft: "-9px" }}>
              {isLastElement && <div ref={lastElementRefPendientes} />}
              <Link to={`/${getNotificacionLink(noti)}`} key={noti.id}>
                {noti.createdAt?.substring(0, 10)} - {getNotificacionText(noti)}
              </Link>
            </MDBox>
          ),
        };
      }) || [],
  };
  return (
    <PageLayout>
      <Navbar isMini isFull />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingLeidas || isLoadingPendientes}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "15px" }}>
              &nbsp;
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <Grid container alignItems="center" my={2} mr={2}>
                  <Grid item xs={11}>
                    <MDBox display="flex" ml={2}>
                      Todas las Notificaciones
                    </MDBox>
                  </Grid>
                  <Grid item xs={1}>
                    <MDBox display="flex" ml={2}>
                      <MDButton variant="gradient" color="light" onClick={() => navigate("/")}>
                        Salir
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox pb={3} px={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        {dataTableDataPendientes.columns.map((column: any) => (
                          <DataTableHeadCell
                            width={column.width ? column.width : "auto"}
                            align="left"
                            sorted={false}
                            key={column.accessor}
                          >
                            {column.Header}
                          </DataTableHeadCell>
                        ))}
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {dataTableDataPendientes.rows.map((row: any, index: any) => (
                        <TableRow
                          ref={index + 1 === pendientes.length ? lastElementRefPendientes : null}
                          key={row.id}
                        >
                          {dataTableDataPendientes.columns.map((cell: any) => (
                            <DataTableBodyCell
                              noBorder={dataTableDataPendientes.rows.length - 1 === index}
                              align={cell.align ? cell.align : "left"}
                              key={cell.accessor}
                            >
                              {row[cell.accessor]}
                            </DataTableBodyCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card id="basic-info" sx={{ overflow: "visible", height: "100%" }}>
              <MDBox pb={3} px={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        {dataTableDataLeidas.columns.map((column: any) => (
                          <DataTableHeadCell
                            width={column.width ? column.width : "auto"}
                            align="left"
                            sorted={false}
                            key={column.accessor}
                          >
                            {column.Header}
                          </DataTableHeadCell>
                        ))}
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {dataTableDataLeidas.rows.map((row: any, index: any) => (
                        <TableRow
                          ref={index + 1 === leidas.length ? lastElementRefLeidas : null}
                          key={row.id}
                        >
                          {dataTableDataLeidas.columns.map((cell: any) => (
                            <DataTableBodyCell
                              noBorder={dataTableDataLeidas.rows.length - 1 === index}
                              align={cell.align ? cell.align : "left"}
                              key={cell.accessor}
                            >
                              {row[cell.accessor]}
                            </DataTableBodyCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Notificaciones;
