/* tslint:disable */
/* eslint-disable */

import { Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDInput from "components/md/MDInput";
import MDProgress from "components/md/MDProgress";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useState } from "react";
import * as Service from "services/Importacion/ImportacionCRUD";

function Import(): JSX.Element {
  const [paisProgress, setpaisProgress] = useState({ done: 0, total: 0 });
  const [autoridadProgress, setautoridadProgress] = useState({ done: 0, total: 0 });
  const [buProgress, setbuProgress] = useState({ done: 0, total: 0 });
  const [categoriaProgress, setcategoriaProgress] = useState({ done: 0, total: 0 });
  const [categoriaTerapeuticaProgress, setcategoriaTerapeuticaProgress] = useState({
    done: 0,
    total: 0,
  });
  const [fffcProgress, setfffcProgress] = useState({ done: 0, total: 0 });
  const [fabricanteProgress, setfabricanteProgress] = useState({ done: 0, total: 0 });
  const [principioProgresss, setprincipioProgresss] = useState({ done: 0, total: 0 });
  const [principioPostaProgresss, setprincipioPostaProgresss] = useState({ done: 0, total: 0 });

  const [PaisIn, setPaisIn] = useState("");
  const [AutoridadIn, setAutoridadIn] = useState("");
  const [BusinessUnitIn, setBusinessUnitIn] = useState("");
  const [CategoriaIn, setCategoriaIn] = useState("");
  const [CategoriaTerapeuticaIn, setCategoriaTerapeuticaIn] = useState("");
  const [FFFCIn, setFFFCIn] = useState("");
  const [FabricanteIn, setFabricanteIn] = useState("");
  const [PrincipioIn, setPrincipioIn] = useState("");
  const [PrincipioPostaIn, setPrincipioPostaIn] = useState("");

  const tuto = {
    PaisIn,
    AutoridadIn,
    BusinessUnitIn,
    CategoriaIn,
    CategoriaTerapeuticaIn,
    FabricanteIn,
    FFFCIn,
    PrincipioIn,
    PrincipioPostaIn,
  };

  const importPaises = () => {
    Service.importPaises(tuto, setpaisProgress);
  };

  const importAutoridades = () => {
    Service.importAutoridades(tuto, setautoridadProgress);
  };

  const importBUs = () => {
    Service.importBUs(tuto, setbuProgress);
  };

  const importCategorias = () => {
    Service.importCategorias(tuto, setcategoriaProgress);
  };

  const importCategoriasTerapeuticas = () => {
    Service.importCategoriasTerapeuticas(tuto, setcategoriaTerapeuticaProgress);
  };

  const importFFFCs = () => {
    Service.importFFFCs(tuto, setfffcProgress);
  };

  const importFabricantes = () => {
    Service.importFabricantes(tuto, setfabricanteProgress);
  };

  const importPrincipios = () => {
    Service.importPrincipios(tuto, setprincipioProgresss, false);
  };

  const importPrincipiosPosta = () => {
    Service.importPrincipios(tuto, setprincipioPostaProgresss, true);
  };

  const updateAllEntries = () => {
    Service.updateAllEntries("TramiteSearch").then(() => {
      Service.updateAllEntries("RegistroSearch");
    });
  };

  return (
    <Layout>
      <Navbar />
      <MDButton onClick={updateAllEntries}>Update</MDButton>
      <MDBox>
        <Grid container spacing={4}>
          <Grid item sm={2}>
            Pais:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPaisIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importPaises}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={parseFloat(((paisProgress.done / paisProgress.total) * 100).toFixed(2)) || 0}
              label
            />
          </Grid>

          <Grid item sm={2}>
            Autoridad:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setAutoridadIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importAutoridades}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={
                parseFloat(((autoridadProgress.done / autoridadProgress.total) * 100).toFixed(2)) ||
                0
              }
              label
            />
          </Grid>

          <Grid item sm={2}>
            Business Unit:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setBusinessUnitIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importBUs}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={parseFloat(((buProgress.done / buProgress.total) * 100).toFixed(2)) || 0}
              label
            />
          </Grid>

          <Grid item sm={2}>
            Categoria:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setCategoriaIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importCategorias}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={
                parseFloat(((categoriaProgress.done / categoriaProgress.total) * 100).toFixed(2)) ||
                0
              }
              label
            />
          </Grid>

          <Grid item sm={2}>
            Categoría terapéutica:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setCategoriaTerapeuticaIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importCategoriasTerapeuticas}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={
                parseFloat(
                  (
                    (categoriaTerapeuticaProgress.done / categoriaTerapeuticaProgress.total) *
                    100
                  ).toFixed(2)
                ) || 0
              }
              label
            />
          </Grid>

          <Grid item sm={2}>
            FF/FC:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setFFFCIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importFFFCs}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={parseFloat(((fffcProgress.done / fffcProgress.total) * 100).toFixed(2)) || 0}
              label
            />
          </Grid>

          <Grid item sm={2}>
            Fabricante:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setFabricanteIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importFabricantes}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={
                parseFloat(
                  ((fabricanteProgress.done / fabricanteProgress.total) * 100).toFixed(2)
                ) || 0
              }
              label
            />
          </Grid>

          <Grid item sm={2}>
            Principio:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPrincipioIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importPrincipios}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={
                parseFloat(
                  ((principioProgresss.done / principioProgresss.total) * 100).toFixed(2)
                ) || 0
              }
              label
            />
          </Grid>

          <Grid item sm={2}>
            Principios validados:
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPrincipioPostaIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            <MDButton onClick={importPrincipiosPosta}>Importar</MDButton>
          </Grid>
          <Grid item sm={6}>
            <MDProgress
              variant="gradient"
              value={
                parseFloat(
                  ((principioPostaProgresss.done / principioPostaProgresss.total) * 100).toFixed(2)
                ) || 0
              }
              label
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={12}> </MDBox>
    </Layout>
  );
}
export default Import;
