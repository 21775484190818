import {
  CodigoFormula,
  EstadoTramite,
  RegistroFabricanteVO,
  RegistroFormulaVO,
  RegistroPresentacionesVO,
  RegistroPrincipioActivoVO,
  TipoTramite,
  Tramite,
} from "API";
import { useState, useEffect } from "react";
import { nanoid } from "nanoid";

import { fetchTramite, updateTramite } from "services/Tramite/TramiteCRUD";
import { fetchCodigoFormula } from "services/CodigoFormula/CodigoFormulaCRUD";

/* eslint-disable */
type comitChangesFn = (
  data: Tramite,
  formulas: CodigoFormula[],
  done: (result: any) => void
) => void;
type doneCallback = (result: any) => void;
type setTramiteFn = (t: Tramite) => void;
type setFormulaFn = (t: CodigoFormula[]) => void;
/* eslint-enable */

const fetchFormula = async (id: string) => fetchCodigoFormula(id);
const fetchFormulas = async (formulas: RegistroFormulaVO[]) => {
  const ret: CodigoFormula[] = [];
  for (let i = 0; i < formulas.length; i += 1) {
    /* eslint-disable */
    ret.push(await fetchFormula(formulas[i].id));
    /* eslint-enable */
  }
  return ret;
};

const cleanTramiteByPais = (tramite: Tramite): Tramite => {
  const tramiteOut = { ...tramite };
  if (!["MX", "CL"].includes(tramite.pais.codigo)) {
    tramiteOut.presentaciones = tramiteOut.presentaciones?.map((p: RegistroPresentacionesVO) => {
      const fabricantes = p.fabricantes?.map((rf: RegistroFabricanteVO) => {
        const { maquiladores } = rf;

        return {
          ...rf,
          maquiladores: tramite.pais.codigo === "MX" ? maquiladores : [],
          fabricantesAPI: [],
        };
      });
      return { ...p, fabricantes };
    });
  }

  return tramiteOut;
};

const updateFormulasInTramite = (tramite: Tramite, formulas: CodigoFormula[]) => {
  if (tramite.estado === EstadoTramite.pendiente) {
    const newFormulas = tramite.formulas?.map((tramiteFormula: RegistroFormulaVO) => {
      if (tramiteFormula.principiosActivos?.length) {
        const newPrincipios = [...tramiteFormula.principiosActivos];
        const currentFormula = formulas.find(
          (codigoFormula: CodigoFormula) => codigoFormula.id === tramiteFormula.id
        );
        if (currentFormula) {
          const indexesToRemove: number[] = [];
          const formulaIndexesPresent: number[] = [];
          tramiteFormula.principiosActivos?.forEach(
            (presentPrincipio: RegistroPrincipioActivoVO, index: number) => {
              let present = false;
              currentFormula.principiosActivos?.forEach(
                (globalPrincipio: RegistroPrincipioActivoVO, formulaIndex: number) => {
                  if (globalPrincipio.principio.id === presentPrincipio.principio.id) {
                    present = true;
                    formulaIndexesPresent.push(formulaIndex);
                  }
                }
              );
              if (!present) {
                indexesToRemove.push(index);
              }
            }
          );
          indexesToRemove.reverse().forEach((index: number) => {
            newPrincipios.splice(index, 1);
          });
          for (let i = 0; i < currentFormula.principiosActivos?.length; i += 1) {
            if (!formulaIndexesPresent.includes(i)) {
              newPrincipios.push(currentFormula.principiosActivos[i]);
            }
          }
        }
        return { ...tramiteFormula, principiosActivos: newPrincipios };
      }
      return tramiteFormula;
    });
    return { ...tramite, formulas: newFormulas };
  }
  return tramite;
};

const useTramiteCRUD = (
  id: string
): {
  tramite: Tramite;
  setTramite: setTramiteFn;
  commitChanges: comitChangesFn;
  formulas: CodigoFormula[];
  setFormulas: setFormulaFn;
} => {
  const [formulas, setFormulas] = useState([] as CodigoFormula[]);
  const [tramite, setTramite] = useState({
    id: "",
    descripcion: "",
    tipo: TipoTramite.nuevo,
    innovacion: false,
    estado: EstadoTramite.pendiente,
    fechaPresentacion: null,
    fechaPresentacionObjetivo: null,
    fechaObjetivo: null,
    fechaObtencion: null,
    fechaLanzamiento: null,
    fechaLanzamientoObjetivo: null,
    fechaLiberacion: null,
    fechaLiberacionObjetivo: null,
    iniciativa: null,
    costosDirectos: "",
    costosIndirectos: "",
    comentarios: "",
    numeroRegistro: "",
    vencimientoSanitario: null,
    objeciones: [],
    formulas: [],
    presentaciones: [],
    dossierPreferences: "",
    dossierCompleteness: 0,
    dossierData: [],
    historico: false,
    deleted: false,
    codigoCPT: "",
    tipoCertificado: null,
    medioDifusion: null,
    etiqueta: "",
  } as Tramite);

  // Get tramite if ID was provided
  useEffect(() => {
    if (id) {
      fetchTramite(id).then(
        (result) => {
          fetchFormulas(result.formulas).then((fetchedFormulas) => {
            const newTramite = updateFormulasInTramite(result, fetchedFormulas);
            setTramite(newTramite);
            setFormulas(fetchedFormulas);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [id]);

  const commitChanges = (
    theTramite: Tramite,
    formulasDossier: CodigoFormula[],
    done: doneCallback
  ) => {
    if (theTramite) {
      const tramiteIn = cleanTramiteByPais(theTramite);

      let newCodigoCPT = tramite.codigoCPT;
      if (!newCodigoCPT) {
        newCodigoCPT = `${tramite.tipo.substring(3, 0)}-${nanoid(10)}`;
        tramiteIn.codigoCPT = newCodigoCPT;
      }

      updateTramite(tramiteIn, formulasDossier).then((commitId) => {
        const newTramite = updateFormulasInTramite({ ...tramiteIn, id: commitId }, formulas);
        setTramite(newTramite);
        done({ ...tramiteIn, id: commitId });

        if (!formulas || formulas.length < 1) {
          fetchFormulas(tramiteIn.formulas).then((fetchedFormulas) => {
            const newTramite = updateFormulasInTramite(tramiteIn, fetchedFormulas);
            setTramite(newTramite);
            setFormulas(fetchedFormulas);
            done({ ...tramiteIn, id: commitId });
          });
        } else {
          const newTramite = updateFormulasInTramite({ ...tramiteIn, id: commitId }, formulas);
          setTramite(newTramite);
          done({ ...tramiteIn, id: commitId });
        }

        /* fetchTramite(commitId).then(
          (result) => {
            const newTramite = updateFormulasInTramite(result, formulas);
            setTramite(newTramite);
             fetchFormulas(result.formulas).then((fetchedFormulas) => {
              const newTramite = updateFormulasInTramite(result, fetchedFormulas);
              setTramite(newTramite);
              setFormulas(fetchedFormulas);
            }); 
            done(result);
          },
          (error) => {
            console.log(error);
            done(error);
          }
        ); */
      });
    }
  };

  return { tramite, setTramite, commitChanges, formulas, setFormulas };
};

export default useTramiteCRUD;
