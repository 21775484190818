import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/md/MDButton";
import { Grid, Switch } from "@mui/material";
import * as Yup from "yup";
import FormField from "components/tramites/FormField";
import MDBox from "components/md/MDBox";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import MDTypography from "components/md/MDTypography";
import { Controller, useForm, UseFormReturn, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import { format } from "date-fns";
import * as APIt from "API";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validations = Yup.object().shape({
  objecion: Yup.string().required("Detalle objeción requerido"),
  fechaObjecion: Yup.string().required("Fecha de objeción requerida"),
  autoridad: Yup.object().required("Autoridad requerida").nullable(true),
  asignableRA: Yup.boolean().required("Asignable RA requerido"),
  limiteEspera: Yup.string().required("Limite espera requerido"),
});

/* eslint-disable */
type SetValueCallback = (values: any) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  valueSetter: SetValueCallback;
  paisId: string;
};

function NewObjecionDialog(params: Props): JSX.Element {
  const { valueSetter, paisId, formMethods } = params;
  const [open, setOpen] = React.useState(false);

  const { control: tramiteControl } = formMethods;

  const watchObjeciones = useWatch({ control: tramiteControl, name: "objeciones" });
  const canAdd =
    !watchObjeciones ||
    watchObjeciones.reduce(
      (acc: boolean, curr: APIt.ObjecionVO) =>
        acc && [APIt.EstadoObjecion.aceptada, APIt.EstadoObjecion.rechazada].includes(curr.estado),
      true
    );

  const {
    register,
    formState: { errors },
    control,
    reset,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const {
    state: { autoridadList },
  } = useTramitesAppStateContext();

  const handleClickOpen = () => {
    reset({
      objecion: "",
      autoridad: null,
      fechaObjecion: "",
      limiteEspera: "",
      asignableRA: false,
      fechaIngreso: format(new Date(), "yyyy-MM-dd"),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const e = getValues();
    trigger().then((valid) => {
      if (!valid) return;

      const data: any = {
        ...e,
        estado: APIt.EstadoObjecion.pendiente,
        descripcion: "",
      };

      console.log(data);

      valueSetter(data);
      setOpen(false);
    });
  };

  React.useEffect(() => {
    reset({
      objecion: "",
      autoridad: null,
      fechaObjecion: "",
      limiteEspera: "",
      asignableRA: false,
      fechaIngreso: format(new Date(), "yyyy-MM-dd"),
    });
  }, []);

  return (
    <div>
      {canAdd && (
        <MDButton variant="outlined" color="info" size="small" onClick={handleClickOpen}>
          Nueva
        </MDButton>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <form onSubmit={() => {}}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Nueva Objeción
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox mr={1}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Controller
                      name="asignableRA"
                      control={control}
                      render={(props: any) => {
                        const { field } = props;
                        return (
                          <Switch
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>Si</Grid>
                </Grid>
              </MDBox>
              <MDBox lineHeight={0} mx={2}>
                <MDTypography variant="button" color="text">
                  Asignable RA
                </MDTypography>
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogContent dividers>
            <MDBox>
              <FormField
                label="Objeción"
                type="text"
                name="objecion"
                errors={errors.objecion?.message}
                register={register}
                multiline
                rows={4}
              />
            </MDBox>
            <MDBox mt={4}>
              <FormField
                label="Fecha"
                type="date"
                name="fechaObjecion"
                errors={errors.fechaObjecion?.message}
                register={register}
                control={control}
              />
            </MDBox>
            <MDBox>
              <FormField
                label="Límite de espera"
                type="date"
                name="limiteEspera"
                errors={errors.limiteEspera?.message}
                register={register}
                control={control}
              />
            </MDBox>
            <MDBox>
              <ControlledAutocomplete
                label="Autoridad"
                name="autoridad"
                options={autoridadList.filter((e) => e.pais.id === paisId)}
                control={control}
                errors={errors.autoridad?.message}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => handleClose()}>
              Cancelar
            </Button>
            <Button autoFocus onClick={() => handleSave()}>
              Ingresar
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default NewObjecionDialog;
