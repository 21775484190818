import { CodigoFormula } from "API";
import { useState, useEffect } from "react";

import { fetchCodigoFormula, updateCodigoFormula } from "services/CodigoFormula/CodigoFormulaCRUD";

/* eslint-disable */
type commitCallback = (result: any) => void;
type comitChangesFn = (data: any, done: commitCallback) => void;
type refetchFn = (id: string) => void;
/* eslint-enable */
const useCodigoFormulaCRUD = (id: string): [CodigoFormula, comitChangesFn, refetchFn] => {
  const [codigoFormula, setCodigoFormula] = useState({
    id: "",
    codigo: "",
    marca: "",
    producto: "",
    bu: null,
    categoria: null,
    categoriaTerapeutica: null,
    formaCosmeticaFarmaceutica: null,
    fabricantes: [],
    principiosActivos: [],
    dossierData: [],
  } as CodigoFormula);

  // get first page
  useEffect(() => {
    if (id) {
      fetchCodigoFormula(id).then(
        (result) => {
          setCodigoFormula(result);
        },
        (error) => console.log(error)
      );
    }
  }, [id]);

  const commitChanges = (data: any, done: commitCallback) => {
    if (data) {
      updateCodigoFormula(data).then((commitId) => {
        fetchCodigoFormula(commitId).then(
          (result) => {
            setCodigoFormula(result);
            done(result);
          },
          (error) => {
            console.log(error);
            done(error);
          }
        );
      });
    }
  };

  const refetch = (id: string) => {
    if (id) {
      fetchCodigoFormula(id).then(
        (result) => {
          setCodigoFormula(result);
        },
        (error) => console.log(error)
      );
    } else {
      setCodigoFormula({
        id: "",
        codigo: "",
        marca: "",
        producto: "",
        bu: null,
        categoria: null,
        categoriaTerapeutica: null,
        formaCosmeticaFarmaceutica: null,
        principiosActivos: [],
        fabricantes: [],
        dossierData: [],
      } as CodigoFormula);
    }
  };

  return [codigoFormula, commitChanges, refetch];
};

export default useCodigoFormulaCRUD;
