import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listIniciativas } from "services/Iniciativa/queries";

const fetchIniciativasList = async (): Promise<{
  iniciativas: APIt.Iniciativa[];
}> => {
  let iniciativas: APIt.Iniciativa[] = [];

  const conditions: APIt.ListIniciativasQueryVariables = { limit: 20000 };

  const result = (await API.graphql(
    graphqlOperation(listIniciativas, conditions)
  )) as GraphQLResult<APIt.ListIniciativasQuery>;

  if (result.data) {
    const resultData: APIt.ListIniciativasQuery = result.data;
    iniciativas = resultData.listIniciativas.items;
  }

  return { iniciativas };
};

export default fetchIniciativasList;
