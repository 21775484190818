import { Card, Grid, Icon, Switch } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDInput from "components/md/MDInput";
import MDTypography from "components/md/MDTypography";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useNavigate } from "react-router-dom";
import AdvancedSearch from "features/Tramite/TramiteList/AdvancedSearch";
import { useCallback, useState } from "react";
import { exportTramitesKPIs } from "services/Export/Tramites/ExportTramitesKPIs";
import DownloadExcel from "./DownloadExcel";

/* eslint-disable */
type RefreshCallback = (overrideFull?: string) => void;
/* eslint-enable */

type Props = {
  refresh: RefreshCallback;
  exportTramites: any;
  tipo: string;
};
export default function SearchBar(params: Props): JSX.Element {
  const { refresh, tipo, exportTramites } = params;
  const navigate = useNavigate();

  const {
    state: { tramiteSearchState, userRol },
    dispatch,
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";

  const [localSearch, setLocalSearch] = useState(tramiteSearchState.full);

  const handleSearch = useCallback(() => {
    dispatch({
      type: "update-tramiteSearchState",
      payload: {
        ...tramiteSearchState,
        full: localSearch,
      },
    });
    refresh(localSearch);
  }, [tramiteSearchState, localSearch]);

  const handleExportTramitesKPIs = () => {
    exportTramitesKPIs();
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Grid container alignItems="center" my={2} mr={2}>
              <Grid item xs={12} md={9}>
                <MDBox display="flex" ml={2}>
                  <Grid container alignItems="center" my={2} mr={2}>
                    <Grid item md={12} lg={6} xl={7}>
                      <MDInput
                        style={{ marginLeft: "5px" }}
                        placeholder="Buscar"
                        size="small"
                        fullWidth
                        value={localSearch}
                        onChange={(e: any) => {
                          setLocalSearch(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={5} lg={2} xl={1}>
                      <MDBox>
                        <MDButton variant="contained" color="info" onClick={handleSearch}>
                          Buscar
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item md={1} lg={1} xl={1}>
                      <MDBox ml={1}>
                        <DownloadExcel exportTramites={exportTramites} />
                      </MDBox>
                    </Grid>
                    <Grid item md={6} lg={3} xl={3}>
                      <MDBox ml={1}>
                        <Grid container direction="row">
                          <Grid item md={4}>
                            <MDBox mt={-0.6}>
                              <Switch
                                onChange={(e) =>
                                  dispatch({
                                    type: "update-tramiteSearchState",
                                    payload: {
                                      ...tramiteSearchState,
                                      advanced: e.target.checked,
                                    },
                                  })
                                }
                                checked={tramiteSearchState.advanced}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={7}>
                            <MDTypography variant="h6">Avanzada</MDTypography>
                          </Grid>
                          <Grid item md={1}>
                            {!tipo && isUserGlobal && (
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => handleExportTramitesKPIs()}
                              >
                                Descargar KPIs
                              </MDButton>
                            )}
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
                {tipo && tipo !== "CREG" && (
                  <MDBox mr={3}>
                    <MDButton variant="gradient" color="info" onClick={() => navigate("nueva")}>
                      <Icon>add</Icon>&nbsp; Nuevo trámite
                    </MDButton>
                  </MDBox>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {tramiteSearchState.advanced && <AdvancedSearch tipo={tipo} />}
      </Grid>
    </form>
  );
}
