import { formatExportDate, linkBase } from "globalvars";
import { ExportConfigEntry, ExportDataEntry } from "../ExcelExporter";

const getFormulaExportType = (dataEntry: ExportDataEntry[]) => {
  const showFormulasJoined = dataEntry.some(
    (entry) =>
      [
        "formulasJoined",
        "marcasJoined",
        "categoriasJoined",
        "buJoined",
        "categoriaTerapeuticaJoined",
        "formaCosmeticaFarmaceuticaJoined",
        "principioJoined",
      ].includes(entry.id) && !entry.hidden
  );
  const showFormulasGroup = dataEntry.some(
    (entry) =>
      [
        "codigo",
        "marca",
        "categoria",
        "bu",
        "formaCosmeticaFarmaceutica",
        "categoriaTerapeutica",
        "principio",
        "concentracion",
      ].includes(entry.id) && !entry.hidden
  );
  if (!showFormulasJoined && !showFormulasGroup) {
    return null;
  }
  if (showFormulasJoined) return "J";
  return "G";
};

const tramitesConfig: ExportConfigEntry[] = [
  {
    id: "link",
    column: "Link",
    field: "id",
    extractionFunction: (data: any) => `${linkBase}todos/${data}`,
  },
  {
    id: "codigoCPT",
    column: "Código Identificador",
    field: "codigoCPT",
  },
  {
    id: "tipo",
    column: "Tipo",
    field: "tipo",
  },
  {
    id: "estado",
    column: "Estado",
    field: "estado",
  },
  {
    id: "innovacion",
    column: "Es Innovación?",
    field: "innovacion",
    extractionFunction: (data: any) => (data ? "Si" : "No"),
  },
  {
    id: "numeroRegistro",
    column: "# Registro",
    field: "numeroRegistro",
  },
  {
    id: "vencimientoSanitario",
    column: "Vencimiento sanitario",
    field: "vencimientoSanitario",
  },
  {
    id: "etiqueta",
    column: "Etiqueta",
    field: "etiqueta",
  },
  {
    id: "dossierCompleteness",
    column: "Porcentaje Dossier",
    field: "dossierCompleteness",
  },
  {
    id: "createdAt",
    column: "Fecha de Activación",
    field: "createdAt",
    extractionFunction: (data: any) => formatExportDate(data),
  },
  {
    id: "fechaLiberacion",
    column: "Fecha de Liberación",
    field: "fechaLiberacion",
  },
  {
    id: "fechaLiberacionObjetivo",
    column: "F. de Liberación Obj.",
    field: "fechaLiberacionObjetivo",
  },
  {
    id: "fechaPresentacion",
    column: "Fecha de Presentación",
    field: "fechaPresentacion",
  },
  {
    id: "fechaPresentacionObjetivo",
    column: "F. de Presentación Obj.",
    field: "fechaPresentacionObjetivo",
  },
  {
    id: "fechaObtencion",
    column: "Fecha de Obtención/Rechazo",
    field: "fechaObtencion",
  },
  {
    id: "fechaObjetivo",
    column: "F. de Obtención/Rechazo Obj.",
    field: "fechaObjetivo",
  },
  {
    id: "fechaLanzamiento",
    column: "Fecha de Lanzamiento",
    field: "fechaLanzamiento",
  },
  {
    id: "fechaLanzamientoObjetivo",
    column: "F. de Lanzamiento Obj.",
    field: "fechaLanzamientoObjetivo",
  },
  {
    id: "pais",
    column: "País",
    field: "pais.nombre",
  },
  {
    id: "autoridad",
    column: "Autoridad",
    field: "autoridad.nombre",
  },
  {
    id: "iniciativa",
    column: "Iniciativa",
    field: "iniciativa.nombre",
  },
  {
    id: "prioridad",
    column: "Prioridad",
    field: "prioridad.nombre",
  },
  {
    id: "medioDifusion",
    column: "M. Difusión",
    field: "medioDifusion.nombre",
  },
  {
    id: "tipoCertificado",
    column: "Tipo Certificado",
    field: "tipoCertificado.nombre",
  },
  {
    id: "costosDirectos",
    column: "Costos Directos",
    field: "costosDirectos",
  },
  {
    id: "costosIndirectos",
    column: "Costos Indirectos",
    field: "costosIndirectos",
  },
  {
    id: "comentarios",
    column: "Comentarios",
    field: "comentarios",
  },
  /**
   *
   * FORMULAS JOINED
   *
   */
  {
    id: "formulasJoined",
    column: "Fórmula",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => (acc ? `${acc}|${curr.codigo}` : curr.codigo), ""),
  },
  {
    id: "marcasJoined",
    column: "Marca",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => (acc ? `${acc}|${curr.marca}` : curr.marca), ""),
  },
  {
    id: "categoriasJoined",
    column: "Categoría",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc && curr?.categoria?.codigo && !acc.includes(curr.categoria.codigo)
            ? `${acc}|${curr.categoria.codigo}`
            : curr.categoria.codigo,
        ""
      ),
  },
  {
    id: "buJoined",
    column: "B.U.",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc && curr?.bu?.codigo && !acc.includes(curr?.bu?.codigo)
            ? `${acc}|${curr?.bu?.codigo}`
            : curr?.bu?.codigo,
        ""
      ),
  },
  {
    id: "categoriaTerapeuticaJoined",
    column: "Categoría terapéutica",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc &&
          curr?.categoriaTerapeutica?.nombre &&
          !acc.includes(curr?.categoriaTerapeutica?.nombre)
            ? `${acc}|${curr?.categoriaTerapeutica?.nombre}`
            : curr?.categoriaTerapeutica?.nombre,
        ""
      ),
  },
  {
    id: "formaCosmeticaFarmaceuticaJoined",
    column: "FF/FC",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc &&
          curr?.formaCosmeticaFarmaceutica?.nombre &&
          !acc.includes(curr?.formaCosmeticaFarmaceutica?.nombre)
            ? `${acc}|${curr?.formaCosmeticaFarmaceutica?.nombre}`
            : curr?.formaCosmeticaFarmaceutica?.nombre,
        ""
      ),
  },
  {
    id: "principioJoined",
    column: "Ppo. Activo/Ingrediente",
    field: "formulas",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => {
        let ret = acc;
        const principios = curr.principiosActivos?.map((entry: any) => entry.principio.nombre);
        principios?.forEach((entry: string) => {
          ret = ret && !ret.includes(entry) ? `${ret}, ${entry}` : entry;
        });

        return ret;
      }, ""),
  },
  /**
   *
   * FORMULAS GROUP
   *
   */
  {
    id: "formulasGroup",
    column: "Formulas",
    field: "formulas",
    isGroup: true,
    hidden: true,
    groupMatchFilter: () => true,
    group: [
      {
        id: "codigo",
        column: "Código de fórmula",
        field: "codigo",
      },
      {
        id: "marca",
        column: "Marca",
        field: "marca",
      },
      {
        id: "categoria",
        column: "Categoria",
        field: "categoria.codigo",
      },
      {
        id: "bu",
        column: "B.U.",
        field: "bu.nombre",
      },
      {
        id: "formaCosmeticaFarmaceutica",
        column: "FF/FC",
        field: "formaCosmeticaFarmaceutica.nombre",
      },
      {
        id: "categoriaTerapeutica",
        column: "Categoría Terapéutica",
        field: "categoriaTerapeutica.nombre",
      },
      {
        id: "principiosActivosGroup",
        column: "Principios Activos",
        field: "principiosActivos",
        isGroup: true,
        hidden: true,
        groupMatchFilter: () => true,
        group: [
          {
            id: "principio",
            column: "Principio activo",
            field: "principio.nombre",
          },
          {
            id: "concentracion",
            column: "Concentración",
            field: "concentracion",
          },
        ],
      },
    ],
  },
  /**
   *
   * PRESENTACIONES JOINED
   *
   */
  {
    id: "productosJoined",
    column: "Producto",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce(
          (acc: string, curr: any) =>
            acc && curr?.producto && !acc.includes(curr?.producto)
              ? `${acc}|${curr?.producto}`
              : curr?.producto,
          ""
        ),
  },
  {
    id: "presentacionesJoined",
    column: "Presentaciones",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce(
          (acc: string, curr: any) =>
            acc && curr?.presentaciones && !acc.includes(curr?.presentaciones)
              ? `${acc}|${curr?.presentaciones}`
              : curr?.presentaciones,
          ""
        ),
  },
  {
    id: "presentacionesFabricantesJoined",
    column: "Fabricantes PT",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          const fabricantes = curr.fabricantes?.map((entry: any) => entry.fabricante.nombre);
          let ret = acc;
          fabricantes.forEach((entry: string) => {
            ret = ret && !ret.includes(entry) ? `${ret}|${entry}` : entry;
          });
          return ret;
        }, ""),
  },
  {
    id: "presentacionesFabricantesAPIJoined",
    column: "Fabricantes API",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const fabricantesAPI = fabricante.fabricantesAPI?.map((entry: any) => entry.nombre);
            fabricantesAPI?.forEach((entry: string) => {
              ret = ret && !ret.includes(entry) ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  {
    id: "presentacionesMaquiladoresJoined",
    column: "Maquiladores",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const maquiladores = fabricante.maquiladores?.map((entry: any) => entry.nombre);
            maquiladores?.forEach((entry: string) => {
              ret = ret && !ret.includes(entry) ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  {
    id: "presentacionesAcondicionadoresPrimariosJoined",
    column: "Acondicionadores Primarios",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const acondicionadoresPrimarios = fabricante.acondicionadoresPrimarios?.map(
              (entry: any) => entry.acondicionadorPrimario.nombre
            );
            acondicionadoresPrimarios?.forEach((entry: string) => {
              ret = ret ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  {
    id: "presentacionesAcondicionadoresPrimariosComplianceJoined",
    column: "Compliance",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const values = fabricante.acondicionadoresPrimarios?.map((entry: any) =>
              entry.compliance ? "Si" : "No"
            );
            values?.forEach((entry: string) => {
              ret = ret ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  {
    id: "presentacionesAcondicionadoresPrimariosEstabilidadJoined",
    column: "Estabilidad",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const values = fabricante.acondicionadoresPrimarios?.map((entry: any) =>
              entry.estabilidad ? "Si" : "No"
            );
            values?.forEach((entry: string) => {
              ret = ret ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  {
    id: "presentacionesAcondicionadoresPrimariosEstatusVentaJoined",
    column: "Estatus Venta",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const values = fabricante.acondicionadoresPrimarios?.map(
              (entry: any) => entry.estatusVenta
            );
            values?.forEach((entry: string) => {
              ret = ret ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  {
    id: "presentacionesAcondicionadoresPrimariosVidaUtilJoined",
    column: "Vida Útil",
    field: "presentaciones",
    extractionFunction: (data: any, dataEntry: ExportDataEntry[]) =>
      data
        ?.filter((e: any) => {
          const formulaExportType = getFormulaExportType(dataEntry);

          // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
          if (formulaExportType === null || formulaExportType === "J") return true;

          // SI SE MUESTRA UNA LÍNEA POR FÓRMULA FILTRAR LO QUE NO CORRESPONDA
          const codigoField = dataEntry.find((entry) => entry.id === "codigo");
          return codigoField && e.formulas.includes(codigoField.value);
        })
        .reduce((acc: string, curr: any) => {
          let ret = acc;
          curr.fabricantes?.forEach((fabricante: any) => {
            const values = fabricante.acondicionadoresPrimarios?.map((entry: any) =>
              entry.vidaUtil ? entry.vidaUtil : "Pendiente"
            );
            values?.forEach((entry: string) => {
              ret = ret ? `${ret}|${entry}` : entry;
            });
          });

          return ret;
        }, ""),
  },
  /**
   *
   * PRESENTACIONES GROUP
   *
   */
  {
    id: "presentacionesGroup",
    column: "Presentaciones",
    field: "presentaciones",
    isGroup: true,
    hidden: true,
    groupMatchFilter: (groupEntry: ExportDataEntry[], dataEntry: ExportDataEntry[]) => {
      const formulaExportType = getFormulaExportType(dataEntry);

      // SI NO SE MUESTRA DATA DE FORMULA, O SE MUESTRA JOINEADO VA TODO SIEMPRE
      if (formulaExportType === null || formulaExportType === "J") return true;

      // CHEQUEAR CADA FILA SI ES DE UNA FÓRMULA QUE APLICA A LA PRESENTACIÓN
      const codigoField = dataEntry.find((entry) => entry.id === "codigo");
      const formulas = groupEntry.find((entry) => entry.id === "presentacionesFormulas").value;
      return codigoField && formulas.includes(codigoField.value);
    },
    group: [
      {
        id: "presentacionesFormulas",
        column: "Formulas",
        field: "formulas",
        hidden: true,
      },
      {
        id: "producto",
        column: "Producto",
        field: "producto",
      },
      {
        id: "presentaciones",
        column: "Presentaciones",
        field: "presentaciones",
      },
      {
        id: "presentacionFabricantesGroup",
        column: "Fabricantes",
        field: "fabricantes",
        isGroup: true,
        hidden: true,
        groupMatchFilter: () => true,
        group: [
          {
            id: "presentacionFabricante",
            column: "Fabricante PT",
            field: "fabricante.nombre",
          },
          {
            id: "presentacionFabricanteDirección",
            column: "Dirección Fabricante PT",
            field: "fabricante.direccion",
          },
          {
            id: "presentacionFabricantePais",
            column: "País Fabricante PT",
            field: "fabricante.pais.nombre",
          },
          {
            id: "presentacionFabricanteVencimiento",
            column: "Vencimiento Fabricante PT",
            field: "fabricante.vencimientoBPM",
          },
          {
            id: "fabricantesAPIGroup",
            column: "Fabricantes API",
            field: "fabricantesAPI",
            isGroup: true,
            hidden: true,
            groupMatchFilter: () => true,
            group: [
              {
                id: "presentacionFabricanteAPI",
                column: "Fabricante API",
                field: "nombre",
              },
              {
                id: "presentacionFabricanteAPIDirección",
                column: "Dirección Fabricante API",
                field: "direccion",
              },
              {
                id: "presentacionFabricanteAPIPais",
                column: "País Fabricante API",
                field: "pais.nombre",
              },
            ],
          },
          {
            id: "presentacionMaquiladoresGroup",
            column: "Maquiladores",
            field: "maquiladores",
            isGroup: true,
            hidden: true,
            groupMatchFilter: () => true,
            group: [
              {
                id: "presentacionMaquiladores",
                column: "Maquilador",
                field: "nombre",
              },
              {
                id: "presentacionMaquiladoresDirección",
                column: "Dirección Maquilador",
                field: "direccion",
              },
              {
                id: "presentacionMaquiladoresPais",
                column: "País Maquilador",
                field: "pais.nombre",
              },
            ],
          },
          {
            id: "presentacionAcondicionadoresPrimariosGroup",
            column: "Acondicionadores Primarios",
            field: "acondicionadoresPrimarios",
            isGroup: true,
            hidden: true,
            groupMatchFilter: () => true,
            group: [
              {
                id: "acondicionadorPrimario",
                column: "Acondicionador",
                field: "acondicionadorPrimario.nombre",
              },
              {
                id: "acondicionadorPrimarioDireccion",
                column: "Acondicionador Dirección",
                field: "acondicionadorPrimario.direccion",
              },
              {
                id: "acondicionadorPrimarioPais",
                column: "Acondicionador País",
                field: "acondicionadorPrimario.pais.nombre",
              },
              {
                id: "acondicionadorPrimarioCompliance",
                column: "Compliance",
                field: "compliance",
              },
              {
                id: "acondicionadorPrimarioEstabilidad",
                column: "Estabilidad",
                field: "estabilidad",
                extractionFunction: (data: any) => (data ? "Si" : "No"),
              },
              {
                id: "acondicionadorPrimarioEstatusVenta",
                column: "Estatus venta",
                field: "estatusVenta",
              },
              {
                id: "acondicionadorPrimarioVidaUtil",
                column: "Vida Útil",
                field: "vidaUtil",
              },
            ],
          },
        ],
      },
    ],
  },
  /**
   *
   * OBJECIONES JOINED
   *
   */
  {
    id: "objecionJoined",
    column: "Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.objecion}` : curr.objecion),
        ""
      ),
  },
  {
    id: "objecionFechaJoined",
    column: "Fecha Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.fechaObjecion}` : curr.fechaObjecion),
        ""
      ),
  },
  {
    id: "objecionLimiteEsperaJoined",
    column: "Límite Espera Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.limiteEspera}` : curr.limiteEspera),
        ""
      ),
  },
  {
    id: "objecionAutoridadJoined",
    column: "Autoridad Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc ? `${acc}|${curr.autoridad.nombre}` : curr.autoridad.nombre,
        ""
      ),
  },
  {
    id: "objecionAsignableRAJoined",
    column: "Límite Espera Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => {
        const asignable = curr.asignableRA ? "Si" : "No";
        return acc ? `${acc}|${asignable}` : asignable;
      }, ""),
  },
  {
    id: "objecionFechaRespuestaJoined",
    column: "Fecha Respuesta Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc ? `${acc}|${curr.fechaRespuesta || "Pendiente"}` : curr.fechaRespuesta || "Pendiente",
        ""
      ),
  },
  {
    id: "objecionRespuestaJoined",
    column: "Respuesta Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc ? `${acc}|${curr.descripcion || "Pendiente"}` : curr.descripcion || "Pendiente",
        ""
      ),
  },
  {
    id: "objecionEstadoJoined",
    column: "Estado Objeción",
    field: "objeciones",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => (acc ? `${acc}|${curr.estado}` : curr.estado), ""),
  },
  /**
   *
   * OBJECIONES GROUP
   *
   */
  {
    id: "objecionesGroup",
    column: "Objeciones",
    field: "objeciones",
    isGroup: true,
    hidden: true,
    groupMatchFilter: () => true,
    group: [
      {
        id: "objecion",
        column: "Objeción",
        field: "objecion",
      },
      {
        id: "fechaObjecion",
        column: "Fecha objeción",
        field: "fechaObjecion",
      },
      {
        id: "limiteEspera",
        column: "Fecha límite de respuesta",
        field: "limiteEspera",
      },
      {
        id: "objecionAutoridad",
        column: "Autoridad",
        field: "autoridad.nombre",
      },
      {
        id: "asignableRA",
        column: "Asignable RA?",
        field: "asignableRA",
        extractionFunction: (data: any) => (data ? "Si" : "No"),
      },
      {
        id: "fechaRespuesta",
        column: "Fecha de respuesta",
        field: "fechaRespuesta",
      },
      {
        id: "respuesta",
        column: "Respuesta",
        field: "descripcion",
      },
      {
        id: "objecionEstado",
        column: "Estado",
        field: "estado",
      },
    ],
  },
];

export default tramitesConfig;
