import { useNavigate, useParams } from "react-router-dom";

// @material-ui core components
import Grid from "@mui/material/Grid";
import { Backdrop, Card, CircularProgress } from "@mui/material";

import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import MDButton from "components/md/MDButton";

import Navbar from "components/tramites/Navbar";
import FormulaDetalle from "features/CodigoFormula/CodigoFormulaEdit/FormulaDetalle";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, useWatch } from "react-hook-form";

import useCodigoFormulaCRUD from "features/CodigoFormula/ServiceHooks/useCodigoFormulaCRUD";
import { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { toast } from "react-toastify";
import FormulaPrincipiosNew from "./FormulaPrincipiosNew";

const validations = Yup.object().shape({
  marca: Yup.string().required("Marca requerida"),
  producto: Yup.string().required("Producto requerido"),
  bu: Yup.object().required("Business Unit requerido").nullable(true),
  // categoriaTerapeutica: Yup.object().required("Categoría terapéutica requerida").nullable(true),
  formaCosmeticaFarmaceutica: Yup.object().required("FC/FF requerida").nullable(true),
  categoria: Yup.object().required("Cateogria requerida").nullable(true),
  principioActivo: Yup.array().of(
    Yup.object().shape({
      // concentracion: Yup.string().required("Concentracion requerida"),
      principio: Yup.object().required("Principio requerido").nullable(true),
      // fabricante: Yup.object().required("Fabricante requerido").nullable(true),
    })
  ),
});

function CodigoFormulaEdit(): JSX.Element {
  const { id: idParam } = useParams();
  const id = idParam === "nueva" ? undefined : idParam;

  const [codigoFormula, commitChanges] = useCodigoFormulaCRUD(id);
  const navigate = useNavigate();
  const [adding] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    dispatch,
    state: { userRol },
  } = useTramitesAppStateContext();

  const {
    register,
    formState: { errors },
    setValue,
    control,
    reset,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const { fields, prepend, append, update, remove } = useFieldArray({
    control,
    name: "principiosActivos",
    keyName: "arrayKey",
  });

  const watchCategoria = useWatch({ control, name: "categoria" });

  useEffect(() => {
    reset(codigoFormula);
    setLoading(false);
  }, [codigoFormula]);

  useEffect(() => {
    if (id) setLoading(true);
  }, []);

  const doCommitChanges = async () => {
    commitChanges(getValues(), (result: any) => {
      setLoading(false);
      dispatch({
        type: "update-formula-list-entry",
        payload: result,
      });
    });
  };

  const save = () => {
    trigger().then((valid) => {
      if (valid) {
        setLoading(true);
        doCommitChanges();
      } else {
        toast.error("Verificar campos obligatorios");
        setLoading(false);
      }
    });
  };

  const saveAndClose = () => {
    setLoading(true);
    trigger().then((valid) => {
      if (valid) {
        doCommitChanges().then(() => {});
        navigate("/codigo-formula");
      } else {
        toast.error("Verificar campos obligatorios");
        setLoading(false);
      }
    });
  };

  return (
    <PageLayout>
      <Navbar isMini isFull />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDBox ml={1}>
              <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "15px" }}>
                Código de fórmula
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormulaDetalle
                formData={{
                  register,
                  errors,
                  setValue,
                  control,
                  values: getValues(),
                  adding,
                  append,
                  remove,
                }}
              />
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
            {watchCategoria?.codigo === "OTC" && (
              <Grid item xs={12}>
                <FormulaPrincipiosNew
                  formData={{ fields, prepend, update, remove }}
                  codigoFormula={codigoFormula}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card id="basic-info" sx={{ overflow: "visible", paddingRight: 4 }}>
                <Grid container direction="row-reverse" my={2} mr={2} spacing={1}>
                  <Grid item>
                    {userRol === "Global" && (
                      <MDButton variant="gradient" color="info" onClick={saveAndClose}>
                        Guardar y Salir
                      </MDButton>
                    )}
                  </Grid>
                  <Grid item>
                    {userRol === "Global" && (
                      <MDButton variant="gradient" color="info" type="submit">
                        Guardar
                      </MDButton>
                    )}
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="gradient"
                      color="light"
                      onClick={() => navigate("/codigo-formula")}
                    >
                      Salir
                    </MDButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </form>
    </PageLayout>
  );
}

export default CodigoFormulaEdit;
