import { DashboardTramiteYearly } from "API";
import { getBasePieOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (initialData: DashboardTramiteYearly[]) => {
  const header = "Registros";
  const labels: any[] = [];

  // SET LABELS
  if (initialData?.length > 0) {
    initialData.forEach((e: DashboardTramiteYearly) => {
      if (!labels.includes(e.year)) {
        labels.push(e.year);
      }
    });
  }
  labels.sort();

  // BUILD DATASETS
  const registrosData: number[] = [];
  labels.forEach((e) => {
    const aprobado = initialData?.find((a) => a.year === e);

    if (aprobado) {
      registrosData.push(aprobado.registros);
    } else {
      registrosData.push(0);
    }
  });

  const datasets = [
    {
      label: "Registros",
      data: registrosData,
      borderColor: dashboardColors.green.border,
    },
  ];

  const data: any = {
    labels,
    datasets,
  };

  return { header, data, options: getBasePieOptions() };
};

export default useData;
