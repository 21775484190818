/* tslint:disable */
/* eslint-disable */
import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as mutations from "graphql/mutations";

import * as APIt from "API";
import saveCodigoFormula from "services/FunctionsHack/SaveFormula";
import saveRegistro from "services/FunctionsHack/SaveRegistro";
import { prepareForSearch } from "globalvars";

const excelDateToDate = (excelDate: string) => {
  return excelDate &&
    excelDate != "NA" &&
    excelDate != "" &&
    excelDate != "TBC" &&
    excelDate != "-" &&
    excelDate != "N/A"
    ? new Date(Date.UTC(0, 0, +excelDate)).toISOString().split("T")[0]
    : null;
};

const translateFloat = (costoString: string) => {
  if (!costoString || isNaN(Number(costoString.replaceAll(",", ".")))) return null;

  return parseFloat(costoString.replaceAll(",", "."));
};

const getCondicionVenta = (condicionString: string) => {
  if (!condicionString) return null;

  if (condicionString === "Venta libre") return APIt.CondicionVenta.VentaLibre;
  else if (condicionString.startsWith("OTC")) return APIt.CondicionVenta.OTC;
  else if (condicionString.startsWith("RX")) return APIt.CondicionVenta.RX;
  else return null;
};

const getEstatusVenta = (estatusString: string) => {
  if (estatusString === "Comercializado") return APIt.EstatusVenta.comercializado;
  else if (estatusString === "No comercializado") return APIt.EstatusVenta.nocomercializado;
  else return APIt.EstatusVenta.nocomercializado;
};

const getVidaUtil = (vidaUtil: string): number => {
  if (!vidaUtil) return null;
  if (vidaUtil.indexOf("d") > -1) {
    const number = vidaUtil.substring(0, vidaUtil.indexOf(" "));
    return Math.floor(+number / 30);
  }

  const number = vidaUtil.substring(0, vidaUtil.indexOf(" "));
  return +number;
};

// GET DB ENTRIES
const fetchObjectEntries = async (object: string, itemSelect: string) => {
  let plural = object.endsWith("ch") ? `${object}es` : object;
  plural = plural.endsWith("s") ? plural : `${plural}s`;
  let responseData: any[] = [];
  let nextToken = null;
  do {
    let query = `
        query list {
            list${plural}(limit: 500${nextToken ? `, nextToken: "${nextToken}"` : ""}) {
                items {
                    ${itemSelect}
                }
                nextToken
            }
        }
    `;
    let result = (await API.graphql(graphqlOperation(query, {}))) as any;
    if (result.data) {
      const list = result.data[`list${plural}`].items;
      nextToken = result.data[`list${plural}`].nextToken;

      for (let i = 0; i < list.length; i++) {
        responseData.push(list[i]);
      }
    } else break;
  } while (nextToken != null);

  return responseData;
};

// CLEAN TABLE
const deleteObjectEntries = async (object: string) => {
  const entries = (await fetchObjectEntries(object, "id")) as any[];

  for (let i = 0; i < entries.length; i++) {
    const id = entries[i].id;
    await API.graphql(graphqlOperation(`mutation {delete${object}(input: {id: "${id}"}){id}}`, {}));
  }
};

// HACK
export const updateAllEntries = async (object: string) => {
  console.log(`Starting with ${object}`);
  const entries = (await fetchObjectEntries(object, "id")) as any[];

  for (let i = 0; i < entries.length; i++) {
    console.log(`Updating ${i} of ${entries.length}`);
    const id = entries[i].id;
    await API.graphql(graphqlOperation(`mutation {update${object}(input: {id: "${id}"}){id}}`, {}));
  }
};

export const importPaises = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("Pais");
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.PaisIn);
  for (var i = 0; i < data.length; i++) {
    const pais = data[i];
    const input: APIt.CreatePaisInput = {
      codigo: pais.CODIGO,
      nombre: pais.NOMBRE,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createPais, { input })
    )) as GraphQLResult<APIt.CreatePaisMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importAutoridades = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("Autoridad");
  console.log("Fetching paises");
  const paisItem = `
    id
    codigo
    nombre
  `;
  const paises = (await fetchObjectEntries("Pais", paisItem)) as APIt.Pais[];
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.AutoridadIn);
  for (var i = 0; i < data.length; i++) {
    const autoridad = data[i];
    const pais = paises.find((p: APIt.Pais) => p.codigo === autoridad.pais);
    if (!pais) console.log(autoridad);
    const input: APIt.CreateAutoridadInput = {
      nombre: autoridad.nombre,
      paisId: pais.id,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createAutoridad, { input })
    )) as GraphQLResult<APIt.CreateAutoridadMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importBUs = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("BusinessUnit");
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.BusinessUnitIn);
  for (var i = 0; i < data.length; i++) {
    const businesUnit = data[i];
    const input: APIt.CreateBusinessUnitInput = {
      codigo: businesUnit.CODIGO,
      nombre: businesUnit.NOMBRE,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createBusinessUnit, { input })
    )) as GraphQLResult<APIt.CreateBusinessUnitMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importCategorias = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("Categoria");
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.CategoriaIn);
  for (var i = 0; i < data.length; i++) {
    const categoria = data[i];
    const input: APIt.CreateCategoriaInput = {
      codigo: categoria.CODIGO,
      nombre: categoria.NOMBRE,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createCategoria, { input })
    )) as GraphQLResult<APIt.CreateCategoriaMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importCategoriasTerapeuticas = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("CategoriaTerapeutica");
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.CategoriaTerapeuticaIn);
  for (var i = 0; i < data.length; i++) {
    const CategoriaTerapeutica = data[i];
    const input: APIt.CreateCategoriaTerapeuticaInput = {
      nombre: CategoriaTerapeutica.NOMBRE,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createCategoriaTerapeutica, { input })
    )) as GraphQLResult<APIt.CreateCategoriaTerapeuticaMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importFFFCs = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("FormaCosmeticaFarmaceutica");
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.FFFCIn);
  for (var i = 0; i < data.length; i++) {
    const FormaCosmeticaFarmaceutica = data[i];
    const input: APIt.CreateFormaCosmeticaFarmaceuticaInput = {
      nombre: FormaCosmeticaFarmaceutica.NOMBRE,
      historico: true,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createFormaCosmeticaFarmaceutica, { input })
    )) as GraphQLResult<APIt.CreateFormaCosmeticaFarmaceuticaMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importFabricantes = async (tuto: any, updateImportStatus: any) => {
  console.log("Deleting old entries");
  await deleteObjectEntries("Fabricante");
  console.log("Fetching paises");
  const paisItem = `
    id
    codigo
    nombre
  `;
  const paises = (await fetchObjectEntries("Pais", paisItem)) as APIt.Pais[];
  console.log("Inserting new entries");
  const data = JSON.parse(tuto.FabricanteIn);
  for (var i = 0; i < data.length; i++) {
    const Fabricante = data[i];

    const pais = paises.find((p: APIt.Pais) => p.codigo === Fabricante.SU);
    if (!pais) console.log(Fabricante);

    const input: APIt.CreateFabricanteInput = {
      paisId: pais.id,
      nombre: Fabricante.NOMBRE,
      direccion: Fabricante.DIRECCION?.trim(),
      vencimientoBPM: excelDateToDate(Fabricante.FECHA?.trim()),
      historico: true,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createFabricante, { input })
    )) as GraphQLResult<APIt.CreateFabricanteMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importPrincipios = async (tuto: any, updateImportStatus: any, posta: boolean) => {
  console.log("Deleting old entries");
  if (!posta) await deleteObjectEntries("PrincipioActivo");
  console.log("Inserting new entries");
  const data = JSON.parse(posta ? tuto.PrincipioPostaIn : tuto.PrincipioIn);
  for (var i = 0; i < data.length; i++) {
    const PrincipioActivo = data[i];
    const input: APIt.CreatePrincipioActivoInput = {
      nombre: PrincipioActivo.NOMBRE,
      historico: !posta,
    };

    const createRequest = (await API.graphql(
      graphqlOperation(mutations.createPrincipioActivo, { input })
    )) as GraphQLResult<APIt.CreatePrincipioActivoMutation>;

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

export const importFormulas = async (tuto: any, updateImportStatus: any) => {
  console.log("Fetching categorias");
  const categoriaItem = `
    id
    codigo
    nombre
  `;
  const categorias = (await fetchObjectEntries("Categoria", categoriaItem)) as APIt.Categoria[];
  console.log("Fetching BusinessUnits");
  const businessUnitItem = `
    id
    codigo
    nombre
  `;
  const businessUnits = (await fetchObjectEntries(
    "BusinessUnit",
    businessUnitItem
  )) as APIt.BusinessUnit[];
  console.log("Fetching Formas Cosméticas/Farmacéuticas");
  const fffcItem = `
    id
    nombre
  `;
  const fffcs = (await fetchObjectEntries(
    "FormaCosmeticaFarmaceutica",
    fffcItem
  )) as APIt.FormaCosmeticaFarmaceutica[];
  console.log("Fetching Categorias Terapéutcias");
  const categoriaTerapeuticaItem = `
    id
    nombre
  `;
  const categoriaTerapeuticas = (await fetchObjectEntries(
    "CategoriaTerapeutica",
    categoriaTerapeuticaItem
  )) as APIt.CategoriaTerapeutica[];
  console.log("Fetching Principios Activos");
  const principioActivoItem = `
    id
    nombre
  `;
  const principioActivos = (await fetchObjectEntries(
    "PrincipioActivo",
    principioActivoItem
  )) as APIt.PrincipioActivo[];
  console.log("Fetching Fabricantes");
  const fabricanteItem = `
    id
    nombre
    pais {
        id
        codigo
        nombre
    }
    direccion
    vencimientoBPM
  `;
  const fabricantesDB = (await fetchObjectEntries(
    "Fabricante",
    fabricanteItem
  )) as APIt.Fabricante[];
  console.log("Fetching Formulas");
  const formulaItem = `
    id
    codigo
  `;
  const formulasDB = (await fetchObjectEntries(
    "CodigoFormula",
    formulaItem
  )) as APIt.CodigoFormula[];

  console.log("Inserting new entries");
  const data = JSON.parse(tuto.FormulaIn);
  const principioData = JSON.parse(tuto.FormulaPrincipioIn);
  for (var i = 0; i < data.length; i++) {
    const done = i + 1;
    const total = data.length;

    const CodigoFormula = data[i];

    const dbFormula = formulasDB.find((f: APIt.CodigoFormula) => f.codigo === CodigoFormula.CODIGO);
    if (dbFormula) {
      console.log(dbFormula);
      updateImportStatus({ done, total });
      continue;
    }

    const bu = businessUnits.find(
      (e: APIt.BusinessUnit) => e.codigo.toUpperCase() === CodigoFormula.BU?.toUpperCase()
    );
    const categoriaTerapeutica = categoriaTerapeuticas.find(
      (e: APIt.CategoriaTerapeutica) =>
        e.nombre.toUpperCase() === CodigoFormula.CATTE?.toUpperCase()
    );
    const formaCosmeticaFarmaceutica = fffcs.find(
      (e: APIt.FormaCosmeticaFarmaceutica) =>
        e.nombre.toUpperCase() === CodigoFormula.FFFC?.toUpperCase()
    );
    const categoria = categorias.find(
      (e: APIt.Categoria) => e.codigo.toUpperCase() === CodigoFormula.CATEGORIA?.toUpperCase()
    );
    const exportFabricantes = CodigoFormula.FABRICANTES
      ? CodigoFormula.FABRICANTES?.split("||||").map((e: string) => e.toUpperCase())
      : [];
    const fabricantes = fabricantesDB.filter((e: APIt.Fabricante) =>
      exportFabricantes.includes(e.nombre.toUpperCase())
    );

    const principiosActivos =
      principioData
        .filter((e: any) => e.FORMULA === CodigoFormula.CODIGO)
        ?.map((e: any) => {
          const { FORMULA, NOMBRE, CONCENTRACION, FABRICANTES } = e;

          const ppoExportFabricantes = FABRICANTES
            ? FABRICANTES?.split("||||").map((e: string) => e.toUpperCase())
            : [];
          const ppoFabricantes = fabricantesDB.filter((e: APIt.Fabricante) =>
            ppoExportFabricantes.includes(e.nombre.toUpperCase())
          );
          const principio = principioActivos.find(
            (e: APIt.PrincipioActivo) => e.nombre.toUpperCase() === NOMBRE?.toUpperCase()
          );

          return { principio, concentracion: CONCENTRACION, fabricantes: ppoFabricantes };
        })
        ?.filter((e: any) => e.principio?.id) || [];

    const input = {
      codigo: CodigoFormula.CODIGO,
      marca: CodigoFormula.MARCA,
      producto: CodigoFormula.PRODUCTO,
      presentaciones: CodigoFormula.PRESENTACIONES,
      bu,
      categoriaTerapeutica,
      formaCosmeticaFarmaceutica,
      categoria,
      fabricantes,
      principiosActivos,
      tramites: [] as APIt.FormulaTramiteVO[],
      registros: [] as APIt.FormulaRegistroVO[],
      dossierData: [] as APIt.DossierField[],
      dossierCompleteness: 0,
      historico: true,
    };

    console.log(input);
    await saveCodigoFormula(input as any);
    updateImportStatus({ done, total });
  }
};

export const importRegistros = async (tuto: any, updateImportStatus: any) => {
  console.log("Fetching paises");
  const paisItem = `
        id
        codigo
        nombre
      `;
  const paises = (await fetchObjectEntries("Pais", paisItem)) as APIt.Pais[];
  console.log("Fetching categorias");
  const categoriaItem = `
      id
      codigo
      nombre
    `;
  const categorias = (await fetchObjectEntries("Categoria", categoriaItem)) as APIt.Categoria[];
  console.log("Fetching BusinessUnits");
  const businessUnitItem = `
      id
      codigo
      nombre
    `;
  const businessUnits = (await fetchObjectEntries(
    "BusinessUnit",
    businessUnitItem
  )) as APIt.BusinessUnit[];
  console.log("Fetching Formas Cosméticas/Farmacéuticas");
  const fffcItem = `
      id
      nombre
    `;
  const fffcs = (await fetchObjectEntries(
    "FormaCosmeticaFarmaceutica",
    fffcItem
  )) as APIt.FormaCosmeticaFarmaceutica[];
  console.log("Fetching Categorias Terapéutcias");
  const categoriaTerapeuticaItem = `
      id
      nombre
    `;
  const categoriaTerapeuticas = (await fetchObjectEntries(
    "CategoriaTerapeutica",
    categoriaTerapeuticaItem
  )) as APIt.CategoriaTerapeutica[];
  console.log("Fetching Principios Activos");
  const principioActivoItem = `
      id
      nombre
    `;
  const principioActivos = (await fetchObjectEntries(
    "PrincipioActivo",
    principioActivoItem
  )) as APIt.PrincipioActivo[];
  console.log("Fetching Fabricantes");
  const fabricanteItem = `
      id
      nombre
      pais {
          id
          codigo
          nombre
      }
      direccion
      vencimientoBPM
    `;
  const fabricantesDB = (await fetchObjectEntries(
    "Fabricante",
    fabricanteItem
  )) as APIt.Fabricante[];
  console.log("Fetching Formulas");
  const formulaItem = `
    id
    codigo
    marca
    producto
    categoria {
        id
        codigo
        nombre
    }
    bu {
        id
        nombre
        codigo
    }
    categoriaTerapeutica {
        id
        nombre
    }
    formaCosmeticaFarmaceutica {
        id
        nombre
    }
    presentaciones
    `;
  const formulasDB = (await fetchObjectEntries(
    "CodigoFormula",
    formulaItem
  )) as APIt.CodigoFormula[];
  console.log("Fetching Registros");
  const registroItem = `
      id
      numeroRegistro
    `;
  const registrosDB = (await fetchObjectEntries("Registro", registroItem)) as APIt.Registro[];

  console.log("Inserting new entries");
  const data = JSON.parse(tuto.registros);
  const registrosFormulas = JSON.parse(tuto.registrosFormulas);
  const registrosFormulasPrincipios = JSON.parse(tuto.registrosFormulasPrincipios);
  const registrosPresentaciones = JSON.parse(tuto.registrosPresentaciones);
  const registrosPresentacionesAcondicionadores = JSON.parse(
    tuto.registrosPresentacionesAcondicionadores
  );
  const registrosPresentacionesAcondicionadoresData = JSON.parse(
    tuto.registrosPresentacionesAcondicionadoresData
  );

  for (var i = 0; i < data.length; i++) {
    const registro = data[i];
    const { REGSANITARIO, PAIS, VENCIMIENTOSANITARIO, FECHAINICIORENOVACION, FORMULAS } = registro;

    const numeroRegistro = REGSANITARIO.startsWith("'") ? REGSANITARIO.substring(1) : REGSANITARIO;
    const pais = paises.find((p: APIt.Pais) => p.codigo === PAIS);
    const vencimientoSanitario = excelDateToDate(VENCIMIENTOSANITARIO);
    const inicioRenovacion = excelDateToDate(FECHAINICIORENOVACION);
    const historico = true;

    const existingRegistro = registrosDB.find(
      (r: APIt.Registro) => r.numeroRegistro === numeroRegistro
    );
    if (existingRegistro) {
      console.log(existingRegistro);
      continue;
    }

    // FORMULAS
    const formulas = FORMULAS?.split("||||").map((codigoFormula: string) => {
      const dbFormula = formulasDB.find((e: APIt.CodigoFormula) => e.codigo === codigoFormula);
      const exportFormula = registrosFormulas.find(
        (e: any) => e.REGSANITARIO === REGSANITARIO && e.FORMULA === codigoFormula
      );

      const { MARCA, BU, CATEGORIA, CATEGORIATERAPEUTICA, FFOFC, CONDICIONVENTA, PPONOMBRES } =
        exportFormula;
      const { id, marca, bu, categoria, categoriaTerapeutica, formaCosmeticaFarmaceutica } =
        dbFormula;

      const expPpos = PPONOMBRES?.split("||||");
      const expBu = businessUnits.find((e: APIt.BusinessUnit) => e.codigo === BU);
      const expCategoria = categorias.find((e: APIt.Categoria) => e.codigo === CATEGORIA);
      const expCategoriaT = categoriaTerapeuticas.find(
        (e: APIt.CategoriaTerapeutica) => e.nombre === CATEGORIATERAPEUTICA
      );
      const expFFOFC = fffcs.find((e: APIt.FormaCosmeticaFarmaceutica) => e.nombre === FFOFC);
      const condicionVenta = getCondicionVenta(CONDICIONVENTA);

      const inputPrincipios =
        expPpos
          ?.map((principioNombre: string) => {
            const principioDB = principioActivos.find(
              (e: APIt.PrincipioActivo) => e.nombre === principioNombre
            );
            if (!principioDB) return null;
            const principioExp = registrosFormulasPrincipios.find(
              (e: any) =>
                e.REGSANITARIO === REGSANITARIO &&
                e.FORMULA === codigoFormula &&
                e.PPONOMBRE === principioNombre
            );
            if (!principioExp) return null;
            const { PPOCONCENTRACION, PPFABRICANTE } = principioExp;
            const expFabricantes = PPFABRICANTE?.split("||||");

            const fabricantes =
              expFabricantes
                ?.map((fabricanteNombre: string) =>
                  fabricantesDB.find((e: APIt.Fabricante) => e.nombre === fabricanteNombre)
                )
                ?.filter((e: APIt.Fabricante) => !!e) || [];

            return { principio: principioDB, concentracion: PPOCONCENTRACION, fabricantes };
          })
          ?.filter((x: any) => !!x) || [];

      return {
        id,
        codigo: codigoFormula,
        marca: MARCA || marca,
        bu: expBu || bu,
        categoria: expCategoria || categoria,
        categoriaTerapeutica: expCategoriaT || categoriaTerapeutica,
        formaCosmeticaFarmaceutica: expFFOFC || formaCosmeticaFarmaceutica,
        condicionVenta: condicionVenta
          ? { id: condicionVenta, nombre: condicionVenta }
          : condicionVenta,
        principiosActivos: inputPrincipios || [],
      };
    });

    // CONCENTRACIONES
    const presentaciones =
      registrosPresentaciones
        .filter((e: any) => e.REGSANITARIO === REGSANITARIO)
        ?.map((registroPresentacion: any) => {
          const { PRESENTACIONES, PRODUCTO, FORMULAS, FABRICANTES } = registroPresentacion;
          const formulas = FORMULAS.split("||||");
          const fabricanteNombres = FABRICANTES.split("||||");

          const fabricantesDBAsociados =
            fabricanteNombres
              ?.map((fabricanteNombre: string) =>
                fabricantesDB.find((e: APIt.Fabricante) => e.nombre === fabricanteNombre)
              )
              ?.filter((e: APIt.Fabricante) => !!e) || [];

          const registroFabricantresVO = // RegistroFabricanteVO
            registrosPresentacionesAcondicionadores
              .filter(
                (e: any) =>
                  e.REGSANITARIO === REGSANITARIO &&
                  e.PRESENTACIONES === PRESENTACIONES &&
                  e.PRODUCTO === PRODUCTO
              )
              ?.map((acondicionadorPrimario: any) => {
                const { MAQUILADORES, ACONDICIONADORES, PPFABRICANTES, FABRICANTE } =
                  acondicionadorPrimario;
                const expMaquiladores = MAQUILADORES?.split("||||") || [];
                const expAcondicionadores = ACONDICIONADORES?.split("||||") || [];
                const expFabricantesAPI = PPFABRICANTES?.split("||||") || [];

                const fabricante = fabricantesDB.find(
                  (e: APIt.Fabricante) => e.nombre === FABRICANTE
                );
                if (!fabricante) return null;
                const dbMaquiladores =
                  fabricantesDB.filter((e: APIt.Fabricante) =>
                    expMaquiladores.includes(e.nombre)
                  ) || [];

                const dbFabricantesAPI =
                  fabricantesDB.filter((e: APIt.Fabricante) =>
                    expFabricantesAPI.includes(e.nombre)
                  ) || [];
                const dbAcondicionadores =
                  fabricantesDB.filter((e: APIt.Fabricante) =>
                    expAcondicionadores.includes(e.nombre)
                  ) || [];

                const registroAcondicionadores = // RegistroAcondicionadorPrimarioVO
                  dbAcondicionadores
                    .map((dbAcondicionadorPrimario: APIt.Fabricante) => {
                      const acondicionadorData = registrosPresentacionesAcondicionadoresData.find(
                        (e: any) =>
                          e.REGSANITARIO === REGSANITARIO &&
                          e.PRESENTACIONES === PRESENTACIONES &&
                          e.PRODUCTO === PRODUCTO &&
                          e.FABRICANTE === fabricante.nombre &&
                          e.ACONDICIONADOR === dbAcondicionadorPrimario.nombre
                      );

                      if (!acondicionadorData) return null;

                      const {
                        COMPLIANCE,
                        ESTABILIDAD,
                        ESTABILIDADVIDAUTIL,
                        ESTATUSVENTA,
                        COMENTARIOS,
                      } = acondicionadorData;

                      return {
                        acondicionadorPrimario: dbAcondicionadorPrimario,
                        compliance: COMPLIANCE === "Si",
                        estabilidad: ESTABILIDAD === "Presente",
                        vidaUtil: getVidaUtil(ESTABILIDADVIDAUTIL),
                        estatusVenta: getEstatusVenta(ESTATUSVENTA),
                        // comentarios: COMENTARIOS,
                      };
                    })
                    ?.filter((x: any) => !!x) || []; // RegistroAcondicionadorPrimarioVO

                return {
                  acondicionadoresPrimarios: registroAcondicionadores,
                  fabricante,
                  maquiladores: dbMaquiladores || [],
                  fabricantesAPI: dbFabricantesAPI || [],
                };
              })
              ?.filter((x: any) => !!x) || []; // RegistroFabricanteVO
          return {
            fabricantes: registroFabricantresVO,
            producto: PRODUCTO,
            presentaciones: PRESENTACIONES,
            formulas,
          };
        }) || []; // RegistroPresentacionesVO

    const input = {
      numeroRegistro,
      pais,
      vencimientoSanitario,
      inicioRenovacion,
      historico,
      formulas,
      presentaciones,
    };

    const search = buildSearch(input as any);
    console.log(input, search);

    await saveRegistro(input as any, search);

    const done = i + 1;
    const total = data.length;
    updateImportStatus({ done, total });
  }
};

const buildSearch = (registro: APIt.Registro) => {
  const { pais, formulas, presentaciones } = registro;

  const fabricantesArray: APIt.FabricanteVO[] = [];
  const susArray: APIt.PaisVO[] = [];
  const busArray: APIt.BusinessUnitVO[] = [];
  const principiosArray: APIt.PrincipioActivoVO[] = [];
  const categoriasArray: APIt.CategoriaVO[] = [];
  const fffcsArray: APIt.FormaCosmeticaFarmaceuticaVO[] = [];
  const cateTerArray: APIt.CategoriaTerapeuticaVO[] = [];

  formulas?.forEach((f: APIt.RegistroFormulaVO) => {
    categoriasArray.push(f.categoria);
    fffcsArray.push(f.formaCosmeticaFarmaceutica);
    cateTerArray.push(f.categoriaTerapeutica);
    busArray.push(f.bu);
    f.principiosActivos?.forEach((p: APIt.RegistroPrincipioActivoVO) => {
      principiosArray.push(p.principio);
      p.fabricantes?.forEach((f: APIt.FabricanteVO) => {
        fabricantesArray.push(f);
        susArray.push(f.pais);
      });
    });
  });
  presentaciones?.forEach((p: APIt.RegistroPresentacionesVO) => {
    p.fabricantes?.forEach((f: APIt.RegistroFabricanteVO) => {
      fabricantesArray.push(f.fabricante);
      susArray.push(f.fabricante?.pais);
    });
  });

  const searchFabricantesArray: string[] = [];
  fabricantesArray.forEach((f: APIt.FabricanteVO) => {
    if (f && !searchFabricantesArray.includes(prepareForSearch(f.nombre)))
      searchFabricantesArray.push(prepareForSearch(f.nombre));
  });
  const searchSUsArray: string[] = [];
  const searchSUsArrayCheck: string[] = [];
  susArray.forEach((s: APIt.PaisVO) => {
    if (s && !searchSUsArrayCheck.includes(prepareForSearch(s.codigo))) {
      searchSUsArrayCheck.push(prepareForSearch(s.codigo));
      searchSUsArray.push(prepareForSearch(s.codigo));
      searchSUsArray.push(prepareForSearch(s.nombre));
    }
  });
  const searchBUsArray: string[] = [];
  const searchBUsArrayCheck: string[] = [];
  busArray.forEach((s: APIt.BusinessUnitVO) => {
    if (s && !searchBUsArrayCheck.includes(prepareForSearch(s.codigo))) {
      searchBUsArrayCheck.push(prepareForSearch(s.codigo));
      searchBUsArray.push(prepareForSearch(s.codigo));
      searchBUsArray.push(prepareForSearch(s.nombre));
    }
  });
  const searchPposArray: string[] = [];
  principiosArray.forEach((p: APIt.PrincipioActivoVO) => {
    if (p && !searchPposArray.includes(prepareForSearch(p.nombre)))
      searchPposArray.push(prepareForSearch(p.nombre));
  });
  const searchCategoriasArray: string[] = [];
  const searchCategoriasArrayCheck: string[] = [];
  categoriasArray.forEach((c: APIt.CategoriaVO) => {
    if (c && !searchCategoriasArrayCheck.includes(prepareForSearch(c.codigo))) {
      searchCategoriasArrayCheck.push(prepareForSearch(c.codigo));
      searchCategoriasArray.push(prepareForSearch(c.codigo));
      searchCategoriasArray.push(prepareForSearch(c.nombre));
    }
  });
  const searchFFFCsArray: string[] = [];
  fffcsArray.forEach((c: APIt.FormaCosmeticaFarmaceuticaVO) => {
    if (c && !searchFFFCsArray.includes(prepareForSearch(c.nombre))) {
      searchFFFCsArray.push(prepareForSearch(c.nombre));
    }
  });
  const searchCatTerArray: string[] = [];
  cateTerArray.forEach((c: APIt.CategoriaTerapeuticaVO) => {
    if (c && !searchCatTerArray.includes(prepareForSearch(c.nombre))) {
      searchCatTerArray.push(prepareForSearch(c.nombre));
    }
  });

  const search = {
    searchPaisDestino: JSON.stringify([
      prepareForSearch(pais.codigo),
      prepareForSearch(pais.nombre),
    ]),
    searchCodigos: JSON.stringify(formulas?.map((e: APIt.RegistroFormulaVO) => e.codigo) || []),
    searchMarcas: JSON.stringify(
      formulas?.map((e: APIt.RegistroFormulaVO) => prepareForSearch(e.marca)) || []
    ),
    searchBus: JSON.stringify(searchBUsArray || "[]"),
    searchCategoriasTerapeuticas: JSON.stringify(searchCatTerArray || "[]"),
    searchFormasCosmeticasFarmaceuticas: JSON.stringify(searchFFFCsArray || "[]"),
    searchCategorias: JSON.stringify(searchCategoriasArray || "[]"),
    searchPrincipiosActivos: JSON.stringify(searchPposArray || "[]"),
    searchSourcingUnits: JSON.stringify(searchSUsArray || "[]"),
    searchProductos: JSON.stringify(
      presentaciones.map((e: APIt.RegistroPresentacionesVO) => prepareForSearch(e.producto))
    ),
    searchPresentaciones: JSON.stringify(
      presentaciones.map((e: APIt.RegistroPresentacionesVO) => prepareForSearch(e.presentaciones))
    ),
    searchFabricantes: JSON.stringify(searchFabricantesArray || "[]"),
  };

  return search;
};
