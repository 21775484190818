import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { Auth } from "aws-amplify";

import * as APIt from "API";
import { removeKey } from "globalvars";
import { getRegistro } from "services/Registro/queries";
import { saveRegistro } from "graphql/mutations";

const fetchRegistro = async (id: string): Promise<APIt.Registro> => {
  let registro: APIt.Registro = null;
  const params: APIt.GetRegistroQueryVariables = { id };

  const result = (await API.graphql({
    query: getRegistro,
    variables: params,
  })) as GraphQLResult<APIt.GetRegistroQuery>;
  if (result.data) {
    const query: APIt.GetRegistroQuery = result.data;
    if (query.getRegistro) {
      registro = query.getRegistro as APIt.Registro;
    }
  }
  return registro;
};

const updateRegistro = async (registro: APIt.Registro): Promise<string> => {
  const registroSinKey = removeKey(registro, "arrayKey");

  // SET USER
  const userData = await Auth.currentAuthenticatedUser();
  if (!registroSinKey.createdBy) {
    registroSinKey.createdBy = userData.attributes.email;
  }
  registroSinKey.modifiedBy = userData.attributes.email;

  // SAVE REGISTRO
  const input = { registro: JSON.stringify(registroSinKey) };
  const body = (await API.graphql(
    graphqlOperation(saveRegistro, {
      input,
    })
  )) as GraphQLResult<APIt.SaveRegistroMutation>;
  const retId = body.data.saveRegistro?.objectId;
  return retId;
};

export { fetchRegistro, updateRegistro };
