import { AppBar, Icon, Tab, Tabs } from "@mui/material";

import breakpoints from "assets/theme/base/breakpoints";
import MDTypography from "components/md/MDTypography";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  value: string;
  setValue: any;
};
function TramiteTabBar(params: Props): JSX.Element {
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");

  const { value, setValue, formMethods } = params;
  const { getValues } = formMethods;
  const { registroModificado } = getValues();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: any) => setValue(newValue);

  return (
    <AppBar position="static">
      <Tabs
        orientation={tabsOrientation}
        value={value}
        onChange={handleSetTabValue}
        variant="fullWidth"
      >
        <Tab label={<MDTypography variant="h5">Editar Detalle</MDTypography>} value="detalle" />
        <Tab
          label={<MDTypography variant="h5">Ver Fórmulas Globales</MDTypography>}
          icon={
            <Icon fontSize="small" sx={{ mt: -0.25 }}>
              science
            </Icon>
          }
          value="formulas"
        />
        <Tab
          label={<MDTypography variant="h5">Ver Otros trámites</MDTypography>}
          icon={
            <Icon fontSize="small" sx={{ mt: -0.25 }}>
              filternote
            </Icon>
          }
          value="otrosTramites"
        />
        {registroModificado?.id && (
          <Tab
            label={<MDTypography variant="h5">Ver Registro</MDTypography>}
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                gavel
              </Icon>
            }
            value="registro"
          />
        )}
      </Tabs>
    </AppBar>
  );
}

export default TramiteTabBar;
