import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useState, useEffect } from "react";

import fetchCodigosFormulaList from "services/CodigoFormula/CodigoFormulaList";
import { downloadExcel } from "services/Export/ExcelExporter";
import formulasConfig from "services/Export/Formulas/FormulasExportConfig";

/* eslint-disable */
type RefreshCallback = (overrideFull?: string) => void;
/* eslint-enable */

const useCodigoFormulaList = (): [any[], RefreshCallback, () => void, boolean, boolean, any] => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { formulaSearchState },
    dispatch,
  } = useTramitesAppStateContext();

  // get first page
  useEffect(() => {
    if (formulaSearchState?.results?.length < 1) {
      setIsLoading(true);
      fetchCodigosFormulaList(null, formulaSearchState).then(
        (result) => {
          const { page, nextToken } = result;
          dispatch({
            type: "update-formula-list",
            payload: { page, nextToken },
          });
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  }, []);

  const fetchNextPage = () => {
    setIsLoading(true);
    fetchCodigosFormulaList(formulaSearchState.nextToken, formulaSearchState).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-formula-list",
          payload: { page: [...formulaSearchState.results, ...page], nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const refresh = (overrideFull: string) => {
    setIsLoading(true);
    const searchState =
      overrideFull !== null ? { ...formulaSearchState, full: overrideFull } : formulaSearchState;
    fetchCodigosFormulaList(null, searchState).then(
      (result) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-formula-list",
          payload: { page, nextToken },
        });
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const fetchAll = async (searchState: any) => {
    const allItems: any[] = [];
    let currentToken: any = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      const { page, nextToken } = await fetchCodigosFormulaList(
        currentToken,
        searchState,
        true,
        true
      );

      allItems.push(...page);
      currentToken = nextToken;
    } while (currentToken != null);
    return allItems;
  };

  const exportFormulas = (columns: any) => {
    setIsLoading(true);
    fetchAll(formulaSearchState).then((results) => {
      setIsLoading(false);
      downloadExcel(results, formulasConfig, columns);
    });
  };

  return [
    formulaSearchState.results,
    refresh,
    fetchNextPage,
    isLoading,
    !!formulaSearchState.nextToken,
    exportFormulas,
  ];
};

export default useCodigoFormulaList;
