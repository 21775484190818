import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { Grid } from "@mui/material";
import { estadoTramiteNames, gridSizes, tipoNames } from "globalvars";
import { differenceInDays, parseISO } from "date-fns";
import MDTypography from "components/md/MDTypography";
import ReadOnlyField from "./ReadOnlyField";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function DatosReadOnlyTramite(params: Props): JSX.Element {
  const {
    formMethods: { getValues },
  } = params;

  const {
    tipo,
    registroModificado,
    estado,
    innovacion,
    codigoCPT,
    medioDifusion,
    tipoCertificado,
    presentaciones,
    numeroRegistro,
    vencimientoSanitario,
  } = getValues();

  let renovacionVencida = false;
  if (estado === APIt.EstadoTramite.pendiente) {
    const vencimientoDate = parseISO(vencimientoSanitario);
    const now = new Date();
    const dif = differenceInDays(now, vencimientoDate);
    renovacionVencida = dif > 0;
  }

  const productos = presentaciones?.reduce(
    (resultado, presentacion) =>
      resultado ? `${resultado} / ${presentacion.producto}` : presentacion.producto,
    ""
  );

  const registroLabel = [
    APIt.TipoTramite.vigilanciaSanitaria,
    APIt.TipoTramite.certificados,
    APIt.TipoTramite.publicidad,
  ].includes(tipo)
    ? "Número de trámite"
    : "Número de registro";

  const modificacion =
    (tipo === APIt.TipoTramite.modificacion ||
      tipo === APIt.TipoTramite.modificacionAdministrativa ||
      tipo === APIt.TipoTramite.modificacionTecnica ||
      tipo === APIt.TipoTramite.renovacion) &&
    registroModificado;

  const publicidad = tipo === APIt.TipoTramite.publicidad;
  const certificado = tipo === APIt.TipoTramite.certificados;

  // FECHAS DEFINITIVAS
  const liberacionFragment: JSX.Element = (
    <ReadOnlyField
      gridXS={12}
      gridSM={6}
      gridLGTop={4}
      gridXLTop={4}
      title="Fecha de Liberación de dossier"
    >
      {getValues().fechaLiberacion}
    </ReadOnlyField>
  );
  const presentacionFragment: JSX.Element = (
    <ReadOnlyField gridXS={12} gridSM={6} gridLGTop={4} gridXLTop={4} title="Fecha de Presentación">
      {getValues().fechaPresentacion}
    </ReadOnlyField>
  );
  const aprobacionFragment: JSX.Element = (
    <ReadOnlyField gridXS={12} gridSM={6} gridLGTop={4} gridXLTop={4} title="Fecha de Aprobación">
      {getValues().fechaObtencion}
    </ReadOnlyField>
  );
  const rechazoFragment: JSX.Element = (
    <ReadOnlyField gridXS={12} gridSM={6} gridLGTop={4} gridXLTop={4} title="Fecha de Rechazo">
      {getValues().fechaObtencion}
    </ReadOnlyField>
  );
  const fechasDefinitivas = {
    [APIt.EstadoTramite.pendiente]: null as JSX.Element,
    [APIt.EstadoTramite.liberado]: liberacionFragment,
    [APIt.EstadoTramite.presentado]: (
      <>
        {liberacionFragment}
        {presentacionFragment}
      </>
    ),
    [APIt.EstadoTramite.objetado]: (
      <>
        {liberacionFragment}
        {presentacionFragment}
      </>
    ),
    [APIt.EstadoTramite.aprobado]: (
      <>
        {liberacionFragment}
        {presentacionFragment}
        {aprobacionFragment}
      </>
    ),
    [APIt.EstadoTramite.rechazado]: (
      <>
        {liberacionFragment}
        {presentacionFragment}
        {rechazoFragment}
      </>
    ),
  };

  return (
    <MDBox p={3} mt={-5}>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3} pt={4}>
          <ReadOnlyField
            gridXS={gridSizes.xs}
            gridSM={gridSizes.sm}
            gridLGTop={gridSizes.lgtop}
            gridXLTop={gridSizes.xltop}
            title="Identificador"
          >
            {codigoCPT}
          </ReadOnlyField>
          <ReadOnlyField
            gridXS={gridSizes.xs}
            gridSM={gridSizes.sm}
            gridLGTop={gridSizes.lgtop}
            gridXLTop={gridSizes.xltop}
            title="Tipo"
          >
            {tipoNames[tipo]}
          </ReadOnlyField>
          <ReadOnlyField
            gridXS={gridSizes.xs}
            gridSM={gridSizes.sm}
            gridLGTop={gridSizes.lgtop}
            gridXLTop={gridSizes.xltop}
            title="Estado"
          >
            {estadoTramiteNames[estado]}
          </ReadOnlyField>
          {numeroRegistro && (
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title={registroLabel}
            >
              {numeroRegistro}
            </ReadOnlyField>
          )}
          <ReadOnlyField
            gridXS={gridSizes.xs}
            gridSM={gridSizes.sm}
            gridLGTop={gridSizes.lgtop}
            gridXLTop={gridSizes.xltop}
            title="¿Es innovación?"
          >
            {innovacion ? "Si" : "No"}
          </ReadOnlyField>
          {certificado && (
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="Tipo de Certificado"
            >
              {tipoCertificado?.nombre}
            </ReadOnlyField>
          )}
          {publicidad && (
            <ReadOnlyField
              gridXS={gridSizes.xs}
              gridSM={gridSizes.sm}
              gridLGTop={gridSizes.lgtop}
              gridXLTop={gridSizes.xltop}
              title="Medio de difusión"
            >
              {medioDifusion?.nombre}
            </ReadOnlyField>
          )}
          {modificacion && (
            <>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="Registro Modificado"
              >
                {registroModificado?.numeroRegistro || "Pendiente"}
              </ReadOnlyField>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="Autoridad"
              >
                {registroModificado?.autoridad?.nombre}
              </ReadOnlyField>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="Vencimiento"
              >
                {renovacionVencida ? (
                  <MDTypography variant="caption" color="error" fontWeight="regular">
                    {registroModificado?.vencimientoSanitario}
                  </MDTypography>
                ) : (
                  registroModificado?.vencimientoSanitario
                )}
              </ReadOnlyField>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="Inicio de renovación"
              >
                {registroModificado?.inicioRenovacion}
              </ReadOnlyField>
            </>
          )}
          <ReadOnlyField
            gridXS={gridSizes.xs}
            gridSM={gridSizes.sm}
            gridLGTop={gridSizes.lgtop}
            gridXLTop={gridSizes.xltop}
            title="Producto"
          >
            {productos}
          </ReadOnlyField>
          {fechasDefinitivas[estado as keyof typeof fechasDefinitivas]}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default DatosReadOnlyTramite;
