import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import { getBasePieOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (
  initialData: DashboardTramiteDaily[],
  label: string,
  filterType: string,
  filterValue: string
) => {
  const data: any = {
    labels: ["Aprobados", "Rechazados", "Desistidos", "En Proceso"],
    datasets: [],
  };

  let header: string = `${label} - Sin datos`;

  let filteredData = initialData;
  if (filterType) {
    const filterData = (tramite: DashboardTramiteVO) => {
      if (filterType === "tipo") {
        return tramite.tipo === filterValue;
      }
      return tramite.iniciativa?.nombre === filterValue;
    };
    filteredData = initialData.map((dashboardEntry: DashboardTramiteDaily) => ({
      ...dashboardEntry,
      efectividadAprobados: dashboardEntry.efectividadAprobados?.filter(filterData),
      efectividadRechazados: dashboardEntry.efectividadRechazados?.filter(filterData),
      efectividadDesistidos: dashboardEntry.efectividadDesistidos?.filter(filterData),
      efectividadEnCurso: dashboardEntry.efectividadEnCurso?.filter(filterData),
    }));
  }

  if (filteredData?.length > 0) {
    const aprobados = filteredData?.reduce((acc, curr) => {
      if (curr.efectividadAprobados?.length) return acc + curr.efectividadAprobados.length;
      return acc;
    }, 0);
    const rechazados = filteredData?.reduce((acc, curr) => {
      if (curr.efectividadRechazados?.length) return acc + curr.efectividadRechazados.length;
      return acc;
    }, 0);
    const desistidos = filteredData?.reduce((acc, curr) => {
      if (curr.efectividadDesistidos?.length) return acc + curr.efectividadDesistidos.length;
      return acc;
    }, 0);
    const enProceso = filteredData?.reduce((acc, curr) => {
      if (curr.efectividadEnCurso?.length) return acc + curr.efectividadEnCurso.length;
      return acc;
    }, 0);

    const dataset = {
      data: [aprobados, rechazados, desistidos, enProceso],
      backgroundColor: [
        dashboardColors.green.background,
        dashboardColors.red.background,
        dashboardColors.orange.background,
        dashboardColors.yellow.background,
      ],
      borderColor: [
        dashboardColors.green.border,
        dashboardColors.red.border,
        dashboardColors.orange.border,
        dashboardColors.yellow.background,
      ],
      borderWidth: 1,
    };
    data.datasets = [dataset];
    header = label;
  }

  const options = getBasePieOptions();
  options.plugins.legend.display = false;

  return { header, data, options };
};

export default useData;
