import { CodigoFormula, DossierField, RegistroFormulaVO, TipoTramite } from "API";
import { masterDossierDefinition, DossierDefinitionEntry } from "./DossierConfig";

export type DossierFieldDetails = {
  note: string;
  date: string;
};

export type TramiteDefinitionEntry = {
  path: string;
  name: string;
  code: string;
  isField?: boolean;
  showDetails: boolean;
  details?: DossierFieldDetails;
  userAdded?: boolean;
  dataIndex?: number;
};

// GET FIELD OPTIONS
export function buildTramiteDossierDefinition(
  categorias: string[],
  pais: string,
  tipo: TipoTramite,
  scope: "Formula" | "Tramite"
): TramiteDefinitionEntry[] {
  const fieldPaths: string[] = [];

  const tramiteDossierDefinition = masterDossierDefinition
    .filter((entry: DossierDefinitionEntry) => {
      let ok = entry.preLoaded;
      // CHECK CATEGORY
      if (ok && entry.categories) {
        ok = entry.categories.reduce(
          (acc: boolean, value: string) => acc || categorias.includes(value),
          false
        );
      }
      // CHECK COUNTRY
      if (ok && entry.countries) {
        ok = entry.countries.reduce((acc: boolean, value: string) => acc || pais === value, false);
      }
      // CHIECK TIPO
      if (ok && entry.tiposTramite) {
        ok = entry.tiposTramite.reduce(
          (acc: boolean, value: string) => acc || tipo === value,
          false
        );
      }
      // CHECK SCOPE
      if (ok && entry.scope) {
        ok = entry.scope === scope;
      }

      if (ok && entry.isField) {
        fieldPaths.push(entry.path);
      }

      return ok;
    })
    .map((entry: DossierDefinitionEntry) => {
      const { path, name, code, isField, details } = entry;

      return {
        path,
        name,
        code,
        isField,
        showDetails: details,
        details: null,
        userAdded: false,
      } as TramiteDefinitionEntry;
    });

  // clean unnecesary props
  return tramiteDossierDefinition;
}

export function getGroupImmediateFieldChilds(
  tramiteConfig: TramiteDefinitionEntry[],
  current: TramiteDefinitionEntry
) {
  return tramiteConfig?.filter(
    (entry: TramiteDefinitionEntry) =>
      entry.isField && entry.path.endsWith(`/${current.code}/${entry.code}`)
  );
}

export function getGroupFullFieldChilds(
  tramiteConfig: TramiteDefinitionEntry[],
  current: TramiteDefinitionEntry
): TramiteDefinitionEntry[] {
  return tramiteConfig?.filter(
    (entry: TramiteDefinitionEntry) => entry.isField && entry.path.startsWith(`${current.path}/`)
  );
}

export function getGroupImmediateGroupChilds(
  tramiteConfig: TramiteDefinitionEntry[],
  current: TramiteDefinitionEntry,
  noEmpty?: boolean
) {
  let groups = tramiteConfig?.filter(
    (entry: TramiteDefinitionEntry) =>
      !entry.isField && entry.path.endsWith(`/${current.code}/${entry.code}`)
  );

  if (noEmpty) {
    groups = groups.filter((group: TramiteDefinitionEntry) => {
      const fieldChilds = getGroupFullFieldChilds(tramiteConfig, group);
      return fieldChilds?.length > 0;
    });
  }

  return groups;
}

export function getGroupFullGroupChilds(
  tramiteConfig: TramiteDefinitionEntry[],
  current: TramiteDefinitionEntry
) {
  return tramiteConfig.filter(
    (entry: TramiteDefinitionEntry) => !entry.isField && entry.path.startsWith(`${current.path}/`)
  );
}

// OPTIONS FOR NEW FIELDS
export function getExtraFieldOptions(
  tramiteDossierDefinition: TramiteDefinitionEntry[],
  categorias: string[],
  scope: "Formula" | "Tramite"
): TramiteDefinitionEntry[] {
  const otra = categorias.includes("OTRA"); // TODO: HACKAZO
  const exptraFieldDeffinition = masterDossierDefinition.filter((entry: DossierDefinitionEntry) => {
    let ok =
      entry.isField &&
      entry.categories?.reduce(
        (acc: boolean, value: string) => acc || otra || categorias.includes(value),
        false
      );

    if (ok) {
      ok = !tramiteDossierDefinition.reduce(
        (acc: boolean, value: TramiteDefinitionEntry) => acc || value.code === entry.code,
        false
      );
    }
    // CHECK SCOPE
    if (ok && entry.scope) {
      ok = entry.scope === scope;
    }

    return ok;
  });

  // Cast to TramiteDefinitionEntry
  return exptraFieldDeffinition.map((entry: DossierDefinitionEntry) => {
    const { path, name, code, isField, details } = entry;

    return {
      path,
      name,
      code,
      isField,
      showDetails: details,
      details: null,
      userAdded: true,
    } as TramiteDefinitionEntry;
  });
}

/* HELPERS */
export type PreferencesType = {
  tramite: { categorias: string[]; preferences: TramiteDefinitionEntry[] };
  formulas: {
    [key: string]: { categoria: string; preferences: TramiteDefinitionEntry[] };
  };
};

const builtFormulaPreferences = (
  preferencesIn: PreferencesType,
  formulaVO: RegistroFormulaVO,
  formula: CodigoFormula,
  pais: string,
  tipo: TipoTramite
) => {
  const categoria = formulaVO.categoria?.codigo;
  const preferences: PreferencesType = { ...preferencesIn };

  if (!preferences.formulas[formula.id]) {
    preferences.formulas[formula.id] = { categoria: "", preferences: [] };
  }

  // BUILD PREFERENCES WITH CURRENT SCHEMA
  const builtDossierPreferencesObj = buildTramiteDossierDefinition(
    [categoria],
    pais,
    tipo,
    "Formula"
  );
  const builtDossierPreferencesStr = JSON.stringify(builtDossierPreferencesObj);
  preferences.formulas[formula.id].categoria = categoria;

  // REMOVE ENTRIES FROM DB THAT SHOULDN'T BE THERE
  const entriesToRemove: string[] = [];
  preferences.formulas[formula.id].preferences.forEach((entry: TramiteDefinitionEntry) => {
    if (!entry.userAdded && builtDossierPreferencesStr.indexOf(entry.code) < 0) {
      entriesToRemove.push(entry.code);
    }
  });
  preferences.formulas[formula.id].preferences = preferences.formulas[
    formula.id
  ].preferences.filter((entry: TramiteDefinitionEntry) => !entriesToRemove.includes(entry.code));
  // ADD ENTRIES TO DB IF NOT PRESENT
  const formulaDossierPreferencesStr = JSON.stringify(preferences.formulas[formula.id].preferences);
  builtDossierPreferencesObj.forEach((entry: TramiteDefinitionEntry) => {
    if (formulaDossierPreferencesStr.indexOf(`"${entry.code}"`) < 0) {
      preferences.formulas[formula.id].preferences.push(entry);
    }
  });
  // SET DATA INDEXES
  const newDossierData = [...formula.dossierData];
  preferences.formulas[formula.id].preferences = preferences.formulas[formula.id].preferences.map(
    (entry: TramiteDefinitionEntry) => {
      let dataIndex = newDossierData.map((e: DossierField) => e.codigo).indexOf(entry.code);

      if (dataIndex < 0) {
        dataIndex = newDossierData.length;
        newDossierData.push({ codigo: entry.code, done: false } as DossierField);
      }
      return { ...entry, dataIndex } as TramiteDefinitionEntry;
    }
  );

  return {
    newDossierData,
    newPreferences: preferences.formulas[formula.id].preferences,
  };
};

export function prepareDossierPreferences(
  registroFormulas: RegistroFormulaVO[],
  dossierPreferences: string,
  codigoPais: string,
  tipo: TipoTramite,
  dossierData: DossierField[],
  formulas: CodigoFormula[]
) {
  const allCategorias: string[] = [];
  registroFormulas.forEach((registroFormula: RegistroFormulaVO) => {
    allCategorias.push(registroFormula.categoria?.codigo);
  });

  // GET PREFERENCES FROM DB
  const tramiteDossierPreferencesObj: PreferencesType = JSON.parse(
    dossierPreferences || '{ "tramite": {"categorias":[], "preferences":[]}, "formulas": {} }'
  );
  const tramiteDossierPreferencesStr: string = JSON.stringify(tramiteDossierPreferencesObj.tramite);

  // BUILD TRAMITE PREFERENCES WITH CURRENT SCHEMA
  const builtDossierPreferencesObj: TramiteDefinitionEntry[] = buildTramiteDossierDefinition(
    allCategorias,
    codigoPais,
    tipo,
    "Tramite"
  );
  const builtDossierPreferencesStr: string = JSON.stringify(builtDossierPreferencesObj);
  // SET CATEGORIES
  tramiteDossierPreferencesObj.tramite.categorias = allCategorias;

  // REMOVE ENTRIES FROM DB THAT SHOULDN'T BE THERE
  const entriesToRemove: string[] = [];
  tramiteDossierPreferencesObj.tramite.preferences.forEach((entry: TramiteDefinitionEntry) => {
    if (!entry.userAdded && builtDossierPreferencesStr.indexOf(`"${entry.code}"`) < 0) {
      entriesToRemove.push(entry.code);
    }
  });
  tramiteDossierPreferencesObj.tramite.preferences =
    tramiteDossierPreferencesObj.tramite.preferences.filter(
      (entry: TramiteDefinitionEntry) => !entriesToRemove.includes(entry.code)
    );
  // ADD ENTRIES TO DB IF NOT PRESENT
  builtDossierPreferencesObj.forEach((entry: TramiteDefinitionEntry) => {
    if (tramiteDossierPreferencesStr.indexOf(`"${entry.code}"`) < 0) {
      tramiteDossierPreferencesObj.tramite.preferences.push(entry);
    }
  });
  // SET DATA INDEXES
  const newDossierData = [...dossierData];
  tramiteDossierPreferencesObj.tramite.preferences =
    tramiteDossierPreferencesObj.tramite.preferences.map((entry: TramiteDefinitionEntry) => {
      let dataIndex = newDossierData.map((e: DossierField) => e.codigo).indexOf(entry.code);

      if (dataIndex < 0) {
        dataIndex = newDossierData.length;
        newDossierData.push({ codigo: entry.code, done: false } as DossierField);
      }
      return { ...entry, dataIndex } as TramiteDefinitionEntry;
    });

  const formulaDossierDataArray: DossierField[][] = [];

  formulas?.forEach((codigoFormula: CodigoFormula) => {
    const formulaVO = registroFormulas.find((fv: RegistroFormulaVO) => fv.id === codigoFormula.id);
    const formulaDossierData = builtFormulaPreferences(
      tramiteDossierPreferencesObj,
      formulaVO,
      codigoFormula,
      codigoPais,
      tipo
    );
    const { newDossierData: newFormulaDossierData, newPreferences: newFormulaPreferences } =
      formulaDossierData;

    formulaDossierDataArray.push(newFormulaDossierData);

    tramiteDossierPreferencesObj.formulas[codigoFormula.id].preferences = newFormulaPreferences;
  });

  return { tramiteDossierPreferencesObj, formulaDossierDataArray, newDossierData };
}
