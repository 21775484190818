export const getFormaCosmeticaFarmaceutica = /* GraphQL */ `
  query GetFormaCosmeticaFarmaceutica($id: ID!) {
    getFormaCosmeticaFarmaceutica(id: $id) {
      id
      nombre
    }
  }
`;
export const listFormaCosmeticaFarmaceuticas = /* GraphQL */ `
  query ListFormaCosmeticaFarmaceuticas(
    $filter: ModelFormaCosmeticaFarmaceuticaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormaCosmeticaFarmaceuticas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
      }
      nextToken
    }
  }
`;
export const createFormaCosmeticaFarmaceuticaMutation = /* GraphQL */ `
  mutation CreateFormaCosmeticaFarmaceutica(
    $input: CreateFormaCosmeticaFarmaceuticaInput!
    $condition: ModelFormaCosmeticaFarmaceuticaConditionInput
  ) {
    createFormaCosmeticaFarmaceutica(input: $input, condition: $condition) {
      id
      nombre
    }
  }
`;
export const updateFormaCosmeticaFarmaceuticaMutation = /* GraphQL */ `
  mutation UpdateFormaCosmeticaFarmaceutica(
    $input: UpdateFormaCosmeticaFarmaceuticaInput!
    $condition: ModelFormaCosmeticaFarmaceuticaConditionInput
  ) {
    updateFormaCosmeticaFarmaceutica(input: $input, condition: $condition) {
      id
      nombre
    }
  }
`;
