/* eslint-disable no-unused-vars */
import { Box, Chip } from "@mui/material";
import * as APIt from "API";
import MDTypography from "components/md/MDTypography";
import Avatar from "components/tramites/Avatar";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FlagBuilder from "components/tramites/FlagBuilder";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { prepareForSearch } from "globalvars";
import { debounce } from "lodash";
import React, { useState } from "react";

type Props = {
  formMethods: any;
};
function RegistroComboMultiple(data: Props): JSX.Element {
  const { formMethods } = data;

  const {
    formState: { errors },
    control,
    setValue,
  } = formMethods;

  const {
    state: {
      registroSearchState: { results },
      userRol,
      userPaises,
    },
  } = useTramitesAppStateContext();

  const [options, setOptions] = useState([]);

  // OBTENCIÓN DE OPCIONES
  /* const debouncedSearch = React.useRef(
    debounce(async (codigo) => {
      fetchRegistros({ codigo, numeroRegistro: codigo });
    }, 300)
  ).current; */
  const debouncedSearch = React.useRef(
    debounce(async (codigo) => {
      const newOptions = results?.filter(
        (r) =>
          (userRol === "Global" || userPaises.includes(r.pais?.codigo)) &&
          (prepareForSearch(r.numeroRegistro).includes(prepareForSearch(codigo)) ||
            r.formulas?.some((f) => prepareForSearch(f.codigo).includes(prepareForSearch(codigo))))
      );
      setOptions(newOptions);
    }, 500)
  ).current;

  async function handleRegistroSearch(numeroRegistro: string) {
    debouncedSearch(numeroRegistro);
  }

  const pickRegistro = (data: APIt.Registro) => {
    const {
      pais,
      autoridad,
      vencimientoSanitario,
      formulas,
      presentaciones,
      numeroRegistro,
      inicioRenovacion,
      id,
    } = data;

    const formulaRegistro = {
      id,
      pais,
      autoridad,
      vencimientoSanitario,
      inicioRenovacion,
      numeroRegistro,
    } as APIt.FormulaRegistroVO;

    setValue("pais", pais);
    setValue("autoridad", autoridad);
    setValue("vencimientoSanitario", vencimientoSanitario);
    setValue("formulas", formulas);
    setValue("presentaciones", presentaciones);
    setValue("numeroRegistro", numeroRegistro);
    setValue("registroModificado", formulaRegistro);
  };

  const unpickRegistro = () => {
    setValue("pais", null);
    setValue("autoridad", null);
    setValue("vencimientoSanitario", null);
    setValue("formulas", null);
    setValue("presentaciones", null);
    setValue("numeroRegistro", null);
    setValue("registroModificado", null);
  };

  React.useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  return (
    <ControlledAutocomplete
      label="Registros"
      multiple
      name="registros"
      errors={errors.registros?.message}
      options={options || []}
      control={control as any}
      renderOption={(props: any, option: any) => (
        <Box component="li" {...props} key={option.id} display="flex">
          <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 1 }}>
            <FlagBuilder country={option.pais.codigo} />
          </Avatar>
          <MDTypography variant="caption">{option.numeroRegistro}</MDTypography>
          <MDTypography variant="caption" sx={{ ml: 1 }}>
            {" ("}
            {option.formulas?.map(
              (formula: APIt.RegistroFormulaVO, index: number) =>
                `${index === 0 ? "" : ", "}${formula.codigo}`
            )}
            )
          </MDTypography>
        </Box>
      )}
      renderTags={(value: any, getTagProps: any) =>
        value.map((option: any, index: number) => (
          <Chip
            variant="outlined"
            label={`${option.pais.codigo} - ${option.numeroRegistro} (${option.formulas?.map(
              (formula: APIt.RegistroFormulaVO, index: number) =>
                `${index === 0 ? "" : ", "}${formula.codigo}`
            )})`}
            {...getTagProps({ index })}
          />
        ))
      }
      onInputChange={(event: any, newInputValue: any) => {
        handleRegistroSearch(newInputValue);
      }}
      getOptionLabel={(option: any) =>
        typeof option === "string"
          ? option
          : `${option.pais.codigo} - ${option.numeroRegistro} (${option.formulas?.map(
              (formula: APIt.RegistroFormulaVO, index: number) =>
                `${index === 0 ? "" : ", "}${formula.codigo}`
            )})`
      }
    />
  );
}

export default RegistroComboMultiple;
