import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (initialData: DashboardTramiteDaily[]) => {
  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["Aprobados", "Rechazados", "Desistidos"];

  let header = "Por Innovación - Sin datos";

  if (initialData?.length > 0) {
    // ADD PAIS LABELS
    const labels: string[] = ["Si", "No"];

    // ADD DATASETS
    // -- Aprobados
    const tmpAprobadosData: any = {};
    const tmpRechazadosData: any = {};
    const tmpDesistidosData: any = {};
    initialData.forEach((entry: DashboardTramiteDaily) => {
      entry.efectividadAprobados
        ?.filter((t) =>
          ["nuevo", "modificacion", "modificacionTecnica", "modificacionAdministrativa"].includes(
            t.tipo
          )
        )
        ?.forEach((tramite: DashboardTramiteVO) => {
          if (!tmpAprobadosData[tramite.innovacion ? "Si" : "No"])
            tmpAprobadosData[tramite.innovacion ? "Si" : "No"] = 0;
          tmpAprobadosData[tramite.innovacion ? "Si" : "No"] += 1;
        });
      entry.efectividadRechazados
        ?.filter((t) =>
          ["nuevo", "modificacion", "modificacionTecnica", "modificacionAdministrativa"].includes(
            t.tipo
          )
        )
        ?.forEach((tramite: DashboardTramiteVO) => {
          if (!tmpRechazadosData[tramite.innovacion ? "Si" : "No"])
            tmpRechazadosData[tramite.innovacion ? "Si" : "No"] = 0;
          tmpRechazadosData[tramite.innovacion ? "Si" : "No"] += 1;
        });
      entry.efectividadDesistidos
        ?.filter((t) =>
          ["nuevo", "modificacion", "modificacionTecnica", "modificacionAdministrativa"].includes(
            t.tipo
          )
        )
        ?.forEach((tramite: DashboardTramiteVO) => {
          if (!tmpDesistidosData[tramite.innovacion ? "Si" : "No"])
            tmpDesistidosData[tramite.innovacion ? "Si" : "No"] = 0;
          tmpDesistidosData[tramite.innovacion ? "Si" : "No"] += 1;
        });
    });

    const datasetAprobados = {
      label: "Aprobados",
      data: labels.map((innovacion: string) => tmpAprobadosData[innovacion] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetAprobados);

    const datasetRechazados = {
      label: "Rechazados",
      data: labels.map((innovacion: string) => tmpRechazadosData[innovacion] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetRechazados);

    const datasetDesistidos = {
      label: "Desistidos",
      data: labels.map((innovacion: string) => tmpDesistidosData[innovacion] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetDesistidos);

    data.labels = labels.map((innovacion: string) => {
      const aprobados = tmpAprobadosData[innovacion] || 0;
      const rechazados = tmpRechazadosData[innovacion] || 0;
      const desistidos = tmpDesistidosData[innovacion] || 0;

      const total = aprobados + rechazados + desistidos;
      let percentage = (aprobados / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${innovacion} (${percentage.toFixed(2)}%)`;
    });

    header = "Por Innovación - Solo considera nuevos registros y modificaciones";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
