import { Categoria } from "API";
import { useState, useEffect } from "react";

import fetchCategoriaList from "services/Categoria/CategoriaList";

const useCategoriaOptions = (): [Categoria[], () => any] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchCategoriaList().then((result) => {
        const { categorias } = result;
        setItems(categorias);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useCategoriaOptions;
