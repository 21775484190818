import { Autocomplete, Box, Card, Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDInput from "components/md/MDInput";
import MDTypography from "components/md/MDTypography";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { BusinessUnit, CategoriaTerapeutica, FormaCosmeticaFarmaceutica, Pais } from "API";

export default function (): JSX.Element {
  const {
    state: {
      formulaSearchState,
      buList,
      categoriaList,
      categoriaTerapeuticaList,
      formaCosmeticaFarmaceuticaList,
      principioList,
      suList,
    },
    dispatch,
  } = useTramitesAppStateContext();
  return (
    <Grid item xs={12}>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5" fontWeight="bold">
            Búsqueda avanzada
          </MDTypography>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Código"
                  value={formulaSearchState.advancedData.searchCodigo || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchCodigo: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={categoriaList}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Categoría" />
                  )}
                  getOptionLabel={(option: any) => option.nombre}
                  value={
                    (categoriaList.find(
                      (e) => e.codigo === formulaSearchState.advancedData.searchCategoria
                    ) || null) as any
                  }
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.nombre}
                    </Box>
                  )}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchCategoria: newVal?.codigo,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Producto"
                  value={formulaSearchState.advancedData.searchProducto || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchProducto: e.target.value,
                          searchProductosDestino: e.target.value,
                        },
                      },
                    });
                  }}
                />
                </Grid> */}

              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Marca"
                  value={formulaSearchState.advancedData.searchMarca || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchMarca: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={[EstadoCodigoFormula.borrador, EstadoCodigoFormula.publicado]}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Estado" />
                  )}
                  value={formulaSearchState.advancedData.searchEstado || null}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchEstado: newVal,
                        },
                      },
                    });
                  }}
                />
                </Grid> */}

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={buList.map((e: BusinessUnit) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Business Unit" />
                  )}
                  value={(formulaSearchState.advancedData.searchBu || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchBu: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={iniciativaList.map((e: Iniciativa) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Iniciativa" />
                  )}
                  value={formulaSearchState.advancedData.searchIniciativa || null}
                  onChange={(e: any, newVal: string) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchIniciativa: newVal,
                        },
                      },
                    });
                  }}
                />
                </Grid> */}

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={suList.map((e: Pais) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Sourcing Unit" />
                  )}
                  value={(formulaSearchState.advancedData.searchSourcingUnits || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchSourcingUnits: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={suList.map((e: Pais) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Pais Destino" />
                  )}
                  value={(formulaSearchState.advancedData.searchPaisesDestino || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchPaisesDestino: newVal,
                        },
                      },
                    });
                  }}
                />
                </Grid> */}

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={principioList}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      placeholder="Principio Activo/Ingredientes"
                    />
                  )}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.nombre}
                    </Box>
                  )}
                  value={
                    (principioList.find(
                      (e: any) =>
                        e.nombre === formulaSearchState.advancedData.searchPrincipiosActivos
                    ) || null) as any
                  }
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchPrincipiosActivos: newVal?.nombre,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Presentaciones"
                  value={formulaSearchState.advancedData.searchPresentaciones || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchPresentaciones: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={formaCosmeticaFarmaceuticaList.map(
                    (e: FormaCosmeticaFarmaceutica) => e.nombre
                  )}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="FC/FF" />
                  )}
                  value={
                    (formulaSearchState.advancedData.searchFormaCosmeticaFarmaceutica ||
                      null) as any
                  }
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchFormaCosmeticaFarmaceutica: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={categoriaTerapeuticaList}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Categoría Terapéutica" />
                  )}
                  getOptionLabel={(option: any) => option.nombre}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.nombre}
                    </Box>
                  )}
                  value={
                    (categoriaTerapeuticaList.find(
                      (e: CategoriaTerapeutica) =>
                        e.nombre === formulaSearchState.advancedData.searchCategoriaTerapeutica
                    ) || null) as any
                  }
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-formulaSearchState",
                      payload: {
                        ...formulaSearchState,
                        advancedData: {
                          ...formulaSearchState.advancedData,
                          searchCategoriaTerapeutica: newVal?.nombre,
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}
