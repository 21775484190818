import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/md/MDButton";
import * as Yup from "yup";
import FormField from "components/tramites/FormField";
import MDBox from "components/md/MDBox";
import { Icon } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EstadoObjecion } from "API";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validations = Yup.object().shape({
  descripcion: Yup.string().required("Descripción requerida").nullable(),
  fechaRespuesta: Yup.string().required("Fecha de respuesta requerida").nullable(),
});

/* eslint-disable */
type SetValueCallback = (values: any) => void;
/* eslint-enable */

type Props = {
  valueSetter: SetValueCallback;
  values: any;
};

function ObjecionRespuestaDialog(params: Props): JSX.Element {
  const { valueSetter } = params;
  const [open, setOpen] = React.useState(false);

  const {
    register,
    formState: { errors },
    control,
    reset,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const handleClickOpen = () => {
    reset({
      descripcion: "",
      fechaRespuesta: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const e = getValues();
    trigger().then((valid) => {
      if (!valid) return;

      const objecion: any = {
        descripcion: e.descripcion,
        fechaRespuesta: e.fechaRespuesta,
        estado: EstadoObjecion.respondida,
      };

      valueSetter(objecion);
    });
  };

  React.useEffect(() => {
    reset({
      descripcion: "",
      fechaRespuesta: "",
    });
  }, []);

  return (
    <div>
      <MDButton variant="text" color="dark" onClick={handleClickOpen}>
        <Icon>add</Icon>&nbsp;Ingresar respuesta
      </MDButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <form onSubmit={() => {}}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Respuesta enviada
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <MDBox>
              <FormField
                label="Descripción"
                type="text"
                name="descripcion"
                errors={errors.descripcion?.message}
                register={register}
                multiline
                rows={4}
              />
            </MDBox>
            <MDBox mt={4}>
              <FormField
                label="Fecha"
                type="date"
                name="fechaRespuesta"
                errors={errors.fechaRespuesta?.message}
                register={register}
                control={control}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button autoFocus onClick={() => handleSave()}>
              Ingresar
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default ObjecionRespuestaDialog;
