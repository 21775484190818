import { Accordion, AccordionDetails, AccordionSummary, Grid, Icon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import MDBox from "components/md/MDBox";
import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDTypography from "components/md/MDTypography";
import MDButton from "components/md/MDButton";
import { PreferencesType } from "services/Utils/DossierUtils";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import DossierHeaderProgress from "./DossierHeaderProgress";

export interface Props {
  children?: React.ReactNode;
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>;
  preferences: PreferencesType;
}

function DossierMasterAccordion(params: Props): JSX.Element {
  const { children, formMethods, formulaFormMethods, preferences } = params;
  const [expanded, setExpanded] = useState(false);

  const { setValue: setFormulasValue, getValues: getFormulasValues } = formulaFormMethods;
  const { setValue: setTramiteValue, getValues: getTramiteValues } = formMethods;
  const { estado, pais } = getTramiteValues();

  const selectAll = (value: boolean) => {
    const { formulas } = getFormulasValues();
    const { dossierData: tramiteDossierData, dossierPreferences } = getTramiteValues();

    const dossierPreferencesObj = JSON.parse(dossierPreferences) || {};

    const newTramiteDossierData = tramiteDossierData.map((entry: APIt.DossierField) => ({
      ...entry,
      done: value,
    }));
    setTramiteValue("dossierData", newTramiteDossierData);

    formulas?.forEach((formula: APIt.CodigoFormula, index: number) => {
      const formulaDossierData = formula.dossierData;
      const newFormulaDossierData = formulaDossierData.map((entry: APIt.DossierField) => {
        if (
          dossierPreferencesObj.formulas[formula.id]?.preferences?.find(
            (p: any) => p.code === entry.codigo
          )
        ) {
          return {
            ...entry,
            done: value,
          };
        }
        return entry;
      });

      setFormulasValue(`formulas.${index}.dossierData`, newFormulaDossierData);
    });
  };

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            onClick={() => setExpanded(!expanded)}
            sx={{
              pointerEvents: "auto",
            }}
          />
        }
        sx={{
          pointerEvents: "none",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={8}>
            <MDBox>
              <Grid container direction="row">
                <Grid
                  item
                  onClick={() => setExpanded(!expanded)}
                  sx={{
                    pointerEvents: "auto",
                  }}
                >
                  <MDTypography variant="h3">Seguimiento de dossier</MDTypography>
                </Grid>
                {canEdit && (
                  <Grid item sx={{ mt: 0.5, ml: 2, pointerEvents: "auto" }}>
                    <MDButton
                      title="Seleccionar todo"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      iconOnly
                      circular
                      sx={{ mt: 0, mr: 1 }}
                      onClick={() => {
                        selectAll(true);
                      }}
                    >
                      <Icon>checkcirclecutline</Icon>
                    </MDButton>
                    <MDButton
                      title="Deseleccionar todo"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      iconOnly
                      circular
                      sx={{ mt: 0, mr: 1 }}
                      onClick={() => {
                        selectAll(false);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDBox
              onClick={() => setExpanded(!expanded)}
              sx={{
                pointerEvents: "auto",
              }}
            >
              <MDBox mr={2} mt={-1}>
                <DossierHeaderProgress
                  formMethods={formMethods}
                  formulaFormMethods={formulaFormMethods}
                  preferences={preferences}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
export default DossierMasterAccordion;
