import { DashboardTramiteYearly } from "API";
import React from "react";
import LineChart from "components/tramites/Dashboard/LineChart";
import useData from "./useData";

interface Props {
  initialAprobadosData: DashboardTramiteYearly[];
  initialRechazadosData: DashboardTramiteYearly[];
  loading: boolean;
}

function Tramites(props: Props): JSX.Element {
  const { initialAprobadosData, initialRechazadosData, loading } = props;

  const { header, data, options } = useData(initialAprobadosData, initialRechazadosData);

  return <LineChart header={header} data={data} options={options} loading={loading} />;
}

export default React.memo(Tramites);
