import { CategoriaTerapeutica } from "API";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

import {
  createCategoriaTerapeutica,
  fetchCategoriaTerapeutica,
} from "services/CategoriaTerapeutica/CategoriaTerapeuticaCRUD";

/* eslint-disable */
type CreateCallback = (newEntry: CategoriaTerapeutica) => void;
/* eslint-enable */

const useCategoriaTerapeuticaCRUD = () => {
  const {
    state: { categoriaTerapeuticaList },
    dispatch,
  } = useTramitesAppStateContext();

  const doCreateCategoriaTerapeutica = (
    categoriaTerapeutica: CategoriaTerapeutica,
    callback: CreateCallback
  ) => {
    createCategoriaTerapeutica(categoriaTerapeutica).then((id) => {
      fetchCategoriaTerapeutica(id).then((categoriaTerapeuticas) => {
        dispatch({
          type: "update-categoriasTerapeuticas",
          payload: [...categoriaTerapeuticaList, categoriaTerapeuticas],
        });
        if (callback) callback(categoriaTerapeuticas);
      });
    });
  };

  return [doCreateCategoriaTerapeutica];
};

export default useCategoriaTerapeuticaCRUD;
