/* eslint-disable no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Box, Chip, CircularProgress, Grid, Switch } from "@mui/material";
import { EstadoTramite, MedioDifusion, TipoTramite, Tramite } from "API";
import * as Yup from "yup";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDTypography from "components/md/MDTypography";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FormField from "components/tramites/FormField";
import FormulaYPresentacionCombo from "features/Common/FormulaYPresentacionCombo";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateTramite } from "services/Tramite/TramiteCRUD";
import RegistroComboMultiple from "features/Common/RegistroComboMultiple";

type Props = {
  tipo: TipoTramite;
};

function MultiplePicker(params: Props): JSX.Element {
  const { tipo } = params;
  const path = useLocation().pathname;
  const back = path.substring(0, path.lastIndexOf("/"));
  const navigate = useNavigate();

  const tramite: Tramite = {
    id: "",
    descripcion: "",
    tipo: TipoTramite.nuevo,
    innovacion: false,
    estado: EstadoTramite.pendiente,
    fechaPresentacion: null,
    fechaPresentacionObjetivo: null,
    fechaObjetivo: null,
    fechaObtencion: null,
    fechaLanzamiento: null,
    fechaLanzamientoObjetivo: null,
    fechaLiberacion: null,
    fechaLiberacionObjetivo: null,
    iniciativa: null,
    costosDirectos: "",
    costosIndirectos: "",
    comentarios: "",
    numeroRegistro: "",
    vencimientoSanitario: null,
    objeciones: [],
    formulas: [],
    presentaciones: [],
    dossierPreferences: "",
    dossierCompleteness: 0,
    dossierData: [],
    historico: false,
    deleted: false,
    codigoCPT: "",
    tipoCertificado: null,
    medioDifusion: null,
    etiqueta: "",
  } as Tramite;

  const validateNuevo = {
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
    autoridades: Yup.array().min(1, "Seleccione al menos una autoridad/país"),
  };
  const validateModificacion = {
    registros: Yup.array().min(1, "Seleccione al menos un registro a modificar"),
  };
  const validatePublicidad = {
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
    medioDifusion: Yup.object().required("Debe seleccionar el medio de difusión").nullable(),
    paises: Yup.array().min(1, "Seleccione al menos un país emisor"),
  };

  const {
    state: { autoridadList, suList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const paises = suList.filter((p) => userRol === "Global" || userPaises.includes(p.codigo));
  const autoridades = autoridadList.filter(
    (a) => userRol === "Global" || userPaises.includes(a.pais.codigo)
  );

  const tiposModificacion = [
    TipoTramite.modificacion,
    TipoTramite.modificacionAdministrativa,
    TipoTramite.modificacionTecnica,
  ];
  const isModificacion = tiposModificacion.includes(tipo);
  const isPublicidad = tipo === TipoTramite.publicidad;
  const isNuevo = tipo === TipoTramite.nuevo;

  let validations: any = validateNuevo;
  if (isModificacion) validations = validateModificacion;
  if (isPublicidad) validations = validatePublicidad;

  const formMethods: any = useForm({
    resolver: yupResolver(Yup.object().shape(validations)),
    defaultValues: {
      etiqueta: "",
      medioDifusion: null,
      formulas: [],
      presentaciones: [],
      autoridades: [],
      paises: [],
      registros: [],
      innovacion: false,
    },
  });
  const {
    trigger,
    control,
    formState: { errors },
    getValues,
    register,
  } = formMethods;

  const [tramites, setTramites] = useState([]);
  const [loading, setLoading] = useState(false);

  const crearTramitesNuevos = async () => {
    const { etiqueta, formulas, presentaciones, autoridades, innovacion } = getValues();
    setLoading(true);

    const tramiteList = [];
    for (let i = 0; i < autoridades.length; i += 1) {
      const autoridad = autoridades[i];

      const tramiteIn = {
        ...tramite,
        tipo,
        etiqueta,
        formulas,
        presentaciones,
        autoridad,
        pais: autoridad.pais,
        innovacion,
      };

      // eslint-disable-next-line no-await-in-loop
      const id = await updateTramite(tramiteIn as Tramite, []);
      tramiteList.push({ ...tramiteIn, id });
    }
    setTramites(tramiteList);
    setLoading(false);
  };

  const createTramitesPublicidad = async () => {
    const { etiqueta, formulas, presentaciones, paises, medioDifusion } = getValues();
    setLoading(true);

    const tramiteList = [];
    for (let i = 0; i < paises.length; i += 1) {
      const pais = paises[i];
      const tramiteIn = {
        ...tramite,
        tipo,
        etiqueta,
        formulas,
        presentaciones,
        pais,
        medioDifusion,
      };

      // eslint-disable-next-line no-await-in-loop
      const id = await updateTramite(tramiteIn as Tramite, []);
      tramiteList.push({ ...tramiteIn, id });
    }
    setTramites(tramiteList);
    setLoading(false);
  };

  const createTramitesModificacion = async () => {
    const { etiqueta, registros, innovacion } = getValues();
    setLoading(true);

    const tramiteList = [];
    for (let i = 0; i < registros.length; i += 1) {
      const registro = registros[i];
      const {
        pais,
        autoridad,
        vencimientoSanitario,
        formulas,
        presentaciones,
        numeroRegistro,
        formulaRegistro,
      } = registro;

      const tramiteIn = {
        ...tramite,
        tipo,
        etiqueta,
        formulas,
        presentaciones,
        pais,
        innovacion,
        autoridad,
        vencimientoSanitario,
        numeroRegistro,
        formulaRegistro,
      };

      // eslint-disable-next-line no-await-in-loop
      const id = await updateTramite(tramiteIn as Tramite, []);
      tramiteList.push({ ...tramiteIn, id });
    }
    setTramites(tramiteList);
    setLoading(false);
  };
  const activar = () => {
    trigger().then((valid: any) => {
      if (valid) {
        if (isNuevo) crearTramitesNuevos();
        if (isPublicidad) createTramitesPublicidad();
        if (isModificacion) createTramitesModificacion();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox p={3}>
        <MDTypography variant="h6">Activación múltiple</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="Etiqueta"
              type="text"
              name="etiqueta"
              errors={errors.etiqueta?.message}
              register={register as any}
            />
          </Grid>
          {(isPublicidad || isNuevo) && (
            <FormulaYPresentacionCombo formMethods={formMethods} pickPresentacion />
          )}
          {isNuevo && ( // -------------------------------------------------NUEVO
            <Grid item xs={12} sm={8} xl={6}>
              <ControlledAutocomplete
                label="Autoridades"
                multiple
                name="autoridades"
                errors={errors.autoridades?.message}
                options={
                  autoridades.sort((a, b) => a.pais.nombre.localeCompare(b.pais.nombre)) || []
                }
                control={control as any}
                renderOption={(props: any, option: any) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.pais.nombre} - {option.nombre}
                  </Box>
                )}
                renderTags={(value: any, getTagProps: any) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="outlined"
                      label={`${option.pais.nombre} - ${option.nombre}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                filterOptions={(x: any, y: any) =>
                  x.filter((entry: any) =>
                    `${entry.pais.nombre} - ${entry.nombre}`
                      .toUpperCase()
                      .includes(y.inputValue?.toUpperCase())
                  )
                }
              />
            </Grid>
          )}

          {isModificacion && ( // -------------------------------------------------MODIFICACION
            <Grid item xs={12} sm={12} xl={9}>
              <RegistroComboMultiple formMethods={formMethods} />
            </Grid>
          )}

          {(isNuevo || isModificacion) && (
            <Grid item xs={12} sm={12} xl={3}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 2, sm: 1 }}
              >
                <MDBox mr={1}>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Controller
                        name="innovacion"
                        control={control}
                        render={(props: any) => {
                          const { field } = props;
                          return (
                            <Switch
                              onChange={(e) => field.onChange(e.target.checked)}
                              checked={field.value}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>Si</Grid>
                  </Grid>
                </MDBox>
                <MDBox lineHeight={0} mx={2}>
                  <MDTypography variant="button" color="text">
                    ¿Es innovación?
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          )}

          {isPublicidad && ( // -------------------------------------------------PUBLICIDAD
            <Grid item xs={12} sm={8} xl={6}>
              <ControlledAutocomplete
                label="Paises"
                multiple
                name="paises"
                errors={errors.paises?.message}
                options={paises || []}
                control={control as any}
                renderOption={(props: any, option: any) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.nombre}
                  </Box>
                )}
                renderTags={(value: any, getTagProps: any) =>
                  value.map((option: any, index: number) => (
                    <Chip variant="outlined" label={option.nombre} {...getTagProps({ index })} />
                  ))
                }
              />
            </Grid>
          )}
          {isPublicidad && (
            <Grid item xs={12} sm={4} xl={3}>
              <ControlledAutocomplete
                label="Medio de Difusión"
                name="medioDifusion"
                errors={errors.medioDifusion?.message}
                options={[
                  { id: MedioDifusion.grafico, nombre: "Materiales impresos" },
                  { id: MedioDifusion.radio, nombre: "Radio" },
                  { id: MedioDifusion.television, nombre: "TV" },
                  { id: MedioDifusion.cine, nombre: "Cine" },
                  { id: MedioDifusion.mediosDigitales, nombre: "Medios Digitales" },
                ]}
                control={control as any}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} direction="row-reverse" my={2}>
          {tramites.length < 1 && (
            <Grid item>
              <MDBox
                width="100%"
                height={{ xs: "auto", md: "100%" }}
                display="flex"
                justifyContent={{ xs: "flex-start", md: "flex-end" }}
                alignItems="flex-end"
                mt={{ xs: 2, md: 0 }}
              >
                <MDButton variant="gradient" color="info" onClick={activar}>
                  Activar
                </MDButton>
              </MDBox>
            </Grid>
          )}
          <Grid item>
            <MDBox
              width="100%"
              height={{ xs: "auto", md: "100%" }}
              display="flex"
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
              alignItems="flex-end"
              mt={{ xs: 2, md: 0 }}
            >
              <MDButton variant="gradient" color="light" onClick={() => navigate(back)}>
                Salir
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      {tramites.length > 0 && (
        <>
          <MDBox p={3}>
            <MDTypography variant="h6">Trámites creados</MDTypography>
          </MDBox>
          <MDBox pb={3} px={3}>
            {tramites.map((tramite) => (
              <MDBox key={tramite.id}>
                <Link to={`${back}/${tramite.id}`}>{tramite.pais.nombre}</Link>
              </MDBox>
            ))}
          </MDBox>
        </>
      )}
    </MDBox>
  );
}

export default MultiplePicker;
