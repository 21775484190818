import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Icon, Tooltip } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDInput from "components/md/MDInput";
import * as DossierUtils from "services/Utils/DossierUtils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

/* eslint-disable */
type UpdatePreferencesFunction = (preferences: DossierUtils.TramiteDefinitionEntry[]) => void;
/* eslint-enable */

type Props = {
  preferences: DossierUtils.TramiteDefinitionEntry[];
  newOptions: DossierUtils.TramiteDefinitionEntry[];
  updatePreferences: UpdatePreferencesFunction;
};

function NewDossierFieldDlg(params: Props): JSX.Element {
  const { preferences, updatePreferences, newOptions } = params;
  const [open, setOpen] = React.useState(false);
  const [newFields, setNewFields] = React.useState([]);

  const handleClickOpen = () => {
    setNewFields([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    updatePreferences([...preferences, ...newFields]);
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Ingresar nuevos campos">
        <IconButton size="small" disableRipple onClick={handleClickOpen}>
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
        </IconButton>
      </Tooltip>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ingresar campos al dossier
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <MDBox width={500}>
            <Autocomplete
              multiple
              filterSelectedOptions
              options={newOptions}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" placeholder="Nuevos campos" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.code}>
                  {option.name}
                </li>
              )}
              isOptionEqualToValue={(option, value) => {
                if (!value.code) return false;
                return option.code === value.code;
              }}
              getOptionLabel={(option) => option.name}
              onChange={(_, data) => setNewFields(data)}
              value={newFields}
              filterOptions={(options, state) =>
                options.filter((option) =>
                  option.name.toUpperCase().includes(state.inputValue.toUpperCase())
                )
              }
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancelar
          </Button>
          <Button autoFocus onClick={handleSave}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default NewDossierFieldDlg;
