export const listFabricantes = /* GraphQL */ `
  query ListFabricantes($filter: ModelFabricanteFilterInput, $limit: Int, $nextToken: String) {
    listFabricantes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
        direccion
        vencimientoBPM
        pais {
          codigo
          nombre
        }
      }
      nextToken
    }
  }
`;

export const createFabricanteMutation = /* GraphQL */ `
  mutation CreateFabricante(
    $input: CreateFabricanteInput!
    $condition: ModelFabricanteConditionInput
  ) {
    createFabricante(input: $input, condition: $condition) {
      id
      nombre
      direccion
      vencimientoBPM
      pais {
        codigo
        nombre
      }
    }
  }
`;

export const getFabricante = /* GraphQL */ `
  query GetFabricante($id: ID!) {
    getFabricante(id: $id) {
      id
      nombre
      direccion
      vencimientoBPM
      pais {
        codigo
        nombre
      }
    }
  }
`;
