export const listPais = /* GraphQL */ `
  query ListPais($filter: ModelPaisFilterInput, $limit: Int, $nextToken: String) {
    listPais(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        codigo
        nombre
      }
      nextToken
    }
  }
`;

export const tmp = "";
