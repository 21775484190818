import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";

export default function ControlledAutocomplete({
  control,
  name,
  label,
  errors,
  options,
  renderOption,
  getOptionLabel,
  isOptionEqualToValue,
  renderInput,
  onChange,
  children,
  ...rest
}: {
  control: Control;
  name: string;
  label: string;
  errors: any;
  options: any[];
  renderOption?: any;
  getOptionLabel?: any;
  isOptionEqualToValue?: any;
  renderInput?: any;
  onChange?: any;
  children?: ReactNode;
  [key: string]: any;
}): JSX.Element {
  return (
    <Controller
      render={(props) => {
        const renderAutocomplete = () => (
          <Autocomplete
            {...rest}
            options={options}
            getOptionLabel={getOptionLabel || ((option) => option.nombre)}
            renderOption={
              renderOption ||
              ((props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.nombre}
                </Box>
              ))
            }
            isOptionEqualToValue={
              isOptionEqualToValue ||
              ((option, value) => {
                if (!value.id) return false;
                return option.id === value.id;
              })
            }
            renderInput={
              renderInput ||
              ((params) => (
                <MDBox mb={1.5}>
                  <TextField
                    {...params}
                    name={name}
                    label={label}
                    error={!!errors}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ))
            }
            onChange={(_, data) => {
              if (onChange) onChange(data, props.field);
              props.field.onChange(data);
            }}
            value={props.field.value ? props.field.value : null}
          />
        );

        if (children) {
          return (
            <Grid container direction="row" spacing={1}>
              <Grid item md={10}>
                {renderAutocomplete()}
              </Grid>
              <Grid item md={2} pt={1} mt={1}>
                {children}
              </Grid>
            </Grid>
          );
        }
        return renderAutocomplete();
      }}
      name={name}
      control={control}
    />
  );
}

ControlledAutocomplete.defaultProps = {
  children: null,
  renderOption: null,
  getOptionLabel: null,
  onChange: null,
  isOptionEqualToValue: null,
  renderInput: null,
};
