export const queryEfectividad = /* GraphQL */ `
  query listDashboardDailyByTypeAndDate($date: ModelStringKeyConditionInput, $nextToken: String) {
    dashboardDailyByTypeAndDate(
      type: efectividad
      date: $date
      sortDirection: ASC
      nextToken: $nextToken
    ) {
      items {
        id
        date
        type
        efectividadAprobados {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            id
            codigo
            nombre
          }
          tipo
        }
        efectividadDesistidos {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            id
            codigo
            nombre
          }
          tipo
        }
        efectividadEnCurso {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            id
            codigo
            nombre
          }
          tipo
        }
        efectividadRechazados {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            id
            codigo
            nombre
          }
          tipo
        }
      }
      nextToken
    }
  }
`;

export const querySometimientos = /* GraphQL */ `
  query listDashboardDailyByTypeAndDate($date: ModelStringKeyConditionInput, $nextToken: String) {
    dashboardDailyByTypeAndDate(
      type: sometimientos
      date: $date
      sortDirection: ASC
      nextToken: $nextToken
    ) {
      items {
        id
        date
        type
        sometimientosEnCurso {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            codigo
          }
          tipo
        }
        sometimientosOnTime {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            codigo
          }
          tipo
        }
        sometimientosVencidos {
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            id
            nombre
          }
          businessUnit {
            codigo
          }
          tipo
        }
      }
      nextToken
    }
  }
`;

export const queryObjeciones = /* GraphQL */ `
  query listDashboardDailyByTypeAndDate($date: ModelStringKeyConditionInput, $nextToken: String) {
    dashboardDailyByTypeAndDate(
      type: objeciones
      date: $date
      sortDirection: ASC
      nextToken: $nextToken
    ) {
      items {
        id
        date
        type
        objecionesAFavor {
          id
          tramite {
            estado
            formula
            id
            iniciativa {
              id
              nombre
            }
            innovacion
            pais {
              codigo
              id
              nombre
            }
            businessUnit {
              codigo
            }
            tipo
          }
        }
        objecionesDesistidas {
          id
          tramite {
            estado
            formula
            id
            iniciativa {
              id
              nombre
            }
            innovacion
            pais {
              codigo
              id
              nombre
            }
            businessUnit {
              codigo
            }
            tipo
          }
        }
        objecionesEnContra {
          id
          tramite {
            estado
            formula
            id
            iniciativa {
              id
              nombre
            }
            innovacion
            pais {
              codigo
              id
              nombre
            }
            businessUnit {
              codigo
            }
            tipo
          }
        }
        objecionesEnCurso {
          id
          tramite {
            estado
            formula
            id
            iniciativa {
              id
              nombre
            }
            innovacion
            pais {
              codigo
              id
              nombre
            }
            businessUnit {
              codigo
            }
            tipo
          }
        }
      }
      nextToken
    }
  }
`;

export const queryEestatus = /* GraphQL */ `
  query listDashboardDailyByTypeAndDate($date: ModelStringKeyConditionInput, $nextToken: String) {
    dashboardDailyByTypeAndDate(
      type: estatus
      date: $date
      sortDirection: ASC
      nextToken: $nextToken
    ) {
      items {
        id
        date
        type

        estatusAprobado {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
        estatusArmado {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
        estatusDesistido {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
        estatusEnDesarrollo {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
        estatusEnProceso {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
        estatusRechazado {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
        estatusSinDossier {
          businessUnit {
            codigo
          }
          estado
          formula
          id
          iniciativa {
            id
            nombre
          }
          innovacion
          pais {
            codigo
            nombre
          }
          tipo
        }
      }
      nextToken
    }
  }
`;
