export const queryRegistros = /* GraphQL */ `
  query listDashboardYearlyByTypeAndYear {
    dashboardYearlyByTypeAndYear(type: registros, sortDirection: ASC) {
      items {
        id
        type
        year
        registros
      }
      nextToken
    }
  }
`;

export const queryTramitesAprbados = /* GraphQL */ `
  query listDashboardYearlyByTypeAndYear {
    dashboardYearlyByTypeAndYear(type: tramitesAprobados, sortDirection: ASC) {
      items {
        id
        type
        year
        tramitesAprobados
      }
      nextToken
    }
  }
`;

export const queryTramitesRechazados = /* GraphQL */ `
  query listDashboardYearlyByTypeAndYear {
    dashboardYearlyByTypeAndYear(type: tramitesRechazados, sortDirection: ASC) {
      items {
        id
        type
        year
        tramitesRechazados
      }
      nextToken
    }
  }
`;
