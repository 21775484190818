/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Autocomplete, DialogContentText, Divider } from "@mui/material";
import MDInput from "components/md/MDInput";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

type Props = {
  options: any;
  columnName: any;
  columnId: string;
  callback: any;
};

function MultiSelectFilter(params: Props): JSX.Element {
  const { options, columnName, callback, columnId } = params;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState([]);

  const {
    dispatch,
    state: { registroSearchState },
  } = useTramitesAppStateContext();

  const stateValue: any = registroSearchState.filters[columnId];

  React.useEffect(() => {
    setValue(stateValue);
  }, [stateValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    callback(value || undefined);
    const newFilters = { ...registroSearchState.filters };
    newFilters[columnId] = value;
    dispatch({
      type: "update-registroSearchState",
      payload: { ...registroSearchState, filters: newFilters },
    });
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleClickOpen} style={{ marginLeft: 4, cursor: "pointer" }}>
        👁
      </span>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle sx={{ width: 900 }}>Seleccione las columnas a visualizar</DialogTitle>
        <DialogContent>
          <DialogContentText>Filtrar por {columnName}</DialogContentText>
          <Divider />
          <Autocomplete
            options={options}
            multiple
            renderInput={(params) => (
              <MDInput {...params} variant="standard" placeholder="Seleccione..." />
            )}
            value={value}
            onChange={(e: any, newVal: any) => {
              setValue(newVal);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MultiSelectFilter;
