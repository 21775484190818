import { Accordion, AccordionDetails, AccordionSummary, Card, Divider } from "@mui/material";

import MDBox from "components/md/MDBox";
import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DatosReadOnlyTramite from "./DatosReadOnlyTramite";
import DatosGeneralesTramite from "./DatosGeneralesTramite";
import TramiteTabBar from "./TramiteTabBar";
import TramiteHeader from "./TramiteHeader";
import DetalleRegistro from "./DetalleRegistro";
import VerFormulas from "../Lectura/VerFormulas";
import VerOtrosTramites from "../Lectura/VerOtrosTramites";
import VerRegistro from "../Lectura/VerRegistro";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
};

function DetalleTramite(params: Props): JSX.Element {
  const [tabValue, setTabValue] = useState("detalle");

  const { formMethods, formulas } = params;
  const { getValues } = formMethods;
  const { registroModificado, tipo, formulas: tramiteFormulas } = getValues();

  const showFormulas = formulas.map((f) => {
    const tf = tramiteFormulas.find((e) => e.codigo === f.codigo);
    return {
      ...f,
      bu: tf?.bu,
      categoria: tf?.categoria,
      categoriaTerapeutica: tf?.categoriaTerapeutica,
      condicionVenta: tf?.condicionVenta,
      formaCosmeticaFarmaceutica: tf?.formaCosmeticaFarmaceutica,
      marca: tf?.marca,
      principiosActivos: tf?.principiosActivos,
    };
  });

  const detalleRegistro =
    tipo === APIt.TipoTramite.nuevo ||
    tipo === APIt.TipoTramite.modificacion ||
    tipo === APIt.TipoTramite.modificacionTecnica ||
    tipo === APIt.TipoTramite.modificacionAdministrativa;

  return (
    <MDBox pb={1}>
      <Card>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  pointerEvents: "auto",
                }}
              />
            }
          >
            <TramiteHeader formMethods={formMethods} />
          </AccordionSummary>
          <AccordionDetails>
            <DatosReadOnlyTramite formMethods={formMethods} />
            <MDBox display="flex" mt={-1}>
              <TramiteTabBar setValue={setTabValue} value={tabValue} formMethods={formMethods} />
            </MDBox>
            <Divider />
            {tabValue === "detalle" && <DatosGeneralesTramite formMethods={formMethods} />}
            {tabValue === "detalle" && detalleRegistro && (
              <DetalleRegistro formMethods={formMethods} formulas={formulas} />
            )}
            {tabValue === "formulas" && (
              <VerFormulas formMethods={formMethods} formulas={showFormulas} />
            )}
            {tabValue === "otrosTramites" && (
              <VerOtrosTramites formMethods={formMethods} formulas={formulas} />
            )}
            {tabValue === "registro" && (
              <VerRegistro formMethods={formMethods} registroId={registroModificado?.id} />
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
    </MDBox>
  );
}

export default DetalleTramite;
