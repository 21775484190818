import { DashboardTramiteDaily } from "API";
import { getBasePieOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (initialData: DashboardTramiteDaily[]) => {
  const data: any = {
    labels: ["Aprobados", "Rechazados", "Desistidos"],
    datasets: [],
  };

  let header = "Total - Sin datos";

  if (initialData?.length > 0) {
    const aprobados = initialData?.reduce((acc, curr) => {
      if (curr.efectividadAprobados?.length) return acc + curr.efectividadAprobados.length;
      return acc;
    }, 0);
    const rechazados = initialData?.reduce((acc, curr) => {
      if (curr.efectividadRechazados?.length) return acc + curr.efectividadRechazados.length;
      return acc;
    }, 0);
    const desistidos = initialData?.reduce((acc, curr) => {
      if (curr.efectividadDesistidos?.length) return acc + curr.efectividadDesistidos.length;
      return acc;
    }, 0);

    const dataset = {
      data: [aprobados, rechazados, desistidos],
      backgroundColor: [
        dashboardColors.green.background,
        dashboardColors.red.background,
        dashboardColors.orange.background,
      ],
      borderColor: [
        dashboardColors.green.border,
        dashboardColors.red.border,
        dashboardColors.orange.border,
      ],
      borderWidth: 1,
    };
    data.datasets = [dataset];

    const total = rechazados + desistidos + aprobados;
    const efectividad = (aprobados / total) * 100;
    header = `Total - Efectividad ${efectividad.toFixed(2)}%`;
  }

  return { header, data, options: getBasePieOptions() };
};

export default useData;
