import { Divider, Tooltip } from "@mui/material";
import React from "react";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

export interface Props {
  note: string;
  date: string;
  enabled: boolean;
  children?: React.ReactNode;
  truncate: number;
  text: string;
  variant: string;
}

function DetailsTooltip(params: Props): JSX.Element {
  const { note, date, enabled, truncate, text, variant } = params;

  let truncatedText = text;
  let truncated = false;
  if (text.length > truncate) {
    truncated = true;
    truncatedText = `${text.substring(0, truncate)}...`;
  }

  if (enabled)
    return (
      <Tooltip
        title={
          <>
            {truncated && (
              <>
                <MDBox>
                  <MDTypography color="light" variant="caption">
                    {text}
                  </MDTypography>
                </MDBox>
                <Divider />
              </>
            )}
            {note && (
              <MDBox>
                <MDTypography color="light" variant="subtitle2">
                  {note}
                </MDTypography>
              </MDBox>
            )}
            {date && (
              <MDBox>
                <MDTypography color="light" variant="subtitle2">
                  Fecha estimada: {date}
                </MDTypography>
              </MDBox>
            )}
          </>
        }
      >
        <u>
          <MDTypography variant={variant}>{truncatedText}</MDTypography>
        </u>
      </Tooltip>
    );

  return truncated ? (
    <Tooltip title={text}>
      <MDTypography variant={variant}>{truncatedText}</MDTypography>
    </Tooltip>
  ) : (
    <MDTypography variant={variant}>{truncatedText}</MDTypography>
  );
}

export default DetailsTooltip;
