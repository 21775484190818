import { useEffect, useState } from "react";
import {
  getEfectividadEntries,
  getEstatusEntries,
  getObjecionessEntries,
  getSometimientosEntries,
} from "services/DashboardTramiteDaily/DashboardTramiteDaily";
import {
  getRegistrosEntries,
  getTramitesAprobadosEntries,
  getTramitesRechazados,
} from "services/DashboardTramiteYearly/DashboardTramiteYearly";
import { DashboardTramiteDaily, DashboardTramiteYearly } from "API";
import useDashboardFilters from "./useDashboardFilter";

const useTramitesEfectividadData = (): [DashboardTramiteDaily[], boolean] => {
  const { dashboardFilterState } = useDashboardFilters();
  const { fechaInicio, fechaFin } = dashboardFilterState;

  const [data, setData] = useState([] as DashboardTramiteDaily[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getEfectividadEntries(fechaInicio, fechaFin).then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, [fechaInicio, fechaFin]);

  return [data, loading];
};

const useTramitesSometimientosData = (): [DashboardTramiteDaily[], boolean] => {
  const { dashboardFilterState } = useDashboardFilters();
  const { fechaInicio, fechaFin } = dashboardFilterState;

  const [data, setData] = useState([] as DashboardTramiteDaily[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getSometimientosEntries(fechaInicio, fechaFin).then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, [fechaInicio, fechaFin]);

  return [data, loading];
};

const useTramitesObjecionesData = (): [DashboardTramiteDaily[], boolean] => {
  const { dashboardFilterState } = useDashboardFilters();
  const { fechaInicio, fechaFin } = dashboardFilterState;

  const [data, setData] = useState([] as DashboardTramiteDaily[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getObjecionessEntries(fechaInicio, fechaFin).then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, [fechaInicio, fechaFin]);

  return [data, loading];
};

const useTramitesEstatusData = (): [DashboardTramiteDaily[], boolean] => {
  const { dashboardFilterState } = useDashboardFilters();
  const { fechaInicio, fechaFin } = dashboardFilterState;

  const [data, setData] = useState([] as DashboardTramiteDaily[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getEstatusEntries(fechaInicio, fechaFin).then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, [fechaInicio, fechaFin]);

  return [data, loading];
};

const useRegistrosRegistrosData = (): [DashboardTramiteYearly[], boolean] => {
  const [data, setData] = useState([] as DashboardTramiteYearly[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getRegistrosEntries().then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, []);

  return [data, loading];
};

const useRegistrosTramitesAprobadosData = (): [DashboardTramiteYearly[], boolean] => {
  const [data, setData] = useState([] as DashboardTramiteYearly[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getTramitesAprobadosEntries().then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, []);

  return [data, loading];
};

const useRegistrosTramitesRechazadosData = (): [DashboardTramiteYearly[], boolean] => {
  const [data, setData] = useState([] as DashboardTramiteYearly[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getTramitesRechazados().then((dashboardEntries) => {
      setData(dashboardEntries);
      setLoading(false);
    });
  }, []);

  return [data, loading];
};

export {
  useTramitesEfectividadData,
  useTramitesSometimientosData,
  useTramitesObjecionesData,
  useTramitesEstatusData,
  useRegistrosRegistrosData,
  useRegistrosTramitesAprobadosData,
  useRegistrosTramitesRechazadosData,
};
