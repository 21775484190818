import { Autocomplete, Box, Card, Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDInput from "components/md/MDInput";
import MDTypography from "components/md/MDTypography";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import {
  BusinessUnit,
  Categoria,
  CategoriaTerapeutica,
  EstadoTramite,
  FormaCosmeticaFarmaceutica,
  Iniciativa,
  Pais,
  TipoTramite,
} from "API";
import { paisByCluster, tipoNames } from "globalvars";

type Props = {
  tipo: string;
};

export default function (params: Props): JSX.Element {
  const { tipo } = params;
  const {
    state: {
      tramiteSearchState,
      buList,
      categoriaList,
      categoriaTerapeuticaList,
      formaCosmeticaFarmaceuticaList,
      iniciativaList,
      principioList,
      suList,
    },
    dispatch,
  } = useTramitesAppStateContext();
  return (
    <Grid item xs={12}>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5" fontWeight="bold">
            Búsqueda avanzada
          </MDTypography>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Código"
                  value={tramiteSearchState.advancedData.searchCodigo || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchCodigo: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={categoriaList.map((e: Categoria) => e.codigo)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Categoría" />
                  )}
                  value={(tramiteSearchState.advancedData.searchCategoria || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchCategoria: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Producto"
                  value={tramiteSearchState.advancedData.searchProducto || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchProducto: e.target.value,
                          searchProductoDestino: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Marca"
                  value={tramiteSearchState.advancedData.searchMarca || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchMarca: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {tipo !== "CREG" && (
                <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                  <Autocomplete
                    options={[
                      EstadoTramite.pendiente,
                      EstadoTramite.liberado,
                      EstadoTramite.presentado,
                      EstadoTramite.objetado,
                      EstadoTramite.aprobado,
                      EstadoTramite.rechazado,
                      EstadoTramite.desistido,
                    ]}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" placeholder="Estado" />
                    )}
                    value={(tramiteSearchState.advancedData.searchEstado || null) as any}
                    onChange={(e: any, newVal: any) => {
                      dispatch({
                        type: "update-tramiteSearchState",
                        payload: {
                          ...tramiteSearchState,
                          advancedData: {
                            ...tramiteSearchState.advancedData,
                            searchEstado: newVal,
                          },
                        },
                      });
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={buList.map((e: BusinessUnit) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Business Unit" />
                  )}
                  value={(tramiteSearchState.advancedData.searchBu || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchBu: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={iniciativaList.map((e: Iniciativa) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Iniciativa" />
                  )}
                  value={(tramiteSearchState.advancedData.searchIniciativa || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchIniciativa: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={suList.map((e: Pais) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Sourcing Unit" />
                  )}
                  value={(tramiteSearchState.advancedData.searchSourcingUnit || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchSourcingUnit: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  options={suList}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="País" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.codigo}>
                      {option.nombre}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) => {
                    if (!value.codigo) return false;
                    return option.codigo === value.codigo;
                  }}
                  getOptionLabel={(option: any) => option.nombre}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchPaisDestino: newVal,
                        },
                      },
                    });
                  }}
                  value={tramiteSearchState.advancedData.searchPaisDestino}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={Object.keys(paisByCluster).map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Cluster" />
                  )}
                  value={(tramiteSearchState.advancedData.searchCluster || null) as any}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchCluster: newVal ? newVal.id : null,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={principioList}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      placeholder="Principio Activo/Ingredientes"
                    />
                  )}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.nombre}
                    </Box>
                  )}
                  value={
                    (principioList.find(
                      (e: any) =>
                        e.nombre === tramiteSearchState.advancedData.searchPrincipiosActivos
                    ) || null) as any
                  }
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchPrincipiosActivos: newVal?.nombre,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Presentaciones"
                  value={tramiteSearchState.advancedData.searchPresentaciones || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchPresentaciones: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={formaCosmeticaFarmaceuticaList.map(
                    (e: FormaCosmeticaFarmaceutica) => e.nombre
                  )}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="FC/FF" />
                  )}
                  value={
                    (tramiteSearchState.advancedData.searchFormaCosmeticaFarmaceutica ||
                      null) as any
                  }
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchFormaCosmeticaFarmaceutica: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                <Autocomplete
                  options={categoriaTerapeuticaList.map((e: CategoriaTerapeutica) => e.nombre)}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" placeholder="Categoría Terapéutica" />
                  )}
                  value={
                    (tramiteSearchState.advancedData.searchCategoriaTerapeutica || null) as any
                  }
                  onChange={(e: any, newVal: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchCategoriaTerapeutica: newVal,
                        },
                      },
                    });
                  }}
                />
              </Grid>

              {!tipo && (
                <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
                  <Autocomplete
                    options={[
                      { id: TipoTramite.nuevo, label: tipoNames[TipoTramite.nuevo] },
                      { id: TipoTramite.renovacion, label: tipoNames[TipoTramite.renovacion] },
                      /* {
                        id: TipoTramite.modificacionTecnica,
                        label: tipoNames[TipoTramite.modificacionTecnica],
                      },
                      {
                        id: TipoTramite.modificacionAdministrativa,
                        label: tipoNames[TipoTramite.modificacionAdministrativa],
                      }, */
                      {
                        id: TipoTramite.modificacion,
                        label: tipoNames[TipoTramite.modificacion],
                      },
                      {
                        id: TipoTramite.docSoporteLegal,
                        label: tipoNames[TipoTramite.docSoporteLegal],
                      },
                      {
                        id: TipoTramite.vigilanciaSanitaria,
                        label: tipoNames[TipoTramite.vigilanciaSanitaria],
                      },
                      { id: TipoTramite.publicidad, label: tipoNames[TipoTramite.publicidad] },
                      { id: TipoTramite.otros, label: tipoNames[TipoTramite.otros] },
                      { id: TipoTramite.certificados, label: tipoNames[TipoTramite.certificados] },
                      {
                        id: TipoTramite.reconocimiento,
                        label: tipoNames[TipoTramite.reconocimiento],
                      },
                      { id: TipoTramite.modificacion, label: tipoNames[TipoTramite.modificacion] },
                      { id: TipoTramite.creg, label: tipoNames[TipoTramite.creg] },
                    ]}
                    isOptionEqualToValue={(option, value) => {
                      if (!value.id) return false;
                      return option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" placeholder="Tipo" />
                    )}
                    value={
                      (tramiteSearchState.advancedData.searchTipo
                        ? {
                            id: tramiteSearchState.advancedData.searchTipo,
                            label: tipoNames[tramiteSearchState.advancedData.searchTipo],
                          }
                        : null) as any
                    }
                    onChange={(e: any, newVal: any) => {
                      dispatch({
                        type: "update-tramiteSearchState",
                        payload: {
                          ...tramiteSearchState,
                          advancedData: {
                            ...tramiteSearchState.advancedData,
                            searchTipo: newVal ? newVal.id : null,
                          },
                        },
                      });
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Etiqueta"
                  value={tramiteSearchState.advancedData.searchEtiqueta || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchEtiqueta: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDInput
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  type="text"
                  label="Identificador"
                  value={tramiteSearchState.advancedData.searchCodigoCPT || ""}
                  onChange={(e: any) => {
                    dispatch({
                      type: "update-tramiteSearchState",
                      payload: {
                        ...tramiteSearchState,
                        advancedData: {
                          ...tramiteSearchState.advancedData,
                          searchCodigoCPT: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}
