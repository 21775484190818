import * as React from "react";
import * as DossierUtils from "services/Utils/DossierUtils";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Icon } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDInput from "components/md/MDInput";
import { parseISO, format, isValid } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
/* eslint-disable */
type UpdatePreferencesFunction = (preferences: DossierUtils.TramiteDefinitionEntry[]) => void;
/* eslint-enable */

type Props = {
  field: DossierUtils.TramiteDefinitionEntry;
  preferences: DossierUtils.TramiteDefinitionEntry[];
  updatePreferences: UpdatePreferencesFunction;
};

function DossierDetailsDlg(params: Props): JSX.Element {
  const { field, preferences, updatePreferences } = params;

  const [state, setState] = React.useState(field.details);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState(field.details);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const newPreferences = preferences.map((entry: DossierUtils.TramiteDefinitionEntry) => {
      if (entry.code === field.code) {
        return { ...entry, details: state };
      }
      return entry;
    });
    updatePreferences(newPreferences);
    setOpen(false);
  };

  return (
    <div>
      <IconButton size="small" disableRipple onClick={handleClickOpen}>
        <Icon sx={{ fontWeight: "bold" }}>notes</Icon>
      </IconButton>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <form onSubmit={() => {}}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Notas de {field.name}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <MDBox>
              <MDInput
                fullWidth
                label="Nota"
                value={state?.note || ""}
                onChange={(e: any) => {
                  setState({ ...state, note: e.target.value });
                }}
              />
            </MDBox>
            <Divider />
            <MDBox>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  onChange={(date: any) => {
                    if (isValid(date)) {
                      const inputDate = format(date, "yyyy-MM-dd");
                      setState({ ...state, date: inputDate });
                    } else {
                      setState({ ...state, date: "" });
                    }
                  }}
                  value={state?.date ? parseISO(state.date) : ""}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <MDInput fullWidth ref={inputRef} {...inputProps} label="Fecha estimada" />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button autoFocus onClick={handleSave}>
              Confirmar
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default DossierDetailsDlg;
