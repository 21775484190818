/* eslint-disable no-param-reassign */
import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";

import ExcelJs from "exceljs";
import axios from "axios";
import { saveAs } from "file-saver";
import { parseISO, format, isValid, differenceInDays } from "date-fns";
import { tipoNames, estadoTramiteNames, linkBase } from "globalvars";

const listTramites = /* GraphQL */ `
  query ListTramites($filter: ModelTramiteFilterInput, $limit: Int, $nextToken: String) {
    listTramites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tipo
        fechaObjetivo
        estado
        fechaLiberacion
        fechaPresentacion
        fechaObtencion
        fechaLanzamiento
        fechaLanzamientoObjetivo
        fechaLiberacionObjetivo
        fechaPresentacionObjetivo
        comentarios
        createdAt
        codigoCPT
        innovacion
        formulas {
          codigo
          marca
          categoria {
            codigo
            nombre
          }
          bu {
            codigo
            nombre
          }
        }
        pais {
          codigo
          nombre
        }
        presentaciones {
          formulas
          producto
          presentaciones
          fabricantes {
            fabricante {
              nombre
              pais {
                codigo
                nombre
              }
            }
          }
        }
        objeciones {
          estado
          autoridad {
            nombre
          }
          asignableRA
          fechaObjecion
          limiteEspera
          objecion
          fechaCierre
        }
        iniciativa {
          nombre
        }
        prioridad {
          nombre
        }
      }
      nextToken
    }
  }
`;

const formatDate = (date: string) =>
  date && isValid(parseISO(date)) && format(parseISO(date), "dd/MM/yyyy");

const getDateDifference = (dateStart: string, dateEnd: string): number => {
  if (dateStart && dateEnd) {
    const start = parseISO(dateStart);
    const end = parseISO(dateEnd);
    return differenceInDays(end, start);
  }
  return null;
};

const setTramiteRow = (seguimiento: ExcelJs.Worksheet, tramite: APIt.Tramite, row: number) => {
  const categoria = tramite.formulas?.reduce((acc: string, value: APIt.RegistroFormulaVO) => {
    if (!acc.includes(value.categoria.codigo)) {
      return acc ? `${acc}/${value.categoria.codigo}` : value.categoria.codigo;
    }
    return acc;
  }, "");

  const bu = tramite.formulas?.reduce((acc: string, value: APIt.RegistroFormulaVO) => {
    if (!acc.includes(value.bu?.codigo)) {
      return acc ? `${acc}/${value.bu?.codigo}` : value.bu?.codigo;
    }
    return acc;
  }, "");
  const codigoFormula = tramite.formulas?.reduce(
    (acc: string, value: APIt.RegistroFormulaVO) => (acc ? `${acc}/${value.codigo}` : value.codigo),
    ""
  );
  const producto = tramite.presentaciones?.reduce(
    (acc: string, value: APIt.RegistroPresentacionesVO) =>
      acc ? `${acc}/${value.producto}` : value.producto,
    ""
  );
  const marca = tramite.formulas?.reduce((acc: string, value: APIt.RegistroFormulaVO) => {
    if (!acc.includes(value.marca)) {
      return acc ? `${acc}/${value.marca}` : value.marca;
    }
    return acc;
  }, "");
  let SU = "";
  let fabricante = "";
  tramite.presentaciones?.forEach((presentacion: APIt.RegistroPresentacionesVO) => {
    presentacion.fabricantes?.forEach((fab: APIt.RegistroFabricanteVO) => {
      if (!SU.includes(fab.fabricante.pais.codigo)) {
        SU = SU ? `${SU}/${fab.fabricante.pais.codigo}` : fab.fabricante.pais.codigo;
      }
      if (!fabricante.includes(fab.fabricante.nombre)) {
        fabricante = fabricante ? `${fabricante}/${fab.fabricante.nombre}` : fab.fabricante.nombre;
      }
    });
  });
  const presentaciones = tramite.presentaciones?.reduce(
    (acc: string, value: APIt.RegistroPresentacionesVO) =>
      acc ? `${acc}/${value.presentaciones}` : value.presentaciones,
    ""
  );

  const fechaObjetivo = formatDate(tramite.fechaObjetivo);
  const estatusLiberacion = [
    APIt.EstadoTramite.aprobado,
    APIt.EstadoTramite.liberado,
    APIt.EstadoTramite.objetado,
    APIt.EstadoTramite.presentado,
    APIt.EstadoTramite.rechazado,
  ].includes(tramite.estado)
    ? "Si"
    : "No";
  const fechaLiberacion = formatDate(tramite.fechaLiberacion);
  const estatusRadicacion = [
    APIt.EstadoTramite.aprobado,
    APIt.EstadoTramite.objetado,
    APIt.EstadoTramite.presentado,
    APIt.EstadoTramite.rechazado,
  ].includes(tramite.estado)
    ? "Si"
    : "No";
  const fechaSometimiento = formatDate(tramite.fechaPresentacion);
  const fechaFinalizacion = formatDate(tramite.fechaObtencion);
  let concepto = "";
  if (tramite.estado === APIt.EstadoTramite.aprobado) concepto = "Aprobado";
  if (tramite.estado === APIt.EstadoTramite.rechazado) concepto = "Rechazado";
  if (tramite.estado === APIt.EstadoTramite.desistido) concepto = "Desistido";
  const tiempoTotal = getDateDifference(tramite.createdAt, tramite.fechaObtencion);
  const tiempoInterno = getDateDifference(tramite.createdAt, tramite.fechaPresentacion);
  const tiempoAutoridad = getDateDifference(tramite.fechaPresentacion, tramite.fechaObtencion);

  let costoTotal = null;
  if (
    tramite.costosDirectos &&
    tramite.costosIndirectos &&
    !Number.isNaN(tramite.costosDirectos) &&
    !Number.isNaN(tramite.costosIndirectos)
  ) {
    costoTotal = parseFloat(tramite.costosDirectos) + parseFloat(tramite.costosIndirectos);
  }
  const objecion = tramite.objeciones?.length > 0 ? "Si" : "No";
  let aFavor = "";
  const cantidad = tramite.objeciones?.length || 0;
  let cantidadFavor = 0;
  let cantidadContra = 0;
  let autoridad = "";
  let fechaObjecion = "";
  let fechaLimiteRespuesta = "";
  let descripcion = "";
  let fechaCierre = "";
  let asignableRA = "";
  tramite.objeciones?.forEach((obj: APIt.ObjecionVO) => {
    if (!autoridad.includes(obj.autoridad?.nombre)) {
      autoridad = autoridad ? `${autoridad}/${obj.autoridad?.nombre}` : obj.autoridad?.nombre;
    }
    fechaObjecion = fechaObjecion
      ? `${fechaObjecion}-${formatDate(obj.fechaObjecion)}`
      : formatDate(obj.fechaObjecion);
    fechaLimiteRespuesta = fechaLimiteRespuesta
      ? `${fechaLimiteRespuesta}-${formatDate(obj.limiteEspera)}`
      : formatDate(obj.limiteEspera);
    descripcion = descripcion ? `${descripcion}/${obj.descripcion}` : obj.descripcion;
    fechaCierre = fechaCierre
      ? `${fechaCierre}-${formatDate(obj.fechaCierre)}`
      : formatDate(obj.fechaCierre);

    const isAsignable = obj.asignableRA ? "Si" : "No";
    asignableRA = asignableRA ? `${asignableRA}/${isAsignable}` : isAsignable;

    let isAFavor = "Si";
    if ([APIt.EstadoObjecion.aceptada].includes(obj.estado)) {
      cantidadFavor += 1;
      isAFavor = "Si";
    } else {
      cantidadContra += 1;
      isAFavor = "No";
    }
    if ([APIt.EstadoObjecion.aceptada, APIt.EstadoObjecion.rechazada].includes(obj.estado))
      aFavor = aFavor ? `${aFavor}/${isAFavor}` : isAFavor;
  });
  const iniciado = [
    APIt.EstadoTramite.aprobado,
    APIt.EstadoTramite.objetado,
    APIt.EstadoTramite.presentado,
    APIt.EstadoTramite.rechazado,
  ].includes(tramite.estado)
    ? "x"
    : "";
  const aprobado = [APIt.EstadoTramite.aprobado].includes(tramite.estado) ? "x" : "";
  const concluido = [APIt.EstadoTramite.aprobado, APIt.EstadoTramite.rechazado].includes(
    tramite.estado
  )
    ? "x"
    : "";
  const rechazado = [APIt.EstadoTramite.rechazado].includes(tramite.estado) ? "x" : "";
  const desistido = [APIt.EstadoTramite.desistido].includes(tramite.estado) ? "x" : "";
  const innovacion = tramite.innovacion ? "I" : "";
  const mantenimiento =
    [
      APIt.TipoTramite.renovacion,
      APIt.TipoTramite.modificacion,
      APIt.TipoTramite.modificacionAdministrativa,
      APIt.TipoTramite.modificacionTecnica,
      APIt.TipoTramite.renovacion,
      APIt.TipoTramite.otros,
    ].includes(tramite.tipo) ||
    ([APIt.TipoTramite.nuevo].includes(tramite.tipo) && !innovacion)
      ? "M"
      : "";
  const doc = [APIt.TipoTramite.certificados].includes(tramite.tipo) ? "C" : "";
  const publicidad = [APIt.TipoTramite.publicidad].includes(tramite.tipo) ? "P" : "";
  const vigilancia = [APIt.TipoTramite.vigilanciaSanitaria].includes(tramite.tipo) ? "V" : "";

  let onTime = "";
  if (iniciado) {
    const metricaOnTime = getDateDifference(
      tramite.fechaPresentacionObjetivo,
      tramite.fechaPresentacion
    );
    onTime = metricaOnTime < 1 ? "On time" : "Out of time";
  }

  let aprobadoOnTime = "";
  if ([APIt.EstadoTramite.aprobado].includes(tramite.estado)) {
    const metricaOnTime = getDateDifference(tramite.fechaObjetivo, tramite.fechaObtencion);
    aprobadoOnTime = metricaOnTime < 1 ? "On time" : "Out of time";
  }

  let liberadosOnTime = "";
  if (tramite.fechaLiberacion) {
    const metricaOnTime = getDateDifference(
      tramite.fechaLiberacionObjetivo,
      tramite.fechaLiberacion
    );
    liberadosOnTime =
      !tramite.fechaLiberacionObjetivo || metricaOnTime < 1 ? "On time" : "Out of time";
  }

  seguimiento.getCell(`A${row}`).value = `${linkBase}todos/${tramite.id}`;
  seguimiento.getCell(`B${row}`).value = tramite.pais.codigo;
  seguimiento.getCell(`C${row}`).value = bu;
  seguimiento.getCell(`D${row}`).value = categoria;
  seguimiento.getCell(`E${row}`).value = codigoFormula;
  seguimiento.getCell(`F${row}`).value = producto;
  seguimiento.getCell(`G${row}`).value = marca;
  seguimiento.getCell(`H${row}`).value = tramite.iniciativa?.nombre;
  seguimiento.getCell(`I${row}`).value = tramite.prioridad?.nombre;
  seguimiento.getCell(`J${row}`).value = SU;
  seguimiento.getCell(`K${row}`).value = fabricante;
  seguimiento.getCell(`L${row}`).value = presentaciones;
  seguimiento.getCell(`M${row}`).value = tipoNames[tramite.tipo];
  seguimiento.getCell(`N${row}`).value = fechaObjetivo;
  seguimiento.getCell(`O${row}`).value = estadoTramiteNames[tramite.estado];
  seguimiento.getCell(`P${row}`).value = estatusLiberacion;
  seguimiento.getCell(`Q${row}`).value = fechaLiberacion;
  seguimiento.getCell(`R${row}`).value = estatusRadicacion;
  seguimiento.getCell(`S${row}`).value = fechaSometimiento;
  seguimiento.getCell(`T${row}`).value = fechaFinalizacion;
  seguimiento.getCell(`U${row}`).value = concepto;
  seguimiento.getCell(`V${row}`).value = tiempoTotal;
  seguimiento.getCell(`W${row}`).value = tiempoInterno;
  seguimiento.getCell(`X${row}`).value = tiempoAutoridad;
  seguimiento.getCell(`Y${row}`).value = tramite.costosDirectos;
  seguimiento.getCell(`Z${row}`).value = tramite.costosIndirectos;
  seguimiento.getCell(`AA${row}`).value = costoTotal;
  seguimiento.getCell(`AB${row}`).value = objecion;
  seguimiento.getCell(`AC${row}`).value = cantidad;
  seguimiento.getCell(`AD${row}`).value = cantidadFavor;
  seguimiento.getCell(`AE${row}`).value = cantidadContra;
  seguimiento.getCell(`AF${row}`).value = aFavor;
  seguimiento.getCell(`AG${row}`).value = autoridad;
  seguimiento.getCell(`AH${row}`).value = fechaObjecion;
  seguimiento.getCell(`AI${row}`).value = fechaLimiteRespuesta;
  seguimiento.getCell(`AJ${row}`).value = descripcion;
  seguimiento.getCell(`AK${row}`).value = fechaCierre;
  seguimiento.getCell(`AL${row}`).value = asignableRA;
  seguimiento.getCell(`AM${row}`).value = tramite.comentarios;
  seguimiento.getCell(`AO${row}`).value = iniciado;
  seguimiento.getCell(`AP${row}`).value = aprobado;
  seguimiento.getCell(`AQ${row}`).value = concluido;
  seguimiento.getCell(`AR${row}`).value = rechazado;
  seguimiento.getCell(`AS${row}`).value = desistido;
  seguimiento.getCell(`AT${row}`).value = innovacion;
  seguimiento.getCell(`AU${row}`).value = mantenimiento;
  seguimiento.getCell(`AV${row}`).value = doc;
  seguimiento.getCell(`AW${row}`).value = publicidad;
  seguimiento.getCell(`AX${row}`).value = vigilancia;
  seguimiento.getCell(`AY${row}`).value = liberadosOnTime;
  seguimiento.getCell(`AZ${row}`).value = onTime;
  seguimiento.getCell(`BA${row}`).value = aprobadoOnTime;
};

const getAllTramites = async (): Promise<APIt.Tramite[]> => {
  let tramites: APIt.Tramite[] = [];

  let nextToken: string = null;

  do {
    console.log("Fetching batch");
    const conditions: APIt.ListTramiteSearchesQueryVariables = {};
    conditions.limit = 2000;
    conditions.nextToken = nextToken;
    conditions.filter = { and: [{ deleted: { eq: false } }] };

    // eslint-disable-next-line no-await-in-loop
    const result = (await API.graphql(
      graphqlOperation(listTramites, conditions)
    )) as GraphQLResult<APIt.ListTramitesQuery>;

    if (result.data) {
      const resultData: APIt.ListTramitesQuery = result.data;
      const page = resultData.listTramites.items as APIt.Tramite[];

      tramites = [...tramites, ...page];

      nextToken = resultData.listTramites.nextToken;
      console.log(`Fetched ${page.length}`);
      console.log(`Next token ${nextToken}`);
    } else {
      nextToken = null;
    }
  } while (nextToken);

  return tramites;
};

const exportTramitesKPIs = async () => {
  console.log("Geting template");
  const axiosResponse = await axios.get("/GCalc.xlsx", { responseType: "arraybuffer" });

  const wb = new ExcelJs.Workbook();

  console.log("Fetching tramites");
  const tramites: APIt.Tramite[] = await getAllTramites();

  wb.xlsx.load(axiosResponse.data).then((workbook) => {
    const seguimiento = wb.getWorksheet("Seguimiento");

    let row = 2;
    tramites.forEach((tramite) => {
      setTramiteRow(seguimiento, tramite, row);
      row += 1;
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Tramites.xlsx");
    });
  });
};

export { getAllTramites, exportTramitesKPIs };
