import { DashboardTramiteYearly } from "API";
import React from "react";
import LineChart from "components/tramites/Dashboard/LineChart";
import useData from "./useData";

interface Props {
  initialData: DashboardTramiteYearly[];
  loading: boolean;
}

function Registros(props: Props): JSX.Element {
  const { initialData, loading } = props;

  const { header, data, options } = useData(initialData);

  return <LineChart header={header} data={data} options={options} loading={loading} />;
}

export default React.memo(Registros);
