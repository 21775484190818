import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listAutoridads } from "services/Autoridad/queries";

const fetchAutoridadesList = async (): Promise<{ autoridads: APIt.Autoridad[] }> => {
  let autoridads: any[] = [];

  const conditions: APIt.ListAutoridadsQueryVariables = { limit: 20000 };

  const result = (await API.graphql(
    graphqlOperation(listAutoridads, conditions)
  )) as GraphQLResult<APIt.ListAutoridadsQuery>;

  if (result.data) {
    const resultData: APIt.ListAutoridadsQuery = result.data;
    autoridads = resultData.listAutoridads.items;
  }

  return { autoridads };
};

export default fetchAutoridadesList;
