import { Grid } from "@mui/material";
import * as APIt from "API";
import { UseFormReturn } from "react-hook-form";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FormulaYPresentacionCombo from "features/Common/FormulaYPresentacionCombo";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function PublicidadPicker(data: Props): JSX.Element {
  const { formMethods } = data;
  const {
    control,
    formState: { errors },
  } = formMethods;

  const {
    state: { suList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const paises = suList.filter((p) => userRol === "Global" || userPaises.includes(p.codigo));

  return (
    <Grid container spacing={3}>
      <FormulaYPresentacionCombo formMethods={formMethods} pickPresentacion />
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="País"
          name="pais"
          errors={errors.pais?.message}
          options={paises}
          control={control as any}
        />
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="Medio de Difusión"
          name="medioDifusion"
          errors={errors.medioDifusion?.message}
          options={[
            { id: APIt.MedioDifusion.grafico, nombre: "Materiales impresos" },
            { id: APIt.MedioDifusion.radio, nombre: "Radio" },
            { id: APIt.MedioDifusion.television, nombre: "TV" },
            { id: APIt.MedioDifusion.cine, nombre: "Cine" },
            { id: APIt.MedioDifusion.mediosDigitales, nombre: "Medios Digitales" },
          ]}
          control={control as any}
        />
      </Grid>
    </Grid>
  );
}

export default PublicidadPicker;
