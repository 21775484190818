import { Card, Icon } from "@mui/material";
import { FabricanteVO, RegistroPrincipioActivoVO } from "API";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDTypography from "components/md/MDTypography";
import FlagBuilder from "components/tramites/FlagBuilder";
import Avatar from "components/tramites/Avatar";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import NewFormulaPrincipio from "./NewFormulaPrincipio";

/* eslint-disable */
type SetValueCallback = (values: any) => void;
/* eslint-enable */

function FormulaPrincipiosNew(params: any): JSX.Element {
  const {
    formData: { fields, prepend, update, remove },
  } = params;

  const newPrincipioCallback: SetValueCallback = (values: any) => {
    prepend(values);
  };

  const {
    state: { userRol },
  } = useTramitesAppStateContext();

  const canEdit = userRol === "Global";

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Principios Activos</MDTypography>
      </MDBox>
      <MDBox m={2} height="50vh" overflow="auto">
        <MDBox
          component="li"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgColor="grey-100"
          borderRadius="lg"
          p={1}
          mb={1}
        >
          <MDBox width="40%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              Principio
            </MDBox>
          </MDBox>
          <MDBox width="40%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              Fabricantes API
            </MDBox>
          </MDBox>
          <MDBox width="10%" display="flex" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              Concentración
            </MDBox>
          </MDBox>
          {/* BOTONES */}
          <MDBox
            width="10%"
            display="flex"
            flexDirection="column"
            alignItems={{ xs: "flex-end", sm: "end" }}
          >
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-end", sm: "end" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <MDBox
                display="flex"
                alignItems="center"
                mt={{ xs: 2, sm: 0 }}
                ml={{ xs: -1.5, sm: 0 }}
              >
                {canEdit && (
                  <NewFormulaPrincipio
                    valueSetter={newPrincipioCallback}
                    edit={false}
                    editValues={{} as RegistroPrincipioActivoVO}
                    inUse={
                      fields?.map((entry: RegistroPrincipioActivoVO) => entry.principio.nombre) ||
                      []
                    }
                  />
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        {fields.map((item: RegistroPrincipioActivoVO, index: number): any => (
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor="grey-100"
            borderRadius="lg"
            p={1}
            mb={1}
            key={`su-${item.principio.id}`}
          >
            <MDBox width="40%" display="flex" flexDirection="column">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={2}
              >
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {item.principio.nombre}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="40%" display="flex" flexDirection="column">
              <MDBox mb={2}>
                {item.fabricantes?.map((fabricante: FabricanteVO) => (
                  <MDBox key={fabricante.id} display="flex" sx={{ mb: 1 }}>
                    <Avatar title={fabricante.pais.nombre} size="xs" sx={{ mr: 1 }}>
                      <FlagBuilder country={fabricante.pais.codigo} />
                    </Avatar>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      textTransform="capitalize"
                      sx={{ mt: 0.3 }}
                    >
                      {fabricante.nombre}
                    </MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            </MDBox>
            <MDBox width="10%" display="flex" flexDirection="column">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mb={2}
              >
                <MDTypography variant="button" fontWeight="medium">
                  {item.concentracion}
                </MDTypography>
              </MDBox>
            </MDBox>
            {/* BOTONES */}
            <MDBox
              width="10%"
              display="flex"
              flexDirection="column"
              alignItems={{ xs: "flex-end", sm: "end" }}
            >
              <MDBox mr={1}>
                {canEdit && (
                  <NewFormulaPrincipio
                    valueSetter={(updatedValues) => {
                      update(index, updatedValues);
                    }}
                    edit
                    editValues={fields[index]}
                    inUse={
                      fields
                        ?.filter(
                          (entry: RegistroPrincipioActivoVO) =>
                            entry.principio.nombre !== item.principio.nombre
                        )
                        .map((entry: any) => entry.principio.nombre) || []
                    }
                  />
                )}
                {canEdit && (
                  <MDButton
                    variant="text"
                    color="error"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <Icon>delete</Icon>&nbsp;Eliminar
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
    </Card>
  );
}
export default FormulaPrincipiosNew;
