import { DashboardTramiteDaily, DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import { dashboardColors, tipoNames } from "globalvars";

const useData = (initialData: DashboardTramiteDaily[]) => {
  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["Aprobados", "Rechazados", "Desistidos"];

  let header = "Por Tipo - Sin datos";

  if (initialData?.length > 0) {
    // ADD PAIS LABELS
    const labels: string[] = [];
    initialData.forEach((entry: DashboardTramiteDaily) => {
      const addTipoLabel = (tramite: DashboardTramiteVO) => {
        if (!tramite.tipo) return;
        if (labels.includes(tramite.tipo)) return;

        labels.push(tramite.tipo);
      };

      entry.efectividadAprobados?.forEach(addTipoLabel);
      entry.efectividadRechazados?.forEach(addTipoLabel);
      entry.efectividadDesistidos?.forEach(addTipoLabel);
    });

    // ADD DATASETS
    // -- Aprobados
    const tmpAprobadosData: any = {};
    const tmpRechazadosData: any = {};
    const tmpDesistidosData: any = {};
    initialData.forEach((entry: DashboardTramiteDaily) => {
      entry.efectividadAprobados?.forEach((tramite: DashboardTramiteVO) => {
        if (tramite.tipo && !tmpAprobadosData[tramite.tipo]) tmpAprobadosData[tramite.tipo] = 0;
        tmpAprobadosData[tramite.tipo] += 1;
      });
      entry.efectividadRechazados?.forEach((tramite: DashboardTramiteVO) => {
        if (tramite.tipo && !tmpRechazadosData[tramite.tipo]) tmpRechazadosData[tramite.tipo] = 0;
        tmpRechazadosData[tramite.tipo] += 1;
      });
      entry.efectividadDesistidos?.forEach((tramite: DashboardTramiteVO) => {
        if (tramite.tipo && !tmpDesistidosData[tramite.tipo]) tmpDesistidosData[tramite.tipo] = 0;
        tmpDesistidosData[tramite.tipo] += 1;
      });
    });

    const datasetAprobados = {
      label: "Aprobados",
      data: labels.map((tipo: string) => tmpAprobadosData[tipo] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetAprobados);

    const datasetRechazados = {
      label: "Rechazados",
      data: labels.map((tipo: string) => tmpRechazadosData[tipo] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetRechazados);

    const datasetDesistidos = {
      label: "Desistidos",
      data: labels.map((tipo: string) => tmpDesistidosData[tipo] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetDesistidos);

    data.labels = labels.map((tipo: string) => {
      const aprobados = tmpAprobadosData[tipo] || 0;
      const rechazados = tmpRechazadosData[tipo] || 0;
      const desistidos = tmpDesistidosData[tipo] || 0;

      const total = aprobados + rechazados + desistidos;
      let percentage = (aprobados / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${tipoNames[tipo as keyof typeof tipoNames]} (${percentage.toFixed(2)}%)`;
    });

    header = "Por Tipo";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
