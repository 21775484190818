import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";

const findNotificaciones = async (
  user: string,
  leido: string,
  limit: number,
  nextToken: string
): Promise<{
  page: APIt.Notificacion[];
  nextToken: string;
}> => {
  let notificaciones: APIt.Notificacion[] = [];

  const nextTokenQuery = nextToken ? `, nextToken: "${nextToken}"` : "";

  const query = /* GraphQL */ `
    query MyQuery {
      notificacionesByDestinatario(
        destinatario: "${user}"
        leidoCreatedAt: {beginsWith: {createdAt: "2", leido: "${leido}"}},
        sortDirection: DESC,
        limit: ${limit}${nextTokenQuery}
      ) {
        items {
          id
          tipo
          createdAt
          objectData {
            autoridad
            codigoFormula
            codigoTramite
            fechaLiberacionObjetivo
            fechaObjetivo
            fechaPresentacionObjetivo
            id
            inicioRenovacion
            numeroRegistro
            tipoCertificado
            tipoTramite
            vencimientoCertificado
            vencimientoSanitario
          }
        }
        nextToken
      }
    }
  `;

  const result = (await API.graphql(
    graphqlOperation(query)
  )) as GraphQLResult<APIt.NotificacionesByDestinatarioQuery>;

  let retToken = null;
  if (result.data) {
    const resultData: APIt.NotificacionesByDestinatarioQuery = result.data;
    notificaciones = resultData.notificacionesByDestinatario.items;
    retToken = resultData.notificacionesByDestinatario.nextToken;
  }

  return { page: notificaciones, nextToken: retToken };
};

const setAsReaded = (user: string, id: string) => {
  console.log(user, id);
};

export { findNotificaciones, setAsReaded };
