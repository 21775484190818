import MDBox from "components/md/MDBox";
import MDProgress from "components/md/MDProgress";

export interface Props {
  progress: number;
}

function DossierColoredProgress(params: Props): JSX.Element {
  const { progress } = params;
  let color: "success" | "warning" | "error" = "success";
  if (progress < 50) {
    color = "error";
  } else if (progress < 100) {
    color = "warning";
  }
  return (
    <MDBox mr={2} mt={-1}>
      <MDProgress variant="gradient" value={progress || 0} color={color} label />
    </MDBox>
  );
}

export default DossierColoredProgress;
