import Flags from "country-flag-icons/react/1x1";

interface Props {
  country: string;
}

function FlagBuilder({ country }: Props): JSX.Element {
  return (
    <>
      {country === "AC" && <Flags.AC />}
      {country === "AD" && <Flags.AD />}
      {country === "AE" && <Flags.AE />}
      {country === "AF" && <Flags.AF />}
      {country === "AG" && <Flags.AG />}
      {country === "AI" && <Flags.AI />}
      {country === "AL" && <Flags.AL />}
      {country === "AM" && <Flags.AM />}
      {country === "AO" && <Flags.AO />}
      {country === "AQ" && <Flags.AQ />}
      {country === "AR" && <Flags.AR />}
      {country === "AS" && <Flags.AS />}
      {country === "AT" && <Flags.AT />}
      {country === "AU" && <Flags.AU />}
      {country === "AW" && <Flags.AW />}
      {country === "AX" && <Flags.AX />}
      {country === "AZ" && <Flags.AZ />}
      {country === "BA" && <Flags.BA />}
      {country === "BB" && <Flags.BB />}
      {country === "BD" && <Flags.BD />}
      {country === "BE" && <Flags.BE />}
      {country === "BF" && <Flags.BF />}
      {country === "BG" && <Flags.BG />}
      {country === "BH" && <Flags.BH />}
      {country === "BI" && <Flags.BI />}
      {country === "BJ" && <Flags.BJ />}
      {country === "BL" && <Flags.BL />}
      {country === "BM" && <Flags.BM />}
      {country === "BN" && <Flags.BN />}
      {country === "BO" && <Flags.BO />}
      {country === "BQ" && <Flags.BQ />}
      {country === "BR" && <Flags.BR />}
      {country === "BS" && <Flags.BS />}
      {country === "BT" && <Flags.BT />}
      {country === "BV" && <Flags.BV />}
      {country === "BW" && <Flags.BW />}
      {country === "BY" && <Flags.BY />}
      {country === "BZ" && <Flags.BZ />}
      {country === "CA" && <Flags.CA />}
      {country === "CC" && <Flags.CC />}
      {country === "CD" && <Flags.CD />}
      {country === "CF" && <Flags.CF />}
      {country === "CG" && <Flags.CG />}
      {country === "CH" && <Flags.CH />}
      {country === "CI" && <Flags.CI />}
      {country === "CK" && <Flags.CK />}
      {country === "CL" && <Flags.CL />}
      {country === "CM" && <Flags.CM />}
      {country === "CN" && <Flags.CN />}
      {country === "CO" && <Flags.CO />}
      {country === "CR" && <Flags.CR />}
      {country === "CU" && <Flags.CU />}
      {country === "CV" && <Flags.CV />}
      {country === "CW" && <Flags.CW />}
      {country === "CX" && <Flags.CX />}
      {country === "CY" && <Flags.CY />}
      {country === "CZ" && <Flags.CZ />}
      {country === "DE" && <Flags.DE />}
      {country === "DJ" && <Flags.DJ />}
      {country === "DK" && <Flags.DK />}
      {country === "DM" && <Flags.DM />}
      {country === "DO" && <Flags.DO />}
      {country === "DZ" && <Flags.DZ />}
      {country === "EC" && <Flags.EC />}
      {country === "EE" && <Flags.EE />}
      {country === "EG" && <Flags.EG />}
      {country === "EH" && <Flags.EH />}
      {country === "ER" && <Flags.ER />}
      {country === "ES" && <Flags.ES />}
      {country === "ET" && <Flags.ET />}
      {country === "EU" && <Flags.EU />}
      {country === "FI" && <Flags.FI />}
      {country === "FJ" && <Flags.FJ />}
      {country === "FK" && <Flags.FK />}
      {country === "FM" && <Flags.FM />}
      {country === "FO" && <Flags.FO />}
      {country === "FR" && <Flags.FR />}
      {country === "GA" && <Flags.GA />}
      {country === "GB" && <Flags.GB />}
      {country === "GD" && <Flags.GD />}
      {country === "GE" && <Flags.GE />}
      {country === "GF" && <Flags.GF />}
      {country === "GG" && <Flags.GG />}
      {country === "GH" && <Flags.GH />}
      {country === "GI" && <Flags.GI />}
      {country === "GL" && <Flags.GL />}
      {country === "GM" && <Flags.GM />}
      {country === "GN" && <Flags.GN />}
      {country === "GP" && <Flags.GP />}
      {country === "GQ" && <Flags.GQ />}
      {country === "GR" && <Flags.GR />}
      {country === "GS" && <Flags.GS />}
      {country === "GT" && <Flags.GT />}
      {country === "GU" && <Flags.GU />}
      {country === "GW" && <Flags.GW />}
      {country === "GY" && <Flags.GY />}
      {country === "HK" && <Flags.HK />}
      {country === "HM" && <Flags.HM />}
      {country === "HN" && <Flags.HN />}
      {country === "HR" && <Flags.HR />}
      {country === "HT" && <Flags.HT />}
      {country === "HU" && <Flags.HU />}
      {country === "IC" && <Flags.IC />}
      {country === "ID" && <Flags.ID />}
      {country === "IE" && <Flags.IE />}
      {country === "IL" && <Flags.IL />}
      {country === "IM" && <Flags.IM />}
      {country === "IN" && <Flags.IN />}
      {country === "IO" && <Flags.IO />}
      {country === "IQ" && <Flags.IQ />}
      {country === "IR" && <Flags.IR />}
      {country === "IS" && <Flags.IS />}
      {country === "IT" && <Flags.IT />}
      {country === "JE" && <Flags.JE />}
      {country === "JM" && <Flags.JM />}
      {country === "JO" && <Flags.JO />}
      {country === "JP" && <Flags.JP />}
      {country === "KE" && <Flags.KE />}
      {country === "KG" && <Flags.KG />}
      {country === "KH" && <Flags.KH />}
      {country === "KI" && <Flags.KI />}
      {country === "KM" && <Flags.KM />}
      {country === "KN" && <Flags.KN />}
      {country === "KP" && <Flags.KP />}
      {country === "KR" && <Flags.KR />}
      {country === "KW" && <Flags.KW />}
      {country === "KY" && <Flags.KY />}
      {country === "KZ" && <Flags.KZ />}
      {country === "LA" && <Flags.LA />}
      {country === "LB" && <Flags.LB />}
      {country === "LC" && <Flags.LC />}
      {country === "LI" && <Flags.LI />}
      {country === "LK" && <Flags.LK />}
      {country === "LR" && <Flags.LR />}
      {country === "LS" && <Flags.LS />}
      {country === "LT" && <Flags.LT />}
      {country === "LU" && <Flags.LU />}
      {country === "LV" && <Flags.LV />}
      {country === "LY" && <Flags.LY />}
      {country === "MA" && <Flags.MA />}
      {country === "MC" && <Flags.MC />}
      {country === "MD" && <Flags.MD />}
      {country === "ME" && <Flags.ME />}
      {country === "MF" && <Flags.MF />}
      {country === "MG" && <Flags.MG />}
      {country === "MH" && <Flags.MH />}
      {country === "MK" && <Flags.MK />}
      {country === "ML" && <Flags.ML />}
      {country === "MM" && <Flags.MM />}
      {country === "MN" && <Flags.MN />}
      {country === "MO" && <Flags.MO />}
      {country === "MP" && <Flags.MP />}
      {country === "MQ" && <Flags.MQ />}
      {country === "MR" && <Flags.MR />}
      {country === "MS" && <Flags.MS />}
      {country === "MT" && <Flags.MT />}
      {country === "MU" && <Flags.MU />}
      {country === "MV" && <Flags.MV />}
      {country === "MW" && <Flags.MW />}
      {country === "MX" && <Flags.MX />}
      {country === "MY" && <Flags.MY />}
      {country === "MZ" && <Flags.MZ />}
      {country === "NA" && <Flags.NA />}
      {country === "NC" && <Flags.NC />}
      {country === "NE" && <Flags.NE />}
      {country === "NF" && <Flags.NF />}
      {country === "NG" && <Flags.NG />}
      {country === "NI" && <Flags.NI />}
      {country === "NL" && <Flags.NL />}
      {country === "NO" && <Flags.NO />}
      {country === "NP" && <Flags.NP />}
      {country === "NR" && <Flags.NR />}
      {country === "NU" && <Flags.NU />}
      {country === "NZ" && <Flags.NZ />}
      {country === "OM" && <Flags.OM />}
      {country === "PA" && <Flags.PA />}
      {country === "PE" && <Flags.PE />}
      {country === "PF" && <Flags.PF />}
      {country === "PG" && <Flags.PG />}
      {country === "PH" && <Flags.PH />}
      {country === "PK" && <Flags.PK />}
      {country === "PL" && <Flags.PL />}
      {country === "PM" && <Flags.PM />}
      {country === "PN" && <Flags.PN />}
      {country === "PR" && <Flags.PR />}
      {country === "PS" && <Flags.PS />}
      {country === "PT" && <Flags.PT />}
      {country === "PW" && <Flags.PW />}
      {country === "PY" && <Flags.PY />}
      {country === "QA" && <Flags.QA />}
      {country === "RE" && <Flags.RE />}
      {country === "RO" && <Flags.RO />}
      {country === "RS" && <Flags.RS />}
      {country === "RU" && <Flags.RU />}
      {country === "RW" && <Flags.RW />}
      {country === "SA" && <Flags.SA />}
      {country === "SB" && <Flags.SB />}
      {country === "SC" && <Flags.SC />}
      {country === "SD" && <Flags.SD />}
      {country === "SE" && <Flags.SE />}
      {country === "SG" && <Flags.SG />}
      {country === "SH" && <Flags.SH />}
      {country === "SI" && <Flags.SI />}
      {country === "SJ" && <Flags.SJ />}
      {country === "SK" && <Flags.SK />}
      {country === "SL" && <Flags.SL />}
      {country === "SM" && <Flags.SM />}
      {country === "SN" && <Flags.SN />}
      {country === "SO" && <Flags.SO />}
      {country === "SR" && <Flags.SR />}
      {country === "SS" && <Flags.SS />}
      {country === "ST" && <Flags.ST />}
      {country === "SV" && <Flags.SV />}
      {country === "SX" && <Flags.SX />}
      {country === "SY" && <Flags.SY />}
      {country === "SZ" && <Flags.SZ />}
      {country === "TA" && <Flags.TA />}
      {country === "TC" && <Flags.TC />}
      {country === "TD" && <Flags.TD />}
      {country === "TF" && <Flags.TF />}
      {country === "TG" && <Flags.TG />}
      {country === "TH" && <Flags.TH />}
      {country === "TJ" && <Flags.TJ />}
      {country === "TK" && <Flags.TK />}
      {country === "TL" && <Flags.TL />}
      {country === "TM" && <Flags.TM />}
      {country === "TN" && <Flags.TN />}
      {country === "TO" && <Flags.TO />}
      {country === "TR" && <Flags.TR />}
      {country === "TT" && <Flags.TT />}
      {country === "TV" && <Flags.TV />}
      {country === "TW" && <Flags.TW />}
      {country === "TZ" && <Flags.TZ />}
      {country === "UA" && <Flags.UA />}
      {country === "UG" && <Flags.UG />}
      {country === "UM" && <Flags.UM />}
      {country === "US" && <Flags.US />}
      {country === "UY" && <Flags.UY />}
      {country === "UZ" && <Flags.UZ />}
      {country === "VA" && <Flags.VA />}
      {country === "VC" && <Flags.VC />}
      {country === "VE" && <Flags.VE />}
      {country === "VG" && <Flags.VG />}
      {country === "VI" && <Flags.VI />}
      {country === "VN" && <Flags.VN />}
      {country === "VU" && <Flags.VU />}
      {country === "WF" && <Flags.WF />}
      {country === "WS" && <Flags.WS />}
      {country === "XK" && <Flags.XK />}
      {country === "YE" && <Flags.YE />}
      {country === "YT" && <Flags.YT />}
      {country === "ZA" && <Flags.ZA />}
      {country === "ZM" && <Flags.ZM />}
      {country === "ZW" && <Flags.ZW />}
    </>
  );
}

export default FlagBuilder;
