/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import Layout from "components/tramites/Layout";
import ComplexFormulaCard from "features/CodigoFormula/FormulaCards/ComplexFormulaCard";
// import DefaultFormulaCard from "features/CodigoFormula/FormulaCards/DefaultFormulaCard";

import Navbar from "components/tramites/Navbar";

import useCodigoFormulaList from "features/CodigoFormula/ServiceHooks/useCodigoFormulaList";
import { Backdrop, CircularProgress } from "@mui/material";
import SearchBar from "features/CodigoFormula/CodigoFormulaList/SearchBar";

function CodigoFormulaList(): JSX.Element {
  // ComplexFormulaCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const observer: any = useRef();
  const [codigosFromula, refresh, fetchNextPage, isLoading, hasMore, exportFormulas] =
    useCodigoFormulaList();

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event: any) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);

  // Dropdown menu template for the ComplexFormulaCard
  const renderMenu = (state: any, close: any) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Action</MenuItem>
      <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem>
    </Menu>
  );

  const renderFormulaCards = codigosFromula.map((codigoFromula, i) => {
    const isLastElement = codigosFromula.length === i + 1;
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Grid item xs={12} md={6} lg={6} xl={4} key={`${codigoFromula.id}-${i}`}>
        <MDBox mb={1.5} mt={1.5}>
          {isLastElement ? (
            <div ref={lastElementRef}>
              <ComplexFormulaCard
                formula={codigoFromula}
                su="US"
                dropdown={{
                  action: openSlackBotMenu,
                  menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                }}
              />
            </div>
          ) : (
            <ComplexFormulaCard
              formula={codigoFromula}
              su="US"
              dropdown={{
                action: openSlackBotMenu,
                menu: renderMenu(slackBotMenu, closeSlackBotMenu),
              }}
            />
          )}
        </MDBox>
      </Grid>
    );
  });

  return (
    <Layout>
      <Navbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Códigos de fórmula
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar refresh={refresh} exportFormulas={exportFormulas} />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            {codigosFromula && renderFormulaCards}
          </Grid>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default CodigoFormulaList;
