import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

export const getDataPercentage = (data: number[], value: number) => {
  const total = data.reduce((acc: number, curr: number) => acc + curr, 0);
  const percentage = (value / total) * 100;
  return Number.isNaN(percentage) ? 0 : percentage.toFixed(2);
};

export const getBasePieOptions = () => ({
  plugins: {
    tooltip: {
      callbacks: {
        label: (yDatapoint: any) => {
          const percentaje = getDataPercentage(yDatapoint.dataset.data, yDatapoint.raw);
          return `${yDatapoint.label}: ${yDatapoint.raw} (${percentaje}%)`;
        },
      },
    },
    datalabels: {
      display: true,
      align: "center",
      borderRadius: 3,
      font: {
        size: 18,
      },
      formatter: (value: any) => (value > 0 ? value : ""),
    },
    legend: {
      display: true,
      onClick: () => {},
    },
  },
});

export const getBaseBarOptions = (labels: string[]) => ({
  plugins: {
    tooltip: {
      callbacks: {
        mode: "label",
        label: (yDatapoint: any) => {
          if (yDatapoint.raw === 0) return "";
          const percentaje = getDataPercentage(yDatapoint.dataset.data, yDatapoint.raw);
          return `${labels[yDatapoint.datasetIndex]}: ${yDatapoint.raw} (${percentaje}%)`;
        },
      },
    },
    legend: {
      display: true,
      onClick: () => {},
    },
    datalabels: {
      display: true,
      formatter: (value: any) => (value > 0 ? value : ""),
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
});

export const getBaseSingleBarOptions = () => ({
  plugins: {
    tooltip: {
      callbacks: {
        mode: "label",
        label: (yDatapoint: any) => {
          if (yDatapoint.raw === 0) return "";
          const percentaje = getDataPercentage(yDatapoint.dataset.data, yDatapoint.raw);
          return `${yDatapoint.label}: ${yDatapoint.raw} (${percentaje}%)`;
        },
      },
    },
    legend: {
      display: true,
      onClick: () => {},
    },
    datalabels: {
      display: true,
      formatter: (value: any) => (value > 0 ? value : ""),
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
});
