import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import MDButton from "components/md/MDButton";
import FormField from "components/tramites/FormField";
import React from "react";
import * as Yup from "yup";
import * as APIt from "API";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* eslint-disable */
type ConfirmCallback = (e: any) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  onConfirm: ConfirmCallback;
};

export default function Desistir(params: Props) {
  const { onConfirm } = params;
  const [open, setOpen] = React.useState(false);

  const validations = Yup.object().shape({
    comentarios: Yup.string().required("Indique por favor un comentario").nullable(),
    fechaObtencion: Yup.string().required("Indique por favor la fecha de desistimiento").nullable(),
  });
  const {
    register,
    formState: { errors },
    control,
    trigger: triggerLocal,
    getValues: getLocalValues,
    reset,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const handleClickOpen = () => {
    setOpen(true);
    reset({
      comentarios: "",
      fechaObtencion: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const e = getLocalValues();
    triggerLocal().then((valid: any) => {
      if (!valid) return;

      onConfirm(e);
    });
  };

  return (
    <div>
      <MDButton variant="gradient" color="warning" onClick={handleClickOpen}>
        Desistir
      </MDButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Desistir el trámite</DialogTitle>
        <DialogContent>
          <DialogContentText>Por favor indique un comentario.</DialogContentText>
          <FormField
            label="Fecha de desistimiento"
            type="date"
            name="fechaObtencion"
            errors={errors.fechaObtencion?.message}
            register={register as any}
            control={control as any}
          />
          <Divider />
          <FormField
            label="Comentarios"
            type="text"
            name="comentarios"
            multiline
            rows={4}
            errors={errors.comentarios?.message}
            register={register}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
