import { DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import { dashboardColors, tipoNames } from "globalvars";

const useData = (initialData: any[]) => {
  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["On Time", "Vencidos", "En Curso", "Retrasados"];

  let header = "Por Tipo - Sin datos";

  if (initialData?.length > 0) {
    // ADD TIPO LABELS
    const labels: string[] = [];
    initialData.forEach((entry: any) => {
      const addTipoLabel = (tramite: DashboardTramiteVO) => {
        if (!tramite.tipo || labels.includes(tramite.tipo)) return;

        labels.push(tramite.tipo);
      };

      entry.sometimientosOnTime?.forEach(addTipoLabel);
      entry.sometimientosVencidos?.forEach(addTipoLabel);
      entry.sometimientosEnCurso?.forEach(addTipoLabel);
      entry.sometimientosRetrasados?.forEach(addTipoLabel);
    });

    // ADD DATASETS
    const tmpOnTimeData: any = {};
    const tmpVencidosData: any = {};
    const tmpEnCursoData: any = {};
    const tmpRetrasadosData: any = {};
    initialData.forEach((entry: any) => {
      entry.sometimientosOnTime?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpOnTimeData[tramite.tipo]) tmpOnTimeData[tramite.tipo] = 0;
        tmpOnTimeData[tramite.tipo] += 1;
      });
      entry.sometimientosVencidos?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpVencidosData[tramite.tipo]) tmpVencidosData[tramite.tipo] = 0;
        tmpVencidosData[tramite.tipo] += 1;
      });
      entry.sometimientosEnCurso?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpEnCursoData[tramite.tipo]) tmpEnCursoData[tramite.tipo] = 0;
        tmpEnCursoData[tramite.tipo] += 1;
      });
      entry.sometimientosRetrasados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpRetrasadosData[tramite.tipo]) tmpRetrasadosData[tramite.tipo] = 0;
        tmpRetrasadosData[tramite.tipo] += 1;
      });
    });

    const datasetOnTime = {
      label: "On Time",
      data: labels.map((tipo: string) => tmpOnTimeData[tipo] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetOnTime);

    const datasetVencidos = {
      label: "Vencidos",
      data: labels.map((tipo: string) => tmpVencidosData[tipo] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetVencidos);

    const datasetEnCurso = {
      label: "En Curso",
      data: labels.map((tipo: string) => tmpEnCursoData[tipo] || 0),
      backgroundColor: dashboardColors.yellow.background,
    };
    data.datasets.push(datasetEnCurso);

    const datasetRetrasados = {
      label: "Retrasados",
      data: labels.map((tipo: string) => tmpRetrasadosData[tipo] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetRetrasados);

    data.labels = labels.map((tipo: string) => {
      const onTime = tmpOnTimeData[tipo] || 0;
      const vencidos = tmpVencidosData[tipo] || 0;
      const retrasados = tmpRetrasadosData[tipo] || 0;

      const total = onTime + vencidos + retrasados;
      let percentage = (onTime / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${tipoNames[tipo as keyof typeof tipoNames]} (${percentage.toFixed(2)}%)`;
    });

    header = "Por Tipo";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
