export const getIniciativa = /* GraphQL */ `
  query GetIniciativa($id: ID!) {
    getIniciativa(id: $id) {
      id
      nombre
    }
  }
`;
export const listIniciativas = /* GraphQL */ `
  query ListIniciativas($filter: ModelIniciativaFilterInput, $limit: Int, $nextToken: String) {
    listIniciativas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
      }
      nextToken
    }
  }
`;
export const createIniciativaMutation = /* GraphQL */ `
  mutation CreateIniciativa(
    $input: CreateIniciativaInput!
    $condition: ModelIniciativaConditionInput
  ) {
    createIniciativa(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateIniciativaMutation = /* GraphQL */ `
  mutation UpdateIniciativa(
    $input: UpdateIniciativaInput!
    $condition: ModelIniciativaConditionInput
  ) {
    updateIniciativa(input: $input, condition: $condition) {
      id
    }
  }
`;
