import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";

const findUserConfig = async (
  user: string
): Promise<{
  user: APIt.UserConfig;
}> => {
  let users: APIt.UserConfig[] = [];

  const query = /* GraphQL */ `
    query MyQuery {
      userConfigByEmail(email: "${user}") {
        items {
          rol
          paises
        }
      }
    }
  `;

  const result = (await API.graphql(
    graphqlOperation(query)
  )) as GraphQLResult<APIt.UserConfigByEmailQuery>;

  if (result.data) {
    const resultData: APIt.UserConfigByEmailQuery = result.data;
    users = resultData.userConfigByEmail.items || [];
  }

  return { user: users[0] };
};

const setAsReaded = (user: string, id: string) => {
  console.log(user, id);
};

export { findUserConfig, setAsReaded };
