import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import Avatar from "components/tramites/Avatar";
import MDTypography from "components/md/MDTypography";
import FlagBuilder from "components/tramites/FlagBuilder";
import { estadoTramiteNames, tipoNames } from "globalvars";
import { Link } from "react-router-dom";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
};

export default function VerOtrosTramites(params: Props): JSX.Element {
  const { formulas, formMethods } = params;
  const { getValues } = formMethods;
  const { id } = getValues();

  return (
    <MDBox>
      {formulas?.map((formula: APIt.CodigoFormula) =>
        formula.tramites?.map(
          (tramite: APIt.FormulaTramiteVO) =>
            tramite.id !== id && (
              <MDBox key={tramite.id} p={2} pl={4} display="flex">
                <Avatar title={formula.categoria?.nombre} size="sm" sx={{ mr: 1 }} bgColor="info">
                  {formula.categoria?.codigo}
                </Avatar>
                <Link to={`/${tramite.tipo}/${tramite.id}`} target="_blank" color="white">
                  <MDTypography variant="label" fontWeight="medium" textTransform="capitalize">
                    {formula.codigo}
                  </MDTypography>
                </Link>
                <Avatar title={tramite.pais.nombre} size="xs" sx={{ mr: 1, ml: 2, mt: 0.5 }}>
                  <FlagBuilder country={tramite.pais.codigo} />
                </Avatar>
                <Link to={`/${tramite.tipo}/${tramite.id}`} target="_blank" color="white">
                  <MDTypography variant="label" fontWeight="medium" textTransform="capitalize">
                    {tipoNames[tramite.tipo]} - {estadoTramiteNames[tramite.estado]} (Dossier:{" "}
                    {tramite.dossierCompleteness || 0}%)
                  </MDTypography>
                </Link>
              </MDBox>
            )
        )
      )}
    </MDBox>
  );
}
