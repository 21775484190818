import { useFieldArray, UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import { Autocomplete, Box, Chip, Grid, TableRow, TextField } from "@mui/material";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import Avatar from "components/tramites/Avatar";
import FlagBuilder from "components/tramites/FlagBuilder";
import MDBox from "components/md/MDBox";
import CropTooltip from "components/tramites/CropTooltip/CropTooltip";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  codigoFormula: APIt.CodigoFormula;
  formula: APIt.RegistroFormulaVO;
  formulaIndex: number;
  registroPrincipio: APIt.RegistroPrincipioActivoVO;
  ppoIndex: number;
};

function DetalleFormulaPrincipio(params: Props): JSX.Element {
  const {
    formMethods: {
      control,
      getValues,
      formState: { errors },
    },
    codigoFormula,
    formula,
    formulaIndex,
    registroPrincipio,
    ppoIndex,
  } = params;
  const { estado, pais } = getValues();

  const { append: appendFabricante, remove: removeFabricante } = useFieldArray({
    control,
    name: `formulas.${formulaIndex}.principiosActivos.${ppoIndex}.fabricantes`,
    keyName: "arrayKey",
  });

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;

  return (
    <TableRow key={registroPrincipio.principio.id}>
      <DataTableBodyCell noBorder={formula.principiosActivos.length - 1 === ppoIndex} align="left">
        {registroPrincipio.principio.nombre}
      </DataTableBodyCell>
      <DataTableBodyCell noBorder={formula.principiosActivos.length - 1 === ppoIndex} align="left">
        {registroPrincipio.concentracion}
      </DataTableBodyCell>
      {["MX", "CL"].includes(pais.codigo) && (
        <MDBox component="td" px={3}>
          <MDBox sx={{ verticalAlign: "middle", mt: 1, mb: 1 }}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12}>
                <Autocomplete
                  id="fabricantes"
                  disabled={!canEdit}
                  getOptionLabel={(option) => (typeof option === "string" ? option : option.nombre)}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                    )
                  }
                  options={
                    codigoFormula?.principiosActivos?.find(
                      (registroPrincipioFormula: APIt.RegistroPrincipioActivoVO) =>
                        registroPrincipioFormula.principio.id === registroPrincipio.principio.id
                    )?.fabricantes || []
                  }
                  autoComplete
                  multiple
                  includeInputInList
                  filterSelectedOptions
                  value={registroPrincipio.fabricantes || []}
                  onChange={(event: any, newValue: any, selectOption: any, option: any) => {
                    if (option?.option?.id) appendFabricante(option.option);
                    else removeFabricante();
                  }}
                  isOptionEqualToValue={(option, value: any) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      onKeyDown={(event: any) => {
                        if (event.key === "Backspace") {
                          event.stopPropagation();
                        }
                      }}
                      error={
                        !!(
                          errors.formulas &&
                          errors.formulas[formulaIndex] &&
                          errors.formulas[formulaIndex].principiosActivos &&
                          errors.formulas[formulaIndex].principiosActivos[ppoIndex] &&
                          errors.formulas[formulaIndex].principiosActivos[ppoIndex].fabricantes
                            ?.message
                        )
                      }
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 2 }}>
                        {" "}
                        <FlagBuilder country={option.pais.codigo} />
                      </Avatar>
                      {option.nombre}
                    </Box>
                  )}
                  renderTags={(value: any, getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        avatar={
                          <Avatar title={option.pais.nombre} size="xxs">
                            {" "}
                            <FlagBuilder country={option.pais.codigo} />
                          </Avatar>
                        }
                        variant="outlined"
                        label={
                          <MDBox sx={{ ml: 1 }}>
                            <CropTooltip
                              truncate={40}
                              text={option.nombre || ""}
                              variant="label"
                              color="white"
                            />
                          </MDBox>
                        }
                        {...getTagProps({ index })}
                        onDelete={() => {
                          removeFabricante(index);
                        }}
                      />
                    ))
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </TableRow>
  );
}

export default DetalleFormulaPrincipio;
