import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { updateNotificacion } from "graphql/mutations";

const markNotificationsReaded = async (user: string, id: string): Promise<boolean> => {
  let someUnreaded = false;
  const query = /* GraphQL */ `
    query MyQuery {
      notificacionesByObjectId(
        objectId: "${id}"
        destinatario: { eq: "${user}" }
      ) {
        items {
          id
          leido
          createdAt
        }
      }
    }
  `;

  const result = (await API.graphql(
    graphqlOperation(query)
  )) as GraphQLResult<APIt.NotificacionesByObjectIdQuery>;

  if (result.data) {
    const resultData: APIt.NotificacionesByObjectIdQuery = result.data;
    const notificaciones = resultData.notificacionesByObjectId.items;
    notificaciones.forEach(async (noti) => {
      if (noti.leido === "N") {
        someUnreaded = true;

        (await API.graphql(
          graphqlOperation(updateNotificacion, {
            input: { id: noti.id, leido: "Y", createdAt: noti.createdAt },
          })
        )) as GraphQLResult<APIt.SaveRegistroMutation>;
      }
    });
  }

  return someUnreaded;
};

const setAsReaded = (user: string, id: string) => {
  console.log(user, id);
};

export { markNotificationsReaded, setAsReaded };
