import MDBox from "components/md/MDBox";
import Avatar from "components/tramites/Avatar";

import { Icon, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import * as APIt from "API";
import MDTypography from "components/md/MDTypography";
import FlagBuilder from "../FlagBuilder";

interface Props {
  registros?: APIt.FormulaRegistroVO[];
}

function RegistroBadge(params: Props): JSX.Element {
  const { registros } = params;

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  return (
    registros?.length > 0 && (
      <MDBox display="flex" flexDirection="row" lineHeight={0} ml={0.1}>
        <NoMaxWidthTooltip
          title={
            <MDBox>
              <MDBox>
                <MDTypography variant="label" color="white">
                  Registros sanitarios
                </MDTypography>
              </MDBox>
              {registros?.map((registro: APIt.FormulaRegistroVO) => (
                <MDBox key={registro.id} display="flex" padding={1}>
                  <Avatar title={registro.pais.nombre} size="xs" sx={{ mr: 1 }} bgColor="info">
                    <FlagBuilder country={registro.pais.codigo} />
                  </Avatar>
                  <MDBox>
                    <MDTypography variant="label" color="white">
                      {registro.numeroRegistro}
                    </MDTypography>
                  </MDBox>
                  <MDBox marginLeft={1}>
                    <MDTypography variant="label" color="white">
                      {registro.vencimientoSanitario}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          }
        >
          <Avatar alt={APIt.EstadoTramite.aprobado} size="xs" bgColor="dark">
            <Icon fontSize="medium">gavel</Icon>
          </Avatar>
        </NoMaxWidthTooltip>
      </MDBox>
    )
  );
}

// Declaring default props for RegistroBadge
RegistroBadge.defaultProps = {
  registros: [],
};

export default RegistroBadge;
