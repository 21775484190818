import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from "@mui/material";
import * as APIt from "API";
import * as DossierUtils from "services/Utils/DossierUtils";
import { parseISO, format, isValid } from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDBox from "components/md/MDBox";
import { UseFormReturn, useWatch } from "react-hook-form";
import DossierField from "./DossierField";
import DetailsTooltip from "./DetailsTooltip";
import DossierProgres from "./DossierProgress";
import DossierDetailsDlg from "./DossierDetailsDlg";
import NewDossierFieldDlg from "./NewDossierFieldDlg";

/* eslint-disable */
type UpdatePreferencesFunction = (preferences: DossierUtils.TramiteDefinitionEntry[]) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<any, any>;
  formMethodsPrefix: string;
  dossierGroup: DossierUtils.TramiteDefinitionEntry;
  preferences: DossierUtils.TramiteDefinitionEntry[];
  updatePreferences: UpdatePreferencesFunction;
  newFieldOptions?: DossierUtils.TramiteDefinitionEntry[];
  root?: boolean;
  rootTitle?: string;
  canEdit?: boolean;
};

function DossierGroupAccordion(params: Props): JSX.Element {
  const {
    formMethods,
    dossierGroup,
    preferences,
    updatePreferences,
    root,
    rootTitle,
    newFieldOptions,
    formMethodsPrefix,
    canEdit,
  } = params;
  const [expanded, setExpanded] = useState(false);

  const { control } = formMethods;

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const watchDossierData = useWatch({ control, name: `${formMethodsPrefix}dossierData` });

  const fieldChilds: DossierUtils.TramiteDefinitionEntry[] =
    DossierUtils.getGroupImmediateFieldChilds(preferences, dossierGroup);

  const groupChilds: DossierUtils.TramiteDefinitionEntry[] =
    DossierUtils.getGroupImmediateGroupChilds(preferences, dossierGroup, true);

  if ((!fieldChilds || fieldChilds.length < 1) && (!groupChilds || groupChilds.length < 1) && !root)
    return null;

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            onClick={handleExpand}
            sx={{
              pointerEvents: "auto",
            }}
          />
        }
        sx={{
          pointerEvents: "none",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={8}>
            <MDBox>
              <Grid container direction="row">
                <Grid
                  item
                  onClick={handleExpand}
                  sx={{
                    pointerEvents: "auto",
                  }}
                >
                  <DetailsTooltip
                    note={dossierGroup?.details?.note}
                    date={
                      dossierGroup?.details?.date &&
                      isValid(parseISO(dossierGroup?.details?.date)) &&
                      format(parseISO(dossierGroup?.details?.date), "dd/MM/yyyy")
                    }
                    enabled={
                      dossierGroup?.details &&
                      // TODO: dossierGroup.doneFields < dossierGroup.totalFields &&
                      !!(dossierGroup?.details?.note || dossierGroup?.details?.date)
                    }
                    variant="h5"
                    text={rootTitle || dossierGroup.name}
                    truncate={40}
                  />
                </Grid>
                {root && canEdit && (
                  <Grid
                    item
                    mt={-0.5}
                    sx={{
                      pointerEvents: "auto",
                    }}
                  >
                    <NewDossierFieldDlg
                      preferences={preferences}
                      updatePreferences={updatePreferences}
                      newOptions={newFieldOptions}
                    />
                  </Grid>
                )}
                {canEdit && dossierGroup.showDetails && (
                  <Grid
                    item
                    mt={-0.5}
                    sx={{
                      pointerEvents: "auto",
                    }}
                  >
                    <DossierDetailsDlg
                      field={dossierGroup}
                      preferences={preferences}
                      updatePreferences={updatePreferences}
                    />
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDBox
              onClick={handleExpand}
              sx={{
                pointerEvents: "auto",
              }}
            >
              <DossierProgres
                formMethods={formMethods}
                formMethodsPrefix={formMethodsPrefix}
                dossierGroup={dossierGroup}
                preferences={preferences}
              />
            </MDBox>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {/* CAMPOS EN EL GRUPO */}
        <Grid container spacing={1}>
          {fieldChilds
            ?.filter(
              (field: DossierUtils.TramiteDefinitionEntry) =>
                !!watchDossierData?.find((data: APIt.DossierField) => data?.codigo === field.code)
            )
            .map((field: DossierUtils.TramiteDefinitionEntry) => (
              <DossierField
                formMethods={formMethods}
                formMethodsPrefix={formMethodsPrefix}
                field={field}
                key={field.code}
                preferences={preferences}
                updatePreferences={updatePreferences}
                canEdit={canEdit}
              />
            ))}
        </Grid>
        <hr />
        {/* GRUPOS ANIDADOS */}
        {groupChilds?.map((childGroup: DossierUtils.TramiteDefinitionEntry) => (
          <Box key={childGroup.code} mt={1}>
            <DossierGroupAccordion
              formMethods={formMethods}
              formMethodsPrefix={formMethodsPrefix}
              preferences={preferences}
              dossierGroup={childGroup}
              updatePreferences={updatePreferences}
              canEdit={canEdit}
            />
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default DossierGroupAccordion;
