import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listPrincipioActivos } from "services/PrincipioActivo/queries";

const fetchPrincipiosActivosList = async (): Promise<{
  principiosActivos: APIt.PrincipioActivo[];
}> => {
  let principiosActivos: APIt.PrincipioActivo[] = [];

  let nextToken = null;

  do {
    const conditions: APIt.ListPrincipioActivosQueryVariables = {
      filter: { historico: { eq: false } },
      limit: 1000,
      nextToken,
    };
    /* eslint-disable */
    const result = (await API.graphql(
      graphqlOperation(listPrincipioActivos, conditions)
    )) as GraphQLResult<APIt.ListPrincipioActivosQuery>;
    /* eslint-enable */
    if (result.data) {
      const resultData: APIt.ListPrincipioActivosQuery = result.data;
      nextToken = resultData.listPrincipioActivos.nextToken;
      principiosActivos = [...principiosActivos, ...resultData.listPrincipioActivos.items];
    }
  } while (nextToken !== null);

  return { principiosActivos };
};

export default fetchPrincipiosActivosList;
