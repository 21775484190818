import { CategoriaTerapeutica } from "API";
import { useState, useEffect } from "react";

import fetchCategoriaTerapeuticaesList from "services/CategoriaTerapeutica/CategoriaTerapeuticaList";

const useCategoriaTerapeuticaOptions = (): [CategoriaTerapeutica[], () => void] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchCategoriaTerapeuticaesList().then((result) => {
        const { categoriaTerapeuticas } = result;
        setItems(categoriaTerapeuticas);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useCategoriaTerapeuticaOptions;
