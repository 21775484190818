import { Card } from "@mui/material";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import DetallePresentacion from "./DetallePresentacion";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
};

function DetallePresentaciones(params: Props): JSX.Element {
  const { formMethods, formulas } = params;
  const { control, getValues } = formMethods;

  const { estado, pais } = getValues();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "presentaciones",
    keyName: "arrayKey",
  });

  // DEFAULT PRESENTACIÓN TO BE ADDED
  const primerFormula = formulas?.at(0);
  const fabricantesAPI: APIt.FabricanteVO[] = [];
  const usedFabricanteIds: string[] = [];
  primerFormula?.principiosActivos?.forEach((principio: APIt.RegistroPrincipioActivoVO) => {
    principio.fabricantes?.forEach((fabricante: APIt.FabricanteVO) => {
      if (!usedFabricanteIds.includes(fabricante.id)) {
        usedFabricanteIds.push(fabricante.id);
        fabricantesAPI.push(fabricante);
      }
    });
  });
  const defaultPresentacion: APIt.RegistroPresentacionesVO = {
    formulas: [primerFormula?.codigo],
    producto: primerFormula?.producto,
    presentaciones: primerFormula?.presentaciones,
    fabricantes: primerFormula?.fabricantes.map(
      (fabricante: APIt.FabricanteVO) =>
        ({
          fabricante,
          acondicionadoresPrimarios: [
            {
              acondicionadorPrimario: fabricante,
              estatusVenta: APIt.EstatusVenta.nocomercializado,
              estabilidad: false,
            },
          ],
          maquiladores: [fabricante],
          fabricantesAPI,
        } as APIt.RegistroFabricanteVO)
    ),
  } as APIt.RegistroPresentacionesVO;

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;

  return (
    <MDBox px={3}>
      {fields?.map((registroPresentacion: APIt.RegistroPresentacionesVO, index: number) => (
        <Card
          sx={{ pb: 1, mb: 1, backgroundColor: "HoneyDew" }}
          key={`${registroPresentacion.producto}.${index + 1}.${
            registroPresentacion.presentaciones
          }`}
        >
          <DetallePresentacion
            formMethods={formMethods}
            formulas={formulas}
            registroPresentacion={registroPresentacion}
            index={index}
            remove={remove}
          />
        </Card>
      ))}
      {canEdit && (
        <MDBox display="flex" flexDirection="row-reverse">
          <MDButton
            variant="outlined"
            color="info"
            size="small"
            sx={{ mt: 2 }}
            onClick={() => {
              append(defaultPresentacion);
            }}
          >
            Nueva presentación
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default DetallePresentaciones;
