// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import Layout from "components/tramites/Layout";
// import DefaultFormulaCard from "features/Tramite/FormulaCards/DefaultFormulaCard";

import Navbar from "components/tramites/Navbar";
import Card from "@mui/material/Card";

import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import useTramiteList from "features/Tramite/ServiceHooks/useTramiteList";
import { useCallback, useRef } from "react";
import { TipoTramite } from "API";
import SearchBar from "features/Tramite/TramiteList/SearchBar";
import Avatar from "components/tramites/Avatar";
import FlagBuilder from "components/tramites/FlagBuilder";
import AccessTime from "@mui/icons-material/AccessTime";
import PendingActions from "@mui/icons-material/PendingActions";
import WarningAmber from "@mui/icons-material/WarningAmber";
import ThumbUpOffAlt from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAlt from "@mui/icons-material/ThumbDownOffAlt";
import Backspace from "@mui/icons-material/Backspace";
import AccessAlarm from "@mui/icons-material/AccessAlarm";
import Done from "@mui/icons-material/Done";
import Approval from "@mui/icons-material/Approval";
// import MDProgress from "components/md/MDProgress";
import {
  /* clusterByPais, */ estadoTramiteColors,
  getFase,
  tipoNames,
  tipoTitles,
} from "globalvars";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import * as APIt from "API";
import MDButton from "components/md/MDButton";
import { deleteTramite } from "services/Tramite/TramiteCRUD";
import { toast } from "react-toastify";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import Swal from "sweetalert2";
import CropTooltip from "components/tramites/CropTooltip/CropTooltip";

function TramiteList({ tipo }: { tipo: TipoTramite }): JSX.Element {
  const [tramites, refresh, fetchNextPage, isLoading, hasMore, setIsLoading, exportTramites] =
    useTramiteList(tipo);
  const observer: any = useRef();

  /* let registroLabel = [
    APIt.TipoTramite.vigilanciaSanitaria,
    APIt.TipoTramite.certificados,
    APIt.TipoTramite.publicidad,
  ].includes(tipo)
    ? "#TRÁMITE"
    : "#REGISTRO";
  if (!tipo) {
    registroLabel = "#REGISTRO/TRÁMITE";
  } */

  // CONTEXT (UPDATE LIST ON SAVE)
  const {
    dispatch,
    state: { userRol },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";

  const callDeleteTramite = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar el tramite?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#07bc0c",
      cancelButtonColor: "#e74c3c",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, borrar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deleteTramite(id)
          .then(() => {
            setIsLoading(false);
            dispatch({
              type: "delete-tramite-list-entry",
              payload: id,
            });
          })
          .catch((ex) => {
            setIsLoading(false);
            console.log(ex);
            toast.error("Se ha producido un error al borrar el trámite");
          });
      }
    });
  };

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && tramites.length >= 20) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const estadoIcons = {
    pendiente: (
      <Avatar size="xs" bgColor={estadoTramiteColors.pendiente} title="Pendiente">
        <AccessTime />
      </Avatar>
    ),
    liberado: (
      <Avatar size="xs" bgColor={estadoTramiteColors.liberado} title="Liberado">
        <PendingActions />
      </Avatar>
    ),
    presentado: (
      <Avatar size="xs" bgColor={estadoTramiteColors.presentado} title="Presentado">
        <Approval />
      </Avatar>
    ),
    objetado: (
      <Avatar size="xs" bgColor={estadoTramiteColors.objetado} title="Objetado">
        <WarningAmber />
      </Avatar>
    ),
    aprobado: (
      <Avatar size="xs" bgColor={estadoTramiteColors.aprobado} title="Aprobado">
        <ThumbUpOffAlt />
      </Avatar>
    ),
    rechazado: (
      <Avatar size="xs" bgColor={estadoTramiteColors.rechazado} title="Rechazado">
        <ThumbDownOffAlt />
      </Avatar>
    ),
    desistido: (
      <Avatar size="xs" bgColor={estadoTramiteColors.desistido} title="Desistido">
        <Backspace />
      </Avatar>
    ),
  };

  /* const getDossierColor = (progress: number) => {
    let color: "success" | "warning" | "error" = "success";
    if (progress < 50) {
      color = "error";
    } else if (progress < 100) {
      color = "warning";
    }
    return color;
  }; */

  const getFechaIcon = (objetivoStr: string, efectivaStr: string) => {
    let color: "success" | "transparent" | "error" = "transparent";
    /* if (!objetivoStr)
      return {
        color,
        title: (
          <MDBox>
            <MDTypography color="light" variant="caption">
              No ingresado
            </MDTypography>
          </MDBox>
        ),
      }; */

    const objetivo: Date = new Date(objetivoStr);
    const efectiva: Date = new Date(efectivaStr);
    const today: Date = new Date();

    let title: JSX.Element = (
      <MDBox>
        <MDTypography color="light" variant="caption">
          {objetivoStr ? `Objetivo: ${objetivoStr}` : "Objetivo no indicado"}
        </MDTypography>
      </MDBox>
    );

    if (efectivaStr) {
      color = !objetivoStr || objetivo >= efectiva ? "success" : "error";
      title = (
        <MDBox>
          <MDBox>
            <MDTypography color="light" variant="caption">
              Objetivo: {objetivoStr || "No indicado"}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography color="light" variant="caption">
              Efectiva: {efectivaStr}
            </MDTypography>
          </MDBox>
        </MDBox>
      );
    } else {
      color = !objetivoStr || objetivo >= today ? "success" : "error";
    }

    return { color, title };
  };

  const getRows = useCallback(
    () =>
      tramites.map((tramite: APIt.Tramite, index: number) => {
        const { color: liberacionColor, title: liberacionTitle } = getFechaIcon(
          tramite.fechaLiberacionObjetivo,
          tramite.fechaLiberacion
        );
        const { color: presentacionColor, title: presentacionTitle } = getFechaIcon(
          tramite.fechaPresentacionObjetivo,
          tramite.fechaPresentacion
        );
        let { color: registroColor, title: registroTitle } = getFechaIcon(
          tramite.fechaObjetivo,
          tramite.fechaObtencion
        );
        const liberacionPendiente = !tramite.fechaLiberacion;
        const presentacionPendiente = !tramite.fechaPresentacion;
        let registroPendiente = !tramite.fechaObtencion;

        if (tramite.tipo === TipoTramite.creg) {
          registroPendiente = false;
          registroColor = "success";
          registroTitle = (
            <MDBox>
              <MDTypography color="light" variant="caption">
                Registro histórico
              </MDTypography>
            </MDBox>
          );
        }

        const isLastElement = index === tramites.length + 1;

        const codigoFormula = tramite.formulas?.reduce(
          (acumulator: string, curr: APIt.RegistroFormulaVO, index: number) =>
            `${acumulator}${index === 0 ? "" : ", "}${curr.codigo}`,

          ""
        );

        const businessUnit = tramite.formulas?.reduce(
          (acumulator: string, curr: APIt.RegistroFormulaVO, index: number) =>
            acumulator.includes(curr.bu.nombre)
              ? acumulator
              : `${acumulator}${index === 0 ? "" : ", "}${curr.bu.nombre}`,

          ""
        );

        const marca = tramite.formulas?.reduce(
          (acumulator: string, curr: APIt.RegistroFormulaVO, index: number) =>
            acumulator.includes(curr.marca)
              ? acumulator
              : `${acumulator}${index === 0 ? "" : ", "}${curr.marca}`,

          ""
        );

        const productos = tramite.presentaciones?.reduce(
          (acumulator: string, curr: APIt.RegistroPresentacionesVO, index: number) =>
            acumulator.includes(curr.producto)
              ? acumulator
              : `${acumulator}${index === 0 ? "" : ", "}${curr.producto}`,
          ""
        );

        /* const sourcingUnitsCheck: string[] = [];
        const sourcingUnits: APIt.PaisVO[] = [];
        if (tramite.tipo === APIt.TipoTramite.certificados) {
          if (tramite.paisReceptor) sourcingUnits.push(tramite.paisReceptor);
        } else {
          tramite.presentaciones?.forEach((prestentacion: APIt.RegistroPresentacionesVO) => {
            prestentacion.fabricantes?.forEach((fabricante: APIt.RegistroFabricanteVO) => {
              if (!sourcingUnitsCheck.includes(fabricante.fabricante.pais.codigo)) {
                sourcingUnitsCheck.push(fabricante.fabricante.pais.codigo);
                sourcingUnits.push(fabricante.fabricante.pais);
              }
            });
          });
        } */

        const categorias: APIt.CategoriaVO[] = [];
        const categoriasCheck: string[] = [];
        tramite.formulas?.forEach((value: APIt.RegistroFormulaVO) => {
          if (!categoriasCheck.includes(value.categoria?.codigo)) {
            categoriasCheck.push(value.categoria?.codigo);
            categorias.push(value.categoria);
          }
        });

        return {
          delete: (
            <MDBox>
              {isUserGlobal && (
                <MDButton
                  variant="text"
                  color="error"
                  onClick={() => callDeleteTramite(tramite.id)}
                >
                  Borrar
                </MDButton>
              )}
            </MDBox>
          ),
          id: tramite.id,
          formula: (
            <MDBox mt={-1.2}>
              <MDBox display="flex" alignItems="center" style={{ marginLeft: "-33px" }}>
                <MDBox lineHeight={0}>
                  <MDBox display="flex">
                    <Avatar
                      size="xs"
                      sx={({ borders: { borderWidth }, palette: { white } }) => ({
                        border: `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                          mr: 1,
                        },

                        "&:hover, &:focus": {
                          zIndex: "10",
                        },
                      })}
                      title={tramite.pais?.nombre}
                    >
                      <FlagBuilder country={tramite.pais?.codigo} />
                    </Avatar>
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDBox ml={4} lineHeight={0} mt={-1.5} style={{ marginLeft: "-9px" }}>
                {isLastElement && <div ref={lastElementRef} />}
                <Link to={tramite.id} key={tramite.id}>
                  <CropTooltip truncate={22} text={codigoFormula} variant="label" color="inherit" />
                </Link>
              </MDBox>
            </MDBox>
          ),
          categoria: (
            <MDBox
              display="flex"
              alignItems="center"
              style={{ marginRight: "-35px", marginLeft: "-20px" }}
            >
              <MDBox mr={1}>
                <Link to={`${tramite.id}`}>
                  <MDBox>{estadoIcons[tramite.estado]}</MDBox>
                </Link>
              </MDBox>
              {/* {categorias?.map((categoria: APIt.CategoriaVO) => (
                <MDBox ml={1} key={categoria?.codigo}>
                  <Avatar
                    size="xs"
                    bgColor="info"
                    }
                  >
                    {categoria?.codigo}
                  </Avatar>
                </MDBox>
              ))} */}
            </MDBox>
          ),
          registro: (
            <MDBox display="flex" alignItems="center" style={{ marginLeft: "-36px" }}>
              <Tooltip title={registroTitle}>
                <Avatar size="xs" bgColor={registroColor}>
                  {registroPendiente && <AccessAlarm />}
                  {!registroPendiente && <Done />}
                </Avatar>
              </Tooltip>
            </MDBox>
          ),
          liberacion: (
            <MDBox display="flex" alignItems="center" style={{ marginRight: "-20px" }}>
              <Tooltip title={liberacionTitle}>
                <Avatar size="xs" bgColor={liberacionColor}>
                  {liberacionPendiente && <AccessAlarm />}
                  {!liberacionPendiente && <Done />}
                </Avatar>
              </Tooltip>
            </MDBox>
          ),
          presentacion: (
            <MDBox
              display="flex"
              alignItems="center"
              style={{ marginLeft: "-20px", marginRight: "-20px" }}
            >
              <Tooltip title={presentacionTitle}>
                <Avatar size="xs" bgColor={presentacionColor}>
                  {presentacionPendiente && <AccessAlarm />}
                  {!presentacionPendiente && <Done />}
                </Avatar>
              </Tooltip>
            </MDBox>
          ),
          /* dossier: (
            <MDBox width={100} title={`${tramite.dossierCompleteness}%`}>
              <MDProgress
                variant="gradient"
                value={tramite.dossierCompleteness || 0}
                color={getDossierColor(tramite.dossierCompleteness)}
              />
            </MDBox>
          ), */
          /* sourcingUnits: (
            <MDBox display="flex" alignItems="center">
              <MDBox lineHeight={0}>
                <MDBox display="flex">
                  {sourcingUnits.map((pais: APIt.PaisVO) => (
                    <Avatar
                      size="xs"
                      sx={({ borders: { borderWidth }, palette: { white } }) => ({
                        border: `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                          mr: 1,
                        },

                        "&:hover, &:focus": {
                          zIndex: "10",
                        },
                      })}
                      title={pais.nombre}
                      key={pais.codigo}
                    >
                      <FlagBuilder country={pais.codigo} />
                    </Avatar>
                  ))}
                </MDBox>
              </MDBox>
            </MDBox>
          ), */
          marca: <CropTooltip truncate={15} text={marca} variant="label" color="inherit" />,
          productos: <CropTooltip truncate={15} text={productos} variant="label" color="inherit" />,
          bu: businessUnit,
          tipo: tipoNames[tramite.tipo],
          etiqueta: (
            <CropTooltip
              truncate={15}
              text={tramite.etiqueta || ""}
              variant="label"
              color="inherit"
            />
          ),
          codigoCPT: tramite.codigoCPT,
          resumenTramite: getFase(tramite),
          // cluster: clusterByPais[tramite.pais?.codigo as keyof typeof clusterByPais],
          // numeroRegistroTramite: tramite.numeroRegistro,
        };
      }),
    [tramites]
  );

  const deleteHeader = { Header: "", accessor: "delete", width: "1%" };
  const categoriaHeader = { Header: "", accessor: "categoria", width: "1%" };
  const formulaHeader = { Header: "# Formula(s)", accessor: "formula", width: "10%" };
  const tipoHeader = { Header: "Tipo", accessor: "tipo", width: "5%" };
  const etiquetaHeader = { Header: "Etiqueta", accessor: "etiqueta", width: "1%" };
  const identificadorHeader = { Header: "Identificador", accessor: "codigoCPT", width: "1%" };
  const resumenHeader = { Header: "Fase", accessor: "resumenTramite", width: "1%" };
  const buHeader = { Header: "B.U.", accessor: "bu", width: "10%" };
  const marcaHeader = { Header: "Marca", accessor: "marca", width: "24%" };
  const productosHeader = { Header: "Productos", accessor: "productos", width: "24%" };
  /* const sourcingUnits = {
    Header: tipo === APIt.TipoTramite.certificados ? "País receptor" : "Sourcing",
    accessor: "sourcingUnits",
    width: "1%",
  }; */
  /*  const numeroRegistroHeader = {
    Header: registroLabel,
    accessor: "numeroRegistroTramite",
    width: "1%",
  }; */
  // const clusterHeader = { Header: "Cluster", accessor: "cluster", width: "1%" };
  // const productoHeader = { Header: "Producto", accessor: "producto", width: tipo ? "40%" : "35%" };
  const liberacionHeader = {
    Header: (
      <MDBox title="Liberación de dossier" textAlign="center" style={{ marginRight: "-20px" }}>
        L
      </MDBox>
    ),
    accessor: "liberacion",
    width: "1%",
  };
  const presentacionHeader = {
    Header: (
      <MDBox
        title="Presentación"
        textAlign="center"
        style={{ marginLeft: "-20px", marginRight: "-7px" }}
      >
        P
      </MDBox>
    ),
    accessor: "presentacion",
    width: "1%",
  };
  const registroHeader = {
    Header: (
      <MDBox title="Registro" textAlign="center" style={{ marginLeft: "-50px" }}>
        R
      </MDBox>
    ),
    accessor: "registro",
    width: "1%",
  };
  // const dossierHeader = { Header: "Dossier", accessor: "dossier", width: "10%" };

  const globalRows = isUserGlobal ? [deleteHeader] : [];

  const dataTableData = {
    columns: tipo
      ? [
          categoriaHeader,
          // sourcingUnits,
          formulaHeader,
          productosHeader,
          buHeader,
          marcaHeader,
          liberacionHeader,
          presentacionHeader,
          registroHeader,
          resumenHeader,
          // dossierHeader,
          identificadorHeader,
          etiquetaHeader,
          // clusterHeader,
          // numeroRegistroHeader,
          ...globalRows,
        ]
      : [
          categoriaHeader,
          // sourcingUnits,
          formulaHeader,
          tipoHeader,
          productosHeader,
          buHeader,
          marcaHeader,
          liberacionHeader,
          presentacionHeader,
          registroHeader,
          resumenHeader,
          // dossierHeader,
          identificadorHeader,
          etiquetaHeader,
          // clusterHeader,
          // numeroRegistroHeader,
          ...globalRows,
        ],
    rows: getRows(),
  };

  const getTittle = () => tipoTitles[tipo] || "Todos";

  return (
    <Layout>
      <Navbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              {getTittle()}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar refresh={refresh} tipo={tipo} exportTramites={exportTramites} />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Card>
            <TableContainer sx={{ boxShadow: "none" }}>
              <Table>
                <MDBox component="thead">
                  <TableRow>
                    {dataTableData.columns.map((column: any) => (
                      <DataTableHeadCell
                        width={column.width ? column.width : "auto"}
                        align="left"
                        sorted={false}
                        key={column.accessor}
                      >
                        {column.Header}
                      </DataTableHeadCell>
                    ))}
                  </TableRow>
                </MDBox>
                <TableBody>
                  {dataTableData.rows.map((row: any, index: any) => (
                    <TableRow
                      ref={index + 1 === tramites.length ? lastElementRef : null}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${row.id}${index}`}
                    >
                      {dataTableData.columns.map((cell: any) => (
                        <DataTableBodyCell
                          noBorder={dataTableData.rows.length - 1 === index}
                          align={cell.align ? cell.align : "left"}
                          key={cell.accessor}
                        >
                          {row[cell.accessor]}
                        </DataTableBodyCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default TramiteList;
