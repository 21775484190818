import { DashboardTramiteDaily } from "API";
import React from "react";
import DougnuthChart from "components/tramites/Dashboard/DougnuthChart";
import useData from "./useData";

interface Props {
  initialData: DashboardTramiteDaily[];
  loading: boolean;
  label: string;
  filterType: string;
  filterValue: string;
}

function PorIniciativa(props: Props): JSX.Element {
  const { initialData, loading, label, filterType, filterValue } = props;

  const { header, data, options } = useData(initialData, label, filterType, filterValue);

  return <DougnuthChart header={header} data={data} options={options} loading={loading} />;
}

export default React.memo(PorIniciativa);
