import { Registro } from "API";
import { useState, useEffect } from "react";
import { fetchCodigoFormula } from "services/CodigoFormula/CodigoFormulaCRUD";

import { fetchRegistro, updateRegistro } from "services/Registro/RegistroCRUD";

/* eslint-disable */
type comitChangesFn = (data: Registro, done: (result: any) => void) => void;
type doneCallback = (result: any) => void;
type setRegistroFn = (t: Registro) => void;
type fetchFormulaFn = (id: string, done: (result: any) => void) => void;
type findRegistro = (id: string) => Promise<Registro>;
type saveRegistro = (registro: Registro) => Promise<string>;
/* eslint-enable */

const useRegistroCRUD = (
  id: string
): {
  registro: Registro;
  setRegistro: setRegistroFn;
  commitChanges: comitChangesFn;
  fetchFormula: fetchFormulaFn;
  findRegistro: findRegistro;
  saveRegistro: saveRegistro;
} => {
  const [registro, setRegistro] = useState({} as Registro);

  // Get registro if ID was provided
  useEffect(() => {
    if (id) {
      fetchRegistro(id).then(
        (result) => {
          setRegistro(result);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [id]);

  const commitChanges = (registro: Registro, done: doneCallback) => {
    if (registro) {
      updateRegistro(registro).then((commitId) => {
        setRegistro({ ...registro, id: commitId });
        done({ ...registro, id: commitId });
        /* fetchRegistro(commitId).then(
          (result) => {
            setRegistro(result);
            done(result);
          },
          (error) => {
            console.log(error);
            done(error);
          }
        ); */
      });
    }
  };

  const fetchFormula = (id: string, done: doneCallback) => {
    fetchCodigoFormula(id).then((result: any) => {
      done(result);
    });
  };

  const findRegistro = (id: string) => {
    const registroPromise = fetchRegistro(id);
    return registroPromise;
  };

  const saveRegistro = (registro: Registro) => {
    const registroPromise = updateRegistro(registro);
    return registroPromise;
  };

  return { registro, setRegistro, commitChanges, fetchFormula, findRegistro, saveRegistro };
};

export default useRegistroCRUD;
