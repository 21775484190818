/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Avatar from "components/tramites/Avatar";

import FlagBuilder from "components/tramites/FlagBuilder";
import TramiteBadge from "components/tramites/TramiteBadge";

import * as APIt from "API";
import { Link } from "react-router-dom";
import MDProgress from "components/md/MDProgress";
import CropTooltip from "components/tramites/CropTooltip/CropTooltip";
import RegistroBadge from "components/tramites/RegistroBadge";

// Declaring prop types for the ComplexFormulaCard
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  formula: APIt.CodigoFormula;
  dropdown?: {
    action?: (...arg: any) => void;
    menu?: ReactNode;
  };
  [key: string]: any;
}

// Custom styles for ComplexFormulaCard
function ComplexFormulaCard(params: Props): JSX.Element {
  const { formula } = params;
  const {
    tramites,
    registros,
    fabricantes,
    id,
    codigo,
    dossierCompleteness,
    categoria,
    marca,
    producto,
    principiosActivos,
  } = formula;

  const countryAdded: string[] = [];
  const susAdded: string[] = [];
  const paisesDestino: APIt.PaisVO[] = [];
  const sourcingUnits: APIt.PaisVO[] = [];

  fabricantes?.forEach((fabricante: APIt.FabricanteVO) => {
    if (!susAdded.includes(fabricante.pais.codigo)) {
      susAdded.push(fabricante.pais.codigo);
      sourcingUnits.push(fabricante.pais);
    }
  });

  tramites?.forEach((tramite: APIt.FormulaTramiteVO) => {
    if (!countryAdded.includes(tramite.pais.codigo)) {
      countryAdded.push(tramite.pais.codigo);
      paisesDestino.push(tramite.pais);
    }
  });

  const renderCountries =
    paisesDestino.length > 0 &&
    paisesDestino.map((country: APIt.PaisVO) => {
      const countryKey = `country-${country.codigo}`;

      return (
        <Avatar
          key={countryKey}
          size="xs"
          sx={({ borders: { borderWidth }, palette: { white } }) => ({
            border: `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          })}
          title={country.nombre}
        >
          <FlagBuilder country={country.codigo} />
        </Avatar>
      );
    });

  const renderSUs =
    sourcingUnits.length > 0 &&
    sourcingUnits.map((country: APIt.PaisVO) => {
      const countryKey = `country-${country.codigo}`;

      return (
        <Avatar
          key={countryKey}
          size="xs"
          sx={({ borders: { borderWidth }, palette: { white } }) => ({
            border: `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          })}
          title={country.nombre}
        >
          <FlagBuilder country={country.codigo} />
        </Avatar>
      );
    });

  const getDossierColor = (progress: number) => {
    let color: "success" | "warning" | "error" = "success";
    if (progress < 50) {
      color = "error";
    } else if (progress < 100) {
      color = "warning";
    }
    return color;
  };

  let ingredientes = "";
  principiosActivos?.forEach((principio: APIt.RegistroPrincipioActivoVO) => {
    ingredientes = `${ingredientes}${principio.principio.nombre}, `;
  });

  return (
    <Card>
      <MDBox p={2}>
        {/** CODIGO E ICONOS */}
        <MDBox display="flex" alignItems="center">
          <MDBox ml={1} mt={-1}>
            <MDBox lineHeight={0} mr={0.25} ml={-1} mb={3}>
              <Avatar size="md" bgColor="info">
                {categoria?.codigo}
              </Avatar>
              {sourcingUnits.length > 0 && (
                <MDBox display="flex" mt={-1}>
                  {renderSUs}
                </MDBox>
              )}
            </MDBox>
          </MDBox>
          <MDBox ml={2} mt={-5} lineHeight={0} width="100%">
            <Link to={`${id}`}>
              <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {codigo}
              </MDTypography>
            </Link>
            <MDBox ml={0} mt={-0.5} lineHeight={0}>
              {paisesDestino.length > 0 && <MDBox display="flex">{renderCountries}</MDBox>}
            </MDBox>
          </MDBox>
          <MDBox ml={2} mt={-8.2}>
            <MDBox width={100} title={`${dossierCompleteness || 0}%`}>
              <MDProgress
                variant="gradient"
                value={dossierCompleteness || 0}
                color={getDossierColor(dossierCompleteness || 0)}
                label
              />
            </MDBox>
          </MDBox>
        </MDBox>
        {/** MARCA Y PRODUCTO */}
        <MDBox ml={-2} mt={-1} lineHeight={0}>
          <Link to={`${id}`}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" ml={3}>
              {marca} - {producto}
            </MDTypography>
          </Link>
        </MDBox>
        <Divider />
        {/** PRINCIPIOS Y TRÁMITES */}
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" flexDirection="column" lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              <CropTooltip truncate={50} text={ingredientes} variant="label" />
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="row" lineHeight={0}>
            <TramiteBadge tramites={tramites} estadoEsperado={APIt.EstadoTramite.pendiente} />
            <TramiteBadge tramites={tramites} estadoEsperado={APIt.EstadoTramite.liberado} />
            <TramiteBadge tramites={tramites} estadoEsperado={APIt.EstadoTramite.presentado} />
            <TramiteBadge tramites={tramites} estadoEsperado={APIt.EstadoTramite.objetado} />
            {/* <TramiteBadge tramites={tramites} estadoEsperado={APIt.EstadoTramite.aprobado} /> */}
            <TramiteBadge tramites={tramites} estadoEsperado={APIt.EstadoTramite.rechazado} />
            <RegistroBadge registros={registros} />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Declaring default props for ComplexFormulaCard
ComplexFormulaCard.defaultProps = {
  color: "dark",
  dropdown: false,
};

export default ComplexFormulaCard;
