import MDBox from "components/md/MDBox";
import { UseFormReturn, useWatch } from "react-hook-form";
import * as DossierUtils from "services/Utils/DossierUtils";
import * as APIt from "API";
import DossierColoredProgress from "./DossierColoredProgress";

export interface Props {
  formMethods: UseFormReturn<any, any>;
  formMethodsPrefix: string;
  dossierGroup: DossierUtils.TramiteDefinitionEntry;
  preferences: DossierUtils.TramiteDefinitionEntry[];
}

function DossierProgres(params: Props): JSX.Element {
  const { formMethods, dossierGroup, preferences, formMethodsPrefix } = params;
  const { control } = formMethods;

  let dossierData: APIt.DossierField[] = null;
  dossierData = useWatch({ control, name: `${formMethodsPrefix}dossierData` });

  const fieldChilds: DossierUtils.TramiteDefinitionEntry[] = DossierUtils.getGroupFullFieldChilds(
    preferences,
    dossierGroup
  );

  const done = fieldChilds?.reduce(
    (acc: number, value: DossierUtils.TramiteDefinitionEntry) =>
      acc + (dossierData?.find((e: APIt.DossierField) => e.codigo === value.code)?.done ? 1 : 0),
    0
  );

  const progress = fieldChilds?.length
    ? parseFloat(((done / (fieldChilds?.length || 1)) * 100).toFixed(2))
    : 0;

  return (
    <MDBox mr={2} mt={-1}>
      <DossierColoredProgress progress={progress} />
    </MDBox>
  );
}

export default DossierProgres;
