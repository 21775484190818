import MDBox from "components/md/MDBox";
import Avatar from "components/tramites/Avatar";

import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { estadoTramiteColors, estadoTramiteNames, tipoNames } from "globalvars";
import * as APIt from "API";
import MDTypography from "components/md/MDTypography";
import FlagBuilder from "../FlagBuilder";

interface Props {
  tramites?: APIt.FormulaTramiteVO[];
  estadoEsperado: APIt.EstadoTramite;
}

function TramiteBadge(params: Props): JSX.Element {
  const { tramites, estadoEsperado } = params;

  const color: string = estadoTramiteColors[estadoEsperado];

  const filteredTramites = tramites?.filter(
    (tramite: APIt.FormulaTramiteVO) => tramite.estado === estadoEsperado
  );

  return (
    filteredTramites?.length > 0 && (
      <MDBox display="flex" flexDirection="row" lineHeight={0} ml={0.1}>
        <Tooltip
          title={
            <MDBox>
              <MDBox>
                <MDTypography variant="label" color="white">
                  {estadoTramiteNames[estadoEsperado]}
                </MDTypography>
              </MDBox>
              {filteredTramites?.map((tramite: APIt.FormulaTramiteVO) => (
                <MDBox key={tramite.id} display="flex" padding={1} width={400}>
                  <Avatar title={tramite.pais.nombre} size="xs" sx={{ mr: 1 }} bgColor="info">
                    <FlagBuilder country={tramite.pais.codigo} />
                  </Avatar>
                  <MDBox>
                    <Link to={`/${tramite.tipo}/${tramite.id}`} target="_blank" color="white">
                      <MDTypography variant="label" color="white">
                        {tipoNames[tramite.tipo]}
                      </MDTypography>
                    </Link>
                  </MDBox>
                  <MDBox marginLeft={1}>
                    <MDTypography variant="label" color="white">
                      {tramite.dossierCompleteness}%
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          }
        >
          <Avatar alt={estadoEsperado} size="xs" bgColor={color}>
            {filteredTramites.length}
          </Avatar>
        </Tooltip>
      </MDBox>
    )
  );
}

// Declaring default props for TramiteBadge
TramiteBadge.defaultProps = {
  tramites: [],
};

export default TramiteBadge;
