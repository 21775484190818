import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import {
  createFormaCosmeticaFarmaceuticaMutation,
  getFormaCosmeticaFarmaceutica,
} from "services/FormaCosmeticaFarmaceutica/queries";

const createFormaCosmeticaFarmaceutica = async (
  formaCosmeticaFarmaceutica: APIt.FormaCosmeticaFarmaceutica
): Promise<string> => {
  let id: string = "";

  const { nombre } = formaCosmeticaFarmaceutica;

  const formaCosmeticaFarmaceuticaInput: APIt.CreateFormaCosmeticaFarmaceuticaInput = {
    nombre,
  };

  const inputVariables: APIt.CreateFormaCosmeticaFarmaceuticaMutationVariables = {
    input: formaCosmeticaFarmaceuticaInput,
  };

  const createRequest = (await API.graphql(
    graphqlOperation(createFormaCosmeticaFarmaceuticaMutation, inputVariables)
  )) as GraphQLResult<APIt.CreateFormaCosmeticaFarmaceuticaMutation>;

  if (createRequest?.data?.createFormaCosmeticaFarmaceutica) {
    id = createRequest.data.createFormaCosmeticaFarmaceutica.id;
  }

  return id;
};

const fetchFormaCosmeticaFarmaceutica = async (
  id: string
): Promise<APIt.FormaCosmeticaFarmaceutica> => {
  let formaCosmeticaFarmaceutica: APIt.FormaCosmeticaFarmaceutica = null;
  const params: APIt.GetFormaCosmeticaFarmaceuticaQueryVariables = { id };

  const result = (await API.graphql({
    query: getFormaCosmeticaFarmaceutica,
    variables: params,
  })) as GraphQLResult<APIt.GetFormaCosmeticaFarmaceuticaQuery>;

  if (result.data) {
    const query: APIt.GetFormaCosmeticaFarmaceuticaQuery = result.data;
    if (query.getFormaCosmeticaFarmaceutica) {
      formaCosmeticaFarmaceutica = query.getFormaCosmeticaFarmaceutica;
    }
  }

  return formaCosmeticaFarmaceutica;
};

export { createFormaCosmeticaFarmaceutica, fetchFormaCosmeticaFarmaceutica };
