/* tslint:disable */
/* eslint-disable */

import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import * as APIt from "API";
import { amplifyMock, prepareForSearch, removeKey } from "globalvars";
import { fetchCodigoFormula } from "services/CodigoFormula/CodigoFormulaCRUD";
import { createRegistroCambio } from "services/RegistroCambio/RegistroCambioCRUD";

const createCodigoFormulaMutation = `
  mutation CreateCodigoFormula($input: CreateCodigoFormulaInput!) {
    createCodigoFormula(input: $input) {
      id
    }
  }
`;

const updateCodigoFormulaMutation = `
  mutation UpdateCodigoFormula($input: UpdateCodigoFormulaInput!) {
    updateCodigoFormula(input: $input) {
      id
    }
  }
`;
export const listCodigoFormulas = /* GraphQL */ `
  query ListCodigoFormulas(
    $filter: ModelCodigoFormulaSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCodigoFormulaSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        codigoFormulaId
        historico
        deleted
        searchBu
        searchCategoria
        searchCategoriaTerapeutica
        searchCodigo
        searchFabricantes
        searchFormaCosmeticaFarmaceutica
        searchMarca
        searchPaisesDestino
        searchPresentaciones
        searchPrincipiosActivos
        searchProducto
        searchSourcingUnits
      }
      nextToken
    }
  }
`;

export const searchCodigoFormulas = `
  query ListCodigoFormulas(
    $filter: SearchableCodigoFormulaSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableCodigoFormulaSearchSortInput]
  ) {
    searchCodigoFormulaSearches(sort: $sort, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        codigoFormulaId
        historico
        deleted
        searchBu
        searchCategoria
        searchCategoriaTerapeutica
        searchCodigo
        searchFabricantes
        searchFormaCosmeticaFarmaceutica
        searchMarca
        searchPaisesDestino
        searchPresentaciones
        searchPrincipiosActivos
        searchProducto
        searchSourcingUnits
      }
      nextToken
    }
  }
`;

const createCodigoFormulaSearchMutation = `
  mutation CreateCodigoFormulaSearch($input: CreateCodigoFormulaSearchInput!, $condition: ModelCodigoFormulaSearchConditionInput) {
    createCodigoFormulaSearch(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateCodigoFormulaSearchMutation = `
  mutation UpdateCodigoFormulaSearch($input: UpdateCodigoFormulaSearchInput!, $condition: ModelCodigoFormulaSearchConditionInput) {
    updateCodigoFormulaSearch(input: $input, condition: $condition) {
      id
    }
  }
`;

async function findCodigoFormulaSearch(id: string) {
  const query = amplifyMock ? listCodigoFormulas : searchCodigoFormulas;
  const conditions: APIt.ListCodigoFormulaSearchesQueryVariables = {
    filter: { codigoFormulaId: { eq: id } },
  };

  const result = (await API.graphql(
    graphqlOperation(query, conditions)
  )) as GraphQLResult<APIt.ListCodigoFormulaSearchesQuery>;

  const data: any = result.data;
  const entries: APIt.CodigoFormulaSearch[] = amplifyMock
    ? data.listCodigoFormulaSearches.items
    : data.searchCodigoFormulaSearches.items;
  return entries?.at(0);
}

async function executeQuery(query: string, input: any) {
  return API.graphql(graphqlOperation(query, input));
}

export default async function saveCodigoFormula(codigoFormula: APIt.CodigoFormula) {
  console.log(codigoFormula);
  const {
    id,
    codigo,
    marca,
    bu,
    producto,
    presentaciones,
    categoria,
    categoriaTerapeutica,
    formaCosmeticaFarmaceutica,
    principiosActivos,
    historico,
    deleted,
  } = codigoFormula;

  const searchCodigo = codigo ? prepareForSearch(codigo) : null;
  const searchMarca = marca ? prepareForSearch(marca) : null;
  const searchProducto = producto ? prepareForSearch(producto) : null;
  const searchPresentaciones = presentaciones ? prepareForSearch(presentaciones) : null;
  const searchBu = bu ? `|${prepareForSearch(bu.codigo)}|${prepareForSearch(bu.nombre)}|` : "";
  const searchCategoria = categoria
    ? `|${prepareForSearch(categoria.codigo)}|${prepareForSearch(categoria.nombre)}|`
    : null;
  const searchCategoriaTerapeutica = prepareForSearch(categoriaTerapeutica?.nombre);
  const searchFormaCosmeticaFarmaceutica = prepareForSearch(formaCosmeticaFarmaceutica?.nombre);

  const sourcingUnitsSearchArrray: any[] = [];
  const fabricantesSearchArrray: string[] = [];
  if (codigoFormula.fabricantes?.length) {
    codigoFormula.fabricantes.forEach((fabricante: APIt.FabricanteVO) => {
      const { pais, nombre } = fabricante;
      fabricantesSearchArrray.push(prepareForSearch(nombre));
      if (!sourcingUnitsSearchArrray.includes(prepareForSearch(pais.codigo))) {
        sourcingUnitsSearchArrray.push(prepareForSearch(pais.codigo));
        sourcingUnitsSearchArrray.push(prepareForSearch(pais.nombre));
      }
    });
  }

  const principiosSearchArrray: string[] = [];
  if (principiosActivos?.length) {
    principiosActivos.forEach((registroPrincipio: APIt.RegistroPrincipioActivoVO) => {
      const { principio } = registroPrincipio;
      if (registroPrincipio.fabricantes?.length) {
        registroPrincipio.fabricantes.forEach((fabricante: APIt.FabricanteVO) => {
          if (!fabricantesSearchArrray.includes(prepareForSearch(fabricante.nombre))) {
            fabricantesSearchArrray.push(prepareForSearch(fabricante.nombre));
          }
        });
      }
      principiosSearchArrray.push(prepareForSearch(principio.nombre));
    });
  }
  const searchPrincipiosActivos = JSON.stringify(principiosSearchArrray);

  const searchSourcingUnits = JSON.stringify(sourcingUnitsSearchArrray);
  const searchFabricantes = JSON.stringify(fabricantesSearchArrray);

  const { id: idOut, ...codigoFormulaInputBase } = codigoFormula;

  const codigoFormulaInput = {
    ...codigoFormulaInputBase,
    principiosActivos: principiosActivos?.map(
      (registroPrincipio: APIt.RegistroPrincipioActivoVO) => {
        const { principio, concentracion, fabricantes } = registroPrincipio;
        const { id, nombre } = principio;
        return { principio: { id, nombre }, concentracion, fabricantes };
      }
    ),
  } as APIt.CreateCodigoFormulaInput;

  const savedFormula = id ? await fetchCodigoFormula(id) : {};
  let retId = id;
  let body = null;
  if (id) {
    body = (await executeQuery(updateCodigoFormulaMutation, {
      input: { ...codigoFormulaInput, id },
    })) as GraphQLResult<APIt.UpdateCodigoFormulaMutation>;
    retId = body.data.updateCodigoFormula.id;
  } else {
    body = (await executeQuery(createCodigoFormulaMutation, {
      input: codigoFormulaInput,
    })) as GraphQLResult<APIt.CreateCodigoFormulaMutation>;
    retId = body.data.createCodigoFormula.id;
  }

  // Historico cambios
  createRegistroCambio(savedFormula, { ...codigoFormulaInput, id: retId }, "formula");

  let currentSearch: any = await findCodigoFormulaSearch(retId);
  if (!currentSearch) currentSearch = { codigoFormulaId: retId, historico, deleted };
  currentSearch = {
    ...currentSearch,
    deleted,
    searchCodigo,
    searchMarca,
    searchProducto,
    searchPresentaciones,
    searchBu,
    searchCategoriaTerapeutica,
    searchFormaCosmeticaFarmaceutica,
    searchCategoria,
    searchPrincipiosActivos,
    searchSourcingUnits,
    searchFabricantes,
  };

  if (currentSearch.id) {
    body = (await executeQuery(updateCodigoFormulaSearchMutation, {
      input: { ...currentSearch },
    })) as GraphQLResult<APIt.UpdateCodigoFormulaSearchMutation>;
  } else {
    body = (await executeQuery(createCodigoFormulaSearchMutation, {
      input: currentSearch,
    })) as GraphQLResult<APIt.CreateCodigoFormulaSearchMutation>;
  }

  return retId;
}
