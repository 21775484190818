import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { estadoTramiteNames, tipoNames } from "globalvars";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

// Material Dashboard 2 PRO React TS examples components

import Navbar from "components/tramites/Navbar";
import Card from "@mui/material/Card";

import { Backdrop, CircularProgress } from "@mui/material";
import MDButton from "components/md/MDButton";
import FormulaPicker from "features/Tramite/TramiteEdit/FormulaPicker";
import * as APIt from "API";
import * as Yup from "yup";
import DetalleTramite from "features/Tramite/TramiteEdit/DatosTramite/DetalleTramite";
import useTramiteCRUD from "features/CodigoFormula/ServiceHooks/useTramiteCRUD";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import PageLayout from "examples/LayoutContainers/PageLayout";
import PresentarDlg from "features/Tramite/TramiteEdit/Aprobaciones/PresentarDlg";
import AprobarDlg from "features/Tramite/TramiteEdit/Aprobaciones/AprobarDlg";
import RechazarDlg from "features/Tramite/TramiteEdit/Aprobaciones/RechazarDlg";
import LiberarDlg from "features/Tramite/TramiteEdit/Aprobaciones/LiberarDlg";
import { toast } from "react-toastify";
import { parseISO, differenceInDays } from "date-fns";
import { useNotificacionUpdater } from "features/Notificaciones/ServiceHooks/useNotificaciones";
import Dossier from "./Dossier";
import Objeciones from "./DatosTramite/Objeciones";
import VolverAPendiente from "./Aprobaciones/VolverAPendiente";
import Desistir from "./Aprobaciones/Desistir";

// VALIDATIONS
const pickValidation = (tramite: APIt.Tramite, tipo: APIt.TipoTramite) => {
  const activeShape = {
    fechaPresentacionObjetivo: Yup.string()
      .required("Fecha objetivo de presentación requerida")
      .nullable(true),
    fechaObjetivo: Yup.string().required("Fecha objetivo de obtención requerida").nullable(true),
    etiqueta: Yup.string().nullable(true).max(50, "Máximo 50 caracteres"),
  };
  const activeShapeConRegistro = {
    ...activeShape,
    iniciativa: Yup.object().required("Iniciativa requerida").nullable(true),
    presentaciones: Yup.array()
      .of(
        Yup.object().shape({
          formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
          producto: Yup.string().required("Producto requerido").nullable(true),
          presentaciones: Yup.string().required("Indique presntaciones").nullable(true),
          fabricantes: Yup.array()
            .of(
              Yup.object().shape({
                fabricante: Yup.object().required("Fabricante requerido").nullable(true),
                acondicionadoresPrimarios: Yup.array()
                  .of(
                    Yup.object().shape({
                      acondicionadorPrimario: Yup.object()
                        .required("Acondicionador primario requerido")
                        .nullable(true),
                    })
                  )
                  .min(1, "Seleccione al menos un acondicionador"),
              })
            )
            .min(1, "Seleccione al menos un fabricante"),
        })
      )
      .min(1, "Indique al menos una presentación"),
    formulas: Yup.array().of(
      Yup.object().shape({
        categoria: Yup.object().required("Categoría requerida").nullable(true),
        bu: Yup.object().required("B.U. requerido").nullable(true),
        formaCosmeticaFarmaceutica: Yup.object()
          .required("Forma cosmética/farmacéutica requerida")
          .nullable(true),
        marca: Yup.string().required("Marca requerida").nullable(true),
        /* condicionVenta: Yup.object()
          .when("categoria", {
            is: (categoria: APIt.CategoriaVO) => categoria?.codigo === "OTC",
            then: Yup.object().required("Condicion de venta requerida").nullable(true),
          })
          .nullable(true), */
        categoriaTerapeutica: Yup.object()
          .when("categoria", {
            is: (categoria: APIt.CategoriaVO) => categoria?.codigo === "OTC",
            then: Yup.object().required("Categoría terapéutica requerida").nullable(true),
          })
          .nullable(true),
        /* principiosActivos: Yup.array().when("categoria", {
          is: (categoria: APIt.CategoriaVO) => categoria?.codigo === "OTC",
          then: Yup.array().of(
            Yup.object().shape({
              fabricantes: Yup.array().min(1, "Seleccione al menos un fabricante"),
            })
          ),
        }), */
      })
    ),
  };
  const inactiveShapeNuevo = {
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
    pais: Yup.object().required("Debe seleccionar el país").nullable(),
    autoridad: Yup.object()
      .when("pais", {
        is: (pais: APIt.PaisVO) => !pais?.codigo || pais?.codigo !== "US",
        then: Yup.object().required("Debe seleccionar la autoridad").nullable(),
      })
      .nullable(true),
  };
  const inactiveShapeModificacion = {
    registroModificado: Yup.object()
      .required("Debe seleccionar el registro a modificar")
      .nullable(),
  };
  const inactiveShapePublicidad = {
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
    medioDifusion: Yup.object().required("Debe seleccionar el medio de difusión").nullable(),
    pais: Yup.object().required("Debe seleccionar el país emisor").nullable(),
  };
  const inactiveShapeCertificados = {
    pais: Yup.object().required("Debe seleccionar el país emisor").nullable(),
    paisReceptor: Yup.object().required("Debe seleccionar el pais receptor").nullable(),
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
    tipoCertificado: Yup.object().required("Debe seleccionar el tipo de certificado").nullable(),
  };
  const inactiveShapeVigilancia = {
    pais: Yup.object().required("Debe seleccionar el país").nullable(),
    autoridad: Yup.object().required("Debe seleccionar la autoridad").nullable(),
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
  };
  const inactiveShapeOtros = {
    pais: Yup.object().required("Debe seleccionar el país").nullable(),
    autoridad: Yup.object().required("Debe seleccionar la autoridad").nullable(),
    formulas: Yup.array().min(1, "Seleccione al menos una fórmula"),
  };

  if (tramite?.id) {
    if (
      tipo === APIt.TipoTramite.nuevo ||
      tipo === APIt.TipoTramite.modificacion ||
      tipo === APIt.TipoTramite.modificacionAdministrativa ||
      tipo === APIt.TipoTramite.modificacionTecnica
    ) {
      return Yup.object().shape(activeShapeConRegistro);
    }
    return Yup.object().shape(activeShape);
  }
  if (tipo === APIt.TipoTramite.nuevo) {
    return Yup.object().shape(inactiveShapeNuevo);
  }
  if (
    tipo === APIt.TipoTramite.modificacion ||
    tipo === APIt.TipoTramite.modificacionAdministrativa ||
    tipo === APIt.TipoTramite.modificacionTecnica ||
    tipo === APIt.TipoTramite.renovacion
  ) {
    return Yup.object().shape(inactiveShapeModificacion);
  }
  if (tipo === APIt.TipoTramite.publicidad) {
    return Yup.object().shape(inactiveShapePublicidad);
  }
  if (tipo === APIt.TipoTramite.certificados) {
    return Yup.object().shape(inactiveShapeCertificados);
  }
  if (tipo === APIt.TipoTramite.vigilanciaSanitaria) {
    return Yup.object().shape(inactiveShapeVigilancia);
  }
  if (tipo === APIt.TipoTramite.otros) {
    return Yup.object().shape(inactiveShapeOtros);
  }
  return Yup.object();
};

function TramiteEdit({ tipo: tipoParam }: { tipo: APIt.TipoTramite }): JSX.Element {
  // NAVIGATION
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const back = path.substring(0, path.lastIndexOf("/"));

  // TRAMITE
  const { id: idParam } = useParams();
  const id = idParam === "nueva" ? undefined : idParam;
  const { tramite, commitChanges, formulas } = useTramiteCRUD(id);

  // HELPERS
  const [tipo, setTipo] = useState(tipoParam);
  const [loading, setLoading] = useState(true);
  const [isUserPais, setIsUserPais] = useState(false);

  // TRAMITE REACT HOOK FORM
  const validations = pickValidation(tramite, tipo);
  const formMethods: UseFormReturn<APIt.Tramite, any> = useForm({
    resolver: yupResolver(validations),
    defaultValues: tramite,
  }); // TRAMITE REACT HOOK FORM
  const formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }, any> = useForm({
    resolver: yupResolver(validations),
    defaultValues: { formulas: [] },
  });
  const { trigger, reset, getValues } = formMethods;
  const { estado } = getValues();

  // LOAD TRAMITE FROM URL
  useEffect(() => {
    if (tramite?.id) {
      reset(tramite);
      setLoading(false);
    }
    if (tramite.id) setTipo(tramite.tipo);
  }, [tramite]);
  // LOAD FORMULAS (DOSSIER)
  useEffect(() => {
    formulaFormMethods.reset({ formulas });
  }, [formulas]);

  // STOP SPINNER IF NO ID
  useEffect(() => {
    if (!id) setLoading(false);
  }, []);

  // CONTEXT (UPDATE LIST ON SAVE)
  const {
    dispatch,
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();
  const [markReaded] = useNotificacionUpdater();
  const isUserGlobal = userRol === "Global";

  // MARK NOTIFICATIONS AS READED
  useEffect(() => {
    if (tramite?.id) {
      markReaded(tramite.id);
      setIsUserPais(userRol === "Local" && userPaises.includes(tramite?.pais?.codigo));
    }
  }, [tramite?.id]);

  // SHOW LIBERAR DIALOG
  let showLiberarBtn = false;
  if (tramite.estado === APIt.EstadoTramite.pendiente) {
    showLiberarBtn = isUserGlobal || isUserPais;
    if (!showLiberarBtn) {
      if (
        [
          APIt.TipoTramite.modificacion,
          APIt.TipoTramite.modificacionAdministrativa,
          APIt.TipoTramite.modificacionTecnica,
          APIt.TipoTramite.renovacion,
        ].includes(tipo)
      ) {
        const vencimientoDate = parseISO(tramite.vencimientoSanitario);
        const now = new Date();
        const dif = differenceInDays(now, vencimientoDate);
        showLiberarBtn = dif <= 0;
      } else {
        showLiberarBtn = true;
      }
    }
  }

  const doCommitChanges = async (refresh?: boolean) => {
    const { formulas } = formulaFormMethods.getValues();
    commitChanges(getValues(), formulas, (result) => {
      setLoading(false);
      dispatch({
        type: "update-tramite-list-entry",
        payload: result,
      });
      if (refresh) {
        navigate(`/${tipo}/${result.id}`);
      }
    });
  };

  const save = () => {
    if (!id && !getValues()?.id) {
      trigger().then((valid) => {
        if (valid) {
          setLoading(true);
          doCommitChanges(true);
        } else {
          toast.error("Verificar campos obligatorios");
        }
      });
    } else {
      trigger("presentaciones").then((valid) => {
        if (valid) {
          setLoading(true);
          doCommitChanges();
        } else {
          toast.error("Verificar campos obligatorios");
        }
      });
    }
  };

  const saveAndClose = () => {
    if (!id && !getValues()?.id) {
      trigger().then((valid) => {
        if (valid) {
          doCommitChanges();
        } else {
          toast.error("Verificar campos obligatorios");
        }
      });
    } else {
      trigger("presentaciones").then((valid) => {
        if (valid) {
          doCommitChanges();
          navigate(back);
        } else {
          toast.error("Verificar campos obligatorios");
        }
      });
    }
  };

  const presentar = (e: any) => {
    trigger().then((valid) => {
      if (valid) {
        reset({ ...getValues(), ...e, estado: APIt.EstadoTramite.presentado });
        saveAndClose();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const finalizar = (e: any) => {
    trigger().then((valid) => {
      if (valid) {
        reset({ ...getValues(), ...e, estado: APIt.EstadoTramite.aprobado });
        saveAndClose();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const rechazar = (e: any) => {
    trigger().then((valid) => {
      if (valid) {
        reset({ ...getValues(), ...e, estado: APIt.EstadoTramite.rechazado });
        saveAndClose();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const liberar = (e: any) => {
    trigger().then((valid) => {
      if (valid) {
        reset({ ...getValues(), ...e, estado: APIt.EstadoTramite.liberado });
        saveAndClose();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const volverAPendiente = (e: any) => {
    trigger().then((valid) => {
      if (valid) {
        const tramite = getValues();
        const { comentarios } = e;

        reset({
          ...tramite,
          comentarios: `${tramite.comentarios || ""} \n${comentarios}`,
          estado: APIt.EstadoTramite.pendiente,
        });
        saveAndClose();
      } else {
        toast.error("Verificar campos obligatorios");
      }
    });
  };

  const desistir = (e: any) => {
    const tramite = getValues();
    const { comentarios, fechaObtencion } = e;
    reset({
      ...tramite,
      comentarios: `${tramite.comentarios || ""} \n${comentarios}`,
      fechaObtencion,
      estado: APIt.EstadoTramite.desistido,
    });
    saveAndClose();
  };

  const getTittle = () => {
    const base = "Trámite de ";
    const inn = tramite.innovacion ? "Innovación " : "";
    const estado = estadoTramiteNames[tramite.estado as keyof typeof estadoTramiteNames];

    return `${base} ${tipoNames[tipo as keyof typeof tipoNames]} (${inn}${estado})`;
  };

  return (
    <PageLayout>
      <Navbar isMini isFull />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDBox ml={1}>
              <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
                {getTittle()}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {!id && !getValues()?.id && (
          <MDBox pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <FormulaPicker formMethods={formMethods} tipo={tipo as APIt.TipoTramite} />
              </Grid>
            </Grid>
          </MDBox>
        )}
        {getValues()?.id?.length > 0 && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DetalleTramite formMethods={formMethods} formulas={formulas} />

                <Dossier formMethods={formMethods} formulaFormMethods={formulaFormMethods} />

                {estado !== APIt.EstadoTramite.pendiente &&
                  estado !== APIt.EstadoTramite.liberado && (
                    <Objeciones formMethods={formMethods} />
                  )}
              </Grid>
            </Grid>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {formMethods.getValues()?.id?.length > 0 && (
                    <Card id="basic-info" sx={{ overflow: "visible", paddingRight: 10 }}>
                      <Grid container direction="row-reverse" my={2} mr={2} spacing={1}>
                        {((tramite.estado !== APIt.EstadoTramite.rechazado &&
                          tramite.estado !== APIt.EstadoTramite.aprobado &&
                          tramite.estado !== APIt.EstadoTramite.desistido &&
                          isUserPais) ||
                          isUserGlobal) && (
                          <>
                            {formMethods.getValues()?.id?.length > 0 && (
                              <>
                                {tramite.estado === APIt.EstadoTramite.liberado && (
                                  <Grid item>
                                    <PresentarDlg formMethods={formMethods} onConfirm={presentar} />
                                  </Grid>
                                )}

                                {(tramite.estado === APIt.EstadoTramite.presentado ||
                                  tramite.estado === APIt.EstadoTramite.objetado) && (
                                  <Grid item>
                                    <AprobarDlg formMethods={formMethods} onConfirm={finalizar} />
                                  </Grid>
                                )}

                                {(tramite.estado === APIt.EstadoTramite.presentado ||
                                  tramite.estado === APIt.EstadoTramite.objetado) && (
                                  <Grid item>
                                    <RechazarDlg formMethods={formMethods} onConfirm={rechazar} />
                                  </Grid>
                                )}
                              </>
                            )}

                            {showLiberarBtn && (
                              <Grid item>
                                <LiberarDlg
                                  formMethods={formMethods}
                                  formulaFormMethods={formulaFormMethods}
                                  onConfirm={liberar}
                                />
                              </Grid>
                            )}

                            {tramite.estado === APIt.EstadoTramite.liberado && (
                              <Grid item>
                                <VolverAPendiente
                                  formMethods={formMethods}
                                  onConfirm={volverAPendiente}
                                />
                              </Grid>
                            )}

                            {isUserGlobal &&
                              [APIt.EstadoTramite.presentado, APIt.EstadoTramite.objetado].includes(
                                tramite.estado
                              ) && (
                                <Grid item>
                                  <Desistir formMethods={formMethods} onConfirm={desistir} />
                                </Grid>
                              )}

                            <Grid item>
                              <MDButton variant="gradient" color="info" onClick={saveAndClose}>
                                Guardar y salir
                              </MDButton>
                            </Grid>

                            <Grid item>
                              <MDButton variant="gradient" color="info" type="submit">
                                Guardar
                              </MDButton>
                            </Grid>
                          </>
                        )}

                        <Grid item>
                          <MDButton variant="gradient" color="light" onClick={() => navigate(back)}>
                            Salir
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </MDBox>
          </>
        )}
      </form>
    </PageLayout>
  );
}

export default TramiteEdit;
