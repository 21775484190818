/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCodigoFormulaSearchInput = {
  id?: string | null,
  codigoFormulaId: string,
  historico?: boolean | null,
  deleted?: boolean | null,
  searchCodigo?: string | null,
  searchMarca?: string | null,
  searchProducto?: string | null,
  searchBu?: string | null,
  searchCategoriaTerapeutica?: string | null,
  searchFormaCosmeticaFarmaceutica?: string | null,
  searchCategoria?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchFabricantes?: string | null,
  searchPaisesDestino?: string | null,
  searchPresentaciones?: string | null,
};

export type ModelCodigoFormulaSearchConditionInput = {
  codigoFormulaId?: ModelIDInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  searchCodigo?: ModelStringInput | null,
  searchMarca?: ModelStringInput | null,
  searchProducto?: ModelStringInput | null,
  searchBu?: ModelStringInput | null,
  searchCategoriaTerapeutica?: ModelStringInput | null,
  searchFormaCosmeticaFarmaceutica?: ModelStringInput | null,
  searchCategoria?: ModelStringInput | null,
  searchPrincipiosActivos?: ModelStringInput | null,
  searchSourcingUnits?: ModelStringInput | null,
  searchFabricantes?: ModelStringInput | null,
  searchPaisesDestino?: ModelStringInput | null,
  searchPresentaciones?: ModelStringInput | null,
  and?: Array< ModelCodigoFormulaSearchConditionInput | null > | null,
  or?: Array< ModelCodigoFormulaSearchConditionInput | null > | null,
  not?: ModelCodigoFormulaSearchConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type CodigoFormulaSearch = {
  __typename: "CodigoFormulaSearch",
  id: string,
  codigoFormula: CodigoFormula,
  codigoFormulaId: string,
  historico?: boolean | null,
  deleted?: boolean | null,
  searchCodigo?: string | null,
  searchMarca?: string | null,
  searchProducto?: string | null,
  searchBu?: string | null,
  searchCategoriaTerapeutica?: string | null,
  searchFormaCosmeticaFarmaceutica?: string | null,
  searchCategoria?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchFabricantes?: string | null,
  searchPaisesDestino?: string | null,
  searchPresentaciones?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CodigoFormula = {
  __typename: "CodigoFormula",
  id: string,
  codigo: string,
  marca?: string | null,
  bu?: BusinessUnitVO | null,
  categoriaTerapeutica?: CategoriaTerapeuticaVO | null,
  formaCosmeticaFarmaceutica?: FormaCosmeticaFarmaceuticaVO | null,
  producto?: string | null,
  presentaciones?: string | null,
  categoria?: CategoriaVO | null,
  fabricantes?:  Array<FabricanteVO | null > | null,
  principiosActivos?:  Array<RegistroPrincipioActivoVO | null > | null,
  tramites?:  Array<FormulaTramiteVO | null > | null,
  registros?:  Array<FormulaRegistroVO | null > | null,
  dossierData?:  Array<DossierField | null > | null,
  dossierCompleteness?: number | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type BusinessUnitVO = {
  __typename: "BusinessUnitVO",
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type CategoriaTerapeuticaVO = {
  __typename: "CategoriaTerapeuticaVO",
  id?: string | null,
  nombre: string,
};

export type FormaCosmeticaFarmaceuticaVO = {
  __typename: "FormaCosmeticaFarmaceuticaVO",
  id?: string | null,
  nombre: string,
};

export type CategoriaVO = {
  __typename: "CategoriaVO",
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type FabricanteVO = {
  __typename: "FabricanteVO",
  id?: string | null,
  pais: PaisVO,
  nombre: string,
  direccion?: string | null,
  vencimientoBPM?: string | null,
};

export type PaisVO = {
  __typename: "PaisVO",
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type RegistroPrincipioActivoVO = {
  __typename: "RegistroPrincipioActivoVO",
  principio: PrincipioActivoVO,
  concentracion?: string | null,
  fabricantes?:  Array<FabricanteVO | null > | null,
};

export type PrincipioActivoVO = {
  __typename: "PrincipioActivoVO",
  id?: string | null,
  nombre: string,
};

export type FormulaTramiteVO = {
  __typename: "FormulaTramiteVO",
  id: string,
  tipo: TipoTramite,
  estado: EstadoTramite,
  pais: PaisVO,
  dossierCompleteness?: number | null,
};

export enum TipoTramite {
  nuevo = "nuevo",
  renovacion = "renovacion",
  modificacion = "modificacion",
  modificacionTecnica = "modificacionTecnica",
  modificacionAdministrativa = "modificacionAdministrativa",
  docSoporteLegal = "docSoporteLegal",
  publicidad = "publicidad",
  vigilanciaSanitaria = "vigilanciaSanitaria",
  otros = "otros",
  certificados = "certificados",
  reconocimiento = "reconocimiento",
  creg = "creg",
}


export enum EstadoTramite {
  pendiente = "pendiente",
  presentado = "presentado",
  objetado = "objetado",
  aprobado = "aprobado",
  rechazado = "rechazado",
  liberado = "liberado",
  desistido = "desistido",
}


export type FormulaRegistroVO = {
  __typename: "FormulaRegistroVO",
  id: string,
  pais: PaisVO,
  autoridad?: AutoridadVO | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  numeroRegistro?: string | null,
};

export type AutoridadVO = {
  __typename: "AutoridadVO",
  id?: string | null,
  nombre: string,
  pais: PaisVO,
};

export type DossierField = {
  __typename: "DossierField",
  codigo: string,
  done?: boolean | null,
};

export type UpdateCodigoFormulaSearchInput = {
  id: string,
  codigoFormulaId?: string | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  searchCodigo?: string | null,
  searchMarca?: string | null,
  searchProducto?: string | null,
  searchBu?: string | null,
  searchCategoriaTerapeutica?: string | null,
  searchFormaCosmeticaFarmaceutica?: string | null,
  searchCategoria?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchFabricantes?: string | null,
  searchPaisesDestino?: string | null,
  searchPresentaciones?: string | null,
};

export type DeleteCodigoFormulaSearchInput = {
  id: string,
};

export type CreateCodigoFormulaInput = {
  id?: string | null,
  codigo: string,
  marca?: string | null,
  bu?: BusinessUnitVOInput | null,
  categoriaTerapeutica?: CategoriaTerapeuticaVOInput | null,
  formaCosmeticaFarmaceutica?: FormaCosmeticaFarmaceuticaVOInput | null,
  producto?: string | null,
  presentaciones?: string | null,
  categoria?: CategoriaVOInput | null,
  fabricantes?: Array< FabricanteVOInput | null > | null,
  principiosActivos?: Array< RegistroPrincipioActivoVOInput | null > | null,
  tramites?: Array< FormulaTramiteVOInput | null > | null,
  registros?: Array< FormulaRegistroVOInput | null > | null,
  dossierData?: Array< DossierFieldInput | null > | null,
  dossierCompleteness?: number | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
};

export type BusinessUnitVOInput = {
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type CategoriaTerapeuticaVOInput = {
  id?: string | null,
  nombre: string,
};

export type FormaCosmeticaFarmaceuticaVOInput = {
  id?: string | null,
  nombre: string,
};

export type CategoriaVOInput = {
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type FabricanteVOInput = {
  id?: string | null,
  pais: PaisVOInput,
  nombre: string,
  direccion?: string | null,
  vencimientoBPM?: string | null,
};

export type PaisVOInput = {
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type RegistroPrincipioActivoVOInput = {
  principio: PrincipioActivoVOInput,
  concentracion?: string | null,
  fabricantes?: Array< FabricanteVOInput | null > | null,
};

export type PrincipioActivoVOInput = {
  id?: string | null,
  nombre: string,
};

export type FormulaTramiteVOInput = {
  id: string,
  tipo: TipoTramite,
  estado: EstadoTramite,
  pais: PaisVOInput,
  dossierCompleteness?: number | null,
};

export type FormulaRegistroVOInput = {
  id: string,
  pais: PaisVOInput,
  autoridad?: AutoridadVOInput | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  numeroRegistro?: string | null,
};

export type AutoridadVOInput = {
  id?: string | null,
  nombre: string,
  pais: PaisVOInput,
};

export type DossierFieldInput = {
  codigo: string,
  done?: boolean | null,
};

export type ModelCodigoFormulaConditionInput = {
  codigo?: ModelStringInput | null,
  marca?: ModelStringInput | null,
  producto?: ModelStringInput | null,
  presentaciones?: ModelStringInput | null,
  dossierCompleteness?: ModelFloatInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  and?: Array< ModelCodigoFormulaConditionInput | null > | null,
  or?: Array< ModelCodigoFormulaConditionInput | null > | null,
  not?: ModelCodigoFormulaConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateCodigoFormulaInput = {
  id: string,
  codigo?: string | null,
  marca?: string | null,
  bu?: BusinessUnitVOInput | null,
  categoriaTerapeutica?: CategoriaTerapeuticaVOInput | null,
  formaCosmeticaFarmaceutica?: FormaCosmeticaFarmaceuticaVOInput | null,
  producto?: string | null,
  presentaciones?: string | null,
  categoria?: CategoriaVOInput | null,
  fabricantes?: Array< FabricanteVOInput | null > | null,
  principiosActivos?: Array< RegistroPrincipioActivoVOInput | null > | null,
  tramites?: Array< FormulaTramiteVOInput | null > | null,
  registros?: Array< FormulaRegistroVOInput | null > | null,
  dossierData?: Array< DossierFieldInput | null > | null,
  dossierCompleteness?: number | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
};

export type DeleteCodigoFormulaInput = {
  id: string,
};

export type CreateTramiteSearchInput = {
  id?: string | null,
  tipo: TipoTramite,
  estado: EstadoTramite,
  tramiteId: string,
  historico?: boolean | null,
  deleted?: boolean | null,
  updatedAt?: string | null,
  searchIniciativa?: string | null,
  searchPaisDestino?: string | null,
  searchCodigos?: string | null,
  searchMarcas?: string | null,
  searchBus?: string | null,
  searchCategoriasTerapeuticas?: string | null,
  searchFormasCosmeticasFarmaceuticas?: string | null,
  searchCategorias?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchProductos?: string | null,
  searchPresentaciones?: string | null,
  searchFabricantes?: string | null,
  searchEtiqueta?: string | null,
  searchCodigoCPT?: string | null,
};

export type ModelTramiteSearchConditionInput = {
  tipo?: ModelTipoTramiteInput | null,
  estado?: ModelEstadoTramiteInput | null,
  tramiteId?: ModelIDInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  searchIniciativa?: ModelStringInput | null,
  searchPaisDestino?: ModelStringInput | null,
  searchCodigos?: ModelStringInput | null,
  searchMarcas?: ModelStringInput | null,
  searchBus?: ModelStringInput | null,
  searchCategoriasTerapeuticas?: ModelStringInput | null,
  searchFormasCosmeticasFarmaceuticas?: ModelStringInput | null,
  searchCategorias?: ModelStringInput | null,
  searchPrincipiosActivos?: ModelStringInput | null,
  searchSourcingUnits?: ModelStringInput | null,
  searchProductos?: ModelStringInput | null,
  searchPresentaciones?: ModelStringInput | null,
  searchFabricantes?: ModelStringInput | null,
  searchEtiqueta?: ModelStringInput | null,
  searchCodigoCPT?: ModelStringInput | null,
  and?: Array< ModelTramiteSearchConditionInput | null > | null,
  or?: Array< ModelTramiteSearchConditionInput | null > | null,
  not?: ModelTramiteSearchConditionInput | null,
};

export type ModelTipoTramiteInput = {
  eq?: TipoTramite | null,
  ne?: TipoTramite | null,
};

export type ModelEstadoTramiteInput = {
  eq?: EstadoTramite | null,
  ne?: EstadoTramite | null,
};

export type TramiteSearch = {
  __typename: "TramiteSearch",
  id: string,
  tipo: TipoTramite,
  estado: EstadoTramite,
  tramite: Tramite,
  tramiteId: string,
  historico?: boolean | null,
  deleted?: boolean | null,
  updatedAt?: string | null,
  searchIniciativa?: string | null,
  searchPaisDestino?: string | null,
  searchCodigos?: string | null,
  searchMarcas?: string | null,
  searchBus?: string | null,
  searchCategoriasTerapeuticas?: string | null,
  searchFormasCosmeticasFarmaceuticas?: string | null,
  searchCategorias?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchProductos?: string | null,
  searchPresentaciones?: string | null,
  searchFabricantes?: string | null,
  searchEtiqueta?: string | null,
  searchCodigoCPT?: string | null,
  createdAt: string,
};

export type Tramite = {
  __typename: "Tramite",
  id: string,
  pais: PaisVO,
  paisReceptor?: PaisVO | null,
  autoridad?: AutoridadVO | null,
  descripcion?: string | null,
  tipo: TipoTramite,
  innovacion?: boolean | null,
  estado: EstadoTramite,
  fechaPresentacion?: string | null,
  fechaPresentacionObjetivo?: string | null,
  fechaObjetivo?: string | null,
  fechaObtencion?: string | null,
  fechaLanzamiento?: string | null,
  fechaLanzamientoObjetivo?: string | null,
  fechaLiberacion?: string | null,
  fechaLiberacionObjetivo?: string | null,
  iniciativa?: IniciativaVO | null,
  prioridad?: PrioridadVO | null,
  costosDirectos?: string | null,
  costosIndirectos?: string | null,
  comentarios?: string | null,
  rechazoMotivo?: string | null,
  rechazoRA?: boolean | null,
  numeroRegistro?: string | null,
  vencimientoSanitario?: string | null,
  medioDifusion?: MedioDifusionVO | null,
  tipoCertificado?: TipoCertificadoVO | null,
  objeciones?:  Array<ObjecionVO | null > | null,
  formulas?:  Array<RegistroFormulaVO | null > | null,
  presentaciones?:  Array<RegistroPresentacionesVO | null > | null,
  registroModificado?: FormulaRegistroVO | null,
  dossierPreferences?: string | null,
  dossierCompleteness?: number | null,
  dossierData?:  Array<DossierField | null > | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  codigoCPT?: string | null,
  etiqueta?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  formulaDossierData?:  Array<FormulaDossierData | null > | null,
  updatedAt?: string | null,
  notificacionesWatchList?: string | null,
  createdAt: string,
};

export type IniciativaVO = {
  __typename: "IniciativaVO",
  id?: string | null,
  nombre: string,
};

export type PrioridadVO = {
  __typename: "PrioridadVO",
  id: string,
  nombre: string,
};

export type MedioDifusionVO = {
  __typename: "MedioDifusionVO",
  id: MedioDifusion,
  nombre: string,
};

export enum MedioDifusion {
  grafico = "grafico",
  radio = "radio",
  television = "television",
  cine = "cine",
  mediosDigitales = "mediosDigitales",
}


export type TipoCertificadoVO = {
  __typename: "TipoCertificadoVO",
  id: TipoCertificado,
  nombre: string,
};

export enum TipoCertificado {
  certifLibreVenta = "certifLibreVenta",
  certifProdFarma = "certifProdFarma",
  certifExpo = "certifExpo",
  certifBuenasPracticasDeFabricacion = "certifBuenasPracticasDeFabricacion",
  permisoImportacion = "permisoImportacion",
}


export type ObjecionVO = {
  __typename: "ObjecionVO",
  id?: string | null,
  estado?: EstadoObjecion | null,
  objecion: string,
  autoridad?: AutoridadVO | null,
  asignableRA?: boolean | null,
  fechaIngreso?: string | null,
  fechaObjecion?: string | null,
  limiteEspera?: string | null,
  descripcion?: string | null,
  fechaRespuesta?: string | null,
  exitoso?: boolean | null,
  fechaCierre?: string | null,
};

export enum EstadoObjecion {
  pendiente = "pendiente",
  respondida = "respondida",
  aceptada = "aceptada",
  rechazada = "rechazada",
}


export type RegistroFormulaVO = {
  __typename: "RegistroFormulaVO",
  id: string,
  codigo: string,
  marca: string,
  bu: BusinessUnitVO,
  categoria: CategoriaVO,
  categoriaTerapeutica?: CategoriaTerapeuticaVO | null,
  formaCosmeticaFarmaceutica?: FormaCosmeticaFarmaceuticaVO | null,
  condicionVenta?: CondicionVentaVO | null,
  principiosActivos?:  Array<RegistroPrincipioActivoVO | null > | null,
};

export type CondicionVentaVO = {
  __typename: "CondicionVentaVO",
  id?: CondicionVenta | null,
  nombre: string,
};

export enum CondicionVenta {
  OTC = "OTC",
  VentaLibre = "VentaLibre",
  RX = "RX",
}


export type RegistroPresentacionesVO = {
  __typename: "RegistroPresentacionesVO",
  presentaciones?: string | null,
  producto?: string | null,
  fabricantes?:  Array<RegistroFabricanteVO | null > | null,
  formulas?: Array< string | null > | null,
};

export type RegistroFabricanteVO = {
  __typename: "RegistroFabricanteVO",
  fabricante: FabricanteVO,
  acondicionadoresPrimarios?:  Array<RegistroAcondicionadorPrimarioVO | null > | null,
  maquiladores?:  Array<FabricanteVO | null > | null,
  fabricantesAPI?:  Array<FabricanteVO | null > | null,
};

export type RegistroAcondicionadorPrimarioVO = {
  __typename: "RegistroAcondicionadorPrimarioVO",
  acondicionadorPrimario: FabricanteVO,
  compliance?: boolean | null,
  estabilidad?: boolean | null,
  vidaUtil?: string | null,
  estatusVenta: EstatusVenta,
};

export enum EstatusVenta {
  comercializado = "comercializado",
  nocomercializado = "nocomercializado",
  discontinuado = "discontinuado",
}


export type FormulaDossierData = {
  __typename: "FormulaDossierData",
  id: string,
  dossierData?:  Array<DossierField | null > | null,
};

export type UpdateTramiteSearchInput = {
  id: string,
  tipo?: TipoTramite | null,
  estado?: EstadoTramite | null,
  tramiteId?: string | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  updatedAt?: string | null,
  searchIniciativa?: string | null,
  searchPaisDestino?: string | null,
  searchCodigos?: string | null,
  searchMarcas?: string | null,
  searchBus?: string | null,
  searchCategoriasTerapeuticas?: string | null,
  searchFormasCosmeticasFarmaceuticas?: string | null,
  searchCategorias?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchProductos?: string | null,
  searchPresentaciones?: string | null,
  searchFabricantes?: string | null,
  searchEtiqueta?: string | null,
  searchCodigoCPT?: string | null,
};

export type DeleteTramiteSearchInput = {
  id: string,
};

export type CreateTramiteInput = {
  id?: string | null,
  pais: PaisVOInput,
  paisReceptor?: PaisVOInput | null,
  autoridad?: AutoridadVOInput | null,
  descripcion?: string | null,
  tipo: TipoTramite,
  innovacion?: boolean | null,
  estado: EstadoTramite,
  fechaPresentacion?: string | null,
  fechaPresentacionObjetivo?: string | null,
  fechaObjetivo?: string | null,
  fechaObtencion?: string | null,
  fechaLanzamiento?: string | null,
  fechaLanzamientoObjetivo?: string | null,
  fechaLiberacion?: string | null,
  fechaLiberacionObjetivo?: string | null,
  iniciativa?: IniciativaVOInput | null,
  prioridad?: PrioridadVOInput | null,
  costosDirectos?: string | null,
  costosIndirectos?: string | null,
  comentarios?: string | null,
  rechazoMotivo?: string | null,
  rechazoRA?: boolean | null,
  numeroRegistro?: string | null,
  vencimientoSanitario?: string | null,
  medioDifusion?: MedioDifusionVOInput | null,
  tipoCertificado?: TipoCertificadoVOInput | null,
  objeciones?: Array< ObjecionVOInput | null > | null,
  formulas?: Array< RegistroFormulaVOInput | null > | null,
  presentaciones?: Array< RegistroPresentacionesVOInput | null > | null,
  registroModificado?: FormulaRegistroVOInput | null,
  dossierPreferences?: string | null,
  dossierCompleteness?: number | null,
  dossierData?: Array< DossierFieldInput | null > | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  codigoCPT?: string | null,
  etiqueta?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  formulaDossierData?: Array< FormulaDossierDataInput | null > | null,
  updatedAt?: string | null,
  notificacionesWatchList?: string | null,
};

export type IniciativaVOInput = {
  id?: string | null,
  nombre: string,
};

export type PrioridadVOInput = {
  id: string,
  nombre: string,
};

export type MedioDifusionVOInput = {
  id: MedioDifusion,
  nombre: string,
};

export type TipoCertificadoVOInput = {
  id: TipoCertificado,
  nombre: string,
};

export type ObjecionVOInput = {
  id?: string | null,
  estado?: EstadoObjecion | null,
  objecion: string,
  autoridad?: AutoridadVOInput | null,
  asignableRA?: boolean | null,
  fechaIngreso?: string | null,
  fechaObjecion?: string | null,
  limiteEspera?: string | null,
  descripcion?: string | null,
  fechaRespuesta?: string | null,
  exitoso?: boolean | null,
  fechaCierre?: string | null,
};

export type RegistroFormulaVOInput = {
  id: string,
  codigo: string,
  marca: string,
  bu: BusinessUnitVOInput,
  categoria: CategoriaVOInput,
  categoriaTerapeutica?: CategoriaTerapeuticaVOInput | null,
  formaCosmeticaFarmaceutica?: FormaCosmeticaFarmaceuticaVOInput | null,
  condicionVenta?: CondicionVentaVOInput | null,
  principiosActivos?: Array< RegistroPrincipioActivoVOInput | null > | null,
};

export type CondicionVentaVOInput = {
  id?: CondicionVenta | null,
  nombre: string,
};

export type RegistroPresentacionesVOInput = {
  presentaciones?: string | null,
  producto?: string | null,
  fabricantes?: Array< RegistroFabricanteVOInput | null > | null,
  formulas?: Array< string | null > | null,
};

export type RegistroFabricanteVOInput = {
  fabricante: FabricanteVOInput,
  acondicionadoresPrimarios?: Array< RegistroAcondicionadorPrimarioVOInput | null > | null,
  maquiladores?: Array< FabricanteVOInput | null > | null,
  fabricantesAPI?: Array< FabricanteVOInput | null > | null,
};

export type RegistroAcondicionadorPrimarioVOInput = {
  acondicionadorPrimario: FabricanteVOInput,
  compliance?: boolean | null,
  estabilidad?: boolean | null,
  vidaUtil?: string | null,
  estatusVenta: EstatusVenta,
};

export type FormulaDossierDataInput = {
  id: string,
  dossierData?: Array< DossierFieldInput | null > | null,
};

export type ModelTramiteConditionInput = {
  descripcion?: ModelStringInput | null,
  tipo?: ModelTipoTramiteInput | null,
  innovacion?: ModelBooleanInput | null,
  estado?: ModelEstadoTramiteInput | null,
  fechaPresentacion?: ModelStringInput | null,
  fechaPresentacionObjetivo?: ModelStringInput | null,
  fechaObjetivo?: ModelStringInput | null,
  fechaObtencion?: ModelStringInput | null,
  fechaLanzamiento?: ModelStringInput | null,
  fechaLanzamientoObjetivo?: ModelStringInput | null,
  fechaLiberacion?: ModelStringInput | null,
  fechaLiberacionObjetivo?: ModelStringInput | null,
  costosDirectos?: ModelStringInput | null,
  costosIndirectos?: ModelStringInput | null,
  comentarios?: ModelStringInput | null,
  rechazoMotivo?: ModelStringInput | null,
  rechazoRA?: ModelBooleanInput | null,
  numeroRegistro?: ModelStringInput | null,
  vencimientoSanitario?: ModelStringInput | null,
  dossierPreferences?: ModelStringInput | null,
  dossierCompleteness?: ModelFloatInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  codigoCPT?: ModelStringInput | null,
  etiqueta?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  notificacionesWatchList?: ModelStringInput | null,
  and?: Array< ModelTramiteConditionInput | null > | null,
  or?: Array< ModelTramiteConditionInput | null > | null,
  not?: ModelTramiteConditionInput | null,
};

export type UpdateTramiteInput = {
  id: string,
  pais?: PaisVOInput | null,
  paisReceptor?: PaisVOInput | null,
  autoridad?: AutoridadVOInput | null,
  descripcion?: string | null,
  tipo?: TipoTramite | null,
  innovacion?: boolean | null,
  estado?: EstadoTramite | null,
  fechaPresentacion?: string | null,
  fechaPresentacionObjetivo?: string | null,
  fechaObjetivo?: string | null,
  fechaObtencion?: string | null,
  fechaLanzamiento?: string | null,
  fechaLanzamientoObjetivo?: string | null,
  fechaLiberacion?: string | null,
  fechaLiberacionObjetivo?: string | null,
  iniciativa?: IniciativaVOInput | null,
  prioridad?: PrioridadVOInput | null,
  costosDirectos?: string | null,
  costosIndirectos?: string | null,
  comentarios?: string | null,
  rechazoMotivo?: string | null,
  rechazoRA?: boolean | null,
  numeroRegistro?: string | null,
  vencimientoSanitario?: string | null,
  medioDifusion?: MedioDifusionVOInput | null,
  tipoCertificado?: TipoCertificadoVOInput | null,
  objeciones?: Array< ObjecionVOInput | null > | null,
  formulas?: Array< RegistroFormulaVOInput | null > | null,
  presentaciones?: Array< RegistroPresentacionesVOInput | null > | null,
  registroModificado?: FormulaRegistroVOInput | null,
  dossierPreferences?: string | null,
  dossierCompleteness?: number | null,
  dossierData?: Array< DossierFieldInput | null > | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  codigoCPT?: string | null,
  etiqueta?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  formulaDossierData?: Array< FormulaDossierDataInput | null > | null,
  updatedAt?: string | null,
  notificacionesWatchList?: string | null,
};

export type DeleteTramiteInput = {
  id: string,
};

export type CreateRegistroSearchInput = {
  id?: string | null,
  registroId: string,
  historico?: boolean | null,
  deleted?: boolean | null,
  searchIniciativa?: string | null,
  searchPaisDestino?: string | null,
  searchCodigos?: string | null,
  searchMarcas?: string | null,
  searchBus?: string | null,
  searchCategoriasTerapeuticas?: string | null,
  searchFormasCosmeticasFarmaceuticas?: string | null,
  searchCategorias?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchProductos?: string | null,
  searchPresentaciones?: string | null,
  searchFabricantes?: string | null,
  searchRegistro?: string | null,
  searchEstatusVenta?: string | null,
};

export type ModelRegistroSearchConditionInput = {
  registroId?: ModelIDInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  searchIniciativa?: ModelStringInput | null,
  searchPaisDestino?: ModelStringInput | null,
  searchCodigos?: ModelStringInput | null,
  searchMarcas?: ModelStringInput | null,
  searchBus?: ModelStringInput | null,
  searchCategoriasTerapeuticas?: ModelStringInput | null,
  searchFormasCosmeticasFarmaceuticas?: ModelStringInput | null,
  searchCategorias?: ModelStringInput | null,
  searchPrincipiosActivos?: ModelStringInput | null,
  searchSourcingUnits?: ModelStringInput | null,
  searchProductos?: ModelStringInput | null,
  searchPresentaciones?: ModelStringInput | null,
  searchFabricantes?: ModelStringInput | null,
  searchRegistro?: ModelStringInput | null,
  searchEstatusVenta?: ModelStringInput | null,
  and?: Array< ModelRegistroSearchConditionInput | null > | null,
  or?: Array< ModelRegistroSearchConditionInput | null > | null,
  not?: ModelRegistroSearchConditionInput | null,
};

export type RegistroSearch = {
  __typename: "RegistroSearch",
  id: string,
  registro: Registro,
  registroId: string,
  historico?: boolean | null,
  deleted?: boolean | null,
  searchIniciativa?: string | null,
  searchPaisDestino?: string | null,
  searchCodigos?: string | null,
  searchMarcas?: string | null,
  searchBus?: string | null,
  searchCategoriasTerapeuticas?: string | null,
  searchFormasCosmeticasFarmaceuticas?: string | null,
  searchCategorias?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchProductos?: string | null,
  searchPresentaciones?: string | null,
  searchFabricantes?: string | null,
  searchRegistro?: string | null,
  searchEstatusVenta?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Registro = {
  __typename: "Registro",
  id: string,
  numeroRegistro?: string | null,
  pais?: PaisVO | null,
  autoridad?: AutoridadVO | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  formulas?:  Array<RegistroFormulaVO | null > | null,
  presentaciones?:  Array<RegistroPresentacionesVO | null > | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  updatedAt?: string | null,
  notificacionesWatchList: string,
  createdAt: string,
};

export type UpdateRegistroSearchInput = {
  id: string,
  registroId?: string | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  searchIniciativa?: string | null,
  searchPaisDestino?: string | null,
  searchCodigos?: string | null,
  searchMarcas?: string | null,
  searchBus?: string | null,
  searchCategoriasTerapeuticas?: string | null,
  searchFormasCosmeticasFarmaceuticas?: string | null,
  searchCategorias?: string | null,
  searchPrincipiosActivos?: string | null,
  searchSourcingUnits?: string | null,
  searchProductos?: string | null,
  searchPresentaciones?: string | null,
  searchFabricantes?: string | null,
  searchRegistro?: string | null,
  searchEstatusVenta?: string | null,
};

export type DeleteRegistroSearchInput = {
  id: string,
};

export type CreateRegistroInput = {
  id?: string | null,
  numeroRegistro?: string | null,
  pais?: PaisVOInput | null,
  autoridad?: AutoridadVOInput | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  formulas?: Array< RegistroFormulaVOInput | null > | null,
  presentaciones?: Array< RegistroPresentacionesVOInput | null > | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  updatedAt?: string | null,
  notificacionesWatchList: string,
};

export type ModelRegistroConditionInput = {
  numeroRegistro?: ModelStringInput | null,
  vencimientoSanitario?: ModelStringInput | null,
  inicioRenovacion?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  notificacionesWatchList?: ModelStringInput | null,
  and?: Array< ModelRegistroConditionInput | null > | null,
  or?: Array< ModelRegistroConditionInput | null > | null,
  not?: ModelRegistroConditionInput | null,
};

export type UpdateRegistroInput = {
  id: string,
  numeroRegistro?: string | null,
  pais?: PaisVOInput | null,
  autoridad?: AutoridadVOInput | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  formulas?: Array< RegistroFormulaVOInput | null > | null,
  presentaciones?: Array< RegistroPresentacionesVOInput | null > | null,
  historico?: boolean | null,
  deleted?: boolean | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  updatedAt?: string | null,
  notificacionesWatchList?: string | null,
};

export type DeleteRegistroInput = {
  id: string,
};

export type CreateRegistroCambioInput = {
  id?: string | null,
  tipo: string,
  objectId: string,
  usuario?: string | null,
  cambios?: string | null,
  updatedAt?: string | null,
};

export type ModelRegistroCambioConditionInput = {
  tipo?: ModelStringInput | null,
  objectId?: ModelStringInput | null,
  usuario?: ModelStringInput | null,
  cambios?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRegistroCambioConditionInput | null > | null,
  or?: Array< ModelRegistroCambioConditionInput | null > | null,
  not?: ModelRegistroCambioConditionInput | null,
};

export type RegistroCambio = {
  __typename: "RegistroCambio",
  id: string,
  tipo: string,
  objectId: string,
  usuario?: string | null,
  cambios?: string | null,
  updatedAt: string,
  createdAt: string,
};

export type UpdateRegistroCambioInput = {
  id: string,
  tipo?: string | null,
  objectId?: string | null,
  usuario?: string | null,
  cambios?: string | null,
  updatedAt?: string | null,
};

export type DeleteRegistroCambioInput = {
  id: string,
};

export type CreateCategoriaTerapeuticaInput = {
  id?: string | null,
  nombre: string,
};

export type ModelCategoriaTerapeuticaConditionInput = {
  nombre?: ModelStringInput | null,
  and?: Array< ModelCategoriaTerapeuticaConditionInput | null > | null,
  or?: Array< ModelCategoriaTerapeuticaConditionInput | null > | null,
  not?: ModelCategoriaTerapeuticaConditionInput | null,
};

export type CategoriaTerapeutica = {
  __typename: "CategoriaTerapeutica",
  id: string,
  nombre: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCategoriaTerapeuticaInput = {
  id: string,
  nombre?: string | null,
};

export type DeleteCategoriaTerapeuticaInput = {
  id: string,
};

export type CreateIniciativaInput = {
  id?: string | null,
  nombre: string,
};

export type ModelIniciativaConditionInput = {
  nombre?: ModelStringInput | null,
  and?: Array< ModelIniciativaConditionInput | null > | null,
  or?: Array< ModelIniciativaConditionInput | null > | null,
  not?: ModelIniciativaConditionInput | null,
};

export type Iniciativa = {
  __typename: "Iniciativa",
  id: string,
  nombre: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateIniciativaInput = {
  id: string,
  nombre?: string | null,
};

export type DeleteIniciativaInput = {
  id: string,
};

export type CreateFormaCosmeticaFarmaceuticaInput = {
  id?: string | null,
  nombre: string,
  historico?: boolean | null,
};

export type ModelFormaCosmeticaFarmaceuticaConditionInput = {
  nombre?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  and?: Array< ModelFormaCosmeticaFarmaceuticaConditionInput | null > | null,
  or?: Array< ModelFormaCosmeticaFarmaceuticaConditionInput | null > | null,
  not?: ModelFormaCosmeticaFarmaceuticaConditionInput | null,
};

export type FormaCosmeticaFarmaceutica = {
  __typename: "FormaCosmeticaFarmaceutica",
  id: string,
  nombre: string,
  historico?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFormaCosmeticaFarmaceuticaInput = {
  id: string,
  nombre?: string | null,
  historico?: boolean | null,
};

export type DeleteFormaCosmeticaFarmaceuticaInput = {
  id: string,
};

export type CreateBusinessUnitInput = {
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type ModelBusinessUnitConditionInput = {
  codigo?: ModelStringInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelBusinessUnitConditionInput | null > | null,
  or?: Array< ModelBusinessUnitConditionInput | null > | null,
  not?: ModelBusinessUnitConditionInput | null,
};

export type BusinessUnit = {
  __typename: "BusinessUnit",
  id: string,
  codigo: string,
  nombre: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBusinessUnitInput = {
  id: string,
  codigo?: string | null,
  nombre?: string | null,
};

export type DeleteBusinessUnitInput = {
  id: string,
};

export type CreateCategoriaInput = {
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type ModelCategoriaConditionInput = {
  codigo?: ModelStringInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelCategoriaConditionInput | null > | null,
  or?: Array< ModelCategoriaConditionInput | null > | null,
  not?: ModelCategoriaConditionInput | null,
};

export type Categoria = {
  __typename: "Categoria",
  id: string,
  codigo: string,
  nombre: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCategoriaInput = {
  id: string,
  codigo?: string | null,
  nombre?: string | null,
};

export type DeleteCategoriaInput = {
  id: string,
};

export type CreateFabricanteInput = {
  id?: string | null,
  paisId: string,
  nombre: string,
  direccion?: string | null,
  vencimientoBPM?: string | null,
  historico?: boolean | null,
};

export type ModelFabricanteConditionInput = {
  paisId?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  direccion?: ModelStringInput | null,
  vencimientoBPM?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  and?: Array< ModelFabricanteConditionInput | null > | null,
  or?: Array< ModelFabricanteConditionInput | null > | null,
  not?: ModelFabricanteConditionInput | null,
};

export type Fabricante = {
  __typename: "Fabricante",
  id: string,
  pais: Pais,
  paisId: string,
  nombre: string,
  direccion?: string | null,
  vencimientoBPM?: string | null,
  historico?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Pais = {
  __typename: "Pais",
  id: string,
  codigo: string,
  nombre: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFabricanteInput = {
  id: string,
  paisId?: string | null,
  nombre?: string | null,
  direccion?: string | null,
  vencimientoBPM?: string | null,
  historico?: boolean | null,
};

export type DeleteFabricanteInput = {
  id: string,
};

export type CreatePrincipioActivoInput = {
  id?: string | null,
  nombre: string,
  historico?: boolean | null,
};

export type ModelPrincipioActivoConditionInput = {
  nombre?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  and?: Array< ModelPrincipioActivoConditionInput | null > | null,
  or?: Array< ModelPrincipioActivoConditionInput | null > | null,
  not?: ModelPrincipioActivoConditionInput | null,
};

export type PrincipioActivo = {
  __typename: "PrincipioActivo",
  id: string,
  nombre: string,
  historico?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePrincipioActivoInput = {
  id: string,
  nombre?: string | null,
  historico?: boolean | null,
};

export type DeletePrincipioActivoInput = {
  id: string,
};

export type CreatePaisInput = {
  id?: string | null,
  codigo: string,
  nombre: string,
};

export type ModelPaisConditionInput = {
  codigo?: ModelStringInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelPaisConditionInput | null > | null,
  or?: Array< ModelPaisConditionInput | null > | null,
  not?: ModelPaisConditionInput | null,
};

export type UpdatePaisInput = {
  id: string,
  codigo?: string | null,
  nombre?: string | null,
};

export type DeletePaisInput = {
  id: string,
};

export type CreateAutoridadInput = {
  id?: string | null,
  nombre: string,
  paisId: string,
};

export type ModelAutoridadConditionInput = {
  nombre?: ModelStringInput | null,
  paisId?: ModelIDInput | null,
  and?: Array< ModelAutoridadConditionInput | null > | null,
  or?: Array< ModelAutoridadConditionInput | null > | null,
  not?: ModelAutoridadConditionInput | null,
};

export type Autoridad = {
  __typename: "Autoridad",
  id: string,
  nombre: string,
  pais: Pais,
  paisId: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAutoridadInput = {
  id: string,
  nombre?: string | null,
  paisId?: string | null,
};

export type DeleteAutoridadInput = {
  id: string,
};

export type CreateDashboardTramiteDailyInput = {
  id?: string | null,
  date: string,
  type: TipoDashboardTramiteDaily,
  efectividadAprobados?: Array< DashboardTramiteVOInput | null > | null,
  efectividadRechazados?: Array< DashboardTramiteVOInput | null > | null,
  efectividadDesistidos?: Array< DashboardTramiteVOInput | null > | null,
  efectividadEnCurso?: Array< DashboardTramiteVOInput | null > | null,
  sometimientosOnTime?: Array< DashboardTramiteVOInput | null > | null,
  sometimientosVencidos?: Array< DashboardTramiteVOInput | null > | null,
  sometimientosEnCurso?: Array< DashboardTramiteVOInput | null > | null,
  objecionesEnCurso?: Array< DashboardObjecionVOInput | null > | null,
  objecionesDesistidas?: Array< DashboardObjecionVOInput | null > | null,
  objecionesAFavor?: Array< DashboardObjecionVOInput | null > | null,
  objecionesEnContra?: Array< DashboardObjecionVOInput | null > | null,
  estatusArmado?: Array< DashboardTramiteVOInput | null > | null,
  estatusEnDesarrollo?: Array< DashboardTramiteVOInput | null > | null,
  estatusSinDossier?: Array< DashboardTramiteVOInput | null > | null,
  estatusEnProceso?: Array< DashboardTramiteVOInput | null > | null,
  estatusAprobado?: Array< DashboardTramiteVOInput | null > | null,
  estatusRechazado?: Array< DashboardTramiteVOInput | null > | null,
  estatusDesistido?: Array< DashboardTramiteVOInput | null > | null,
};

export enum TipoDashboardTramiteDaily {
  efectividad = "efectividad",
  sometimientos = "sometimientos",
  objeciones = "objeciones",
  estatus = "estatus",
}


export type DashboardTramiteVOInput = {
  id: string,
  pais: PaisVOInput,
  businessUnit: BusinessUnitVOInput,
  estado: EstadoTramite,
  tipo: TipoTramite,
  innovacion?: boolean | null,
  iniciativa?: IniciativaVOInput | null,
  formula: string,
};

export type DashboardObjecionVOInput = {
  id: string,
  tramite: DashboardTramiteVOInput,
};

export type ModelDashboardTramiteDailyConditionInput = {
  date?: ModelStringInput | null,
  type?: ModelTipoDashboardTramiteDailyInput | null,
  and?: Array< ModelDashboardTramiteDailyConditionInput | null > | null,
  or?: Array< ModelDashboardTramiteDailyConditionInput | null > | null,
  not?: ModelDashboardTramiteDailyConditionInput | null,
};

export type ModelTipoDashboardTramiteDailyInput = {
  eq?: TipoDashboardTramiteDaily | null,
  ne?: TipoDashboardTramiteDaily | null,
};

export type DashboardTramiteDaily = {
  __typename: "DashboardTramiteDaily",
  id: string,
  date: string,
  type: TipoDashboardTramiteDaily,
  efectividadAprobados?:  Array<DashboardTramiteVO | null > | null,
  efectividadRechazados?:  Array<DashboardTramiteVO | null > | null,
  efectividadDesistidos?:  Array<DashboardTramiteVO | null > | null,
  efectividadEnCurso?:  Array<DashboardTramiteVO | null > | null,
  sometimientosOnTime?:  Array<DashboardTramiteVO | null > | null,
  sometimientosVencidos?:  Array<DashboardTramiteVO | null > | null,
  sometimientosEnCurso?:  Array<DashboardTramiteVO | null > | null,
  objecionesEnCurso?:  Array<DashboardObjecionVO | null > | null,
  objecionesDesistidas?:  Array<DashboardObjecionVO | null > | null,
  objecionesAFavor?:  Array<DashboardObjecionVO | null > | null,
  objecionesEnContra?:  Array<DashboardObjecionVO | null > | null,
  estatusArmado?:  Array<DashboardTramiteVO | null > | null,
  estatusEnDesarrollo?:  Array<DashboardTramiteVO | null > | null,
  estatusSinDossier?:  Array<DashboardTramiteVO | null > | null,
  estatusEnProceso?:  Array<DashboardTramiteVO | null > | null,
  estatusAprobado?:  Array<DashboardTramiteVO | null > | null,
  estatusRechazado?:  Array<DashboardTramiteVO | null > | null,
  estatusDesistido?:  Array<DashboardTramiteVO | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type DashboardTramiteVO = {
  __typename: "DashboardTramiteVO",
  id: string,
  pais: PaisVO,
  businessUnit: BusinessUnitVO,
  estado: EstadoTramite,
  tipo: TipoTramite,
  innovacion?: boolean | null,
  iniciativa?: IniciativaVO | null,
  formula: string,
};

export type DashboardObjecionVO = {
  __typename: "DashboardObjecionVO",
  id: string,
  tramite: DashboardTramiteVO,
};

export type UpdateDashboardTramiteDailyInput = {
  id: string,
  date?: string | null,
  type?: TipoDashboardTramiteDaily | null,
  efectividadAprobados?: Array< DashboardTramiteVOInput | null > | null,
  efectividadRechazados?: Array< DashboardTramiteVOInput | null > | null,
  efectividadDesistidos?: Array< DashboardTramiteVOInput | null > | null,
  efectividadEnCurso?: Array< DashboardTramiteVOInput | null > | null,
  sometimientosOnTime?: Array< DashboardTramiteVOInput | null > | null,
  sometimientosVencidos?: Array< DashboardTramiteVOInput | null > | null,
  sometimientosEnCurso?: Array< DashboardTramiteVOInput | null > | null,
  objecionesEnCurso?: Array< DashboardObjecionVOInput | null > | null,
  objecionesDesistidas?: Array< DashboardObjecionVOInput | null > | null,
  objecionesAFavor?: Array< DashboardObjecionVOInput | null > | null,
  objecionesEnContra?: Array< DashboardObjecionVOInput | null > | null,
  estatusArmado?: Array< DashboardTramiteVOInput | null > | null,
  estatusEnDesarrollo?: Array< DashboardTramiteVOInput | null > | null,
  estatusSinDossier?: Array< DashboardTramiteVOInput | null > | null,
  estatusEnProceso?: Array< DashboardTramiteVOInput | null > | null,
  estatusAprobado?: Array< DashboardTramiteVOInput | null > | null,
  estatusRechazado?: Array< DashboardTramiteVOInput | null > | null,
  estatusDesistido?: Array< DashboardTramiteVOInput | null > | null,
};

export type DeleteDashboardTramiteDailyInput = {
  id: string,
};

export type CreateDashboardTramiteYearlyInput = {
  id?: string | null,
  year: number,
  type: TipoDashboardTramiteYearly,
  registros?: number | null,
  tramitesAprobados?: number | null,
  tramitesRechazados?: number | null,
};

export enum TipoDashboardTramiteYearly {
  registros = "registros",
  tramitesAprobados = "tramitesAprobados",
  tramitesRechazados = "tramitesRechazados",
}


export type ModelDashboardTramiteYearlyConditionInput = {
  year?: ModelIntInput | null,
  type?: ModelTipoDashboardTramiteYearlyInput | null,
  registros?: ModelIntInput | null,
  tramitesAprobados?: ModelIntInput | null,
  tramitesRechazados?: ModelIntInput | null,
  and?: Array< ModelDashboardTramiteYearlyConditionInput | null > | null,
  or?: Array< ModelDashboardTramiteYearlyConditionInput | null > | null,
  not?: ModelDashboardTramiteYearlyConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTipoDashboardTramiteYearlyInput = {
  eq?: TipoDashboardTramiteYearly | null,
  ne?: TipoDashboardTramiteYearly | null,
};

export type DashboardTramiteYearly = {
  __typename: "DashboardTramiteYearly",
  id: string,
  year: number,
  type: TipoDashboardTramiteYearly,
  registros?: number | null,
  tramitesAprobados?: number | null,
  tramitesRechazados?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDashboardTramiteYearlyInput = {
  id: string,
  year?: number | null,
  type?: TipoDashboardTramiteYearly | null,
  registros?: number | null,
  tramitesAprobados?: number | null,
  tramitesRechazados?: number | null,
};

export type DeleteDashboardTramiteYearlyInput = {
  id: string,
};

export type CreateNotificacionInput = {
  id?: string | null,
  tipo: TipoNotificacion,
  destinatario: string,
  objectId: string,
  objectData?: NotificacionDataInput | null,
  enviado: string,
  leido: string,
  createdAt?: string | null,
};

export enum TipoNotificacion {
  tramiteActivado = "tramiteActivado",
  tramiteModificacionAprobada = "tramiteModificacionAprobada",
  tramiteLiberado = "tramiteLiberado",
  tramiteAprobado = "tramiteAprobado",
  tramiteRechazado = "tramiteRechazado",
  tramiteDesistido = "tramiteDesistido",
  tramitePresentado = "tramitePresentado",
  objecionNueva = "objecionNueva",
  objecionCerrada = "objecionCerrada",
  registroPorVencer = "registroPorVencer",
  registroVencido = "registroVencido",
  sometimientoPorVencer = "sometimientoPorVencer",
  sometimientoVencido = "sometimientoVencido",
  tramitePorVencer = "tramitePorVencer",
  tramiteVencido = "tramiteVencido",
  certificadoPorVencer = "certificadoPorVencer",
  certificadoVencido = "certificadoVencido",
}


export type NotificacionDataInput = {
  id: string,
  codigoTramite?: string | null,
  codigoFormula?: string | null,
  numeroRegistro?: string | null,
  tipoTramite?: TipoTramite | null,
  fechaLiberacionObjetivo?: string | null,
  fechaPresentacionObjetivo?: string | null,
  fechaObjetivo?: string | null,
  tipoCertificado?: TipoCertificado | null,
  pais?: string | null,
  autoridad?: string | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  vencimientoCertificado?: string | null,
};

export type ModelNotificacionConditionInput = {
  tipo?: ModelTipoNotificacionInput | null,
  destinatario?: ModelStringInput | null,
  objectId?: ModelStringInput | null,
  enviado?: ModelStringInput | null,
  leido?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificacionConditionInput | null > | null,
  or?: Array< ModelNotificacionConditionInput | null > | null,
  not?: ModelNotificacionConditionInput | null,
};

export type ModelTipoNotificacionInput = {
  eq?: TipoNotificacion | null,
  ne?: TipoNotificacion | null,
};

export type Notificacion = {
  __typename: "Notificacion",
  id: string,
  tipo: TipoNotificacion,
  destinatario: string,
  objectId: string,
  objectData?: NotificacionData | null,
  enviado: string,
  leido: string,
  createdAt?: string | null,
  updatedAt: string,
};

export type NotificacionData = {
  __typename: "NotificacionData",
  id: string,
  codigoTramite?: string | null,
  codigoFormula?: string | null,
  numeroRegistro?: string | null,
  tipoTramite?: TipoTramite | null,
  fechaLiberacionObjetivo?: string | null,
  fechaPresentacionObjetivo?: string | null,
  fechaObjetivo?: string | null,
  tipoCertificado?: TipoCertificado | null,
  pais?: string | null,
  autoridad?: string | null,
  vencimientoSanitario?: string | null,
  inicioRenovacion?: string | null,
  vencimientoCertificado?: string | null,
};

export type UpdateNotificacionInput = {
  id: string,
  tipo?: TipoNotificacion | null,
  destinatario?: string | null,
  objectId?: string | null,
  objectData?: NotificacionDataInput | null,
  enviado?: string | null,
  leido?: string | null,
  createdAt?: string | null,
};

export type DeleteNotificacionInput = {
  id: string,
};

export type CreateUserConfigInput = {
  id?: string | null,
  email: string,
  rol: string,
  paises?: Array< string | null > | null,
  enabled?: boolean | null,
};

export type ModelUserConfigConditionInput = {
  email?: ModelStringInput | null,
  rol?: ModelStringInput | null,
  paises?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelUserConfigConditionInput | null > | null,
  or?: Array< ModelUserConfigConditionInput | null > | null,
  not?: ModelUserConfigConditionInput | null,
};

export type UserConfig = {
  __typename: "UserConfig",
  id: string,
  email: string,
  rol: string,
  paises?: Array< string | null > | null,
  enabled?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserConfigInput = {
  id: string,
  email?: string | null,
  rol?: string | null,
  paises?: Array< string | null > | null,
  enabled?: boolean | null,
};

export type DeleteUserConfigInput = {
  id: string,
};

export type SaveFormulaInput = {
  formula?: string | null,
};

export type MutationResponse = {
  __typename: "MutationResponse",
  statusCode?: string | null,
  objectId?: string | null,
  errors?: Array< string | null > | null,
};

export type SaveTramiteInput = {
  tramite?: string | null,
};

export type SaveRegistroInput = {
  registro?: string | null,
};

export type ModelCodigoFormulaSearchFilterInput = {
  id?: ModelIDInput | null,
  codigoFormulaId?: ModelIDInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  searchCodigo?: ModelStringInput | null,
  searchMarca?: ModelStringInput | null,
  searchProducto?: ModelStringInput | null,
  searchBu?: ModelStringInput | null,
  searchCategoriaTerapeutica?: ModelStringInput | null,
  searchFormaCosmeticaFarmaceutica?: ModelStringInput | null,
  searchCategoria?: ModelStringInput | null,
  searchPrincipiosActivos?: ModelStringInput | null,
  searchSourcingUnits?: ModelStringInput | null,
  searchFabricantes?: ModelStringInput | null,
  searchPaisesDestino?: ModelStringInput | null,
  searchPresentaciones?: ModelStringInput | null,
  and?: Array< ModelCodigoFormulaSearchFilterInput | null > | null,
  or?: Array< ModelCodigoFormulaSearchFilterInput | null > | null,
  not?: ModelCodigoFormulaSearchFilterInput | null,
};

export type ModelCodigoFormulaSearchConnection = {
  __typename: "ModelCodigoFormulaSearchConnection",
  items:  Array<CodigoFormulaSearch | null >,
  nextToken?: string | null,
};

export type ModelCodigoFormulaFilterInput = {
  id?: ModelIDInput | null,
  codigo?: ModelStringInput | null,
  marca?: ModelStringInput | null,
  producto?: ModelStringInput | null,
  presentaciones?: ModelStringInput | null,
  dossierCompleteness?: ModelFloatInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  and?: Array< ModelCodigoFormulaFilterInput | null > | null,
  or?: Array< ModelCodigoFormulaFilterInput | null > | null,
  not?: ModelCodigoFormulaFilterInput | null,
};

export type ModelCodigoFormulaConnection = {
  __typename: "ModelCodigoFormulaConnection",
  items:  Array<CodigoFormula | null >,
  nextToken?: string | null,
};

export type ModelTramiteSearchFilterInput = {
  id?: ModelIDInput | null,
  tipo?: ModelTipoTramiteInput | null,
  estado?: ModelEstadoTramiteInput | null,
  tramiteId?: ModelIDInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  searchIniciativa?: ModelStringInput | null,
  searchPaisDestino?: ModelStringInput | null,
  searchCodigos?: ModelStringInput | null,
  searchMarcas?: ModelStringInput | null,
  searchBus?: ModelStringInput | null,
  searchCategoriasTerapeuticas?: ModelStringInput | null,
  searchFormasCosmeticasFarmaceuticas?: ModelStringInput | null,
  searchCategorias?: ModelStringInput | null,
  searchPrincipiosActivos?: ModelStringInput | null,
  searchSourcingUnits?: ModelStringInput | null,
  searchProductos?: ModelStringInput | null,
  searchPresentaciones?: ModelStringInput | null,
  searchFabricantes?: ModelStringInput | null,
  searchEtiqueta?: ModelStringInput | null,
  searchCodigoCPT?: ModelStringInput | null,
  and?: Array< ModelTramiteSearchFilterInput | null > | null,
  or?: Array< ModelTramiteSearchFilterInput | null > | null,
  not?: ModelTramiteSearchFilterInput | null,
};

export type ModelTramiteSearchConnection = {
  __typename: "ModelTramiteSearchConnection",
  items:  Array<TramiteSearch | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTramiteFilterInput = {
  id?: ModelIDInput | null,
  descripcion?: ModelStringInput | null,
  tipo?: ModelTipoTramiteInput | null,
  innovacion?: ModelBooleanInput | null,
  estado?: ModelEstadoTramiteInput | null,
  fechaPresentacion?: ModelStringInput | null,
  fechaPresentacionObjetivo?: ModelStringInput | null,
  fechaObjetivo?: ModelStringInput | null,
  fechaObtencion?: ModelStringInput | null,
  fechaLanzamiento?: ModelStringInput | null,
  fechaLanzamientoObjetivo?: ModelStringInput | null,
  fechaLiberacion?: ModelStringInput | null,
  fechaLiberacionObjetivo?: ModelStringInput | null,
  costosDirectos?: ModelStringInput | null,
  costosIndirectos?: ModelStringInput | null,
  comentarios?: ModelStringInput | null,
  rechazoMotivo?: ModelStringInput | null,
  rechazoRA?: ModelBooleanInput | null,
  numeroRegistro?: ModelStringInput | null,
  vencimientoSanitario?: ModelStringInput | null,
  dossierPreferences?: ModelStringInput | null,
  dossierCompleteness?: ModelFloatInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  codigoCPT?: ModelStringInput | null,
  etiqueta?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  notificacionesWatchList?: ModelStringInput | null,
  and?: Array< ModelTramiteFilterInput | null > | null,
  or?: Array< ModelTramiteFilterInput | null > | null,
  not?: ModelTramiteFilterInput | null,
};

export type ModelTramiteConnection = {
  __typename: "ModelTramiteConnection",
  items:  Array<Tramite | null >,
  nextToken?: string | null,
};

export type ModelRegistroSearchFilterInput = {
  id?: ModelIDInput | null,
  registroId?: ModelIDInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  searchIniciativa?: ModelStringInput | null,
  searchPaisDestino?: ModelStringInput | null,
  searchCodigos?: ModelStringInput | null,
  searchMarcas?: ModelStringInput | null,
  searchBus?: ModelStringInput | null,
  searchCategoriasTerapeuticas?: ModelStringInput | null,
  searchFormasCosmeticasFarmaceuticas?: ModelStringInput | null,
  searchCategorias?: ModelStringInput | null,
  searchPrincipiosActivos?: ModelStringInput | null,
  searchSourcingUnits?: ModelStringInput | null,
  searchProductos?: ModelStringInput | null,
  searchPresentaciones?: ModelStringInput | null,
  searchFabricantes?: ModelStringInput | null,
  searchRegistro?: ModelStringInput | null,
  searchEstatusVenta?: ModelStringInput | null,
  and?: Array< ModelRegistroSearchFilterInput | null > | null,
  or?: Array< ModelRegistroSearchFilterInput | null > | null,
  not?: ModelRegistroSearchFilterInput | null,
};

export type ModelRegistroSearchConnection = {
  __typename: "ModelRegistroSearchConnection",
  items:  Array<RegistroSearch | null >,
  nextToken?: string | null,
};

export type ModelRegistroFilterInput = {
  id?: ModelIDInput | null,
  numeroRegistro?: ModelStringInput | null,
  vencimientoSanitario?: ModelStringInput | null,
  inicioRenovacion?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  deleted?: ModelBooleanInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  notificacionesWatchList?: ModelStringInput | null,
  and?: Array< ModelRegistroFilterInput | null > | null,
  or?: Array< ModelRegistroFilterInput | null > | null,
  not?: ModelRegistroFilterInput | null,
};

export type ModelRegistroConnection = {
  __typename: "ModelRegistroConnection",
  items:  Array<Registro | null >,
  nextToken?: string | null,
};

export type ModelRegistroCambioFilterInput = {
  id?: ModelIDInput | null,
  tipo?: ModelStringInput | null,
  objectId?: ModelStringInput | null,
  usuario?: ModelStringInput | null,
  cambios?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRegistroCambioFilterInput | null > | null,
  or?: Array< ModelRegistroCambioFilterInput | null > | null,
  not?: ModelRegistroCambioFilterInput | null,
};

export type ModelRegistroCambioConnection = {
  __typename: "ModelRegistroCambioConnection",
  items:  Array<RegistroCambio | null >,
  nextToken?: string | null,
};

export type ModelCategoriaTerapeuticaFilterInput = {
  id?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelCategoriaTerapeuticaFilterInput | null > | null,
  or?: Array< ModelCategoriaTerapeuticaFilterInput | null > | null,
  not?: ModelCategoriaTerapeuticaFilterInput | null,
};

export type ModelCategoriaTerapeuticaConnection = {
  __typename: "ModelCategoriaTerapeuticaConnection",
  items:  Array<CategoriaTerapeutica | null >,
  nextToken?: string | null,
};

export type ModelIniciativaFilterInput = {
  id?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelIniciativaFilterInput | null > | null,
  or?: Array< ModelIniciativaFilterInput | null > | null,
  not?: ModelIniciativaFilterInput | null,
};

export type ModelIniciativaConnection = {
  __typename: "ModelIniciativaConnection",
  items:  Array<Iniciativa | null >,
  nextToken?: string | null,
};

export type ModelFormaCosmeticaFarmaceuticaFilterInput = {
  id?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  and?: Array< ModelFormaCosmeticaFarmaceuticaFilterInput | null > | null,
  or?: Array< ModelFormaCosmeticaFarmaceuticaFilterInput | null > | null,
  not?: ModelFormaCosmeticaFarmaceuticaFilterInput | null,
};

export type ModelFormaCosmeticaFarmaceuticaConnection = {
  __typename: "ModelFormaCosmeticaFarmaceuticaConnection",
  items:  Array<FormaCosmeticaFarmaceutica | null >,
  nextToken?: string | null,
};

export type ModelBusinessUnitFilterInput = {
  id?: ModelIDInput | null,
  codigo?: ModelStringInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelBusinessUnitFilterInput | null > | null,
  or?: Array< ModelBusinessUnitFilterInput | null > | null,
  not?: ModelBusinessUnitFilterInput | null,
};

export type ModelBusinessUnitConnection = {
  __typename: "ModelBusinessUnitConnection",
  items:  Array<BusinessUnit | null >,
  nextToken?: string | null,
};

export type ModelCategoriaFilterInput = {
  id?: ModelIDInput | null,
  codigo?: ModelStringInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelCategoriaFilterInput | null > | null,
  or?: Array< ModelCategoriaFilterInput | null > | null,
  not?: ModelCategoriaFilterInput | null,
};

export type ModelCategoriaConnection = {
  __typename: "ModelCategoriaConnection",
  items:  Array<Categoria | null >,
  nextToken?: string | null,
};

export type ModelFabricanteFilterInput = {
  id?: ModelIDInput | null,
  paisId?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  direccion?: ModelStringInput | null,
  vencimientoBPM?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  and?: Array< ModelFabricanteFilterInput | null > | null,
  or?: Array< ModelFabricanteFilterInput | null > | null,
  not?: ModelFabricanteFilterInput | null,
};

export type ModelFabricanteConnection = {
  __typename: "ModelFabricanteConnection",
  items:  Array<Fabricante | null >,
  nextToken?: string | null,
};

export type ModelPrincipioActivoFilterInput = {
  id?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  historico?: ModelBooleanInput | null,
  and?: Array< ModelPrincipioActivoFilterInput | null > | null,
  or?: Array< ModelPrincipioActivoFilterInput | null > | null,
  not?: ModelPrincipioActivoFilterInput | null,
};

export type ModelPrincipioActivoConnection = {
  __typename: "ModelPrincipioActivoConnection",
  items:  Array<PrincipioActivo | null >,
  nextToken?: string | null,
};

export type ModelPaisFilterInput = {
  id?: ModelIDInput | null,
  codigo?: ModelStringInput | null,
  nombre?: ModelStringInput | null,
  and?: Array< ModelPaisFilterInput | null > | null,
  or?: Array< ModelPaisFilterInput | null > | null,
  not?: ModelPaisFilterInput | null,
};

export type ModelPaisConnection = {
  __typename: "ModelPaisConnection",
  items:  Array<Pais | null >,
  nextToken?: string | null,
};

export type ModelAutoridadFilterInput = {
  id?: ModelIDInput | null,
  nombre?: ModelStringInput | null,
  paisId?: ModelIDInput | null,
  and?: Array< ModelAutoridadFilterInput | null > | null,
  or?: Array< ModelAutoridadFilterInput | null > | null,
  not?: ModelAutoridadFilterInput | null,
};

export type ModelAutoridadConnection = {
  __typename: "ModelAutoridadConnection",
  items:  Array<Autoridad | null >,
  nextToken?: string | null,
};

export type ModelDashboardTramiteDailyFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  type?: ModelTipoDashboardTramiteDailyInput | null,
  and?: Array< ModelDashboardTramiteDailyFilterInput | null > | null,
  or?: Array< ModelDashboardTramiteDailyFilterInput | null > | null,
  not?: ModelDashboardTramiteDailyFilterInput | null,
};

export type ModelDashboardTramiteDailyConnection = {
  __typename: "ModelDashboardTramiteDailyConnection",
  items:  Array<DashboardTramiteDaily | null >,
  nextToken?: string | null,
};

export type ModelDashboardTramiteYearlyFilterInput = {
  id?: ModelIDInput | null,
  year?: ModelIntInput | null,
  type?: ModelTipoDashboardTramiteYearlyInput | null,
  registros?: ModelIntInput | null,
  tramitesAprobados?: ModelIntInput | null,
  tramitesRechazados?: ModelIntInput | null,
  and?: Array< ModelDashboardTramiteYearlyFilterInput | null > | null,
  or?: Array< ModelDashboardTramiteYearlyFilterInput | null > | null,
  not?: ModelDashboardTramiteYearlyFilterInput | null,
};

export type ModelDashboardTramiteYearlyConnection = {
  __typename: "ModelDashboardTramiteYearlyConnection",
  items:  Array<DashboardTramiteYearly | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelNotificacionFilterInput = {
  id?: ModelIDInput | null,
  tipo?: ModelTipoNotificacionInput | null,
  destinatario?: ModelStringInput | null,
  objectId?: ModelStringInput | null,
  enviado?: ModelStringInput | null,
  leido?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificacionFilterInput | null > | null,
  or?: Array< ModelNotificacionFilterInput | null > | null,
  not?: ModelNotificacionFilterInput | null,
};

export type ModelNotificacionConnection = {
  __typename: "ModelNotificacionConnection",
  items:  Array<Notificacion | null >,
  nextToken?: string | null,
};

export type ModelNotificacionByDestinatarioCompositeKeyConditionInput = {
  eq?: ModelNotificacionByDestinatarioCompositeKeyInput | null,
  le?: ModelNotificacionByDestinatarioCompositeKeyInput | null,
  lt?: ModelNotificacionByDestinatarioCompositeKeyInput | null,
  ge?: ModelNotificacionByDestinatarioCompositeKeyInput | null,
  gt?: ModelNotificacionByDestinatarioCompositeKeyInput | null,
  between?: Array< ModelNotificacionByDestinatarioCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificacionByDestinatarioCompositeKeyInput | null,
};

export type ModelNotificacionByDestinatarioCompositeKeyInput = {
  leido?: string | null,
  createdAt?: string | null,
};

export type ModelUserConfigFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  rol?: ModelStringInput | null,
  paises?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelUserConfigFilterInput | null > | null,
  or?: Array< ModelUserConfigFilterInput | null > | null,
  not?: ModelUserConfigFilterInput | null,
};

export type ModelUserConfigConnection = {
  __typename: "ModelUserConfigConnection",
  items:  Array<UserConfig | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionCodigoFormulaSearchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  codigoFormulaId?: ModelSubscriptionIDInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  searchCodigo?: ModelSubscriptionStringInput | null,
  searchMarca?: ModelSubscriptionStringInput | null,
  searchProducto?: ModelSubscriptionStringInput | null,
  searchBu?: ModelSubscriptionStringInput | null,
  searchCategoriaTerapeutica?: ModelSubscriptionStringInput | null,
  searchFormaCosmeticaFarmaceutica?: ModelSubscriptionStringInput | null,
  searchCategoria?: ModelSubscriptionStringInput | null,
  searchPrincipiosActivos?: ModelSubscriptionStringInput | null,
  searchSourcingUnits?: ModelSubscriptionStringInput | null,
  searchFabricantes?: ModelSubscriptionStringInput | null,
  searchPaisesDestino?: ModelSubscriptionStringInput | null,
  searchPresentaciones?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCodigoFormulaSearchFilterInput | null > | null,
  or?: Array< ModelSubscriptionCodigoFormulaSearchFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionCodigoFormulaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  codigo?: ModelSubscriptionStringInput | null,
  marca?: ModelSubscriptionStringInput | null,
  producto?: ModelSubscriptionStringInput | null,
  presentaciones?: ModelSubscriptionStringInput | null,
  dossierCompleteness?: ModelSubscriptionFloatInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  modifiedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCodigoFormulaFilterInput | null > | null,
  or?: Array< ModelSubscriptionCodigoFormulaFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTramiteSearchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tipo?: ModelSubscriptionStringInput | null,
  estado?: ModelSubscriptionStringInput | null,
  tramiteId?: ModelSubscriptionIDInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  searchIniciativa?: ModelSubscriptionStringInput | null,
  searchPaisDestino?: ModelSubscriptionStringInput | null,
  searchCodigos?: ModelSubscriptionStringInput | null,
  searchMarcas?: ModelSubscriptionStringInput | null,
  searchBus?: ModelSubscriptionStringInput | null,
  searchCategoriasTerapeuticas?: ModelSubscriptionStringInput | null,
  searchFormasCosmeticasFarmaceuticas?: ModelSubscriptionStringInput | null,
  searchCategorias?: ModelSubscriptionStringInput | null,
  searchPrincipiosActivos?: ModelSubscriptionStringInput | null,
  searchSourcingUnits?: ModelSubscriptionStringInput | null,
  searchProductos?: ModelSubscriptionStringInput | null,
  searchPresentaciones?: ModelSubscriptionStringInput | null,
  searchFabricantes?: ModelSubscriptionStringInput | null,
  searchEtiqueta?: ModelSubscriptionStringInput | null,
  searchCodigoCPT?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTramiteSearchFilterInput | null > | null,
  or?: Array< ModelSubscriptionTramiteSearchFilterInput | null > | null,
};

export type ModelSubscriptionTramiteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  descripcion?: ModelSubscriptionStringInput | null,
  tipo?: ModelSubscriptionStringInput | null,
  innovacion?: ModelSubscriptionBooleanInput | null,
  estado?: ModelSubscriptionStringInput | null,
  fechaPresentacion?: ModelSubscriptionStringInput | null,
  fechaPresentacionObjetivo?: ModelSubscriptionStringInput | null,
  fechaObjetivo?: ModelSubscriptionStringInput | null,
  fechaObtencion?: ModelSubscriptionStringInput | null,
  fechaLanzamiento?: ModelSubscriptionStringInput | null,
  fechaLanzamientoObjetivo?: ModelSubscriptionStringInput | null,
  fechaLiberacion?: ModelSubscriptionStringInput | null,
  fechaLiberacionObjetivo?: ModelSubscriptionStringInput | null,
  costosDirectos?: ModelSubscriptionStringInput | null,
  costosIndirectos?: ModelSubscriptionStringInput | null,
  comentarios?: ModelSubscriptionStringInput | null,
  rechazoMotivo?: ModelSubscriptionStringInput | null,
  rechazoRA?: ModelSubscriptionBooleanInput | null,
  numeroRegistro?: ModelSubscriptionStringInput | null,
  vencimientoSanitario?: ModelSubscriptionStringInput | null,
  dossierPreferences?: ModelSubscriptionStringInput | null,
  dossierCompleteness?: ModelSubscriptionFloatInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  codigoCPT?: ModelSubscriptionStringInput | null,
  etiqueta?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  modifiedBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  notificacionesWatchList?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTramiteFilterInput | null > | null,
  or?: Array< ModelSubscriptionTramiteFilterInput | null > | null,
};

export type ModelSubscriptionRegistroSearchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  registroId?: ModelSubscriptionIDInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  searchIniciativa?: ModelSubscriptionStringInput | null,
  searchPaisDestino?: ModelSubscriptionStringInput | null,
  searchCodigos?: ModelSubscriptionStringInput | null,
  searchMarcas?: ModelSubscriptionStringInput | null,
  searchBus?: ModelSubscriptionStringInput | null,
  searchCategoriasTerapeuticas?: ModelSubscriptionStringInput | null,
  searchFormasCosmeticasFarmaceuticas?: ModelSubscriptionStringInput | null,
  searchCategorias?: ModelSubscriptionStringInput | null,
  searchPrincipiosActivos?: ModelSubscriptionStringInput | null,
  searchSourcingUnits?: ModelSubscriptionStringInput | null,
  searchProductos?: ModelSubscriptionStringInput | null,
  searchPresentaciones?: ModelSubscriptionStringInput | null,
  searchFabricantes?: ModelSubscriptionStringInput | null,
  searchRegistro?: ModelSubscriptionStringInput | null,
  searchEstatusVenta?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRegistroSearchFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegistroSearchFilterInput | null > | null,
};

export type ModelSubscriptionRegistroFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  numeroRegistro?: ModelSubscriptionStringInput | null,
  vencimientoSanitario?: ModelSubscriptionStringInput | null,
  inicioRenovacion?: ModelSubscriptionStringInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  modifiedBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  notificacionesWatchList?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRegistroFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegistroFilterInput | null > | null,
};

export type ModelSubscriptionRegistroCambioFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tipo?: ModelSubscriptionStringInput | null,
  objectId?: ModelSubscriptionStringInput | null,
  usuario?: ModelSubscriptionStringInput | null,
  cambios?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRegistroCambioFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegistroCambioFilterInput | null > | null,
};

export type ModelSubscriptionCategoriaTerapeuticaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoriaTerapeuticaFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoriaTerapeuticaFilterInput | null > | null,
};

export type ModelSubscriptionIniciativaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIniciativaFilterInput | null > | null,
  or?: Array< ModelSubscriptionIniciativaFilterInput | null > | null,
};

export type ModelSubscriptionFormaCosmeticaFarmaceuticaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionFormaCosmeticaFarmaceuticaFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormaCosmeticaFarmaceuticaFilterInput | null > | null,
};

export type ModelSubscriptionBusinessUnitFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  codigo?: ModelSubscriptionStringInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBusinessUnitFilterInput | null > | null,
  or?: Array< ModelSubscriptionBusinessUnitFilterInput | null > | null,
};

export type ModelSubscriptionCategoriaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  codigo?: ModelSubscriptionStringInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoriaFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoriaFilterInput | null > | null,
};

export type ModelSubscriptionFabricanteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  paisId?: ModelSubscriptionIDInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  direccion?: ModelSubscriptionStringInput | null,
  vencimientoBPM?: ModelSubscriptionStringInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionFabricanteFilterInput | null > | null,
  or?: Array< ModelSubscriptionFabricanteFilterInput | null > | null,
};

export type ModelSubscriptionPrincipioActivoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  historico?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPrincipioActivoFilterInput | null > | null,
  or?: Array< ModelSubscriptionPrincipioActivoFilterInput | null > | null,
};

export type ModelSubscriptionPaisFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  codigo?: ModelSubscriptionStringInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaisFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaisFilterInput | null > | null,
};

export type ModelSubscriptionAutoridadFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nombre?: ModelSubscriptionStringInput | null,
  paisId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionAutoridadFilterInput | null > | null,
  or?: Array< ModelSubscriptionAutoridadFilterInput | null > | null,
};

export type ModelSubscriptionDashboardTramiteDailyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDashboardTramiteDailyFilterInput | null > | null,
  or?: Array< ModelSubscriptionDashboardTramiteDailyFilterInput | null > | null,
};

export type ModelSubscriptionDashboardTramiteYearlyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  year?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  registros?: ModelSubscriptionIntInput | null,
  tramitesAprobados?: ModelSubscriptionIntInput | null,
  tramitesRechazados?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDashboardTramiteYearlyFilterInput | null > | null,
  or?: Array< ModelSubscriptionDashboardTramiteYearlyFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionNotificacionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tipo?: ModelSubscriptionStringInput | null,
  destinatario?: ModelSubscriptionStringInput | null,
  objectId?: ModelSubscriptionStringInput | null,
  enviado?: ModelSubscriptionStringInput | null,
  leido?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificacionFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificacionFilterInput | null > | null,
};

export type ModelSubscriptionUserConfigFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  rol?: ModelSubscriptionStringInput | null,
  paises?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUserConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserConfigFilterInput | null > | null,
};

export type CreateCodigoFormulaSearchMutationVariables = {
  input: CreateCodigoFormulaSearchInput,
  condition?: ModelCodigoFormulaSearchConditionInput | null,
};

export type CreateCodigoFormulaSearchMutation = {
  createCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCodigoFormulaSearchMutationVariables = {
  input: UpdateCodigoFormulaSearchInput,
  condition?: ModelCodigoFormulaSearchConditionInput | null,
};

export type UpdateCodigoFormulaSearchMutation = {
  updateCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCodigoFormulaSearchMutationVariables = {
  input: DeleteCodigoFormulaSearchInput,
  condition?: ModelCodigoFormulaSearchConditionInput | null,
};

export type DeleteCodigoFormulaSearchMutation = {
  deleteCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCodigoFormulaMutationVariables = {
  input: CreateCodigoFormulaInput,
  condition?: ModelCodigoFormulaConditionInput | null,
};

export type CreateCodigoFormulaMutation = {
  createCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCodigoFormulaMutationVariables = {
  input: UpdateCodigoFormulaInput,
  condition?: ModelCodigoFormulaConditionInput | null,
};

export type UpdateCodigoFormulaMutation = {
  updateCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCodigoFormulaMutationVariables = {
  input: DeleteCodigoFormulaInput,
  condition?: ModelCodigoFormulaConditionInput | null,
};

export type DeleteCodigoFormulaMutation = {
  deleteCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTramiteSearchMutationVariables = {
  input: CreateTramiteSearchInput,
  condition?: ModelTramiteSearchConditionInput | null,
};

export type CreateTramiteSearchMutation = {
  createTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type UpdateTramiteSearchMutationVariables = {
  input: UpdateTramiteSearchInput,
  condition?: ModelTramiteSearchConditionInput | null,
};

export type UpdateTramiteSearchMutation = {
  updateTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type DeleteTramiteSearchMutationVariables = {
  input: DeleteTramiteSearchInput,
  condition?: ModelTramiteSearchConditionInput | null,
};

export type DeleteTramiteSearchMutation = {
  deleteTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type CreateTramiteMutationVariables = {
  input: CreateTramiteInput,
  condition?: ModelTramiteConditionInput | null,
};

export type CreateTramiteMutation = {
  createTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type UpdateTramiteMutationVariables = {
  input: UpdateTramiteInput,
  condition?: ModelTramiteConditionInput | null,
};

export type UpdateTramiteMutation = {
  updateTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type DeleteTramiteMutationVariables = {
  input: DeleteTramiteInput,
  condition?: ModelTramiteConditionInput | null,
};

export type DeleteTramiteMutation = {
  deleteTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type CreateRegistroSearchMutationVariables = {
  input: CreateRegistroSearchInput,
  condition?: ModelRegistroSearchConditionInput | null,
};

export type CreateRegistroSearchMutation = {
  createRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRegistroSearchMutationVariables = {
  input: UpdateRegistroSearchInput,
  condition?: ModelRegistroSearchConditionInput | null,
};

export type UpdateRegistroSearchMutation = {
  updateRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRegistroSearchMutationVariables = {
  input: DeleteRegistroSearchInput,
  condition?: ModelRegistroSearchConditionInput | null,
};

export type DeleteRegistroSearchMutation = {
  deleteRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRegistroMutationVariables = {
  input: CreateRegistroInput,
  condition?: ModelRegistroConditionInput | null,
};

export type CreateRegistroMutation = {
  createRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type UpdateRegistroMutationVariables = {
  input: UpdateRegistroInput,
  condition?: ModelRegistroConditionInput | null,
};

export type UpdateRegistroMutation = {
  updateRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type DeleteRegistroMutationVariables = {
  input: DeleteRegistroInput,
  condition?: ModelRegistroConditionInput | null,
};

export type DeleteRegistroMutation = {
  deleteRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type CreateRegistroCambioMutationVariables = {
  input: CreateRegistroCambioInput,
  condition?: ModelRegistroCambioConditionInput | null,
};

export type CreateRegistroCambioMutation = {
  createRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type UpdateRegistroCambioMutationVariables = {
  input: UpdateRegistroCambioInput,
  condition?: ModelRegistroCambioConditionInput | null,
};

export type UpdateRegistroCambioMutation = {
  updateRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type DeleteRegistroCambioMutationVariables = {
  input: DeleteRegistroCambioInput,
  condition?: ModelRegistroCambioConditionInput | null,
};

export type DeleteRegistroCambioMutation = {
  deleteRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type CreateCategoriaTerapeuticaMutationVariables = {
  input: CreateCategoriaTerapeuticaInput,
  condition?: ModelCategoriaTerapeuticaConditionInput | null,
};

export type CreateCategoriaTerapeuticaMutation = {
  createCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoriaTerapeuticaMutationVariables = {
  input: UpdateCategoriaTerapeuticaInput,
  condition?: ModelCategoriaTerapeuticaConditionInput | null,
};

export type UpdateCategoriaTerapeuticaMutation = {
  updateCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoriaTerapeuticaMutationVariables = {
  input: DeleteCategoriaTerapeuticaInput,
  condition?: ModelCategoriaTerapeuticaConditionInput | null,
};

export type DeleteCategoriaTerapeuticaMutation = {
  deleteCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIniciativaMutationVariables = {
  input: CreateIniciativaInput,
  condition?: ModelIniciativaConditionInput | null,
};

export type CreateIniciativaMutation = {
  createIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIniciativaMutationVariables = {
  input: UpdateIniciativaInput,
  condition?: ModelIniciativaConditionInput | null,
};

export type UpdateIniciativaMutation = {
  updateIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIniciativaMutationVariables = {
  input: DeleteIniciativaInput,
  condition?: ModelIniciativaConditionInput | null,
};

export type DeleteIniciativaMutation = {
  deleteIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFormaCosmeticaFarmaceuticaMutationVariables = {
  input: CreateFormaCosmeticaFarmaceuticaInput,
  condition?: ModelFormaCosmeticaFarmaceuticaConditionInput | null,
};

export type CreateFormaCosmeticaFarmaceuticaMutation = {
  createFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFormaCosmeticaFarmaceuticaMutationVariables = {
  input: UpdateFormaCosmeticaFarmaceuticaInput,
  condition?: ModelFormaCosmeticaFarmaceuticaConditionInput | null,
};

export type UpdateFormaCosmeticaFarmaceuticaMutation = {
  updateFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFormaCosmeticaFarmaceuticaMutationVariables = {
  input: DeleteFormaCosmeticaFarmaceuticaInput,
  condition?: ModelFormaCosmeticaFarmaceuticaConditionInput | null,
};

export type DeleteFormaCosmeticaFarmaceuticaMutation = {
  deleteFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessUnitMutationVariables = {
  input: CreateBusinessUnitInput,
  condition?: ModelBusinessUnitConditionInput | null,
};

export type CreateBusinessUnitMutation = {
  createBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessUnitMutationVariables = {
  input: UpdateBusinessUnitInput,
  condition?: ModelBusinessUnitConditionInput | null,
};

export type UpdateBusinessUnitMutation = {
  updateBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessUnitMutationVariables = {
  input: DeleteBusinessUnitInput,
  condition?: ModelBusinessUnitConditionInput | null,
};

export type DeleteBusinessUnitMutation = {
  deleteBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoriaMutationVariables = {
  input: CreateCategoriaInput,
  condition?: ModelCategoriaConditionInput | null,
};

export type CreateCategoriaMutation = {
  createCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoriaMutationVariables = {
  input: UpdateCategoriaInput,
  condition?: ModelCategoriaConditionInput | null,
};

export type UpdateCategoriaMutation = {
  updateCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoriaMutationVariables = {
  input: DeleteCategoriaInput,
  condition?: ModelCategoriaConditionInput | null,
};

export type DeleteCategoriaMutation = {
  deleteCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFabricanteMutationVariables = {
  input: CreateFabricanteInput,
  condition?: ModelFabricanteConditionInput | null,
};

export type CreateFabricanteMutation = {
  createFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFabricanteMutationVariables = {
  input: UpdateFabricanteInput,
  condition?: ModelFabricanteConditionInput | null,
};

export type UpdateFabricanteMutation = {
  updateFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFabricanteMutationVariables = {
  input: DeleteFabricanteInput,
  condition?: ModelFabricanteConditionInput | null,
};

export type DeleteFabricanteMutation = {
  deleteFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePrincipioActivoMutationVariables = {
  input: CreatePrincipioActivoInput,
  condition?: ModelPrincipioActivoConditionInput | null,
};

export type CreatePrincipioActivoMutation = {
  createPrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePrincipioActivoMutationVariables = {
  input: UpdatePrincipioActivoInput,
  condition?: ModelPrincipioActivoConditionInput | null,
};

export type UpdatePrincipioActivoMutation = {
  updatePrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePrincipioActivoMutationVariables = {
  input: DeletePrincipioActivoInput,
  condition?: ModelPrincipioActivoConditionInput | null,
};

export type DeletePrincipioActivoMutation = {
  deletePrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePaisMutationVariables = {
  input: CreatePaisInput,
  condition?: ModelPaisConditionInput | null,
};

export type CreatePaisMutation = {
  createPais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePaisMutationVariables = {
  input: UpdatePaisInput,
  condition?: ModelPaisConditionInput | null,
};

export type UpdatePaisMutation = {
  updatePais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePaisMutationVariables = {
  input: DeletePaisInput,
  condition?: ModelPaisConditionInput | null,
};

export type DeletePaisMutation = {
  deletePais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAutoridadMutationVariables = {
  input: CreateAutoridadInput,
  condition?: ModelAutoridadConditionInput | null,
};

export type CreateAutoridadMutation = {
  createAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAutoridadMutationVariables = {
  input: UpdateAutoridadInput,
  condition?: ModelAutoridadConditionInput | null,
};

export type UpdateAutoridadMutation = {
  updateAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAutoridadMutationVariables = {
  input: DeleteAutoridadInput,
  condition?: ModelAutoridadConditionInput | null,
};

export type DeleteAutoridadMutation = {
  deleteAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDashboardTramiteDailyMutationVariables = {
  input: CreateDashboardTramiteDailyInput,
  condition?: ModelDashboardTramiteDailyConditionInput | null,
};

export type CreateDashboardTramiteDailyMutation = {
  createDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDashboardTramiteDailyMutationVariables = {
  input: UpdateDashboardTramiteDailyInput,
  condition?: ModelDashboardTramiteDailyConditionInput | null,
};

export type UpdateDashboardTramiteDailyMutation = {
  updateDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDashboardTramiteDailyMutationVariables = {
  input: DeleteDashboardTramiteDailyInput,
  condition?: ModelDashboardTramiteDailyConditionInput | null,
};

export type DeleteDashboardTramiteDailyMutation = {
  deleteDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDashboardTramiteYearlyMutationVariables = {
  input: CreateDashboardTramiteYearlyInput,
  condition?: ModelDashboardTramiteYearlyConditionInput | null,
};

export type CreateDashboardTramiteYearlyMutation = {
  createDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDashboardTramiteYearlyMutationVariables = {
  input: UpdateDashboardTramiteYearlyInput,
  condition?: ModelDashboardTramiteYearlyConditionInput | null,
};

export type UpdateDashboardTramiteYearlyMutation = {
  updateDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDashboardTramiteYearlyMutationVariables = {
  input: DeleteDashboardTramiteYearlyInput,
  condition?: ModelDashboardTramiteYearlyConditionInput | null,
};

export type DeleteDashboardTramiteYearlyMutation = {
  deleteDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificacionMutationVariables = {
  input: CreateNotificacionInput,
  condition?: ModelNotificacionConditionInput | null,
};

export type CreateNotificacionMutation = {
  createNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateNotificacionMutationVariables = {
  input: UpdateNotificacionInput,
  condition?: ModelNotificacionConditionInput | null,
};

export type UpdateNotificacionMutation = {
  updateNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteNotificacionMutationVariables = {
  input: DeleteNotificacionInput,
  condition?: ModelNotificacionConditionInput | null,
};

export type DeleteNotificacionMutation = {
  deleteNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateUserConfigMutationVariables = {
  input: CreateUserConfigInput,
  condition?: ModelUserConfigConditionInput | null,
};

export type CreateUserConfigMutation = {
  createUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserConfigMutationVariables = {
  input: UpdateUserConfigInput,
  condition?: ModelUserConfigConditionInput | null,
};

export type UpdateUserConfigMutation = {
  updateUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserConfigMutationVariables = {
  input: DeleteUserConfigInput,
  condition?: ModelUserConfigConditionInput | null,
};

export type DeleteUserConfigMutation = {
  deleteUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SaveFormulaMutationVariables = {
  input?: SaveFormulaInput | null,
};

export type SaveFormulaMutation = {
  saveFormula?:  {
    __typename: "MutationResponse",
    statusCode?: string | null,
    objectId?: string | null,
    errors?: Array< string | null > | null,
  } | null,
};

export type SaveTramiteMutationVariables = {
  input?: SaveTramiteInput | null,
};

export type SaveTramiteMutation = {
  saveTramite?:  {
    __typename: "MutationResponse",
    statusCode?: string | null,
    objectId?: string | null,
    errors?: Array< string | null > | null,
  } | null,
};

export type SaveRegistroMutationVariables = {
  input?: SaveRegistroInput | null,
};

export type SaveRegistroMutation = {
  saveRegistro?:  {
    __typename: "MutationResponse",
    statusCode?: string | null,
    objectId?: string | null,
    errors?: Array< string | null > | null,
  } | null,
};

export type GetCodigoFormulaSearchQueryVariables = {
  id: string,
};

export type GetCodigoFormulaSearchQuery = {
  getCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCodigoFormulaSearchesQueryVariables = {
  filter?: ModelCodigoFormulaSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCodigoFormulaSearchesQuery = {
  listCodigoFormulaSearches?:  {
    __typename: "ModelCodigoFormulaSearchConnection",
    items:  Array< {
      __typename: "CodigoFormulaSearch",
      id: string,
      codigoFormula:  {
        __typename: "CodigoFormula",
        id: string,
        codigo: string,
        marca?: string | null,
        producto?: string | null,
        presentaciones?: string | null,
        dossierCompleteness?: number | null,
        historico?: boolean | null,
        deleted?: boolean | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      codigoFormulaId: string,
      historico?: boolean | null,
      deleted?: boolean | null,
      searchCodigo?: string | null,
      searchMarca?: string | null,
      searchProducto?: string | null,
      searchBu?: string | null,
      searchCategoriaTerapeutica?: string | null,
      searchFormaCosmeticaFarmaceutica?: string | null,
      searchCategoria?: string | null,
      searchPrincipiosActivos?: string | null,
      searchSourcingUnits?: string | null,
      searchFabricantes?: string | null,
      searchPaisesDestino?: string | null,
      searchPresentaciones?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCodigoFormulaQueryVariables = {
  id: string,
};

export type GetCodigoFormulaQuery = {
  getCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCodigoFormulasQueryVariables = {
  filter?: ModelCodigoFormulaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCodigoFormulasQuery = {
  listCodigoFormulas?:  {
    __typename: "ModelCodigoFormulaConnection",
    items:  Array< {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTramiteSearchQueryVariables = {
  id: string,
};

export type GetTramiteSearchQuery = {
  getTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type ListTramiteSearchesQueryVariables = {
  filter?: ModelTramiteSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTramiteSearchesQuery = {
  listTramiteSearches?:  {
    __typename: "ModelTramiteSearchConnection",
    items:  Array< {
      __typename: "TramiteSearch",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      tramite:  {
        __typename: "Tramite",
        id: string,
        descripcion?: string | null,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        estado: EstadoTramite,
        fechaPresentacion?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        fechaObtencion?: string | null,
        fechaLanzamiento?: string | null,
        fechaLanzamientoObjetivo?: string | null,
        fechaLiberacion?: string | null,
        fechaLiberacionObjetivo?: string | null,
        costosDirectos?: string | null,
        costosIndirectos?: string | null,
        comentarios?: string | null,
        rechazoMotivo?: string | null,
        rechazoRA?: boolean | null,
        numeroRegistro?: string | null,
        vencimientoSanitario?: string | null,
        dossierPreferences?: string | null,
        dossierCompleteness?: number | null,
        historico?: boolean | null,
        deleted?: boolean | null,
        codigoCPT?: string | null,
        etiqueta?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        updatedAt?: string | null,
        notificacionesWatchList?: string | null,
        createdAt: string,
      },
      tramiteId: string,
      historico?: boolean | null,
      deleted?: boolean | null,
      updatedAt?: string | null,
      searchIniciativa?: string | null,
      searchPaisDestino?: string | null,
      searchCodigos?: string | null,
      searchMarcas?: string | null,
      searchBus?: string | null,
      searchCategoriasTerapeuticas?: string | null,
      searchFormasCosmeticasFarmaceuticas?: string | null,
      searchCategorias?: string | null,
      searchPrincipiosActivos?: string | null,
      searchSourcingUnits?: string | null,
      searchProductos?: string | null,
      searchPresentaciones?: string | null,
      searchFabricantes?: string | null,
      searchEtiqueta?: string | null,
      searchCodigoCPT?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TramiteSearchByTipoQueryVariables = {
  tipo: TipoTramite,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTramiteSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TramiteSearchByTipoQuery = {
  tramiteSearchByTipo?:  {
    __typename: "ModelTramiteSearchConnection",
    items:  Array< {
      __typename: "TramiteSearch",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      tramite:  {
        __typename: "Tramite",
        id: string,
        descripcion?: string | null,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        estado: EstadoTramite,
        fechaPresentacion?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        fechaObtencion?: string | null,
        fechaLanzamiento?: string | null,
        fechaLanzamientoObjetivo?: string | null,
        fechaLiberacion?: string | null,
        fechaLiberacionObjetivo?: string | null,
        costosDirectos?: string | null,
        costosIndirectos?: string | null,
        comentarios?: string | null,
        rechazoMotivo?: string | null,
        rechazoRA?: boolean | null,
        numeroRegistro?: string | null,
        vencimientoSanitario?: string | null,
        dossierPreferences?: string | null,
        dossierCompleteness?: number | null,
        historico?: boolean | null,
        deleted?: boolean | null,
        codigoCPT?: string | null,
        etiqueta?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        updatedAt?: string | null,
        notificacionesWatchList?: string | null,
        createdAt: string,
      },
      tramiteId: string,
      historico?: boolean | null,
      deleted?: boolean | null,
      updatedAt?: string | null,
      searchIniciativa?: string | null,
      searchPaisDestino?: string | null,
      searchCodigos?: string | null,
      searchMarcas?: string | null,
      searchBus?: string | null,
      searchCategoriasTerapeuticas?: string | null,
      searchFormasCosmeticasFarmaceuticas?: string | null,
      searchCategorias?: string | null,
      searchPrincipiosActivos?: string | null,
      searchSourcingUnits?: string | null,
      searchProductos?: string | null,
      searchPresentaciones?: string | null,
      searchFabricantes?: string | null,
      searchEtiqueta?: string | null,
      searchCodigoCPT?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTramiteQueryVariables = {
  id: string,
};

export type GetTramiteQuery = {
  getTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type ListTramitesQueryVariables = {
  filter?: ModelTramiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTramitesQuery = {
  listTramites?:  {
    __typename: "ModelTramiteConnection",
    items:  Array< {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TramiteByWatchlistQueryVariables = {
  notificacionesWatchList: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTramiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TramiteByWatchlistQuery = {
  tramiteByWatchlist?:  {
    __typename: "ModelTramiteConnection",
    items:  Array< {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRegistroSearchQueryVariables = {
  id: string,
};

export type GetRegistroSearchQuery = {
  getRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRegistroSearchesQueryVariables = {
  filter?: ModelRegistroSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistroSearchesQuery = {
  listRegistroSearches?:  {
    __typename: "ModelRegistroSearchConnection",
    items:  Array< {
      __typename: "RegistroSearch",
      id: string,
      registro:  {
        __typename: "Registro",
        id: string,
        numeroRegistro?: string | null,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        historico?: boolean | null,
        deleted?: boolean | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        updatedAt?: string | null,
        notificacionesWatchList: string,
        createdAt: string,
      },
      registroId: string,
      historico?: boolean | null,
      deleted?: boolean | null,
      searchIniciativa?: string | null,
      searchPaisDestino?: string | null,
      searchCodigos?: string | null,
      searchMarcas?: string | null,
      searchBus?: string | null,
      searchCategoriasTerapeuticas?: string | null,
      searchFormasCosmeticasFarmaceuticas?: string | null,
      searchCategorias?: string | null,
      searchPrincipiosActivos?: string | null,
      searchSourcingUnits?: string | null,
      searchProductos?: string | null,
      searchPresentaciones?: string | null,
      searchFabricantes?: string | null,
      searchRegistro?: string | null,
      searchEstatusVenta?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRegistroQueryVariables = {
  id: string,
};

export type GetRegistroQuery = {
  getRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type ListRegistrosQueryVariables = {
  filter?: ModelRegistroFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrosQuery = {
  listRegistros?:  {
    __typename: "ModelRegistroConnection",
    items:  Array< {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RegistroByWatchlistQueryVariables = {
  notificacionesWatchList: string,
  vencimientoSanitario?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistroFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegistroByWatchlistQuery = {
  registroByWatchlist?:  {
    __typename: "ModelRegistroConnection",
    items:  Array< {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRegistroCambioQueryVariables = {
  id: string,
};

export type GetRegistroCambioQuery = {
  getRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type ListRegistroCambiosQueryVariables = {
  filter?: ModelRegistroCambioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistroCambiosQuery = {
  listRegistroCambios?:  {
    __typename: "ModelRegistroCambioConnection",
    items:  Array< {
      __typename: "RegistroCambio",
      id: string,
      tipo: string,
      objectId: string,
      usuario?: string | null,
      cambios?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RegistroCambioByObjectAndDateQueryVariables = {
  objectId: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistroCambioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegistroCambioByObjectAndDateQuery = {
  registroCambioByObjectAndDate?:  {
    __typename: "ModelRegistroCambioConnection",
    items:  Array< {
      __typename: "RegistroCambio",
      id: string,
      tipo: string,
      objectId: string,
      usuario?: string | null,
      cambios?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoriaTerapeuticaQueryVariables = {
  id: string,
};

export type GetCategoriaTerapeuticaQuery = {
  getCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategoriaTerapeuticasQueryVariables = {
  filter?: ModelCategoriaTerapeuticaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriaTerapeuticasQuery = {
  listCategoriaTerapeuticas?:  {
    __typename: "ModelCategoriaTerapeuticaConnection",
    items:  Array< {
      __typename: "CategoriaTerapeutica",
      id: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIniciativaQueryVariables = {
  id: string,
};

export type GetIniciativaQuery = {
  getIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIniciativasQueryVariables = {
  filter?: ModelIniciativaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIniciativasQuery = {
  listIniciativas?:  {
    __typename: "ModelIniciativaConnection",
    items:  Array< {
      __typename: "Iniciativa",
      id: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFormaCosmeticaFarmaceuticaQueryVariables = {
  id: string,
};

export type GetFormaCosmeticaFarmaceuticaQuery = {
  getFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFormaCosmeticaFarmaceuticasQueryVariables = {
  filter?: ModelFormaCosmeticaFarmaceuticaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormaCosmeticaFarmaceuticasQuery = {
  listFormaCosmeticaFarmaceuticas?:  {
    __typename: "ModelFormaCosmeticaFarmaceuticaConnection",
    items:  Array< {
      __typename: "FormaCosmeticaFarmaceutica",
      id: string,
      nombre: string,
      historico?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessUnitQueryVariables = {
  id: string,
};

export type GetBusinessUnitQuery = {
  getBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessUnitsQueryVariables = {
  filter?: ModelBusinessUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessUnitsQuery = {
  listBusinessUnits?:  {
    __typename: "ModelBusinessUnitConnection",
    items:  Array< {
      __typename: "BusinessUnit",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoriaQueryVariables = {
  id: string,
};

export type GetCategoriaQuery = {
  getCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategoriasQueryVariables = {
  filter?: ModelCategoriaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriasQuery = {
  listCategorias?:  {
    __typename: "ModelCategoriaConnection",
    items:  Array< {
      __typename: "Categoria",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFabricanteQueryVariables = {
  id: string,
};

export type GetFabricanteQuery = {
  getFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFabricantesQueryVariables = {
  filter?: ModelFabricanteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFabricantesQuery = {
  listFabricantes?:  {
    __typename: "ModelFabricanteConnection",
    items:  Array< {
      __typename: "Fabricante",
      id: string,
      pais:  {
        __typename: "Pais",
        id: string,
        codigo: string,
        nombre: string,
        createdAt: string,
        updatedAt: string,
      },
      paisId: string,
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
      historico?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPrincipioActivoQueryVariables = {
  id: string,
};

export type GetPrincipioActivoQuery = {
  getPrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPrincipioActivosQueryVariables = {
  filter?: ModelPrincipioActivoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrincipioActivosQuery = {
  listPrincipioActivos?:  {
    __typename: "ModelPrincipioActivoConnection",
    items:  Array< {
      __typename: "PrincipioActivo",
      id: string,
      nombre: string,
      historico?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaisQueryVariables = {
  id: string,
};

export type GetPaisQuery = {
  getPais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPaisQueryVariables = {
  filter?: ModelPaisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaisQuery = {
  listPais?:  {
    __typename: "ModelPaisConnection",
    items:  Array< {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAutoridadQueryVariables = {
  id: string,
};

export type GetAutoridadQuery = {
  getAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAutoridadsQueryVariables = {
  filter?: ModelAutoridadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAutoridadsQuery = {
  listAutoridads?:  {
    __typename: "ModelAutoridadConnection",
    items:  Array< {
      __typename: "Autoridad",
      id: string,
      nombre: string,
      pais:  {
        __typename: "Pais",
        id: string,
        codigo: string,
        nombre: string,
        createdAt: string,
        updatedAt: string,
      },
      paisId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDashboardTramiteDailyQueryVariables = {
  id: string,
};

export type GetDashboardTramiteDailyQuery = {
  getDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDashboardTramiteDailiesQueryVariables = {
  filter?: ModelDashboardTramiteDailyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDashboardTramiteDailiesQuery = {
  listDashboardTramiteDailies?:  {
    __typename: "ModelDashboardTramiteDailyConnection",
    items:  Array< {
      __typename: "DashboardTramiteDaily",
      id: string,
      date: string,
      type: TipoDashboardTramiteDaily,
      efectividadAprobados?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      efectividadRechazados?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      efectividadDesistidos?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      efectividadEnCurso?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      sometimientosOnTime?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      sometimientosVencidos?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      sometimientosEnCurso?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      objecionesEnCurso?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      objecionesDesistidas?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      objecionesAFavor?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      objecionesEnContra?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      estatusArmado?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusEnDesarrollo?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusSinDossier?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusEnProceso?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusAprobado?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusRechazado?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusDesistido?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DashboardDailyByTypeAndDateQueryVariables = {
  type: TipoDashboardTramiteDaily,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDashboardTramiteDailyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DashboardDailyByTypeAndDateQuery = {
  dashboardDailyByTypeAndDate?:  {
    __typename: "ModelDashboardTramiteDailyConnection",
    items:  Array< {
      __typename: "DashboardTramiteDaily",
      id: string,
      date: string,
      type: TipoDashboardTramiteDaily,
      efectividadAprobados?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      efectividadRechazados?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      efectividadDesistidos?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      efectividadEnCurso?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      sometimientosOnTime?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      sometimientosVencidos?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      sometimientosEnCurso?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      objecionesEnCurso?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      objecionesDesistidas?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      objecionesAFavor?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      objecionesEnContra?:  Array< {
        __typename: "DashboardObjecionVO",
        id: string,
      } | null > | null,
      estatusArmado?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusEnDesarrollo?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusSinDossier?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusEnProceso?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusAprobado?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusRechazado?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      estatusDesistido?:  Array< {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDashboardTramiteYearlyQueryVariables = {
  id: string,
};

export type GetDashboardTramiteYearlyQuery = {
  getDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDashboardTramiteYearliesQueryVariables = {
  filter?: ModelDashboardTramiteYearlyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDashboardTramiteYearliesQuery = {
  listDashboardTramiteYearlies?:  {
    __typename: "ModelDashboardTramiteYearlyConnection",
    items:  Array< {
      __typename: "DashboardTramiteYearly",
      id: string,
      year: number,
      type: TipoDashboardTramiteYearly,
      registros?: number | null,
      tramitesAprobados?: number | null,
      tramitesRechazados?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DashboardYearlyByTypeAndYearQueryVariables = {
  type: TipoDashboardTramiteYearly,
  year?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDashboardTramiteYearlyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DashboardYearlyByTypeAndYearQuery = {
  dashboardYearlyByTypeAndYear?:  {
    __typename: "ModelDashboardTramiteYearlyConnection",
    items:  Array< {
      __typename: "DashboardTramiteYearly",
      id: string,
      year: number,
      type: TipoDashboardTramiteYearly,
      registros?: number | null,
      tramitesAprobados?: number | null,
      tramitesRechazados?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificacionQueryVariables = {
  id: string,
};

export type GetNotificacionQuery = {
  getNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListNotificacionsQueryVariables = {
  filter?: ModelNotificacionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificacionsQuery = {
  listNotificacions?:  {
    __typename: "ModelNotificacionConnection",
    items:  Array< {
      __typename: "Notificacion",
      id: string,
      tipo: TipoNotificacion,
      destinatario: string,
      objectId: string,
      objectData?:  {
        __typename: "NotificacionData",
        id: string,
        codigoTramite?: string | null,
        codigoFormula?: string | null,
        numeroRegistro?: string | null,
        tipoTramite?: TipoTramite | null,
        fechaLiberacionObjetivo?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        tipoCertificado?: TipoCertificado | null,
        pais?: string | null,
        autoridad?: string | null,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        vencimientoCertificado?: string | null,
      } | null,
      enviado: string,
      leido: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificacionesByDestinatarioQueryVariables = {
  destinatario: string,
  leidoCreatedAt?: ModelNotificacionByDestinatarioCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificacionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificacionesByDestinatarioQuery = {
  notificacionesByDestinatario?:  {
    __typename: "ModelNotificacionConnection",
    items:  Array< {
      __typename: "Notificacion",
      id: string,
      tipo: TipoNotificacion,
      destinatario: string,
      objectId: string,
      objectData?:  {
        __typename: "NotificacionData",
        id: string,
        codigoTramite?: string | null,
        codigoFormula?: string | null,
        numeroRegistro?: string | null,
        tipoTramite?: TipoTramite | null,
        fechaLiberacionObjetivo?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        tipoCertificado?: TipoCertificado | null,
        pais?: string | null,
        autoridad?: string | null,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        vencimientoCertificado?: string | null,
      } | null,
      enviado: string,
      leido: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificacionesByObjectIdQueryVariables = {
  objectId: string,
  destinatario?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificacionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificacionesByObjectIdQuery = {
  notificacionesByObjectId?:  {
    __typename: "ModelNotificacionConnection",
    items:  Array< {
      __typename: "Notificacion",
      id: string,
      tipo: TipoNotificacion,
      destinatario: string,
      objectId: string,
      objectData?:  {
        __typename: "NotificacionData",
        id: string,
        codigoTramite?: string | null,
        codigoFormula?: string | null,
        numeroRegistro?: string | null,
        tipoTramite?: TipoTramite | null,
        fechaLiberacionObjetivo?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        tipoCertificado?: TipoCertificado | null,
        pais?: string | null,
        autoridad?: string | null,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        vencimientoCertificado?: string | null,
      } | null,
      enviado: string,
      leido: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificacionesByEnviadoQueryVariables = {
  enviado: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificacionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificacionesByEnviadoQuery = {
  notificacionesByEnviado?:  {
    __typename: "ModelNotificacionConnection",
    items:  Array< {
      __typename: "Notificacion",
      id: string,
      tipo: TipoNotificacion,
      destinatario: string,
      objectId: string,
      objectData?:  {
        __typename: "NotificacionData",
        id: string,
        codigoTramite?: string | null,
        codigoFormula?: string | null,
        numeroRegistro?: string | null,
        tipoTramite?: TipoTramite | null,
        fechaLiberacionObjetivo?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        tipoCertificado?: TipoCertificado | null,
        pais?: string | null,
        autoridad?: string | null,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        vencimientoCertificado?: string | null,
      } | null,
      enviado: string,
      leido: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificacionesByLeidoQueryVariables = {
  leido: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificacionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificacionesByLeidoQuery = {
  notificacionesByLeido?:  {
    __typename: "ModelNotificacionConnection",
    items:  Array< {
      __typename: "Notificacion",
      id: string,
      tipo: TipoNotificacion,
      destinatario: string,
      objectId: string,
      objectData?:  {
        __typename: "NotificacionData",
        id: string,
        codigoTramite?: string | null,
        codigoFormula?: string | null,
        numeroRegistro?: string | null,
        tipoTramite?: TipoTramite | null,
        fechaLiberacionObjetivo?: string | null,
        fechaPresentacionObjetivo?: string | null,
        fechaObjetivo?: string | null,
        tipoCertificado?: TipoCertificado | null,
        pais?: string | null,
        autoridad?: string | null,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        vencimientoCertificado?: string | null,
      } | null,
      enviado: string,
      leido: string,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserConfigQueryVariables = {
  id: string,
};

export type GetUserConfigQuery = {
  getUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserConfigsQueryVariables = {
  filter?: ModelUserConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserConfigsQuery = {
  listUserConfigs?:  {
    __typename: "ModelUserConfigConnection",
    items:  Array< {
      __typename: "UserConfig",
      id: string,
      email: string,
      rol: string,
      paises?: Array< string | null > | null,
      enabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserConfigByEmailQueryVariables = {
  email: string,
  rol?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserConfigByEmailQuery = {
  userConfigByEmail?:  {
    __typename: "ModelUserConfigConnection",
    items:  Array< {
      __typename: "UserConfig",
      id: string,
      email: string,
      rol: string,
      paises?: Array< string | null > | null,
      enabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCodigoFormulaSearchSubscriptionVariables = {
  filter?: ModelSubscriptionCodigoFormulaSearchFilterInput | null,
};

export type OnCreateCodigoFormulaSearchSubscription = {
  onCreateCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCodigoFormulaSearchSubscriptionVariables = {
  filter?: ModelSubscriptionCodigoFormulaSearchFilterInput | null,
};

export type OnUpdateCodigoFormulaSearchSubscription = {
  onUpdateCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCodigoFormulaSearchSubscriptionVariables = {
  filter?: ModelSubscriptionCodigoFormulaSearchFilterInput | null,
};

export type OnDeleteCodigoFormulaSearchSubscription = {
  onDeleteCodigoFormulaSearch?:  {
    __typename: "CodigoFormulaSearch",
    id: string,
    codigoFormula:  {
      __typename: "CodigoFormula",
      id: string,
      codigo: string,
      marca?: string | null,
      bu?:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      producto?: string | null,
      presentaciones?: string | null,
      categoria?:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
      tramites?:  Array< {
        __typename: "FormulaTramiteVO",
        id: string,
        tipo: TipoTramite,
        estado: EstadoTramite,
        dossierCompleteness?: number | null,
      } | null > | null,
      registros?:  Array< {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null > | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      dossierCompleteness?: number | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    codigoFormulaId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchCodigo?: string | null,
    searchMarca?: string | null,
    searchProducto?: string | null,
    searchBu?: string | null,
    searchCategoriaTerapeutica?: string | null,
    searchFormaCosmeticaFarmaceutica?: string | null,
    searchCategoria?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchFabricantes?: string | null,
    searchPaisesDestino?: string | null,
    searchPresentaciones?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCodigoFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionCodigoFormulaFilterInput | null,
};

export type OnCreateCodigoFormulaSubscription = {
  onCreateCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCodigoFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionCodigoFormulaFilterInput | null,
};

export type OnUpdateCodigoFormulaSubscription = {
  onUpdateCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCodigoFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionCodigoFormulaFilterInput | null,
};

export type OnDeleteCodigoFormulaSubscription = {
  onDeleteCodigoFormula?:  {
    __typename: "CodigoFormula",
    id: string,
    codigo: string,
    marca?: string | null,
    bu?:  {
      __typename: "BusinessUnitVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    categoriaTerapeutica?:  {
      __typename: "CategoriaTerapeuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    formaCosmeticaFarmaceutica?:  {
      __typename: "FormaCosmeticaFarmaceuticaVO",
      id?: string | null,
      nombre: string,
    } | null,
    producto?: string | null,
    presentaciones?: string | null,
    categoria?:  {
      __typename: "CategoriaVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    fabricantes?:  Array< {
      __typename: "FabricanteVO",
      id?: string | null,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      nombre: string,
      direccion?: string | null,
      vencimientoBPM?: string | null,
    } | null > | null,
    principiosActivos?:  Array< {
      __typename: "RegistroPrincipioActivoVO",
      principio:  {
        __typename: "PrincipioActivoVO",
        id?: string | null,
        nombre: string,
      },
      concentracion?: string | null,
      fabricantes?:  Array< {
        __typename: "FabricanteVO",
        id?: string | null,
        nombre: string,
        direccion?: string | null,
        vencimientoBPM?: string | null,
      } | null > | null,
    } | null > | null,
    tramites?:  Array< {
      __typename: "FormulaTramiteVO",
      id: string,
      tipo: TipoTramite,
      estado: EstadoTramite,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      dossierCompleteness?: number | null,
    } | null > | null,
    registros?:  Array< {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null > | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    dossierCompleteness?: number | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTramiteSearchSubscriptionVariables = {
  filter?: ModelSubscriptionTramiteSearchFilterInput | null,
};

export type OnCreateTramiteSearchSubscription = {
  onCreateTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type OnUpdateTramiteSearchSubscriptionVariables = {
  filter?: ModelSubscriptionTramiteSearchFilterInput | null,
};

export type OnUpdateTramiteSearchSubscription = {
  onUpdateTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type OnDeleteTramiteSearchSubscriptionVariables = {
  filter?: ModelSubscriptionTramiteSearchFilterInput | null,
};

export type OnDeleteTramiteSearchSubscription = {
  onDeleteTramiteSearch?:  {
    __typename: "TramiteSearch",
    id: string,
    tipo: TipoTramite,
    estado: EstadoTramite,
    tramite:  {
      __typename: "Tramite",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      paisReceptor?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      descripcion?: string | null,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      estado: EstadoTramite,
      fechaPresentacion?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      fechaObtencion?: string | null,
      fechaLanzamiento?: string | null,
      fechaLanzamientoObjetivo?: string | null,
      fechaLiberacion?: string | null,
      fechaLiberacionObjetivo?: string | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      prioridad?:  {
        __typename: "PrioridadVO",
        id: string,
        nombre: string,
      } | null,
      costosDirectos?: string | null,
      costosIndirectos?: string | null,
      comentarios?: string | null,
      rechazoMotivo?: string | null,
      rechazoRA?: boolean | null,
      numeroRegistro?: string | null,
      vencimientoSanitario?: string | null,
      medioDifusion?:  {
        __typename: "MedioDifusionVO",
        id: MedioDifusion,
        nombre: string,
      } | null,
      tipoCertificado?:  {
        __typename: "TipoCertificadoVO",
        id: TipoCertificado,
        nombre: string,
      } | null,
      objeciones?:  Array< {
        __typename: "ObjecionVO",
        id?: string | null,
        estado?: EstadoObjecion | null,
        objecion: string,
        asignableRA?: boolean | null,
        fechaIngreso?: string | null,
        fechaObjecion?: string | null,
        limiteEspera?: string | null,
        descripcion?: string | null,
        fechaRespuesta?: string | null,
        exitoso?: boolean | null,
        fechaCierre?: string | null,
      } | null > | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      registroModificado?:  {
        __typename: "FormulaRegistroVO",
        id: string,
        vencimientoSanitario?: string | null,
        inicioRenovacion?: string | null,
        numeroRegistro?: string | null,
      } | null,
      dossierPreferences?: string | null,
      dossierCompleteness?: number | null,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      codigoCPT?: string | null,
      etiqueta?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      formulaDossierData?:  Array< {
        __typename: "FormulaDossierData",
        id: string,
      } | null > | null,
      updatedAt?: string | null,
      notificacionesWatchList?: string | null,
      createdAt: string,
    },
    tramiteId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    updatedAt?: string | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchEtiqueta?: string | null,
    searchCodigoCPT?: string | null,
    createdAt: string,
  } | null,
};

export type OnCreateTramiteSubscriptionVariables = {
  filter?: ModelSubscriptionTramiteFilterInput | null,
};

export type OnCreateTramiteSubscription = {
  onCreateTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type OnUpdateTramiteSubscriptionVariables = {
  filter?: ModelSubscriptionTramiteFilterInput | null,
};

export type OnUpdateTramiteSubscription = {
  onUpdateTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type OnDeleteTramiteSubscriptionVariables = {
  filter?: ModelSubscriptionTramiteFilterInput | null,
};

export type OnDeleteTramiteSubscription = {
  onDeleteTramite?:  {
    __typename: "Tramite",
    id: string,
    pais:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    },
    paisReceptor?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    descripcion?: string | null,
    tipo: TipoTramite,
    innovacion?: boolean | null,
    estado: EstadoTramite,
    fechaPresentacion?: string | null,
    fechaPresentacionObjetivo?: string | null,
    fechaObjetivo?: string | null,
    fechaObtencion?: string | null,
    fechaLanzamiento?: string | null,
    fechaLanzamientoObjetivo?: string | null,
    fechaLiberacion?: string | null,
    fechaLiberacionObjetivo?: string | null,
    iniciativa?:  {
      __typename: "IniciativaVO",
      id?: string | null,
      nombre: string,
    } | null,
    prioridad?:  {
      __typename: "PrioridadVO",
      id: string,
      nombre: string,
    } | null,
    costosDirectos?: string | null,
    costosIndirectos?: string | null,
    comentarios?: string | null,
    rechazoMotivo?: string | null,
    rechazoRA?: boolean | null,
    numeroRegistro?: string | null,
    vencimientoSanitario?: string | null,
    medioDifusion?:  {
      __typename: "MedioDifusionVO",
      id: MedioDifusion,
      nombre: string,
    } | null,
    tipoCertificado?:  {
      __typename: "TipoCertificadoVO",
      id: TipoCertificado,
      nombre: string,
    } | null,
    objeciones?:  Array< {
      __typename: "ObjecionVO",
      id?: string | null,
      estado?: EstadoObjecion | null,
      objecion: string,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      asignableRA?: boolean | null,
      fechaIngreso?: string | null,
      fechaObjecion?: string | null,
      limiteEspera?: string | null,
      descripcion?: string | null,
      fechaRespuesta?: string | null,
      exitoso?: boolean | null,
      fechaCierre?: string | null,
    } | null > | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    registroModificado?:  {
      __typename: "FormulaRegistroVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      numeroRegistro?: string | null,
    } | null,
    dossierPreferences?: string | null,
    dossierCompleteness?: number | null,
    dossierData?:  Array< {
      __typename: "DossierField",
      codigo: string,
      done?: boolean | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    codigoCPT?: string | null,
    etiqueta?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    formulaDossierData?:  Array< {
      __typename: "FormulaDossierData",
      id: string,
      dossierData?:  Array< {
        __typename: "DossierField",
        codigo: string,
        done?: boolean | null,
      } | null > | null,
    } | null > | null,
    updatedAt?: string | null,
    notificacionesWatchList?: string | null,
    createdAt: string,
  } | null,
};

export type OnCreateRegistroSearchSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroSearchFilterInput | null,
};

export type OnCreateRegistroSearchSubscription = {
  onCreateRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRegistroSearchSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroSearchFilterInput | null,
};

export type OnUpdateRegistroSearchSubscription = {
  onUpdateRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRegistroSearchSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroSearchFilterInput | null,
};

export type OnDeleteRegistroSearchSubscription = {
  onDeleteRegistroSearch?:  {
    __typename: "RegistroSearch",
    id: string,
    registro:  {
      __typename: "Registro",
      id: string,
      numeroRegistro?: string | null,
      pais?:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      } | null,
      autoridad?:  {
        __typename: "AutoridadVO",
        id?: string | null,
        nombre: string,
      } | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      formulas?:  Array< {
        __typename: "RegistroFormulaVO",
        id: string,
        codigo: string,
        marca: string,
      } | null > | null,
      presentaciones?:  Array< {
        __typename: "RegistroPresentacionesVO",
        presentaciones?: string | null,
        producto?: string | null,
        formulas?: Array< string | null > | null,
      } | null > | null,
      historico?: boolean | null,
      deleted?: boolean | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      updatedAt?: string | null,
      notificacionesWatchList: string,
      createdAt: string,
    },
    registroId: string,
    historico?: boolean | null,
    deleted?: boolean | null,
    searchIniciativa?: string | null,
    searchPaisDestino?: string | null,
    searchCodigos?: string | null,
    searchMarcas?: string | null,
    searchBus?: string | null,
    searchCategoriasTerapeuticas?: string | null,
    searchFormasCosmeticasFarmaceuticas?: string | null,
    searchCategorias?: string | null,
    searchPrincipiosActivos?: string | null,
    searchSourcingUnits?: string | null,
    searchProductos?: string | null,
    searchPresentaciones?: string | null,
    searchFabricantes?: string | null,
    searchRegistro?: string | null,
    searchEstatusVenta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRegistroSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroFilterInput | null,
};

export type OnCreateRegistroSubscription = {
  onCreateRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type OnUpdateRegistroSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroFilterInput | null,
};

export type OnUpdateRegistroSubscription = {
  onUpdateRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type OnDeleteRegistroSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroFilterInput | null,
};

export type OnDeleteRegistroSubscription = {
  onDeleteRegistro?:  {
    __typename: "Registro",
    id: string,
    numeroRegistro?: string | null,
    pais?:  {
      __typename: "PaisVO",
      id?: string | null,
      codigo: string,
      nombre: string,
    } | null,
    autoridad?:  {
      __typename: "AutoridadVO",
      id?: string | null,
      nombre: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
    } | null,
    vencimientoSanitario?: string | null,
    inicioRenovacion?: string | null,
    formulas?:  Array< {
      __typename: "RegistroFormulaVO",
      id: string,
      codigo: string,
      marca: string,
      bu:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoria:  {
        __typename: "CategoriaVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      categoriaTerapeutica?:  {
        __typename: "CategoriaTerapeuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formaCosmeticaFarmaceutica?:  {
        __typename: "FormaCosmeticaFarmaceuticaVO",
        id?: string | null,
        nombre: string,
      } | null,
      condicionVenta?:  {
        __typename: "CondicionVentaVO",
        id?: CondicionVenta | null,
        nombre: string,
      } | null,
      principiosActivos?:  Array< {
        __typename: "RegistroPrincipioActivoVO",
        concentracion?: string | null,
      } | null > | null,
    } | null > | null,
    presentaciones?:  Array< {
      __typename: "RegistroPresentacionesVO",
      presentaciones?: string | null,
      producto?: string | null,
      fabricantes?:  Array< {
        __typename: "RegistroFabricanteVO",
      } | null > | null,
      formulas?: Array< string | null > | null,
    } | null > | null,
    historico?: boolean | null,
    deleted?: boolean | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    updatedAt?: string | null,
    notificacionesWatchList: string,
    createdAt: string,
  } | null,
};

export type OnCreateRegistroCambioSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroCambioFilterInput | null,
};

export type OnCreateRegistroCambioSubscription = {
  onCreateRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type OnUpdateRegistroCambioSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroCambioFilterInput | null,
};

export type OnUpdateRegistroCambioSubscription = {
  onUpdateRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type OnDeleteRegistroCambioSubscriptionVariables = {
  filter?: ModelSubscriptionRegistroCambioFilterInput | null,
};

export type OnDeleteRegistroCambioSubscription = {
  onDeleteRegistroCambio?:  {
    __typename: "RegistroCambio",
    id: string,
    tipo: string,
    objectId: string,
    usuario?: string | null,
    cambios?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type OnCreateCategoriaTerapeuticaSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriaTerapeuticaFilterInput | null,
};

export type OnCreateCategoriaTerapeuticaSubscription = {
  onCreateCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategoriaTerapeuticaSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriaTerapeuticaFilterInput | null,
};

export type OnUpdateCategoriaTerapeuticaSubscription = {
  onUpdateCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategoriaTerapeuticaSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriaTerapeuticaFilterInput | null,
};

export type OnDeleteCategoriaTerapeuticaSubscription = {
  onDeleteCategoriaTerapeutica?:  {
    __typename: "CategoriaTerapeutica",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIniciativaSubscriptionVariables = {
  filter?: ModelSubscriptionIniciativaFilterInput | null,
};

export type OnCreateIniciativaSubscription = {
  onCreateIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIniciativaSubscriptionVariables = {
  filter?: ModelSubscriptionIniciativaFilterInput | null,
};

export type OnUpdateIniciativaSubscription = {
  onUpdateIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIniciativaSubscriptionVariables = {
  filter?: ModelSubscriptionIniciativaFilterInput | null,
};

export type OnDeleteIniciativaSubscription = {
  onDeleteIniciativa?:  {
    __typename: "Iniciativa",
    id: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFormaCosmeticaFarmaceuticaSubscriptionVariables = {
  filter?: ModelSubscriptionFormaCosmeticaFarmaceuticaFilterInput | null,
};

export type OnCreateFormaCosmeticaFarmaceuticaSubscription = {
  onCreateFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFormaCosmeticaFarmaceuticaSubscriptionVariables = {
  filter?: ModelSubscriptionFormaCosmeticaFarmaceuticaFilterInput | null,
};

export type OnUpdateFormaCosmeticaFarmaceuticaSubscription = {
  onUpdateFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFormaCosmeticaFarmaceuticaSubscriptionVariables = {
  filter?: ModelSubscriptionFormaCosmeticaFarmaceuticaFilterInput | null,
};

export type OnDeleteFormaCosmeticaFarmaceuticaSubscription = {
  onDeleteFormaCosmeticaFarmaceutica?:  {
    __typename: "FormaCosmeticaFarmaceutica",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessUnitSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessUnitFilterInput | null,
};

export type OnCreateBusinessUnitSubscription = {
  onCreateBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessUnitSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessUnitFilterInput | null,
};

export type OnUpdateBusinessUnitSubscription = {
  onUpdateBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessUnitSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessUnitFilterInput | null,
};

export type OnDeleteBusinessUnitSubscription = {
  onDeleteBusinessUnit?:  {
    __typename: "BusinessUnit",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategoriaSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriaFilterInput | null,
};

export type OnCreateCategoriaSubscription = {
  onCreateCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategoriaSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriaFilterInput | null,
};

export type OnUpdateCategoriaSubscription = {
  onUpdateCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategoriaSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriaFilterInput | null,
};

export type OnDeleteCategoriaSubscription = {
  onDeleteCategoria?:  {
    __typename: "Categoria",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFabricanteSubscriptionVariables = {
  filter?: ModelSubscriptionFabricanteFilterInput | null,
};

export type OnCreateFabricanteSubscription = {
  onCreateFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFabricanteSubscriptionVariables = {
  filter?: ModelSubscriptionFabricanteFilterInput | null,
};

export type OnUpdateFabricanteSubscription = {
  onUpdateFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFabricanteSubscriptionVariables = {
  filter?: ModelSubscriptionFabricanteFilterInput | null,
};

export type OnDeleteFabricanteSubscription = {
  onDeleteFabricante?:  {
    __typename: "Fabricante",
    id: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    nombre: string,
    direccion?: string | null,
    vencimientoBPM?: string | null,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePrincipioActivoSubscriptionVariables = {
  filter?: ModelSubscriptionPrincipioActivoFilterInput | null,
};

export type OnCreatePrincipioActivoSubscription = {
  onCreatePrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePrincipioActivoSubscriptionVariables = {
  filter?: ModelSubscriptionPrincipioActivoFilterInput | null,
};

export type OnUpdatePrincipioActivoSubscription = {
  onUpdatePrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePrincipioActivoSubscriptionVariables = {
  filter?: ModelSubscriptionPrincipioActivoFilterInput | null,
};

export type OnDeletePrincipioActivoSubscription = {
  onDeletePrincipioActivo?:  {
    __typename: "PrincipioActivo",
    id: string,
    nombre: string,
    historico?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePaisSubscriptionVariables = {
  filter?: ModelSubscriptionPaisFilterInput | null,
};

export type OnCreatePaisSubscription = {
  onCreatePais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePaisSubscriptionVariables = {
  filter?: ModelSubscriptionPaisFilterInput | null,
};

export type OnUpdatePaisSubscription = {
  onUpdatePais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePaisSubscriptionVariables = {
  filter?: ModelSubscriptionPaisFilterInput | null,
};

export type OnDeletePaisSubscription = {
  onDeletePais?:  {
    __typename: "Pais",
    id: string,
    codigo: string,
    nombre: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAutoridadSubscriptionVariables = {
  filter?: ModelSubscriptionAutoridadFilterInput | null,
};

export type OnCreateAutoridadSubscription = {
  onCreateAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAutoridadSubscriptionVariables = {
  filter?: ModelSubscriptionAutoridadFilterInput | null,
};

export type OnUpdateAutoridadSubscription = {
  onUpdateAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAutoridadSubscriptionVariables = {
  filter?: ModelSubscriptionAutoridadFilterInput | null,
};

export type OnDeleteAutoridadSubscription = {
  onDeleteAutoridad?:  {
    __typename: "Autoridad",
    id: string,
    nombre: string,
    pais:  {
      __typename: "Pais",
      id: string,
      codigo: string,
      nombre: string,
      createdAt: string,
      updatedAt: string,
    },
    paisId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDashboardTramiteDailySubscriptionVariables = {
  filter?: ModelSubscriptionDashboardTramiteDailyFilterInput | null,
};

export type OnCreateDashboardTramiteDailySubscription = {
  onCreateDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDashboardTramiteDailySubscriptionVariables = {
  filter?: ModelSubscriptionDashboardTramiteDailyFilterInput | null,
};

export type OnUpdateDashboardTramiteDailySubscription = {
  onUpdateDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDashboardTramiteDailySubscriptionVariables = {
  filter?: ModelSubscriptionDashboardTramiteDailyFilterInput | null,
};

export type OnDeleteDashboardTramiteDailySubscription = {
  onDeleteDashboardTramiteDaily?:  {
    __typename: "DashboardTramiteDaily",
    id: string,
    date: string,
    type: TipoDashboardTramiteDaily,
    efectividadAprobados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadRechazados?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadDesistidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    efectividadEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosOnTime?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosVencidos?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    sometimientosEnCurso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    objecionesEnCurso?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesDesistidas?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesAFavor?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    objecionesEnContra?:  Array< {
      __typename: "DashboardObjecionVO",
      id: string,
      tramite:  {
        __typename: "DashboardTramiteVO",
        id: string,
        estado: EstadoTramite,
        tipo: TipoTramite,
        innovacion?: boolean | null,
        formula: string,
      },
    } | null > | null,
    estatusArmado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnDesarrollo?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusSinDossier?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusEnProceso?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusAprobado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusRechazado?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    estatusDesistido?:  Array< {
      __typename: "DashboardTramiteVO",
      id: string,
      pais:  {
        __typename: "PaisVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      businessUnit:  {
        __typename: "BusinessUnitVO",
        id?: string | null,
        codigo: string,
        nombre: string,
      },
      estado: EstadoTramite,
      tipo: TipoTramite,
      innovacion?: boolean | null,
      iniciativa?:  {
        __typename: "IniciativaVO",
        id?: string | null,
        nombre: string,
      } | null,
      formula: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDashboardTramiteYearlySubscriptionVariables = {
  filter?: ModelSubscriptionDashboardTramiteYearlyFilterInput | null,
};

export type OnCreateDashboardTramiteYearlySubscription = {
  onCreateDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDashboardTramiteYearlySubscriptionVariables = {
  filter?: ModelSubscriptionDashboardTramiteYearlyFilterInput | null,
};

export type OnUpdateDashboardTramiteYearlySubscription = {
  onUpdateDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDashboardTramiteYearlySubscriptionVariables = {
  filter?: ModelSubscriptionDashboardTramiteYearlyFilterInput | null,
};

export type OnDeleteDashboardTramiteYearlySubscription = {
  onDeleteDashboardTramiteYearly?:  {
    __typename: "DashboardTramiteYearly",
    id: string,
    year: number,
    type: TipoDashboardTramiteYearly,
    registros?: number | null,
    tramitesAprobados?: number | null,
    tramitesRechazados?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificacionSubscriptionVariables = {
  filter?: ModelSubscriptionNotificacionFilterInput | null,
};

export type OnCreateNotificacionSubscription = {
  onCreateNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificacionSubscriptionVariables = {
  filter?: ModelSubscriptionNotificacionFilterInput | null,
};

export type OnUpdateNotificacionSubscription = {
  onUpdateNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificacionSubscriptionVariables = {
  filter?: ModelSubscriptionNotificacionFilterInput | null,
};

export type OnDeleteNotificacionSubscription = {
  onDeleteNotificacion?:  {
    __typename: "Notificacion",
    id: string,
    tipo: TipoNotificacion,
    destinatario: string,
    objectId: string,
    objectData?:  {
      __typename: "NotificacionData",
      id: string,
      codigoTramite?: string | null,
      codigoFormula?: string | null,
      numeroRegistro?: string | null,
      tipoTramite?: TipoTramite | null,
      fechaLiberacionObjetivo?: string | null,
      fechaPresentacionObjetivo?: string | null,
      fechaObjetivo?: string | null,
      tipoCertificado?: TipoCertificado | null,
      pais?: string | null,
      autoridad?: string | null,
      vencimientoSanitario?: string | null,
      inicioRenovacion?: string | null,
      vencimientoCertificado?: string | null,
    } | null,
    enviado: string,
    leido: string,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateUserConfigSubscriptionVariables = {
  filter?: ModelSubscriptionUserConfigFilterInput | null,
};

export type OnCreateUserConfigSubscription = {
  onCreateUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserConfigSubscriptionVariables = {
  filter?: ModelSubscriptionUserConfigFilterInput | null,
};

export type OnUpdateUserConfigSubscription = {
  onUpdateUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserConfigSubscriptionVariables = {
  filter?: ModelSubscriptionUserConfigFilterInput | null,
};

export type OnDeleteUserConfigSubscription = {
  onDeleteUserConfig?:  {
    __typename: "UserConfig",
    id: string,
    email: string,
    rol: string,
    paises?: Array< string | null > | null,
    enabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
