import { Tooltip, styled, tooltipClasses, TooltipProps } from "@mui/material";
import MDTypography from "components/md/MDTypography";

export interface Props {
  truncate: number;
  text: string;
  variant: string;
  color?: string;
}

function CropTooltip(params: Props): JSX.Element {
  const { truncate, text, variant, color } = params;
  let truncatedText = text;
  let truncated = false;
  if (text.length > truncate) {
    truncated = true;
    truncatedText = `${text.substring(0, truncate)}...`;
  }

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  return truncated ? (
    <NoMaxWidthTooltip title={text}>
      <MDTypography variant={variant} color={color}>
        {truncatedText}
      </MDTypography>
    </NoMaxWidthTooltip>
  ) : (
    <MDTypography variant={variant} color={color}>
      {truncatedText}
    </MDTypography>
  );
}

export default CropTooltip;
