import { DashboardTramiteYearly } from "API";
import { getBasePieOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (
  aprobadosInitialData: DashboardTramiteYearly[],
  rechazadosInitialData: DashboardTramiteYearly[]
) => {
  const header = "Trámites";
  const labels: any[] = [];

  // SET LABELS
  if (aprobadosInitialData?.length > 0) {
    aprobadosInitialData.forEach((e: DashboardTramiteYearly) => {
      if (!labels.includes(e.year)) {
        labels.push(e.year);
      }
    });
  }

  if (rechazadosInitialData?.length > 0) {
    rechazadosInitialData.forEach((e: DashboardTramiteYearly) => {
      if (!labels.includes(e.year)) {
        labels.push(e.year);
      }
    });
  }
  labels.sort();

  // BUILD DATASETS
  const aprobadosData: number[] = [];
  const rechazadosData: number[] = [];
  labels.forEach((e) => {
    const aprobado = aprobadosInitialData?.find((a) => a.year === e);
    const rechazado = rechazadosInitialData?.find((a) => a.year === e);

    if (aprobado) {
      aprobadosData.push(aprobado.tramitesAprobados);
    } else {
      aprobadosData.push(0);
    }

    if (rechazado) {
      rechazadosData.push(rechazado.tramitesRechazados);
    } else {
      rechazadosData.push(0);
    }
  });

  const datasets = [
    {
      label: "Trámites aprobados",
      data: aprobadosData,
      borderColor: dashboardColors.green.border,
    },
    {
      label: "Trámites rechazados",
      data: rechazadosData,
      borderColor: dashboardColors.red.border,
    },
  ];

  const data: any = {
    labels,
    datasets,
  };

  return { header, data, options: getBasePieOptions() };
};

export default useData;
