import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";

type Props = {
  formula: any;
};

export default function VerPrincipiosTable(params: Props): JSX.Element {
  const { formula } = params;

  return (
    <TableContainer>
      <Table style={{ width: "100%" }}>
        <MDBox component="thead">
          <TableRow>
            <DataTableHeadCell width="40%" align="left" sorted={false}>
              Principio activo
            </DataTableHeadCell>
            <DataTableHeadCell width="20%" align="left" sorted={false}>
              Concentración
            </DataTableHeadCell>
            <DataTableHeadCell width="40%" align="left" sorted={false}>
              Fabricantes API
            </DataTableHeadCell>
          </TableRow>
        </MDBox>
        <TableBody>
          {formula.principiosActivos?.map((principio: APIt.RegistroPrincipioActivoVO) => (
            <TableRow key={principio.principio.id}>
              <DataTableBodyCell align="left">{principio.principio.nombre}</DataTableBodyCell>
              <DataTableBodyCell align="left">{principio.concentracion}</DataTableBodyCell>
              <DataTableBodyCell align="left">
                {principio.fabricantes?.map((fabricante: APIt.FabricanteVO) => (
                  <MDBox key={fabricante.id}>{fabricante.nombre}</MDBox>
                ))}
              </DataTableBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
