import { UseFormReturn, useWatch } from "react-hook-form";
import * as APIt from "API";
import { gridSizes } from "globalvars";
import { Grid } from "@mui/material";
import FormField from "components/tramites/FormField";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import FormulaYPresentacionCombo from "features/Common/FormulaYPresentacionCombo";

type Props = {
  formMethods: UseFormReturn<APIt.Registro, any>;
};
function DatosGenerales(params: Props): JSX.Element {
  const { formMethods } = params;

  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const {
    state: { autoridadList, userRol },
  } = useTramitesAppStateContext();

  const canEdit = userRol === "Global";

  const watchPais = useWatch({ control, name: "pais" });

  const paises: APIt.PaisVO[] = [];
  const paisesCheck: string[] = [];
  autoridadList?.forEach((autoridad: APIt.Autoridad) => {
    if (!paisesCheck.includes(autoridad.pais.codigo)) {
      paisesCheck.push(autoridad.pais.codigo);
      paises.push(autoridad.pais as any);
    }
  });

  const formulaPicked = !!getValues().id;

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
        <FormField
          label="Número de registro"
          type="text"
          name="numeroRegistro"
          errors={errors.numeroRegistro?.message}
          register={register as any}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
        <FormField
          label="Vencimiento Sanitario"
          type="date"
          name="vencimientoSanitario"
          errors={errors.vencimientoSanitario?.message}
          register={register as any}
          control={control as any}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
        <FormField
          label="Inicio de renovación"
          type="date"
          name="inicioRenovacion"
          errors={errors.inicioRenovacion?.message}
          register={register as any}
          control={control as any}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
        <ControlledAutocomplete
          label="País"
          name="pais"
          errors={errors.pais?.message}
          options={paises}
          control={control as any}
          onChange={() => {
            setValue("autoridad", null);
          }}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
        <ControlledAutocomplete
          label="Autoridad"
          name="autoridad"
          errors={errors.autoridad?.message}
          options={
            autoridadList.filter(
              (autoridad: APIt.Autoridad) => autoridad.pais.id === watchPais?.id
            ) || []
          }
          control={control as any}
          disabled={!canEdit}
        />
      </Grid>
      {!formulaPicked && (
        <FormulaYPresentacionCombo formMethods={formMethods as any} pickPresentacion />
      )}
    </Grid>
  );
}

export default DatosGenerales;
