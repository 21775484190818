import { Grid, Switch } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import { Controller, UseFormReturn } from "react-hook-form";
import RegistroCombo from "features/Common/RegistroCombo";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function ModificacionPicker(data: Props): JSX.Element {
  const { formMethods } = data;
  const { control, getValues } = formMethods;
  const { tipo } = getValues();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} xl={3}>
        <RegistroCombo formMethods={formMethods} />
      </Grid>
      {tipo !== APIt.TipoTramite.renovacion && (
        <Grid item xs={12} sm={4} xl={3}>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 2, sm: 1 }}
          >
            <MDBox mr={1}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>No</Grid>
                <Grid item>
                  <Controller
                    name="innovacion"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item>Si</Grid>
              </Grid>
            </MDBox>
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                ¿Es innovación?
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      )}
    </Grid>
  );
}

export default ModificacionPicker;
