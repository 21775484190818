import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import MDBox from "components/md/MDBox";

export interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  startOpen?: boolean;
}

function SimpleTitledAccordion(params: Props): JSX.Element {
  const { children, header, startOpen } = params;
  const [expanded, setExpanded] = useState(!!startOpen);

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            onClick={() => setExpanded(!expanded)}
            sx={{
              pointerEvents: "auto",
            }}
          />
        }
        sx={{
          pointerEvents: "none",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={8}>
            <MDBox>
              <Grid container direction="row">
                <Grid
                  item
                  onClick={() => setExpanded(!expanded)}
                  sx={{
                    pointerEvents: "auto",
                  }}
                >
                  {header}
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
export default SimpleTitledAccordion;
