import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { createFabricanteMutation, getFabricante } from "services/Fabricante/queries";

const createFabricante = async (fabricante: APIt.Fabricante): Promise<string> => {
  let id: string = "";

  const {
    nombre,
    direccion,
    vencimientoBPM,
    pais: { id: paisId },
  } = fabricante;

  const fabricanteInput: APIt.CreateFabricanteInput = {
    nombre,
    direccion,
    vencimientoBPM,
    paisId,
  };

  const inputVariables: APIt.CreateFabricanteMutationVariables = {
    input: fabricanteInput,
  };

  const createRequest = (await API.graphql(
    graphqlOperation(createFabricanteMutation, inputVariables)
  )) as GraphQLResult<APIt.CreateFabricanteMutation>;

  if (createRequest?.data?.createFabricante) {
    id = createRequest.data.createFabricante.id;
  }

  return id;
};

const fetchFabricante = async (id: string): Promise<APIt.Fabricante> => {
  let fabricante: APIt.Fabricante = null;
  const params: APIt.GetFabricanteQueryVariables = { id };

  const result = (await API.graphql({
    query: getFabricante,
    variables: params,
  })) as GraphQLResult<APIt.GetFabricanteQuery>;

  if (result.data) {
    const query: APIt.GetFabricanteQuery = result.data;
    if (query.getFabricante) {
      fabricante = query.getFabricante;
    }
  }

  return fabricante;
};

export { createFabricante, fetchFabricante };
