import { Card } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { UseFormReturn } from "react-hook-form";
import * as DossierUtils from "services/Utils/DossierUtils";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import DossierGroupAccordion from "./DossierGroupAccordion";
import useDossierPreferencesHook from "./DossierPreferencesHook";
import DossierMasterAccordion from "./DossierMasterAccordion";
import DossierFormulas from "./DossierFormulas";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>;
};

function Dossier(params: Props): JSX.Element {
  const { formMethods, formulaFormMethods } = params;

  if (!formulaFormMethods) return null;

  const { setValue, getValues, watch } = formMethods;
  // const { dossierData } = getValues();
  const dossierData = watch("dossierData");
  const dossierPreferencesString = watch("dossierPreferences");
  const { formulas, estado, pais } = getValues();

  const categorias: string[] = formulas.map(
    (formula: APIt.RegistroFormulaVO) => formula.categoria?.codigo
  );

  let preferences: DossierUtils.PreferencesType = null;
  let categoriasChanged = false;

  if (dossierPreferencesString) {
    preferences = JSON.parse(dossierPreferencesString);
    // FORMULA CATEGORIES CHANGED?
    categoriasChanged = preferences.tramite.categorias.length !== categorias.length;
    preferences.tramite.categorias.forEach((present: string, index: number) => {
      if (present !== categorias[index]) categoriasChanged = true;
    });
  }
  // CONFIGURE DOSSIER PREFERENCES WITH CONFIG, DB VALUES & USER PICKED DETAILS
  useDossierPreferencesHook(formMethods, formulaFormMethods);
  // EXIT IF PREFERENCES ARE NOT YET SET
  if (!dossierPreferencesString || categoriasChanged) {
    return null;
  }

  const updatePreferences = (updatedPreferences: DossierUtils.TramiteDefinitionEntry[]) => {
    const dataCodes = dossierData.map((data: APIt.DossierField) => data.codigo);
    const newData = [...dossierData];
    preferences.tramite.preferences = updatedPreferences.map(
      (entry: DossierUtils.TramiteDefinitionEntry) => {
        let dataIndex = dataCodes.indexOf(entry.code);
        if (dataIndex < 0) {
          dataIndex = newData.length;
          newData.push({ codigo: entry.code, done: false } as APIt.DossierField);
        }
        return { ...entry, dataIndex };
      }
    );
    setValue("dossierPreferences", JSON.stringify(preferences));
    setValue("dossierData", newData);
  };
  const newFieldOptions: DossierUtils.TramiteDefinitionEntry[] = DossierUtils.getExtraFieldOptions(
    preferences.tramite.preferences,
    categorias,
    "Tramite"
  );

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;

  return (
    <MDBox pb={1}>
      <Card>
        <DossierMasterAccordion
          formMethods={formMethods}
          formulaFormMethods={formulaFormMethods}
          preferences={preferences}
        >
          <DossierGroupAccordion
            formMethods={formMethods}
            formMethodsPrefix=""
            dossierGroup={preferences?.tramite.preferences?.find(
              (entry: DossierUtils.TramiteDefinitionEntry) => entry.code === "root"
            )}
            preferences={preferences.tramite.preferences}
            updatePreferences={updatePreferences}
            newFieldOptions={newFieldOptions}
            root
            canEdit={canEdit}
          />
          <DossierFormulas
            formMethods={formMethods}
            formulaFormMethods={formulaFormMethods}
            preferences={preferences}
          />
        </DossierMasterAccordion>
      </Card>
    </MDBox>
  );
}

export default Dossier;
