import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/md/MDButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContentText,
  Divider,
  Grid,
  Icon,
  Switch,
} from "@mui/material";
import ExportCheck from "features/Common/ExportColumnCheck";
import MDTypography from "components/md/MDTypography";

type Props = {
  exportTramites: any;
};

function DownloadExcel(params: Props): JSX.Element {
  const { exportTramites } = params;
  const [open, setOpen] = React.useState(false);
  const [columns, setColumns] = React.useState({
    link: true,
    codigoCPT: true,
    tipo: true,
    estado: true,
    innovacion: true,
    numeroRegistro: false,
    vencimientoSanitario: false,
    etiqueta: false,
    dossierCompleteness: false,
    createdAt: false,
    fechaLiberacion: false,
    fechaLiberacionObjetivo: false,
    fechaPresentacion: false,
    fechaPresentacionObjetivo: false,
    fechaObtencion: false,
    fechaObjetivo: false,
    fechaLanzamiento: false,
    fechaLanzamientoObjetivo: false,
    pais: false,
    autoridad: false,
    iniciativa: false,
    prioridad: false,
    medioDifusion: false,
    tipoCertificado: false,
    costosDirectos: false,
    costosIndirectos: false,
    comentarios: false,
    useFormulasJoined: true,
    // FORMULAS JOINED
    formulasJoined: true,
    marcasJoined: true,
    categoriasJoined: true,
    buJoined: true,
    categoriaTerapeuticaJoined: false,
    formaCosmeticaFarmaceuticaJoined: false,
    principioJoined: false,
    // FORMULAS GROUP
    codigo: false,
    marca: false,
    categoria: false,
    bu: false,
    formaCosmeticaFarmaceutica: false,
    categoriaTerapeutica: false,
    principio: false,
    concentracion: false,
    usePresentacionesJoined: true,
    // PRESENTACIONES JOINED
    productosJoined: true,
    presentacionesJoined: true,
    presentacionesFabricantesJoined: false,
    presentacionesFabricantesAPIJoined: false,
    presentacionesMaquiladoresJoined: false,
    presentacionesAcondicionadoresPrimariosJoined: false,
    presentacionesAcondicionadoresPrimariosComplianceJoined: false,
    presentacionesAcondicionadoresPrimariosEstabilidadJoined: false,
    presentacionesAcondicionadoresPrimariosEstatusVentaJoined: false,
    presentacionesAcondicionadoresPrimariosVidaUtilJoined: false,
    // PRESENTACIONES GROUP
    producto: false,
    presentaciones: false,
    presentacionFabricante: false,
    presentacionFabricanteDirección: false,
    presentacionFabricantePais: false,
    presentacionFabricanteVencimiento: false,
    presentacionFabricanteAPI: false,
    presentacionFabricanteAPIDirección: false,
    presentacionFabricanteAPIPais: false,
    presentacionMaquiladores: false,
    presentacionMaquiladoresDirección: false,
    presentacionMaquiladoresPais: false,
    acondicionadorPrimario: false,
    acondicionadorPrimarioDireccion: false,
    acondicionadorPrimarioPais: false,
    acondicionadorPrimarioCompliance: false,
    acondicionadorPrimarioEstabilidad: false,
    acondicionadorPrimarioEstatusVenta: false,
    acondicionadorPrimarioVidaUtil: false,
    useObjecionJoined: true,
    // OBJECIONES JOINED
    objecionJoined: true,
    objecionFechaJoined: false,
    objecionLimiteEsperaJoined: false,
    objecionAutoridadJoined: false,
    objecionAsignableRAJoined: false,
    objecionFechaRespuestaJoined: false,
    objecionRespuestaJoined: false,
    objecionEstadoJoined: true,
    // OBJECIONES GROUP
    objecion: false,
    fechaObjecion: false,
    limiteEspera: false,
    objecionAutoridad: false,
    asignableRA: false,
    fechaRespuesta: false,
    respuesta: false,
    objecionEstado: false,
  });

  const handleFormulaJoined = () => {
    const useFormulasJoined = !columns.useFormulasJoined;
    if (!useFormulasJoined) {
      setColumns({
        ...columns,
        formulasJoined: false,
        marcasJoined: false,
        categoriasJoined: false,
        buJoined: false,
        categoriaTerapeuticaJoined: false,
        formaCosmeticaFarmaceuticaJoined: false,
        useFormulasJoined,
      });
    } else {
      setColumns({
        ...columns,
        codigo: false,
        marca: false,
        categoria: false,
        bu: false,
        formaCosmeticaFarmaceutica: false,
        categoriaTerapeutica: false,
        principio: false,
        concentracion: false,
        usePresentacionesJoined: true,
        useFormulasJoined,
      });
    }
  };

  const handlePresentacionesJoined = () => {
    const usePresentacionesJoined = !columns.usePresentacionesJoined;
    if (!usePresentacionesJoined) {
      setColumns({
        ...columns,
        productosJoined: false,
        presentacionesJoined: false,
        presentacionesFabricantesJoined: false,
        presentacionesFabricantesAPIJoined: false,
        presentacionesMaquiladoresJoined: false,
        presentacionesAcondicionadoresPrimariosJoined: false,
        presentacionesAcondicionadoresPrimariosComplianceJoined: false,
        presentacionesAcondicionadoresPrimariosEstabilidadJoined: false,
        presentacionesAcondicionadoresPrimariosEstatusVentaJoined: false,
        presentacionesAcondicionadoresPrimariosVidaUtilJoined: false,
        usePresentacionesJoined,
      });
    } else {
      setColumns({
        ...columns,
        producto: false,
        presentaciones: false,
        presentacionFabricante: false,
        presentacionFabricanteDirección: false,
        presentacionFabricantePais: false,
        presentacionFabricanteVencimiento: false,
        presentacionFabricanteAPI: false,
        presentacionFabricanteAPIDirección: false,
        presentacionFabricanteAPIPais: false,
        presentacionMaquiladores: false,
        presentacionMaquiladoresDirección: false,
        presentacionMaquiladoresPais: false,
        acondicionadorPrimario: false,
        acondicionadorPrimarioDireccion: false,
        acondicionadorPrimarioPais: false,
        acondicionadorPrimarioCompliance: false,
        acondicionadorPrimarioEstabilidad: false,
        acondicionadorPrimarioEstatusVenta: false,
        acondicionadorPrimarioVidaUtil: false,
        usePresentacionesJoined,
      });
    }
  };

  const handleObjecionesJoined = () => {
    const useObjecionJoined = !columns.useObjecionJoined;
    if (!useObjecionJoined) {
      setColumns({
        ...columns,
        objecionJoined: false,
        objecionFechaJoined: false,
        objecionLimiteEsperaJoined: false,
        objecionAutoridadJoined: false,
        objecionAsignableRAJoined: false,
        objecionFechaRespuestaJoined: false,
        objecionRespuestaJoined: false,
        objecionEstadoJoined: false,
        useObjecionJoined,
      });
    } else {
      setColumns({
        ...columns,
        objecion: false,
        fechaObjecion: false,
        limiteEspera: false,
        objecionAutoridad: false,
        asignableRA: false,
        fechaRespuesta: false,
        respuesta: false,
        objecionEstado: false,
        useObjecionJoined,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    exportTramites(columns);
    setOpen(false);
  };

  return (
    <div>
      <MDButton
        variant="gradient"
        color="info"
        iconOnly
        onClick={handleClickOpen}
        title="Descargar como Excel"
      >
        <Icon sx={{ fontWeight: "bold" }}>savealt</Icon>
      </MDButton>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle>Descargar como Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione los campos que desea incluir en el archivo descargado.
          </DialogContentText>
          <Divider />
          <Accordion defaultExpanded>
            <AccordionSummary>Datos generales del trámite</AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" spacing={1}>
                <ExportCheck
                  column="codigoCPT"
                  description="Código Identificador"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="tipo"
                  description="Tipo"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="estado"
                  description="Estado"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="innovacion"
                  description="Innovación?"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="numeroRegistro"
                  description="# Registro"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="vencimientoSanitario"
                  description="Venc. Sanitario"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="etiqueta"
                  description="Etiqueta"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="dossierCompleteness"
                  description="% Dossier"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="createdAt"
                  description="Fecha Activación"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaLiberacion"
                  description="Fecha Liberación"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaLiberacionObjetivo"
                  description="Fecha Obj. Liberación"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaPresentacion"
                  description="Fecha Presentación"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaPresentacionObjetivo"
                  description="Fecha Obj. Presentación"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaObtencion"
                  description="Fecha Obtención"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaObjetivo"
                  description="Fecha Obj. Obtención"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaLanzamiento"
                  description="Fecha Lanzamiento"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="fechaLanzamientoObjetivo"
                  description="Fecha Obj. Lanzamiento"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="link"
                  description="Link"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="pais"
                  description="País"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="iniciativa"
                  description="Iniciativa"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="prioridad"
                  description="Prioridad"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="medioDifusion"
                  description="Medio Difusión"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="tipoCertificado"
                  description="Tipo Certificado"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="costosDirectos"
                  description="Costos Directos"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="costosIndirectos"
                  description="Costos Indirectos"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="comentarios"
                  description="Comentarios"
                  setState={setColumns}
                  state={columns}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion>
            <AccordionSummary>Datos de fórmulas</AccordionSummary>
            <Switch checked={columns.useFormulasJoined} onChange={handleFormulaJoined} />
            <MDTypography variant="h7">Agrupar datos datos en una única línea</MDTypography>
            <AccordionDetails>
              {columns.useFormulasJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="formulasJoined"
                    description="Código de fórmula"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="marcasJoined"
                    description="Marca"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="categoriasJoined"
                    description="Categoría"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="buJoined"
                    description="B.U."
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="categoriaTerapeuticaJoined"
                    description="Categoría Terapéutica"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="formaCosmeticaFarmaceuticaJoined"
                    description="FF/FC"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="principioJoined"
                    description="Principio activo"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
              {!columns.useFormulasJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="codigo"
                    description="Código de fórmula"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="marca"
                    description="Marca"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="categoria"
                    description="Categoría"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="bu"
                    description="B.U."
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="categoriaTerapeutica"
                    description="Categoría Terapéutica"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="formaCosmeticaFarmaceutica"
                    description="FF/FC"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="principio"
                    description="Principio activo"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="concentracion"
                    description="Concentración"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Datos de presentaciones</AccordionSummary>
            <Switch
              checked={columns.usePresentacionesJoined}
              onChange={handlePresentacionesJoined}
            />
            <MDTypography variant="h7">Agrupar datos datos en una única línea</MDTypography>
            <AccordionDetails>
              {columns.usePresentacionesJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="productosJoined"
                    description="Producto"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesJoined"
                    description="Presentaciones"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesFabricantesJoined"
                    description="Fabricante PT"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesFabricantesAPIJoined"
                    description="Fabricante API"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesMaquiladoresJoined"
                    description="Maquilador"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesAcondicionadoresPrimariosJoined"
                    description="Acondicionador Primario"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesAcondicionadoresPrimariosComplianceJoined"
                    description="Compliance"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesAcondicionadoresPrimariosEstabilidadJoined"
                    description="Estabilidad"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesAcondicionadoresPrimariosEstatusVentaJoined"
                    description="EStatus Venta"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionesAcondicionadoresPrimariosVidaUtilJoined"
                    description="Vida Útil"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
              {!columns.usePresentacionesJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="producto"
                    description="Producto"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentaciones"
                    description="Presentaciones"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricante"
                    description="Fabricante PT"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricanteDirección"
                    description="Fabricante PT - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricantePais"
                    description="Fabricante PT - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricanteVencimiento"
                    description="Fabricante PT - Vencimiento"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricanteAPI"
                    description="Fabricante API"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricanteAPIDirección"
                    description="Fabricante API - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionFabricanteAPIPais"
                    description="Fabricante API - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionMaquiladores"
                    description="Maquilador"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionMaquiladoresDirección"
                    description="Maquilador - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="presentacionMaquiladoresPais"
                    description="Maquilador - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimario"
                    description="Acondicionador Primario"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimarioDireccion"
                    description="Acondicionador Primario - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimarioPais"
                    description="Acondicionador Primario - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimarioCompliance"
                    description="Compliance"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimarioEstabilidad"
                    description="Estabilidad"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimarioEstatusVenta"
                    description="Estatus Venta"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="acondicionadorPrimarioVidaUtil"
                    description="Vida Útil"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Datos de objeciones</AccordionSummary>
            <Switch checked={columns.useObjecionJoined} onChange={handleObjecionesJoined} />
            <MDTypography variant="h7">Agrupar datos datos en una única línea</MDTypography>
            <AccordionDetails>
              {columns.useObjecionJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="objecionJoined"
                    description="Objeción"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionFechaJoined"
                    description="Fecha Objecion"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionLimiteEsperaJoined"
                    description="Límite espera"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionAutoridadJoined"
                    description="Autoridad"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionAsignableRAJoined"
                    description="Asignable RA"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionFechaRespuestaJoined"
                    description="Fecha respuesta"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionRespuestaJoined"
                    description="Respuesta"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionEstadoJoined"
                    description="Estado Objeción"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
              {!columns.useObjecionJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="objecion"
                    description="Objeción"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fechaObjecion"
                    description="Fecha Objeción"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="limiteEspera"
                    description="Límite espera"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionAutoridad"
                    description="Autoridad"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="asignableRA"
                    description="Asignalbe RA"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fechaRespuesta"
                    description="Fecha respuesta"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="respuesta"
                    description="Respuesta"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="objecionEstado"
                    description="Estado Objeción"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DownloadExcel;
