import { UseFormReturn, useWatch } from "react-hook-form";
import * as APIt from "API";
import * as DossierUtils from "services/Utils/DossierUtils";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import DossierGroupAccordion from "./DossierGroupAccordion";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>;
  preferences: DossierUtils.PreferencesType;
  formula: APIt.CodigoFormula;
  index: number;
};

function DossierFormula(params: Props): JSX.Element {
  const { preferences, formula, formMethods, formulaFormMethods, index } = params;
  const { setValue, getValues } = formMethods;
  const { control: formulasControl, setValue: setFormulaValue } = formulaFormMethods;

  const { formulas: formulaVOs, estado, pais } = getValues();
  const dossierData = useWatch({ control: formulasControl, name: `formulas.${index}.dossierData` });

  if (!preferences.formulas[formula.id]) return null;

  const categoria = formulaVOs.find((fvo: APIt.RegistroFormulaVO) => fvo.id === formula.id)
    .categoria?.codigo;

  const updatePreferences = (updatedPreferences: DossierUtils.TramiteDefinitionEntry[]) => {
    const dataCodes = dossierData.map((data: APIt.DossierField) => data.codigo);
    const newData = [...dossierData];
    preferences.formulas[formula.id].preferences = updatedPreferences.map(
      (entry: DossierUtils.TramiteDefinitionEntry) => {
        let dataIndex = dataCodes.indexOf(entry.code);
        if (dataIndex < 0) {
          dataIndex = newData.length;
          newData.push({ codigo: entry.code, done: false } as APIt.DossierField);
        }
        return { ...entry, dataIndex };
      }
    );
    setValue("dossierPreferences", JSON.stringify(preferences));
    setFormulaValue(`formulas.${index}.dossierData`, newData);
  };
  const newFieldOptions: DossierUtils.TramiteDefinitionEntry[] = DossierUtils.getExtraFieldOptions(
    preferences.formulas[formula.id].preferences,
    [categoria],
    "Formula"
  );

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;

  return (
    <DossierGroupAccordion
      formMethods={formulaFormMethods}
      formMethodsPrefix={`formulas.${index}.`}
      dossierGroup={preferences.formulas[formula.id].preferences?.find(
        (entry: DossierUtils.TramiteDefinitionEntry) => entry.code === "root"
      )}
      preferences={preferences.formulas[formula.id].preferences}
      updatePreferences={updatePreferences}
      newFieldOptions={newFieldOptions}
      root
      rootTitle={`Fórmula ${formula.codigo} (${categoria})`}
      canEdit={canEdit}
    />
  );
}

export default DossierFormula;
