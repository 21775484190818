import { Grid, Icon, IconButton, Switch } from "@mui/material";
import MDBox from "components/md/MDBox";
import * as DossierUtils from "services/Utils/DossierUtils";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { parseISO, format, isValid } from "date-fns";
import * as APIt from "API";
import DetailsTooltip from "./DetailsTooltip";
import DossierDetailsDlg from "./DossierDetailsDlg";

/* eslint-disable */
type UpdatePreferencesFunction = (preferences: DossierUtils.TramiteDefinitionEntry[]) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<any, any>;
  formMethodsPrefix: string;
  field: DossierUtils.TramiteDefinitionEntry;
  preferences: DossierUtils.TramiteDefinitionEntry[];
  updatePreferences: UpdatePreferencesFunction;
  canEdit?: boolean;
};

function DossierField(params: Props): JSX.Element {
  const { formMethods, field, preferences, updatePreferences, formMethodsPrefix, canEdit } = params;
  const { control } = formMethods;
  const dossierData = useWatch({ control, name: `${formMethodsPrefix}dossierData` });

  if (!dossierData || !dossierData[field.dataIndex]) return null;

  const fieldKey = `${formMethodsPrefix}dossierData.${field.dataIndex}.done`;
  const done = dossierData?.find((dd: APIt.DossierField) => dd.codigo === field.code)?.done;

  const handleRemove = () => {
    updatePreferences(
      preferences.filter((e: DossierUtils.TramiteDefinitionEntry) => e.code !== field.code)
    );
  };

  return (
    <Grid item xs={12} md={4} key={field.code}>
      <Grid container direction="row">
        {field.userAdded && canEdit && (
          <Grid item>
            <IconButton size="small" disableRipple onClick={handleRemove}>
              <Icon sx={{ fontWeight: "bold" }}>remove</Icon>
            </IconButton>
          </Grid>
        )}
        <Grid item ml={-1.5}>
          <MDBox>
            <Controller
              name={fieldKey}
              control={control}
              render={(props: any) => {
                const { field } = props;
                return (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    disabled={!canEdit}
                  />
                );
              }}
            />
          </MDBox>
        </Grid>
        <Grid item>
          <DetailsTooltip
            note={field?.details?.note}
            date={
              field?.details?.date &&
              isValid(parseISO(field?.details?.date)) &&
              format(parseISO(field?.details?.date), "dd/MM/yyyy")
            }
            enabled={!done && field.details && !!(field?.details?.date || field?.details?.note)}
            truncate={20}
            text={field.name}
            variant="caption"
          />
        </Grid>
        {!done && canEdit && field.showDetails && (
          <Grid item>
            <DossierDetailsDlg
              field={field}
              preferences={preferences}
              updatePreferences={updatePreferences}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default DossierField;
