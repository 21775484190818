/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
// import MDInput from "components/MDInput";
// import MDBadge from "components/md/MDBadge";
import MDTypography from "components/md/MDTypography";
import InfoIcon from "@mui/icons-material/Info";
import NotificationIcon from "@mui/icons-material/RadioButtonUnchecked";
import AllNotificationsIcon from "@mui/icons-material/ReadMore";

// Material Dashboard 2 PRO React TS examples components
// import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "components/tramites/NotificationItem";

// Custom styles for Navbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/tramites/Navbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator ,
} from "context";
import { Auth } from "aws-amplify";
import MDBadge from "components/md/MDBadge";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { getNotificacionLink, getNotificacionText } from "globalvars";

// Declaring prop types for Navbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  isFull?: boolean;
}

function Navbar({ absolute, light, isMini, isFull }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<any>(false);
  const [openNotificacionesMenu, setOpenNotificacionesMenu] = useState<any>(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  const breadCrumTitle =
    route[route.length - 1] === "codigo-formula"
      ? "Códigos de fórmula"
      : route[route.length - 1].replace("-", " ");

  const {
    state: { user, topNotificaciones },
  } = useTramitesAppStateContext();

  useEffect(() => {
    // Setting the navbar type
    setNavbarType("absolute");

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
       The event listener that's calling the handleTransparentNavbar function when 
       scrolling the window.
      */
    // window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  const handleOpenNotificacionesMenu = (event: any) =>
    setOpenNotificacionesMenu(event.currentTarget);
  const handleCloseMenu = () => {
    setOpenMenu(false);
    handleOpenNotificacionesMenu(false);
  };
  const handleHomeOpen = () => navigate("/");

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<InfoIcon>ayuda</InfoIcon>}
        title="Ayuda"
        onClick={() =>
          window.open(
            "https://genommalabinternacional.sharepoint.com/Regulatorio/GIRA/Forms/AllItems.aspx?xsdata=MDV8MDF8fDdkY2YxYTIwMDEwYzQ3NTBiOTA1MDhkYWYzM2U4YjQxfGZkNjJiMjljZjBmNDQyMTg4ZWQ2MGJjY2JiMWIxYjY3fDB8MHw2MzgwODk3NDcxNzYyMDg2NDV8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVFkzTXpNM056a3hOamMxTnpzeE5qY3pNemMzT1RFMk56VTNPekU1T20xbFpYUnBibWRmVGxkTmVsbFVUWGRQUkZWMFQwZGFiVTVwTURCT2Vtc3dURmRGZWs5VVJYUlpNazB3VFZSak0xcFVVbTFPUjFsNVFIUm9jbVZoWkM1Mk1nPT18MmMyZjFhY2M3MmU5NDE5ZWI5MDUwOGRhZjMzZThiNDF8YzZjOWRkYmUwNzg0NGJhZjhkNzQxOWM0NzE0NjIxMTM%3D&sdata=MzZGMzZubXljQ2Q1dmVndGxMOTl2N1ovelhIZlNjNDBTOEZCcHA0QU44MD0%3D",
            "_blank"
          )
        }
      />
      <NotificationItem
        icon={<Icon>email</Icon>}
        title="Salir"
        onClick={() => {
          Auth.signOut();
        }}
      />

      {/* <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
    <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
    </Menu>
  );

  const renderNotificacionesMenu = () => (
    <Menu
      anchorEl={openNotificacionesMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openNotificacionesMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {topNotificaciones?.map((notificacion: any) => (
        <NotificationItem
          key={notificacion.id}
          icon={<NotificationIcon />}
          title={getNotificacionText(notificacion)}
          onClick={() => navigate(`/${getNotificacionLink(notificacion)}`)}
        />
      ))}
      <NotificationItem
        icon={<AllNotificationsIcon />}
        title="Ver todas las notificaciones"
        onClick={() => navigate(`/notificaciones`)}
      />

      {/* <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
    <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {!isFull && (
            <>
              <IconButton
                size="small"
                disableRipple
                sx={navbarDesktopMenu}
                onClick={handleMiniSidenav}
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </>
          )}

          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
          <MuiBreadcrumbs
            sx={{
              "& .MuiBreadcrumbs-separator": {
                color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
              },
            }}
          >
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleHomeOpen}
            >
              <Icon sx={iconsStyle}>home</Icon>
            </IconButton>
            {route.slice(0, -1).map((el: string) => {
              const name = el === "codigo-formula" ? "Código de fórmula" : el;
              return (
                <Link to={`/${el}`} key={el}>
                  <MDTypography
                    component="span"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    color={light ? "white" : "dark"}
                    opacity={light ? 0.8 : 0.5}
                    sx={{ lineHeight: 0 }}
                  >
                    {name}
                  </MDTypography>
                </Link>
              );
            })}
            <MDTypography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              sx={{ lineHeight: 0 }}
            >
              {breadCrumTitle}
            </MDTypography>
          </MuiBreadcrumbs>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}

            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                sx={navbarIconButton}
                size="small"
                disableRipple
                onClick={handleOpenMenu}
                title={user}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={() => {}}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenNotificacionesMenu}
              >
                <MDBadge badgeContent={topNotificaciones?.length} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton>
              {renderMenu()}
              {renderNotificacionesMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for Navbar
Navbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  isFull: false,
};

export default Navbar;
