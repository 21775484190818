import * as React from "react";
import {
  Autoridad,
  BusinessUnit,
  Categoria,
  Fabricante,
  Pais,
  PrincipioActivo,
  CategoriaTerapeutica,
  FormaCosmeticaFarmaceutica,
  Iniciativa,
  EstadoTramite,
  TipoTramite,
  CodigoFormula,
  Tramite,
  Registro,
} from "API";
import usePaisOptions from "features/ServiceHooks/usePaisOptions";
import useBusinessUnitOptions from "features/ServiceHooks/useBusninessUnitOptions";
import useCategoriaOptions from "features/ServiceHooks/useCategoriaOptions";
import useFabricanteOptions from "features/ServiceHooks/useFabricanteOptions";
import usePrincipioActivoOptions from "features/ServiceHooks/usePrincipioActivoOptions";
import useAutoridadOptions from "features/ServiceHooks/useAutoridadOptions";
import useCategoriaTerapeuticaOptions from "features/ServiceHooks/useCategoriaTerapeuticaOptions";
import useFormaCosmeticaFarmaceuticaOptions from "features/ServiceHooks/useFormaCosmeticaFarmaceuticaOptions";
import useIniciativaOptions from "features/ServiceHooks/useIniciativaOptions";
import { useUserName } from "features/ServiceHooks/useAuthorization";
import { findNotificaciones } from "services/Notificaciones/NotificacionesList";
import { findUserConfig } from "services/UserConfig/UserConfigCRUD";
import { fetchAllRegistrosList } from "services/Registro/RegistroList";

type ActionType =
  | "update-sus"
  | "update-bus"
  | "update-categorias"
  | "update-fabricantes"
  | "update-principios"
  | "update-maquiladores"
  | "update-autoridades"
  | "update-categoriasTerapeuticas"
  | "update-iniciativas"
  | "update-formasCosmeticasFarmaceuticas"
  | "update-formulaSearchState"
  | "update-formula-list"
  | "update-formula-list-entry"
  | "update-tramiteSearchState"
  | "update-tramite-list"
  | "update-tramite-list-entry"
  | "delete-tramite-list-entry"
  | "update-tramite-tipo"
  | "update-registro-list"
  | "update-registro-list-entry"
  | "update-registroSearchState"
  | "update-dashboard-filter-state"
  | "update-dashboard-tramite-efectividad-state"
  | "update-user"
  | "update-user-rol"
  | "update-user-paises"
  | "update-top-notifications"
  | "update-notificaciones-leidas-search-state"
  | "update-notificaciones-pendientes-search-state";

type Action = {
  type: ActionType;
  payload?: any;
};

export type DashboardFilterState = {
  paises: string[];
  businessUnits: string[];
  range: "Q" | "Y" | "M" | "C";
  fechaInicio: string;
  fechaFin: string;
};

export type FormulaSearchState = {
  codigo: string;
  full: string;
  advanced: boolean;
  advancedData: {
    searchSourcingUnits?: string;
    searchProductosDestino?: string;
    searchProducto?: string;
    searchPrincipiosActivos?: string;
    searchPresentaciones?: string;
    searchPaisesDestino?: string;
    searchMarca?: string;
    searchIniciativa?: string;
    searchFormaCosmeticaFarmaceutica?: string;
    searchCodigo?: string;
    searchCategoriaTerapeutica?: string;
    searchCategoria?: string;
    searchBu?: string;
  };
  results: CodigoFormula[];
  nextToken: string;
};

export type RegistroSearchState = {
  results: Registro[];
  filters: any;
  columnOrder: string[];
};

export type TramiteSearchState = {
  codigo: string;
  full: string;
  advanced: boolean;
  advancedData: {
    searchSourcingUnit?: string;
    searchProductoDestino?: string;
    searchProducto?: string;
    searchPrincipiosActivos?: string;
    searchPresentaciones?: string;
    searchPaisDestino?: Pais[];
    searchMarca?: string;
    searchIniciativa?: string;
    searchFormaCosmeticaFarmaceutica?: string;
    searchCodigo?: string;
    searchCategoriaTerapeutica?: string;
    searchCategoria?: string;
    searchBu?: string;
    searchEstado?: EstadoTramite;
    searchTipo?: TipoTramite;
    searchEtiqueta?: string;
    searchCodigoCPT?: string;
    searchCluster?: string;
  };
  results: Tramite[];
  nextToken: string;
  tipo: string;
};

export type NotificacionesSearchState = {
  results: Tramite[];
  nextToken: string;
};

/* eslint-disable */
type Dispatch = (action: Action) => void;
/* eslint-enable */

export type TramitesAppState = {
  suList: Pais[];
  buList: BusinessUnit[];
  fabricanteList: Fabricante[];
  categoriaList: Categoria[];
  principioList: PrincipioActivo[];
  autoridadList: Autoridad[];
  categoriaTerapeuticaList: CategoriaTerapeutica[];
  iniciativaList: Iniciativa[];
  formaCosmeticaFarmaceuticaList: FormaCosmeticaFarmaceutica[];
  formulaSearchState: FormulaSearchState;
  tramiteSearchState: TramiteSearchState;
  registroSearchState: RegistroSearchState;
  dashboardFilterState: DashboardFilterState;
  user: string;
  userRol: string;
  userPaises: string[];
  topNotificaciones: Notification[];
  notificacionesLeidasSearchState: NotificacionesSearchState;
  notificacionesPendientesSearchState: NotificacionesSearchState;
};
type TramitesAppProviderProps = { children: React.ReactNode };

const TramitesAppStateContext = React.createContext<
  { state: TramitesAppState; dispatch: Dispatch } | undefined
>(undefined);

const updateFormulaListEntry = (state: TramitesAppState, payload: any): any => {
  let found = false;
  const updatedFormulaList = state.formulaSearchState.results.map((entry: any) => {
    if (entry.id === payload.id) {
      found = true;
      return payload;
    }
    return entry;
  });

  if (found) {
    return {
      ...state,
      formulaSearchState: { ...state.formulaSearchState, results: updatedFormulaList },
    };
  }
  return {
    ...state,
    formulaSearchState: {
      ...state.formulaSearchState,
      results: [payload, ...updatedFormulaList],
    },
  };
};

const deleteTramiteListEntry = (state: TramitesAppState, payload: any): any => {
  const updatedTramiteList = state.tramiteSearchState.results.filter(
    (entry: any) => entry.id !== payload
  );
  return {
    ...state,
    tramiteSearchState: {
      ...state.tramiteSearchState,
      results: updatedTramiteList,
    },
  };
};

const updateTramiteListEntry = (state: TramitesAppState, payload: any): any => {
  let found = false;
  const updatedTramiteList = state.tramiteSearchState.results.map((entry: any) => {
    if (entry.id === payload.id) {
      found = true;
      return payload;
    }
    return entry;
  });

  if (found) {
    return {
      ...state,
      tramiteSearchState: { ...state.tramiteSearchState, results: updatedTramiteList },
    };
  }
  return {
    ...state,
    tramiteSearchState: {
      ...state.tramiteSearchState,
      results: [payload, ...updatedTramiteList],
    },
  };
};

const updateRegistroListEntry = (state: TramitesAppState, payload: any): any => {
  let found = false;
  const updatedRegistroList = state.registroSearchState.results.map((entry: any) => {
    if (entry.id === payload.id) {
      found = true;
      return payload;
    }
    return entry;
  });

  if (found) {
    return {
      ...state,
      registroSearchState: { ...state.registroSearchState, results: updatedRegistroList },
    };
  }
  return {
    ...state,
    registroSearchState: {
      ...state.registroSearchState,
      results: [payload, ...updatedRegistroList],
    },
  };
};

function newReportReducer(state: TramitesAppState, action: Action) {
  switch (action.type) {
    case "update-sus":
      return { ...state, suList: action.payload };
    case "update-bus":
      return { ...state, buList: action.payload };
    case "update-categorias":
      return { ...state, categoriaList: action.payload };
    case "update-fabricantes":
      return { ...state, fabricanteList: action.payload };
    case "update-principios":
      return { ...state, principioList: action.payload };
    case "update-maquiladores":
      return { ...state, maquiladorList: action.payload };
    case "update-autoridades":
      return { ...state, autoridadList: action.payload };
    case "update-categoriasTerapeuticas":
      return { ...state, categoriaTerapeuticaList: action.payload };
    case "update-iniciativas":
      return { ...state, iniciativaList: action.payload };
    case "update-formasCosmeticasFarmaceuticas":
      return { ...state, formaCosmeticaFarmaceuticaList: action.payload };
    case "update-formulaSearchState":
      return { ...state, formulaSearchState: action.payload };
    case "update-tramiteSearchState":
      return { ...state, tramiteSearchState: action.payload };
    case "update-formula-list":
      return {
        ...state,
        formulaSearchState: {
          ...state.formulaSearchState,
          results: action.payload.page,
          nextToken: action.payload.nextToken,
        },
      };
    case "update-formula-list-entry":
      return updateFormulaListEntry(state, action.payload);
    case "update-tramite-list":
      return {
        ...state,
        tramiteSearchState: {
          ...state.tramiteSearchState,
          results: action.payload.page,
          nextToken: action.payload.nextToken,
        },
      };
    case "update-tramite-list-entry":
      return updateTramiteListEntry(state, action.payload);
    case "delete-tramite-list-entry":
      return deleteTramiteListEntry(state, action.payload);
    case "update-tramite-tipo":
      return {
        ...state,
        tramiteSearchState: {
          ...state.tramiteSearchState,
          tipo: action.payload,
        },
      };
    case "update-registro-list-entry":
      return updateRegistroListEntry(state, action.payload);
    case "update-registro-list":
      return {
        ...state,
        registroSearchState: {
          ...state.registroSearchState,
          results: action.payload.page,
        },
      };
    case "update-registroSearchState":
      return { ...state, registroSearchState: action.payload };
    case "update-dashboard-filter-state":
      return { ...state, dashboardFilterState: action.payload };
    case "update-user":
      return { ...state, user: action.payload };
    case "update-user-rol":
      return { ...state, userRol: action.payload };
    case "update-user-paises":
      return { ...state, userPaises: action.payload };
    case "update-top-notifications":
      return { ...state, topNotificaciones: action.payload };
    case "update-notificaciones-leidas-search-state":
      return {
        ...state,
        notificacionesLeidasSearchState: {
          results: action.payload.page,
          nextToken: action.payload.nextToken,
        },
      };
    case "update-notificaciones-pendientes-search-state":
      return {
        ...state,
        notificacionesPendientesSearchState: {
          results: action.payload.page,
          nextToken: action.payload.nextToken,
        },
      };
    default:
      return state;
  }
}

function TramitesAppProvider({ children }: TramitesAppProviderProps) {
  const [state, dispatch] = React.useReducer(newReportReducer, {
    suList: [],
    buList: [],
    fabricanteList: [],
    categoriaList: [],
    principioList: [],
    acondicionadorList: [],
    maquiladorList: [],
    autoridadList: [],
    categoriaTerapeuticaList: [],
    iniciativaList: [],
    formaCosmeticaFarmaceuticaList: [],
    envasadorList: [],
    formulaSearchState: {
      codigo: "",
      full: "",
      advanced: false,
      advancedData: {},
      results: [],
    },
    tramiteSearchState: {
      codigo: "",
      full: "",
      advanced: false,
      advancedData: { searchPaisDestino: [] },
      results: [],
    },
    registroSearchState: {
      results: [],
      filters: {},
      columnOrder: [
        "categoria",
        "codigo",
        "pais",
        "marca",
        "registro",
        "vencimiento",
        "inicioRenovacion",
      ],
    },
    dashboardFilterState: {},
    user: "",
    userRol: "",
    userPaises: [],
    topNotificaciones: [],
    notificacionesLeidasSearchState: {
      results: [],
      nextToken: null,
    },
    notificacionesPendientesSearchState: {
      results: [],
      nextToken: null,
    },
  });

  const [paises] = usePaisOptions();
  const [bus] = useBusinessUnitOptions();
  const [categorias] = useCategoriaOptions();
  const [fabricantes] = useFabricanteOptions();
  const [principios] = usePrincipioActivoOptions();
  const [autoridades] = useAutoridadOptions();
  const [categoriasTerapeuticas] = useCategoriaTerapeuticaOptions();
  const [iniciativas] = useIniciativaOptions();
  const [formasCosmeticasFarmaceuticas] = useFormaCosmeticaFarmaceuticaOptions();
  const user = useUserName();

  React.useEffect(() => {
    fetchAllRegistrosList(null, null).then(
      (result: any) => {
        const { page, nextToken } = result;
        dispatch({
          type: "update-registro-list",
          payload: { page, nextToken },
        });
        // console.log("----------------------------------");
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, []);

  React.useEffect(() => {
    if (user) {
      dispatch({ type: "update-user", payload: user });
      findNotificaciones(user, "N", 10, null).then(({ page }) => {
        dispatch({ type: "update-top-notifications", payload: page });
      });
      findUserConfig(user).then(({ user: userData }) => {
        dispatch({ type: "update-user-rol", payload: userData.rol });
        dispatch({ type: "update-user-paises", payload: userData.paises || [] });
      });
    }
  }, [user]);

  React.useEffect(() => {
    dispatch({ type: "update-sus", payload: paises });
  }, [paises]);

  React.useEffect(() => {
    dispatch({ type: "update-bus", payload: bus });
  }, [bus]);

  React.useEffect(() => {
    dispatch({ type: "update-categorias", payload: categorias });
  }, [categorias]);

  React.useEffect(() => {
    dispatch({ type: "update-fabricantes", payload: fabricantes });
  }, [fabricantes]);

  React.useEffect(() => {
    dispatch({ type: "update-principios", payload: principios });
  }, [principios]);

  React.useEffect(() => {
    dispatch({ type: "update-autoridades", payload: autoridades });
  }, [autoridades]);

  React.useEffect(() => {
    dispatch({ type: "update-categoriasTerapeuticas", payload: categoriasTerapeuticas });
  }, [categoriasTerapeuticas]);

  React.useEffect(() => {
    dispatch({ type: "update-iniciativas", payload: iniciativas });
  }, [iniciativas]);

  React.useEffect(() => {
    dispatch({
      type: "update-formasCosmeticasFarmaceuticas",
      payload: formasCosmeticasFarmaceuticas,
    });
  }, [formasCosmeticasFarmaceuticas]);

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );
  return (
    <TramitesAppStateContext.Provider value={value}>{children}</TramitesAppStateContext.Provider>
  );
}

function useTramitesAppStateContext() {
  const context = React.useContext(TramitesAppStateContext);
  if (context === undefined) {
    throw new Error("useTramitesAppStateContext must be used within a TramitesAppProvider");
  }
  return context;
}

export { TramitesAppProvider, useTramitesAppStateContext };
