import { FormaCosmeticaFarmaceutica } from "API";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

import {
  createFormaCosmeticaFarmaceutica,
  fetchFormaCosmeticaFarmaceutica,
} from "services/FormaCosmeticaFarmaceutica/FormaCosmeticaFarmaceuticaCRUD";

/* eslint-disable */
type CreateCallback = (newEntry: FormaCosmeticaFarmaceutica) => void;
/* eslint-enable */

const useFormaCosmeticaFarmaceuticaCRUD = () => {
  const {
    state: { formaCosmeticaFarmaceuticaList },
    dispatch,
  } = useTramitesAppStateContext();

  const doCreateFormaCosmeticaFarmaceutica = (
    newEntry: FormaCosmeticaFarmaceutica,
    callback: CreateCallback
  ) => {
    createFormaCosmeticaFarmaceutica(newEntry).then((id) => {
      fetchFormaCosmeticaFarmaceutica(id).then((formaCosmeticaFarmaceuticas) => {
        dispatch({
          type: "update-formasCosmeticasFarmaceuticas",
          payload: [...formaCosmeticaFarmaceuticaList, formaCosmeticaFarmaceuticas],
        });
        if (callback) callback(formaCosmeticaFarmaceuticas);
      });
    });
  };

  return [doCreateFormaCosmeticaFarmaceutica];
};

export default useFormaCosmeticaFarmaceuticaCRUD;
