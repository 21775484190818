import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Avatar from "components/tramites/Avatar";
import { Card, Divider, Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { gridSizes } from "globalvars";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FormField from "components/tramites/FormField";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";
import DetalleFormulaPrincipio from "./DetalleFormulaPrincipio";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
  formula: APIt.RegistroFormulaVO;
  index: number;
};

function DetalleFormula(params: Props): JSX.Element {
  const { formulas, formula, index, formMethods } = params;
  const {
    control,
    formState: { errors },
    getValues,
    register,
  } = formMethods;
  const { estado, pais } = getValues();

  const watchCategoria = useWatch({ control, name: `formulas.${index}.categoria` });

  const {
    state: {
      buList,
      categoriaList,
      categoriaTerapeuticaList,
      formaCosmeticaFarmaceuticaList,
      userRol,
      userPaises,
    },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;
  const canEditRegistro = !estado && isUserGlobal;

  return (
    <MDBox px={3} mt={2}>
      <MDBox key={formula.codigo} display="flex" sx={{ mt: 0.7 }}>
        <Avatar title={formula.categoria?.nombre} size="xs" sx={{ mr: 1 }} bgColor="info">
          {watchCategoria?.codigo}
        </Avatar>
        <MDTypography
          variant="label"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{ mt: -0.5 }}
        >
          {formula.codigo}
        </MDTypography>
      </MDBox>
      <Grid container direction="row" spacing={1} pl={2}>
        <Grid container spacing={3} pt={4}>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <ControlledAutocomplete
              label="Categoría *"
              name={`formulas.${index}.categoria`}
              options={categoriaList}
              control={control as any}
              errors={
                errors.formulas &&
                errors.formulas[index] &&
                errors.formulas[index].categoria?.message
              }
              disabled={!canEdit}
              value={formula.categoria}
            />
          </Grid>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <ControlledAutocomplete
              label="Business Unit *"
              name={`formulas.${index}.bu`}
              options={buList}
              control={control as any}
              errors={
                errors.formulas && errors.formulas[index] && errors.formulas[index].bu?.message
              }
              disabled={!canEdit}
              value={formula.bu}
            />
          </Grid>
          {watchCategoria?.codigo === "OTC" && (
            <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
              <ControlledAutocomplete
                label="Categoría Terapéutica *"
                name={`formulas.${index}.categoriaTerapeutica`}
                options={categoriaTerapeuticaList}
                control={control as any}
                errors={
                  errors.formulas &&
                  errors.formulas[index] &&
                  errors.formulas[index].categoriaTerapeutica?.message
                }
                disabled={!canEdit && !canEditRegistro}
                value={formula.categoriaTerapeutica}
              />
            </Grid>
          )}
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <ControlledAutocomplete
              label="Forma cosmética/farmacéutica *"
              name={`formulas.${index}.formaCosmeticaFarmaceutica`}
              options={formaCosmeticaFarmaceuticaList}
              control={control as any}
              errors={
                errors.formulas &&
                errors.formulas[index] &&
                errors.formulas[index].formaCosmeticaFarmaceutica?.message
              }
              disabled={!canEdit}
              value={formula.formaCosmeticaFarmaceutica}
            />
          </Grid>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <FormField
              label="Marca *"
              type="text"
              name={`formulas.${index}.marca`}
              errors={
                errors.formulas && errors.formulas[index] && errors.formulas[index].marca?.message
              }
              register={register as any}
              control={control as any}
              disabled={!canEdit}
            />
          </Grid>
          {/* watchCategoria?.codigo === "OTC" && (
            <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
              <ControlledAutocomplete
                label="Condicion de Venta *"
                name={`formulas.${index}.condicionVenta`}
                options={[
                  { id: APIt.CondicionVenta.OTC, nombre: APIt.CondicionVenta.OTC },
                  { id: APIt.CondicionVenta.RX, nombre: APIt.CondicionVenta.RX },
                  { id: APIt.CondicionVenta.VentaLibre, nombre: APIt.CondicionVenta.VentaLibre },
                ]}
                control={control as any}
                errors={
                  errors.formulas &&
                  errors.formulas[index] &&
                  errors.formulas[index].condicionVenta?.message
                }
                disabled={!canEdit}
              />
            </Grid>
              ) */}
        </Grid>
      </Grid>
      {watchCategoria?.codigo !== "OTC" && (
        <MDBox pl={4} mb={4}>
          <MDTypography variant="label" fontWeight="medium" sx={{ mt: -0.5 }}>
            Ingredientes
          </MDTypography>
          <Card sx={{ backgroundColor: "White" }}>
            <MDBox mb={2} mt={2} ml={2}>
              <MDTypography>
                {formula.principiosActivos?.map(
                  (registroPrincipio: APIt.RegistroPrincipioActivoVO, ppoIndex: number) => {
                    if (ppoIndex === 0) {
                      return registroPrincipio.principio.nombre;
                    }
                    return `, ${registroPrincipio.principio.nombre}`;
                  }
                )}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      )}
      {watchCategoria?.codigo === "OTC" && (
        <MDBox pl={4}>
          <Divider />
          <MDTypography variant="label" fontWeight="medium" sx={{ mt: -0.5 }}>
            Principios activos
          </MDTypography>
          <MDBox>
            <TableContainer>
              <Table style={{ width: "100%" }}>
                <MDBox component="thead">
                  <TableRow>
                    <DataTableHeadCell width="40%" align="left" sorted={false}>
                      Principio activo
                    </DataTableHeadCell>
                    <DataTableHeadCell width="20%" align="left" sorted={false}>
                      Concentración
                    </DataTableHeadCell>
                    {["MX", "CL"].includes(pais.codigo) && (
                      <DataTableHeadCell width="40%" align="left" sorted={false}>
                        Fabricantes API
                      </DataTableHeadCell>
                    )}
                  </TableRow>
                </MDBox>
                <TableBody>
                  {formula.principiosActivos?.map(
                    (registroPrincipio: APIt.RegistroPrincipioActivoVO, ppoIndex: number) => (
                      <DetalleFormulaPrincipio
                        formMethods={formMethods}
                        codigoFormula={formulas.find(
                          (codigoFormula: APIt.CodigoFormula) =>
                            codigoFormula.codigo === formula.codigo
                        )}
                        formula={formula}
                        formulaIndex={index}
                        registroPrincipio={registroPrincipio}
                        ppoIndex={ppoIndex}
                        key={registroPrincipio.principio.id}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

export default DetalleFormula;
