export const listCategoriaTerapeuticas = /* GraphQL */ `
  query ListCategoriaTerapeuticas(
    $filter: ModelCategoriaTerapeuticaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriaTerapeuticas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
      }
      nextToken
    }
  }
`;
export const getCategoriaTerapeutica = /* GraphQL */ `
  query GetCategoriaTerapeutica($id: ID!) {
    getCategoriaTerapeutica(id: $id) {
      id
      nombre
    }
  }
`;
export const createCategoriaTerapeuticaMutation = /* GraphQL */ `
  mutation CreateCategoriaTerapeutica(
    $input: CreateCategoriaTerapeuticaInput!
    $condition: ModelCategoriaTerapeuticaConditionInput
  ) {
    createCategoriaTerapeutica(input: $input, condition: $condition) {
      id
      nombre
    }
  }
`;
export const updateCategoriaTerapeuticaMutation = /* GraphQL */ `
  mutation UpdateCategoriaTerapeutica(
    $input: UpdateCategoriaTerapeuticaInput!
    $condition: ModelCategoriaTerapeuticaConditionInput
  ) {
    updateCategoriaTerapeutica(input: $input, condition: $condition) {
      id
      nombre
    }
  }
`;
