import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { Divider } from "@mui/material";
import MDTypography from "components/md/MDTypography";
import SimpleTitledAccordion from "components/tramites/Accordion/SimpleTitledAccordion";
import DetalleFormulas from "./DetalleFormulas";
import DetallePresentaciones from "./DetallePresentaciones";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
};

function DetalleRegistro(params: Props): JSX.Element {
  const { formMethods, formulas } = params;

  const {
    formState: { errors },
  } = formMethods;

  return (
    <MDBox>
      <SimpleTitledAccordion
        header={
          <MDTypography variant="h3" color={errors.formulas ? "error" : "inherit"}>
            Detalle de fórmulas
          </MDTypography>
        }
      >
        <DetalleFormulas formMethods={formMethods} formulas={formulas} />
      </SimpleTitledAccordion>
      <Divider />
      <SimpleTitledAccordion
        header={
          <>
            <MDTypography variant="h3" color={errors.presentaciones ? "error" : "inherit"}>
              Presentaciones
            </MDTypography>

            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errors && errors.presentaciones?.message}
            </MDTypography>
          </>
        }
      >
        <DetallePresentaciones formMethods={formMethods} formulas={formulas} />
      </SimpleTitledAccordion>
    </MDBox>
  );
}

export default DetalleRegistro;
