import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { RegistroSearchState } from "features/TramitesAppContext";
import { prepareForSearch } from "globalvars";

import { listRegistros } from "services/Registro/queries";

const fetchRegistrosList = async (
  _nextToken: string,
  filter?: any
): Promise<{ page: any[]; nextToken: string }> => {
  let nextToken = null;
  let page: APIt.Registro[] = [];

  const conditions: APIt.ListRegistroSearchesQueryVariables = {};
  conditions.filter = { and: [{ deleted: { eq: false } }] };

  if (filter?.numeroRegistro) {
    conditions.filter.and.push({
      searchCodigos: { contains: prepareForSearch(filter.numeroRegistro) },
    });
  }

  do {
    // eslint-disable-next-line no-await-in-loop
    const result = (await API.graphql(
      graphqlOperation(listRegistros, conditions)
    )) as GraphQLResult<APIt.ListRegistroSearchesQuery>;

    if (result.data) {
      const resultData: APIt.ListRegistroSearchesQuery = result.data;
      const entries = resultData.listRegistroSearches.items;

      page = entries.map((entry: APIt.RegistroSearch) => entry.registro);

      nextToken = resultData.listRegistroSearches.nextToken;
      conditions.nextToken = nextToken;
    }
  } while (nextToken !== null);

  return { page, nextToken };
};

const doFetch = async (
  _nextToken: string,
  forExport?: boolean
): Promise<{ page: any[]; nextToken: string }> => {
  let nextToken = null;
  let page: APIt.Registro[] = [];

  const conditions: APIt.ListRegistroSearchesQueryVariables = {};
  conditions.limit = forExport ? 1000 : 20;
  conditions.nextToken = _nextToken;
  conditions.filter = { and: [{ deleted: { eq: false } }] };

  const result = (await API.graphql(
    graphqlOperation(listRegistros, conditions)
  )) as GraphQLResult<APIt.ListRegistroSearchesQuery>;

  if (result.data) {
    const resultData: APIt.ListRegistroSearchesQuery = result.data;
    const entries = resultData.listRegistroSearches.items;

    page = entries.map((entry: APIt.RegistroSearch) => entry.registro);

    nextToken = resultData.listRegistroSearches.nextToken;
  }

  return { page, nextToken };
};

const fetchAllRegistrosList = async (
  _nextToken: string,
  filter?: RegistroSearchState,
  forExport?: boolean
): Promise<{ page: any[]; nextToken: string }> => {
  let returnData: any[] = [];
  let nextToken = _nextToken;
  do {
    // eslint-disable-next-line no-await-in-loop
    const partData = await doFetch(nextToken, true);
    returnData = [...returnData, ...partData.page];
    nextToken = partData.nextToken;
    if (2 > 3) console.log(forExport);
  } while (nextToken != null);

  return { page: returnData, nextToken: null };
};

export { fetchRegistrosList, fetchAllRegistrosList };
