import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { Box, Grid, Switch, Table, TableContainer, TableRow } from "@mui/material";
import DataTableHeadCell from "components/tramites/DataTable/DataTableHeadCell";
import MDTypography from "components/md/MDTypography";
import DataTableBodyCell from "components/tramites/DataTable/DataTableBodyCell";
import FormField from "components/tramites/FormField";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import { estatusVentaLabel } from "globalvars";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  presentacion: APIt.RegistroPresentacionesVO;
  presentacionIndex: number;
  fabricante: APIt.RegistroFabricanteVO;
  fabricanteIndex: number;
};

function AprobarDlgRegistroFabricante(params: Props): JSX.Element {
  const { formMethods, presentacionIndex, fabricanteIndex, fabricante } = params;
  const {
    control,
    formState: { errors },
    register,
  } = formMethods;

  const { fields } = useFieldArray({
    control,
    name: `presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios`,
    keyName: "arrayKey",
  });

  return (
    <MDBox marginLeft={3} marginTop={2}>
      <MDTypography variant="h6">{fabricante.fabricante.nombre}</MDTypography>
      <TableContainer>
        <Table style={{ width: "100%" }}>
          <MDBox component="thead">
            <TableRow>
              <DataTableHeadCell width="40%" align="left" sorted={false}>
                Acondicionador primario
              </DataTableHeadCell>
              <DataTableHeadCell width="15%" align="left" sorted={false}>
                Compliance
              </DataTableHeadCell>
              <DataTableHeadCell width="15%" align="left" sorted={false}>
                Estabilidad
              </DataTableHeadCell>
              <DataTableHeadCell width="15%" align="left" sorted={false}>
                Vida útil (Meses)
              </DataTableHeadCell>
              <DataTableHeadCell width="15%" align="left" sorted={false}>
                Estatus Venta
              </DataTableHeadCell>
            </TableRow>
            {fields.map(
              (
                registroAcondicionadorPrimario: APIt.RegistroAcondicionadorPrimarioVO,
                acondicionadorPrimarioIndex: number
              ) => (
                <TableRow
                  key={`${registroAcondicionadorPrimario.acondicionadorPrimario?.id}${
                    acondicionadorPrimarioIndex + 1
                  }`}
                >
                  <DataTableBodyCell>
                    {registroAcondicionadorPrimario.acondicionadorPrimario.nombre}
                  </DataTableBodyCell>
                  <DataTableBodyCell align="left">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      width={{ xs: "100%", sm: "auto" }}
                      mt={{ xs: 2, sm: 1 }}
                    >
                      <MDBox mr={1}>
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item>No</Grid>
                          <Grid item>
                            <Controller
                              name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.compliance`}
                              control={control}
                              render={(props: any) => {
                                const { field } = props;
                                return (
                                  <Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid item>Si</Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </DataTableBodyCell>
                  <DataTableBodyCell align="left">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      width={{ xs: "100%", sm: "auto" }}
                      mt={{ xs: 2, sm: 1 }}
                    >
                      <MDBox mr={1}>
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item>No</Grid>
                          <Grid item>
                            <Controller
                              name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.estabilidad`}
                              control={control}
                              render={(props: any) => {
                                const { field } = props;
                                return (
                                  <Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid item>Si</Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </DataTableBodyCell>
                  <DataTableBodyCell align="left">
                    <MDBox width={50}>
                      <FormField
                        label=""
                        type="number"
                        name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.vidaUtil`}
                        errors={
                          errors.presentaciones &&
                          errors.presentaciones[presentacionIndex].fabricantes &&
                          errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                            .acondicionadoresPrimarios &&
                          errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                            .acondicionadoresPrimarios[acondicionadorPrimarioIndex].vidaUtil
                            ?.message
                        }
                        register={register as any}
                      />
                    </MDBox>
                  </DataTableBodyCell>
                  <MDBox component="td" px={3}>
                    <MDBox sx={{ verticalAlign: "middle", mt: 1, mb: 1 }}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item md={12}>
                          <ControlledAutocomplete
                            label=""
                            name={`presentaciones.${presentacionIndex}.fabricantes.${fabricanteIndex}.acondicionadoresPrimarios.${acondicionadorPrimarioIndex}.estatusVenta`}
                            options={[
                              APIt.EstatusVenta.comercializado,
                              APIt.EstatusVenta.nocomercializado,
                              APIt.EstatusVenta.discontinuado,
                            ]}
                            getOptionLabel={(option: any) =>
                              option
                                ? estatusVentaLabel[option as keyof typeof estatusVentaLabel]
                                : ""
                            }
                            isOptionEqualToValue={(option: any, value: any) => {
                              if (!value) return false;
                              return option.id === value.id;
                            }}
                            renderOption={(props: any, option: any) => (
                              <Box component="li" {...props} key={option}>
                                {estatusVentaLabel[option as keyof typeof estatusVentaLabel]}
                              </Box>
                            )}
                            control={control as any}
                            errors={
                              errors.presentaciones &&
                              errors.presentaciones[presentacionIndex].fabricantes &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios &&
                              errors.presentaciones[presentacionIndex].fabricantes[fabricanteIndex]
                                .acondicionadoresPrimarios[acondicionadorPrimarioIndex].estatusVenta
                                ?.message
                            }
                            onChange={(data: any, field: any) => {
                              if (data) field.onChange(data);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </TableRow>
              )
            )}
          </MDBox>
        </Table>
      </TableContainer>
    </MDBox>
  );
}

export default AprobarDlgRegistroFabricante;
