import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import {
  createCategoriaTerapeuticaMutation,
  getCategoriaTerapeutica,
} from "services/CategoriaTerapeutica/queries";

const createCategoriaTerapeutica = async (
  categoriaTerapeutica: APIt.CategoriaTerapeutica
): Promise<string> => {
  let id: string = "";

  const { nombre } = categoriaTerapeutica;

  const categoriaTerapeuticaInput: APIt.CreateCategoriaTerapeuticaInput = {
    nombre,
  };

  const inputVariables: APIt.CreateCategoriaTerapeuticaMutationVariables = {
    input: categoriaTerapeuticaInput,
  };

  const createRequest = (await API.graphql(
    graphqlOperation(createCategoriaTerapeuticaMutation, inputVariables)
  )) as GraphQLResult<APIt.CreateCategoriaTerapeuticaMutation>;

  if (createRequest?.data?.createCategoriaTerapeutica) {
    id = createRequest.data.createCategoriaTerapeutica.id;
  }

  return id;
};

const fetchCategoriaTerapeutica = async (id: string): Promise<APIt.CategoriaTerapeutica> => {
  let categoriaTerapeutica: APIt.CategoriaTerapeutica = null;
  const params: APIt.GetCategoriaTerapeuticaQueryVariables = { id };

  const result = (await API.graphql({
    query: getCategoriaTerapeutica,
    variables: params,
  })) as GraphQLResult<APIt.GetCategoriaTerapeuticaQuery>;

  if (result.data) {
    const query: APIt.GetCategoriaTerapeuticaQuery = result.data;
    if (query.getCategoriaTerapeutica) {
      categoriaTerapeutica = query.getCategoriaTerapeutica;
    }
  }

  return categoriaTerapeutica;
};

export { createCategoriaTerapeutica, fetchCategoriaTerapeutica };
