import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listPais } from "services/Pais/queries";

const fetchPaisesList = async (): Promise<{ paises: APIt.Pais[] }> => {
  let paises: APIt.Pais[] = [];

  const conditions: APIt.ListPaisQueryVariables = { limit: 20000 };

  const result = (await API.graphql(
    graphqlOperation(listPais, conditions)
  )) as GraphQLResult<APIt.ListPaisQuery>;

  if (result.data) {
    const resultData: APIt.ListPaisQuery = result.data;
    paises = resultData.listPais.items;
  }

  return { paises };
};

export default fetchPaisesList;
