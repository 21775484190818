import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import { useNavigate } from "react-router-dom";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import SortColumns from "./SortColumns";

type Props = {
  exportRegistros: any;
  allColumnDef: any;
  columnOrder: any;
  setColumnOrder: any;
};
export default function SearchBar(params: Props): JSX.Element {
  const { allColumnDef, columnOrder, setColumnOrder, exportRegistros } = params;
  const navigate = useNavigate();

  const {
    state: { userRol },
  } = useTramitesAppStateContext();

  const canEdit = userRol === "Global";

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Grid container alignItems="center" my={2} mr={2}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <MDBox display="flex" flexDirection="row-reverse" lineHeight={0}>
                  <MDBox mr={3}>
                    {canEdit && (
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => navigate("/registro-sanitario")}
                      >
                        <Icon>add</Icon>&nbsp; Nuevo registro
                      </MDButton>
                    )}
                  </MDBox>
                  <MDBox mr={3}>
                    <SortColumns
                      allColumnDef={allColumnDef}
                      columnOrder={columnOrder}
                      setColumnOrder={setColumnOrder}
                    />
                  </MDBox>
                  <MDBox mr={3}>
                    {canEdit && (
                      <MDButton variant="gradient" color="info" onClick={exportRegistros}>
                        <Icon>savealt</Icon>&nbsp; Exportar
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}
