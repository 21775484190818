import { Fabricante } from "API";
import { useState, useEffect } from "react";

import fetchFabricantesList from "services/Fabricante/FabricanteList";

const useFabricanteOptions = (): [Fabricante[], () => void] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchFabricantesList().then((result) => {
        const { fabricantes } = result;
        setItems(fabricantes);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useFabricanteOptions;
