import * as APIt from "API";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { queryRegistros, queryTramitesAprbados, queryTramitesRechazados } from "./queries";

const getRegistrosEntries = async (): Promise<any[]> => {
  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardYearlyByTypeAndYearQuery>>(
      graphqlOperation(queryRegistros)
    );
    if (result?.data?.dashboardYearlyByTypeAndYear?.items?.length > 0) {
      const newData = result?.data?.dashboardYearlyByTypeAndYear?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardYearlyByTypeAndYear?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

const getTramitesAprobadosEntries = async (): Promise<any[]> => {
  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardYearlyByTypeAndYearQuery>>(
      graphqlOperation(queryTramitesAprbados)
    );
    if (result?.data?.dashboardYearlyByTypeAndYear?.items?.length > 0) {
      const newData = result?.data?.dashboardYearlyByTypeAndYear?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardYearlyByTypeAndYear?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

const getTramitesRechazados = async (): Promise<any[]> => {
  let data: any[] = [];
  let nextToken = null;

  do {
    // eslint-disable-next-line no-await-in-loop
    const result: any = await API.graphql<GraphQLQuery<APIt.DashboardYearlyByTypeAndYearQuery>>(
      graphqlOperation(queryTramitesRechazados)
    );
    if (result?.data?.dashboardYearlyByTypeAndYear?.items?.length > 0) {
      const newData = result?.data?.dashboardYearlyByTypeAndYear?.items;
      data = [...data, ...newData];
    }
    nextToken = result?.data?.dashboardYearlyByTypeAndYear?.nextToken;
  } while (nextToken);

  return data as APIt.DashboardTramiteDaily[];
};

export { getRegistrosEntries, getTramitesAprobadosEntries, getTramitesRechazados };
