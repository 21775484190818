/* tslint:disable */
/* eslint-disable */

import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import * as APIt from "API";
import { amplifyMock, prepareForSearch, removeKey } from "globalvars";
import { fetchRegistro } from "services/Registro/RegistroCRUD";
import { createRegistroCambio } from "services/RegistroCambio/RegistroCambioCRUD";

const createRegistroMutation = `
  mutation CreateRegistro($input: CreateRegistroInput!, $condition: ModelRegistroConditionInput) {
    createRegistro(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateRegistroMutation = `
  mutation UpdateRegistro($input: UpdateRegistroInput!, $condition: ModelRegistroConditionInput) {
    updateRegistro(input: $input, condition: $condition) {
      id
    }
  }
`;

const createRegistroSearchMutation = `
  mutation CreateRegistroSearch($input: CreateRegistroSearchInput!, $condition: ModelRegistroSearchConditionInput) {
    createRegistroSearch(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateRegistroSearchMutation = `
  mutation UpdateRegistroSearch($input: UpdateRegistroSearchInput!, $condition: ModelRegistroSearchConditionInput) {
    updateRegistroSearch(input: $input, condition: $condition) {
      id
    }
  }
`;

const getFormulaQuery = `
  query GetCodigoFormula($id: ID!) {
    getCodigoFormula(id: $id) {
      id
      registros {
        id
        pais {
          id
          codigo
          nombre
        }
        autoridad {
          id
          nombre
          pais {
            id
            codigo
            nombre
          }
        }
        vencimientoSanitario
        inicioRenovacion
        numeroRegistro
      }
    }
  }
`;

const updateFormulaMutation = `
  mutation UpdateCodigoFormula($input: UpdateCodigoFormulaInput!, $condition: ModelCodigoFormulaConditionInput) {
    updateCodigoFormula(input: $input, condition: $condition) {
      id
    }
  }
`;
export const listRegistros = /* GraphQL */ `
  query ListRegistros($filter: ModelRegistroSearchFilterInput, $limit: Int, $nextToken: String) {
    listRegistroSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        registroId
        historico
        deleted
        searchBus
        searchCategorias
        searchCategoriasTerapeuticas
        searchCodigos
        searchFabricantes
        searchFormasCosmeticasFarmaceuticas
        searchIniciativa
        searchMarcas
        searchPaisDestino
        searchPresentaciones
        searchPrincipiosActivos
        searchProductos
        searchRegistro
        searchSourcingUnits
        searchEstatusVenta
      }
      nextToken
    }
  }
`;

export const searchRegistros = `
  query ListRegistros(
    $filter: SearchableRegistroSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableRegistroSearchSortInput]
  ) {
    searchRegistroSearches(sort: $sort, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        registroId
        historico
        deleted
        searchBus
        searchCategorias
        searchCategoriasTerapeuticas
        searchCodigos
        searchFabricantes
        searchFormasCosmeticasFarmaceuticas
        searchIniciativa
        searchMarcas
        searchPaisDestino
        searchPresentaciones
        searchPrincipiosActivos
        searchProductos
        searchRegistro
        searchSourcingUnits
        searchEstatusVenta
      }
      nextToken
    }
  }
`;

async function findRegistroSearch(id: string) {
  const query = amplifyMock ? listRegistros : searchRegistros;
  const conditions: APIt.ListRegistroSearchesQueryVariables = {
    filter: { registroId: { eq: id } },
  };

  const result = (await API.graphql(
    graphqlOperation(query, conditions)
  )) as GraphQLResult<APIt.ListRegistroSearchesQuery>;

  const data: any = result.data;
  const entries: APIt.RegistroSearch[] = amplifyMock
    ? data.listRegistroSearches.items
    : data.searchRegistroSearches.items;

  return entries?.at(0);
}

export default async function saveRegistro(registro: APIt.Registro, previousSearch?: any) {
  console.log(registro);
  const { id: idOut, ...registroInput } = registro;

  const savedRegistro = registro.id ? await fetchRegistro(registro.id) : {};

  let retId = registro.id;
  let body = null;
  if (retId) {
    body = (await executeQuery(updateRegistroMutation, {
      input: {
        ...registroInput,
        id: registro.id,
      },
    })) as GraphQLResult<APIt.UpdateRegistroMutation>;
    retId = body.data.updateRegistro.id;
  } else {
    body = (await executeQuery(createRegistroMutation, {
      input: registroInput,
    })) as GraphQLResult<APIt.CreateRegistroMutation>;
    retId = body.data.createRegistro.id;
  }

  // Historico cambios
  createRegistroCambio(savedRegistro, { ...registroInput, id: retId }, "registro");

  const {
    pais,
    autoridad,
    vencimientoSanitario,
    inicioRenovacion,
    numeroRegistro,
    formulas,
    historico,
    deleted,
  } = registro;

  const formulaRegistro = {
    id: retId,
    pais,
    autoridad,
    vencimientoSanitario,
    inicioRenovacion,
    numeroRegistro,
  } as APIt.FormulaRegistroVO;

  formulas.forEach((formula: APIt.RegistroFormulaVO) => {
    updateFormulaRegistro(formula, formulaRegistro);
  });

  let currentSearch: any = await findRegistroSearch(retId);
  if (!currentSearch) currentSearch = { registroId: retId, historico, deleted };
  if (previousSearch) {
    const {
      searchIniciativa,
      searchPaisDestino,
      searchCodigos,
      searchMarcas,
      searchBus,
      searchCategoriasTerapeuticas,
      searchFormasCosmeticasFarmaceuticas,
      searchCategorias,
      searchPrincipiosActivos,
      searchSourcingUnits,
      searchProductos,
      searchPresentaciones,
      searchFabricantes,
    } = previousSearch;

    currentSearch = {
      ...currentSearch,
      searchIniciativa,
      searchPaisDestino,
      searchCodigos,
      searchMarcas,
      searchBus,
      searchCategoriasTerapeuticas,
      searchFormasCosmeticasFarmaceuticas,
      searchCategorias,
      searchPrincipiosActivos,
      searchSourcingUnits,
      searchProductos,
      searchPresentaciones,
      searchFabricantes,
      searchRegistro: prepareForSearch(numeroRegistro),
    };
  }

  let searchEstatusVenta = "";
  registro.presentaciones?.forEach((presentacion: APIt.RegistroPresentacionesVO) => {
    presentacion.fabricantes?.forEach((fabricante: APIt.RegistroFabricanteVO) => {
      fabricante.acondicionadoresPrimarios?.forEach(
        (acondicionador: APIt.RegistroAcondicionadorPrimarioVO) => {
          if (!searchEstatusVenta.includes(acondicionador.estatusVenta)) {
            searchEstatusVenta = `${searchEstatusVenta}xx${acondicionador.estatusVenta}`;
          }
        }
      );
    });
  });

  currentSearch.searchEstatusVenta = prepareForSearch(searchEstatusVenta);

  if (currentSearch.id) {
    body = (await executeQuery(updateRegistroSearchMutation, {
      input: { ...currentSearch },
    })) as GraphQLResult<APIt.UpdateRegistroSearchMutation>;
  } else {
    body = (await executeQuery(createRegistroSearchMutation, {
      input: currentSearch,
    })) as GraphQLResult<APIt.CreateRegistroSearchMutation>;
  }

  return retId;
}

async function updateFormulaRegistro(
  formula: APIt.RegistroFormulaVO,
  registro: APIt.FormulaRegistroVO
) {
  const result = (await API.graphql({
    query: getFormulaQuery,
    variables: { id: formula.id },
  })) as GraphQLResult<APIt.GetCodigoFormulaQuery>;
  const query: APIt.GetCodigoFormulaQuery = result.data;
  const formulaDB = query.getCodigoFormula as APIt.CodigoFormula;

  let found = false;
  formulaDB.registros = formulaDB.registros?.map((dbRegistro: APIt.FormulaRegistroVO) => {
    if (dbRegistro.id === registro.id) {
      found = true;
      return registro;
    }
    return dbRegistro;
  });
  if (!found) {
    if (!formulaDB.registros) formulaDB.registros = [];
    formulaDB.registros.push(registro);
  }

  const body = (await executeQuery(updateFormulaMutation, {
    input: {
      id: formulaDB.id,
      registros: formulaDB.registros,
    },
  })) as GraphQLResult<APIt.UpdateTramiteMutation>;
}

async function executeQuery(query: string, input: any) {
  return await API.graphql(graphqlOperation(query, input));
}
