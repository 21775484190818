import { useCallback, useState } from "react";
import { Card, Grid, Icon, Switch } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDInput from "components/md/MDInput";
import MDTypography from "components/md/MDTypography";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { useNavigate } from "react-router-dom";
import AdvancedSearch from "features/CodigoFormula/CodigoFormulaList/AdvancedSearch";
import DownloadExcel from "./DownloadExcel";

/* eslint-disable */
type RefreshCallback = (val?: string) => void;
/* eslint-enable */

type Props = {
  refresh: RefreshCallback;
  exportFormulas: any;
};
export default function SearchBar(params: Props): JSX.Element {
  const { refresh, exportFormulas } = params;
  const navigate = useNavigate();
  const {
    state: { formulaSearchState, userRol },
    dispatch,
  } = useTramitesAppStateContext();
  const canEdit = userRol === "Global";

  const [localSearch, setLocalSearch] = useState(formulaSearchState.full);

  const handleSearch = useCallback(() => {
    dispatch({
      type: "update-formulaSearchState",
      payload: {
        ...formulaSearchState,
        full: localSearch,
      },
    });
    refresh(localSearch);
  }, [formulaSearchState, localSearch]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Grid container alignItems="center" my={2} mr={2}>
              <Grid item xs={12} md={9}>
                <MDBox display="flex" ml={2}>
                  <Grid container alignItems="center" my={2} mr={2}>
                    <Grid item md={12} lg={6} xl={7}>
                      <MDInput
                        style={{ marginLeft: "5px" }}
                        placeholder="Buscar"
                        size="small"
                        fullWidth
                        value={localSearch}
                        onChange={(e: any) => {
                          setLocalSearch(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={5} lg={2} xl={1}>
                      <MDBox ml={1}>
                        <MDButton variant="contained" color="info" onClick={handleSearch}>
                          Buscar
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item md={1} lg={1} xl={1}>
                      <MDBox ml={2}>
                        <DownloadExcel exportFormulas={exportFormulas} />
                      </MDBox>
                    </Grid>
                    <Grid item md={6} lg={3} xl={3}>
                      <MDBox ml={1}>
                        <Grid container direction="row">
                          <Grid item md={4}>
                            <MDBox mt={-0.6}>
                              <Switch
                                onChange={(e) =>
                                  dispatch({
                                    type: "update-formulaSearchState",
                                    payload: {
                                      ...formulaSearchState,
                                      advanced: e.target.checked,
                                    },
                                  })
                                }
                                checked={formulaSearchState.advanced}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={8}>
                            <MDTypography variant="h6">Avanzada</MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
                {canEdit && (
                  <MDBox mr={3}>
                    <MDButton variant="gradient" color="info" onClick={() => navigate("nueva")}>
                      <Icon>add</Icon>&nbsp; Nueva fórmula
                    </MDButton>
                  </MDBox>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {formulaSearchState.advanced && <AdvancedSearch />}
      </Grid>
    </form>
  );
}
