import { linkBase } from "globalvars";
import { ExportConfigEntry } from "../ExcelExporter";

const formulasConfig: ExportConfigEntry[] = [
  {
    id: "link",
    column: "Link",
    field: "id",
    extractionFunction: (data: any) => `${linkBase}codigo-formula/${data}`,
  },
  {
    id: "codigo",
    column: "Codigo",
    field: "codigo",
  },
  {
    id: "marca",
    column: "Marca",
    field: "marca",
  },
  {
    id: "producto",
    column: "Producto",
    field: "producto",
  },
  {
    id: "presentaciones",
    column: "Presentaciones",
    field: "presentaciones",
  },
  {
    id: "categoria",
    column: "Categoria",
    field: "categoria.codigo",
  },
  {
    id: "bu",
    column: "B.U.",
    field: "bu.nombre",
  },
  {
    id: "formaCosmeticaFarmaceutica",
    column: "FF/FC",
    field: "formaCosmeticaFarmaceutica.nombre",
  },
  {
    id: "categoriaTerapeutica",
    column: "Categoría Terapéutica",
    field: "categoriaTerapeutica.nombre",
  },
  {
    id: "dossierCompleteness",
    column: "% de Dossier",
    field: "dossierCompleteness",
  },
  /**
   *
   * FABRICANTES PT JOINED
   *
   */
  {
    id: "fabricantePTJoined",
    column: "Fabricante PT",
    field: "fabricantes",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => (acc ? `${acc}|${curr.nombre}` : curr.nombre), ""),
  },
  {
    id: "fabricantePTDireccionJoined",
    column: "Dirección Fabricante PT",
    field: "fabricantes",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.direccion}` : curr.direccion),
        ""
      ),
  },
  {
    id: "fabricantePTPaisJoined",
    column: "País Fabricante PT",
    field: "fabricantes",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.pais.nombre}` : curr.pais.nombre),
        ""
      ),
  },
  {
    id: "fabricantePTVencimientoJoined",
    column: "Vencimiento Fabricante PT",
    field: "fabricantes",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.vencimientoBPM}` : curr.vencimientoBPM),
        ""
      ),
  },
  /**
   *
   * FABRICANTES PT GROUP
   *
   */
  {
    id: "fabricantesPTGroup",
    column: "Fabricantes PT",
    field: "fabricantes",
    isGroup: true,
    hidden: true,
    groupMatchFilter: () => true,
    group: [
      {
        id: "fabricantePT",
        column: "Fabricante PT",
        field: "nombre",
      },
      {
        id: "fabricantePTDireccion",
        column: "Dirección Fabricante PT",
        field: "direccion",
      },
      {
        id: "fabricantePTPais",
        column: "País Fabricante PT",
        field: "pais.nombre",
      },
      {
        id: "fabricantePTVencimiento",
        column: "Vencimiento Fabricante PT",
        field: "vencimientoBPM",
      },
    ],
  },
  /**
   *
   * PRINCIPIOS ACTIVOS JOINED
   *
   */
  {
    id: "principioActivoJoined",
    column: "Principio/Ingrediente",
    field: "principiosActivos",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) =>
          acc ? `${acc}|${curr.principio.nombre}` : curr.principio.nombre,
        ""
      ),
  },
  {
    id: "principioActivoConcentracionJoined",
    column: "Concentración",
    field: "principiosActivos",
    extractionFunction: (data: any) =>
      data?.reduce(
        (acc: string, curr: any) => (acc ? `${acc}|${curr.concentracion}` : curr.concentracion),
        ""
      ),
  },
  {
    id: "principioActivoFabricanteJoined",
    column: "Fabricante API",
    field: "principiosActivos",
    extractionFunction: (data: any) =>
      data?.reduce((acc: string, curr: any) => {
        let ret = acc;
        curr.fabricantes?.forEach((fabricante: any) => {
          if (!ret.includes(fabricante.nombre))
            ret = ret ? `${ret}|${fabricante.nombre}` : fabricante.nombre;
        });
        return ret;
      }, ""),
  },
  /**
   *
   * PRINCIPIOS ACTIVOS GROUP
   *
   */
  {
    id: "principiosActivosGroup",
    column: "Principios Activos",
    field: "principiosActivos",
    isGroup: true,
    hidden: true,
    groupMatchFilter: () => true,
    group: [
      {
        id: "principioActivo",
        column: "Principio/Ingrediente",
        field: "principio.nombre",
      },
      {
        id: "principioActivoConcentracion",
        column: "Concentración",
        field: "concentracion",
      },
      {
        id: "fabricantesAPIGroup",
        column: "Fabricantes API",
        field: "fabricantes",
        isGroup: true,
        hidden: true,
        groupMatchFilter: () => true,
        group: [
          {
            id: "fabricanteAPI",
            column: "Fabricante API",
            field: "nombre",
          },
          {
            id: "fabricanteAPIDireccion",
            column: "Dirección Fabricante API",
            field: "direccion",
          },
          {
            id: "fabricanteAPIPais",
            column: "País Fabricante API",
            field: "pais.nombre",
          },
          {
            id: "fabricanteAPIVencimiento",
            column: "Vencimiento Fabricante API",
            field: "vencimientoBPM",
          },
        ],
      },
    ],
  },
];

export default formulasConfig;
