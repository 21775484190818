/* tslint:disable */
/* eslint-disable */

import { Grid } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDInput from "components/md/MDInput";
import MDProgress from "components/md/MDProgress";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useState } from "react";
import * as Service from "services/Importacion/ImportacionCRUD";

function ImportFormulas(): JSX.Element {
  const [Progress, setProgress] = useState({ done: 0, total: 0 });

  const [FormulaIn, setFormulaIn] = useState("");
  const [FormulaPrincipioIn, setformulaPrincipioIn] = useState("");

  const tuto = {
    FormulaIn,
    FormulaPrincipioIn,
  };

  const importFormulas = () => {
    Service.importFormulas(tuto, setProgress);
  };

  return (
    <Layout>
      <Navbar />
      <MDBox>
        <Grid container spacing={4}>
          <Grid item sm={2}>
            Formulas:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setFormulaIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>
          <Grid item sm={2}>
            Formula/Principios:
          </Grid>
          <Grid item sm={10}>
            <MDInput
              type="file"
              onChange={(e: any) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setformulaPrincipioIn(reader.result as string);
                };
                reader.readAsText(e.target.files[0]);
              }}
              placeholder="IN"
            />
          </Grid>

          <Grid item sm={2}>
            <MDButton onClick={importFormulas}>Importar</MDButton>
          </Grid>
          <Grid item sm={10}>
            <MDProgress
              variant="gradient"
              value={parseFloat(((Progress.done / Progress.total) * 100).toFixed(2)) || 0}
              label
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={12}> </MDBox>
    </Layout>
  );
}
export default ImportFormulas;
