export const listBusinessUnits = /* GraphQL */ `
  query ListBusinessUnits($filter: ModelBusinessUnitFilterInput, $limit: Int, $nextToken: String) {
    listBusinessUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        codigo
        nombre
      }
      nextToken
    }
  }
`;

export const createBusinessUnitMutation = /* GraphQL */ `
  mutation CreateBusinessUnit(
    $input: CreateBusinessUnitInput!
    $condition: ModelBusinessUnitConditionInput
  ) {
    createBusinessUnit(input: $input, condition: $condition) {
      id
      codigo
      nombre
    }
  }
`;

export const getBusinessUnit = /* GraphQL */ `
  query GetBusinessUnit($id: ID!) {
    getBusinessUnit(id: $id) {
      id
      codigo
      nombre
    }
  }
`;
