import { FormaCosmeticaFarmaceutica } from "API";
import { useState, useEffect } from "react";

import fetchFormaCosmeticaFarmaceuticaesList from "services/FormaCosmeticaFarmaceutica/FormaCosmeticaFarmaceuticaList";

const useFormaCosmeticaFarmaceuticaOptions = (): [FormaCosmeticaFarmaceutica[], () => void] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchFormaCosmeticaFarmaceuticaesList().then((result) => {
        const { formaCosmeticaFarmaceuticas } = result;
        setItems(formaCosmeticaFarmaceuticas);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useFormaCosmeticaFarmaceuticaOptions;
