import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/md/MDButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContentText,
  Divider,
  Grid,
  Icon,
  Switch,
} from "@mui/material";
import ExportCheck from "features/Common/ExportColumnCheck";
import MDTypography from "components/md/MDTypography";

type Props = {
  exportFormulas: any;
};

function DownloadExcel(params: Props): JSX.Element {
  const { exportFormulas } = params;
  const [open, setOpen] = React.useState(false);
  const [columns, setColumns] = React.useState({
    link: true,
    codigo: true,
    marca: true,
    producto: true,
    presentaciones: true,
    categoria: false,
    bu: false,
    formaCosmeticaFarmaceutica: false,
    categoriaTerapeutica: false,
    dossierCompleteness: false,
    usefabricantePTJoined: true,
    // FABRICANTES JOINED
    fabricantePTJoined: true,
    fabricantePTDireccionJoined: false,
    fabricantePTPaisJoined: false,
    fabricantePTVencimientoJoined: false,
    // FABRICANTES GROUP
    fabricantePT: false,
    fabricantePTDireccion: false,
    fabricantePTPais: false,
    fabricantePTVencimiento: false,
    usePrincipioActivoJoined: true,
    // PRINCIPIOS ACTIVOS JOINED
    principioActivoJoined: true,
    principioActivoConcentracionJoined: false,
    principioActivoFabricanteJoined: false,
    // PRINCIPIOS ACTIVOS GROUP
    principioActivo: false,
    principioActivoConcentracion: false,
    fabricanteAPI: false,
    fabricanteAPIDireccion: false,
    fabricanteAPIPais: false,
    fabricanteAPIVencimiento: false,
  });

  const handleFabricantePTJoined = () => {
    const usefabricantePTJoined = !columns.usefabricantePTJoined;
    if (!usefabricantePTJoined) {
      setColumns({
        ...columns,
        fabricantePTJoined: false,
        fabricantePTDireccionJoined: false,
        fabricantePTPaisJoined: false,
        fabricantePTVencimientoJoined: false,
        usefabricantePTJoined,
      });
    } else {
      setColumns({
        ...columns,
        fabricantePT: false,
        fabricantePTDireccion: false,
        fabricantePTPais: false,
        fabricantePTVencimiento: false,
        usefabricantePTJoined,
      });
    }
  };

  const handlePrincipioActivoJoined = () => {
    const usePrincipioActivoJoined = !columns.usePrincipioActivoJoined;
    if (!usePrincipioActivoJoined) {
      setColumns({
        ...columns,
        principioActivoJoined: false,
        principioActivoConcentracionJoined: false,
        principioActivoFabricanteJoined: false,
        usePrincipioActivoJoined,
      });
    } else {
      setColumns({
        ...columns,
        principioActivo: false,
        principioActivoConcentracion: false,
        fabricanteAPI: false,
        fabricanteAPIDireccion: false,
        fabricanteAPIPais: false,
        fabricanteAPIVencimiento: false,
        usePrincipioActivoJoined,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    exportFormulas(columns);
    setOpen(false);
  };

  return (
    <div>
      <MDButton
        variant="gradient"
        color="info"
        iconOnly
        onClick={handleClickOpen}
        title="Descargar como Excel"
      >
        <Icon sx={{ fontWeight: "bold" }}>savealt</Icon>
      </MDButton>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle>Descargar como Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione los campos que desea incluir en el archivo descargado.
          </DialogContentText>
          <Divider />
          <Accordion defaultExpanded>
            <AccordionSummary>Datos generales del trámite</AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" spacing={1}>
                <ExportCheck
                  column="codigo"
                  description="Código"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="marca"
                  description="Marca"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="producto"
                  description="Producto"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="presentaciones"
                  description="Presentaciones"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="categoria"
                  description="Categoría"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck column="bu" description="B.U." setState={setColumns} state={columns} />
                <ExportCheck
                  column="formaCosmeticaFarmaceutica"
                  description="FF/FC"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="categoriaTerapeutica"
                  description="Categoría terapéutica"
                  setState={setColumns}
                  state={columns}
                />
                <ExportCheck
                  column="dossierCompleteness"
                  description="% Dossier"
                  setState={setColumns}
                  state={columns}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion>
            <AccordionSummary>Datos de fabricante PT</AccordionSummary>
            <Switch checked={columns.usefabricantePTJoined} onChange={handleFabricantePTJoined} />
            <MDTypography variant="h7">Agrupar datos datos en una única línea</MDTypography>
            <AccordionDetails>
              {columns.usefabricantePTJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="fabricantePTJoined"
                    description="Fabricante PT"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricantePTDireccionJoined"
                    description="Fabricante PT - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricantePTPaisJoined"
                    description="Fabricante PT - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricantePTVencimientoJoined"
                    description="Fabricante PT - Vencimiento"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
              {!columns.usefabricantePTJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="fabricantePT"
                    description="Fabricante PT"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricantePTDireccion"
                    description="Fabricante PT - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricantePTPais"
                    description="Fabricante PT - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricantePTVencimiento"
                    description="Fabricante PT - Vencimiento"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Datos de Principios/Ingredientes</AccordionSummary>
            <Switch
              checked={columns.usePrincipioActivoJoined}
              onChange={handlePrincipioActivoJoined}
            />
            <MDTypography variant="h7">Agrupar datos datos en una única línea</MDTypography>
            <AccordionDetails>
              {columns.usePrincipioActivoJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="principioActivoJoined"
                    description="Principio/Ingrediente"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="principioActivoConcentracionJoined"
                    description="Concentración"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="principioActivoFabricanteJoined"
                    description="Fabricante API"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
              {!columns.usePrincipioActivoJoined && (
                <Grid container direction="row" spacing={1}>
                  <ExportCheck
                    column="principioActivo"
                    description="Principio/Ingrediente"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="principioActivoConcentracion"
                    description="Concentración"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricanteAPI"
                    description="Fabricante API"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricanteAPIDireccion"
                    description="Fabricante API - Dirección"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricanteAPIPais"
                    description="Fabricante API - País"
                    setState={setColumns}
                    state={columns}
                  />
                  <ExportCheck
                    column="fabricanteAPIVencimiento"
                    description="Fabricante API - Vencimiento"
                    setState={setColumns}
                    state={columns}
                  />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DownloadExcel;
