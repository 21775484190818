export const getAutoridad = /* GraphQL */ `
  query GetAutoridad($id: ID!) {
    getAutoridad(id: $id) {
      id
      nombre
      pais {
        id
        codigo
        nombre
      }
      paisId
    }
  }
`;
export const listAutoridads = /* GraphQL */ `
  query ListAutoridads($filter: ModelAutoridadFilterInput, $limit: Int, $nextToken: String) {
    listAutoridads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
        pais {
          id
          codigo
          nombre
        }
      }
      nextToken
    }
  }
`;
export const createAutoridadMutation = /* GraphQL */ `
  mutation CreateAutoridad(
    $input: CreateAutoridadInput!
    $condition: ModelAutoridadConditionInput
  ) {
    createAutoridad(input: $input, condition: $condition) {
      id
      nombre
      paisId
    }
  }
`;
export const updateAutoridadMutation = /* GraphQL */ `
  mutation UpdateAutoridad(
    $input: UpdateAutoridadInput!
    $condition: ModelAutoridadConditionInput
  ) {
    updateAutoridad(input: $input, condition: $condition) {
      id
      nombre
      paisId
    }
  }
`;
