import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import AprobarDlgRegistroFabricante from "./AprobarDlgRegistroFabricante";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
export default function AprobarDlgRegistro(params: Props): JSX.Element {
  const { formMethods } = params;
  const {
    control,
    formState: { errors },
  } = formMethods;

  const { fields } = useFieldArray({
    control,
    name: "presentaciones",
    keyName: "arrayKey",
  });

  const { fields: formulaFields } = useFieldArray({
    control,
    name: "formulas",
    keyName: "arrayKey",
  });

  const hasOTC = formulaFields?.reduce(
    (acc: boolean, curr: APIt.RegistroFormulaVO) => acc || curr.categoria.codigo === "OTC",
    true
  );

  return (
    <MDBox>
      {hasOTC && (
        <MDBox>
          <Divider />
          <MDTypography variant="h3">Condición de venta</MDTypography>
          {formulaFields?.map((formula: APIt.RegistroFormulaVO, index: number) => (
            <Grid container direction="row" key={formula.id} display="flex">
              <Grid item xs={2}>
                <MDTypography variant="h4" sx={{ mt: 1.5 }}>
                  {formula.codigo}
                </MDTypography>
              </Grid>
              <Grid item xs={10}>
                <ControlledAutocomplete
                  label=""
                  name={`formulas.${index}.condicionVenta`}
                  options={[
                    { id: APIt.CondicionVenta.OTC, nombre: APIt.CondicionVenta.OTC },
                    { id: APIt.CondicionVenta.RX, nombre: APIt.CondicionVenta.RX },
                    {
                      id: APIt.CondicionVenta.VentaLibre,
                      nombre: "Venta Libre",
                    },
                  ]}
                  control={control as any}
                  errors={
                    errors.formulas &&
                    errors.formulas[index] &&
                    errors.formulas[index].condicionVenta?.message
                  }
                />
              </Grid>
            </Grid>
          ))}
        </MDBox>
      )}
      <MDBox>
        {fields.map((presentacion: APIt.RegistroPresentacionesVO, presentacionIndex: number) => (
          <MDBox key={`${presentacion.formulas.length}.${presentacionIndex + 1}`}>
            <Divider />
            <MDBox display="flex">
              <MDBox marginRight={1}>
                <MDTypography variant="h5">
                  {presentacion.formulas.map((formula: string) => `${formula}, `)}
                </MDTypography>
              </MDBox>
              <MDBox marginRight={1}>
                <MDTypography variant="h5">
                  {presentacion.producto}/{presentacion.presentaciones}
                </MDTypography>
              </MDBox>
            </MDBox>
            {presentacion.fabricantes?.map(
              (fabricante: APIt.RegistroFabricanteVO, fabricanteIndex: number) => (
                <AprobarDlgRegistroFabricante
                  formMethods={formMethods}
                  presentacion={presentacion}
                  presentacionIndex={presentacionIndex}
                  fabricante={fabricante}
                  fabricanteIndex={fabricanteIndex}
                />
              )
            )}
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
}
