import { Grid, Switch } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FormulaYPresentacionCombo from "features/Common/FormulaYPresentacionCombo";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function NuevoPicker(data: Props): JSX.Element {
  const { formMethods } = data;

  const {
    control,
    formState: { errors },
    setValue,
  } = formMethods;
  const pais = useWatch({ control, name: "pais" });

  const {
    state: { autoridadList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const paises: APIt.PaisVO[] = [];
  const paisesCheck: string[] = [];
  autoridadList?.forEach((autoridad: APIt.Autoridad) => {
    if (
      !paisesCheck.includes(autoridad.pais.codigo) &&
      (userRol === "Global" || userPaises.includes(autoridad.pais.codigo))
    ) {
      paisesCheck.push(autoridad.pais.codigo);
      paises.push(autoridad.pais as any);
    }
  });

  return (
    <Grid container spacing={3}>
      <FormulaYPresentacionCombo formMethods={formMethods} pickPresentacion />
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="País"
          name="pais"
          errors={errors.pais?.message}
          options={paises}
          control={control as any}
          onChange={() => {
            setValue("autoridad", null);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="Autoridad"
          name="autoridad"
          errors={errors.autoridad?.message}
          options={
            autoridadList.filter((autoridad: APIt.Autoridad) => autoridad.pais.id === pais?.id) ||
            []
          }
          control={control as any}
        />
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 2, sm: 1 }}
        >
          <MDBox mr={1}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>No</Grid>
              <Grid item>
                <Controller
                  name="innovacion"
                  control={control}
                  render={(props: any) => {
                    const { field } = props;
                    return (
                      <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item>Si</Grid>
            </Grid>
          </MDBox>
          <MDBox lineHeight={0} mx={2}>
            <MDTypography variant="button" color="text">
              ¿Es innovación?
            </MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default NuevoPicker;
