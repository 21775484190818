/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import React, { useEffect, useState } from "react";
import Layout from "components/tramites/Layout";
// import DefaultFormulaCard from "features/Tramite/FormulaCards/DefaultFormulaCard";

import Navbar from "components/tramites/Navbar";
import Card from "@mui/material/Card";

import { Backdrop, CircularProgress } from "@mui/material";
import {
  useRegistroListPlano,
  formatRegistros,
} from "features/Registro/ServiceHooks/useRegistroList";
import SearchBar from "features/Registro/RegistroList/SearchBar";

import { useFilters, useSortBy, useTable } from "react-table";
import { useTramitesAppStateContext } from "features/TramitesAppContext";

import useTableConfig from "./useTableConfig";

const hasPrincipios = (columnOrder: string[]) =>
  columnOrder.includes("ingrediente") ||
  columnOrder.includes("ingredienteFabricante") ||
  columnOrder.includes("ingredienteDireccion") ||
  columnOrder.includes("concentracion");

function RegistroList(): JSX.Element {
  const { useInstance, allColumnDef, defaultColumn, filterTypes } = useTableConfig();

  const {
    dispatch,
    state: { registroSearchState },
  } = useTramitesAppStateContext();
  const { filters, columnOrder } = registroSearchState;
  const existingFilters = Object.keys(filters);

  const [registros, isLoading, exportRegistros] = useRegistroListPlano();

  const setColumnOrder = (newOrder: string[]) => {
    dispatch({
      type: "update-registroSearchState",
      payload: { ...registroSearchState, columnOrder: newOrder },
    });
  };
  const hasPrincipiosSelected = hasPrincipios(columnOrder);
  const [data, setData] = useState(formatRegistros(registros, hasPrincipiosSelected));

  const updateMyData = (rowIndex: number, columnId: string, value: any) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    setData(formatRegistros(registros, hasPrincipiosSelected));
  }, [registros, hasPrincipiosSelected]);

  const columns = React.useMemo(
    () => columnOrder.map((c) => allColumnDef.find((d) => d.accessor === c)),
    [columnOrder]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowSpanHeaders } =
    useTable(
      {
        columns,
        data,
        initialState: {
          filters: existingFilters.map((e) => ({ id: e, value: filters[e] })),
        },
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        autoResetAll: false,
        autoResetFilters: false,
        autoResetSortBy: false,
        updateMyData,
      } as any,
      (hooks) => {
        hooks.useInstance.push(useInstance);
      },
      useFilters,
      useSortBy
    );

  return (
    <Layout>
      <Navbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Registros sanitarios
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar
          exportRegistros={() => {
            const result: any[] = [];
            const check: string[] = [];
            rows.forEach((row) => {
              const checkStr = JSON.stringify(Object.values(row.values));
              if (!check.includes(checkStr)) {
                result.push(Object.values(row.values));
                check.push(checkStr);
              }
            });

            const header = columnOrder.map(
              (c) => allColumnDef.find((d) => d.accessor === c).Header
            );
            exportRegistros(result, header);
          }}
          allColumnDef={allColumnDef}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
        />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Card>
            <MDBox margin={2}>
              <div className="virtualizedtablecontainer">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            <MDBox display="flex" flexDirection="row" marginTop={4}>
                              <span {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                              </span>
                              <span onClick={() => column.toggleSortBy(!column.isSortedDesc)}>
                                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                              </span>

                              <div>
                                <span
                                  style={{
                                    color:
                                      column.filterValue && column.filterValue.length ? "blue" : "",
                                  }}
                                >
                                  {column.canFilter ? column.render("Filter") : null}
                                </span>
                              </div>
                            </MDBox>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);

                      // eslint-disable-next-line no-plusplus
                      for (let j = 0; j < row.allCells.length; j++) {
                        const cell: any = row.allCells[j];
                        const rowSpanHeader = rowSpanHeaders.find(
                          (x: any) => x.id === cell.column.id
                        );

                        if (rowSpanHeader !== undefined) {
                          const parentId = rowSpanHeader.parentIds?.find((id: string) =>
                            row.allCells.some((c) => c.column.id === id)
                          );
                          const parentCell = row.allCells.find((c) => c.column.id === parentId);

                          if (
                            rowSpanHeader.topCellValue === null ||
                            rowSpanHeader.topCellValue !== cell.value ||
                            (rowSpanHeader.parentIds?.length > 0 &&
                              parentCell &&
                              (!rowSpanHeader.parentValue ||
                                rowSpanHeader.parentValue !== parentCell?.value))
                          ) {
                            // RESET
                            cell.isRowSpanned = false;
                            rowSpanHeader.topCellValue = cell.value;
                            rowSpanHeader.topCellIndex = i;
                            rowSpanHeader.parentValue = parentCell?.value;
                            cell.rowSpan = 1;
                          } else {
                            // ADD
                            // eslint-disable-next-line no-plusplus
                            (rows[rowSpanHeader.topCellIndex].allCells[j] as any).rowSpan++;
                            cell.isRowSpanned = true;
                          }
                        }
                      }
                      return null;
                    })}
                    {rows.map((row) => {
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell: any) => {
                            if (cell.isRowSpanned) return null;
                            return (
                              <td
                                rowSpan={cell.rowSpan}
                                {...cell.getCellProps()}
                                tilte={cell.render("Cell") as string}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default RegistroList;
