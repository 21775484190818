import { Card } from "@mui/material";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import DetalleFormula from "./DetalleFormula";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
};

function DetalleFormulas(params: Props): JSX.Element {
  const {
    formMethods: { control },
    formMethods,
    formulas,
  } = params;

  const { fields } = useFieldArray({
    control,
    name: "formulas",
    keyName: "arrayKey",
  });

  return (
    <MDBox px={3}>
      {fields?.map((formula: APIt.RegistroFormulaVO, index: number) => (
        <Card sx={{ pb: 1, mb: 1, backgroundColor: "HoneyDew" }} key={formula.id}>
          <DetalleFormula
            formMethods={formMethods}
            formulas={formulas}
            formula={formula}
            index={index}
          />
        </Card>
      ))}
    </MDBox>
  );
}

export default DetalleFormulas;
