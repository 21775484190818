import { DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import { dashboardColors } from "globalvars";

const useData = (initialData: any[]) => {
  const data: any = {
    labels: [],
    datasets: [],
  };

  const labels = ["On Time", "Vencidos", "En Curso", "Retrasados"];

  let header = "Por Iniciativa - Sin datos";

  if (initialData?.length > 0) {
    // ADD INICIATIVA LABELS
    const unsortedLabels: string[] = [];
    initialData.forEach((entry: any) => {
      const addIniciativaLabel = (tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre || unsortedLabels.includes(tramite.iniciativa?.nombre))
          return;

        unsortedLabels.push(tramite.iniciativa?.nombre);
      };

      entry.sometimientosOnTime?.forEach(addIniciativaLabel);
      entry.sometimientosVencidos?.forEach(addIniciativaLabel);
      entry.sometimientosEnCurso?.forEach(addIniciativaLabel);
      entry.sometimientosRetrasados?.forEach(addIniciativaLabel);
    });
    const labels = unsortedLabels.sort();

    // ADD DATASETS
    const tmpOnTimeData: any = {};
    const tmpVencidosData: any = {};
    const tmpEnCursoData: any = {};
    const tmpRetrasadosData: any = {};
    initialData.forEach((entry: any) => {
      entry.sometimientosOnTime?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpOnTimeData[tramite.iniciativa?.nombre])
          tmpOnTimeData[tramite.iniciativa?.nombre] = 0;
        tmpOnTimeData[tramite.iniciativa?.nombre] += 1;
      });
      entry.sometimientosVencidos?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpVencidosData[tramite.iniciativa?.nombre])
          tmpVencidosData[tramite.iniciativa?.nombre] = 0;
        tmpVencidosData[tramite.iniciativa?.nombre] += 1;
      });
      entry.sometimientosEnCurso?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpEnCursoData[tramite.iniciativa?.nombre])
          tmpEnCursoData[tramite.iniciativa?.nombre] = 0;
        tmpEnCursoData[tramite.iniciativa?.nombre] += 1;
      });
      entry.sometimientosRetrasados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tramite.iniciativa?.nombre) return;
        if (!tmpRetrasadosData[tramite.iniciativa?.nombre])
          tmpRetrasadosData[tramite.iniciativa?.nombre] = 0;
        tmpRetrasadosData[tramite.iniciativa?.nombre] += 1;
      });
    });

    const datasetOnTime = {
      label: "On Time",
      data: labels.map((iniciativa: string) => tmpOnTimeData[iniciativa] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetOnTime);

    const datasetVencidos = {
      label: "Vencidos",
      data: labels.map((iniciativa: string) => tmpVencidosData[iniciativa] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetVencidos);

    const datasetEnCurso = {
      label: "En Curso",
      data: labels.map((iniciativa: string) => tmpEnCursoData[iniciativa] || 0),
      backgroundColor: dashboardColors.yellow.background,
    };
    data.datasets.push(datasetEnCurso);

    const datasetRetrasados = {
      label: "Retrasados",
      data: labels.map((iniciativa: string) => tmpRetrasadosData[iniciativa] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetRetrasados);

    data.labels = labels.map((iniciativa: string) => {
      const onTime = tmpOnTimeData[iniciativa] || 0;
      const vencidos = tmpVencidosData[iniciativa] || 0;
      const retrasados = tmpRetrasadosData[iniciativa] || 0;

      const total = onTime + vencidos + retrasados;
      let percentage = (onTime / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${iniciativa} (${percentage.toFixed(2)}%)`;
    });

    header = "Por Iniciativa";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
