import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

const useGroups = () => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        const grupos = data.signInUserSession.accessToken.payload["cognito:groups"];
        setGroups(grupos);
      })
      .catch((e) => console.log(e));
  }, []);
  return groups;
};

const useUserName = () => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        setUserName(data.attributes.email);
      })
      .catch((e) => console.log(e));
  }, []);
  return userName;
};

export { useGroups, useUserName };
