import { Grid, Switch } from "@mui/material";
import MDTypography from "components/md/MDTypography";

type Props = {
  state: any;
  setState: any;
  column: string;
  description: string;
};
function ExportCheck(data: Props): JSX.Element {
  const { state, setState, column, description } = data;

  const handleChange = () => {
    const newState = { ...state };
    newState[column] = !newState[column];
    setState(newState);
  };

  return (
    <Grid item xs={6} sm={5} lg={3} xl={3}>
      <Switch checked={state[column]} onChange={handleChange} />
      <MDTypography variant="h7">{description}</MDTypography>
    </Grid>
  );
}

export default ExportCheck;
