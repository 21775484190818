import { BusinessUnit } from "API";
import { useState, useEffect } from "react";

import fetchBusinessUnitesList from "services/BusinessUnit/BusinessUnitList";

const useBusinessUnitOptions = (): [BusinessUnit[], () => void] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchBusinessUnitesList().then((result) => {
        const { businessUnits } = result;
        setItems(businessUnits);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useBusinessUnitOptions;
