import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/md/MDButton";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import MDBox from "components/md/MDBox";
import DateFormField from "components/tramites/DateFormField";
import { Grid, Icon, Switch } from "@mui/material";
import MDTypography from "components/md/MDTypography";
import { EstadoObjecion } from "API";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validations = Yup.object().shape({
  fechaCierre: Yup.date().required("Fecha de cierre requerida"),
});

/* eslint-disable */
type SetValueCallback = (values: any) => void;
/* eslint-enable */

type Props = {
  valueSetter: SetValueCallback;
  values: any;
};

function ObjecionCierreDialog(params: Props): JSX.Element {
  const { valueSetter, values: parentValues } = params;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e: any) => {
    const estado: EstadoObjecion = e.exitoso ? EstadoObjecion.aceptada : EstadoObjecion.rechazada;
    const data: any = { fechaCierre: e.fechaCierre, estado };
    valueSetter(data);
  };

  return (
    <div>
      <MDButton variant="text" color="dark" onClick={handleClickOpen}>
        <Icon>edit</Icon>&nbsp;Cerrar objeción
      </MDButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <Formik
          initialValues={{
            exitoso: false,
            fechaCierre: "",
            tramiteId: parentValues.id,
          }}
          validationSchema={validations}
          onSubmit={(e) => handleSave(e)}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => (
            <Form>
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Cierre de objeción
              </BootstrapDialogTitle>

              <DialogContent dividers>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox mr={1}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>No</Grid>
                      <Grid item>
                        <Switch
                          checked={values.exitoso}
                          onChange={() => setFieldValue("exitoso", !values.exitoso)}
                        />
                      </Grid>
                      <Grid item>Si</Grid>
                    </Grid>
                  </MDBox>
                  <MDBox lineHeight={0} mx={2}>
                    <MDTypography variant="button" color="text">
                      Respuesta favorable?
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </DialogContent>

              <DialogContent dividers>
                <MDBox mt={4}>
                  <DateFormField
                    label="Fecha"
                    type="date"
                    name="fechaCierre"
                    value={values.fechaCierre}
                    error={touched.fechaCierre && Boolean(errors.fechaCierre)}
                  />
                </MDBox>
              </DialogContent>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancelar
                </Button>
                <Button autoFocus onClick={submitForm}>
                  Ingresar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </BootstrapDialog>
    </div>
  );
}

export default ObjecionCierreDialog;
