import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Avatar from "components/tramites/Avatar";
import { Grid } from "@mui/material";
import { gridSizes } from "globalvars";
import FlagBuilder from "components/tramites/FlagBuilder";
import ReadOnlyField from "../DetalleTramite/ReadOnlyField";
import VerPrincipiosTable from "./VerPrincipiosTable";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulas: APIt.CodigoFormula[];
};
export default function VerFormulas(params: Props): JSX.Element {
  const { formulas } = params;

  return (
    <MDBox>
      {formulas?.map((formula: APIt.CodigoFormula) => (
        <MDBox key={formula.id} p={2} pl={4}>
          <MDBox display="flex">
            <Avatar title={formula.categoria?.nombre} size="sm" sx={{ mr: 1 }} bgColor="info">
              {formula.categoria?.codigo}
            </Avatar>
            <MDTypography variant="label" fontWeight="medium" textTransform="capitalize">
              {formula.codigo} - {formula.marca} - {formula.producto}
            </MDTypography>
          </MDBox>
          <MDBox pb={3} px={3}>
            <Grid container spacing={3} pt={4}>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="Presentaciones"
              >
                {formula.presentaciones}
              </ReadOnlyField>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="B.U."
              >
                {formula.bu?.nombre}
              </ReadOnlyField>
              <ReadOnlyField
                gridXS={gridSizes.xs}
                gridSM={gridSizes.sm}
                gridLGTop={gridSizes.lgtop}
                gridXLTop={gridSizes.xltop}
                title="FF/FC"
              >
                {formula.formaCosmeticaFarmaceutica?.nombre}
              </ReadOnlyField>
              {formula.categoria?.codigo === "OTC" && (
                <ReadOnlyField
                  gridXS={gridSizes.xs}
                  gridSM={gridSizes.sm}
                  gridLGTop={gridSizes.lgtop}
                  gridXLTop={gridSizes.xltop}
                  title="Categoría terapéutica"
                >
                  {formula.categoriaTerapeutica?.nombre}
                </ReadOnlyField>
              )}
              <ReadOnlyField
                gridXS={12}
                gridSM={12}
                gridLGTop={12}
                gridXLTop={12}
                title="Fabricantes PT"
              >
                {formula.fabricantes?.map((fabricante: APIt.FabricanteVO) => (
                  <MDBox key={fabricante.id} display="flex" p={1}>
                    <Avatar title={fabricante.pais.nombre} size="xs" sx={{ mr: 1 }}>
                      <FlagBuilder country={fabricante.pais.codigo} />
                    </Avatar>
                    <MDTypography
                      variant="label"
                      fontWeight="medium"
                      textTransform="capitalize"
                      sx={{ mt: 0.5 }}
                    >
                      {fabricante.nombre}
                    </MDTypography>
                  </MDBox>
                ))}
              </ReadOnlyField>
              {formula.categoria?.codigo === "OTC" && (
                <ReadOnlyField
                  gridXS={12}
                  gridSM={12}
                  gridLGTop={12}
                  gridXLTop={12}
                  title="Principios Activos"
                >
                  <VerPrincipiosTable formula={formula} />
                </ReadOnlyField>
              )}
              {formula.categoria?.codigo !== "OTC" && (
                <ReadOnlyField
                  gridXS={12}
                  gridSM={12}
                  gridLGTop={12}
                  gridXLTop={12}
                  title="Ingredientes"
                >
                  {formula.principiosActivos?.map(
                    (principio: APIt.RegistroPrincipioActivoVO) => `${principio.principio.nombre}, `
                  )}
                </ReadOnlyField>
              )}
            </Grid>
          </MDBox>
        </MDBox>
      ))}
    </MDBox>
  );
}
