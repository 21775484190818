/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// formik components
import { ErrorMessage, Field } from "formik";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import MDInput from "components/md/MDInput";

// Declaring props types for FormField
interface Props {
  label: string;
  name: string;
  [key: string]: any;
}

function DateFormField({ label, name, ...rest }: Props): JSX.Element {
  return (
    <MDBox mb={1.5} mt={-2.15}>
      <span
        style={{
          fontFamily: "Roboto, Helvetica,Arial",
          fontSize: "0.875rem",
          color: "#7b809a",
        }}
      >
        {label}
      </span>
      <Field {...rest} name={name} as={MDInput} variant="standard" fullWidth autocomplte="off" />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default DateFormField;
