import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/md/MDButton";
import { Icon } from "@mui/material";
import * as Yup from "yup";
import FormField from "components/tramites/FormField";
import MDBox from "components/md/MDBox";
import useFabricanteCRUD from "features/ServiceHooks/useFabricanteCRUD";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validations = Yup.object().shape({
  nombre: Yup.string().required("Nombre requerido"),
  direccion: Yup.string().required("Direccion requerido"),
  vencimientoBPM: Yup.string().required("Fecha vencimiento BPM requerida").nullable(true),
  pais: Yup.object().required("País requerido").nullable(true),
});

/* eslint-disable */
type SetValueCallback = (values: any) => void;
/* eslint-enable */

type Props = {
  valueSetter: SetValueCallback;
};

function NewFabricanteDialog(params: Props): JSX.Element {
  const { valueSetter } = params;
  const [open, setOpen] = React.useState(false);
  const [createFabricante] = useFabricanteCRUD();

  const {
    state: { suList },
  } = useTramitesAppStateContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const handleClickOpen = () => {
    reset({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e: any) => {
    createFabricante(e, valueSetter);
    setOpen(false);
  };

  return (
    <div>
      <MDButton variant="gradient" color="info" iconOnly onClick={handleClickOpen}>
        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
      </MDButton>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <form onSubmit={handleSave}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Nuevo fabricante
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <MDBox>
              <ControlledAutocomplete
                label="País"
                name="pais"
                options={suList}
                control={control}
                errors={errors.pais?.message}
              />
            </MDBox>
            <MDBox>
              <FormField
                type="text"
                label="Nombre"
                name="nombre"
                errors={errors.nombre?.message}
                register={register}
              />
            </MDBox>
            <MDBox>
              <FormField
                type="text"
                label="Direccion"
                name="direccion"
                errors={errors.direccion?.message}
                register={register}
              />
            </MDBox>
            <MDBox mt={3}>
              <FormField
                type="date"
                label="Fecha de vencimiento BPM"
                name="vencimientoBPM"
                errors={errors.vencimientoBPM?.message}
                register={register}
                control={control}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button autoFocus onClick={handleSubmit(handleSave)}>
              Crear y asignar
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}

export default NewFabricanteDialog;
