import { DashboardTramiteDaily } from "API";
import { getBaseSingleBarOptions } from "features/Dashboard/Utils";

const useData = (initialData: DashboardTramiteDaily[]) => {
  const data: any = {
    labels: [
      "Armado de Dossier",
      "Dossier en Desarrollo",
      "Sin Dossier",
      "Trámite en Proceso",
      "Aprobado",
      "Rechazado",
      "Desistido",
    ],
    datasets: [],
  };

  let header = "Total - Sin datos";

  if (initialData?.length > 0) {
    const armado = initialData?.reduce((acc, curr) => {
      if (curr.estatusArmado?.length) return acc + curr.estatusArmado.length;
      return acc;
    }, 0);
    const enDesarrollo = initialData?.reduce((acc, curr) => {
      if (curr.estatusEnDesarrollo?.length) return acc + curr.estatusEnDesarrollo.length;
      return acc;
    }, 0);
    const sinDossier = initialData?.reduce((acc, curr) => {
      if (curr.estatusSinDossier?.length) return acc + curr.estatusSinDossier.length;
      return acc;
    }, 0);
    const enProceso = initialData?.reduce((acc, curr) => {
      if (curr.estatusEnProceso?.length) return acc + curr.estatusEnProceso.length;
      return acc;
    }, 0);
    const aprobado = initialData?.reduce((acc, curr) => {
      if (curr.estatusAprobado?.length) return acc + curr.estatusAprobado.length;
      return acc;
    }, 0);
    const rechazado = initialData?.reduce((acc, curr) => {
      if (curr.estatusRechazado?.length) return acc + curr.estatusRechazado.length;
      return acc;
    }, 0);
    const desistido = initialData?.reduce((acc, curr) => {
      if (curr.estatusDesistido?.length) return acc + curr.estatusDesistido.length;
      return acc;
    }, 0);

    const dataset = {
      data: [armado, enDesarrollo, sinDossier, enProceso, aprobado, rechazado, desistido],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderWidth: 1,
    };
    data.datasets = [dataset];

    header = "Total";
  }

  const options = getBaseSingleBarOptions();

  options.plugins.legend.display = false;

  return { header, data, options };
};

export default useData;
