import { Grid } from "@mui/material";
import * as APIt from "API";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FormulaYPresentacionCombo from "features/Common/FormulaYPresentacionCombo";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { UseFormReturn } from "react-hook-form";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function CertificadoPicker(data: Props): JSX.Element {
  const { formMethods } = data;

  const {
    control,
    formState: { errors },
  } = formMethods;

  const {
    state: { suList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const paises = suList.filter((p) => userRol === "Global" || userPaises.includes(p.codigo));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="País emisor"
          name="pais"
          errors={errors.pais?.message}
          options={paises}
          control={control as any}
        />
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="País receptor"
          name="paisReceptor"
          errors={errors.paisReceptor?.message}
          options={suList}
          control={control as any}
        />
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="Tipo de certificado"
          name="tipoCertificado"
          errors={errors.tipoCertificado?.message}
          options={[
            {
              id: APIt.TipoCertificado.certifLibreVenta,
              nombre: "CLV (Certificado de Libre Venta)",
            },
            {
              id: APIt.TipoCertificado.certifProdFarma,
              nombre: "CPP (Certificado de Producto Farmacéutico)",
            },
            { id: APIt.TipoCertificado.certifExpo, nombre: "CE (Certificado de Exportación)" },
            {
              id: APIt.TipoCertificado.certifBuenasPracticasDeFabricacion,
              nombre: "GMP (Certificado de Buenas Practicas de Fabricación)",
            },
            { id: APIt.TipoCertificado.permisoImportacion, nombre: "PSI (Permiso de Importación)" },
          ]}
          control={control as any}
        />
      </Grid>
      <FormulaYPresentacionCombo formMethods={formMethods} pickPresentacion />
    </Grid>
  );
}

export default CertificadoPicker;
