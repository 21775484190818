import { Grid } from "@mui/material";
import { DashboardObjecionVO, DashboardTramiteDaily } from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import Layout from "components/tramites/Layout";
import Navbar from "components/tramites/Navbar";
import { useTramitesObjecionesData } from "features/Dashboard/useDashboardData";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { useEffect, useState } from "react";
import SearchBar from "../SearchBar";
import Total from "./Total";
import PorPais from "./PorPais";
import PorTipo from "./PorTipo";

function Objeciones(): JSX.Element {
  const [dbData, loading] = useTramitesObjecionesData();
  const { dashboardFilterState } = useDashboardFilters();
  const { paises, businessUnits } = dashboardFilterState;

  // IN MEMORY FILTER
  const [initialData, setInitialData] = useState([] as DashboardTramiteDaily[]);

  useEffect(() => {
    const filterPais = (objecion: DashboardObjecionVO) =>
      !paises || paises.length < 1 || paises.includes(objecion.tramite.pais?.codigo);
    const filterBU = (objecion: DashboardObjecionVO) => {
      const bus = objecion.tramite.businessUnit?.codigo?.split("|") || [];
      return (
        !businessUnits || businessUnits.length < 1 || bus.some((bu) => businessUnits.includes(bu))
      );
    };

    const iData = dbData.map((dashboardEntry: DashboardTramiteDaily) => {
      const objecionesAFavor = dashboardEntry.objecionesAFavor
        ?.filter(filterPais)
        ?.filter(filterBU);
      const objecionesDesistidas = dashboardEntry.objecionesDesistidas
        ?.filter(filterPais)
        ?.filter(filterBU);
      const objecionesEnContra = dashboardEntry.objecionesEnContra
        ?.filter(filterPais)
        ?.filter(filterBU);
      const objecionesEnCurso = dashboardEntry.objecionesEnCurso
        ?.filter(filterPais)
        ?.filter(filterBU);

      return {
        ...dashboardEntry,
        objecionesAFavor,
        objecionesDesistidas,
        objecionesEnContra,
        objecionesEnCurso,
      };
    });

    setInitialData(iData);
  }, [paises, businessUnits, dbData]);

  return (
    <Layout>
      <Navbar />
      <MDBox my={3} sx={{ padding: "0px", marginTop: "30px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox ml={1}>
            <MDTypography variant="h5" fontWeight="medium" sx={{ marginTop: "10px" }}>
              Total de Objeciones
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={3} sx={{ padding: "0px", marginTop: "0px" }}>
        <SearchBar />
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Total initialData={initialData} loading={loading} />
            </Grid>
            <Grid item xs={12} md={8}>
              <PorTipo initialData={initialData} loading={loading} />
            </Grid>
            <Grid item xs={12} md={8}>
              <PorPais initialData={initialData} loading={loading} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Layout>
  );
}

export default Objeciones;
