import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/md/MDButton";
import FormField from "components/tramites/FormField";
import React from "react";
import * as Yup from "yup";
import * as APIt from "API";
import * as DossierUtils from "services/Utils/DossierUtils";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

/* eslint-disable */
type ConfirmCallback = (e: any) => void;
/* eslint-enable */

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>;
  onConfirm: ConfirmCallback;
};

export default function LiberarDlg(params: Props) {
  const { formMethods, formulaFormMethods, onConfirm } = params;
  const [open, setOpen] = React.useState(false);

  const { control: tramiteControl, trigger } = formMethods;
  const dossierData = useWatch({ control: tramiteControl, name: "dossierData" });
  const dossierPreferencesString = useWatch({
    control: tramiteControl,
    name: "dossierPreferences",
  });

  const { control: formulasControl } = formulaFormMethods;
  const formulas = useWatch({ control: formulasControl, name: "formulas" });
  let fieldChildCount = 0;
  let done = -1;

  if (dossierPreferencesString) {
    const preferences = JSON.parse(dossierPreferencesString);

    // TRAMITE PROGRESS
    const fieldChilds: DossierUtils.TramiteDefinitionEntry[] = DossierUtils.getGroupFullFieldChilds(
      preferences.tramite.preferences,
      preferences.tramite.preferences.find(
        (e: DossierUtils.TramiteDefinitionEntry) => e.code === "root"
      )
    );
    fieldChildCount = fieldChilds.length;
    done = fieldChilds?.reduce(
      (acc: number, value: DossierUtils.TramiteDefinitionEntry) =>
        acc + (dossierData?.find((e: APIt.DossierField) => e.codigo === value.code)?.done ? 1 : 0),
      0
    );

    // FORMULA PROGRESS
    formulas?.forEach((formula: APIt.CodigoFormula) => {
      const { id, dossierData: formulaDossierData } = formula;
      if (preferences.formulas[id]) {
        const formulaFieldChilds: DossierUtils.TramiteDefinitionEntry[] =
          DossierUtils.getGroupFullFieldChilds(
            preferences.formulas[id].preferences,
            preferences.formulas[id].preferences.find(
              (e: DossierUtils.TramiteDefinitionEntry) => e.code === "root"
            )
          );
        fieldChildCount += formulaFieldChilds.length;
        done +=
          formulaFieldChilds?.reduce(
            (acc: number, value: DossierUtils.TramiteDefinitionEntry) =>
              acc +
              (formulaDossierData?.find((e: APIt.DossierField) => e.codigo === value.code)?.done
                ? 1
                : 0),
            0
          ) || 0;
      }
    });
  }

  const validations = Yup.object().shape({
    fechaLiberacion: Yup.string().required("Fecha de liberación requerida").nullable(),
  });
  const {
    register,
    formState: { errors },
    control,
    trigger: triggerLocal,
    getValues: getLocalValues,
    reset,
  } = useForm({
    resolver: yupResolver(validations),
  });

  const handleClickOpen = () => {
    trigger().then((valid: boolean) => {
      if (valid) setOpen(true);
      else toast.error("Verificar campos obligatorios");
      reset({
        fechaLiberacion: null,
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const e = getLocalValues();
    triggerLocal().then((valid: any) => {
      if (!valid) return;

      onConfirm(e);
    });
  };

  return (
    <div>
      {fieldChildCount === done && (
        <MDButton variant="gradient" color="success" onClick={handleClickOpen}>
          Liberar
        </MDButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Indicar como liberado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor indique la fecha de liberación de dossier.
          </DialogContentText>
          <FormField
            label="Fecha de liberación de dossier"
            type="date"
            name="fechaLiberacion"
            errors={errors.fechaLiberacion?.message}
            register={register}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirm}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
