import { DashboardTramiteVO } from "API";
import { getBaseBarOptions } from "features/Dashboard/Utils";
import useDashboardFilters from "features/Dashboard/useDashboardFilter";
import { dashboardColors } from "globalvars";

const useData = (initialData: any[]) => {
  const { dashboardFilterState } = useDashboardFilters();
  const { paises } = dashboardFilterState;

  const labels = ["On Time", "Vencidos", "En Curso", "Retrasados"];

  const data: any = {
    labels: [],
    datasets: [],
  };

  let header = "Por País - Sin datos";

  if (initialData?.length > 0) {
    // ADD PAIS LABELS
    const labels: string[] = [];
    initialData.forEach((entry: any) => {
      const addPaisLabel = (tramite: DashboardTramiteVO) => {
        if (labels.includes(tramite.pais.nombre)) return;

        if (paises && paises.length > 0 && !paises.includes(tramite.pais.codigo)) return;

        labels.push(tramite.pais.nombre);
      };

      entry.sometimientosOnTime?.forEach(addPaisLabel);
      entry.sometimientosVencidos?.forEach(addPaisLabel);
      entry.sometimientosEnCurso?.forEach(addPaisLabel);
      entry.sometimientosRetrasados?.forEach(addPaisLabel);
    });

    // ADD DATASETS
    // -- Aprobados
    const tmpOnTimeData: any = {};
    const tmpVencidosData: any = {};
    const tmpEnCursoData: any = {};
    const tmpRetrasadosData: any = {};
    initialData.forEach((entry: any) => {
      entry.sometimientosOnTime?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpOnTimeData[tramite.pais.nombre]) tmpOnTimeData[tramite.pais.nombre] = 0;
        tmpOnTimeData[tramite.pais.nombre] += 1;
      });
      entry.sometimientosVencidos?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpVencidosData[tramite.pais.nombre]) tmpVencidosData[tramite.pais.nombre] = 0;
        tmpVencidosData[tramite.pais.nombre] += 1;
      });
      entry.sometimientosEnCurso?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpEnCursoData[tramite.pais.nombre]) tmpEnCursoData[tramite.pais.nombre] = 0;
        tmpEnCursoData[tramite.pais.nombre] += 1;
      });
      entry.sometimientosRetrasados?.forEach((tramite: DashboardTramiteVO) => {
        if (!tmpRetrasadosData[tramite.pais.nombre]) tmpRetrasadosData[tramite.pais.nombre] = 0;
        tmpRetrasadosData[tramite.pais.nombre] += 1;
      });
    });

    const datasetOnTime = {
      label: "On Time",
      data: labels.map((pais: string) => tmpOnTimeData[pais] || 0),
      backgroundColor: dashboardColors.green.background,
    };
    data.datasets.push(datasetOnTime);

    const datasetVencidos = {
      label: "Vencidos",
      data: labels.map((pais: string) => tmpVencidosData[pais] || 0),
      backgroundColor: dashboardColors.red.background,
    };
    data.datasets.push(datasetVencidos);

    const datasetEnCurso = {
      label: "En Curso",
      data: labels.map((pais: string) => tmpEnCursoData[pais] || 0),
      backgroundColor: dashboardColors.yellow.background,
    };
    data.datasets.push(datasetEnCurso);

    const datasetRetrasados = {
      label: "Retrasados",
      data: labels.map((pais: string) => tmpRetrasadosData[pais] || 0),
      backgroundColor: dashboardColors.orange.background,
    };
    data.datasets.push(datasetRetrasados);

    data.labels = labels.map((pais: string) => {
      const onTime = tmpOnTimeData[pais] || 0;
      const vencidos = tmpVencidosData[pais] || 0;
      const retrasados = tmpRetrasadosData[pais] || 0;

      const total = onTime + vencidos + retrasados;
      let percentage = (onTime / total) * 100;
      percentage = Number.isNaN(percentage) ? 0 : percentage;

      return `${pais} (${percentage.toFixed(2)}%)`;
    });

    header = "Por País";
  }

  return { header, data, options: getBaseBarOptions(labels) };
};

export default useData;
