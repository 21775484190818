import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import NewObjecionDialog from "features/Tramite/TramiteEdit/DatosTramite/Objeciones/NewObjectionDialog";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as APIt from "API";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import ObjecionEntry from "./ObjecionEntry";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};

function Objeciones(data: Props): JSX.Element {
  const { formMethods } = data;
  const [expanded, setExpanded] = useState(true);
  const { control, getValues } = formMethods;
  const { estado, pais } = getValues();

  const { id: paisId } = pais;

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const { fields, prepend, update } = useFieldArray({
    control,
    name: "objeciones",
    keyName: "arrayKey",
  });

  const {
    state: { userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const canEdit =
    ([APIt.EstadoTramite.presentado, APIt.EstadoTramite.objetado].includes(estado) &&
      (isUserPais || isUserGlobal)) ||
    ([APIt.EstadoTramite.aprobado, APIt.EstadoTramite.rechazado].includes(estado) && isUserGlobal);

  return (
    <MDBox pb={1}>
      <Card>
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={handleExpand}
                sx={{
                  pointerEvents: "auto",
                }}
              />
            }
            sx={{
              pointerEvents: "none",
            }}
          >
            <MDBox display="flex" width="100%">
              <MDBox width="100%">
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  onClick={handleExpand}
                  sx={{
                    pointerEvents: "auto",
                  }}
                >
                  Objeciones
                </MDTypography>
              </MDBox>
              {canEdit && (
                <MDBox
                  mr={10}
                  sx={{
                    pointerEvents: "auto",
                  }}
                >
                  <NewObjecionDialog
                    formMethods={formMethods}
                    valueSetter={(newObjecion: any) => {
                      prepend(newObjecion);
                    }}
                    paisId={paisId}
                  />
                </MDBox>
              )}
            </MDBox>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {fields
                  ?.sort((a: APIt.ObjecionVO, b: APIt.ObjecionVO) =>
                    a.fechaCierre < b.fechaCierre ? 1 : -1
                  )
                  ?.map((item: any, index: number) => (
                    <ObjecionEntry
                      key={item.arrayKey}
                      formData={{
                        item,
                        update,
                        index,
                        canEdit,
                      }}
                    />
                  ))}
              </MDBox>
            </MDBox>
          </AccordionDetails>
        </Accordion>
      </Card>
    </MDBox>
  );
}

export default Objeciones;
