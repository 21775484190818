export const searchCodigoFormulasCombo = /* GraphQL */ `
  query ListCodigoFormulas(
    $filter: SearchableCodigoFormulaSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchCodigoFormulaSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        codigoFormula {
          id
          createdBy
          modifiedBy
          codigo
          marca
          producto
          presentaciones
          fabricantes {
            id
            pais {
              id
              codigo
              nombre
            }
            nombre
            direccion
            vencimientoBPM
          }
          categoria {
            id
            codigo
            nombre
          }
          bu {
            id
            codigo
            nombre
          }
          categoriaTerapeutica {
            id
            nombre
          }
          formaCosmeticaFarmaceutica {
            id
            nombre
          }
          principiosActivos {
            concentracion
            fabricantes {
              id
              pais {
                id
                codigo
                nombre
              }
              nombre
              direccion
              vencimientoBPM
            }
            principio {
              id
              nombre
            }
          }
          registros {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
        }
      }
      nextToken
    }
  }
`;

export const listCodigoFormulasCombo = /* GraphQL */ `
  query ListCodigoFormulas(
    $filter: ModelCodigoFormulaSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCodigoFormulaSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        codigoFormula {
          id
          createdBy
          modifiedBy
          codigo
          marca
          producto
          presentaciones
          fabricantes {
            id
            pais {
              id
              codigo
              nombre
            }
            nombre
            direccion
            vencimientoBPM
          }
          categoria {
            id
            codigo
            nombre
          }
          bu {
            id
            codigo
            nombre
          }
          categoriaTerapeutica {
            id
            nombre
          }
          formaCosmeticaFarmaceutica {
            id
            nombre
          }
          principiosActivos {
            concentracion
            fabricantes {
              id
              pais {
                id
                codigo
                nombre
              }
              nombre
              direccion
              vencimientoBPM
            }
            principio {
              id
              nombre
            }
          }
          registros {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
        }
      }
      nextToken
    }
  }
`;

export const searchCodigoFormulas = /* GraphQL */ `
  query ListCodigoFormulas(
    $filter: SearchableCodigoFormulaSearchFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableCodigoFormulaSearchSortInput]
  ) {
    searchCodigoFormulaSearches(
      sort: $sort
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        codigoFormula {
          id
          createdBy
          modifiedBy
          codigo
          marca
          dossierCompleteness
          tramites {
            id
            estado
            dossierCompleteness
            tipo
            pais {
              id
              codigo
              nombre
            }
          }
          bu {
            nombre
          }
          producto
          categoria {
            codigo
            nombre
          }
          categoriaTerapeutica {
            nombre
          }
          formaCosmeticaFarmaceutica {
            nombre
          }
          principiosActivos {
            principio {
              nombre
            }
          }
          fabricantes {
            pais {
              codigo
              nombre
            }
          }
          registros {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
        }
      }
      nextToken
    }
  }
`;

export const listCodigoFormulas = /* GraphQL */ `
  query ListCodigoFormulas(
    $filter: ModelCodigoFormulaSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCodigoFormulaSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        codigoFormula {
          id
          createdBy
          modifiedBy
          codigo
          marca
          dossierCompleteness
          tramites {
            id
            estado
            dossierCompleteness
            tipo
            pais {
              id
              codigo
              nombre
            }
          }
          bu {
            nombre
          }
          producto
          categoria {
            codigo
            nombre
          }
          categoriaTerapeutica {
            nombre
          }
          formaCosmeticaFarmaceutica {
            nombre
          }
          principiosActivos {
            principio {
              nombre
            }
          }
          fabricantes {
            pais {
              codigo
              nombre
            }
          }
          registros {
            autoridad {
              id
              nombre
              pais {
                codigo
                id
                nombre
              }
            }
            id
            inicioRenovacion
            numeroRegistro
            pais {
              codigo
              id
              nombre
            }
            vencimientoSanitario
          }
        }
      }
      nextToken
    }
  }
`;

export const getCodigoFormula = /* GraphQL */ `
  query GetCodigoFormula($id: ID!) {
    getCodigoFormula(id: $id) {
      id
      createdBy
      modifiedBy
      marca
      codigo
      dossierCompleteness
      tramites {
        id
        estado
        dossierCompleteness
        tipo
        pais {
          id
          codigo
          nombre
        }
      }
      dossierData {
        codigo
        done
      }
      bu {
        id
        codigo
        nombre
      }
      producto
      presentaciones
      fabricantes {
        id
        nombre
        direccion
        pais {
          codigo
          nombre
        }
        vencimientoBPM
      }
      principiosActivos {
        principio {
          id
          nombre
        }
        fabricantes {
          id
          pais {
            codigo
            nombre
          }
          direccion
          nombre
          vencimientoBPM
        }
        concentracion
      }
      categoriaTerapeutica {
        id
        nombre
      }
      formaCosmeticaFarmaceutica {
        id
        nombre
      }
      categoria {
        id
        codigo
        nombre
      }
      registros {
        autoridad {
          id
          nombre
          pais {
            codigo
            id
            nombre
          }
        }
        id
        inicioRenovacion
        numeroRegistro
        pais {
          codigo
          id
          nombre
        }
        vencimientoSanitario
      }
    }
  }
`;

export const createCodigoFormulaMutation = /* GraphQL */ `
  mutation CreateCodigoFormula(
    $input: CreateCodigoFormulaInput!
    $condition: ModelCodigoFormulaConditionInput
  ) {
    createCodigoFormula(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateCodigoFormulaMutation = /* GraphQL */ `
  mutation UpdateCodigoFormula(
    $input: UpdateCodigoFormulaInput!
    $condition: ModelCodigoFormulaConditionInput
  ) {
    updateCodigoFormula(input: $input, condition: $condition) {
      id
    }
  }
`;

export const saveCodigoFormula = /* GraphQL */ `
  mutation SaveCodigoFormula($data: FNS_SaveCodigoFormulaInput!) {
    saveCodigoFormula(data: $data)
  }
`;
