import { Grid } from "@mui/material";
import * as APIt from "API";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import FormulaYPresentacionCombo from "features/Common/FormulaYPresentacionCombo";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import { UseFormReturn, useWatch } from "react-hook-form";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function VigilanciaPicker(data: Props): JSX.Element {
  const { formMethods } = data;

  const {
    control,
    formState: { errors },
    setValue,
  } = formMethods;
  const pais = useWatch({ control, name: "pais" });

  const {
    state: { autoridadList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const paises: APIt.PaisVO[] = [];
  const paisesCheck: string[] = [];
  autoridadList?.forEach((autoridad: APIt.Autoridad) => {
    if (
      !paisesCheck.includes(autoridad.pais.codigo) &&
      (userRol === "Global" || userPaises.includes(autoridad.pais.codigo))
    ) {
      paisesCheck.push(autoridad.pais.codigo);
      paises.push(autoridad.pais as any);
    }
  });

  return (
    <Grid container spacing={3}>
      <FormulaYPresentacionCombo formMethods={formMethods} pickPresentacion />
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="País"
          name="pais"
          errors={errors.pais?.message}
          options={paises}
          control={control as any}
          onChange={() => {
            setValue("autoridad", null);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} xl={3}>
        <ControlledAutocomplete
          label="Autoridad"
          name="autoridad"
          errors={errors.autoridad?.message}
          options={
            autoridadList.filter((autoridad: APIt.Autoridad) => autoridad.pais.id === pais?.id) ||
            []
          }
          control={control as any}
        />
      </Grid>
    </Grid>
  );
}

export default VigilanciaPicker;
