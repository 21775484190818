import { UseFormReturn } from "react-hook-form";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import { Divider, Grid } from "@mui/material";
import { gridSizes } from "globalvars";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import FormField from "components/tramites/FormField";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
};
function DatosGeneralesTramite(params: Props): JSX.Element {
  const {
    formMethods: {
      control,
      formState: { errors },
      getValues,
      register,
    },
  } = params;
  const { estado, tipo, pais } = getValues();

  const {
    state: { iniciativaList, userRol, userPaises },
  } = useTramitesAppStateContext();

  const isUserGlobal = userRol === "Global";
  const isUserPais = userRol === "Local" && userPaises?.includes(pais?.codigo);

  const editFechasReales = estado === APIt.EstadoTramite.aprobado && isUserGlobal;

  const canEdit = (estado === APIt.EstadoTramite.pendiente && isUserPais) || isUserGlobal;
  const canEditObjetivos =
    ([
      APIt.EstadoTramite.pendiente,
      APIt.EstadoTramite.liberado,
      APIt.EstadoTramite.objetado,
      APIt.EstadoTramite.presentado,
    ].includes(estado) &&
      isUserPais) ||
    isUserGlobal;

  const nuevoOrMod = [
    APIt.TipoTramite.nuevo,
    APIt.TipoTramite.modificacion,
    APIt.TipoTramite.modificacionAdministrativa,
    APIt.TipoTramite.modificacionTecnica,
  ].includes(tipo);

  const showLanzamiento = ![
    APIt.TipoTramite.vigilanciaSanitaria,
    APIt.TipoTramite.certificados,
    APIt.TipoTramite.renovacion,
  ].includes(tipo);

  const registroOTramite = [
    APIt.TipoTramite.nuevo,
    APIt.TipoTramite.modificacion,
    APIt.TipoTramite.modificacionAdministrativa,
    APIt.TipoTramite.modificacionTecnica,
    APIt.TipoTramite.renovacion,
  ].includes(tipo)
    ? "registro"
    : "tramite";

  return (
    <MDBox pb={3} px={3}>
      <Grid container direction="row" spacing={1}>
        <Grid container spacing={3} pt={4}>
          {nuevoOrMod && (
            <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
              <ControlledAutocomplete
                label="Iniciativa"
                name="iniciativa"
                options={iniciativaList.sort((a: APIt.Iniciativa, b: APIt.Iniciativa) =>
                  a.nombre > b.nombre ? 1 : -1
                )}
                control={control as any}
                errors={errors.iniciativa?.message}
                disabled={!canEdit}
              />
            </Grid>
          )}
          {nuevoOrMod && (
            <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
              <ControlledAutocomplete
                label="Prioridad"
                name="prioridad"
                errors={errors.prioridad?.message}
                options={[
                  { id: "1", nombre: "1" },
                  { id: "2", nombre: "2" },
                  { id: "3", nombre: "3" },
                ]}
                control={control as any}
                disabled={!canEdit}
              />
            </Grid>
          )}
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <FormField
              label="Costos directos (Moneda local)"
              type="number"
              name="costosDirectos"
              errors={errors.costosDirectos?.message}
              register={register as any}
              disabled={!canEdit}
              title="Pagos a autoridad."
            />
          </Grid>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <FormField
              label="Costos indirectos (Moneda local)"
              type="number"
              name="costosIndirectos"
              errors={errors.costosIndirectos?.message}
              register={register as any}
              disabled={!canEdit}
              title="Pagos a consultores y/o terceros."
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3} pt={4}>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <FormField
              label="Fecha objetivo de liberación de dossier"
              type="date"
              name="fechaLiberacionObjetivo"
              errors={errors.fechaLiberacionObjetivo?.message}
              register={register as any}
              control={control as any}
              disabled={!canEditObjetivos}
            />
          </Grid>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <FormField
              label="Fecha objetivo de presentación *"
              type="date"
              name="fechaPresentacionObjetivo"
              errors={errors.fechaPresentacionObjetivo?.message}
              register={register as any}
              control={control as any}
              disabled={!canEditObjetivos}
            />
          </Grid>
          <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
            <FormField
              label={`Fecha objetivo de obtención de ${registroOTramite} *`}
              type="date"
              name="fechaObjetivo"
              errors={errors.fechaObjetivo?.message}
              register={register as any}
              control={control as any}
              disabled={!canEditObjetivos}
            />
          </Grid>
          {showLanzamiento && (
            <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
              <FormField
                label="Fecha objetivo de lanzamiento"
                type="date"
                name="fechaLanzamientoObjetivo"
                errors={errors.fechaLanzamientoObjetivo?.message}
                register={register as any}
                control={control as any}
                disabled={!canEditObjetivos}
              />
            </Grid>
          )}
          {editFechasReales && (
            <>
              <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
                <FormField
                  label="Fecha Real de liberación"
                  type="date"
                  name="fechaLiberacion"
                  errors={errors.fechaLiberacion?.message}
                  register={register as any}
                  control={control as any}
                />
              </Grid>
              <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
                <FormField
                  label="Fecha Real de Presentación"
                  type="date"
                  name="fechaPresentacion"
                  errors={errors.fechaPresentacion?.message}
                  register={register as any}
                  control={control as any}
                />
              </Grid>
              <Grid item xs={gridSizes.xs} sm={gridSizes.sm} lg={gridSizes.lg} xl={gridSizes.xl}>
                <FormField
                  label="Fecha Real de Aprobación"
                  type="date"
                  name="fechaObtencion"
                  errors={errors.fechaObtencion?.message}
                  register={register as any}
                  control={control as any}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Divider />
        <Grid container spacing={3} pt={4}>
          <Grid item xs={12}>
            <FormField
              label="Etiqueta"
              type="text"
              name="etiqueta"
              errors={errors.etiqueta?.message}
              register={register as any}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} pt={4}>
          <Grid item xs={12}>
            <FormField
              label="Comentarios"
              type="text"
              name="comentarios"
              errors={errors.comentarios?.message}
              register={register as any}
              multiline
              rows={4}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default DatosGeneralesTramite;
