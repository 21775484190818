import React, { useEffect } from "react";
import MDBox from "components/md/MDBox";
import { UseFormReturn, useWatch } from "react-hook-form";
import * as DossierUtils from "services/Utils/DossierUtils";
import * as APIt from "API";
import DossierColoredProgress from "./DossierColoredProgress";

export interface Props {
  children?: React.ReactNode;
  formMethods: UseFormReturn<APIt.Tramite, any>;
  formulaFormMethods: UseFormReturn<{ formulas: APIt.CodigoFormula[] }>;
  preferences: DossierUtils.PreferencesType;
}

function DossierHeaderProgress(params: Props): JSX.Element {
  const { formMethods, formulaFormMethods, preferences } = params;

  const { control, setValue } = formMethods;
  const dossierData = useWatch({ control, name: "dossierData" });

  const { control: formulasControl } = formulaFormMethods;
  const formulas = useWatch({ control: formulasControl, name: "formulas" });

  // TRAMITE PROGRESS
  const fieldChilds: DossierUtils.TramiteDefinitionEntry[] = DossierUtils.getGroupFullFieldChilds(
    preferences.tramite.preferences,
    preferences.tramite.preferences.find(
      (e: DossierUtils.TramiteDefinitionEntry) => e.code === "root"
    )
  );
  let fieldChildCount = fieldChilds.length;
  let done = fieldChilds?.reduce(
    (acc: number, value: DossierUtils.TramiteDefinitionEntry) =>
      acc + (dossierData?.find((e: APIt.DossierField) => e.codigo === value.code)?.done ? 1 : 0),
    0
  );

  // FORMULA PROGRESS
  formulas?.forEach((formula: APIt.CodigoFormula) => {
    const { id, dossierData: formulaDossierData } = formula;
    if (preferences.formulas[id]) {
      const formulaFieldChilds: DossierUtils.TramiteDefinitionEntry[] =
        DossierUtils.getGroupFullFieldChilds(
          preferences.formulas[id].preferences,
          preferences.formulas[id].preferences.find(
            (e: DossierUtils.TramiteDefinitionEntry) => e.code === "root"
          )
        );
      fieldChildCount += formulaFieldChilds.length;
      done +=
        formulaFieldChilds?.reduce(
          (acc: number, value: DossierUtils.TramiteDefinitionEntry) =>
            acc +
            (formulaDossierData?.find((e: APIt.DossierField) => e.codigo === value.code)?.done
              ? 1
              : 0),
          0
        ) || 0;
    }
  });

  // PROGRESS DISPLAY
  const progress =
    fieldChildCount > 0 ? parseFloat(((done / (fieldChildCount || 1)) * 100).toFixed(2)) : 0;

  useEffect(() => {
    setValue("dossierCompleteness", progress);
  }, [progress]);

  return (
    <MDBox mr={2} mt={-1}>
      <DossierColoredProgress progress={progress} />
    </MDBox>
  );
}

export default DossierHeaderProgress;
