import { DashboardTramiteDaily } from "API";
import React from "react";
import StackedBarChart from "components/tramites/Dashboard/StackedBarChart";
import useData from "./useData";

interface Props {
  initialData: DashboardTramiteDaily[];
  loading: boolean;
}

function PorIniciativa(props: Props): JSX.Element {
  const { initialData, loading } = props;

  const { header, data, options } = useData(initialData);

  return <StackedBarChart header={header} data={data} options={options} loading={loading} />;
}

export default React.memo(PorIniciativa);
