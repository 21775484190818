import ExcelJs from "exceljs";
import { saveAs } from "file-saver";

export type ExportConfigEntry = {
  id: string;
  column: string;
  field: string;
  extractionFunction?: any;
  isGroup?: boolean;
  group?: ExportConfigEntry[];
  groupMatchFilter?: any;
  hidden?: boolean;
};

export type ExportDataEntry = {
  id: string;
  value?: any;
  hidden?: boolean;
};

const getExportFieldValue = (fields: string[], exportEntry: any): any => {
  const value = exportEntry[fields[0]];
  if (value && fields.length > 1) {
    fields.shift();
    return getExportFieldValue(fields, value);
  }

  return value;
};

const getExportEntryValue = (
  exportEntry: any,
  configEntry: ExportConfigEntry,
  dataEntry?: ExportDataEntry[]
) => {
  const fields = configEntry.field.split(".");

  const value = getExportFieldValue(fields, exportEntry);

  if (value !== null && value !== undefined && configEntry.extractionFunction) {
    return configEntry.extractionFunction(value, dataEntry);
  }

  return value;
};

const getDataEntries = (
  data: ExportDataEntry[][],
  exportEntry: any,
  config: ExportConfigEntry[]
) => {
  let newData: ExportDataEntry[][] = data;

  config.forEach((configEntry) => {
    if (configEntry.isGroup) {
      let value = getExportEntryValue(exportEntry, configEntry);

      const allChildsData: ExportDataEntry[][] = [];
      if (!value || value.length < 1) {
        value = [{}];
      }
      value.forEach((valueChild: any) => {
        const childData: ExportDataEntry[][] = getDataEntries([[]], valueChild, configEntry.group);
        if (childData[0].length > 0) allChildsData.push(...childData);
      });

      const brandNewData: ExportDataEntry[][] = [];
      allChildsData.forEach((childDataentry: any) => {
        newData.forEach((dataEntry) => {
          if (configEntry.groupMatchFilter(childDataentry, dataEntry))
            brandNewData.push([...dataEntry, ...childDataentry]);
        });
      });
      newData = brandNewData;
    } else {
      newData.forEach((dataEntry) => {
        const value = getExportEntryValue(exportEntry, configEntry, dataEntry);

        dataEntry.push({
          id: configEntry.id,
          hidden: configEntry.hidden,
          value,
        });
      });
    }
  });

  return newData;
};

const getExportData = (exportList: any[], config: ExportConfigEntry[]) => {
  const data: ExportDataEntry[][] = [];

  exportList.forEach((exportEntry) => {
    const returnData = getDataEntries([[]], exportEntry, config);
    data.push(...returnData);
  });

  return data;
};

const hideColumns = (config: ExportConfigEntry[], columns: any) =>
  config.map((configEntry) => {
    if (configEntry.isGroup) {
      const group: ExportConfigEntry[] = hideColumns(configEntry.group, columns);
      return { ...configEntry, group };
    }
    return { ...configEntry, hidden: !columns[configEntry.id] };
  });

const fillHeaderRow = (config: ExportConfigEntry[], headerRow: string[]) => {
  config
    .filter((e) => !e.hidden || e.isGroup)
    .forEach((e) => {
      if (e.isGroup) {
        fillHeaderRow(e.group, headerRow);
      } else {
        headerRow.push(e.column);
      }
    });
};

export const downloadExcel = (exportList: any[], baseConfig: ExportConfigEntry[], columns: any) => {
  const config: ExportConfigEntry[] = hideColumns(baseConfig, columns);

  const workbook = new ExcelJs.Workbook();
  const data: ExportDataEntry[][] = getExportData(exportList, config);

  const worksheet = workbook.addWorksheet("Datos Exportados");

  const headerRow: string[] = [];
  fillHeaderRow(config, headerRow);
  // console.log(headerRow);
  worksheet.addRow(headerRow);

  const compareArrays = (a: string[], b: string[]) =>
    a.length === b.length && a.every((element, index) => element === b[index]);
  const rowsAdded: string[][] = [];
  const rowExists = (newRow: string[]) => rowsAdded.some((e) => compareArrays(e, newRow));

  data.forEach((dataEntry) => {
    const row = dataEntry.filter((entry) => !entry.hidden).map((entry) => entry.value);
    if (!rowExists(row)) {
      // console.log(row);
      rowsAdded.push(row);
      worksheet.addRow(row);
    }
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Export.xlsx");
  });
};

export const downloadExcelEasy = (data: string[][], header: string[]) => {
  const workbook = new ExcelJs.Workbook();

  const worksheet = workbook.addWorksheet("Datos Exportados");

  worksheet.addRow(header);

  data.forEach((row) => worksheet.addRow(row));

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Export.xlsx");
  });
};
