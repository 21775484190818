import { Divider, Icon } from "@mui/material";
import { EstadoObjecion } from "API";
import MDBox from "components/md/MDBox";
import MDButton from "components/md/MDButton";
import MDTypography from "components/md/MDTypography";
import ObjecionCierreDialog from "features/Tramite/TramiteEdit/DatosTramite/Objeciones/ObjecionCierreDialog";
import ObjecionRespuestaDialog from "features/Tramite/TramiteEdit/DatosTramite/Objeciones/ObjecionRespuestaDialog";

function ObjecionEntry(data: any): JSX.Element {
  const {
    formData: { item, update, index, canEdit },
  } = data;

  const statusIcon = {
    [EstadoObjecion.pendiente]: (
      <MDButton
        variant="outlined"
        color="warning"
        iconOnly
        circular
        title={`Respuesta pendiente (${item.fechaObjecion})`}
      >
        <Icon sx={{ fontWeight: "bold" }}>priority_high</Icon>
      </MDButton>
    ),
    [EstadoObjecion.respondida]: (
      <MDButton
        variant="outlined"
        color="dark"
        iconOnly
        circular
        title={`Respuesta enviada (${item.fechaRespuesta})`}
      >
        <Icon sx={{ fontWeight: "bold" }}>priority_high</Icon>
      </MDButton>
    ),
    [EstadoObjecion.rechazada]: (
      <MDButton
        variant="outlined"
        color="error"
        iconOnly
        circular
        title={`Respuesta rechazada (${item.fechaCierre})`}
      >
        <Icon sx={{ fontWeight: "bold" }}>expand_more</Icon>
      </MDButton>
    ),
    [EstadoObjecion.aceptada]: (
      <MDButton
        variant="outlined"
        color="success"
        iconOnly
        circular
        title={`Respuesta aceptada (${item.fechaCierre})`}
      >
        <Icon sx={{ fontWeight: "bold" }}>expand_less</Icon>
      </MDButton>
    ),
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={1}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        {/* OBJECION */}
        <MDBox display="flex" mb={-1} mt={-1}>
          <MDBox width="35%">
            {statusIcon[item.estado as keyof typeof statusIcon]}
            <MDTypography variant="label" fontWeight="medium" ml={1}>
              {item.fechaObjecion} {item.autoridad?.nombre}:
            </MDTypography>
            {item.asignableRA && (
              <MDTypography variant="label" fontWeight="medium" ml={1} color="success">
                (RA)
              </MDTypography>
            )}
            <br />
            <MDBox mt={-2}>
              <MDTypography variant="caption" fontWeight="medium" ml={6} color="error">
                Límite: {item.limiteEspera}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDTypography variant="subtitle1" width="65%">
            {item.objecion}
          </MDTypography>
        </MDBox>
        {/* RESPUESTA */}
        <Divider />
        {item.estado !== EstadoObjecion.pendiente && (
          <MDBox display="flex" mb={-2}>
            <MDTypography variant="label" fontWeight="medium" width="35%" ml={5}>
              {item.fechaRespuesta} Respuesta:
            </MDTypography>
            &nbsp;
            <MDTypography variant="subtitle1" width="65%">
              {item.descripcion}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      {/* BOTONES */}
      <MDBox
        width="20%"
        display="flex"
        flexDirection="column"
        alignItems={{ xs: "flex-end", sm: "end" }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-end", sm: "end" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              {canEdit && item.estado === EstadoObjecion.pendiente && (
                <ObjecionRespuestaDialog
                  valueSetter={(updatedObjecion: any) =>
                    update(index, { ...item, ...updatedObjecion })
                  }
                  values={item}
                />
              )}
              {canEdit &&
                (item.estado === EstadoObjecion.pendiente ||
                  item.estado === EstadoObjecion.respondida) && (
                  <ObjecionCierreDialog
                    valueSetter={(updatedObjecion: any) =>
                      update(index, { ...item, ...updatedObjecion })
                    }
                    values={item}
                  />
                )}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default ObjecionEntry;
