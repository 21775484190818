export const listPrincipioActivos = /* GraphQL */ `
  query ListPrincipioActivos(
    $filter: ModelPrincipioActivoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrincipioActivos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
      }
      nextToken
    }
  }
`;

export const createPrincipioActivoMutation = /* GraphQL */ `
  mutation CreatePrincipioActivo(
    $input: CreatePrincipioActivoInput!
    $condition: ModelPrincipioActivoConditionInput
  ) {
    createPrincipioActivo(input: $input, condition: $condition) {
      id
      nombre
    }
  }
`;

export const getPrincipioActivo = /* GraphQL */ `
  query GetPrincipioActivo($id: ID!) {
    getPrincipioActivo(id: $id) {
      id
      nombre
      createdAt
      updatedAt
    }
  }
`;
