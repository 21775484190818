import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";

import * as APIt from "API";
import { getCodigoFormula } from "services/CodigoFormula/queries";
import { removeKey } from "globalvars";
import { saveFormula } from "graphql/mutations";

const fetchCodigoFormula = async (id: string): Promise<APIt.CodigoFormula> => {
  let codigoFormula: any = null;
  const params: APIt.GetCodigoFormulaQueryVariables = { id };

  const result = (await API.graphql({
    query: getCodigoFormula,
    variables: params,
  })) as GraphQLResult<APIt.GetCodigoFormulaQuery>;

  if (result.data) {
    const query: APIt.GetCodigoFormulaQuery = result.data;
    if (query.getCodigoFormula) {
      codigoFormula = query.getCodigoFormula;
    }
  }

  return codigoFormula;
};

const updateCodigoFormula = async (codigoFormula: APIt.CodigoFormula): Promise<string> => {
  let retId = null;
  const userData = await Auth.currentAuthenticatedUser();

  const inputFormula: APIt.CodigoFormula = removeKey(codigoFormula, "arrayKey");
  if (!inputFormula.createdBy) {
    inputFormula.createdBy = userData.attributes.email;
  }
  inputFormula.modifiedBy = userData.attributes.email;

  const input = { formula: JSON.stringify(inputFormula) };

  const body = (await API.graphql(
    graphqlOperation(saveFormula, {
      input,
    })
  )) as GraphQLResult<APIt.SaveFormulaMutation>;
  retId = body.data.saveFormula?.objectId;

  /* if (codigoFormula.id) {
    const body = (await API.graphql(
      graphqlOperation(updateCodigoFormulaMutation, {
        input: { ...inputFormula, postCalculate: true },
      })
    )) as GraphQLResult<APIt.UpdateCodigoFormulaMutation>;
    retId = body.data.updateCodigoFormula.id;
  } else {
    const { id: idOut, ...inputFormulaNoId } = inputFormula;
    const body = (await API.graphql(
      graphqlOperation(createCodigoFormulaMutation, {
        input: { ...inputFormulaNoId, postCalculate: true },
      })
    )) as GraphQLResult<APIt.CreateCodigoFormulaMutation>;
    retId = body.data.createCodigoFormula.id;
  } */

  return retId;
};

export { fetchCodigoFormula, updateCodigoFormula };
