import { Autoridad } from "API";
import { useState, useEffect } from "react";

import fetchAutoridadsList from "services/Autoridad/AutoridadList";

const useAutoridadOptions = (): [Autoridad[], () => void] => {
  const [items, setItems] = useState([]);

  // get first page
  const refresh = () => {
    useEffect(() => {
      fetchAutoridadsList().then((result) => {
        const { autoridads } = result;
        setItems(autoridads);
      });
    }, []);
  };
  refresh();

  return [items, refresh];
};

export default useAutoridadOptions;
