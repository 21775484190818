import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import * as APIt from "API";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import useCodigoFormulaCombo from "features/Tramite/ServiceHooks/useCodigoFormulaCombo";
import { debounce } from "lodash";
import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";

type Props = {
  formMethods: UseFormReturn<APIt.Tramite, any>;
  pickPresentacion: Boolean;
};
function FormulaYPresentacionCombo(data: Props): JSX.Element {
  const { formMethods, pickPresentacion } = data;
  const [options, fetchCodigos] = useCodigoFormulaCombo();

  const {
    getValues,
    control,
    formState: { errors },
  } = formMethods;

  const {
    append: appendFormula,
    remove: removeFormula,
    fields: formulaFields,
  } = useFieldArray({
    control,
    name: "formulas",
    keyName: "arrayKey",
  });

  const {
    append: appendPresentacion,
    remove: removePresentacion,
    fields: presentacionFields,
  } = useFieldArray({
    control,
    name: "presentaciones",
    keyName: "arrayKey",
  });

  // OBTENCIÓN DE OPCIONES
  const debouncedSearch = React.useRef(
    debounce(async (codigo) => {
      fetchCodigos({ codigo });
    }, 300)
  ).current;

  async function handleFormulaSearch(codigo: string) {
    debouncedSearch(codigo);
  }

  React.useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const pickFormula = (formula: APIt.CodigoFormula) => {
    const {
      id,
      codigo,
      marca,
      producto,
      presentaciones,
      fabricantes,
      categoria,
      bu,
      categoriaTerapeutica,
      formaCosmeticaFarmaceutica,
      principiosActivos,
    } = formula;
    appendFormula({
      id,
      codigo,
      marca,
      categoria,
      bu,
      categoriaTerapeutica,
      formaCosmeticaFarmaceutica,
      principiosActivos,
    } as APIt.RegistroFormulaVO);

    const fabricantesAPI: APIt.FabricanteVO[] = [];
    const usedFabricanteIds: string[] = [];
    principiosActivos?.forEach((principio: APIt.RegistroPrincipioActivoVO) => {
      principio.fabricantes?.forEach((fabricante: APIt.FabricanteVO) => {
        if (!usedFabricanteIds.includes(fabricante.id)) {
          usedFabricanteIds.push(fabricante.id);
          fabricantesAPI.push(fabricante);
        }
      });
    });

    if (pickPresentacion)
      appendPresentacion({
        formulas: [codigo],
        producto,
        presentaciones,
        fabricantes: fabricantes.map(
          (fabricante: APIt.FabricanteVO) =>
            ({
              fabricante,
              acondicionadoresPrimarios: [
                {
                  acondicionadorPrimario: fabricante,
                  estatusVenta: APIt.EstatusVenta.nocomercializado,
                  estabilidad: false,
                },
              ],
              maquiladores: [fabricante],
              fabricantesAPI,
            } as APIt.RegistroFabricanteVO)
        ),
      } as APIt.RegistroPresentacionesVO);
  };

  const unpickFormulas = (index?: number) => {
    if (pickPresentacion) {
      if (index === undefined) {
        removePresentacion();
      } else {
        const { codigo } = formulaFields[index];

        const indexes: number[] = [];
        presentacionFields.forEach((presentacion: APIt.RegistroPresentacionesVO, index: number) => {
          if (presentacion.formulas?.includes(codigo)) {
            indexes.push(index);
          }
        });

        indexes.forEach((index: number) => {
          removePresentacion(index);
        });
      }

      removeFormula(index);
    }
  };

  return (
    <Grid item xs={12} sm={4} xl={3}>
      <Autocomplete
        id="codigo-formula-combo"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : `${option.codigo} - ${option.producto}`
        }
        filterOptions={(x) => x}
        options={[...options, ...(getValues().formulas || [])]}
        autoComplete
        multiple
        includeInputInList
        filterSelectedOptions
        value={getValues().formulas || []}
        onChange={(event: any, newValue: any, selectOption: any, option: any) => {
          if (option?.option?.id) pickFormula(option.option);
          else unpickFormulas();
        }}
        onInputChange={(event, newInputValue) => {
          handleFormulaSearch(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => {
          if (!value.id) return false;
          return option.id === value.id;
        }}
        renderInput={(params) => (
          <MDBox mb={1.5}>
            <TextField
              {...params}
              label="Código de Fórmula"
              fullWidth
              onKeyDown={(event: any) => {
                if (event.key === "Backspace") {
                  event.stopPropagation();
                }
              }}
              error={!!errors.formulas?.message}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                {errors.formulas?.message}
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            {option.codigo} - {option.producto}
          </Box>
        )}
        renderTags={(value: any, getTagProps) =>
          value.map((option: any, index: number) => (
            <Chip
              variant="outlined"
              label={option.codigo}
              {...getTagProps({ index })}
              onDelete={() => {
                unpickFormulas(index);
              }}
            />
          ))
        }
      />
    </Grid>
  );
}

export default FormulaYPresentacionCombo;
