import * as dateFns from "date-fns";
import { DashboardFilterState, useTramitesAppStateContext } from "features/TramitesAppContext";
import { useEffect } from "react";

/* eslint-disable */
type SetPaises = (paises: string[]) => void;
type SetBusinessUnits = (businessUnits: string[]) => void;
type SetRange = (range: "Q" | "Y" | "M" | "C") => void;
type SetFechaInicio = (fechaInicio: string) => void;
type SetFechaFin = (fechaFin: string) => void;
type SetNextRange = () => void;
type SetPrevRange = () => void;
/* eslint-enable */

type DashboardFiltersTools = {
  dashboardFilterState: DashboardFilterState;
  setPaises: SetPaises;
  setBusinessUnits: SetBusinessUnits;
  setRange: SetRange;
  setFechaInicio: SetFechaInicio;
  setFechaFin: SetFechaFin;
  setNextRange: SetNextRange;
  setPrevRange: SetPrevRange;
};

const getQuarterRange = (when: Date) => {
  const start = dateFns.startOfQuarter(when);
  const end = dateFns.endOfQuarter(when);

  return { start: dateFns.format(start, "yyyy-MM-dd"), end: dateFns.format(end, "yyyy-MM-dd") };
};

const getMonthRange = (when: Date) => {
  const start = dateFns.startOfMonth(when);
  const end = dateFns.endOfMonth(when);

  return { start: dateFns.format(start, "yyyy-MM-dd"), end: dateFns.format(end, "yyyy-MM-dd") };
};

const getYearRange = (when: Date) => {
  const start = dateFns.startOfYear(when);
  const end = dateFns.endOfYear(when);

  return { start: dateFns.format(start, "yyyy-MM-dd"), end: dateFns.format(end, "yyyy-MM-dd") };
};

const useDashboardFilters = (): DashboardFiltersTools => {
  const {
    state: { dashboardFilterState },
    dispatch,
  } = useTramitesAppStateContext();

  useEffect(() => {
    if (!dashboardFilterState || !dashboardFilterState.range) {
      const { start, end } = getQuarterRange(new Date());

      const newState: DashboardFilterState = {
        paises: [],
        businessUnits: [],
        range: "Q",
        fechaInicio: start,
        fechaFin: end,
      };

      dispatch({
        type: "update-dashboard-filter-state",
        payload: newState,
      });
    }
  }, [dashboardFilterState]);

  // RETURN FUNCTIONS
  const setPaises: SetPaises = (paises: string[]) => {
    dispatch({
      type: "update-dashboard-filter-state",
      payload: { ...dashboardFilterState, paises },
    });
  };

  const setBusinessUnits: SetBusinessUnits = (businessUnits: string[]) => {
    dispatch({
      type: "update-dashboard-filter-state",
      payload: { ...dashboardFilterState, businessUnits },
    });
  };

  const setRange: SetRange = (range: "Q" | "Y" | "M" | "C") => {
    if (range === dashboardFilterState.range) return;

    let { fechaInicio, fechaFin } = dashboardFilterState;

    if (range === "Q") {
      const { start, end } = getQuarterRange(new Date());
      fechaInicio = start;
      fechaFin = end;
    }
    if (range === "Y") {
      const { start, end } = getYearRange(new Date());
      fechaInicio = start;
      fechaFin = end;
    }
    if (range === "M") {
      const { start, end } = getMonthRange(new Date());
      fechaInicio = start;
      fechaFin = end;
    }
    dispatch({
      type: "update-dashboard-filter-state",
      payload: { ...dashboardFilterState, range, fechaInicio, fechaFin },
    });
  };

  const setFechaInicio: SetFechaInicio = (fechaInicio: string) => {
    dispatch({
      type: "update-dashboard-filter-state",
      payload: { ...dashboardFilterState, fechaInicio, range: "C" },
    });
  };

  const setFechaFin: SetFechaFin = (fechaFin: string) => {
    dispatch({
      type: "update-dashboard-filter-state",
      payload: { ...dashboardFilterState, fechaFin, range: "C" },
    });
  };

  const setNextPrevRange = (add: number) => {
    const { range } = dashboardFilterState;
    let { fechaInicio, fechaFin } = dashboardFilterState;
    const dtFechaInicio = dateFns.parse(fechaInicio, "yyyy-MM-dd", new Date());
    const dtFechaFin = dateFns.parse(fechaFin, "yyyy-MM-dd", new Date());

    if (range === "Q") {
      const newInicio = dateFns.addQuarters(dtFechaInicio, add);
      const { start, end } = getQuarterRange(newInicio);
      fechaInicio = start;
      fechaFin = end;
    }
    if (range === "M") {
      const newInicio = dateFns.addMonths(dtFechaInicio, add);
      const { start, end } = getMonthRange(newInicio);
      fechaInicio = start;
      fechaFin = end;
    }
    if (range === "Y") {
      const newInicio = dateFns.addYears(dtFechaInicio, add);
      const { start, end } = getYearRange(newInicio);
      fechaInicio = start;
      fechaFin = end;
    }
    if (range === "C") {
      const diff = dateFns.differenceInDays(dtFechaFin, dtFechaInicio);
      const newInicio = dateFns.addDays(dtFechaInicio, diff * add);
      const newEnd = dateFns.addDays(newInicio, diff);

      fechaInicio = dateFns.format(newInicio, "yyyy-MM-dd");
      fechaFin = dateFns.format(newEnd, "yyyy-MM-dd");
    }
    dispatch({
      type: "update-dashboard-filter-state",
      payload: { ...dashboardFilterState, fechaFin, fechaInicio },
    });
  };

  const setNextRange: SetNextRange = () => {
    setNextPrevRange(1);
  };
  const setPrevRange: SetPrevRange = () => {
    setNextPrevRange(-1);
  };

  return {
    dashboardFilterState,
    setPaises,
    setBusinessUnits,
    setRange,
    setFechaInicio,
    setFechaFin,
    setNextRange,
    setPrevRange,
  };
};

export default useDashboardFilters;
