import { Autocomplete, Box, Card, Chip, Divider, Grid, TextField } from "@mui/material";
import MDBox from "components/md/MDBox";
import MDTypography from "components/md/MDTypography";
import FormField from "components/tramites/FormField";
import { useTramitesAppStateContext } from "features/TramitesAppContext";
import NewCategoriaTerapeuticaDialog from "features/CodigoFormula/NewCategoriaTerapeuticaDialog";
import NewFormaCosmeticaFarmaceuticaDialog from "features/CodigoFormula/newFormaCosmeticaFarmaceuticaDialog";
import ControlledAutocomplete from "components/tramites/ControlledAutocomplete";
import NewPrincipioActivoDialog from "features/CodigoFormula/NewPrincipioActivoDialog";
import { useFieldArray, useWatch } from "react-hook-form";
import NewFabricanteDialog from "features/CodigoFormula/NewFabricanteDialog";
import FlagBuilder from "components/tramites/FlagBuilder";
import Avatar from "components/tramites/Avatar";
import { RegistroPrincipioActivoVO } from "API";
import CropTooltip from "components/tramites/CropTooltip/CropTooltip";

function FormulaDetalle(params: any): JSX.Element {
  const { formData } = params;
  const {
    register,
    errors,
    setValue,
    control,
    values,
    append: appendPrincipio,
    remove: removePrincipio,
  } = formData;

  const { append: appendFabricante, remove: removeFabricante } = useFieldArray({
    control,
    name: "fabricantes",
    keyName: "arrayKey",
  });

  const watchCategoria = useWatch({ control, name: "categoria" });

  const {
    state: {
      buList,
      categoriaList,
      categoriaTerapeuticaList,
      formaCosmeticaFarmaceuticaList,
      principioList,
      fabricanteList,
      userRol,
    },
  } = useTramitesAppStateContext();

  const canEdit = userRol === "Global";

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Detalle</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <FormField
              type="text"
              label="Código"
              name="codigo"
              errors={errors.codigo?.message}
              register={register}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormField
              label="Marca *"
              type="text"
              name="marca"
              errors={errors.marca?.message}
              register={register}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormField
              label="Producto *"
              type="text"
              name="producto"
              errors={errors.producto?.message}
              register={register}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormField
              label="Presentaciones"
              type="text"
              name="presentaciones"
              errors={errors.presentaciones?.message}
              register={register}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <ControlledAutocomplete
              label="Categoría *"
              name="categoria"
              options={categoriaList}
              control={control}
              errors={errors.categoria?.message}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <ControlledAutocomplete
              label="B.U. *"
              name="bu"
              errors={errors.bu?.message}
              options={buList}
              control={control}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <ControlledAutocomplete
              label="FC/FF *"
              name="formaCosmeticaFarmaceutica"
              options={formaCosmeticaFarmaceuticaList}
              control={control}
              errors={errors.formaCosmeticaFarmaceutica?.message}
              title="Forma Cosmética/Farmacéutica"
              disabled={!canEdit}
            >
              {canEdit && (
                <NewFormaCosmeticaFarmaceuticaDialog
                  valueSetter={(newVal: any) =>
                    setValue("formaCosmeticaFarmaceutica", newVal, {
                      shouldTouch: true,
                      shouldValidate: true,
                    })
                  }
                />
              )}
            </ControlledAutocomplete>
          </Grid>
          {watchCategoria?.codigo === "OTC" && (
            <Grid item xs={12} sm={6} lg={4}>
              <ControlledAutocomplete
                label="Categoría terapéutica"
                name="categoriaTerapeutica"
                options={categoriaTerapeuticaList}
                control={control}
                errors={errors.categoriaTerapeutica?.message}
                disabled={!canEdit}
              >
                {canEdit && (
                  <NewCategoriaTerapeuticaDialog
                    valueSetter={(newVal: any) =>
                      setValue("categoriaTerapeutica", newVal, {
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              </ControlledAutocomplete>
            </Grid>
          )}
        </Grid>
        <Divider />
        {watchCategoria?.codigo !== "OTC" && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={1}>
                <Grid item md={11}>
                  <Autocomplete
                    id="ingredientes"
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.nombre
                    }
                    filterOptions={(options, state) =>
                      options.filter((option) =>
                        option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                      )
                    }
                    options={principioList}
                    autoComplete
                    multiple
                    includeInputInList
                    filterSelectedOptions
                    value={values.principiosActivos || []}
                    onChange={(event: any, newValue: any, selectOption: any, option: any) => {
                      if (option?.option?.id) appendPrincipio({ principio: option.option });
                      else removePrincipio();
                    }}
                    isOptionEqualToValue={(option, value: any) =>
                      option.nombre === value.principio.nombre
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ingredientes"
                        fullWidth
                        onKeyDown={(event: any) => {
                          if (event.key === "Backspace") {
                            event.stopPropagation();
                          }
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.id}>
                        {option.nombre}
                      </Box>
                    )}
                    renderTags={(value: any, getTagProps) =>
                      value.map((option: RegistroPrincipioActivoVO, index: number) => (
                        <Chip
                          variant="outlined"
                          label={
                            <MDBox>
                              <CropTooltip
                                truncate={100}
                                text={option.principio.nombre || ""}
                                variant="label"
                                color="white"
                              />
                            </MDBox>
                          }
                          {...getTagProps({ index })}
                          onDelete={() => {
                            removePrincipio(index);
                          }}
                          title="asfd"
                        />
                      ))
                    }
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item md={1} pt={1} mt={1}>
                  {canEdit && (
                    <NewPrincipioActivoDialog
                      valueSetter={(newVal: any) =>
                        appendPrincipio({ principio: newVal, fabricantes: [] })
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={11}>
                <Autocomplete
                  id="fabricantes"
                  getOptionLabel={(option) => (typeof option === "string" ? option : option.nombre)}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      option.nombre.toUpperCase().includes(state.inputValue.toUpperCase())
                    )
                  }
                  options={fabricanteList}
                  autoComplete
                  multiple
                  includeInputInList
                  filterSelectedOptions
                  value={values.fabricantes || []}
                  onChange={(event: any, newValue: any, selectOption: any, option: any) => {
                    if (option?.option?.id) appendFabricante(option.option);
                    else removeFabricante();
                  }}
                  isOptionEqualToValue={(option, value: any) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fabricantes"
                      fullWidth
                      onKeyDown={(event: any) => {
                        if (event.key === "Backspace") {
                          event.stopPropagation();
                        }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      <Avatar title={option.pais.nombre} size="xs" sx={{ mr: 2 }}>
                        {" "}
                        <FlagBuilder country={option.pais.codigo} />
                      </Avatar>
                      {option.nombre}
                    </Box>
                  )}
                  renderTags={(value: any, getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        avatar={
                          <Avatar title={option.pais.nombre} size="xxs">
                            {" "}
                            <FlagBuilder country={option.pais.codigo} />
                          </Avatar>
                        }
                        variant="outlined"
                        label={
                          <MDTypography sx={{ ml: 2 }} variant="label" color="white">
                            {option.nombre}
                          </MDTypography>
                        }
                        {...getTagProps({ index })}
                        onDelete={() => {
                          removeFabricante(index);
                        }}
                      />
                    ))
                  }
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item md={1} pt={1} mt={1}>
                {canEdit && (
                  <NewFabricanteDialog valueSetter={(newVal: any) => appendFabricante(newVal)} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default FormulaDetalle;
