import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api";

import * as APIt from "API";
import { listCategoriaTerapeuticas } from "services/CategoriaTerapeutica/queries";

const fetchCategoriaTerapeuticasList = async (): Promise<{
  categoriaTerapeuticas: APIt.CategoriaTerapeutica[];
}> => {
  let categoriaTerapeuticas: APIt.CategoriaTerapeutica[] = [];

  const conditions: APIt.ListCategoriaTerapeuticasQueryVariables = { limit: 20000 };

  const result = (await API.graphql(
    graphqlOperation(listCategoriaTerapeuticas, conditions)
  )) as GraphQLResult<APIt.ListCategoriaTerapeuticasQuery>;

  if (result.data) {
    const resultData: APIt.ListCategoriaTerapeuticasQuery = result.data;
    categoriaTerapeuticas = resultData.listCategoriaTerapeuticas.items;
  }

  return { categoriaTerapeuticas };
};

export default fetchCategoriaTerapeuticasList;
